import {Loader} from '@progress/kendo-react-indicators';

interface props {
  theme?: 'light'
  size?: 'large' | 'small';
  style?: React.CSSProperties
}

export default function LoaderComponent(props: props) {
  let style = props.style || {}
  return (<div style={{
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 99,
    background: '#fff',
    ...style
  }}>
    <Loader
      themeColor={props.theme || "dark"}
      type='converging-spinner'
      size={props.size}
    />
  </div>);
}
