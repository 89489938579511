import React, {useEffect, useRef, useState} from 'react';
import styles from "./livemap.module.scss";
import {Card} from "@progress/kendo-react-layout";
import moment from "moment/moment";
import {
    IActivity,
    IConnector,
    ICoord,
    IDispatch,
    IDispatchSite,
    IEmployee,
    IInfo,
    IMapPeriodItem,
    ISiteToMap,
    ITimeLineRowRenderItem,
    ITrackDetail,
    ITrackServerItem,
    ITrackSite,
    stopType
} from "./interfaces";
import {useBooleanState} from "../../helpers/hooks";
import {getSQLData} from "../../helpers/queries";
import {formatTimeDiff} from "../../helpers/helpers";
import {getClockInOutPeriodId, getPeriodIdForFilter, SHOW_LOGS_DIV_CLASS_NAME} from "./helpers";
import TERow from "./TERow";
import WoRow from "./WORow";
import EmployeeInfo from "./EmploeeInfo";
import {Button} from "@progress/kendo-react-buttons";
import debounce from "lodash.debounce";
import WOsListContainer from "./WoList";
import {costTypeToColor} from "../TKReview/helpers";
import {TStateData, TStateItem} from "../TKReview/interfaces";
import StateCodes from "./StateCodes";
import {DetailsActivityControl} from "../../Components/Map/DetailsActivityControl";

const getCostTypeColor = (type: stopType, IsWarehouse: boolean) => {
    if (IsWarehouse) return costTypeToColor.OFFICE
    switch (type) {
        case 'D':
            return costTypeToColor.DRIVE // '#f5e263'
        case 'B':
            return costTypeToColor.OTHER // '#30ded8'
        case 'N':
            return costTypeToColor.EMPTY // '#d2d2d2'
        case 'S':
            return costTypeToColor.ONSITE // '#5ca734'
    }
    return costTypeToColor.UNAUTHSTOP // 'grey'
}

interface IProps {
    map: any
    refreshKey: number
    data: IEmployee
    isPinned: boolean
    pinnedColor: string | null
    isSelected: boolean
    ShowStateAllocation: boolean
    selectItem: (listId: number) => void
    unSelectItem: (listId: number) => void
    draw: (
        listId: number,
        clockIns: number[],
        clockInId: number,
        periods: IMapPeriodItem[],
        connectors: IConnector[],
        trackSites: ISiteToMap[],
    ) => void
    onTogglePin: (listId: number) => void
    switchItem: (listId: number) => void;
    switchPeriod: (filteredPeriods: Set<string> | null, listId: number, clockInId: number) => void
    onTrackItemMouseEvent: (e: React.MouseEvent<HTMLDivElement>, filteredPeriods: Set<string> | null, clockInId: number) => void
    onDispatchMouseEvent: (e: React.MouseEvent<HTMLSpanElement>) => void
    onLoadDrawTrack?: (listId: number, clockInId: number) => void
}

function Employee(props: IProps) {
    const {data, isPinned, pinnedColor, isSelected, ShowStateAllocation} = props
    const {ClockIns, ListId} = data

    const [, forceUpdate] = useState(0)
    const isLoading = useBooleanState(false)
    const [details, setDetails] = useState<number[]>([])
    const detailsRef = useRef<{
        [clockInId: number]: {
            info: IInfo
            dispatches: IDispatch[]
            timeEntries: ITimeLineRowRenderItem[]
            filteredPeriods: Set<string> | null
            stateCodes: TStateData
            detailsActivity: IActivity[]
        }
    }>({})
    const sliderControlRef = useRef(DetailsActivityControl({map: props.map}))

    useEffect(() => {
        if (details.length === ClockIns.length) {
            toggleSlider(true)
            isLoading.setFalse()
        }
    }, [details])

    useEffect(() => {
        if (isSelected && details.length < ClockIns.length) {
            loadAllDetails()
        } else if (details.length === ClockIns.length) {
            toggleSlider(isSelected)
            props.switchItem(ListId)
        }
    }, [isSelected])

    useEffect(() => {
        if (isPinned && details.length < ClockIns.length) {
            loadAllDetails()
        } else if (details.length === ClockIns.length) {
            toggleSlider(isPinned)
            props.switchItem(ListId)
        }
    }, [isPinned])

    const toggleSlider = (show: boolean) => {
        if (show) {
            let detailsActivity: IActivity[] = []
            for (let clockInId of details) {
                detailsActivity = [...detailsActivity, ...detailsRef.current[clockInId].detailsActivity]
            }
            if (detailsActivity.length) sliderControlRef.current.initSlider(detailsActivity, data.EmployeeName)
        } else {
            sliderControlRef.current.destroySlider()
        }
    }

    const loadAllDetails = () => {
        isLoading.setTrue()
        for (let id of ClockIns) {
            loadDetails(id)
        }
    }

    const loadDetails = (clockInId: number) => {
        getSQLData({
            spName: "TK_LiveMap_WorkshiftData",
            params: {clockInId}
        }).then((data) => {
            const info: IInfo = data[0][0]
            const periods: ITrackServerItem[] = data[1]
            const coords: ICoord[] = data[2]
            const trackSites: ITrackSite[] = data[3]
            const dispatchSites: IDispatchSite[] = data[4]
            const dispatches: IDispatch[] = data[5]
            const IsHideUnscheduledVisitAlert = data[6][0].IsHideUnscheduledVisitAlert
            const mapPeriods: IMapPeriodItem[] = []
            const listTimeEntries: ITimeLineRowRenderItem[] = []
            const roadMapStates: TStateData = [];
            let newStateRow: TStateItem | null = null

            const clockOutCoords = info.ClockOutLat && info.ClockOutLng ? [info.ClockOutLat, info.ClockOutLng] : null
            const clockOutTime = info.ClockOutTime ? moment(info.ClockOutTime).format('LT') : undefined
            const clockOutType = info.ClockOutType || 'CLOCKOUT'
            const clockInTime = moment(info.ClockInTime).format('LT')
            const clockInCoords = [info.ClockInLat, info.ClockInLng]
            mapPeriods.push({
                Id: getClockInOutPeriodId(clockInId, true),
                Type: 'IN',
                Number: 0,
                Time: clockInTime,
                CenterCoords: clockInCoords,
                ClockInId: clockInId,
                Track: [],
                CostTypeColor: ''
            })
            listTimeEntries.push({
                Id: getClockInOutPeriodId(clockInId, true),
                ClockInId: clockInId,
                Type: 'IN',
                IsUnscheduledSite: false,
                CostTypeColor: '',
                ClockInPlatform: info.Platform,
                IsApprovedHours: true,
            })
            if (ShowStateAllocation) {
                roadMapStates.push({heightMultiplier: 1, isClockFlag: true});
            }

            let lastPrevTEPoint: number[] | undefined = undefined // clockInCoords
            let prevTEId: number | string | undefined = undefined // getClockInOutPeriodId(clockInId, true)
            const connectors: IConnector[] = []

            for (let i = 0; i < periods.length; i++) {
                const period = periods[i]
                const prevPeriod = periods[i - 1]
                const isLast = i === periods.length - 1
                let SiteName: any = ''
                let MainObjectId: number | null = null
                if (ShowStateAllocation) {
                    if (newStateRow && period.StateName !== newStateRow.stateCode) {
                        roadMapStates.push(newStateRow)
                        newStateRow = null
                    }
                    if (!newStateRow) {
                        newStateRow = {
                            heightMultiplier: 0,
                            stateCode: period.StateName,
                        }
                    }
                    newStateRow.heightMultiplier += 1
                }
                if (period.Type === "S") {
                    MainObjectId = period.ScheduledSiteId || period.UnscheduledSiteId || period.LocationId
                    if (!MainObjectId) SiteName = 'Unknown'
                    else SiteName = trackSites.find((site) => +site.ObjectId === MainObjectId)?.ObjectName || ''
                }

                const momentStart = moment(period.StartTime)
                const momentFinish = moment(period.FinishTime)
                const Start = momentStart.format('LT')
                const Finish = momentFinish.format('LT')
                let diffSeconds = momentFinish.diff(momentStart, "seconds");
                const CostTypeColor = getCostTypeColor(period.Type, period.IsWarehouse)
                listTimeEntries.push({
                    Id: period.Id,
                    ClockInId: clockInId,
                    Type: period.Type,
                    Start,
                    Finish: isLast ? Finish : undefined,
                    SiteName,
                    Duration: formatTimeDiff(diffSeconds),
                    IsUnscheduledSite: !IsHideUnscheduledVisitAlert && !!period.UnscheduledSiteId,
                    Number: period.Type === 'S' ? period.Number : undefined,
                    CostTypeColor,
                    IsApprovedHours: true,
                    MainObjectId,
                })

                const Track: number[][] = []
                if (period.Type === 'N' && lastPrevTEPoint) Track.push(lastPrevTEPoint)
                const TrackDetails: ITrackDetail[] = []
                for (let item of coords) {
                    if (item.Time >= period.StartPoint && item.Time <= period.FinishPoint) {
                        const Coords = [item.Lat, item.Lng]
                        Track.push(Coords)
                        if (item.Details) {
                            TrackDetails.push({Coords, Details: item.Details})
                        }
                    }
                }
                if (prevPeriod?.Type === 'N' && Track[0]) {
                    mapPeriods[i - 1].Track.push(Track[0])
                }
                const CenterCoords = period.CenterLat && period.CenterLng ? [period.CenterLat, period.CenterLng] : null
                mapPeriods.push({
                    Id: period.Id,
                    Type: period.Type,
                    Number: period.Number,
                    Time: `${Start} - ${Finish}`,
                    CenterCoords,
                    ClockInId: clockInId,
                    Track,
                    TrackDetails: TrackDetails.length ? TrackDetails : undefined,
                    CostTypeColor,
                    MainObjectId,
                })
                if (lastPrevTEPoint !== undefined && prevTEId !== undefined) {
                    const firtsTEPoint = CenterCoords || Track[0]
                    connectors.push({
                        coords: [lastPrevTEPoint, firtsTEPoint],
                        startId: prevTEId,
                        finishId: period.Id,
                        clockInId,
                    })
                }
                lastPrevTEPoint = CenterCoords || Track[Track.length - 1]
                prevTEId = period.Id
            }
            if (ShowStateAllocation && newStateRow) {
                roadMapStates.push(newStateRow)
            }
            if (clockOutCoords && clockOutTime) {
                if (ShowStateAllocation) {
                    roadMapStates.push({heightMultiplier: 1, isClockFlag: true});
                }
                mapPeriods.push({
                    Id: getClockInOutPeriodId(clockInId, false),
                    Type: 'OUT',
                    Number: 0,
                    Time: clockOutTime,
                    CenterCoords: clockOutCoords,
                    ClockInId: clockInId,
                    Track: [],
                    ClockOutType: clockOutType,
                    CostTypeColor: ''
                })
                listTimeEntries.push({
                    Id: getClockInOutPeriodId(clockInId, false),
                    ClockInId: clockInId,
                    Type: 'OUT',
                    ClockOutType: clockOutType,
                    CostTypeColor: '',
                    IsApprovedHours: true
                })
                /*if (lastPrevTEPoint !== undefined && prevTEId !== undefined) {
                    connectors.push({
                        coords: [lastPrevTEPoint, clockOutCoords],
                        startId: prevTEId,
                        finishId: getClockInOutPeriodId(clockInId, false),
                    })
                }*/
            }
            const detailsActivity: IActivity[] = coords.map(({Lat, Lng, ...c}) => ({
                Lat,
                Lng,
                T: moment(info.ClockInTime).add(c.Time, 'ms').format(),
                TEId: 0,
            }))
            detailsRef.current[clockInId] = {
                info,
                dispatches,
                timeEntries: listTimeEntries,
                filteredPeriods: new Set([]),
                stateCodes: roadMapStates,
                detailsActivity
            }
            setDetails((prevState) => [...prevState, clockInId].sort())

            const uniqueSites: { [key: number]: ISiteToMap } = {}
            const sitesToMap: ISiteToMap[] = []
            for (let site of trackSites) {
                const IsMainStopObject = mapPeriods.findIndex((period) => period.MainObjectId === site.ObjectId) > -1
                const uniqueSite = uniqueSites[site.ObjectId]
                if (uniqueSite) {
                    if (!uniqueSite.PeriodsInfo.IsMainStopObject && IsMainStopObject) uniqueSite.PeriodsInfo.IsMainStopObject = IsMainStopObject
                    continue
                }

                const {
                    Address, Boundaries, Lat, Lng,
                    LocationAbbr, LocationColor, ObjectId, ObjectName, ObjectType,
                    Radius, IsScheduledSite, IsUnscheduledSite, PeriodId
                } = site

                const newUniqueSite: ISiteToMap = {
                    Address,
                    Boundaries,
                    Lat,
                    Lng,
                    LocationAbbr,
                    LocationColor,
                    ObjectId,
                    ObjectName,
                    ObjectType,
                    Radius,
                    PeriodsInfo: {
                        IsMainStopObject,
                        PeriodId,
                    },
                    ScheduledInfo: {
                        IsScheduledSite,
                        IsUnscheduledSite,
                        IsRelatedScheduledSite: null,
                    }
                }
                uniqueSites[site.ObjectId] = newUniqueSite
                sitesToMap.push(newUniqueSite)

            }
            for (let dispatch of dispatches) {
                const sites = dispatchSites.filter(({MainSiteId}) => MainSiteId === dispatch.SiteId)
                for (let site of sites) {
                    const {
                        Id,
                        MainSiteId,
                        Name,
                        Lat,
                        Lng,
                        AddressString,
                        Radius,
                        Boundaries,
                    } = site
                    const uniqueSite = uniqueSites[Id]
                    const IsRelatedScheduledSite = MainSiteId !== Id
                    if (uniqueSite) {
                        uniqueSite.ScheduledInfo.IsScheduledSite = true
                        uniqueSite.ScheduledInfo.IsRelatedScheduledSite = IsRelatedScheduledSite
                        uniqueSite.ScheduledInfo.DispatchId = dispatch.DispatchId
                        continue
                    }
                    const newUniqueSite: ISiteToMap = {
                        Address: AddressString,
                        Boundaries,
                        Lat,
                        Lng,
                        LocationAbbr: null,
                        LocationColor: null,
                        ObjectId: Id,
                        ObjectName: Name,
                        ObjectType: 'Site',
                        Radius,
                        PeriodsInfo: {
                            IsMainStopObject: false,
                        },
                        ScheduledInfo: {
                            IsScheduledSite: true,
                            IsUnscheduledSite: false,
                            IsRelatedScheduledSite,
                            DispatchId: dispatch.DispatchId,
                        }
                    }
                    uniqueSites[site.Id] = newUniqueSite
                    sitesToMap.push(newUniqueSite)

                }
            }

            props.draw(ListId, ClockIns, clockInId, mapPeriods, connectors, sitesToMap)
            if (props.onLoadDrawTrack) props.onLoadDrawTrack(ListId, clockInId)
        });
    }

    const onClick = () => {
        if (isSelected) props.unSelectItem(data.ListId)
        else props.selectItem(data.ListId)
    }

    const onTogglePin = debounce(() => {
        props.onTogglePin(data.ListId)
    }, 100)


    const onToggleClockIn = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const {clockinid} = e.currentTarget.dataset
        if (!clockinid) return
        const info = detailsRef.current[+clockinid]
        if (info.filteredPeriods) {
            info.filteredPeriods = null
        } else {
            info.filteredPeriods = new Set([])
        }
        forceUpdate(+new Date())
        props.switchPeriod(info.filteredPeriods, ListId, +clockinid)
    }

    const onToggleTE = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const {clockinid, periodid} = e.currentTarget.dataset
        if (!clockinid || !periodid) return
        const info = detailsRef.current[+clockinid]
        if (info.filteredPeriods === null) return
        const str = getPeriodIdForFilter(periodid, +clockinid)
        const newValue = info.filteredPeriods.size === 0 || !info.filteredPeriods.has(str)
        if (newValue) {
            info.filteredPeriods.add(str)
        } else {
            info.filteredPeriods!.delete(str)
        }
        forceUpdate(+new Date())
        props.switchPeriod(info.filteredPeriods, ListId, +clockinid)
    }

    const onTEMouseEvent = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        const dataSet = e.currentTarget?.dataset || {}
        const {clockinid} = dataSet
        if (!clockinid) return
        props.onTrackItemMouseEvent(e, detailsRef.current[+clockinid].filteredPeriods, +clockinid)
    }

    const renderTE = (te: ITimeLineRowRenderItem) => {
        const clockInId = te.ClockInId
        const filteredPeriods = detailsRef.current[clockInId].filteredPeriods
        let action: any = ''
        if (te.Type === 'IN') {
            action = <Button
                iconClass={filteredPeriods ? 'mdi mdi-toggle-switch' : 'mdi mdi-toggle-switch-off'}
                onClick={onToggleClockIn}
                rounded={'full'}
                fillMode={'flat'}
                themeColor={filteredPeriods ? 'light' : 'light'}
                size={'large'}
                className={`${styles.DevicePeriodSwitcher} ${styles.On}`}
                data-clockinid={clockInId}
            >
            </Button>
        } else if (te && filteredPeriods) {
            const str = getPeriodIdForFilter(te.Id, clockInId)
            const hasFiltered = filteredPeriods.size > 0
            const isOn = !hasFiltered || filteredPeriods.has(str)
            action = <Button
                iconClass={isOn ? 'mdi mdi-toggle-switch-outline' : 'mdi mdi-toggle-switch-off-outline'}
                onClick={onToggleTE}
                rounded={'full'}
                fillMode={'flat'}
                themeColor={hasFiltered && isOn ? 'primary' : 'light'}
                size={'large'}
                className={`${styles.DevicePeriodSwitcher} ${hasFiltered && isOn ? styles.On : ''}`}
                data-clockinid={clockInId}
                data-periodid={te.Id}
            >
            </Button>
        }

        return <TERow
            key={te.Id}
            item={te}
            listId={ListId}
            clockInId={clockInId}
            pinnedColor={pinnedColor}
            onTrackItemMouseEvent={onTEMouseEvent}
            action={action}
        ></TERow>
    }

    const renderDetails = () => {
        if (!isSelected && !isPinned) return null
        return <>
            {details.map((clockInId) => {
                const {dispatches, timeEntries, info, stateCodes} = detailsRef.current[clockInId]
                return <div
                    key={clockInId}
                    className={SHOW_LOGS_DIV_CLASS_NAME}
                    data-deviceid={info.DeviceId}
                    style={{position: 'relative'}}
                >
                    {ShowStateAllocation && <StateCodes data={stateCodes}/>}
                    <div className={styles.DevicePeriods} style={ShowStateAllocation ? {marginLeft: 20} : undefined}>
                        {timeEntries.map(renderTE)}
                    </div>
                    {!!dispatches?.length && (
                        <WOsListContainer>
                            {dispatches.map((d) => <WoRow
                                    key={d.DispatchId}
                                    listId={ListId}
                                    refName={'FSMDispatchSchedule'}
                                    id={d.DispatchId}
                                    name={d.WorkOrderName}
                                    onDispatchMouseEvent={props.onDispatchMouseEvent}
                                    wasOnSite={d.WasOnSite}
                                />
                            )}
                        </WOsListContainer>
                    )}
                </div>
            })}
        </>
    }


    return (
        <div
            id={'item-' + ListId} // for scrollTo
            className={`${styles.DeviceBox}`}
            onClick={onClick}
        >
            <Card className={styles.DeviceCard}>
                <EmployeeInfo
                    employee={data}
                    isLoading={isLoading.value}
                    onTogglePin={onTogglePin}
                    isPinned={isPinned}
                    pinnedColor={pinnedColor}
                />
                {renderDetails()}
            </Card>
        </div>
    );
}

export default Employee;
