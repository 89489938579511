import React, {useEffect, useState} from 'react';
import FXCard from '../Common/FXCard/FXCard';
import formStyles from './card.module.scss'
import {IFXCardProps} from './interfaces';
import {Button} from "@progress/kendo-react-buttons";
import {Step, Stepper, StepProps} from "@progress/kendo-react-layout";
import {simpleObject} from "../../helpers/interfaces";
import {Loader} from "@progress/kendo-react-indicators";
import {RunScriptAsync} from "../../helpers/runscripts";
import {ModalRef} from "../Common/Modal/Modal";
import moment from "moment";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import {useBooleanState} from "../../helpers/hooks";
import LoaderComponent from "../Common/Loader";
import {Tooltip} from "@progress/kendo-react-tooltip";

interface IProps extends IFXCardProps {
    rowData: simpleObject
    refresh: () => void
    onSetDate: (checkId: number, refreshCardFunc: (rowData: simpleObject) => void) => void
}

type checkActions = 'Confirm' | 'Cancel' | 'Resync' | 'Revise' | 'SetCheckDate'

type checkStatusesNames = 'Pending' | 'Payroll Data Review' | 'Payroll Audit Review' |
    'Review Synced Data' | 'Payroll Revision' | 'Confirming Payment' | 'Processed'

interface ICheckAction {
    Id: checkActions
    Name: string
    IsAvailableField: string
    IsNext: boolean
}

const STATUSES: StepProps[] = [
    {label: "Pending",},
    {label: "Payroll Data Review",},
    {label: "Payroll Audit Review",},
    {label: "Review Synced Data",},
    {label: "Payroll Revision",},
    {label: "Confirming Payment",},
    {label: "Processed",},
];

const statusesExplanation: { [key in checkStatusesNames]: any } = {
    'Pending': <div>
        <div>The Payroll Check Closure process hasn't started yet.</div>
        <div> • For default checks — Closure process starts the next day after the end date of the payroll period.</div>
        <div> • For manually created off-cycle checks — Starts when you create an off-cycle check and click Start Check
            Closure (web) or Start Push to Payroll (desktop).
        </div>
    </div>,
    'Payroll Data Review': <div>
        <div>Check Time Cards and TK Requests for completion.</div>
        <div>Then <b>Confirm and Continue</b>.</div>
        <div>The step is skipped if all are completed.</div>
    </div>,
    'Payroll Audit Review': <div>
        <div>Review missing (cost types, payroll items, Work Orders) allocation on Status tab if there are payroll audit
            results.
        </div>
        <div><b>Set Check Date</b>, if required.</div>
        <div>Then <b>Confirm and Continue</b>.</div>
        <div>
            Time Cards payroll allocation is recalculated. The Check is closed and marked as “Ready to Sync To Payroll”,
            if required by the payroll period type.
        </div>
    </div>,
    'Review Synced Data': <div>
        <div>Review synced data in Payroll system.</div>
        <div>The step is skipped if the payroll period type does not require sync.</div>
        <div><b>Revise and resync Data</b> — Revise Time Cards, make changes and sync again to payroll. It moves you to
            the Payroll Revision step.
        </div>
        <div><b>Revise Data</b> — Revise Time Cards and make changes if needed, but do not sync the Check again to
            payroll. It moves you to the Payroll Revision step.
        </div>
        <div><b>Confirm and continue</b> — Confirm that all data is correct and proceed to the final step of marking the
            Check as “Paid”.
        </div>
        <div><b>Cancel (desktop only)</b> — Stops the Check Closure process, clears TCs from the Check and reopens
            payroll period.
        </div>
    </div>,
    'Payroll Revision': <div>
        <div>Revise Time Cards data.</div>
        <div>Then <b>Confirm and Continue</b>.</div>
        <div>It moves you:</div>
        <div> • to the Confirming Payment step if “Revise Data” was previously selected</div>
        <div> • to the “Review synced Data” step if Revise and resync” was previously selected</div>
    </div>,
    'Confirming Payment': <div>
        <div>Finish Payroll Check Closure process. If this is a Default Check, it marks the Payroll Period as Paid.
        </div>
        <div><b>Revise Data</b> — Revise Time Cards and make changes if necessary, but do not sync the Check again to
            payroll. It moves you to the Payroll Revision step.
        </div>
        <div><b>Mark as Paid</b> — Confirm that all data is correct and proceed to the final step of marking the
            Check as Paid. Paid Payroll Checks are indicated as “ready for sync from payroll,” if configured. Data from
            Payroll will be synced after the Check date.
        </div>
    </div>,
    'Processed': 'Payroll Check is processed'
}

const payrollCheckActions: ICheckAction[] = [
    {
        Id: 'Confirm',
        Name: 'Confirm and Continue',
        IsAvailableField: 'CanConfirm',
        IsNext: true,
    },
    {
        Id: 'Cancel',
        Name: 'Cancel Payroll',
        IsAvailableField: 'CanCancel',
        IsNext: false,
    },
    {
        Id: 'Resync',
        Name: 'Revise and resync Data',
        IsAvailableField: 'CanResync',
        IsNext: false,
    },
    {
        Id: 'Revise',
        Name: 'Revise Data',
        IsAvailableField: 'CanRevise',
        IsNext: false,
    },
    {
        Id: 'SetCheckDate',
        Name: 'Set Check Date for Payroll',
        IsAvailableField: 'CanSetCheckDate',
        IsNext: true,
    },
]

const PayrollCheckStatusCard = (props: IProps) => {
    const {rowData} = props
    const [actualRowData, setActualRowData] = useState<simpleObject>(rowData)
    const [statuses, setStatuses] = useState<StepProps[]>([])
    const [prevActions, setPrevActions] = useState<ICheckAction[]>([])
    const [nextActions, setNextActions] = useState<ICheckAction[]>([])
    const isProcessing = useBooleanState(false)

    useEffect(() => {
        setRowData(rowData)
    }, [rowData])

    const setRowData = (rowData: simpleObject) => {
        setActualRowData(rowData)
        if (rowData.IsRevised) setStatuses(STATUSES)
        else setStatuses(STATUSES.filter((status) => status.label !== 'Payroll Revision'))
        const prevActions: ICheckAction[] = []
        const nextActions: ICheckAction[] = []
        for (let action of payrollCheckActions) {
            const isAvailable = rowData.Status !== 'Review Synced Data' || action.Id !== 'Cancel'
            if (isAvailable && rowData[action.IsAvailableField]) {
                const availableAction = {...action}
                if (action.Id === 'Confirm' && status === 'Confirming Payment') availableAction.Name = 'Mark as "Paid"'
                if (action.IsNext) nextActions.push(availableAction)
                else prevActions.push(availableAction)
            }
        }
        setPrevActions(prevActions)
        setNextActions(nextActions)
        ModalRef.stopProcessing() // stop processing after set date and refresh payrol closure tab
    }

    const close = () => {
        props.finally!()
    }

    const resetAndDelete = async () => {
        const {CheckId} = props.rowData
        if (!CheckId) return
        try {
            isProcessing.setTrue()
            await RunScriptAsync('PayrollChecks_ResetAndDeleteCheck', {CheckId})
            props.refresh()
            close()
        } catch (e: any) {
            console.log(e)
        } finally {
            isProcessing.setFalse()
        }
    }

    const onResetAndDelete = () => {
        ModalRef.showDialog({
            title: 'Confirmation',
            text: 'Confirm Reset and Delete Off-Cycle Check.',
            minWidth: 300,
            buttons: [
                {
                    text: 'Cancel',
                    action: () => {
                        ModalRef.hideDialog();
                    }
                },
                {
                    text: 'Ok',
                    color: 'primary',
                    action: () => {
                        ModalRef.hideDialog();
                        resetAndDelete()
                    }
                },
            ],
        });
    }

    const setStatus = async (JobId: number | null, JobResult: checkActions) => {
        try {
            isProcessing.setTrue()
            await RunScriptAsync('PayrollChecks_CompletePayrollJob', {JobId, JobResult}, true)
            props.refresh()
            close()
        } catch (e: any) {
            console.log(e)
        } finally {
            isProcessing.setFalse()
        }
    }

    const onSetDate = (CheckId: number) => {
        let date: Date | null = new Date();
        ModalRef.showDialog({
            title: 'Check Date for Payroll',
            minWidth: 250,
            buttons: [
                {
                    text: 'Cancel',
                    action: () => {
                        ModalRef.hideDialog();
                    }
                },
                {
                    text: 'Ok',
                    color: 'primary',
                    action: () => {
                        if (!date) return
                        const dateString = moment(date).format('DD.MM.YYYY')
                        setDate(CheckId, dateString)
                    }
                },
            ],
            children: <DatePicker
                onChange={(event) => (date = event.value)}
            />
        });
    }

    const setDate = async (CheckId: number, CheckDate: string) => {
        try {
            ModalRef.hideDialog();
            ModalRef.startProcessing()
            await RunScriptAsync('PayrollChecks_SetCheckDate', {CheckId, CheckDate}, true)
            props.refresh()
        } catch (e) {

        }
    }

    const onAction = (event: React.MouseEvent<HTMLButtonElement>) => {
        const action = event.currentTarget.value as checkActions
        const {CheckId, ActiveJobId} = props.rowData
        if (action === 'SetCheckDate') {
            onSetDate(CheckId)
        } else {
            setStatus(ActiveJobId || null, action)
        }
    }

    const renderActionBtn = (action: ICheckAction) => {
        return <Button
            key={action.Id}
            onClick={onAction}
            value={action.Id}
            themeColor="primary"
        >{action.Name}</Button>
    }

    const renderActionArrow = (isNext: boolean) => {
        const icon = isNext ? 'k-i-arrow-chevron-right' : 'k-i-arrow-chevron-left'
        return <span
            style={{fontSize: 30, opacity: 0.5}}
            className={`k-icon ${icon}`}
        ></span>
    }

    const renderActions = () => {
        return <>
            {!!prevActions.length && <>
                {prevActions.map((a) => renderActionBtn(a))}
                {renderActionArrow(false)}
            </>}
            {!!nextActions.length && !!prevActions.length && <div style={{width: 20}}></div>}
            {!!nextActions.length && <>
                {renderActionArrow(true)}
                {nextActions.map((a) => renderActionBtn(a))}
            </>}
        </>
    }

    const renderStep = (props: any) => {
        return <Step {...props}>
            <Tooltip
                className={'test'}
                tooltipClassName={'test 2'}
                style={{maxWidth: "300px"}}
                parentTitle={true}
                content={(e: any) => {
                    const statusName = e.title as checkStatusesNames
                    return (
                        <div>
                            {statusesExplanation[statusName]}
                        </div>
                    );
                }}
                position={'top'}
                tooltipStyle={{marginTop: '-35px'}}
            >
                <div
                    title={props.label}
                    className="k-step-link"
                >
                <span className="k-step-indicator" aria-hidden="true">
                    <span className="k-step-indicator-text">{props.index + 1}</span>
                </span>
                    <span className="k-step-label">
                    <span className="k-step-text">{props.label}</span>
                </span>
                </div>
            </Tooltip>
        </Step>
    }

    const isRecalculating = actualRowData.Status === 'Recalculating'
    const isSyncingtoPayrollSystem = actualRowData.Status === 'Syncing to Payroll System'
    const haveNotJobId = actualRowData.ActiveJobId === null
    const status = isRecalculating || isSyncingtoPayrollSystem ? actualRowData.PreviousStatus : actualRowData.Status
    const value = statuses.findIndex((s) => s.label === status)

    const renderFooter = () => {
        return <div className={`${formStyles.FormFooter} k-action-buttons`}>
            {!!actualRowData.SyncedErrors && <span className={formStyles.InvalidMessage}>
				{actualRowData.SyncedErrors}
			</span>}

            <Button onClick={close}>Close</Button>
            {rowData.CanResetAndDelete && <Button
                onClick={onResetAndDelete}
                themeColor="primary"
            >Reset & Delete</Button>}
        </div>
    }

    const renderProcessingStatus = () => {
        if (haveNotJobId && actualRowData.Status === 'Processed') {
            return <>
            <span className={'mdi mdi-check'}
                  style={{color: 'var(--color-success)', fontSize: 30}}></span>
                <span>Payroll Check is processed</span>
            </>
        } else {
            let text = ''
            if (haveNotJobId && isSyncingtoPayrollSystem) text = 'Payroll Period Data is syncing to Payroll System. Please Wait.'
            else if (haveNotJobId) text = 'Payroll Check Status is updating. Please Wait.'
            else if (isRecalculating) text = 'Payroll Period Data is recalculating. Please Wait.'
            return <>
                <Loader type={'infinite-spinner'}/>
                <span>{text}</span>
            </>
        }
    }

    return <FXCard
        title={actualRowData.CheckName}
        initialWidth={750}
        initialHeight={260}
        originalPaddings={true}
        onClose={close}
    >
        <div className={formStyles.FormWrapper}>
            {isProcessing.value && <LoaderComponent/>}
            <Stepper
                value={value}
                style={{margin: '20px auto'}}
                items={statuses}
                item={renderStep}
                className={formStyles.IEPayrollCheksStatusStepper}
            />
            <div
                style={{
                    display: 'flex',
                    gap: 8,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                {(haveNotJobId || isRecalculating || isSyncingtoPayrollSystem) ? renderProcessingStatus() : renderActions()}
            </div>
            {renderFooter()}
        </div>
    </FXCard>
}

export default PayrollCheckStatusCard;
