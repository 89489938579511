import React, {Component} from 'react'
import {
  Grid,
  GridColumn as Column,
  GridRowProps,
} from '@progress/kendo-react-grid'
import {simpleObject} from '../../helpers/interfaces'
import {IBuildPlansGridData, IRowItem, sectionName,} from './interfaces'
import styles from './budgetDashboard.module.scss'
import commonStyles from '../../assets/styles/common.module.scss'
import {
  getValues,
  gridColumns,
  IsGroupWithTotal,
  IsRevenueGroup,
  sectionKeys
} from './helpers';
import {GridPDFExport} from '@progress/kendo-react-pdf'

interface props {
  data: Array<IRowItem>
  groupedData: IBuildPlansGridData
}

interface state {
}

const fontSize = 7;

class BudgetDashboardPrintGrid extends Component<props, state> {
  gridPDFExport: any = React.createRef();

  render() {
    return (<GridPDFExport
        ref={this.gridPDFExport}
        fileName="BudgetDashboard.pdf"
        forcePageBreak=".BPGROUPSTART"
        paperSize={'Letter'}
        repeatHeaders={true}
        landscape={true}
      >
        <Grid
          className={`${styles.Grid} ${styles.ExportGrid}`}
          data={this.props.data}
          rowRender={this.renderRowExport}
          cellRender={this.renderCellExport}
          headerCellRender={(el: any, props: any) => <span
            style={{fontSize: fontSize}}>{props.title}</span>}
        >
          {gridColumns.map((column) => {
            return <Column
              key={column.field}
              field={column.field}
              title={column.title}
              width={column.printWidth}
              cell={column.field === 'UsedofPlanned' || column.field === 'UsedofTotalPlanned' ? this.renderCellWithChartExport : undefined}
            />
          })}
        </Grid>
      </GridPDFExport>
    )
  }

  // todo common render functions for print and for grid
  renderRowExport = (el: any, props: GridRowProps) => {
    el.props.style.fontSize = fontSize
    let data: IRowItem = props.dataItem
    if (data.Type !== 'numbers') {
      el.props.style.background = '#fff'
      let isBPRow = data.Type === 'buildPlan'
      let BPId = isBPRow ? data.Id : data.BPId
      return (
        <tr
          className={isBPRow && el.props['data-grid-row-index'] !== 0 ? 'BPGROUPSTART' : ''}
          style={el.props.style}
          key={data.rowType + BPId}
        >
          <td colSpan={9}
              className={isBPRow ? styles.BPTdExport : styles.SectionTdExport}>
            <div className={`${styles.GroupRow}`}>
              {data.Name}
              {isBPRow && <>
                  <span className={styles.GroupRowCell}><span
                      className={styles.GroupRowCellBoldExport}>BP Owner:</span>{data.BPOName}</span>
                {data.EstimatedCompletion !== null &&
                    <span className={styles.GroupRowCell}><span
                        className={styles.GroupRowCellBoldExport}>Estimated Completion:</span>{data.EstimatedCompletion}%</span>}
                {data.CXStatusName &&
                    <span className={styles.GroupRowCell}><span
                        className={styles.GroupRowCellBoldExport}>CX Status:</span>{data.CXStatusName}</span>}
              </>}
            </div>
          </td>
        </tr>
      )
    } else {
      return el
    }
  }

  renderCellExport = (el: any, props: simpleObject) => {
    let field = props.field
    let value = props.dataItem[field];
    let valueFormatted = props.dataItem[`${field}Formatted`]
    let style: simpleObject = {};
    let format;
    let groupName: sectionName = props.rowType === 'groupHeader' ? props.dataItem.value : props.dataItem.Section
    if (props.rowType === 'groupHeader') {
      if (el) {
        let colspan = (IsRevenueGroup(groupName)) ? 2 : el.props.colSpan
        return <td colSpan={colspan}>{el.props.children}</td>
      } else if (
        field === 'Title' ||
        (!IsGroupWithTotal(groupName) && !IsRevenueGroup(groupName))
      ) {
        return null
      } else {
        let row;
        if (IsRevenueGroup(groupName)) {
          row = props.dataItem.items[0]
          value = row[field]
          valueFormatted = row[`${field}Formatted`]
        } else if (IsGroupWithTotal(groupName)) {
          row = props.dataItem.items[props.dataItem.items.length - 1]
          if (row && row.IsTotalRow) {
            value = row[field]
            valueFormatted = row[`${field}Formatted`]
          }
        }
        format = row.Format
      }
    } else {
      format = props.dataItem.Format
      let isTitleColumn = field === 'Title'
      let section: sectionName = props.dataItem.Section
      if (isTitleColumn && !IsRevenueGroup(section)) style.paddingLeft = 8
      if (!isTitleColumn) style.fontSize = fontSize
    }


    if (props.dataItem.IsBoldRow) {
      style.fontWeight = 700;
      if (value !== null) {
        if (format === 'hr') style.paddingRight = 3;
        else if (value < 0) style.paddingRight = 1;
        else style.paddingRight = 2;
      }
    }

    let defProps = el ? el.props : {}
    let colorBefore: any = ''
    if (field === 'Remaining') {
      if (
        (IsRevenueGroup(groupName) && value > 0) ||
        (!IsRevenueGroup(groupName) && value < 0)
      ) {
        colorBefore = <span className={styles.RedNumberExport}>!</span>
        style.color = 'red'
      } else {
        style.color = 'green'
      }
    }
    return <td
      {...defProps}
      className={`${field !== 'Title' && commonStyles.TextRight} ${(field === 'Revised' || field === 'Actual') && styles.MarkedColumnExport} ${styles.TdExport}`}
      style={style}
    >
      {colorBefore}
      {field === 'Title' ? value : valueFormatted}
    </td>
  }

  renderCellWithChartExport = (props: simpleObject) => {
    let columnField = props.field
    let groupName: sectionName = props.rowType === 'groupHeader' ? props.dataItem.value : props.dataItem.Section
    if (props.rowType === 'groupHeader') {
      if (!IsGroupWithTotal(groupName) && !IsRevenueGroup(groupName)) {
        return null
      }
    }
    if (props.rowType !== 'groupHeader' && !IsGroupWithTotal(groupName) && !IsRevenueGroup(groupName)) {
      return <td style={{background: '#fff'}}></td>
    }


    let percentage = props.dataItem[columnField];
    let isRed = !!props.dataItem[`${columnField}IsRed`]

    if (props.rowType === 'groupHeader') {
      let rowData = null
      let values: { total: number | null, value: number | null } = {
        total: null,
        value: null
      }
      if (IsRevenueGroup(groupName)) {
        rowData = props.dataItem.items[0]
      } else if (IsGroupWithTotal(groupName)) {
        let totalRow = props.dataItem.items[props.dataItem.items.length - 1]
        if (totalRow && totalRow.IsTotalRow) {
          rowData = totalRow
        }
        if (rowData) {
          let groupData = this.props.groupedData[rowData.BPId]
          let sectionName: sectionName = rowData.Section
          let sectionKey = sectionKeys[sectionName]
          let sectionData = groupData[sectionKey]

          values = getValues(rowData, columnField, sectionData) || values
        }
      }
      percentage = values.value !== null && values.total ? (values.value / values.total * 100) : null
      if (percentage !== null) {
        percentage = +(percentage.toFixed(0))
        isRed = IsRevenueGroup(groupName) ? percentage < 100 : percentage > 100
      }
    }

    let color = isRed ? '#f5dbd8' : '#d7e6d7'
    let background = `linear-gradient(90deg, ${color} 0%, ${color}  ${percentage}%, #fff ${percentage}%, #fff 100%)`
    return (
      <td className={`${commonStyles.TextCenter}`}
          style={{padding: '5px 0 0 0', position: 'relative', background}}>
        {isRed && <span className={styles.RedNumberExport}>!</span>}
        <span
          style={{position: 'relative'}}>{percentage !== null && (isNaN(percentage) ? 'n/a' : percentage + '%')}</span>
      </td>
    )
  }
}

export default BudgetDashboardPrintGrid
