import {Button} from '@progress/kendo-react-buttons';
import {
    Checkbox,
    CheckboxChangeEvent,
    RadioButtonProps,
    RadioGroup,
    RadioGroupChangeEvent,
    TextArea
} from '@progress/kendo-react-inputs';
import throttle from 'lodash.throttle';
import {IDocumentItem, IHistoryItem} from './interfaces';
import BaseComponent from '../../../Components/BaseComponent';
import Loader from '../../../Components/Common/Loader';
import styles from './checklistResults.module.scss';

import ChecklistResultHistory from './ChecklistHistory';
import {
    GetCommentForMe,
    GetMyComment,
    GetMyCommentsTitle,
    IsDisabledResultFromVersion,
    IsDisableEditStatus,
    LoadHistory,
    OnChangeCompleted,
    OnChangeStatus,
    radioButtonsRoles,
    SaveMyComment,
    UpdateResultStatus
} from './helpers';
import {CheckListStatuses, ICLMSettings} from '../../../stores/interfaces';

interface props {
    dataItem: IDocumentItem;
    currentFileId: number | null;
    settings: ICLMSettings;

    refreshGrid(autoUpdate?: boolean): void;

    createResultFromVersion(): void;

    onCommentChangeFocus(focus: boolean): void;
}

interface state {
    processing: boolean;
    loadingHistory: boolean;
    shownHistory: boolean;
    history: Array<IHistoryItem>;
}

class ChecklistResultInfo extends BaseComponent<props, state> {
    loadedHistory: boolean = false;
    comment: string | null = null;
    timerStatusUpdate: any;

    constructor(props: any) {
        super(props);
        this.state = {
            processing: false,
            loadingHistory: false,
            shownHistory: false,
            history: []
        };
    }

    async componentDidMount() {
        let dataItem = this.props.dataItem;
        this.comment = GetMyComment(dataItem, this.props.settings);
        if (!this.props.settings.IsSubmitter && dataItem.resultId && dataItem.status === 'Pending') {
            this.timerStatusUpdate = setTimeout(() => {
                let dataItem = this.props.dataItem;
                if (dataItem.resultId && dataItem.status === 'Pending') {
                    dataItem.status = 'Reviewed';
                    this.UpdateResultStatus(dataItem.resultId!, 'Reviewed', null, dataItem.approvedFileId || undefined, true);
                    clearTimeout(this.timerStatusUpdate);
                    this.timerStatusUpdate = null;
                }
            }, 3000);
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        if (this.timerStatusUpdate) clearTimeout(this.timerStatusUpdate)
    }

    render() {
        if (this.state.loadingHistory || this.state.processing) return <Loader/>;
        let dataItem = this.props.dataItem;

        let disabledEditStatus = IsDisableEditStatus(dataItem, this.props.settings);
        return <>
            {!this.state.shownHistory && <>
                <div id="status" className="k-content">
                    {this.renderCheckboxes(disabledEditStatus)}
                    {this.props.settings.IsReviewer && <Button
                        disabled={IsDisabledResultFromVersion(this.props.dataItem, this.props.currentFileId)}
                        className={styles.HistoryPanelBtn}
                        onClick={throttle(() => {
                            this.props.createResultFromVersion();
                        }, 200)}
                    >
                        Create Result from Version
                    </Button>}
                    <div
                        className={styles.HistoryPanelTitle}>{GetMyCommentsTitle(dataItem, this.props.settings)}</div>
                    <TextArea
                        rows={10}
                        onChange={this.OnChangeComment}
                        readOnly={disabledEditStatus}
                        id="myComment"
                        //@ts-ignore
                        defaultValue={GetMyComment(dataItem, this.props.settings) || ''}
                        onFocus={this.OnFocusCommentField}
                        onBlur={this.OnBlurCommentField}
                    ></TextArea>
                    <Button
                        className={styles.HistoryPanelBtn}
                        disabled={disabledEditStatus}
                        onClick={this.SaveMyComment}
                    >
                        Save Comment
                    </Button>
                    <div className={styles.HistoryPanelTitle}>Comments For Me</div>
                    <p>{GetCommentForMe(dataItem, this.props.settings)}</p>
                </div>
            </>}
            <div className={styles.History} style={this.state.shownHistory ? {
                flex: 1,
                marginTop: -8
            } : undefined}>
                <Button className={styles.HistoryPanelBtn} onClick={this.ToggleHistory}>
                    {this.state.shownHistory ? 'Show status' : 'Show History'}
                </Button>
                {this.state.shownHistory &&
                    <ChecklistResultHistory
                        loadingHistory={this.state.loadingHistory}
                        history={this.state.history}
                    />
                }
            </div>
        </>;
    }

    renderCheckboxes = (disabledEditStatus: boolean) => {
        let dataItem = this.props.dataItem;
        if (this.props.settings.IsSubmitter) {
            return <Checkbox
                onChange={this.OnChangeCompleted}
                disabled={dataItem.status !== 'InProgress' && dataItem.status !== 'Pending'}
                defaultChecked={dataItem.isCompleted}
                label="Completed"
            />;
        }

        let radioItems: Array<RadioButtonProps> = radioButtonsRoles[this.props.settings.UserRole];
        let disableAll = this.props.settings.IsReviewer && dataItem.status === 'CustomerRejected';
        radioItems.forEach((item) => {
            if (disableAll) item.disabled = true;
            else if (item.value === 'Approved') item.disabled = !dataItem.files.length;
            else item.disabled = false;
            item.checked = dataItem.status === item.value;
        });

        if (!disabledEditStatus) {
            return <RadioGroup
                onChange={this.OnChangeStatus}
                value={dataItem.status}
                data={radioItems}
            />;
        }
        return null;
    };

    OnFocusCommentField = () => {
        this.props.onCommentChangeFocus(true);
    };

    OnBlurCommentField = () => {
        this.props.onCommentChangeFocus(false);
    };

    OnChangeCompleted = async (e: CheckboxChangeEvent) => {
        try {
            this.setState({processing: true});
            let value = e.value;
            let dataItem = this.props.dataItem;
            this.CancelPrevRequestUpdateToken();
            await OnChangeCompleted(value, dataItem, this.cancelToken);
            dataItem.isCompleted = value;
            this.props.refreshGrid();
        } finally {
            this.setState({processing: false});
        }
    };

    OnChangeComment = (e: any) => {
        this.comment = e.value;
    };

    OnChangeStatus = async (e: RadioGroupChangeEvent) => {
        try {
            e.nativeEvent.target.blur();
            clearTimeout(this.timerStatusUpdate);
            this.setState({processing: true});
            let dataItem = this.props.dataItem;
            let newStatus = e.value;
            this.CancelPrevRequestUpdateToken();
            await OnChangeStatus(newStatus, dataItem, this.props.currentFileId, this.cancelToken);
            this.props.refreshGrid();
        } finally {
            this.setState({processing: false});
        }
    };

    SaveMyComment = async () => {
        try {
            this.setState({processing: true});
            let dataItem = this.props.dataItem;
            this.CancelPrevRequestUpdateToken();
            await SaveMyComment(dataItem, this.comment || '', this.props.settings, this.cancelToken);
            this.props.refreshGrid();
        } finally {
            this.setState({processing: false});
        }
    };

    LoadHistory = async () => {
        let resultId = this.props.dataItem.resultId;
        if (!resultId) return;
        try {
            this.setState({loadingHistory: true});
            let history = await LoadHistory(resultId);
            this.loadedHistory = true;
            this.setState({history});
        } finally {
            this.setState({loadingHistory: false});
        }
    };

    UpdateResultStatus = async (resultId: number, status: CheckListStatuses, comment: string | null, approvedFileId?: number, autoUpdate?: boolean) => {
        try {
            this.setState({processing: true});
            this.CancelPrevRequestUpdateToken();
            await UpdateResultStatus(resultId, status, comment || '', approvedFileId, this.cancelToken);
            this.props.refreshGrid(autoUpdate);
        } finally {
            this.setState({processing: false});
        }
    };

    ToggleHistory = () => {
        if (!this.loadedHistory) this.LoadHistory();
        this.setState((state) => ({shownHistory: !state.shownHistory}));
    };
}

export default ChecklistResultInfo;
