import React from "react";
import {simpleObject} from "../../helpers/interfaces";
import DashboardGrid from "../Dashboard/Grid";
import {gridChangeDataAction, IColumnFilterItem, IColumnSetting, IGridFilter} from "../Dashboard/interfaces";
import BaseComponent from "../BaseComponent";
import ButtonLink from "../Common/Buttons/ButtonLink";
import {tabId} from "../Tabs/interfaces";
import {pageId} from "../../Pages/Home/interfaces";
import moment from "moment";

interface props {
    periodId?: number;
    periodName?: string;
    startDate: string
    endDate: string
    isActive: boolean;
    switchTab?: (tab: tabId, serverFilters?: simpleObject, columnFilters?: IColumnFilterItem[], complexGridFilters?: IGridFilter[]) => void
    onChangeGridData: (data: simpleObject[], action: gridChangeDataAction) => void
    doNotSaveFilters: boolean
    pageId: pageId
}

interface state {
    columns: Array<IColumnSetting>
}

class PayrollSummary extends BaseComponent<props, state> {
    gridRef: any = React.createRef();
    periodId = this.props.periodId;

    constructor(props: props) {
        super(props);
        this.state = {
            columns: [],
        }
    }


    render() {
        const {periodId, isActive, startDate, endDate, onChangeGridData} = this.props
        if (isActive && this.periodId !== periodId) {
            this.periodId = periodId;
        }
        if (!this.periodId) return null;
        return (
            <DashboardGrid
                key={this.periodId + startDate + endDate}
                ref={this.gridRef}
                isActive={isActive}
                isParentPage={false}
                columnsSettings={this.state.columns}
                getSqlParams={{
                    spName: "DB_PayrollPeriodSummary",
                    params: {
                        periodId: this.periodId,
                        startDate,
                        endDate,
                    },
                }}
                prepareData={this.PrepareData}
                excelFileName="Payroll Period Summary.xlsx"
                getRowKey={(dataItem: simpleObject) => dataItem.RowNum}
                columnMenu={{
                    filters: true,
                    columns: true,
                }}
                reorderable={true}
                initRefresh={this.InitRefresh}
                onChangeGridData={onChangeGridData}
            />
        );
    }

    renderLinkCell = (value: any, ExternalCode?: string, EmployeeId?: number) => {
        const onClick = () => {
            if (this.props.switchTab) {
                const filters: simpleObject = {}
                if (ExternalCode) filters.ExternalCode = ExternalCode
                if (EmployeeId) filters.EmployeeId = EmployeeId
                sessionStorage.setItem('PPDetailTabColumnValueFilters', JSON.stringify(filters));
                this.props.switchTab('PPDetails')
            }
        }
        return <div style={{textAlign: 'center'}}>
            <ButtonLink
                text={value?.toFixed(2) || ''}
                onClick={onClick}
            />
        </div>
    }

    renderTCLinkCell = (dataItem: simpleObject, field: string) => {
        const value = dataItem[field]
        const onClick = () => {
            if (this.props.switchTab) {
                const columnValuesFilter = [{
                    field: 'EmployeeId',
                    values: [dataItem.EmployeeId]
                }]
                const {startDate, endDate} = this.props
                const complexGridFilters: IGridFilter[] = [
                    {
                        "logic": "and",
                        "filters": [
                            {
                                "field": "Date",
                                "operator": "gte",
                                "value": moment(startDate).toDate()
                            },
                            {
                                "field": "Date",
                                "operator": "lte",
                                "value": moment(endDate).toDate()
                            }]
                    },
                ]
                this.props.switchTab('PPTimeCards', {}, columnValuesFilter, complexGridFilters)
            }
        }

        return <div style={{textAlign: 'center'}}>
            <ButtonLink
                text={value}
                onClick={onClick}
            />
        </div>
    }

    PrepareData = (result: any) => {
        const columns: Array<IColumnSetting> = [
            {
                field: "RowNum",
                title: "#",
                type: "number",
                gridWidth: 40,
                filterable: false,
                columnMenu: false,
                aggregate: "count",
            },
            {
                field: 'ClassName',
                title: 'Class',
                type: 'string',
                format: {
                    type: 'link',
                    refName: 'Classes',
                    fieldId: 'ClassId'
                },
                gridWidth: 180,
            },
            {
                field: "EmployeeName",
                title: "Employee",
                type: "string",
                gridWidth: 200,
                format: {
                    type: "link",
                    fieldId: "EmployeeId",
                    refName: "Employees",
                },
            },
            {
                title: '# of Time Cards',
                field: 'TimeCardNumber',
                type: "number",
                aggregate: 'sum',
                format: {
                    type: "integer",
                    customFormat: true,
                    getCustomFormat: this.renderTCLinkCell,
                },
            },
        ]

        const payrollPeriods: Array<{
            PayrollItemId: number
            PayrollItemName: string
        }> = result[0]
        const data = result[1]
        const dinamicColumns: Array<{
            title: string
            field: string
            order: number
        }> = []
        const unicColumnFields: { [key: string]: boolean } = {};

        for (let item of data) {
            for (let field in item) {
                if (!field.startsWith('pi') || !field.endsWith('Hours')) continue
                const [, id,] = field.split('_')
                const payrollItemIndex = payrollPeriods.findIndex((pp) => +pp.PayrollItemId === +id)
                const payrollItem = payrollPeriods[payrollItemIndex]
                if (!payrollItem) continue
                const columnTitle = payrollItem.PayrollItemName
                const newField = columnTitle.split(' ').join('')
                item[newField] = item[field]

                if (!unicColumnFields[newField]) {
                    unicColumnFields[newField] = true
                    dinamicColumns.push({
                        title: columnTitle,
                        field: newField,
                        order: payrollItemIndex
                    })
                }
            }
        }

        dinamicColumns.sort((a, b) => a.order - b.order)
        for (let col of dinamicColumns) {
            columns.push({
                field: col.field,
                title: col.title,
                type: "number",
                gridWidth: 130,
                format: {
                    type: "number",
                    customFormat: true,
                    getCustomFormat: (dataItem: simpleObject, field: string) => this.renderLinkCell(dataItem[field], col.title, dataItem.EmployeeId),
                    getCustomTotalFormat: (data: simpleObject[], field: string, value: number) => this.renderLinkCell(value, col.title),
                },
                aggregate: 'sum'
            })
        }
        columns.push({
            field: "TotalHours",
            title: "Total Hours",
            type: "number",
            gridWidth: 130,
            format: {
                type: "number",
                customFormat: true,
                getCustomFormat: (dataItem: simpleObject, field: string) => this.renderLinkCell(dataItem[field], undefined, dataItem.EmployeeId),
            },
            aggregate: 'sum',
        })
        this.setState({columns})
        return data
    }

    Refresh = () => {
    }

    InitRefresh = (refresh: any) => {
        this.Refresh = refresh
    }
}


export default PayrollSummary
