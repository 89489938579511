import {Component} from 'react';
import FXCard from '../Common/FXCard/FXCard';
import {IFXCardProps} from './interfaces';
import CheckListResults
  from '../../Pages/CLM/ChecklistResults/ChecklistResults';

interface props extends IFXCardProps {
  buildPlanId: number
  workOrderId?: number
}

class ClmReview extends Component<props> {

  render() {
    return (<FXCard
      title={'Review Interface'}
      onClose={this.props.finally}
    >
      <CheckListResults
        buildPlanId={this.props.buildPlanId}
        workOrderId={this.props.workOrderId}
        isActive={true}
        pageId={'modal'}
      />
    </FXCard>);
  }
}

export default ClmReview;
