import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'
import {
  ExcelExport,
  ExcelExportColumn
} from '@progress/kendo-react-excel-export'
import BaseComponent from '../BaseComponent'
import {orderBy} from '@progress/kendo-data-query'

import {ISortGridItem, simpleObject} from '../../helpers/interfaces'
import {GridRowHeight} from './helpers'
import styles from './dashboard.module.scss'


interface props {
  gridData: Array<simpleObject>
  columns: Array<simpleObject>
  toolbar: any
  excelFileName: string

  initModeExportRef(ref: any): void
}

interface state {
  sort: Array<ISortGridItem>
}

class DashboardModeGrid extends BaseComponent<props, state> {
  modeExport: any

  constructor(props: props) {
    super(props)
    this.state = {
      sort: []
    }
  }

  render() {
    let gridData = orderBy(this.props.gridData, this.state.sort)
    return (
      <>
        <Grid
          className={`${styles.Grid} ${styles.GroupStatusDashboardGrid}`}
          style={{width: '100%', height: '100%', flex: '0 0 auto'}}
          data={gridData}
          rowHeight={GridRowHeight}
          onSortChange={this.OnSort}
          sortable={{mode: 'multiple'}}
          sort={this.state.sort}
          headerCellRender={(el: any, props: any) => {
            let field = props.field
            let column = this.props.columns.find((column) => column.field === field)
            let icon = el.props.children[0].props.children[1]
            return <span className="k-cell-inner" onClick={props.onClick}>
              <span className="k-link">
                <span
                  className="k-column-title">{column?.titleHTML || column?.title}</span>
                {icon}
              </span>
            </span>
          }}
        >
          {this.props.toolbar}
          {this.props.columns.map((column) => {
            return <Column
              key={column.field}
              title={column.title}
              field={column.field}
              width={column.width}
              footerCell={column.renderFooterCell ? (props: any) => column.renderFooterCell(props, gridData) : undefined}
              cell={column.renderCell}
              headerClassName={column.headerClassName}
            />
          })}
        </Grid>
        <ExcelExport
          data={gridData}
          ref={this.props.initModeExportRef}
          fileName={this.props.excelFileName}
        >
          {this.props.columns.map((column) => {
            return (<ExcelExportColumn
              key={column.title}
              field={column.field}
              title={column.title}
              width={column.gridWidth}
              headerCellOptions={{wrap: true}}
              locked={column.locked}
            />)
          })}
        </ExcelExport>
      </>
    )
  }

  OnSort = (e: GridSortChangeEvent) => {
    this.setState({sort: e.sort})
  }

}

export default DashboardModeGrid
