import React, {useEffect, useRef, useState} from 'react';
import {Button} from '@progress/kendo-react-buttons';
import styles from './map.module.scss'
import {ILoadOtherNodesParams, IOtherNodePoint, nodeType} from './interfaces';
import {
    BindNodeTooltip,
    fitBoundsGroup,
    GetAllSiteDetailsHtml,
    getFigureObjectLayer,
    getObjectMapData,
    MARKERS_COLORS,
    OpenObject
} from "./helpers";
import {getSQLData} from "../../helpers/queries";
import {Notification, NotificationEvent} from "@progress/kendo-react-notification";
import {ModalRef} from "../Common/Modal/Modal";

interface IProps {
    map: any
    className: string
}

const MAX_NODES_TO_SHOW = 1000
const COLORS: { [key in nodeType]: string } = {
    Location: MARKERS_COLORS.AQUA,
    Site: MARKERS_COLORS.PINK,
    IncompleteWOSite: MARKERS_COLORS.ORANGE
}
const renderSettings: {
    typeId: nodeType,
    text: string,
    className: string
}[] = [
    {
        typeId: 'Location',
        text: 'Nearby Locations',
        className: styles.LocationsGroup
    },
    {
        typeId: 'Site',
        text: 'Nearby Sites (All)',
        className: styles.SitesGroup
    },
    {
        typeId: 'IncompleteWOSite',
        text: 'Nearby Sites (Incomplete WOs)',
        className: styles.AllSitesGroup
    }
]

const OtherNodesControl = (props: IProps) => {
    const {map} = props
    const lastBounds = useRef<{
        [key in nodeType]?: ILoadOtherNodesParams
    }>({})
    const [lastMapChanged, setLastMapChanged] = useState<number>(+new Date())

    const [isLocationsShown, setLocationsState] = useState<boolean>(false)
    const [isLoadingLocations, setIsLoadingLocations] = useState<boolean>(false)
    const [isSitesShown, setSitesState] = useState<boolean>(false)
    const [isLoadingSites, setIsLoadingSites] = useState<boolean>(false)
    const [isIncSitesShown, setIncSitesState] = useState<boolean>(false)
    const [isLoadingIncSites, setIsLoadingIncSites] = useState<boolean>(false)
    const [notifications, setNotifications] = useState<{
        [key in nodeType]?: {
            text: string
            timer: any
        }
    }>({})
    const lastLoadedDataInfo = useRef<{
        [key in nodeType]: {
        time: number
        layers: any[]
        popups: { [siteId: number]: any /*html*/ }
    } | null
    }>({
        'Location': null,
        'Site': null,
        'IncompleteWOSite': null
    })
    const clusterRef = useRef<any>(window.L.markerClusterGroup({
        // zoomToBoundsOnClick: false,
        iconCreateFunction: function (cluster: any) {
            const childCount = cluster.getChildCount();
            return new window.L.DivIcon({
                className: 'marker-cluster-other',
                iconSize: [20, 20],
                iconAnchor: [10, 20],
                html: '<span>' + childCount + '</span>',
            });
        }
    }));

    useEffect(() => {
        if (map) {
            map.addLayer(clusterRef.current);
            map.on("dragend", onMapChange);
            map.on("zoomend", onMapChange);
        }

        return () => {
            if (map) {
                map.off("dragend", onMapChange);
                map.off("zoomend", onMapChange);
            }
        }
    }, [map])

    const onMapChange = () => setLastMapChanged(+new Date())

    const onShowNodes = async (e: any) => {
        const [type]: [nodeType, string] = e.currentTarget.id.split('_')
        showNodes(type)
    }

    const showNodes = async (type: nodeType, prevBounds?: boolean) => {
        if (type === 'Site') {
            setSitesState(true)
            setIsLoadingSites(true)
        } else if (type === 'Location') {
            setIsLoadingLocations(true)
            setLocationsState(true)
        } else {
            setIsLoadingIncSites(true)
            setIncSitesState(true)
        }
        removeNodesFromMap(type);
        const result = await loadNodes(type, prevBounds ? lastBounds.current[type] : undefined)
        lastLoadedDataInfo.current[type] = {
            time: +new Date(),
            layers: [],
            popups: {},
        }
        if (notifications[type]?.timer) clearTimeout(notifications[type]!.timer)
        const nodes = result[0]
        if (nodes.length >= MAX_NODES_TO_SHOW) {
            nodes.length = MAX_NODES_TO_SHOW
            setNotifications({
                ...notifications,
                [type]: {
                    text: `First ${nodes.length} ${type === 'Location' ? 'Locations' : 'Sites'} are shown`,
                    timer: setTimeout(() => {
                        hideNotification(type)
                    }, 3000)
                }
            })
        }
        drawNodes(nodes, type);
        if (type === 'Site') {
            setIsLoadingSites(false)
        } else if (type === 'Location') {
            setIsLoadingLocations(false)
        } else {
            setIsLoadingIncSites(false)
        }
    }

    const hideNodes = (e: any) => {
        const [type]: [nodeType, string] = e.currentTarget.id.split('_')
        if (type === 'Location') setLocationsState(false)
        else if (type === 'Site') setSitesState(false)
        else setIncSitesState(false)
        removeNodesFromMap(type)
    }

    const removeNodesFromMap = (type: nodeType) => {
        const layers = lastLoadedDataInfo.current[type]?.layers
        if (layers) {
            for (let layer of layers) {
                clusterRef.current.removeLayer(layer)
                layer.removeFrom(map)
            }
        }
        lastLoadedDataInfo.current[type] = null
    }

    const loadNodes = async (type: nodeType, defaultParams?: ILoadOtherNodesParams) => {
        const getParams = (type: nodeType) => {
            const bounds = map.getBounds();
            return {
                clientRectTopLat: bounds._northEast.lat,
                clientRectBottomLat: bounds._southWest.lat,
                clientRectLeftLng: bounds._southWest.lng,
                clientRectRightLng: bounds._northEast.lng,
                objectType: type,
            }
        }

        const params: ILoadOtherNodesParams = defaultParams || getParams(type)
        lastBounds.current[type] = params

        return await getSQLData({
                spName: "GetMapAllAddresses",
                params,
            }
        )
    }

    const onSaveObject = async (objectId: number, objectType: nodeType) => {
        try {
            ModalRef.startProcessing('', 'rgba(255, 255, 255, 0.2)')
            const info = lastLoadedDataInfo.current[objectType]
            if (!info) return
            const layers = info.layers.filter((layer) => +layer.options.objectId !== objectId)
            const objectLayers = info.layers.filter((layer) => +layer.options.objectId === objectId)
            for (let layer of objectLayers) {
                layer.removeFrom(map)
                clusterRef.current.removeLayer(layer)
            }

            const {mainAddress} = await getObjectMapData(objectId)
            const {Lat, Lng, ObjectId, ObjectName, LocationColor, AddressString, Boundaries, Radius} = mainAddress
            if (!Lat || !Lng) return

            const coords = [Lat, Lng]
            const MarkerLayer = getMarkerLayer(ObjectId, coords, objectType, LocationColor)
            BindNodeTooltip(MarkerLayer, ObjectName, AddressString)
            const FigureLayer = getFigureObjectLayer(
                ObjectId, objectType,
                LocationColor || COLORS[objectType], "black",
                coords, Boundaries, Radius
            );
            if (objectType === "IncompleteWOSite") {
                bindPopupForIncompleteWOSiteMarker(MarkerLayer, info.popups[ObjectId])
            }
            MarkerLayer.addTo(map);
            FigureLayer.addTo(map);
            clusterRef.current.addLayer(MarkerLayer)
            layers.push(MarkerLayer)
            layers.push(FigureLayer)
            info.layers = layers

            fitBoundsGroup(new window.L.FeatureGroup([MarkerLayer, FigureLayer]), map)
        } finally {
            ModalRef.stopProcessing()
        }
    }

    const OpenNodeCard = (e: any) => {
        const markerLayerOptions = e.target.options
        let {refName, objectId, objectType} = markerLayerOptions;
        if (!objectId || !refName) return
        OpenObject(e, (objectId: number) => onSaveObject(objectId, objectType as nodeType))
    }

    const getMarkerHTML = (primaryColor: string) => {
        return `<div class="" style="box-sizing: border-box; width: 20px; height: 20px; border-radius: 50%; border: 3px solid ${primaryColor}; background: ${MARKERS_COLORS.WHITE};"></div>`
    }

    const getMarkerLayer = (objectId: number, coords: number[], objectType: nodeType, color?: string | null) => {
        const isSites = objectType !== 'Location'
        return window.L.marker(
            coords,
            {
                icon: window.L.divIcon({
                    className: "-icon-box",
                    iconSize: [20, 20],
                    iconAnchor: [10, 20],
                    html: getMarkerHTML(color || COLORS[objectType]),
                }),
                riseOnHover: true,
                refName: isSites ? "FSMSites" : "Locations",
                objectId,
                objectType,
            })
            .on("contextmenu", OpenNodeCard);
    }

    const bindPopupForIncompleteWOSiteMarker = (MarkerLayer: any, html: any) => {
        MarkerLayer.bindPopup(
            html,
            {
                autoClose: false,
                closeButton: false,
                className: 'leaflet-scroll-popup'
            }
        );
    }

    const drawNodes = (points: Array<IOtherNodePoint>, objectType: nodeType) => {
        const info = lastLoadedDataInfo.current[objectType]!
        const unicPoints: {
            [key: number]: {
                data: Array<IOtherNodePoint>;
                addedToMap: boolean;
            };
        } = {};
        if (objectType === "IncompleteWOSite") {
            for (let point of points) {
                if (!unicPoints[point.ObjectId]) {
                    unicPoints[point.ObjectId] = {data: [], addedToMap: false};
                }
                unicPoints[point.ObjectId].data.push(point);
            }
        }

        for (let point of points) {
            const details = unicPoints[point.ObjectId];
            if (details?.addedToMap) continue;
            const {ObjectId, ObjectName, Address, GeoFenceRadius, Boundaries} = point
            const coords = [point.Lat, point.Lng];
            const FigureLayer = getFigureObjectLayer(
                ObjectId, objectType,
                point.Color || COLORS[objectType], "black",
                coords, Boundaries, GeoFenceRadius,
            );
            const MarkerLayer = getMarkerLayer(ObjectId, coords, objectType, point.Color)
            BindNodeTooltip(MarkerLayer, ObjectName, Address)

            if (objectType === "IncompleteWOSite") {
                details.addedToMap = true;
                let detailsHtml = "";
                details.data.forEach((item) => {
                    detailsHtml += GetAllSiteDetailsHtml(item);
                });
                info.popups[ObjectId] = "<div class='leaflet-scroll-popup-content' style='max-height: 450px;'>" + detailsHtml + "</div>"
                bindPopupForIncompleteWOSiteMarker(MarkerLayer, info.popups[ObjectId])
            }
            MarkerLayer.addTo(map);
            FigureLayer.addTo(map);
            clusterRef.current.addLayer(MarkerLayer)
            info.layers.push(MarkerLayer)
            info.layers.push(FigureLayer)
        }
    };

    const isMarkBtn = (type: nodeType) => {
        const info = lastLoadedDataInfo.current[type]
        return info !== null && info.time < lastMapChanged
    }

    const onCloseNotification = (e: NotificationEvent) => {
        // @ts-ignore
        const [typeId] = e.target.props.id.split('_') as nodeType
        hideNotification(typeId)
    }

    const hideNotification = (typeId: nodeType) => {
        clearTimeout(notifications[typeId]?.timer)
        delete notifications[typeId]
        setNotifications({...notifications})
    }

    return <div className={`${props.className} ${styles.Controls}`}>
        {renderSettings.map(({typeId, text, className}) => {
            const isNodesShown = typeId === 'Location' ? isLocationsShown : typeId === 'Site' ? isSitesShown : isIncSitesShown;
            const isDataLoading = typeId === 'Location' ? isLoadingLocations : typeId === 'Site' ? isLoadingSites : isLoadingIncSites;

            return <div className={`${styles.GroupBtns} ${className}`} key={typeId}>
                {!isNodesShown ?
                    <Button
                        id={`${typeId}_Show`}
                        iconClass="mdi mdi-map-marker-outline"
                        title={`Show ${text}`}
                        onClick={onShowNodes}
                    /> : <>
                        <Button
                            id={`${typeId}_Refresh`}
                            className={`${isDataLoading ? styles.InProcess : ''} ${isMarkBtn(typeId) ? styles.NotActualBtn : ''}`}
                            iconClass="mdi mdi-refresh"
                            title={`Refresh ${text}`}
                            onClick={onShowNodes}
                            disabled={isDataLoading}
                        />
                        <Button
                            iconClass="mdi mdi-eye-off-outline"
                            title={`Hide ${text}`}
                            id={`${typeId}_Hide`}
                            onClick={hideNodes}
                        />
                        {!!notifications[typeId] &&
                            <Notification
                                className={styles.OtherNodesNotification}
                                type={{style: "info", icon: false}}
                                closable={true}
                                onClose={onCloseNotification}
                                // @ts-ignore
                                id={typeId + '_notification'}
                            >
                                {notifications[typeId]?.text}
                            </Notification>
                        }
                    </>
                }
            </div>
        })}
    </div>
}


export default OtherNodesControl