import {Component} from 'react';
import axios from 'axios';
import {getSQLData} from '../helpers/queries';
import {IGetSQLDataParams} from '../helpers/interfaces';

export default class BaseComponent<T = {}, S = {}, SS = {}> extends Component<T, S, SS> {
  cancelToken: any = axios.CancelToken.source();

  constructor(props: T) {
    super(props);
  }

  componentWillUnmount() {
    this.CancelPrevRequestUpdateToken();
  }

  GetSQLData = async (params: IGetSQLDataParams) => {
    return await getSQLData.call(this, params);
  };

  CancelPrevRequestUpdateToken = () => {
    if (this.cancelToken) {
      this.cancelToken.cancel();
      this.cancelToken = axios.CancelToken.source();
    }
  };

}
