import {Component} from 'react'
import {Button, ButtonGroup, Toolbar} from '@progress/kendo-react-buttons'
import {ITabSetting, tabId} from './interfaces'
import {GetTabId, GetTabName, TABS} from './helpers'
import styles from './tabs.module.scss'
import {simpleObject} from '../../helpers/interfaces'
import {gridChangeDataAction, IDashboardChildProps} from '../Dashboard/interfaces'
import dashboardStyles from '../Dashboard/dashboard.module.scss'

const WIPDashboardTABS: Array<ITabSetting | tabId> = [
    'Budget',
    'PL',
    'CIM',
    'VIM',
    'Milestones',
    'BOM',
    'Documents',
    'Labor',
]

const LaborDashboardTABS: Array<ITabSetting | tabId> = [
    'Daily',
    'Labor',
    {
        id: 'Budget',
        props: {isLabor: true}
    },
    'Tasks',
    {
        id: 'Map',
        props: {
            mode: 'bp',
            prefix: 'labor',
            isActive: true, // refill
            bpId: 0, // refill
        },
    },
    'WorkOrders',
    {
        id: 'Calendar',
        props: {
            initOnReady: true,
        },
    },
]

interface state {
    activeTabId: tabId
    isChildFiltersInDefault: boolean
}

class BPTabs extends Component<IDashboardChildProps, state> {
    initedTabs: Array<tabId> = []
    initialTab: tabId
    buildPlanId?: number | null
    tabs: Array<ITabSetting | tabId> = []

    tabsProps: {
        [key in tabId]?: any
    } = {}
    tabsRefs: {
        [key in tabId]?: any
    } = {}
    isCLPM = this.props.parentId === 'CLPM'
    buildPlanNotRequired = this.props.parentId === 'PLDashboard' || this.isCLPM

    constructor(props: IDashboardChildProps) {
        super(props)
        if (this.props.props?.tabs) {
            this.tabs = this.props.props.tabs
        } else if (this.props.parentId === 'LaborDashboard') {
            this.tabs = LaborDashboardTABS
        } else if (this.props.parentId === 'WIPDashboard') {
            this.tabs = WIPDashboardTABS
        }
        this.initialTab = GetTabId(this.props.initialTab || this.tabs[0])

        this.initedTabs.push(this.initialTab)
        this.buildPlanId = this.GetPropsBPId()
        if (this.props.initialTab && typeof this.props.initialTab === "object") {
            this.tabsProps[this.initialTab] = {...this.props.initialTab}
            delete this.tabsProps[this.initialTab].id
        } else if (this.IsIframe(this.initialTab)) {
            this.tabsProps[this.initialTab] = this.GetIframesProps(this.initialTab)
        }
        this.state = {
            activeTabId: this.initialTab,
            isChildFiltersInDefault: true
        }
    }

    componentDidUpdate(prevProps: IDashboardChildProps) {
        let bpId = this.GetPropsBPId()
        if (bpId && this.GetBPID(prevProps) !== bpId) {
            this.buildPlanId = bpId
            let activeTabId = this.state.activeTabId
            if (this.IsIframe(activeTabId) && bpId) {
                let iframesProps = this.tabsProps
                iframesProps[activeTabId] = this.GetIframesProps(activeTabId)
            }
        }
        if (
            this.isCLPM &&
            this.state.activeTabId === 'Calendar' &&
            localStorage.getItem('calendarViewName_clm') === 'map' &&
            !prevProps.isActive && this.props.isActive
        ) {
            let refCalendar = this.tabsRefs.Calendar
            let refWindow = refCalendar && refCalendar.iframeRef && refCalendar.iframeRef.contentWindow
            if (refWindow && refWindow.liveMapPortalRestore) {
                refWindow.liveMapPortalRestore()
            }
        }
    }

    shouldComponentUpdate(nextProps: IDashboardChildProps, nextState: state) {
        if (!this.buildPlanNotRequired && !this.GetBPID(nextProps.rowData) ||
            !nextProps.isActive && !this.props.isActive
        ) {
            return false
        }
        return true
    }

    render() {
        let activeTabId = this.state.activeTabId
        let bpId = this.GetPropsBPId()
        let toolbarStyle = !this.buildPlanNotRequired && !bpId ? {display: 'none'} : undefined
        let hasProps = bpId || this.buildPlanNotRequired
        let activetab = TABS[activeTabId]
        let activeTabRef = this.tabsRefs[activeTabId]
        let hasExcelExport = !!activetab.hasExportToExcel
        let hasSetDefaultFilters = activeTabId === 'PL' ? !!activeTabRef?.HasSetdefaultsFilters() : !!activetab.hasSetDefaultFilters
        let IsFiltersInDefault = true
        if (hasSetDefaultFilters) {
            let IsFiltersInDefaultFunc = undefined
            if (activeTabId === 'Milestones') IsFiltersInDefaultFunc = activeTabRef?.IsFiltersInDefault
            else if (activeTabId === 'PL') {
                IsFiltersInDefaultFunc = activeTabRef.detailsRef.current?.gridRef.current?.IsFiltersInDefault
            } else {
                IsFiltersInDefaultFunc = activeTabRef?.IsFiltersInDefault || activeTabRef?.gridRef?.current?.IsFiltersInDefault
            }
            if (!!IsFiltersInDefaultFunc) IsFiltersInDefault = IsFiltersInDefaultFunc()
        }
        return (
            <>
                <Toolbar className={styles.Toolbar} style={toolbarStyle}>
                    <ButtonGroup className={styles.ButtonGroup}>
                        {this.tabs.map((tab) => {
                            let tabId = GetTabId(tab)
                            return (
                                <Button
                                    key={tabId}
                                    data-id={tabId}
                                    themeColor={this.state.activeTabId === tabId ? 'primary' : undefined}
                                    onClick={this.OnChange}
                                >
                                    {GetTabName(tab)}
                                </Button>
                            )
                        })}
                    </ButtonGroup>
                    <div className={styles.ToolbarRight}>
                        {this.props.props?.toolbarRight}
                        {hasSetDefaultFilters && <Button
                            onClick={this.SetDefaultSettings}
                            title="Set Default Filters"
                            icon="filter-clear"
                            className={IsFiltersInDefault ? '' : dashboardStyles.BlueResetBtn}
                        />}
                        {hasExcelExport && <Button icon="file-excel" title="Export to Excel"
                                                   onClick={this.ExportToExcel}/>}
                        <Button icon='refresh' onClick={this.RefreshActiveTab}
                                disabled={activeTabId === 'PLDetails' && this.props.parentId === 'PLDashboard'}/>
                        {!!this.props.unSelectRow &&
                            <Button icon='close' style={{color: 'red'}}
                                    onClick={this.props.unSelectRow}/>}
                    </div>
                </Toolbar>
                {this.tabs.map((tab) => {
                    let tabId = GetTabId(tab)
                    let isIframe = this.IsIframe(tabId)
                    let isActiveTabId = tabId === this.state.activeTabId
                    let isInit = this.initedTabs.indexOf(tabId) > -1
                    let buildPlanId = bpId
                    let tabSetting = TABS[tabId]
                    let TabComponent = tabSetting.component
                    if (!isActiveTabId && !isInit) return null
                    let props: simpleObject = this.tabsProps[tabId] || {}
                    let key = tabId
                    if (!isIframe && hasProps) {
                        if (typeof tab === 'object' && tab.props) props = {...tab.props, ...props}
                        if (tabId === 'Map') {
                            if (this.props.parentId === 'CLPM') {
                                props = typeof tab === 'object' && tab.props || {}
                            }
                            if (this.props.parentId !== 'CLPM') {
                                props.bpId = buildPlanId || undefined
                            }
                        } else if (buildPlanId) {
                            props.buildPlanId = buildPlanId
                        }
                    }
                    if (tabId === 'Calendar' || tabId === 'ChecklistResults') {
                        props.buildPlanId = buildPlanId // if status dashboard
                    }
                    if (tabId === 'Calendar') {
                        props.id = this.isCLPM ? 'clm' : 'bptab'
                    }
                    props.isActive = this.props.isActive && isActiveTabId
                    if (tabId !== 'Map') props.doNotSaveFilters = this.props.props?.doNotSaveFilters
                    let id = tabId === 'Calendar' ? `Calendar_${props.id}` : ''
                    if (this.props.parentId && tabId !== 'Map') props.pageId = this.props.parentId
                    if (tabSetting.hasSetDefaultFilters || tabId === 'PL') {
                        props.onChangeGridData = this.onChangeChildGridData
                    }
                    return (
                        <div
                            key={key}
                            id={id}
                            className={`${styles.ContentWrap} ${!isActiveTabId ? styles.HiddenTab : ''}`}
                        >
                            <div className={styles.Content}>
                                <TabComponent
                                    {...props}
                                    ref={(ref: any) => {
                                        if (!this.tabsRefs[tabId] || this.tabsRefs[tabId] !== ref) {
                                            this.tabsRefs[tabId] = ref
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )
                })}
            </>
        )
    }

    onChangeChildGridData = (data: Array<simpleObject>, action: gridChangeDataAction) => {
        if (action === 'filterChanged' || action === 'forceRestoreSettings' || action === 'dataLoaded') this.forceUpdate()
    }

    GetPropsBPId = () => {
        return this.GetBPID(this.props.rowData)
    }

    GetBPID = (rowData: simpleObject | null) => {
        if (!rowData) return null
        return rowData.BPID || rowData.BPId || rowData.bpId || rowData.buildPlanId
    }

    IsIframe = (tabId: tabId) => {
        return tabId === 'Calendar' || tabId === 'SimplifiedCalendar'
    }

    GetIframesProps = (tabId: tabId) => {
        let tab = this.tabs.find((tab) => GetTabId(tab) === tabId)
        let props: any = typeof tab === 'object' && tab?.props ? tab.props : {}
        let buildPlanId = this.buildPlanId
        if (buildPlanId) props.buildPlanId = buildPlanId
        return JSON.parse(JSON.stringify(props))
    }

    OnChange = (e: any) => {
        let tabId: tabId = e.currentTarget.dataset.id
        let activeTabId = this.state.activeTabId
        if (activeTabId === tabId) return
        if (this.isCLPM && activeTabId === 'Calendar' && localStorage.getItem('calendarViewName_clm') === 'map') {
            window.helpers.liveMapPortalRemove()
        }
        if (this.initedTabs.indexOf(tabId) === -1) this.initedTabs.push(tabId)
        this.SetActiveTabAndProps(tabId)
        if (this.isCLPM) {
            this.RefreshClmTab(tabId)
        }
    }

    RefreshActiveTab = () => {
        let active = this.state.activeTabId
        if (this.tabsRefs[active]) {
            if (this.tabsRefs[active].Refresh) this.tabsRefs[active].Refresh()
        }
    }

    ExportToExcel = () => {
        let activeTabId = this.state.activeTabId
        let exportFunc = this.tabsRefs[activeTabId]?.ExportToExcel || this.tabsRefs[activeTabId]?.gridRef?.current?.ExportToExcel
        if (exportFunc) exportFunc()
    }

    SetDefaultSettings = () => {
        let activeTabId = this.state.activeTabId
        let activeTabRef = this.tabsRefs[activeTabId]
        let SetDefaultSettingsFunc = undefined
        if (activeTabId === 'Milestones') {
            SetDefaultSettingsFunc = activeTabRef?.ClearFilters
        } else if (activeTabId === 'PL') {
            SetDefaultSettingsFunc = activeTabRef.detailsRef.current?.gridRef.current?.SetDefaultSettings
        } else {
            SetDefaultSettingsFunc = activeTabRef.SetDefaultSettings || activeTabRef?.gridRef?.current?.SetDefaultSettings
        }
        if (SetDefaultSettingsFunc) SetDefaultSettingsFunc()
    }

    RefreshClmTab = (tabId: tabId) => { // for sharing filters
        if (tabId === 'Map' || tabId === 'Documents' || tabId === 'ReviewInterface' || tabId === 'StatusDashboard') {
            if (this.tabsRefs[tabId] && this.tabsRefs[tabId].RefreshOnActivateCLMTab) {
                this.tabsRefs[tabId].RefreshOnActivateCLMTab()
            }
        }
    }

    RouteToCLMReviewInterface = (workOrderId: number, buildPlanId: number) => {
        let activeTabId: tabId = 'ReviewInterface'
        let props = {buildPlanId, workOrderId}
        this.SetActiveTabAndProps(activeTabId, props)
    }


    SetActiveTabAndProps = (activeTabId: tabId, props?: simpleObject) => { // ??
        let tabProps = props || (this.IsIframe(activeTabId) && this.GetIframesProps(activeTabId))
        if (tabProps) {
            let iframesProps = this.tabsProps
            iframesProps[activeTabId] = props
        }
        this.setState({activeTabId})
    }

}

export default BPTabs
