const
    Red = '#e6194B',
    Orange = '#f58231',
    Yellow = '#ffe119',
    Lime = '#bfef45',
    Cyan = '#42d4f4',
    Blue = '#4363d8',
    Purple = '#911eb4',
    Magenta = '#f032e6';

export const DEVICES_COLORS = [
    Cyan,
    Purple,
    Magenta,
    Orange,
    Yellow,
    Red,
    Lime,
    Blue,
];

export const POLYLINE_WEIGHT = 3;
export const MARK_POLYLINE_WEIGHT = 6;
export const stopAnimatedClass = 'last-stop-animated';
export const siteAnimatedClass = 'site-animated';
export const getClockInOutPeriodId = (clockInId: number, isClockIn: boolean) => `${isClockIn ? 'clockIn' : 'clockOut'}_${clockInId}`
export const getPeriodIdForFilter = (periodId: number | string, clockInId: number) => `${periodId}_${clockInId}`
export const SHOW_LOGS_DIV_CLASS_NAME = 'device-logs-div'
export const HEIGHT_TIME_ENTRY = 30

export const animateMarker = (layer: any, isMouseOver: boolean, zIndex: number) => {
    const classList = layer?._icon?.classList
    if (classList) {
        if (isMouseOver) {
            layer._icon.classList.add(siteAnimatedClass)
            layer.setZIndexOffset(zIndex);
        } else {
            layer._icon.classList.remove(siteAnimatedClass)
            layer.setZIndexOffset(zIndex);
        }
    }
}

