import {Component} from 'react';
import styles from './ExpandedPanel.module.scss';

interface props {
  className?: string
  expanded: boolean
  content: string | any
  level: 1 | 2
  dataText?: string
  dataId?: string | number
  arrowItem?: any

  onToggle(id?: string): void

  isAllowToToggle?(e: any): boolean
}

interface state {
  expanded: boolean
}

class ExpandedPanel extends Component<props, state> {
  levelIcons = {
    1: {
      expand: 'arrow-60-up',
      collapse: 'arrow-60-down'
    },
    2: {
      expand: 'arrow-chevron-up',
      collapse: 'arrow-chevron-down'
    }
  }

  constructor(props: any) {
    super(props);
    this.state = {
      expanded: this.props.expanded
    }
  }

  componentDidUpdate(prevProps: props) {
    if (prevProps.expanded !== this.props.expanded && this.props.expanded !== this.state.expanded) {
      this.setState({expanded: this.props.expanded});
    }
  }

  render() {
    let icon = this.state.expanded ? this.levelIcons[this.props.level].expand : this.levelIcons[this.props.level].collapse;
    return (
      <div
        className={`${styles.Header} ${this.props.className || ''}`}
        data-level={this.props.level}
        data-text={this.props.dataText}
        data-id={this.props.dataId}
        onClick={this.OnClick}
      >
        <span>{this.props.content}</span>
        {this.props.arrowItem === undefined ?
          <span className={"k-icon k-i-" + icon}></span> : this.props.arrowItem}
      </div>
    )
  }

  OnClick = (e: any) => {
    if (this.props.isAllowToToggle === undefined || this.props.isAllowToToggle(e)) this.props.onToggle(this.props.dataId ? this.props.dataId + '' : undefined)
  }
}

export default ExpandedPanel;
