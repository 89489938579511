import axios from 'axios';
import PQueue from 'p-queue/dist';

import {currentUserId, DataServerHost, isConterra} from './settings';
import {IAuthData} from './interfaces';
import {getParamsFromUrl} from './helpers'
import {AuthRef} from '../Components/Auth';
import {ModalRef} from '../Components/Common/Modal/Modal';
import {RunScript, RunScriptLocalAsync} from './runscripts';
import FileAccessToken from './fileAccessToken';

type tokenStr = string | undefined;
type tokenNumber = number | undefined;

class AuthData {
    token_type: tokenStr;
    access_token: tokenStr;
    refresh_token: tokenStr;
    currentUserId: tokenNumber;
    token_expired_at: tokenNumber;

    queue = new PQueue({concurrency: 1});

    storeTokens(data: IAuthData) {
        this.token_type = data.token_type;
        this.access_token = data.access_token;
        this.refresh_token = data.refresh_token;

        window.sessionStorage.fxTokenType = data.token_type
        window.sessionStorage.fxAccessToken = data.access_token;
        if (data.refresh_token) window.sessionStorage.fxRefreshToken = data.refresh_token;

        let token_expired_at = new Date();
        this.token_expired_at = token_expired_at.setSeconds(
            token_expired_at.getSeconds() + data.expires_in - 30
        ).valueOf();
        window.sessionStorage.fxTokenExpiredAt = this.token_expired_at;
        document.cookie = `${encodeURIComponent('FX-Auth')}=${encodeURIComponent(data.access_token)};path=/`;

        setTimeout(this.queueGetAuthToken, (data.expires_in - 20) * 1000);
        window.sessionStorage.fxUserId = +(this.currentUserId || 0);
    }

    restoreSavedTokens() {
        let token_expired_at = +window.sessionStorage.fxTokenExpiredAt;

        this.token_expired_at = token_expired_at ? new Date(token_expired_at).valueOf() : undefined;
        this.access_token = window.sessionStorage.fxAccessToken;
        this.token_type = window.sessionStorage.fxTokenType; // ??

        if (getParamsFromUrl().refreshToken) {
            this.refresh_token = getParamsFromUrl().refreshToken;
            window.sessionStorage.fxRefreshToken = this.refresh_token
            window.history.pushState({}, document.title, this.removeParamFromURL('refreshToken', window.location.href));
        } else {
            this.refresh_token = window.sessionStorage.fxRefreshToken;
        }
        let tokenUserId: any = +window.sessionStorage.fxUserId;
        tokenUserId = isNaN(tokenUserId) ? 0 : tokenUserId;
        if (+tokenUserId !== +(currentUserId || 0)) {
            this.access_token = undefined;
            this.refresh_token = undefined;
        }
    }


    removeParamFromURL = (key: string, sourceURL: string) => {
        var rtn = sourceURL.split("?")[0],
            param,
            params_arr = [],
            queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
        if (queryString !== "") {
            params_arr = queryString.split("&");
            for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                param = params_arr[i].split("=")[0];
                if (param === key) {
                    params_arr.splice(i, 1);
                }
            }
            if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
        }
        return rtn;
    }

    getAuthToken = async (force?: boolean) => {
        this.restoreSavedTokens();
        if (this.access_token && this.token_type && !force && (this.token_expired_at && this.token_expired_at > new Date().valueOf())) {
            return [this.access_token, this.token_type];
        }
        // FileAccessToken.ClearFileAccessToken(); //TODO??
        this.access_token = undefined;
        window.sessionStorage.removeItem('fxAccessToken');
        if (this.refresh_token) {
            await this.refreshToken();
        } else {
            await this.askCredentials();
        }
        return [this.access_token, this.token_type];
    }

    async queueGetAuthToken(force?: boolean) {
        return await this.queue.add(async () => {
            return await this.getAuthToken(force);
        }); //??
    }

    conterraAuth = async () => {
        let tokenInfo: any = await RunScript('FXGetAccessToken');
        if (tokenInfo) {
            let authData: IAuthData = {
                access_token: tokenInfo.split('|')[1],
                expires_in: +tokenInfo.split('|')[0],
                token_type: tokenInfo.split('|')[2]
            }
            this.storeTokens(authData);
        }
    }

    nativeAuth = async () => {
        try {
            let tokenInfo = await RunScriptLocalAsync('FXGetAccessToken', {GenerateRefreshToken: true});
            this.refresh_token = tokenInfo.split('|')[1];
            await this.refreshToken();
            AuthRef.toggleForm(false);
            AuthRef.credentialsResolve();
        } catch (err: any) {
            let title = `Authentication error ${err.name}:`;
            let text = err.message;
            ModalRef.showDialog({text, title});
        }
    }

    login = async (login: string, password: string) => {
        let response = await axios({
            method: 'post',
            url: `https://${DataServerHost}/api/Login/Token`,
            data: {login, password},
        });

        this.storeTokens(response.data);
    }

    logout = () => {
        this.token_type = undefined;
        this.access_token = undefined;
        this.refresh_token = undefined;
        this.token_expired_at = undefined;
        window.sessionStorage.fxTokenType = '';
        window.sessionStorage.fxAccessToken = '';
        window.sessionStorage.fxRefreshToken = '';
        window.sessionStorage.fxTokenExpiredAt = '';
        document.cookie = `${encodeURIComponent('FX-Auth')}=; path=/; max-age=0`;
        FileAccessToken.ClearAllTokens();
        window.location.reload();
    };

    refreshToken = async (count: number = 0) => {
        try {
            let response: any = await axios({
                method: 'post',
                url: `https://${DataServerHost}/api/login/Refresh`,
                data: {refresh_token: this.refresh_token},
            });
            this.storeTokens(response.data);
        } catch (err: any) {
            if (err.response.status >= 500) {
                if (count < 5) {
                    await this.refreshToken(count + 1);
                } else {
                    ModalRef.showDialog({
                        title: 'Server Error.',
                        text: 'Please Contact Administrator'
                    });
                }
            } else if (err.response.status === 401) {
                await this.askCredentials();
            } else {
                ModalRef.showDialog({
                    title: 'Server Error.',
                    text: JSON.stringify(err)
                });
            }
        }
    }

    askCredentials = async () => {
        if (isConterra) {
            return await this.conterraAuth();
        } else {
            return await AuthRef.askCredentials();
        }
    }
}

const AuthDataInstance = new AuthData();

export default AuthDataInstance
