import {Component} from 'react';
import {IGroupedVIMLIst} from './interfaces';
import styles from './vim.module.scss';

interface props {
}

interface state {
  text: string
}

class CurrentListGroup extends Component<props, state> {
  constructor(props: props) {
    super(props)
    this.state = {
      text: ''
    }
  }

  render() {
    if (!this.state.text) return null
    return <span className={styles.ListActiveGroup}>
      {this.state.text}
    </span>
  }

  Scroll = (scrollTop: number, direction: 'forward' | 'backward', treeRef: any, groupedList: IGroupedVIMLIst) => {
    let text = ''
    if (scrollTop > 0) {
      let listEl = window.document.querySelector('.VIMList');
      if (listEl) {
        let listPos = listEl.getBoundingClientRect().top;
        let sections = document.querySelectorAll('.VIMList-item');
        let firstRenderedSectionTop = sections[0] && sections[0].getBoundingClientRect().top

        if (direction === 'backward' && (!sections[0] || firstRenderedSectionTop > listPos)) {
          // @ts-ignore
          let lastId = sections[0]?.dataset.id
          let lastGroupIndex = lastId && treeRef.order.indexOf(lastId)
          if (lastGroupIndex !== undefined && lastGroupIndex > -1) {
            for (let i = lastGroupIndex - 1; i > -1; i--) {
              let idString = treeRef.order[i]
              if (idString.indexOf('_user') > -1 || idString.indexOf('_status') > -1) {
                let row = treeRef.records[idString].data.rowData
                let userId = row.UserId;
                let isUserRow = row.type === 'user'
                let lengthInvoices = isUserRow ? groupedList[userId].visibleInvoices : row.children.length
                let groupTitle = `${row.name} - ${lengthInvoices}`;
                text = isUserRow ? groupTitle : `${groupedList[userId].UserName} - ${groupTitle}`
                break;
              }
            }
          }
        } else {
          for (let i = 0; i < sections.length; i++) {
            let s = sections[i]
            let sectionPos = s.getBoundingClientRect().top;
            if ((sectionPos - 20) > listPos) {
              break
            }
            // @ts-ignore
            text = s.dataset.text;
          }
        }
      }
    }
    if (text || scrollTop === 0) this.setState({text})
  }
}

export default CurrentListGroup;
