import React, {Component} from 'react';
import {simpleObject} from '../../helpers/interfaces';
import DashboardGrid from '../Dashboard/Grid'
import {
    gridChangeDataAction,
    IColumnFilterItem,
    IColumnSetting,
    IFilterSetting,
    IGridFilter
} from '../Dashboard/interfaces';
import TCTabs from '../Tabs/TCTabs';
import {ReferenceRecordsDataSource} from '../../helpers/queries';
import {Button} from '@progress/kendo-react-buttons';
import CardManagement from '../Cards/CardManagement';
import UserInfo from '../../stores/User'
import {filterByReviewStateSettings, formatDateColumn} from './helpers';
import {pageId} from "../../Pages/Home/interfaces";
import {tabId} from "../Tabs/interfaces";

interface props {
    isActive: boolean
    switcher?: any
    allowAccessToReports?: false
    // props from PPtabs
    doNotSaveFilters?: boolean
    // isActive        :        true
    pageId?: pageId
    periodId?: number
    periodName?: string
    switchTab?: (activeTabId: tabId) => void
    serverFilters?: simpleObject
    columnsFilters?: IColumnFilterItem[]
    complexGridFilters?: IGridFilter[]
    remountKey?: number

    onChangeGridData?: (data: simpleObject[], action: gridChangeDataAction) => void
}

interface state {
    canCreateTimeCards: boolean
    initedSettings: boolean
}

const COLUMNS: Array<IColumnSetting> = [
    {
        field: 'RowNum',
        title: '#',
        type: 'number',
        gridWidth: 40,
        filterable: false,
        columnMenu: false,
        aggregate: 'count',
        locked: true
    },
    {
        field: 'Date',
        title: 'Date',
        type: 'date',
        locked: true,
        format: {
            type: 'date',
            customFormat: true,
            getCustomFormat: formatDateColumn
        }
    },
    {
        field: 'EmployeeName',
        title: 'Employee',
        type: 'string',
        gridWidth: 200,
        format: {
            type: 'link',
            fieldId: 'EmployeeId',
            refName: 'Employees'
        },
        locked: true
    },
    {
        field: 'EmployeeFirstName',
        title: 'First Name',
        type: 'string',
        gridWidth: 100,
    },
    {
        field: 'EmployeeLastName',
        title: 'Last Name',
        type: 'string',
        gridWidth: 100,
    },
    {
        field: 'ClassName',
        title: 'Class',
        type: 'string',
        format: {
            type: 'link',
            refName: 'Classes',
            fieldId: 'ClassId'
        },
        gridWidth: 180,
    },
    {
        field: 'PeriodName',
        title: 'Payroll Period',
        type: 'string',
        // locked: true,
        gridWidth: 220,
        format: {
            type: 'link',
            fieldId: 'PeriodId',
            refName: 'TKPayrollPeriods'
        },
    },
    {
        field: 'ReviewStateName',
        title: 'Review State',
        type: 'string',
        // locked: true,
        format: {
            type: 'string',
            fieldId: 'ReviewStateId'
        }
    },
    {
        field: 'AllStateNames',
        title: 'Payroll States',
        type: 'string',
        gridWidth: 120,
        format: {
            type: 'string',
        },
    },
    {
        field: 'ClockedHours',
        title: 'Clocked Hours',
        type: 'number',
        format: {
            type: 'number'
        },
        aggregate: 'sum'
    },
    {
        field: 'ApprovedHours',
        title: 'Approved Hours',
        type: 'number',
        gridWidth: 100,
        format: {
            type: 'number'
        },
        aggregate: 'sum'
    },
    {
        field: 'RegularHours',
        title: 'Regular',
        type: 'number',
        format: {
            type: 'number'
        },
        aggregate: 'sum'
    },
    {
        field: 'OvertimeHours',
        title: 'Overtime',
        type: 'number',
        format: {
            type: 'number'
        },
        aggregate: 'sum'
    },
    {
        field: 'DoubleHours',
        title: 'Double',
        type: 'number',
        format: {
            type: 'number'
        },
        aggregate: 'sum'
    },
    {
        title: 'PW',
        type: 'parent',
        columns: [
            {
                field: 'PWRegularHours',
                title: 'Regular',
                type: 'number',
                format: {
                    type: 'number'
                },
                aggregate: 'sum',
            },
            {
                field: 'PWOvertimeHours',
                title: 'Overtime',
                type: 'number',
                format: {
                    type: 'number'
                },
                aggregate: 'sum',
            },
            {
                field: 'PWDoubleHours',
                title: 'Double',
                type: 'number',
                format: {
                    type: 'number'
                },
                aggregate: 'sum',
            },
            {
                field: 'PWTotalHours',
                title: 'Total',
                type: 'number',
                format: {
                    type: 'number'
                },
                aggregate: 'sum',
            }
        ]
    },
    {
        field: 'TotalHours',
        title: 'Total Hours',
        type: 'number',
        format: {
            type: 'number'
        },
        aggregate: 'sum'
    },
    {
        field: 'ReviewerName',
        title: 'Reviewer',
        type: 'string',
        gridWidth: 200,
        format: {
            type: 'link',
            fieldId: 'ReviewerId',
            refName: 'Employees'
        },
    },
    {
        field: 'ReasonName',
        title: 'Reason',
        type: 'string',
        format: {
            type: 'string',
            fieldId: 'ReasonId'
        },
    },
    {
        field: 'FirstClockIn',
        title: 'First Clock In',
        type: 'date',
        gridWidth: 120,
        format: {
            type: 'datetime',
        }
    },
    {
        field: 'LastClockOut',
        title: 'Last Clock Out',
        type: 'date',
        gridWidth: 120,
        format: {
            type: 'datetime',
        }
    },
    {
        field: 'DepartmentName',
        title: 'Department',
        type: 'string',
        format: {
            type: 'link',
            fieldId: 'DepartmentId',
            refName: 'Departments'
        },
    },
    {
        field: 'CategoryName',
        title: 'Employee Category',
        type: 'string',
        gridWidth: 130,
        format: {
            type: 'link',
            fieldId: 'CategoryId',
            refName: 'EmployeeCategories'
        },
    },
    {
        field: 'HasAdjustmentText',
        title: 'Has Adjustment',
        type: 'string',
        format: {
            type: 'string',
            fieldId: 'HasAdjustment'
        }
    },
    {
        field: 'WageAllocationLockedText',
        title: 'Wage Allocation Locked',
        type: 'string',
        format: {
            type: 'string',
            fieldId: 'WageAllocationLocked',
        }
    },
    {
        field: 'RequestsCount',
        title: 'Requests Count',
        type: 'number',
        format: {
            type: 'integer'
        },
        aggregate: 'sum'
    },
    {
        field: 'AuditsCount',
        title: 'Audits Count',
        type: 'number',
        format: {
            type: 'integer'
        },
        aggregate: 'sum',
    },
    {
        field: 'AuditLunchHours',
        title: 'Audit Lunch',
        type: 'number',
        format: {
            type: 'number'
        },
    },
    {
        field: 'AppliedLunchHours',
        title: 'Applied Lunch',
        type: 'number',
        format: {
            type: 'number'
        },
    },
    {
        field: 'ManualLunchHours',
        title: 'Manual Lunch',
        type: 'number',
        format: {
            type: 'number'
        },
    },
    {
        field: 'MileageHours',
        title: 'Mileage',
        type: 'number',
        format: {
            type: 'number'
        },
        aggregate: 'sum'
    },
]

class TimeCardsGrid extends Component<props, state> {
    gridRef: any = React.createRef()
    rowId: number | null = null
    FILTERS: Array<IFilterSetting> = []
    isPPTab = !!this.props.pageId

    constructor(props: any) {
        super(props)
        this.state = {
            canCreateTimeCards: false,
            initedSettings: this.isPPTab,
        }
    }

    componentDidMount(): void {
        if (!this.isPPTab) this.InitSettings()
    }

    render() {
        if (!this.state.initedSettings) return null
        const periodId = this.props.periodId
        if (this.isPPTab && (!periodId || !this.props.isActive)) return null
        let key = '0'
        const sqlParams: simpleObject = {}
        if (this.isPPTab) {
            if (this.props.serverFilters) {
                for (let filter in this.props.serverFilters) {
                    const value = this.props.serverFilters[filter]
                    key += value.toString()
                    sqlParams[filter] = value
                }
            }
            if (periodId) {
                key += periodId + ''
                sqlParams.periodId = periodId
            }
            if (this.props.remountKey) key += this.props.remountKey
        }
        return (
            <DashboardGrid
                key={key}
                isActive={this.props.isActive}
                isParentPage={!this.isPPTab}
                ref={this.gridRef}
                columnsSettings={COLUMNS}
                filters={!this.isPPTab ? this.FILTERS : undefined}
                getSqlParams={{spName: 'DB_TimeCards', params: sqlParams}}
                saveSettings={this.isPPTab ? undefined : {prefix: 'timeCardsDash', saveFilters: true}}
                excelFileName={this.isPPTab ? "PayrollPeriodTimeCards.xlsx" : "TimeCardsDashboard.xlsx"}
                getRowKey={(dataItem: simpleObject) => dataItem.TCId}
                prepareData={this.PrepareData}
                columnValuesFilter={this.isPPTab ? this.props.columnsFilters : undefined}
                complexGridFilters={this.isPPTab ? this.props.complexGridFilters : undefined}
                columnMenu={{
                    filters: true,
                    columns: true,
                }}
                reorderable={true}
                toolbar={this.isPPTab ? undefined : {
                    actionBtns: this.state.canCreateTimeCards &&
                        <Button onClick={this.CreateTC}>Add Time Card</Button>,
                    switcher: this.props.switcher
                }}
                selectSettings={this.isPPTab ? {
                    pageId: 'TKDashboard',
                    onSelectRow: this.OnSelectRowInPPTab
                } : {
                    Component: TCTabs,
                    pageId: 'TKDashboard',
                    componentProps: {
                        refreshParent: this.Refresh
                    }
                }}
                onChangeGridData={this.props.onChangeGridData}
            >
            </DashboardGrid>
        )
    }

    OnSelectRowInPPTab = (dataItem: simpleObject | null) => {
        const tcId = dataItem?.TCId
        if (tcId) CardManagement.OpenTCCard(tcId, this.Refresh)
    }

    PrepareData = (result: any) => {
        let data = result[1]
        let hasNewSelectedRow = false
        data.forEach((item: simpleObject) => {
            if (this.rowId && item.TCId === this.rowId) {
                hasNewSelectedRow = true
            }
            item.WageAllocationLockedText = item.WageAllocationLocked ? 'Yes' : 'No'
            item.HasAdjustmentText = item.HasAdjustment ? 'Yes' : 'No'
        })

        if (!this.isPPTab) {
            if (this.rowId) {
                if (!hasNewSelectedRow) localStorage.removeItem('timeCardsDash_SelectedRowId')
                this.rowId = null
            }
            this.setState({canCreateTimeCards: result[0][0].CanCreateTimeCards})
        }
        return data
    }

    InitSettings = async () => {
        let userInfo = await UserInfo.getInfo()
        let reviewerValue = this.props.allowAccessToReports === false && userInfo ? {
            Id: userInfo.EmployeeId,
            Name: userInfo.Name
        } : undefined
        let filterByReviewer: IFilterSetting = {
            id: 'filterReviewer',
            placeholder: 'Filter by Reviewer',
            type: 'combobox',
            disabled: !!reviewerValue,
            defValue: reviewerValue,
            serverFilter: {
                param: 'reviewerId',
                getData: async () => {
                    let result = await ReferenceRecordsDataSource('Employees', {activeOnly: true})
                    return result[0]
                }
            },
            width: 248,
            toolbarRow: 2
        }

        this.FILTERS = [
            {
                id: 'filterPeriod',
                type: 'dateRange',
                serverFilter: {
                    param: '',
                },
                dateRangeSettings: {
                    dateFromParam: 'dateFrom',
                    dateToParam: 'dateTo',
                    defaultPeriod: 'notApplied',
                    allowEmptyValues: true
                },
                localStoragePrefix: 'TKDashboard',
                toolbarRow: 1
            },
            {
                id: 'filterAR',
                placeholder: 'Filter by Audit Rule',
                type: 'multiselect',
                itemName: 'Audit Rule',
                serverFilter: {
                    param: 'auditRuleId',
                    getData: async () => {
                        let result = await ReferenceRecordsDataSource('TKAuditRules', {activeOnly: true})
                        return result[0]
                    }
                },
                toolbarRow: 1,
                width: 300
            },
            filterByReviewStateSettings,
            filterByReviewer,
            {
                id: 'filterPP',
                placeholder: 'Filter by Payroll Period',
                type: 'combobox',
                serverFilter: {
                    param: 'periodId',
                    getData: async () => {
                        let result = await ReferenceRecordsDataSource('TKPayrollPeriods', {activeOnly: false})
                        return result[0]
                    }
                },
                localStoragePrefix: 'TKDashboard',
                toolbarRow: 2,
                width: 300
            },
        ]

        this.setState({initedSettings: true})
    }

    Refresh = (tcId?: number) => {
        let refresh = this.gridRef?.current?.Refresh
        if (tcId) {
            localStorage.setItem('timeCardsDash_SelectedRowId', JSON.stringify(tcId))
            this.rowId = tcId
        }
        if (refresh) refresh()
    }

    CreateTC = () => {
        CardManagement.OpenAddTCCard({onFinish: this.Refresh})
    }

}

export default TimeCardsGrid
