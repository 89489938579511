import {Button, Toolbar} from "@progress/kendo-react-buttons";
import {IComboboxItem, simpleObject} from "../../helpers/interfaces";
import UserInfo from "../../stores/User";
import {ITabSetting, tabId} from "./interfaces";
import {GetTabId, TABS} from "./helpers";
import styles from "../Tabs/tabs.module.scss";
import {gridChangeDataAction, IColumnFilterItem, IDashboardChildProps, IGridFilter,} from "../Dashboard/interfaces";
import BaseComponent from "../BaseComponent";
import React from "react";
import TabsBtns from "./TabsBtns";
import TabsToolbarRight from "./TabsToolbarRight";
import moment from "moment";
import DateSliderFilter from "../Common/Form/DateSliderFilter";
import ComboboxFilter from "../Dashboard/ComboboxFilter";
import {filterByReviewStateSettings} from "../TC/helpers";
import CardManagement from "../Cards/CardManagement";

interface state {
    activeTabId: tabId | undefined
    settingsInited: boolean
    checks: IComboboxItem[]
    remountFilterByChecksKey: number
}

class PPTabs extends BaseComponent<IDashboardChildProps, state> {
    TABS: Array<ITabSetting | tabId> = [];
    initedTabs: Array<tabId> = [];
    periodId?: number | null;
    tabsRefs: {
        [key in tabId]?: any;
    } = {};
    tabsServerFilters: { [key in tabId]?: simpleObject } = {}
    tabsColumnFilters: { [key in tabId]?: IColumnFilterItem[] } = {}
    tabsComplexGridFilters: { [key in tabId]?: IGridFilter[] } = {}
    ppSummaryFilterDates: { [periodId: number]: Date[] } = {}
    ppStatusFilterDates: { [periodId: number]: Date[] } = {}
    rightBtnsRef: simpleObject = {}

    constructor(props: IDashboardChildProps) {
        super(props);
        const {PeriodId} = this.props.rowData || {}
        this.periodId = PeriodId;
        const initialActiveTab = this.props.initialTab || this.props.props?.initialTab
        this.state = {
            activeTabId: initialActiveTab && GetTabId(initialActiveTab),
            settingsInited: false,
            checks: [],
            remountFilterByChecksKey: 0,
        };
    }

    componentDidMount() {
        this.InitSettings();
        this.LoadChecks()
    }

    componentDidUpdate(prevProps: IDashboardChildProps) {
        const {PeriodId} = this.props.rowData || {}
        if (PeriodId && this.periodId !== PeriodId) {
            this.ppSummaryFilterDates = {}
            this.ppStatusFilterDates = {}
            this.periodId = PeriodId;
            this.LoadChecks()
        }
        const initialActiveTab = this.props.initialTab || this.props.props?.initialTab
        const activeTab = initialActiveTab && GetTabId(initialActiveTab)
        if (activeTab && prevProps.props?.initialTab !== activeTab) {
            this.setState({activeTabId: activeTab})
        } else if (this.periodId && !this.state.activeTabId && !this.initedTabs.length) {
            this.setState({activeTabId: GetTabId(this.TABS[0])})
        }
    }

    render() {
        let activeTabId = this.state.activeTabId;
        if (!this.state.settingsInited || !activeTabId) return null;
        const {PeriodId} = this.props.rowData || {}
        if (PeriodId && this.periodId !== PeriodId) {
            this.ppSummaryFilterDates = {}
            this.ppStatusFilterDates = {}
            this.periodId = PeriodId;
            this.LoadChecks()
        }

        const toolbarStyle = !PeriodId ? {display: "none"} : undefined;
        return (
            <>
                <Toolbar className={styles.Toolbar} style={toolbarStyle}>
                    <TabsBtns
                        tabs={this.TABS}
                        activeTabId={activeTabId}
                        onChange={this.OnChange}
                    />
                    <TabsToolbarRight
                        ref={this.InitRightBtnsRef}
                        activeTabId={activeTabId}
                        tabsRefs={this.tabsRefs}
                        exportToExcel={this.ExportToExcel}
                        refresh={this.RefreshActiveTab}
                        unSelectRow={this.props.unSelectRow}
                    >
                        {this.renderPPChecksAction()}
                        {this.renderDateFilter()}
                        {this.renderTimeCardsFilters()}
                    </TabsToolbarRight>
                </Toolbar>
                {this.TABS.map((tab) => {
                    let tabId = GetTabId(tab);
                    let isActiveTabId = tabId === activeTabId;
                    let isInit = this.initedTabs.indexOf(tabId) > -1;
                    let tabSetting = TABS[tabId];
                    let TabComponent = tabSetting.component;
                    if (PeriodId && activeTabId && isActiveTabId && this.initedTabs.indexOf(activeTabId) === -1) this.initedTabs.push(activeTabId);
                    if (!isActiveTabId && !isInit) return null;
                    let props: simpleObject = {
                        periodId: PeriodId,
                        periodName: this.props.rowData?.PeriodName,
                        isActive: isActiveTabId,
                        doNotSaveFilters: true,
                        switchTab: this.SetActiveTabAndProps,
                        ...(this.props.props || {})
                    };
                    if (this.tabsServerFilters[tabId]) {
                        props.serverFilters = this.tabsServerFilters[tabId]
                        props.remountKey = +new Date()
                    }
                    if (this.tabsColumnFilters[tabId]) {
                        props.columnsFilters = this.tabsColumnFilters[tabId]
                        props.remountKey = +new Date()
                    }
                    if (this.tabsComplexGridFilters[tabId]) {
                        props.complexGridFilters = this.tabsComplexGridFilters[tabId]
                        props.remountKey = +new Date()
                    }
                    
                    if (tabId === 'PPSummary') {
                        const {Start, End} = this.props.rowData || {}
                        const [filterStart, filterEnd] = this.periodId && this.ppSummaryFilterDates[this.periodId]?.length ? this.ppSummaryFilterDates[this.periodId] : []
                        props.startDate = moment(filterStart || Start).format('YYYY-MM-DD')
                        props.endDate = moment(filterEnd || End).format('YYYY-MM-DD')
                        props.remountKey = +new Date()
                    }
                    if (tabId === 'PPStatus') {
                        const values = this.periodId && this.ppStatusFilterDates[this.periodId]?.length ? this.ppStatusFilterDates[this.periodId] : this.getDefaultStatusDatefilterValues(this.props.rowData || {})
                        const [filterStart, filterEnd] = values
                        props.startDate = moment(filterStart).format('YYYY-MM-DD')
                        props.endDate = moment(filterEnd).format('YYYY-MM-DD')
                        props.remountKey = +new Date()
                    }

                    if (this.props.parentId) props.pageId = this.props.parentId;
                    if (tabSetting.hasSetDefaultFilters) {
                        props.onChangeGridData = this.OnChangeChildGridData;
                    }

                    const propsRef = (ref: any) => {
                        if (!this.tabsRefs[tabId]) this.tabsRefs[tabId] = React.createRef<any>();
                        if (this.tabsRefs[tabId].current !== ref) {
                            this.tabsRefs[tabId].current = ref
                        }
                    }

                    return (
                        <div
                            key={tabId}
                            className={`${styles.ContentWrap} ${!isActiveTabId ? styles.HiddenTab : ""}`}
                        >
                            <div className={styles.Content}>
                                <TabComponent
                                    {...props}
                                    ref={propsRef}
                                />
                            </div>
                        </div>
                    );
                })}
            </>
        );
    }


    renderDateFilter = () => {
        if (
            !this.props.rowData ||
            (this.state.activeTabId !== 'PPSummary' && this.state.activeTabId !== 'PPStatus')
        ) return null
        if (!this.periodId) return

        const statusValues = this.ppStatusFilterDates[this.periodId]?.length ? this.ppStatusFilterDates[this.periodId] : this.getDefaultStatusDatefilterValues(this.props.rowData || {})
        const summaryValues = this.ppSummaryFilterDates[this.periodId] || []
        const values = this.state.activeTabId === 'PPSummary' ? summaryValues : statusValues

        const {Start, End} = this.props.rowData || {}
        return <div><DateSliderFilter
            key={this.periodId}
            min={Start}
            max={End}
            defaultValues={!!values.length ? values : undefined}
            onChange={(startDate: Date, endDate: Date) => {
                if (this.state.activeTabId === 'PPSummary') this.ppSummaryFilterDates[this.periodId!] = [startDate, endDate]
                if (this.state.activeTabId === 'PPStatus') this.ppStatusFilterDates[this.periodId!] = [startDate, endDate]
                this.forceUpdate()
            }}
        /></div>
    }

    getDefaultStatusDatefilterValues = (rowData: simpleObject) => {
        const {Start, End} = rowData
        const startDateValue = new Date(+Start)
        let yestardayDate = new Date()
        const yesterday = new Date().getDate() - 1
        yestardayDate.setDate(yesterday)
        let endDateValue = yestardayDate
        if (End < yestardayDate) endDateValue = new Date(End)
        if (yestardayDate < Start) endDateValue = new Date(Start)
        return [startDateValue, endDateValue]
    }

    renderPPChecksAction = () => {
        if (this.state.activeTabId !== 'PPChecks') return null
        if (!this.periodId) return
        if (
            !this.props.rowData || !this.props.rowData.CanAddOffCycle
        ) return null
        return <Button
            icon="plus"
            onClick={this.CreateOffcycleCheck}>
            Off-Cycle Check
        </Button>
    }

    CreateOffcycleCheck = () => {
        const {rowData} = this.props
        if (!rowData) return
        CardManagement.OpenStartCheckClosureCard({
            rowData, refresh: () => {
                this.LoadChecks()
                this.RefreshActiveTab()
            }
        })
    }

    renderTimeCardsFilters = () => {
        if (this.state.activeTabId !== 'PPTimeCards') return null
        const filters = this.tabsServerFilters['PPTimeCards'] || this.props.props?.serverFilters
        const stateValue = filterByReviewStateSettings.values?.find((filter) => filter.Id === filters?.reviewStateId)
        const checkValue = this.state.checks.find((check) => check.Id === filters?.checkId)
        return <>
            <ComboboxFilter
                key={this.state.remountFilterByChecksKey + 'filterchecks'}
                defaultValue={checkValue}
                filterData={this.state.checks}
                onChange={(value: any, filter: any) => {
                    const serverFilters: simpleObject = this.tabsServerFilters['PPTimeCards'] || {}
                    if (value) serverFilters.checkId = value.Id
                    else delete serverFilters.checkId
                    this.tabsServerFilters['PPTimeCards'] = serverFilters
                    this.forceUpdate()
                }}
                filter={{
                    id: 'filterState',
                    placeholder: 'Filter by Check',
                    type: 'combobox',
                    serverFilter: {
                        param: 'checkId',
                    },
                    toolbarRow: 1,
                    width: 170
                }}
            />
            <ComboboxFilter
                defaultValue={stateValue}
                filterData={filterByReviewStateSettings.values}
                onChange={(value: any, filter: any) => {
                    const serverFilters: simpleObject = this.tabsServerFilters['PPTimeCards'] || {}
                    if (value) serverFilters.reviewStateId = value.Id
                    else delete serverFilters.reviewStateId
                    this.tabsServerFilters['PPTimeCards'] = serverFilters
                    this.forceUpdate()
                }}
                filter={filterByReviewStateSettings}
            /></>
    }

    LoadChecks = async () => {
        try {
            let result = await this.GetSQLData({
                spName: 'DB_PayrollClosureData',
                params: {periodId: this.periodId,}
            })
            this.setState({
                remountFilterByChecksKey: +new Date(),
                checks: result[0].map((rowData: simpleObject) => ({
                    Id: rowData.CheckId,
                    Name: rowData.CheckName
                }))
            })
        } finally {

        }
    }

    InitRightBtnsRef = (ref: any) => {
        if (this.rightBtnsRef !== ref) this.rightBtnsRef = ref
    }

    InitSettings = async () => {
        let isFinancialDataAllow = await UserInfo.getIsFinancialDataAllow();
        this.TABS = isFinancialDataAllow
            ? ['PPStatus', 'PPSummary', 'PPTimeCards', "PPDetails", "PPLaborCost", "PPCostRates", "PPChecks"]
            : ['PPStatus', 'PPSummary', 'PPTimeCards', "PPDetails", "PPChecks"];
        this.props.initialTab || GetTabId(this.TABS[0])
        this.setState({settingsInited: true});
    };

    OnChange = (e: any) => {
        let tabId: tabId = e.currentTarget.dataset.id;
        let activeTabId = this.state.activeTabId;
        if (activeTabId === tabId) return;
        this.SetActiveTabAndProps(tabId);
    };

    RefreshActiveTab = () => {
        let active = this.state.activeTabId;
        let refreshFunc = active ? this.tabsRefs[active]?.current?.Refresh : null;
        if (refreshFunc) refreshFunc();
    };

    ExportToExcel = () => {
        let active = this.state.activeTabId;
        let exportFunc = active
            ? this.tabsRefs[active]?.current?.gridRef?.current?.ExportToExcel
            : null;
        if (exportFunc) exportFunc();
    };

    SetActiveTabAndProps = (
        activeTabId: tabId,
        serverFilters?: simpleObject,
        columnFilters?: IColumnFilterItem[],
        complexGridFilters?: IGridFilter[]
    ) => {
        this.tabsServerFilters[activeTabId] = serverFilters
        this.tabsColumnFilters[activeTabId] = columnFilters
        this.tabsComplexGridFilters[activeTabId] = complexGridFilters
        this.setState({activeTabId});
    };

    OnChangeChildGridData = (
        data: Array<simpleObject>,
        action: gridChangeDataAction
    ) => {
        if (
            action === "filterChanged" ||
            action === "forceRestoreSettings" ||
            action === "dataLoaded"
        ) {
            if (this.rightBtnsRef?.forceRefresh) {
                this.rightBtnsRef.forceRefresh()
            }
        }
    };
}

export default PPTabs
