import React, {Component} from 'react';
import {IComboboxItem, simpleObject} from '../../helpers/interfaces';
import DashboardGrid from '../Dashboard/Grid'
import {
    gridChangeDataAction,
    IColumnSetting,
    IColumnValue,
    IFilterSetting,
    IGridChildComponentProps
} from '../Dashboard/interfaces';
import {ReferenceRecordsDataSource} from '../../helpers/queries';
import ButonLink from '../Common/Buttons/ButtonLink';
import UserInfo from '../../stores/User'
import PPTabs from '../Tabs/PPTabs';
import {TTCReviewStateId} from "../TC/helpers";

interface props {
    isActive: boolean
    switcher: any
}

interface state {
    settingsInited: boolean
}

const PAYROLL_STAGES: Array<IColumnValue> = [
    {
        Name: 'Open',
        Id: 'Open',
        FieldId: 'Stage',
        Selected: true
    },
    {
        Name: 'Closed',
        Id: 'Closed',
        FieldId: 'Stage',
        Selected: true
    },
    {
        Name: 'Paid',
        Id: 'Paid',
        FieldId: 'Stage',
        Selected: true
    }
]

class PayrollPeriods extends Component<props, state> {
    gridRef: any = React.createRef()
    wageType: string | null = null
    pw: boolean | null = null
    COLUMNS: Array<IColumnSetting> = []
    periods: Array<IComboboxItem> = []
    initialSelectedRowProps?: IGridChildComponentProps
    FILTERS: Array<IFilterSetting> = [
        {
            id: 'filterPeriod',
            type: 'dateRange',
            serverFilter: {
                param: '',
            },
            dateRangeSettings: {
                dateFromParam: 'dateFrom',
                dateToParam: 'dateTo',
                defaultPeriod: 'notApplied',
                allowEmptyValues: true
            },
            localStoragePrefix: 'TKDashboard',
            toolbarRow: 1
        },
        {
            id: 'filterPP',
            placeholder: 'Filter by Payroll Period',
            type: 'combobox',
            serverFilter: {
                param: 'periodId',
                getData: async () => {
                    let result = await ReferenceRecordsDataSource('TKPayrollPeriods', {activeOnly: false})
                    this.periods = result[0]
                    return this.periods
                }
            },
            localStoragePrefix: 'TKDashboard',
            width: 298,
            toolbarRow: 2,
        },
        {
            id: 'filterStage',
            type: 'combobox',
            placeholder: 'Filter by Stage',
            serverFilter: {
                param: 'periodStage',
            },
            values: PAYROLL_STAGES,
            toolbarRow: 1
        },
    ]

    constructor(props: any) {
        super(props)
        this.state = {
            settingsInited: false
        }
    }

    componentDidMount(): void {
        this.InitSettings()
    }

    InitSettings = async () => {
        let IsFinancialDataAllow = await UserInfo.getIsFinancialDataAllow()
        this.COLUMNS = [
            {
                field: 'RowNum',
                title: '#',
                type: 'number',
                gridWidth: 40,
                filterable: false,
                columnMenu: false,
                aggregate: 'count',
            },
            {
                field: 'PeriodName',
                title: 'Name',
                type: 'string',
                gridWidth: 200,
                format: {
                    type: 'link',
                    fieldId: 'PeriodId',
                    refName: 'TKPayrollPeriods'
                },
            },
            {
                field: 'PeriodTypeName',
                title: 'Period Type',
                type: 'string',
                gridWidth: 100,
                format: {
                    type: 'string',
                    fieldId: 'PeriodTypeId'
                }
            },
            {
                field: 'Start',
                title: 'Start',
                type: 'date',
                format: {
                    type: 'date',
                }
            },
            {
                field: 'End',
                title: 'End',
                type: 'date',
                format: {
                    type: 'date',
                }
            },
            {
                field: 'Closed',
                title: 'Closed',
                type: 'date',
                format: {
                    type: 'date',
                }
            },
            {
                field: 'MarkedPaid',
                title: 'Marked Paid',
                type: 'date',
                format: {
                    type: 'date',
                }
            },
            {
                field: 'Stage',
                title: 'Stage',
                type: 'string',
                gridWidth: 90,
                values: PAYROLL_STAGES,
                format: {
                    type: 'string',
                    fieldId: 'Stage',
                }
            },
            {
                field: '',
                title: 'Time Cards',
                type: 'parent',
                columns: [
                    {
                        field: 'Total',
                        title: 'Total',
                        type: 'number',
                        gridWidth: 130,
                        format: {
                            type: 'integer',
                        },
                    },
                    {
                        field: 'IncompleteReview',
                        title: 'Incomplete Review',
                        type: 'number',
                        gridWidth: 130,
                        format: {
                            type: 'integer',
                            customFormat: true,
                            getCustomFormat: this.renderTCCell
                        },
                    },
                    {
                        field: 'Zero',
                        title: 'Zero',
                        type: 'number',
                        gridWidth: 130,
                        format: {
                            type: 'integer',
                            customFormat: true,
                            getCustomFormat: this.renderTCCell
                        },
                    },
                    {
                        field: 'Non-zero',
                        title: 'Non-zero',
                        type: 'number',
                        gridWidth: 130,
                        format: {
                            type: 'integer',
                            customFormat: true,
                            getCustomFormat: this.renderTCCell
                        },
                    },
                    {
                        field: 'ActiveRequest',
                        title: 'Active Requests',
                        type: 'number',
                        gridWidth: 130,
                        format: {
                            type: 'integer',
                            customFormat: true,
                            getCustomFormat: this.renderTCCell
                        },
                    },
                    {
                        field: 'IncompleteAllocation',
                        title: 'Incomplete Allocation',
                        type: 'number',
                        gridWidth: 130,
                        format: {
                            type: 'integer',
                            customFormat: true,
                            getCustomFormat: this.renderTCCell
                        },
                    },
                ]
            },
            {
                field: '',
                title: 'Hours',
                type: 'parent',
                columns: [
                    {
                        field: 'Regular',
                        title: 'Regular',
                        type: 'number',
                        gridWidth: 130,
                        format: {
                            type: 'number',
                            customFormat: true,
                            getCustomFormat: this.renderHourCell
                        },
                    },
                    {
                        field: 'Overtime',
                        title: 'Overtime',
                        type: 'number',
                        gridWidth: 130,
                        format: {
                            type: 'number',
                            customFormat: true,
                            getCustomFormat: this.renderHourCell
                        },
                    },
                    {
                        field: 'Double',
                        title: 'Double',
                        type: 'number',
                        gridWidth: 130,
                        format: {
                            type: 'number',
                            customFormat: true,
                            getCustomFormat: this.renderHourCell
                        },
                    },
                ]
            },
            {
                title: 'PW Hours',
                type: 'parent',
                columns: [
                    {
                        field: 'PWRegular',
                        title: 'Regular',
                        type: 'number',
                        format: {
                            type: 'number',
                            customFormat: true,
                            getCustomFormat: this.renderHourCell
                        },
                        aggregate: 'sum',
                    },
                    {
                        field: 'PWOvertime',
                        title: 'Overtime',
                        type: 'number',
                        format: {
                            type: 'number',
                            customFormat: true,
                            getCustomFormat: this.renderHourCell
                        },
                        aggregate: 'sum',
                    },
                    {
                        field: 'PWDouble',
                        title: 'Double',
                        type: 'number',
                        format: {
                            type: 'number',
                            customFormat: true,
                            getCustomFormat: this.renderHourCell
                        },
                        aggregate: 'sum',
                    },
                    {
                        field: 'PWTotal',
                        title: 'Total',
                        type: 'number',
                        format: {
                            type: 'number',
                            customFormat: true,
                            getCustomFormat: this.renderHourCell
                        },
                        aggregate: 'sum',
                    }
                ]
            },
            {
                field: 'Approved',
                title: 'Total Approved',
                type: 'number',
                gridWidth: 130,
                format: {
                    type: 'number',
                },
            },
        ]
        if (IsFinancialDataAllow) {
            this.COLUMNS.push({
                field: 'TotalAmount',
                title: 'Total Amount',
                type: 'number',
                format: {
                    type: 'currency'
                }
            })
        }
        this.COLUMNS.push({
            field: '',
            title: 'Last Sync',
            type: 'parent',
            columns: [
                {
                    field: 'LastSyncToPayroll',
                    title: 'To Payroll',
                    type: 'date',
                    format: {
                        type: 'date',
                    }
                },
                {
                    field: 'LastSyncFromPayroll',
                    title: 'From Payroll',
                    type: 'date',
                    format: {
                        type: 'date',
                    }
                },
            ]
        })

        this.setState({settingsInited: true})
    }

    render() {
        if (!this.state.settingsInited) return null

        let componentProps: simpleObject = {}
        if (this.initialSelectedRowProps) {
            componentProps = this.initialSelectedRowProps
            this.initialSelectedRowProps = undefined
        }

        return (
            <DashboardGrid
                isActive={this.props.isActive}
                isParentPage={true}
                ref={this.gridRef}
                columnsSettings={this.COLUMNS}
                filters={this.FILTERS}
                getSqlParams={{spName: 'DB_PayrollPeriods'}}
                saveSettings={{
                    prefix: 'payrollDash',
                    saveGridSettings: true,
                    saveFilters: true
                }}
                excelFileName="Payroll Periods.xlsx"
                getRowKey={(dataItem: simpleObject) => dataItem.PeriodId}
                selectSettings={{
                    pageId: 'TKDashboard',
                    Component: PPTabs,
                    componentProps,
                    onSelectRow: this.OnSelectRow,
                }}
                onChangeGridData={this.OnChangeGridData}
                columnMenu={{
                    filters: true,
                    columns: false
                }}
                toolbar={{
                    switcher: this.props.switcher
                }}
                defaultColumnValuesFilter={[
                    {
                        field: 'Stage',
                        values: ['Open', 'Closed']
                    },
                ]}
                defaultSort={[{
                    dir: 'asc',
                    field: 'Start'
                }]}
                defaultComplexGridFilters={[{
                    "logic": "and",
                    "filters": [{"field": "Total", "operator": "gt", "value": 0}]
                }]}
            />
        )
    }

    renderHourCell = (dataItem: simpleObject, field: string) => {
        let value = dataItem[field]
        let text = value !== null && value !== undefined ? (value).toFixed(2) : ''
        let pw = !(field === 'Regular' || field === 'Overtime' || field === 'Double')
        return <div style={{textAlign: 'center'}}>
            {value ? <ButonLink
                text={text}
                dataAttr={{rowData: dataItem, wageType: field, pw}}
                onClick={this.OnClickWageLink}
            /> : text}
        </div>
    }

    renderTCCell = (dataItem: simpleObject, field: string) => {
        let value = dataItem[field]
        return <div style={{textAlign: 'center'}}>
            {value ? <ButonLink
                text={value}
                dataAttr={{rowData: dataItem, field}}
                onClick={this.OnClickTCLink}
            /> : value}
        </div>
    }

    OnClickWageLink = (e: any, data: {
        rowData: simpleObject,
        wageType: string,
        pw: boolean
    }) => {
        let selectRow = this.gridRef?.current?.SelectRow
        sessionStorage.setItem('PPDetailTabColumnValueFilters', JSON.stringify({
            wageType: data.wageType,
            pw: data.pw
        }));
        if (selectRow) {
            this.initialSelectedRowProps = {initialTab: 'PPDetails'}
            this.forceUpdate()
            selectRow(data.rowData)
        }
    }

    OnClickTCLink = (e: any, data: { rowData: simpleObject, field: string }) => {
        let selectRow = this.gridRef?.current?.SelectRow
        if (selectRow) {
            this.initialSelectedRowProps = {
                initialTab: 'PPTimeCards',
            }
            let field = data.field
            let reviewStateId: TTCReviewStateId | null = null
            switch (field) {
                case 'IncompleteAllocation':
                    reviewStateId = 'ClosureAuditsState'
                    break;
                case 'ActiveRequest':
                    reviewStateId = 'ActiveRequestState'
                    break;
                case 'IncompleteReview':
                    reviewStateId = 'NotCompleted'
                    break;
            }

            if (reviewStateId) {
                this.initialSelectedRowProps.serverFilters = {reviewStateId}
            }

            if (field === 'Non-zero' || field === 'Zero') {
                this.initialSelectedRowProps.complexGridFilters = [{
                    "logic": "and",
                    "filters": [{
                        "field": "ApprovedHours",
                        "operator": field === 'Non-zero' ? 'gt' : "eq",
                        "value": 0
                    }]
                }]
            }

            this.forceUpdate()
            selectRow(data.rowData, true)
        }
    }

    OnSelectRow = () => {
        this.forceUpdate()
    }

    OnChangeGridData = (data: Array<simpleObject>, action: gridChangeDataAction) => {
        if (action === 'unselectRow') {
            localStorage.removeItem('TKDashboard_filterPP')
            let gridRef = this.gridRef?.current

            let refresh = gridRef?.Refresh
            let restoreSettings = gridRef?.RestoreSettings
            let restoreGridFilters = gridRef?.RestoreGridFilters

            if (restoreSettings) restoreSettings()
            if (restoreGridFilters) restoreGridFilters()
            if (refresh) refresh()
        }
    }
}

export default PayrollPeriods
