import React from 'react';
import {Button, ButtonGroup} from "@progress/kendo-react-buttons";
import {Tooltip} from "@progress/kendo-react-tooltip";

interface IProps {
    className: string

    onClick(value: boolean): void
}

const PinAllToggle = (props: IProps) => {
    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        props.onClick(e.currentTarget.id === 'pin')
    }

    return <Tooltip
        anchorElement={'target'}
        parentTitle={true}
        position="top"
        openDelay={150}

    >
        <ButtonGroup className={props.className}>
            <Button
                key={'pin'}
                id={'pin'}
                iconClass={'mdi mdi-pin'}
                size={'small'}
                onClick={onClick}
                title={'Pin All'}
            ></Button>
            <Button
                key={'unpin'}
                id={'unpin'}
                iconClass={'mdi mdi-pin-outline'}
                size={'small'}
                onClick={onClick}
                title={'Unpin All'}
            ></Button>
        </ButtonGroup>
    </Tooltip>

}

export default PinAllToggle