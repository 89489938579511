import React, {Component} from 'react';
import DashboardGrid from '../Dashboard/Grid';
import {IColumnSetting} from '../Dashboard/interfaces';
import {simpleObject} from '../../helpers/interfaces';
import CardManagement from "../Cards/CardManagement";
import {IRequestAllocation} from "./interfaces";

interface props {
    tcId?: number
    isActive: boolean

    onChangeGridData?(data: Array<simpleObject>): void
}

interface state {
}

class TCRequests extends Component<props, state> {
    gridRef: any = React.createRef()
    tcId = this.props.tcId
    allocationData: IRequestAllocation[] = []
    COLUMNS: Array<IColumnSetting>

    constructor(props: props) {
        super(props)

        this.state = {}
        this.COLUMNS = [
            {
                field: 'CreatedLocal',
                title: 'Created (Local)',
                type: 'date',
                gridWidth: 130,
                format: {
                    type: 'datetime'
                }
            },
            {
                field: 'RequestType',
                title: 'Type',
                type: 'string',
                minWidth: 150,
                format: {
                    type: 'string',
                    fieldId: 'RequestType'
                }
            },
            {
                field: 'Solicitation',
                title: 'Solicitation',
                type: 'string',
                minWidth: 150,
            },
            {
                field: 'Description',
                title: 'Description',
                type: 'string',
                minWidth: 150,
            },
            {
                field: 'ReasonName',
                title: 'Reason',
                type: 'string',
                gridWidth: 100,
                format: {
                    type: 'string',
                    fieldId: 'ReasonId'
                }
            },
            {
                field: 'PayrollItemName',
                title: 'Payroll Item',
                type: 'string',
                gridWidth: 180,
                format: {
                    type: 'string',
                    fieldId: 'PayrollItemId'
                }
            },
            {
                field: 'QTY',
                title: 'QTY',
                type: 'number',
                gridWidth: 100,
                format: {
                    type: 'integer'
                }
            },
            {
                field: 'WorkOrderName',
                title: 'Work Order',
                type: 'string',
                minWidth: 180,
                format: {
                    type: 'string',
                    fieldId: 'WorkOrderId'
                }
            },
            {
                field: 'ReviewStarted',
                title: 'Review Started',
                type: 'date',
                gridWidth: 130,
                format: {
                    type: 'datetime'
                }
            },
            {
                field: 'ReviewStage',
                title: 'Stage',
                type: 'string',
                gridWidth: 100,
            },
            {
                field: 'RejectionComment',
                title: 'Rejection Comment',
                type: 'string',
                minWidth: 100,
            },
        ]
    }

    render() {
        if (this.props.isActive && this.tcId !== this.props.tcId) {
            this.tcId = this.props.tcId
        }

        if (!this.props.tcId || !this.props.isActive && this.tcId !== this.props.tcId) return null

        return <>
            <DashboardGrid
                isActive={this.props.isActive}
                key={this.props.tcId}
                ref={this.gridRef}
                columnsSettings={this.COLUMNS}
                getSqlParams={{spName: 'TK_GetRequestData', params: {TcId: this.tcId}}}
                getRowKey={this.GetRowKey}
                initRefresh={this.InitRefresh}
                excelFileName="Requests.xlsx"
                columnMenu={{
                    filters: true,
                    columns: false
                }}
                toolbar={{
                    hide: true
                }}
                onChangeGridData={this.props.onChangeGridData}
                prepareData={this.PrepareData}
                selectSettings={{
                    pageId: 'TKDashboard',
                    onSelectRow: this.OnSelectRow
                }}
            />
        </>
    }

    PrepareData = (data: any) => {
        this.allocationData = data[1]
        return data[0]
    }

    OnSelectRow = (dataItem: simpleObject | null): any => {
        if (!dataItem) return
        const allocation = dataItem.RequestType === "Time Adjustment" ? this.allocationData.filter((item) => item.RequestId === dataItem.RequestId) : []
        CardManagement.OpenTKRequestsActionsCard(
            dataItem,
            allocation,
            this.Refresh
        )
    }

    Refresh = () => {
    }

    InitRefresh = (refresh: any) => {
        this.Refresh = refresh
    }

    GetRowKey = (dataItem: simpleObject) => dataItem.Id
}

export default TCRequests;
