import moment from 'moment'
import {Grid, GridColumn as Column} from '@progress/kendo-react-grid'
import BaseComponent from '../BaseComponent'
import {GridRowHeight} from '../Dashboard/helpers'
import Loader from '../Common/Loader'
import FXCard from '../Common/FXCard/FXCard'
import {IFXCardProps} from './interfaces'

interface IHistoryItem {
  Date: null | string
  Details: string
  User: string
}

interface props extends IFXCardProps {
  id: number
  title?: string
}

interface state {
  history: Array<IHistoryItem>
  loading: boolean
}

class RecordHistory extends BaseComponent<props, state> {

  constructor(props: props) {
    super(props)
    this.state = {
      history: [],
      loading: true
    }
  }

  componentDidMount() {
    this.LoadHistory()
  }

  render() {
    return (
      <FXCard
        title={this.props.title}
        onClose={this.props.finally}
      >
        {this.state.loading && <Loader/>}
        <Grid
          style={{height: '100%'}}
          data={this.state.history}
          resizable={true}
          rowHeight={GridRowHeight}
        >
          <Column
            field='Date'
            title='Date'
            width='150px'
          />
          <Column
            field='User'
            title='User'
            width='200px'
          />
          <Column
            field='Details'
            title='Details'
          />
        </Grid>
      </FXCard>
    )
  }

  LoadHistory = async () => {
    let id = this.props.id;
    if (!id) return;
    try {
      this.setState({loading: true});
      let result = await this.GetSQLData({
        spName: 'GetRecordHistory',
        params: {recordId: id}
      });
      if (!result[0] || !result[0].length) {
        this.setState({history: []})
        return;
      }
      let history = result[0]
      for (let item of history) {
        if (item.Date) item.Date = moment(item.Date).format('L LT')
      }
      this.setState({history});
    } finally {
      this.setState({loading: false});
    }
  }

}

export default RecordHistory
