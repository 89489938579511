import BaseComponent from '../../BaseComponent';
import {Chat, User} from "@progress/kendo-react-conversational-ui";
import moment from 'moment';
// import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import Loader from '../../Common/Loader';
import NoSelectedBP from '../../Common/NoSelectedBP';
import styles from './chatHistory.module.scss';

/* interface IUrl {
	Offset: number
	Length: number
	Url: null // | string ??
} */
interface IContact {
  FirstName: string
  Id: number
  LastName: string | null
}

interface IMessage {
  author: User
  timestamp: Date
  text: string
}

/* interface IEntry {
	Add: Array<number> | null
	Del: null // | number ??
	Id: number
	Message: null | string
	ReplyToId: null | number
	Time: string //"2021-04-12 14:48:20"
	Urls: null | Array<IUrl>// ??
	UserId: number
} */

interface state {
  loading: boolean
  // entries: Array<IEntry>
  messages: Array<IMessage>
}

interface props {
  buildPlanId?: number
  isActive?: boolean
  doNotSaveFilters?: boolean
}

class BPChatHistory extends BaseComponent<props, state> {
  bpId = this.props.buildPlanId
  contacts: {
    [key: number]: {
      FirstName: string
      Id: number
      LastName: string | null
    }
  } = {}

  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      // entries: [],
      messages: []
    }
  }

  componentDidMount() {
    if (this.props.buildPlanId) this.LoadData();
  }

  componentDidUpdate() {
    if (this.props.isActive && this.bpId !== this.props.buildPlanId) {
      super.componentWillUnmount();
      this.bpId = this.props.buildPlanId
      this.LoadData();
    }
  }

  render() {
    if (!this.props.buildPlanId) return <NoSelectedBP/>;
    if (this.state.loading) return <Loader/>;
    // return <div style={{ height: '100%' }}>
    return <Chat
      className={styles.Chat}
      width={600}
      user={{id: 0}}
      messages={this.state.messages}
      showToolbar={false}
    />
    {/* <Grid
				data={this.state.entries}
				filterable={false}
				style={{ height: '100%' }}
			>
				<GridToolbar>
					<ToolbarSpacer></ToolbarSpacer>
					<Button icon="reload" onClick={this.Refresh}></Button>
				</GridToolbar>
				<Column
					field="Time"
					title="Date"
					width={150}
					cell={(props) => {
						let entryIndex = props.dataIndex
						let prevEntry = this.state.entries[entryIndex - 1]
						let dateString = ''
						let date = moment(props.dataItem.Time)
						if (entryIndex === 0 || date.isAfter(moment(prevEntry.Time), 'day')) {
							dateString = date.format('dddd, LL')
						}
						return <td>{dateString}	</td>
					}}
				/>
				<Column
					field="Time"
					title="Time"
					width={100}
					cell={(props) => <td>
						{moment(props.dataItem.Time).format('LT')}
					</td>}
				/>
				<Column
					field="UserId"
					title="User"
					width={200}
					cell={(props) => {
						let user = this.contacts[props.dataItem.UserId]
						return (
							<td>
								<b>{user ? `${user.FirstName || ''} ${user.LastName || ''}` : ''}</b>
							</td>
						)
					}}
				/>
				<Column
					field="Message"
					title="Message"
				/>
			</Grid> */
    }
    // </div>
  }

  async LoadData() {
    this.setState({loading: true})
    try {
      let params = {BuildPlanId: this.props.buildPlanId};
      let result: any = await this.GetSQLData({
        spName: 'DB_BPChatHistory',
        params
      });
      let data = JSON.parse(result[0].ChatHistory)
      let contacts: Array<IContact> = data.Contacts
      for (let user of contacts) {
        if (!this.contacts[user.Id]) {
          this.contacts[user.Id] = user
        }
      }
      let entries = data.Entries
      let messages: Array<IMessage> = []
      for (let entry of entries) {
        let user = this.contacts[entry.UserId]
        messages.push({
          timestamp: moment(entry.Time).toDate(),
          text: entry.Message,
          author: {
            id: user?.Id || 0,
            name: user ? `${user.FirstName || ''} ${user.LastName || ''}` : ''
          },
        })
      }
      this.setState({ /* entries,  */messages});
    } finally {
      this.setState({loading: false})
    }
  }

  Refresh = () => {
    this.LoadData()
  }
}

export default BPChatHistory;
