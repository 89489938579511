import {useEffect, useState} from 'react';
import TCTabsPage from '../../Pages/TCTabs';
import FXCard from '../Common/FXCard/FXCard';
import {OpenRecord} from '../../helpers/runscripts'
import {GetReferenceRecordName} from '../../helpers/queries'
import styles from './card.module.scss'
import {IFXCardProps} from './interfaces';
import OpenCardIconLink from './OpenCardIconLink';
import {IInitialTabSettings, tabId} from '../Tabs/interfaces';

interface IProps extends IFXCardProps {
    cardId: string
    Id: number
    initialTab: tabId | IInitialTabSettings
}

const TCCard = (props: IProps) => {
    const {Id: tcId} = props
    const [cardName, setCardName] = useState('')

    useEffect(() => {
        getTCName()
    }, [tcId])

    const getTCName = async () => {
        const Name = await GetReferenceRecordName(+tcId)
        setCardName(Name)
    }

    const OpenTC = () => {
        OpenRecord('TKTimeCards', tcId)
    }

    return <FXCard
        title={<>
            <OpenCardIconLink onClick={OpenTC} title="Open TC"/>
            <span
                className={styles.HeaderTitle}>{cardName || 'Loading...'}</span>
        </>}
        onClose={props.finally}
    >
        <TCTabsPage tcId={tcId}/>
    </FXCard>
}

export default TCCard;
