import styles from "./tabs.module.scss";
import {Button} from "@progress/kendo-react-buttons";
import dashboardStyles from "../Dashboard/dashboard.module.scss";
import React, {useRef} from "react";
import {tabId} from "./interfaces";
import {TABS} from "./helpers";
import {useRefresher} from "../../helpers/hooks";

interface IProps {
    activeTabId: tabId | undefined
    tabsRefs: { [key in tabId]?: any }
    exportToExcel: () => void
    refresh: () => void
    unSelectRow?: () => void
    children: any
}

export type Ref = (ref: any) => void;

const TabsToolbarRight = React.forwardRef<Ref, IProps>((props: IProps, ref: any) => {
    const {exportToExcel, refresh, unSelectRow, activeTabId, tabsRefs, children} = props
    const forceRefresh = useRefresher()
    const thisExternalRef = useRef({
        forceRefresh
    })
    if (ref) ref(thisExternalRef.current)

    const getGridRef = (activeTabId: tabId) => {
        const activeTabRef = tabsRefs[activeTabId];
        return activeTabRef?.current?.gridRef?.current || activeTabRef?.current || activeTabRef?.gridRef?.current
    }

    const setDefaultSettings = () => {
        if (!activeTabId) return
        const gridref = getGridRef(activeTabId)
        const SetDefaultSettingsFunc = gridref?.SetDefaultSettings
        if (SetDefaultSettingsFunc) SetDefaultSettingsFunc();
    }

    const IFilterInDefaultState = (activeTabId: tabId) => {
        const func = getGridRef(activeTabId)?.IsFiltersInDefault;
        return func ? func() : true
    }

    // render
    const {hasExportToExcel, hasSetDefaultFilters} = activeTabId && TABS[activeTabId] || {}
    const IsFiltersInDefaultState = hasSetDefaultFilters && activeTabId ? IFilterInDefaultState(activeTabId) : true;

    return <div className={styles.ToolbarRight}>
        {children}
        {hasSetDefaultFilters && (
            <Button
                onClick={setDefaultSettings}
                title="Set Default Filters"
                icon="filter-clear"
                className={
                    IsFiltersInDefaultState ? "" : dashboardStyles.BlueResetBtn
                }
            />
        )}
        {hasExportToExcel && (
            <Button
                icon="file-excel"
                title="Export to Excel"
                onClick={exportToExcel}
            ></Button>
        )}
        <Button icon="refresh" onClick={refresh}/>
        {!!unSelectRow && (
            <Button
                icon="close"
                style={{color: "red"}}
                onClick={unSelectRow}
            />
        )}
    </div>
})

export default TabsToolbarRight