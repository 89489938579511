import {Component} from 'react';
import {DataResult} from '@progress/kendo-data-query';
import {VariableSizeTree as Tree} from 'react-vtree';
import ExpandedHeader
  from '../../../Components/Common/ExpandedPanel/ExpandedHeader';
import ListItem from './MobileListItem'
import {IDocumentItem, IUpdateIncludedParams} from './interfaces';
import styles from './checklistResults.module.scss'
import {IsDisableChangeIncluded} from './helpers';
import {Switch} from "@progress/kendo-react-inputs";
import {ICLMSettings} from '../../../stores/interfaces';
import CLPMSettings from '../../../stores/CLPMSettings'
import {
  Group_Height,
  LIST_ITEM_HEIGHT
} from '../../../Components/Common/ExpandedPanel/helpers';

interface props {
  data: DataResult
  toolbarHeight: number
  remountListKey: number
  collapsedKey: number
  expandedSettings: { [key: string]: boolean }

  onItemClick(dataItem: IDocumentItem): void

  onMobileExpandeChange(id: string): void

  setClIncluded(params: IUpdateIncludedParams): void
}

interface state {
  popupActions: null | IDocumentItem
}

class ReviewInterfaceMobileList extends Component<props, state> {
  selectedEl: null | any = null
  scrollOffset: number = 0
  mobileCollapsedKey: number = this.props.collapsedKey
  settings: ICLMSettings | null = null

  constructor(props: any) {
    super(props);
    this.state = {
      popupActions: null,
    }
    this.treeWalker = this.treeWalker.bind(this)
  }

  async componentDidMount() {
    this.settings = await CLPMSettings.getSettings()
  }

  treeWalker = function* (refresh: boolean) {
    const stack = [];
    for (let ch of this.props.data.data) {
      stack.unshift({nestingLevel: 0, node: ch});
    }

    while (stack.length !== 0) {
      // @ts-ignore
      let item = stack.pop()
      // @ts-ignore
      const {node: {items = [], field}} = item;
      let dataItem = items[0]
      let id = ''
      let name = ''
      let nestingLevel = !field ? 1 : 0

      if (!field) {
        id = item.node.groupName.trim() + item.node.id
        name = `${item.node.index} ${item.node.cliName}`
      } else if (field && dataItem) {
        let itemsWithStatus = items.filter((item: IDocumentItem) => !!item.statusName)
        id = dataItem.groupName.trim()
        name = `${id} (${itemsWithStatus.length} - ${items.length})`
      }

      // @ts-ignore
      const isOpened = yield refresh ? {
        defaultHeight: nestingLevel === 1 ? LIST_ITEM_HEIGHT : Group_Height,
        id,
        isLeaf: items.length === 0,
        isOpenByDefault: this.props.expandedSettings[id],
        name,
        nestingLevel,
        rowData: item.node
      } : id;

      if (items.length !== 0 && isOpened) {
        for (let i = items.length - 1; i >= 0; i--) {
          stack.push({
            nestingLevel: 2,
            node: items[i],
          });
        }
      }
    }
  }

  render() {
    if (this.mobileCollapsedKey !== this.props.collapsedKey) {
      this.mobileCollapsedKey = this.props.collapsedKey
      this.scrollOffset = 0
    }

    return <>
      <Tree
        key={`${this.props.remountListKey}_${this.mobileCollapsedKey}`}
        treeWalker={this.treeWalker}
        height={window.innerHeight - this.props.toolbarHeight}
        width={'100%'}
        onScroll={this.OnScroll}
        initialScrollOffset={this.scrollOffset}
      >
        {this.renderRow}
      </Tree>
    </>
  }

  renderRow = (props: any) => {
    let rowData = props.data.rowData
    return (
      <div style={props.style}>
        {rowData?.field ?
          this.renderExpandHeader(props.data, props.isOpen, props.toggle) :
          this.renderCard(rowData)
        }
      </div>
    )
  }

  renderExpandHeader = (item: any, isOpen: boolean, toggle: any) => {
    let enableItemIndex = -1
    let included = true
    let arrowItem;
    if (this.settings?.IsReviewer) {
      let items: IDocumentItem[] = item.rowData.items
      enableItemIndex = items.findIndex((chItem) => !IsDisableChangeIncluded(chItem, this.settings!))
      let notIncludeditem = items.findIndex((chItem) => !chItem.included)
      included = notIncludeditem === -1
      arrowItem = <Switch
        className={styles.MobileGroupSwitch}
        checked={included}
        disabled={enableItemIndex === -1}
        id={item.id}
        size={'large'}
        onLabel={'Disable All'}
        offLabel={'Enable All'}
        onChange={() => {
          let dataItem = item.rowData.items[0]
          this.props.setClIncluded({
            Included: !included,
            CLID: dataItem.clId,
          })
        }}
      />
    }
    return <ExpandedHeader
      expanded={isOpen}
      level={item.nestingLevel + 1}
      dataId={item.id}
      isAllowToToggle={this.IsToggleAllowed}
      onToggle={(id: string) => {
        this.props.onMobileExpandeChange(id)
        toggle()
      }}
      content={item.name}
      arrowItem={arrowItem}
    />
  }

  renderCard = (item: IDocumentItem) => {
    if (item === undefined) return null
    return <ListItem
      data={item}
      settings={this.settings!}
      onClick={this.OnClick}
    />
  }

  IsToggleAllowed = (e: any) => e.target.closest('.k-switch') === null

  OnClick = (dataItem: IDocumentItem, el: any) => {
    this.props.onItemClick(dataItem)
  }

  OnScroll = (e: any) => {
    this.scrollOffset = e.scrollOffset
  }
}

export default ReviewInterfaceMobileList;
