import {Component} from 'react';
import WebIframe from '../WebIframe';

interface props {
}

interface state {
}

class CLPMCalendar extends Component<props, state> {
  paramsString: string = `?${new Date().valueOf()}`

  render() {
    let paramsiframe = {folder: 'CLPMCalendar', urlParams: this.paramsString}
    return (<>
      <WebIframe
        id="CLPMCalendarIframe"
        {...paramsiframe}
      />
    </>);
  }

}

export default CLPMCalendar;
