import {Component} from 'react';
import FXCard from '../Common/FXCard/FXCard';
import {Button} from '@progress/kendo-react-buttons';
import {IFXCardProps} from './interfaces';
import styles from './card.module.scss'
import {
  NumericTextBox,
  NumericTextBoxChangeEvent
} from '@progress/kendo-react-inputs';
import {simpleObject} from '../../helpers/interfaces';
import {RunScriptAsync} from '../../helpers/runscripts';
import {IEasyBudget} from '../../Pages/BudgetDashboard/interfaces';
import {Typography} from "@progress/kendo-react-common";
import Loader from '../Common/Loader';
import {GetReferenceRecordName, getSQLData} from '../../helpers/queries';

interface props extends IFXCardProps {
  buildPlanId: number

  afterSave(): void
}

interface state {
  invalidMessage: string
  processing: boolean
  name: string
}

class EasyBudgetCard extends Component<props, state> {
  PlannedRevenue: number | null = null
  LaborHours: number | null = null
  initData: IEasyBudget | null = null

  constructor(props: props) {
    super(props)

    this.state = {
      invalidMessage: this.GetInvalidMessage(),
      processing: true,
      name: '',
    }
  }

  componentDidMount() {
    this.LoadBPName()
    this.LoadInitData()
  }

  render() {
    return (<FXCard
      title={`AdHoc Budget`}
      onClose={this.props.finally}
      initialWidth={450}
      initialHeight={220}
      originalPaddings={true}
    >
      <div className={styles.FormWrapper}>
        {this.state.processing ? <Loader/> :
          <>
            <div>
              <Typography.h6>{this.state.name || 'Loading...'}</Typography.h6>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 8
              }}>
                <span style={{
                  width: 115,
                  flex: '0 0 auto'
                }}>Labor Cost ($): * </span>
                <NumericTextBox
                  defaultValue={this.PlannedRevenue}
                  onChange={this.OnChangePlannedRevenue}
                  required={true}
                  min={0}
                />
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span
                  style={{width: 115, flex: '0 0 auto'}}>Labor Hours: * </span>
                <NumericTextBox
                  defaultValue={this.LaborHours}
                  onChange={this.OnChangeLaborHours}
                  required={true}
                  min={0}
                />
              </div>
            </div>
            <div className={`${styles.FormFooter} k-action-buttons`}>
              <div style={{flex: 1}}>
                <div style={{color: 'red'}}>
                  {this.state.invalidMessage}
                </div>
              </div>
              <Button
                onClick={this.props.finally}
                disabled={this.state.processing}
              >Cancel</Button>
              <Button
                disabled={this.state.processing || !!this.state.invalidMessage}
                onClick={this.EditAdHocBudget}
                themeColor="primary"
              >Ok</Button>
            </div>
          </>}
      </div>
    </FXCard>);
  }

  GetInvalidMessage = () => this.PlannedRevenue !== null && this.LaborHours !== null ? '' : 'All fields are required.'

  CheckValidity = () => {
    this.setState({invalidMessage: this.GetInvalidMessage()})
  }

  EditAdHocBudget = async () => {
    if (!this.initData) return
    try {
      this.setState({processing: true})
      let params: simpleObject = {
        BuildPlanId: this.props.buildPlanId,
        AdHocLastBudgetID: this.initData.AdHocLastBudgetId,
        AdHocLastBudgetRevisionNum: this.initData.AdHocLastBudgetRevisionNum,
        PlannedRevenue: this.PlannedRevenue,
        LaborHours: this.LaborHours
      }
      await RunScriptAsync('WAFSM_EditAdHocBudget', params)
      this.props.afterSave()
    } finally {
      this.setState({processing: false})
      this.props.finally?.()
    }
  }

  OnChangePlannedRevenue = (event: NumericTextBoxChangeEvent) => {
    this.PlannedRevenue = event.value
    this.CheckValidity()
  }

  OnChangeLaborHours = (event: NumericTextBoxChangeEvent) => {
    this.LaborHours = event.value
    this.CheckValidity()
  }

  LoadBPName = async () => {
    let Name = await GetReferenceRecordName(+this.props.buildPlanId)
    this.setState({name: Name})
  }

  LoadInitData = async () => {
    try {
      let result: any = await getSQLData({
        spName: 'GetEasyBudgetData',
        params: {bpId: this.props.buildPlanId}
      });
      this.initData = result[0][0]
      if (this.initData) {
        this.PlannedRevenue = this.initData.PlannedRevenue
        this.LaborHours = this.initData.LaborHours
      }
    } finally {
      this.setState({processing: false})
    }
  }
}

export default EasyBudgetCard;
