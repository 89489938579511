import {Component} from 'react';
import {Checkbox} from '@progress/kendo-react-inputs';
import {IFilterSetting} from './interfaces';

interface props {
  className?: string
  filter: IFilterSetting
  defaultValue: any

  onChange(value: any, filter: any): void
}

interface state {
}

class CheckBoxFilter extends Component<props, state> {
  render() {
    let filter = this.props.filter;
    return <Checkbox
      id={'checkbox' + filter.id}
      className={this.props.className}
      label={filter.label}
      defaultChecked={this.props.defaultValue}
      onChange={this.OnChange}
    />

  }

  OnChange = (e: any) => {
    this.props.onChange(e.value, this.props.filter)
  }
}

export default CheckBoxFilter
