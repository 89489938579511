import {DataResult, process} from '@progress/kendo-data-query';
import {Button, Toolbar, ToolbarSpacer} from '@progress/kendo-react-buttons';
import {MultiSelectTree} from "@progress/kendo-react-dropdowns";
import {
  Grid,
  GridColumn as Column,
  GridToolbar
} from '@progress/kendo-react-grid';
import {Checkbox} from '@progress/kendo-react-inputs';
import {Menu, MenuItem} from '@progress/kendo-react-layout';
import {Popup} from "@progress/kendo-react-popup";
import "hammerjs";
import moment from 'moment';
import React from 'react';
import {SplitPane} from 'react-multi-split-pane';
import {GetDocumentUrl, sortByNameCombobox} from '../../helpers/helpers';
import {
  IComboboxItem,
  ISortGridItem,
  simpleObject
} from '../../helpers/interfaces';
import {RunScriptAsync} from '../../helpers/runscripts';
import CLPMSettings from '../../stores/CLPMSettings';
import BaseComponent from '../BaseComponent';
import CardManagement from '../Cards/CardManagement';
import OpenCardLink from '../Common/Buttons/OpenCardLink';
import DocumentViewer from '../Common/DocumentViewer/DocumentViewer';
import {DOCS_EXT, IMAGES_EXT} from '../Common/DocumentViewer/helpers';
import ClearableInput from '../Common/Form/ClearableInput';
import Loader from '../Common/Loader';
import {ModalRef} from '../Common/Modal/Modal';
import BooleanFilter from '../Dashboard/BooleanFilter';
import CustomColumnMenu from '../Dashboard/ColumnMenu';
import ComboboxFilterVirtual from '../Dashboard/ComboboxFilterVirtual';
import gridStyles from '../Dashboard/dashboard.module.scss';
import {
  DEFAULT_OPERATOR,
  DisableColumnMenuBtn,
  GetDefaultGridFilter,
  GridRowHeight,
  IsComplexGridFilter
} from '../Dashboard/helpers';
import {IColumnValue, IGridFilter} from '../Dashboard/interfaces';
import MobileList from './MobileList';
import {
  documentAction,
  GetDataItemFromGroup,
  groupFieldName,
  LoadDocuments
} from './helpers';
import {
  IColumnProps,
  IRelatedDocumentItem,
  IServerRelatedDocument,
  ISrcObjectTypeItem
} from './interfaces';
import styles from './relatedDocuments.module.scss';

interface props {
  buildPlanId?: number
  isDaily?: boolean
  isCLM?: boolean
  isActive: boolean
  doNotSaveFilters?: boolean
  pageId?: string
  SourceId?: number
  isMobile: boolean
}

interface state {
  bploading: boolean
  loading: boolean
  documentLoading: boolean
  imgLoading: boolean
  documents: DataResult
  previewDocument: string | null
  sort: Array<ISortGridItem>
  selectedFilterObjects: number
  buildPlans: Array<IComboboxItem>
  gridFilter: IGridFilter
  contextMenu: null | { left: number, top: number }
  remountMobileListKey: number
  mobileCollapsedKey: number
  disableNext: boolean
  disablePrev: boolean
}

const typeFilterPlaceholder = 'Filter by Documents Structure'
const GROUP: Array<{
  field: keyof IRelatedDocumentItem,
  dir: 'asc' | 'desc'
}> = [
  {field: 'SrcObjType', dir: "asc"},
  {field: 'ObjectSubGroupId', dir: "asc"},
]

class RelatedDocuments extends BaseComponent<props, state> {
  mobileGroupsDefaultExpanded = false
  contextMenuDoc: IRelatedDocumentItem | null = null
  isBuildPlanSourceObjectId: boolean = false
  gridRef: any = React.createRef()
  bpId: number | undefined = this.props.buildPlanId
  selectedBP: IComboboxItem | undefined
  group: Array<{ field: keyof IRelatedDocumentItem, dir: 'asc' | 'desc' }> = []
  expandAll: boolean = false
  filterTypeKindValue: string = ''
  filterChangeTimeout: any
  sort: Array<ISortGridItem> = []
  documents: Array<IRelatedDocumentItem> = []
  selected: IRelatedDocumentItem | undefined
  treeTypes: Array<ISrcObjectTypeItem> = []
  gridFilter: IGridFilter = GetDefaultGridFilter()
  selectedFilterObjects: number = 0
  authors: Array<IColumnValue> = []
  isExternalBrowserPage: boolean = this.props.SourceId !== undefined
  COLUMNS: Array<IColumnProps>
  mobileExpandedSettings: {
    [key: string]: boolean
  } = {}

  constructor(props: any) {
    super(props);
    if (this.props.isDaily) {
      this.gridFilter.filters.push({
        field: 'IsPublished',
        operator: 'eq',
        value: true
      })
    }
    this.state = {
      bploading: false,
      loading: false,
      documentLoading: false,
      imgLoading: false,
      sort: [],
      documents: {data: [], total: 0},
      previewDocument: null,
      selectedFilterObjects: 0,
      buildPlans: [],
      gridFilter: this.gridFilter,
      contextMenu: null,
      remountMobileListKey: +new Date(),
      mobileCollapsedKey: +new Date(),
      disableNext: false,
      disablePrev: false,
    }
    this.COLUMNS = [
      {
        field: 'IsPublished',
        title: 'Publ.',
        width: 37,
        cell: this.renderIsPublishedCell
      },
      {
        field: "DocumentName",
        title: "Source Object and Document"
      },
      {
        field: "AuthorName",
        title: "Author",
        width: 100,
        columnMenu: this.renderColumnMenu
      },
      {
        field: "CreatedDate",
        title: "Created",
        width: 130,
        filter: "date",
        cell: this.renderCreatedCell,
        columnMenu: this.renderColumnMenu
      }
    ]
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    if (this.filterChangeTimeout) clearTimeout(this.filterChangeTimeout)
    document.removeEventListener("click", this.CloseContextMenu);
  }

  async componentDidMount() {
    if (this.props.isCLM) await this.LoadClmBuildPlans()
    this.LoadData();

    if (this.props.isMobile) {
      let el = document.querySelector('#RelatedDocuments')
      // @ts-ignore
      var hammertime = new Hammer(el);
      // @ts-ignore
      hammertime.get('swipe').set({direction: Hammer.DIRECTION_VERTICAL});
      hammertime.on('swipedown', this.Refresh);
    }
  }

  componentDidUpdate() {
    if (!this.props.isCLM && this.props.isActive && this.props.buildPlanId && this.bpId !== this.props.buildPlanId) {
      this.expandAll = false
      this.bpId = this.props.buildPlanId
      this.selected = undefined
      this.LoadData();
    }
  }

  render() {
    let sourceId = this.GetSourceId()

    if (this.props.isMobile) {
      return <div id="RelatedDocuments">
        {this.state.loading && <Loader/>}
        <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
          <Toolbar className={`${styles.MobileToolbar} ${styles.Toolbar}`}>
            <Button
              icon="plus"
              onClick={this.NewDocument}
              size="large"
            >New Documents</Button>
            <ToolbarSpacer/>
            <Button
              iconClass={`${styles.ExpandIcon} mdi mdi-${this.expandAll ? 'collapse-all-outline' : 'expand-all-outline'}`}
              title={this.expandAll ? 'Collapse All' : 'Expand All'}
              onClick={this.ToggleExpandGroup}
              size="large"
            />
            <Button
              icon="refresh"
              onClick={this.Refresh}
              size="large"
            />
          </Toolbar>
          <MobileList
            remountListKey={this.state.remountMobileListKey}
            data={this.state.documents}
            expandedSettings={this.mobileExpandedSettings}
            onMobileExpandeChange={this.OnMobileExpandChange}
            onChangePublish={this.ChangePublishDocument}
            showDocument={this.MobileShowDocument}
            onAction={this.DoAction}
            collapsedKey={this.state.mobileCollapsedKey}
            group={this.group}
            onItemClick={this.MobileSelectRow}
          />
          {this.renderMobilePreviewPopup()}
        </div>
      </div>
    }

    return <>
      <SplitPane split="vertical" defaultSizes={[1, 1]} minSize={[400, 400]}>
        <Grid
          ref={this.gridRef}
          className={styles.Grid}
          style={{height: '100%', width: '100%'}}
          data={this.state.documents}
          groupable={false}
          group={this.group}
          onExpandChange={this.ExpandChange}
          expandField="expanded"
          selectedField="Selected"
          onRowClick={this.OnClickRow}
          filterable={true}
          filterCellRender={this.renderFilterCell}
          filter={this.state.gridFilter}
          sortable={true}
          sort={this.state.sort}
          onSortChange={this.OnSort}
          rowHeight={GridRowHeight}
          rowRender={this.renderGridRow}
          cellRender={this.renderCell}
        >
          <GridToolbar>
            {this.props.isCLM &&
                <div className={styles.CLMFilterRow}>
                    <ComboboxFilterVirtual
                        key={this.state.bploading + 'bps'}
                        data={this.state.buildPlans}
                        loading={this.state.bploading}
                        onChange={this.OnChangeBuildPlan}
                        filter={{
                          id: 'buildPlans',
                          placeholder: 'Filter by Build Plan',
                          type: 'combobox',
                          width: 350
                        }}
                        defaultValue={this.selectedBP}
                        required={true}
                    />
                    <ToolbarSpacer/>
                  {this.renderRightButtons()}
                </div>
            }

            {!this.props.isCLM && !!sourceId && <>
                <Button
                    icon="plus"
                    onClick={this.NewDocument}
                >New Documents</Button>
            </>}
            {this.renderMultiSelect()}
            <ToolbarSpacer/>
            {!this.props.isCLM && !!sourceId && this.renderRightButtons()}
          </GridToolbar>
          {this.COLUMNS.map((props) => {
            let field = props.field
            let headerClassName = this.GetColumnHeaderClass(field)
            return <Column
              key={field}
              headerClassName={headerClassName}
              {...props}
            />
          })}
        </Grid>
        <div className={styles.RightPanel}>
          {this.renderContextMenu()}
          <Toolbar className={styles.Toolbar}>
            <ToolbarSpacer/>
            <div className={gridStyles.RightBtns}>
              {this.state.previewDocument && <>
                {!!this.selected?.AllowDelete && <Button
                    title="Delete Document"
                    icon="trash"
                    onClick={this.OnDeleteClick}
                    fillMode="flat"
                />}
                  <Button
                      title="Download Document"
                      icon="download"
                      onClick={this.OnDownloadClick}
                      fillMode="flat"
                  />
                  <a
                      className="k-button k-button-md k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icon-button"
                      href={this.state.previewDocument}
                      target="__blank"
                      title="Open Document in New Tab"
                  >
                      <span className="k-icon k-i-hyperlink-open"></span>
                  </a>
              </>}
            </div>
          </Toolbar>
          {this.state.documentLoading && <Loader/>}
          {this.renderDocumentPreview()}
        </div>
      </SplitPane>
      {this.state.loading && <Loader/>}
    </>
  }

  renderCell = (el: any, props: any) => {
    if (props.rowType === 'groupHeader') {
      if (el === null || !el?.props?.children) return el
      let dataItem = props.dataItem
      let colSpan = dataItem.items[0].items ? 6 : 5
      return <td colSpan={colSpan}>
        <div className={styles.GroupTD}>
          {el?.props?.children?.props?.children[0]}
          {dataItem.value}
        </div>
      </td>
    }
    return el
  }

  renderMobilePreviewPopup = () => {
    if (!this.selected || !this.state.previewDocument) return null
    return <div className={styles.PopupPreview}>
      <Toolbar className={`${styles.MobileToolbar} ${styles.Toolbar}`}>
        <div style={{flex: 1}}> {this.selected.DocumentName} </div>
      </Toolbar>
      {this.state.documentLoading && <Loader/>}
      {this.renderDocumentPreview()}
      <Toolbar className={`${styles.MobileToolbar} ${styles.Toolbar}`}>
        {this.renderArrows()}
        <div className={styles.BottomToolbarBtns}>
          {this.state.previewDocument && <>
              <Button
                  title="Download Document"
                  icon="download"
                  onClick={this.OnDownloadClick}
                  fillMode="flat"
              />
              <a
                  className="k-button k-button-md k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icon-button"
                  href={this.state.previewDocument}
                  target="__blank"
                  title="Open Document in New Tab"
              >
                  <span className="k-icon k-i-hyperlink-open"></span>
              </a>
          </>}
        </div>
        <ToolbarSpacer/>
        <Button
          title="Close Preview"
          icon="close"
          onClick={this.OnMobileHideDocument}
          fillMode="flat"
        />
      </Toolbar>
    </div>
  }

  renderContextMenu = () => {
    return <Popup
      offset={this.state.contextMenu || undefined}
      show={!!this.state.contextMenu}
    >
      <Menu
        vertical={true}
        onSelect={this.OnSelectContextItem}
      >
        <MenuItem key={'Download'} data={'Download'}
                  text="Download document"></MenuItem>
        {this.contextMenuDoc?.AllowUpload &&
            <MenuItem key={'Upload'} data={'Upload'}
                      text="Upload new File"></MenuItem>}
        {this.contextMenuDoc?.AllowChangeKind &&
            <MenuItem key={'ChangeKind'} data={'ChangeKind'}
                      text="Change Kind"></MenuItem>}
        {/* <MenuItem key={'Send'} data={'Send'} text="Send by email"></MenuItem> */}
        {this.contextMenuDoc?.AllowDelete &&
            <MenuItem key={'Delete'} data={'Delete'}
                      text="Delete Document"></MenuItem>}
      </Menu>
    </Popup>
  }

  renderMultiSelect = () => {
    if (!this.group.length) return null
    let objects = this.state.selectedFilterObjects
    let placeholder = objects ? `${objects} Object${objects > 1 ? 's' : ''} Selected` : typeFilterPlaceholder

    return <MultiSelectTree
      className={`${styles.MultiSelect} ${!objects ? styles.MultiSelectNoSelected : ''}`}
      data={this.treeTypes}
      value={[{}]}
      onChange={this.OnChangeTypeFilter}
      placeholder={typeFilterPlaceholder}
      textField={'Name'}
      dataItemKey={'Id'}
      checkField={'checked'}
      expandField={'expanded'}
      onExpandChange={this.OnExpandChange}
      tags={[{text: placeholder, data: []}]}
      popupSettings={{popupClass: styles.MultiSelectPopup}}
    />
  }

  renderRightButtons = () => {
    return <div className={gridStyles.RightBtns}>
      {!!this.group.length && <Button
          iconClass={`${styles.ExpandIcon} mdi mdi-${this.expandAll ? 'collapse-all-outline' : 'expand-all-outline'}`}
          title={this.expandAll ? 'Collapse All' : 'Expand All'}
          onClick={this.ToggleExpandGroup}
      />}
      <Button
        icon="refresh"
        onClick={this.Refresh}
      />
    </div>
  }

  getDataItemFromGroupTree = (field: string, dataItem: any) => {
    let groupIndex = this.group.findIndex((g) => g.field === field)
    let deepLevel = this.group.length - groupIndex - 1
    let resultItem = dataItem.items[0]
    while (deepLevel > 0) {
      deepLevel--
      resultItem = resultItem.items[0]
    }
    return resultItem
  }

  renderGridRow = (row: any, props: any) => {
    if (props.rowType === 'groupHeader') {
      let field: keyof IRelatedDocumentItem = props.dataItem.field
      let data: IRelatedDocumentItem = this.getDataItemFromGroupTree(field, props.dataItem)
      let fieldName: keyof IRelatedDocumentItem = groupFieldName[field]
      let itemsCount = 0
      let groupItems = props.dataItem.items
      if (groupItems[0].items) {
        for (let group of groupItems) {
          itemsCount += group.items.length
        }
      } else {
        itemsCount += groupItems.length
      }
      let text = data[fieldName] + ` (${itemsCount})`
      if (field === "SrcObjName") {
        props.dataItem.value = <OpenCardLink
          dataAttr={data.SrcObjId}
          text={text}
          refName={data.SrcObjType}
        />
      } else {
        // @ts-ignore
        props.dataItem.value = text
      }
      return <tr className={row.props.className} style={row.props.style}>
        {row.props.children}
      </tr>
    }
    return (<tr
      {...row.props}
      onContextMenu={this.OpenContextMenu}
      className={row.props.className}
      data-documentid={props.dataItem.DocumentId}
    >{row.props.children}</tr>);
  }

  renderFilterCell = (el: any, props: simpleObject) => {
    if (props.field === 'action') return null
    if (props.field === 'IsPublished') {
      return <BooleanFilter
        trueText="Published for Field"
        falseText="Not Published for Field"
        id="Published"
        onChange={this.OnPublishedFilterChange}
        defaultValue={props.value === true || props.value === false ? props.value : undefined}
      />
    }
    let value = ''
    let field: keyof IRelatedDocumentItem = props.field
    if (field === 'CreatedDate') field = 'CreatedFormatted'
    let isDocNameFilter = props.field === 'DocumentName'
    if (isDocNameFilter) {
      value = this.filterTypeKindValue
    } else {
      let filter = this.gridFilter.filters.find((f) => !IsComplexGridFilter(f) && f.field === field)
      value = filter && !IsComplexGridFilter(filter) ? filter.value : ''
    }
    return <ClearableInput
      key={props.field}
      defaultValue={value}
      dataprops={field}
      onChange={isDocNameFilter ? this.OnChangeDocNameFilter : this.OnChangeTextGridFilter}
      clear={isDocNameFilter ? this.ClearDocNameFilter : this.ClearTextGridFilter}
    />
  }

  renderIsPublishedCell = (props: any) => {
    if (props.rowType === 'groupHeader') return null
    let dataItem: IRelatedDocumentItem = props.dataItem
    return <td id={props.id} style={props.style} className={props.className}>
      <Checkbox
        id={'publish' + dataItem.Id}
        data-id={dataItem.Id}
        checked={dataItem.IsPublished}
        disabled={!dataItem.AllowPublish}
        onChange={this.OnChangePublish}
        label=" "
      />
    </td>
  }

  renderCreatedCell = (props: any) => {
    if (props.rowType === 'groupHeader') return null
    let dataItem: IRelatedDocumentItem = props.dataItem
    return <td id={props.id} style={props.style} className={props.className}>
      {dataItem.CreatedFormatted}
    </td>
  }

  renderDocumentPreview = () => {
    let selected = this.selected
    if (!selected || !this.state.previewDocument) return null
    let containerId = 'bp-documents'
    if (this.props.isDaily) containerId += '-daily'
    else if (this.props.isCLM) containerId += '-clm'
    else containerId += (this.props.pageId || 'viewer')

    return <DocumentViewer
      containerId={containerId}
      key={'document' + selected.DocumentId}
      document={selected ? {
        id: selected.DocumentId,
        extension: selected.Extension,
        previewUrl: this.state.previewDocument
      } : undefined}
      documentLoading={this.state.documentLoading}
    />
  }

  renderArrows = () => {
    if (!this.selected || !this.state.previewDocument) return null
    return <div className={styles.ResultsNav}>
      <Button
        icon="caret-double-alt-left"
        data-action="prev"
        onClick={this.OnNavResults}
        fillMode='flat'
        disabled={this.state.disablePrev}
      />
      <Button
        icon="caret-double-alt-right"
        data-action="next"
        onClick={this.OnNavResults}
        fillMode='flat'
        disabled={this.state.disableNext}
      />
    </div>
  }

  renderColumnMenu = (props: any) => {
    let field: keyof IRelatedDocumentItem = props.column.field
    let isDateColumn = field === 'CreatedDate'
    return <CustomColumnMenu
      defaultProps={props}
      onCloseMenu={this.OnCloseColumnMenu}
      getColumnValues={isDateColumn ? undefined : this.GetAuthors}
      filterByValues={isDateColumn ? undefined : this.OnSubmitFilterByColumnValues}
      fieldId={isDateColumn ? undefined : props.column.field}
      filterable={isDateColumn}
      filterSubmit={isDateColumn ? this.OnSubmitCreatedFilter : undefined}
    />
  }

  OnMobileExpandChange = (id: string) => {
    this.mobileExpandedSettings[id] = !this.mobileExpandedSettings[id]
    let isExpandedAll = true
    for (let id in this.mobileExpandedSettings) {
      if (isExpandedAll) isExpandedAll = this.mobileExpandedSettings[id]
    }
    if (this.expandAll !== isExpandedAll) {
      this.expandAll = isExpandedAll
      this.forceUpdate()
    }
  }

  GetSourceId = () => this.isExternalBrowserPage ? this.props.SourceId : this.bpId

  GetColumnHeaderClass = (field: keyof IRelatedDocumentItem) => {
    if (field !== 'CreatedDate' && field !== 'AuthorName') return undefined

    let filtered = this.state.gridFilter.filters.find((filter) => {
      if (!IsComplexGridFilter(filter) && filter.field === field && filter.value) return true
      if (IsComplexGridFilter(filter)) {
        let firstFilter = filter.filters[0]
        if (!IsComplexGridFilter(firstFilter) && firstFilter.field === field) return true
      }
      return false
    })
    return filtered ? `${field} ${gridStyles.FilteredColumnTH}` : ''
  }

  OpenContextMenu = (e: any) => {
    e.nativeEvent.preventDefault()
    let left = e.nativeEvent.clientX
    let top = e.nativeEvent.clientY
    let documentId = e.currentTarget.dataset.documentid
    let documentData = this.documents.find((d) => d.DocumentId == documentId)
    if (!documentData) return
    this.contextMenuDoc = documentData
    let contextMenu = {left, top}
    this.setState({contextMenu})
    document.addEventListener("click", this.CloseContextMenu);
  }

  CloseContextMenu = () => {
    this.contextMenuDoc = null
    this.setState({contextMenu: null})
    document.removeEventListener("click", this.CloseContextMenu);
  }

  OnSelectContextItem = (e: any) => {
    let action: documentAction = e.item.data
    if (this.contextMenuDoc && action) this.DoAction(action, this.contextMenuDoc)
  }

  DoAction = async (action: documentAction, dataItem: IRelatedDocumentItem) => {
    let id = dataItem.DocumentId
    switch (action) {
      case 'Upload':
        this.UploadDocuments(id)
        break;

      case 'Download':
        this.DownloadDocument(id)
        break;

      /*  case 'Send':
        this.SendDocumentByEmail(this.contextMenuDoc?.DocumentId)
        break; */

      case 'Delete':
        await this.ConfirmDeleteDocument(id)
        break;

      case 'ChangeKind':
        await this.OnChangeKind(id, dataItem.DocKindId)
        break

    }
  }

  ConfirmDeleteDocument = async (documentId: number) => {
    await new Promise((resolve) => {
      ModalRef.showDialog({
        title: 'Confirmation',
        text: 'Are you sure that you want to delete the document?',
        buttons: [
          {
            text: 'Cancel',
            action: () => {
              resolve(1)
              ModalRef.hideDialog();
            }
          },
          {
            text: 'Ok',
            color: 'primary',
            action: async () => {
              await this.DeleteDocument(documentId)
              resolve(1)
              ModalRef.hideDialog();
            }
          }
        ]
      });
    });
  }

  OnChangeKind = async (documentId: number, kindId: number | null) => {
    CardManagement.OpenChangeKindCard(documentId, kindId, this.Refresh)
  }

  OnCloseColumnMenu = () => {
    DisableColumnMenuBtn(this.gridRef, gridStyles.DisabledColumnMenuBtn)
  }

  GetAuthors = () => this.authors

  OnSubmitCreatedFilter = (filter: IGridFilter, fieldId: string, fieldName: string) => {
    this.gridFilter = filter || GetDefaultGridFilter()
    this.SetDocuments()
  }

  OnSubmitFilterByColumnValues = (
    filters: Array<any>,
    values: Array<IColumnValue>,
    fieldId: string,
    fieldName: string
  ) => {
    this.gridFilter.filters = this.gridFilter.filters.filter((f) => {
      if (!IsComplexGridFilter(f)) return true
      let firstfilter = f.filters[0]
      if (!IsComplexGridFilter(firstfilter) && firstfilter.field !== 'AuthorName') return true
      return false
    })

    if (filters.length && filters.length < values.length) {
      let filter: IGridFilter = {
        filters: [], logic: 'or'
      }
      for (let item of filters) {
        filter.filters.push(item)
      }
      this.gridFilter.filters.push(filter)
    }
    this.authors = values
    this.SetDocuments()
  }

  RemoveDocNameFilter = () => {
    this.gridFilter.filters = this.gridFilter.filters.filter((f) => {
      if (!IsComplexGridFilter(f)) return true
      let firstfilter = f.filters[0]
      if (!IsComplexGridFilter(firstfilter) && firstfilter.field !== 'DocumentName') return true
      return false
    })
  }

  ClearDocNameFilter = () => {
    this.RemoveDocNameFilter()
    this.SetDocuments()
  }

  ClearTextGridFilter = (field: string) => {
    this.filterTypeKindValue = ''
    this.gridFilter.filters = this.gridFilter.filters.filter((f) => !IsComplexGridFilter(f) && f.field !== field)
    this.SetDocuments()
  }

  DownloadDocument = async (documentId: number) => {
    let url = await GetDocumentUrl(documentId, false)
    if (url) window.location.href = url
  }

  /* SendDocumentByEmail = async (documentId: number) => {
    let url = await GetDocumentUrl(documentId, false)
    if (url) {
      window.location.href = 'mailto:?cc=&subject=&body=&file=' + url
    }
  } */

  OnDownloadClick = () => {
    if (this.selected) this.DownloadDocument(this.selected.DocumentId)
  }

  OnDeleteClick = () => {
    if (this.selected) this.ConfirmDeleteDocument(this.selected.DocumentId)
  }

  DeleteDocument = async (documentId: number) => {
    try {
      this.setState({documentLoading: true})
      await RunScriptAsync(
        `Documents_Delete`,
        {IDs: documentId}
      )
      this.selected = undefined
      this.Refresh()
    } finally {
      this.setState({documentLoading: false})
    }
  }

  NewDocument = () => {
    this.UploadDocuments(undefined, this.isBuildPlanSourceObjectId)
  }

  UploadDocuments = (documentId?: number, isBuildPlanDocument?: boolean) => {
    let sourceId = this.GetSourceId()
    CardManagement.UploadDocumentsCard(!!isBuildPlanDocument, sourceId, this.Refresh, documentId)
  }

  LoadData = async () => {
    if (!this.isExternalBrowserPage && this.bpId === undefined) {
      this.selected = undefined
      this.setState({
        documents: {data: [], total: 0},
        selectedFilterObjects: 0,
        previewDocument: null,
        remountMobileListKey: +new Date()
      });
      return
    }
    try {
      this.setState({loading: true, previewDocument: null})
      let SourceId = this.GetSourceId()
      if (SourceId !== undefined) {
        let result = await LoadDocuments(SourceId);
        this.isBuildPlanSourceObjectId = result.settings.IsBuildPlan
        this.ProcessingData(result.documents)
      }
    } finally {
      this.setState({loading: false})
    }
  }

  ProcessingData = (documents: Array<IServerRelatedDocument>) => {
    let initialDocuments = documents || []
    this.documents = []
    let authorsUnic: { [key: string]: boolean } = {}
    this.authors = []
    let unicTypes: {
      [key: string]: {
        type: string
        name: string
        unicObjects: { [key: string]: string }
      }
    } = {}
    let typesLength = 0
    let objectsLength = 0
    for (let item of initialDocuments) {
      let type = item.SrcObjType
      if (!unicTypes[type]) {
        unicTypes[type] = {
          type: type,
          name: item.SrcObjTypeName,
          unicObjects: {},
        }
        typesLength += 1
      }

      let ObjectSubGroupName = '', ObjectSubGroupId = ''
      if (item.SrcObjType === "FSMBuildPlans") {
        ObjectSubGroupName = item.DocKindName || 'No Kind Selected';
        ObjectSubGroupId = `kind_${item.DocKindId || 'null'})`
      } else {
        ObjectSubGroupName = item.SrcObjName;
        ObjectSubGroupId = `obj_${item.SrcObjId}`
      }

      if (!unicTypes[type].unicObjects[ObjectSubGroupId]) {
        objectsLength += 1
        unicTypes[type].unicObjects[ObjectSubGroupId] = ObjectSubGroupName
      }

      let sourceId = this.GetSourceId()
      let createdDate = moment(item.Created)
      let document: IRelatedDocumentItem = {
        ...item,
        DocKindName: item.DocKindId === null ? 'Not Selected' : item.DocKindName,
        ObjectSubGroupName,
        ObjectSubGroupId,
        Selected: !!this.selected && this.selected.Id === item.Id,
        DocumentName: `${item.DocumentName}.${item.Extension}`,
        CreatedFormatted: createdDate.format('L LT'),
        CreatedDate: createdDate.set('h', 0).set('m', 0).set('s', 0).toDate()
      }
      if (document.Selected) this.selected = document
      if (!authorsUnic[item.AuthorName]) {
        authorsUnic[item.AuthorName] = true
        this.authors.push({
          Selected: true,
          FieldId: 'AuthorName',
          Id: item.AuthorName,
          Name: item.AuthorName
        })
      }
      this.documents.push(document)
    }
    this.authors.sort(sortByNameCombobox)
    this.treeTypes = []
    this.group = []
    if (typesLength === 1) {
      this.mobileGroupsDefaultExpanded = true
      this.expandAll = true
    }
    if (typesLength > 1 || objectsLength > 1) {
      let treeTypes: Array<ISrcObjectTypeItem> = []
      for (let type in unicTypes) {
        let item = unicTypes[type]
        let treeItem: ISrcObjectTypeItem = {
          Id: type,
          Name: item.name,
          expanded: true,
          checked: false,
          items: []
        }

        for (let objId in item.unicObjects) {
          let object = item.unicObjects[objId]
          treeItem.items.push({
            Id: objId,
            Name: object,
            checked: false,
            Type: type
          })
        }
        treeTypes.push(treeItem)
      }
      this.treeTypes = treeTypes
      this.group = GROUP
    }
    this.SetDocuments()
    if (this.selected) this.SelectRow(this.selected, true)
  }

  LoadClmBuildPlans = async () => {
    this.setState({bploading: true})
    try {
      let buildPlans = await CLPMSettings.getActiveBuildPlans()
      this.selectedBP = CLPMSettings.getSelectedBP(this.bpId) || undefined
      this.bpId = this.selectedBP && +this.selectedBP.Id
      this.setState({buildPlans})
    } finally {
      this.setState({bploading: false})
    }
  }

  OnChangeBuildPlan = (value: IComboboxItem | null) => {
    if (value) {
      this.bpId = +value.Id
      this.selectedBP = value
      localStorage.setItem(CLPMSettings.CLM_BUILDPLAN_LS, JSON.stringify(value))
      this.LoadData()
    } else {
      this.bpId = undefined
      this.selectedBP = undefined
      localStorage.removeItem(CLPMSettings.CLM_BUILDPLAN_LS)
      this.setState({
        documents: {data: [], total: 0},
        remountMobileListKey: +new Date(),
        selectedFilterObjects: 0,
        previewDocument: null
      });
    }
  }

  OnChangePublish = (e: any) => {
    let rowId = e.nativeEvent.target.dataset.id;
    let document = this.documents.find(d => d.Id == rowId);
    if (!document) throw new Error('Cannot find document by id ' + rowId);
    this.ChangePublishDocument(document?.DocumentId, e.value)
  }

  ChangePublishDocument = async (documentId: number, published: boolean) => {
    this.setState({loading: true})
    try {
      await this.GetSQLData({
        spName: 'FX_PublishDocument',
        params: {
          documentId,
          published
        }
      })
    }/*  catch (error: any) {
    } */ finally {
      // this.setState({ loading: false })
      this.Refresh()
    }
  }

  OnSort = (e: any) => {
    this.setState({sort: e.sort})
    this.sort = e.sort
    this.group[0].dir = e.sort[0]?.dir
    this.SetDocuments()
  }

  OnClickRow = (e: any) => {
    let selected = e.dataItem
    if (this.selected && this.selected.Id === selected.Id) return
    this.SelectRow(selected)
  }

  OnMobileHideDocument = () => {
    this.setState({previewDocument: null})
  }

  OnNavResults = (e: any) => {
    if (!this.selected) return
    let action: 'next' | 'prev' = e.currentTarget.dataset.action
    this.NavResults(action)
  }

  NavResults = (action: 'next' | 'prev') => {
    let selected = this.selected
    if (!selected) return
    let disableNext = false
    let disablePrev = false
    if (action === 'next' && this.state.disableNext || action === 'prev' && this.state.disablePrev) return

    let selectedGroupNames = []
    let selectedGroupName = ''
    for (let gri = 0; gri < this.group.length; gri++) {
      let groupField: keyof IRelatedDocumentItem = this.group[gri].field
      selectedGroupName = `${selectedGroupName}_${selected[groupField]}`
      selectedGroupNames.push(selected[groupField])
    }

    let groupedData = this.state.documents.data
    let groupedLevel = 0
    for (let gi = 0; gi < groupedData.length; gi++) {
      let group = groupedData[gi]
      let groupName = group.value
      if (selectedGroupNames[groupedLevel] !== groupName) {
        continue
      }

      groupedLevel += 1
      for (let sgi = 0; sgi < group.items.length; sgi++) {
        let subGroup = group.items[sgi]
        if (subGroup.items) {
          let groupName = subGroup.value
          if (selectedGroupNames[groupedLevel] !== groupName) {
            continue
          }
        }
        for (let ii = 0; subGroup.items.length; ii++) {
          let dataItem = subGroup.items[ii]
          if (dataItem.Id !== selected.Id) continue
          let newItem: IRelatedDocumentItem | undefined
          if (action === 'next') {
            let nextGroup = group.items[sgi + 1] || groupedData[gi + 1]?.items[0]
            newItem = subGroup.items[ii + 1] || nextGroup.items[0]
            if (!subGroup.items[ii + 2] && ((!nextGroup || nextGroup.items.length === 1) && !groupedData[gi + 2])) {
              disableNext = true
            }
          } else if (action === 'prev') {
            let prevSubGroupItems = group.items[sgi - 1]?.items
            let prevGroupItems = groupedData[gi - 1]?.items
            let prevGroupLastSubGroupItems = prevGroupItems?.[prevGroupItems.length - 1].items
            newItem = subGroup.items[ii - 1] || prevSubGroupItems?.[prevSubGroupItems.length - 1] || prevGroupLastSubGroupItems?.[prevGroupLastSubGroupItems.length - 1]

            if (subGroup.items[ii - 1]) {
              disablePrev = !subGroup.items[ii - 2] && !prevSubGroupItems && !prevGroupLastSubGroupItems?.[prevGroupLastSubGroupItems.length - 1]
            } else if (prevSubGroupItems?.[prevSubGroupItems.length - 1]) {
              disablePrev = prevSubGroupItems?.length === 1 && !prevGroupLastSubGroupItems
            } else if (prevGroupLastSubGroupItems?.[prevGroupLastSubGroupItems.length - 1]) {
              disablePrev = prevGroupLastSubGroupItems?.length === 1 && !groupedData[gi - 2]
            }

          }

          if (newItem) {
            let groupId = ''
            for (let i = 0; i < this.group.length; i++) {
              let groupField: keyof IRelatedDocumentItem = this.group[i].field
              groupId = `${groupId}_${newItem[groupField]}`
              this.mobileExpandedSettings[groupId] = true
            }

            selected.Selected = false
            newItem.Selected = true
            this.selected = newItem
            this.MobileShowDocument({
              disableNext,
              disablePrev,
              remountMobileListKey: +new Date()
            })
          }
          break
        }
      }
    }
  }

  GetDisabledArrows = () => {
    let selected = this.selected
    let disableNext = false
    let disablePrev = false
    if (!selected) return {disableNext, disablePrev}


    let groupedData = this.state.documents.data
    if (!this.group.length) {
      for (let i = 0; i < groupedData.length; i++) {
        let dataItem = groupedData[i]
        if (dataItem.Id !== selected.Id) continue

        disablePrev = !groupedData[i - 1]
        disableNext = !groupedData[i + 1]
        break;
      }

      return {disableNext, disablePrev}
    }

    let selectedGroupNames = []
    let selectedGroupName = ''
    for (let gri = 0; gri < this.group.length; gri++) {
      let groupField: keyof IRelatedDocumentItem = this.group[gri].field
      selectedGroupName = `${selectedGroupName}_${selected[groupField]}`
      selectedGroupNames.push(selected[groupField])
    }

    let groupedLevel = 0
    for (let gi = 0; gi < groupedData.length; gi++) {
      let group = groupedData[gi]
      let groupName = group.value
      if (selectedGroupNames[groupedLevel] !== groupName) {
        continue
      }

      groupedLevel += 1
      for (let sgi = 0; sgi < group.items.length; sgi++) {
        let subGroup = group.items[sgi]
        if (subGroup.items) {
          let groupName = subGroup.value
          if (selectedGroupNames[groupedLevel] !== groupName) {
            continue
          }
        }
        for (let ii = 0; subGroup.items.length; ii++) {
          let dataItem = subGroup.items[ii]
          if (dataItem.Id !== selected.Id) continue
          let nextGroup = group.items[sgi + 1] || groupedData[gi + 1]?.items[0]
          if (!subGroup.items[ii + 1] && !nextGroup) {
            disableNext = true
          }
          let prevSubGroupItems = group.items[sgi - 1]?.items
          disablePrev = !subGroup.items[ii - 1] && !prevSubGroupItems && !groupedData[gi - 1]
          break
        }
      }
    }
    return {disableNext, disablePrev}
  }

  MobileSelectRow = (selected: IRelatedDocumentItem) => {
    try {
      this.selected = selected
      for (let item of this.documents) {
        item.Selected = item.Id === selected.Id
      }
      this.setState({remountMobileListKey: +new Date()})
    } finally {
    }
  }

  MobileShowDocument = async (state?: {
    disableNext: boolean
    disablePrev: boolean
    remountMobileListKey: number
  }) => {
    try {
      let selected = this.selected
      if (!selected) return

      this.setState({documentLoading: true})
      let url = await GetDocumentUrl(selected.DocumentId, true)
      let previewDocument = url
      let ext = this.selected?.Extension.toLowerCase()

      if (previewDocument && ext && DOCS_EXT.indexOf(ext) > -1) {
        previewDocument = 'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(previewDocument);
      }
      let imgLoading = (ext && IMAGES_EXT.indexOf(ext) > -1) || false
      let disabled = state || this.GetDisabledArrows()
      this.setState({previewDocument, imgLoading, ...disabled})
    } finally {
      this.setState({documentLoading: false})
    }
  }

  SelectRow = async (selected: IRelatedDocumentItem, onlyMark?: boolean) => {
    try {
      this.selected = selected
      for (let item of this.documents) {
        item.Selected = selected ? item.Id === selected.Id : false
      }
      let previewDocument = null, imgLoading = false;
      if (!onlyMark) {
        this.setState({documentLoading: true})
        let url = await GetDocumentUrl(selected.DocumentId, true)
        previewDocument = url
        let ext = this.selected?.Extension.toLowerCase()

        if (previewDocument && ext && DOCS_EXT.indexOf(ext) > -1) {
          previewDocument = 'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(previewDocument);
        }
        imgLoading = (ext && IMAGES_EXT.indexOf(ext) > -1) || false
      }
      this.setState({previewDocument, imgLoading,})
    } finally {
      if (!onlyMark) this.setState({documentLoading: false})
    }
  }

  SetDocuments = () => {
    let documents = process(
      this.documents,
      {
        group: this.group,
        sort: this.sort,
        filter: this.gridFilter
      }
    )
    if (this.props.isMobile && this.group.length) {
      for (let ObjName of documents.data) {
        let dataItem = GetDataItemFromGroup(ObjName)
        let groupField = this.group[0].field
        let id = `_${dataItem[groupField]}`
        if (this.mobileExpandedSettings[id] === undefined) {
          this.mobileExpandedSettings[id] = this.mobileGroupsDefaultExpanded
        }

        if (ObjName.items[0].items) {
          let subGroups = ObjName.items
          let groupField = this.group[1].field
          for (let i = 0; i < subGroups.length; i++) {
            let subGroup = subGroups[i]
            let dataItem = GetDataItemFromGroup(subGroup)
            let subId = `${id}_${dataItem[groupField]}`
            if (this.mobileExpandedSettings[subId] === undefined) {
              this.mobileExpandedSettings[subId] = this.mobileGroupsDefaultExpanded
            }
          }
        }
      }
    }

    if (!this.props.isMobile && this.group.length) {
      for (let ObjName of documents.data) {
        if (ObjName.expanded === undefined) ObjName.expanded = this.expandAll
        if (ObjName.items[0].items) {
          let subGroups = ObjName.items
          for (let i = 0; i < subGroups.length; i++) {
            let subGroup = subGroups[i]
            if (subGroup.expanded === undefined) subGroup.expanded = this.expandAll
          }
        }
      }
    }

    this.setState({
      documents,
      remountMobileListKey: +new Date(),
      selectedFilterObjects: this.selectedFilterObjects,
      gridFilter: this.gridFilter
    });
  }

  OnChangeDocNameFilter = (e: any) => {
    this.filterTypeKindValue = e.value || ''
    if (this.filterChangeTimeout) clearTimeout(this.filterChangeTimeout)
    this.filterChangeTimeout = setTimeout(this.SetDocNameFilter, 1000)
  }

  SetDocNameFilter = () => {
    this.RemoveDocNameFilter()
    if (this.filterTypeKindValue) {
      this.gridFilter.filters.push({
        filters: [
          {
            field: 'DocumentName',
            value: this.filterTypeKindValue,
            operator: 'contains'
          },
          {
            field: 'SrcObjName',
            value: this.filterTypeKindValue,
            operator: 'contains'
          }
        ], logic: 'or'
      })
    }
    this.SetDocuments()
  }

  OnChangeTextGridFilter = (e: any, field: any) => {
    let gridFilter = this.gridFilter
    let value = e.value
    let oldFilter = gridFilter.filters.find((f) => !IsComplexGridFilter(f) && f.field === field)
    if (oldFilter && !IsComplexGridFilter(oldFilter)) {
      oldFilter.value = value
    } else {
      this.gridFilter.filters.push({
        field,
        value,
        operator: DEFAULT_OPERATOR.text
      })
    }
    this.SetDocuments()
  }

  ExpandChange = (event: any) => {
    event.dataItem[event.target.props.expandField] = event.value;
    this.setState({});
  }

  ToggleExpandGroup = () => {
    if (this.props.isMobile) {
      this.expandAll = !this.expandAll
      for (let id in this.mobileExpandedSettings) {
        this.mobileExpandedSettings[id] = this.expandAll
      }
      if (!this.expandAll) this.setState({mobileCollapsedKey: +new Date()})
      else this.setState({remountMobileListKey: +new Date()})
    } else {
      this.expandAll = !this.expandAll
      let gridData = this.state.documents
      for (let kindGroup of gridData.data) {
        kindGroup.expanded = this.expandAll
        for (let objGroup of kindGroup.items) {
          objGroup.expanded = this.expandAll
        }
      }
      this.setState({documents: gridData})
    }
  }

  Refresh = () => {
    this.LoadData();
  }

  RefreshOnActivateCLMTab = () => { // external function call
    let bp = localStorage.getItem(CLPMSettings.CLM_BUILDPLAN_LS)
    let selectedBp = bp ? JSON.parse(bp) : undefined
    let newBpId = selectedBp && +selectedBp.Id
    if (this.bpId !== newBpId) {
      this.selectedBP = selectedBp
      this.bpId = selectedBp && +selectedBp.Id
      this.LoadData()
    }
  }

  OnExpandChange = (event: any) => {
    event.item.expanded = !event.item.expanded
    // this.forceUpdate()
  };

  OnChangeTypeFilter = (e: any) => {
    let mainGroupFieldId = 'SrcObjType'
    let subGroupFieldId = 'ObjectSubGroupId'
    let isClear = e.operation === 'clear'
    if (!isClear) {
      let changedItem = e.items[0]
      let checked = !changedItem.checked
      let parentType = changedItem.Type
      changedItem.checked = checked
      if (!parentType) {
        let subitems = changedItem.items
        if (subitems) {
          for (let subItem of subitems) {
            subItem.checked = checked
          }
        }
      }
    }

    if (this.gridFilter) {
      this.gridFilter.filters = this.gridFilter.filters.filter((f) => {
        if (!IsComplexGridFilter(f)) return true
        let firstfilter = f.filters[0]
        if (!IsComplexGridFilter(firstfilter) && firstfilter.field !== mainGroupFieldId && firstfilter.field !== subGroupFieldId) return true
        return false
      })
    }

    let selectedObjects = 0
    if (this.treeTypes.length) {
      let objectIdFilter: IGridFilter = {filters: [], logic: 'or'}

      for (let type of this.treeTypes) {
        let selectedChildren = 0
        for (let obj of type.items) {
          if (isClear) obj.checked = false
          if (obj.checked) {
            objectIdFilter.filters.push({
              field: subGroupFieldId,
              operator: 'eq',
              value: obj.Id
            })
            selectedChildren += 1
          }
        }
        type.checked = isClear ? false : selectedChildren === type.items.length
        selectedObjects += selectedChildren
      }
      if (objectIdFilter.filters.length) this.gridFilter.filters.push(objectIdFilter)
    }
    this.selectedFilterObjects = selectedObjects
    this.SetDocuments()
  }

  OnPublishedFilterChange = (value: boolean | null) => {
    let gridFilter = this.gridFilter
    if (value === null) {
      gridFilter.filters = gridFilter.filters.filter((f) => (IsComplexGridFilter(f) || f.field !== 'IsPublished'))
    } else {
      let oldFilter = gridFilter.filters.find((f) => (!IsComplexGridFilter(f) && f.field === 'IsPublished'))
      if (oldFilter && !IsComplexGridFilter(oldFilter)) oldFilter.value = value
      else gridFilter.filters.push({
        field: 'IsPublished',
        operator: 'eq',
        value
      })
    }
    this.SetDocuments()
  }
}

export default RelatedDocuments;
