import {Component} from 'react';
import {calendarId} from '../../helpers/interfaces';
import WebIframe from '../WebIframe';
import LoaderComponent from "../Common/Loader";
import UserInfo from "../../stores/User";

interface props {
    id: calendarId
    buildPlanId?: number
    initOnReady?: boolean
    isActive: boolean
}

interface state {
    showAddWOCard: boolean
    showDispatchCard: boolean
    userSettingsInited: boolean
}

class Calendar extends Component<props, state> {
    iframeRef: any;
    paramsString: string = this.props.buildPlanId ? `?buildPlanId=${this.props.buildPlanId}` : '';
    bpId = this.props.buildPlanId

    constructor(props: props) {
        super(props)
        this.state = {
            showAddWOCard: false,
            showDispatchCard: false,
            userSettingsInited: false,
        }
        this.paramsString = window.helpers.addParameterToURL('', `mode=${this.props.id}`)
        if (this.props.buildPlanId) this.paramsString = window.helpers.addParameterToURL('', `buildPlanId=${this.props.buildPlanId}`)
    }

    async componentDidMount() {
        const canGenerateBP = await UserInfo.canGenerateBP()
        this.paramsString = window.helpers.addParameterToURL(this.paramsString, `canGenerateBP=${canGenerateBP}`)
        this.setState({userSettingsInited: true})
    }

    componentDidUpdate() {
        if (this.iframeRef) {
            if (this.props.isActive && this.bpId !== this.props.buildPlanId) {
                if (this.iframeRef.contentWindow.changeObjectId) {
                    this.bpId = this.props.buildPlanId
                    this.iframeRef.contentWindow.changeObjectId(this.props.buildPlanId);
                }
            }
        }
    }

    render() {
        if (!this.state.userSettingsInited) return <LoaderComponent/>
        let paramsiframe = {folder: 'Calendar', urlParams: this.paramsString}
        return (
            <WebIframe
                initRef={this.initRef}
                id={"CalendarIframe_" + this.props.id}
                {...paramsiframe}
            />);
    }

    initRef = (ref: any) => {
        if (!this.iframeRef && ref) {
            this.iframeRef = ref;
            ref.onload = () => {
                if (this.props.initOnReady) {
                    this.iframeRef.contentWindow.changeObjectId(this.props.buildPlanId)
                }
            }
        }
    }

    Refresh = () => { // for tabs external call
        if (this.iframeRef && this.iframeRef.contentWindow) {
            if (this.props.buildPlanId) {
                if (this.iframeRef.contentWindow.changeObjectId) {
                    this.iframeRef.contentWindow.changeObjectId(this.props.buildPlanId, true)
                }
            } else if (this.iframeRef.contentWindow.reloadEvents) {
                this.iframeRef.contentWindow.reloadEvents()
            }
        }
    }

}

export default Calendar;
