import {Card} from '@progress/kendo-react-layout';
import {Component} from 'react';
import styles from './index.module.scss';

interface props {
  width: number | string
  height: number | string
  style?: React.CSSProperties

  onHide?(): void
}

interface state {
}

class MobilePopup extends Component<props, state> {

  render() {
    let propsStyles: React.CSSProperties = this.props.style || {}
    let cardStyles: React.CSSProperties = {
      height: this.props.height,
      width: this.props.width,
      ...propsStyles
    }

    return (
      <div className={styles.MobilePopupBox}>
        <div
          className={`k-overlay ${styles.MobilePopupOverlay}`}
          onClick={this.props.onHide}
        ></div>
        <Card className={styles.MobilePopup} style={cardStyles}>
          {this.props.children}
        </Card>
      </div>
    )
  }
}

export default MobilePopup;
