import {
  Button,
  ButtonGroup,
  Chip,
  Toolbar,
  ToolbarSpacer
} from '@progress/kendo-react-buttons';
import BaseComponent from '../../Components/BaseComponent';
import Loader from '../../Components/Common/Loader';
import {IComboboxItem, simpleObject} from '../../helpers/interfaces';
import {
  IApprovalJob,
  IBPItem,
  IBuildPlansGridData,
  IBuildPlansUnic,
  ICurrentWIP,
  IDataForFilters,
  IFilteredData,
  IGridItem,
  IGroupedData,
  ILaborHours,
  ILastDay,
  ILastDayData,
  ILastDays,
  INumbersItem,
  IPlannedExpenses,
  IProfitMargin,
  IRowItem
} from './interfaces'
import styles from './budgetDashboard.module.scss'
import {
  filtersFields,
  GetPreparedSectionData,
  GetSectionTitle,
  minValueAxisNumber,
  sectionTitles
} from './helpers';
import FilterCombobox from '../../Components/Common/Form/FilterCombobox';
import BudgetDetailsGrid from './Grid';
import BudgetDashboardPrintGrid from './PrintGrid';
import BudgetSpendChart from './SpendChart';

import React from 'react';
import moment, {Moment} from 'moment';
import FilterMultiSelect from '../../Components/Common/Form/FilterMultiSelect';
import {TagData} from '@progress/kendo-react-dropdowns';
import {GetComboboxYearsList} from '../../helpers/helpers';
import dashboardStyles from '../../Components/Dashboard/dashboard.module.scss';
import BudgetDetailsExcelGrid from './BudgetDetailsExcelExport';
import {IS_IE} from '../../helpers/settings';
import {ModalRef} from '../../Components/Common/Modal/Modal';

const DetailsTabId = 'details'
const ChartsTabId = 'summary'
const DetailsTabName = 'Details'
const ChartsTabName = 'Summary'

type tabId = typeof DetailsTabId | typeof ChartsTabId
type tabName = typeof DetailsTabName | typeof ChartsTabName

interface props {
  isActive: boolean //
}

interface state extends IFilteredData, IDataForFilters {
  gridData: Array<IGridItem>
  loading: boolean
  skip: number
  pageSize: number
  remountKey: number
  activeTabId: tabId
}

const BPStates = [
  {Name: 'Active', Id: 'Active'},
  {Name: 'Closed', Id: 'Closed'}
]

class BudgetDashboard extends BaseComponent<props, state> {
  gridPDFExport: any = React.createRef()
  gridDetailsExcelExport: any = React.createRef()
  groupedGridData: IBuildPlansGridData = {}
  lastDays: Array<ILastDayData> = []
  filters: { [key in filtersFields]?: IComboboxItem } = {}
  selectedYear: IComboboxItem | null = null
  years: Array<IComboboxItem>
  filterByBP: Array<IComboboxItem> = []
  detailsChart: Array<ILastDay> = []
  chartViewRef: any = React.createRef()
  tabs: Array<{ Id: tabId, Name: tabName }> = [
    {
      Id: ChartsTabId,
      Name: ChartsTabName
    },
    {
      Id: DetailsTabId,
      Name: DetailsTabName
    },
  ]

  constructor(props: any) {
    super(props)

    this.state = {
      bpOwners: [],
      propfitCenters: [],
      projects: [],
      scenarios: [],
      classes: [],
      regions: [],
      buildPlans: [],

      loading: true,
      filteredGridData: [],
      buldPlansCount: 0,
      skip: 0,
      pageSize: 20,
      remountKey: +new Date(),
      activeTabId: ChartsTabId,

      // charts
      months: [],
      varianceData: [],
      varianceDataPercentage: [],
      totalProjectedSpend: [],
      totalActualSpend: [],
      lastDaysBPS: 0,
      totalActualRevenue: [],
      minChartValue: 0,
      gridData: [],
      detailsChart: []
    }
    this.years = GetComboboxYearsList()
  }

  componentDidMount() {
    this.LoadData()
  }

  render() {
    let IsFiltersInDefault = this.IsFiltersInDefault()
    if (this.state.activeTabId === ChartsTabId) {
      let checkFunc = this.chartViewRef.current?.gridRef.current?.IsFiltersInDefault
      if (checkFunc) IsFiltersInDefault = IsFiltersInDefault && checkFunc()
    }
    return (
      <div className={styles.Container}>
        {this.state.loading && <Loader/>}
        <Toolbar className={styles.Toolbar}>
          <div className={styles.ToolbarContent}>
            <div className={styles.ToolbarRow}>
              <div
                style={{width: 146}}> {/* 146 - chip width + margin + labelWidth */}
                <ButtonGroup className={styles.ButtonGroup}>
                  {this.tabs.map((tab) => {
                    let tabId = tab.Id
                    return (
                      <Button
                        key={tabId}
                        data-id={tabId}
                        themeColor={this.state.activeTabId === tabId ? 'primary' : undefined}
                        onClick={this.OnSwitchTab}
                        style={{width: '50%'}}
                      >{tab.Name}</Button>
                    )
                  })}
                </ButtonGroup>
              </div>
              <FilterMultiSelect
                key={this.state.remountKey + 'bpfilter'}
                placeholder="Filter by BP"
                data={this.state.buildPlans}
                className={`${styles.ToolbarCombobox} ${styles.ToolbarLargeCombobox}`}
                autoClose={false}
                defaultValue={this.filterByBP}
                tags={[{text: ` `, data: []}]}
                tagRender={this.renderBPFilterTag}
                onChange={this.OnChangeBPFilter}
              />
              {this.renderFilter(
                'OwnerId',
                this.state.bpOwners,
                'Filter by BP Owner',
              )}
              {this.renderFilter(
                'ProjectId',
                this.state.projects,
                'Filter by Project'
              )}
              {this.renderFilter(
                'ProfitCenterId',
                this.state.propfitCenters,
                'Filter By Profit Center',
                styles.ToolbarSmallCombobox,
              )}
              <ToolbarSpacer/>
              <Button
                icon='filter-clear'
                title="Set Default Filters"
                className={IsFiltersInDefault ? '' : dashboardStyles.BlueResetBtn}
                onClick={this.ClearFilters}
              />
              {this.state.activeTabId === DetailsTabId && <>
                  <Button icon="file-pdf" title="Export to PDF"
                          onClick={this.ExportPDF} className={styles.RightBtn}/>
                  <Button icon="file-excel" title="Export to Excel"
                          onClick={this.ExportEXCEL}
                          className={styles.RightBtn}/>
              </>}
              {this.state.activeTabId === ChartsTabId &&
                  <Button
                      icon="file-excel"
                      title="Export to Excel"
                      onClick={this.ExportToExcel}
                      className={styles.RightBtn}
                  />
              }
              <Button icon='refresh' onClick={this.Refresh}
                      className={styles.RightBtn}/>
            </div>
            <div className={styles.ToolbarRow}>
              <div style={{width: 146}}>
                {this.state.activeTabId === DetailsTabId && <>
                    <span
                        className={styles.ToolbarInfoLabel}>Build Plans:</span>
                    <Chip className={styles.ChipCounter}
                          text={this.state.buldPlansCount + ''}/>
                </>}
              </div>
              {this.renderFilter(
                'ClassId',
                this.state.classes,
                'Filter by Class',
                styles.ToolbarLargeCombobox,
              )}
              {this.renderFilter(
                'ScenarioId',
                this.state.scenarios,
                'Filter by Scenario',
              )}
              {this.renderFilter(
                'RegionId',
                this.state.regions,
                'Filter by Region',
              )}
              {this.state.activeTabId === DetailsTabId ? this.renderFilter(
                'IsActive',
                BPStates,
                'BP State',
                styles.ToolbarSmallCombobox,
              ) : this.renderFilter(
                'Year',
                this.years,
                'Filter by Year',
                styles.ToolbarSmallCombobox,
              )}
              <ToolbarSpacer/>
            </div>
          </div>
        </Toolbar>
        {this.state.activeTabId === DetailsTabId &&
            <div style={{flex: 1, overflow: 'hidden'}}>
                <BudgetDetailsGrid
                    data={this.state.filteredGridData}
                    groupedData={this.groupedGridData}
                    refresh={this.Refresh}
                />
                <BudgetDashboardPrintGrid
                    ref={this.gridPDFExport}
                    data={this.state.filteredGridData}
                    groupedData={this.groupedGridData}
                />
                <BudgetDetailsExcelGrid
                    ref={this.gridDetailsExcelExport}
                    data={this.state.filteredGridData}
                    groupedData={this.groupedGridData}
                />
            </div>}
        {this.state.activeTabId === ChartsTabId &&
            <div style={{
              flex: 1,
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column'
            }}>
                <BudgetSpendChart
                    ref={this.chartViewRef}
                    year={this.selectedYear && +this.selectedYear.Id}
                    months={this.state.months}
                    variance={this.state.varianceData}
                    variancePercentage={this.state.varianceDataPercentage}
                    totalProjectedSpend={this.state.totalProjectedSpend}
                    totalActualSpend={this.state.totalActualSpend}
                    numberOfBPs={this.state.lastDaysBPS}
                    minChartValue={this.state.minChartValue}
                    totalActualRevenue={this.state.totalActualRevenue}
                    detailsChartData={this.state.detailsChart}
                    onChangeGridData={this.OnChangeGridData}
                />
            </div>}
      </div>
    )
  }

  renderFilter = (
    field: filtersFields,
    data: Array<IComboboxItem>,
    placeholder?: string,
    className?: string,
  ) => {
    let value = field === 'Year' ? this.selectedYear : this.filters[field] || null

    return (
      <FilterCombobox
        key={this.state.remountKey + field}
        className={`${styles.ToolbarCombobox} ${className || ''}`}
        dataAttr={field}
        data={data}
        onChange={this.OnChangeFilter}
        defaultValue={value}
        placeholder={placeholder || ''}
        loading={this.state.loading}
      />
    )
  }

  renderBPFilterTag = (tagData: TagData, tag: any) => {
    let bpFiltersLength = this.filterByBP.length
    if (!bpFiltersLength) return null
    return <Chip
      key={'selectedBPNumbers'}
      text={`${bpFiltersLength} Build Plans Selected`}
    />
    return tag // for ts
  }

  OnChangeFilter = (value: IComboboxItem | null, field: filtersFields) => {
    if (field === 'Year') {
      this.selectedYear = value
      this.LoadData()
      return;
    }
    if (value) this.filters[field] = value
    else delete this.filters[field]
    this.setState({...this.GetFilteredData()})
  }

  OnChangeBPFilter = (values?: any) => {
    this.filterByBP = values || []
    this.setState({...this.GetFilteredData()})
  }

  SortLastDay = (a: ILastDay, b: ILastDay) => a.LastDayMoment.isBefore(b.LastDayMoment) ? -1 : 1

  IsFiltersInDefault = () => {
    return !this.selectedYear && !this.filterByBP?.length && !Object.keys(this.filters).length
  }

  OnChangeGridData = () => {
    this.forceUpdate()
  }

  LoadData = async () => {
    try {
      this.setState({loading: true})
      let params: simpleObject = {}
      if (this.selectedYear) params.YearNum = this.selectedYear.Id
      let result = await this.GetSQLData({spName: 'DB_Budget', params})
      let {
        buildPlansUnic,
        lastDays,
        ...dataForFilters
      } = this.GetGroupedData(result[0], result[1], result[2], result[3], result[4], result[5], result[6], result[7])
      this.detailsChart = result[1].filter((item: any) => item.BPId)
      for (let item of this.detailsChart) {
        let dateMoment = moment(item.LastDay)
        item.LastDayMoment = dateMoment
        item.LastDayDate = +dateMoment.toDate()
        item.LastDayFormat = dateMoment.format('MMMM YYYY')
        let projectedSpend = item.ProjectedExpenses
        let actualSpend = item.ActualExpenses
        let varianceExpenses = projectedSpend === null && actualSpend === null ? null : (actualSpend || 0) - (projectedSpend || 0)
        item.VarianceExpenses = varianceExpenses

        let actualRevenue = item.ActualRevenue
        let projectedRevenue = item.ProjectedRevenue
        let varianceRevenue = projectedRevenue === null && actualRevenue === null ? null : (actualRevenue || 0) - (projectedRevenue || 0)
        item.VarianceRevenue = varianceRevenue
      }
      this.detailsChart.sort(this.SortLastDay)
      this.lastDays = lastDays
      this.groupedGridData = await this.GetGroupedGridData(buildPlansUnic)
      this.setState({
        ...dataForFilters,
        ...this.GetFilteredData()
      })
    } finally {
      this.setState({loading: false})
    }
  }

  GetGroupedData = (
    buildPlansList: Array<IBPItem>,
    lastDays: Array<ILastDay>,
    plannedRevenue: Array<INumbersItem>,
    plannedExpenses: Array<IPlannedExpenses>,
    profitMargins: Array<IProfitMargin>,
    laborHours: Array<ILaborHours>,
    currentWIP: Array<ICurrentWIP>,
    approvalJobs: Array<IApprovalJob>
  ): IGroupedData => {

    let filters: Array<Array<keyof IBPItem>> = [
      ['OwnerId', 'OwnerName'],
      ['ProfitCenterId', 'ProfitCenterName'],
      ['ProjectId', 'ProjectName'],
      ['ScenarioId', 'ScenarioName'],
      ['ClassId', 'ClassName'],
      ['RegionId', 'RegionName'],
      ['Id', 'Name'] // buildPlans
    ]

    let unicDataForFilters: {
      [key: string]: { [key: number]: boolean }
    } = {}
    let filtersData: { [key in keyof IBPItem]?: Array<IComboboxItem> } = {}

    let buildPlansUnic: IBuildPlansUnic = {}
    let months: Array<{ date: string, dateMoment: Moment }> = []
    let lastDaysUnic: ILastDays = {}
    for (let buildPlan of buildPlansList) {
      for (let i = 0; i < filters.length; i++) {
        let fieldId = filters[i][0]
        let fieldName = filters[i][1]
        if (!unicDataForFilters[fieldId]) unicDataForFilters[fieldId] = {}
        let unicData = unicDataForFilters[fieldId]
        let Id = buildPlan[fieldId]
        let Name = buildPlan[fieldName]

        if (typeof Id === 'number' && !unicData[Id] && typeof Name === 'string') {
          unicData[Id] = true;
          if (filtersData[fieldId] == undefined) filtersData[fieldId] = []
          filtersData[fieldId]?.push({Id, Name})

          if (fieldId === 'Id') {
            buildPlansUnic[Id] = {
              data: buildPlan,
              plannedRevenue: [],
              plannedExpenses: [],
              profitMargins: [],
              laborHours: [],
              currentWIP: [],
            }
          }
        }
      }
    }

    for (let item of lastDays) {
      if (!lastDaysUnic[item.LastDay]) {
        months.push({date: item.LastDay, dateMoment: moment(item.LastDay)})
        lastDaysUnic[item.LastDay] = []
      }
      let BPData = buildPlansUnic[item.BPId]
      if (BPData) {
        item.BPName = BPData.data.Name
        item.BPOName = BPData.data.OwnerName
        item.BPOId = BPData.data.OwnerId
        item.ScenarioName = BPData.data.ScenarioName
        item.ScenarioId = BPData.data.ScenarioId
      }
      lastDaysUnic[item.LastDay].push(item)
    }
    for (let item of plannedRevenue) {
      buildPlansUnic[item.BPId].plannedRevenue.push(item)
    }
    for (let item of plannedExpenses) {
      buildPlansUnic[item.BPId].plannedExpenses.push(item)
    }
    for (let item of profitMargins) {
      buildPlansUnic[item.BPId].profitMargins.push(item)
    }
    for (let item of laborHours) {
      buildPlansUnic[item.BPId].laborHours.push(item)
    }
    for (let item of currentWIP) {
      buildPlansUnic[item.BPId].currentWIP.push(item)
    }
    for (let item of approvalJobs) {
      buildPlansUnic[item.BPId].ApprovalJobId = item.ApprovalJobId
    }
    months.sort((dateA, dateB) => dateA.dateMoment.isBefore(dateB.dateMoment) ? -1 : 1)
    let firstMonth = 0;
    let lastDaysResult: Array<ILastDayData> = []
    for (let i = 0; i < months.length; i++) {
      let date = months[i]
      let dataMonthNumber = date.dateMoment.get('month')
      if (i === 0) {
        firstMonth = dataMonthNumber
      }
      let monthInOrder = firstMonth + i
      if (monthInOrder > 11) monthInOrder = monthInOrder % 12
      if (monthInOrder !== dataMonthNumber) {
        firstMonth++
        lastDaysResult.push({month: moment.months(monthInOrder), data: []})
      }
      let item = lastDaysUnic[date.date]
      lastDaysResult.push({
        month: date.dateMoment.format('MMMM YYYY'),
        data: item
      })
    }

    let {
      OwnerId: bpOwners = [],
      ProfitCenterId: propfitCenters = [],
      ProjectId: projects = [],
      ScenarioId: scenarios = [],
      ClassId: classes = [],
      RegionId: regions = [],
      Id: buildPlans = [],
    } = filtersData;

    return {
      buildPlansUnic,
      bpOwners,
      propfitCenters,
      projects,
      scenarios,
      classes,
      regions,
      buildPlans,
      lastDays: lastDaysResult
    }
  }

  GetGroupedGridData = async (buildPlansUnic: IBuildPlansUnic) => {
    let data: IBuildPlansGridData = {}
    for (let BPId in buildPlansUnic) {
      let bpGroup = buildPlansUnic[BPId]
      let plannedRevenueTitle = await GetSectionTitle('plannedRevenue')
      let plannedRevenue = GetPreparedSectionData(bpGroup.plannedRevenue, plannedRevenueTitle, 'financial');
      plannedRevenue[0].IsBoldRow = true
      plannedRevenue[0].Title = plannedRevenue[0].Section
      let hasPlannedExpenses = !!bpGroup.plannedExpenses.length
      let totals: Array<simpleObject> = [];
      let totalPlannedExpenses = this.getTotalRowData(bpGroup.plannedExpenses, +BPId);
      // @ts-ignore
      bpGroup.plannedExpenses.push(totalPlannedExpenses);
      let plannedExpenses = GetPreparedSectionData(bpGroup.plannedExpenses, sectionTitles.plannedExpenses, 'financial');
      if (hasPlannedExpenses) {
        let revenueGross: simpleObject = this.getRevenueGrossRowData(plannedRevenue[0], totalPlannedExpenses, +BPId);
        totals.push(revenueGross);
        let currentWIP = bpGroup.currentWIP[0];
        if (currentWIP) {
          totals.push({
            IsBoldRow: true,
            ...currentWIP
          });
          let adjustedPLRow = {
            Title: 'Adjusted P&L',
            Actual: currentWIP.Actual + revenueGross.Actual,
            Initial: undefined,
            POBalance: undefined,
            Remaining: undefined,
            Revised: undefined,
            IsBoldRow: true,
            BPId
          }
          totals.push(adjustedPLRow);
        }
      }
      let totalsRows = GetPreparedSectionData(totals, sectionTitles.totals, 'financial')
      let profitMargins = GetPreparedSectionData(bpGroup.profitMargins, sectionTitles.profitMargins, 'percentages')
      let laborHoursTotalRow = this.getTotalRowData(bpGroup.laborHours, +BPId)
      //@ts-ignore
      bpGroup.laborHours.push(laborHoursTotalRow)
      let laborHours = GetPreparedSectionData(bpGroup.laborHours, sectionTitles.laborHours, 'hr');
      data[BPId] = {
        data: bpGroup.data,
        bpRow: {
          Type: 'buildPlan',
          Name: bpGroup.data.Name,
          BPId: bpGroup.data.Id,
          ApprovalJobId: bpGroup.ApprovalJobId,
          BPOName: bpGroup.data.OwnerName,
          CXStatusName: bpGroup.data.CXStatusName,
          EstimatedCompletion: bpGroup.data.EstimatedCompletion
        },
        plannedRevenue,
        plannedExpenses,
        totals: totalsRows,
        profitMargins,
        laborHours,
      }
    }

    return data
  }

  GetFilteredData = (): IFilteredData => {
    let filteredGridData: Array<IRowItem> = []
    let buildPlansIds: Array<string> = []
    for (let BPId in this.groupedGridData) {
      let groupItem = this.groupedGridData[BPId]
      let isValid = !this.filterByBP.length || this.filterByBP.findIndex((item) => item.Id + '' === BPId + '') > -1

      if (isValid) {
        for (let field in this.filters) {
          if (isValid) {
            // @ts-ignore
            let filterValue = this.filters[field].Id
            // @ts-ignore
            let BPDataValue = groupItem.data[field]
            if (field === 'IsActive') {
              if (
                this.state.activeTabId === DetailsTabId && (
                  BPDataValue === true && filterValue === 'Closed' ||
                  BPDataValue === false && filterValue === 'Active'
                )) isValid = false
            } else if (field !== 'Year') {
              if (filterValue === 'null') filterValue = null
              if (BPDataValue !== filterValue) isValid = false
            }
          }
        }
      }
      if (isValid) {
        buildPlansIds.push(BPId)
        filteredGridData.push(groupItem.bpRow)
        filteredGridData.push(groupItem.plannedRevenue[0])
        filteredGridData.push({
          Type: 'section',
          Name: sectionTitles.plannedExpenses,
          Title: sectionTitles.plannedExpenses,
          Section: sectionTitles.plannedExpenses,
          BPId: +BPId
        })
        filteredGridData.push(...groupItem.plannedExpenses)
        filteredGridData.push(...groupItem.totals)
        filteredGridData.push({
          Type: 'section',
          Name: sectionTitles.profitMargins,
          Title: sectionTitles.profitMargins,
          Section: sectionTitles.profitMargins,
          BPId: +BPId
        })
        filteredGridData.push(...groupItem.profitMargins)
        filteredGridData.push({
          Type: 'section',
          Name: sectionTitles.laborHours,
          Title: sectionTitles.laborHours,
          Section: sectionTitles.laborHours,
          BPId: +BPId
        })
        filteredGridData.push(...groupItem.laborHours)
      }
    }

    let detailsChart: Array<ILastDay> = []
    for (let item of this.detailsChart) {
      if (buildPlansIds.findIndex((bpId) => bpId + '' === item.BPId + '') > -1) {
        detailsChart.push(item)
      }
    }
    let months: Array<string> = []
    let varianceData: Array<number | null> = []
    let varianceDataPercentage: Array<number | null> = []
    let totalProjectedSpend: Array<number | null> = []
    let totalActualSpend: Array<number | null> = []
    let lastDaysBPS: Array<number> = []

    let totalActualRevenue: Array<number | null> = []
    let minVarianceValue = 0
    let maxVarianceValue = 0

    for (let i = 0; i < this.lastDays.length; i++) {
      let item = this.lastDays[i]
      months.push(item.month)
      let projectedSpend: number | null = null
      let actualSpend: number | null = null
      let actualRevenue: number | null = null

      for (let data of item.data) {
        let BPId = data.BPId
        if (buildPlansIds.findIndex((bpId) => bpId + '' === BPId + '') > -1) {
          if (!this.groupedGridData[BPId].data.IsActive && lastDaysBPS.findIndex((bpId) => BPId === bpId) === -1) lastDaysBPS.push(BPId)
          if (data.ProjectedExpenses !== null) projectedSpend = (projectedSpend || 0) + data.ProjectedExpenses
          if (data.ActualExpenses !== null) actualSpend = (actualSpend || 0) + data.ActualExpenses
          if (data.ActualRevenue !== null) actualRevenue = (actualRevenue || 0) + data.ActualRevenue
        }
      }

      totalProjectedSpend.push(projectedSpend)
      totalActualSpend.push(actualSpend)
      let varianceSpend = projectedSpend === null && actualSpend === null ? null : (actualSpend || 0) - (projectedSpend || 0)
      let variancePercentage: number | null = varianceSpend === null ? null : !projectedSpend ? 0 : varianceSpend / (projectedSpend || 0) * 100
      varianceData.push(varianceSpend)
      varianceDataPercentage.push(variancePercentage)
      totalActualRevenue.push(actualRevenue)
      minVarianceValue = Math.min(minVarianceValue, varianceSpend || 0)
      maxVarianceValue = Math.max(maxVarianceValue, varianceSpend || 0)
    }
    let minChartValue = Math.max(Math.abs(maxVarianceValue), Math.abs(minVarianceValue))
    minChartValue = minChartValue < minValueAxisNumber ? minValueAxisNumber : minChartValue * 2
    minChartValue *= -1
    return {
      filteredGridData,
      buldPlansCount: buildPlansIds.length,
      months,
      varianceData,
      varianceDataPercentage,
      totalProjectedSpend,
      totalActualSpend,
      totalActualRevenue,
      lastDaysBPS: lastDaysBPS.length,
      minChartValue,
      detailsChart
    }
  }

  getRevenueGrossRowData = (plannedRevenue: simpleObject, totalPlannedRevenue: simpleObject, BPId: number) => {
    let revenueGross: simpleObject = {
      Actual: undefined,
      Initial: undefined,
      POBalance: undefined,
      Revised: undefined
    }
    let key: keyof typeof revenueGross;
    for (key in revenueGross) {
      if (
        plannedRevenue[key] !== null &&
        plannedRevenue[key] !== undefined &&
        totalPlannedRevenue[key] !== null &&
        totalPlannedRevenue !== undefined
      ) {
        revenueGross[key] = plannedRevenue[key] - totalPlannedRevenue[key];
      } else if (totalPlannedRevenue[key] || totalPlannedRevenue[key] === 0) {
        revenueGross[key] = 0 - totalPlannedRevenue[key];
      } else if (plannedRevenue[key] || plannedRevenue[key] === 0) {
        revenueGross[key] = plannedRevenue[key];
      } else {
        revenueGross[key] = null;
      }
    }
    return {
      Title: 'Total Revenue Gross Profit Margin $',
      IsBoldRow: true,
      BPId,
      ...revenueGross
    };
  }

  getTotalRowData = (data: Array<any>, BPId: number) => {
    let rowTotal: IRowItem = {
      Type: 'numbers',
      Title: 'Total',
      IsTotalRow: true,
      IsBoldRow: true
    };
    data.forEach((item: simpleObject /* IRowItemServer */) => {
      let key: keyof typeof item;
      for (key in item) {
        if (key !== 'Title' && (item[key] || item[key] === 0)) {
          if (!rowTotal[key]) rowTotal[key] = 0;
          rowTotal[key] += item[key];
        } else if (!rowTotal[key] && rowTotal[key] !== 0) {
          rowTotal[key] = item[key];
        }
      }
    });
    rowTotal.BPId = BPId
    return rowTotal;
  }

  ClearFilters = () => {
    this.filters = {}
    if (this.selectedYear) {
      this.selectedYear = null
      this.setState({remountKey: +new Date()})
      this.LoadData()
    } else {
      this.setState({...this.GetFilteredData(), remountKey: +new Date()})
    }
  }

  Refresh = async () => {
    this.LoadData()
  }

  ExportToExcel = () => {
    this.chartViewRef.current?.ExportToExcel?.()
  }

  ExportPDF = () => {
    let bps = this.state.buldPlansCount
    if (IS_IE && bps > 10) {
      ModalRef.showDialog({
        type: 'error',
        title: ' ',
        text: 'You can\'t export to PDF more than 10 Build plan Budgets in Desktop application. Please use external browser or more specific filter.'
      })
      return
    }
    this.gridPDFExport.current.gridPDFExport.current.save(this.state.filteredGridData)
  }

  ExportEXCEL = () => {
    this.gridDetailsExcelExport.current.gridRef.current.save()
  }

  OnSwitchTab = () => {
    this.setState((state) => ({
      activeTabId: state.activeTabId === DetailsTabId ? ChartsTabId : DetailsTabId
    }))
  }

}

export default BudgetDashboard
