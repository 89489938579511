import {IBPInfo, IBPInfoFromServer} from './interfaces';

export const CalculateDataInfo = (bpInfoInit: IBPInfoFromServer) => {
  let bpInfo: IBPInfo = {
    ...bpInfoInit,
    estimateHours: bpInfoInit.BudgetHours > 0 ? bpInfoInit.BudgetHours * bpInfoInit.EstPercentage / 100 : 0,
    actualPercentage: bpInfoInit.BudgetHours > 0 ? bpInfoInit.ActualHours / bpInfoInit.BudgetHours * 100 : 0,
  }

  if (bpInfo.BudgetHours > 0) {
    bpInfo.varianceHours = bpInfo.ActualHours - bpInfo.estimateHours;
    bpInfo.varianceHoursPercentage = bpInfo.varianceHours / bpInfo.BudgetHours * 100;
    bpInfo.estimateNewBudgetHours = bpInfo.ActualHours / bpInfo.estimateHours * bpInfo.BudgetHours;
    bpInfo.estimateNewBudgetHoursPercentage = bpInfo.estimateNewBudgetHours / bpInfo.BudgetHours * 100;
    bpInfo.estimateFinalVarianceHours = bpInfo.estimateNewBudgetHours - bpInfo.BudgetHours;
    bpInfo.estimateFinalVarianceHoursPercentage = bpInfo.estimateNewBudgetHoursPercentage - 100;
  }

  for (let key in bpInfo) {
    // @ts-ignore
    let value = bpInfo[key];
    if (IsNumeric(value) && key !== 'id') {
      // @ts-ignore
      bpInfo[key] = +(Math.round(value * 100) / 100).toFixed(2);
    }
  }
  return bpInfo
}

const IsNumeric = (n: any) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
