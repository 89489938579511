import styles from './tc.module.scss';

interface IProps {
    comment: string | null
    style?: React.CSSProperties
}

const AdjustmentsComment = (props: IProps) => {
    const {comment, style} = props
    if (!comment) return null
    return <div className={styles.TCAdjustmentComment} style={style}>
        {comment}
    </div>
}

export default AdjustmentsComment;
