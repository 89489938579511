export {}

if (!Promise.allSettled) {
  // @ts-ignore
  Promise.allSettled = function (promises) {
    return Promise.all(
      // @ts-ignore
      promises.map((p) =>
        Promise.resolve(p).then(
          (value) => ({
            status: 'fulfilled',
            value: value,
          }),
          (error) => ({
            status: 'rejected',
            reason: error,
          })
        )
      )
    )
  }
}

if (window.Element && !Element.prototype.closest) {
  Element.prototype.closest =
    function (s: any) {
      var matches = (this.document || this.ownerDocument).querySelectorAll(s),
        i,
        el = this;
      do {
        i = matches.length;
        while (--i >= 0 && matches.item(i) !== el) {
        }

      } while ((i < 0) && (el = el.parentElement));
      return el;
    };
}

(function (constructor) {
  // @ts-ignore
  if (constructor && constructor.prototype && constructor.prototype.children == null) {
    Object.defineProperty(constructor.prototype, 'children', {
      get: function () {
        var i = 0, node, nodes = this.childNodes, children = [];
        //iterate all childNodes
        while (node = nodes[i++]) {
          //remenber those, that are Node.ELEMENT_NODE (1)
          if (node.nodeType === 1) {
            children.push(node);
          }
        }
        return children;
      }
    });
  }
  //apply the fix to all HTMLElements (window.Element) and to SVG/XML (window.Node)
})(window.Node || window.Element);

