import {Button, ButtonGroup} from "@progress/kendo-react-buttons";
import styles from "./tabs.module.scss";
import {GetTabId, GetTabName} from "./helpers";
import {ITabSetting, tabId} from "./interfaces";

interface IProps {
    tabs: (tabId | ITabSetting)[]
    activeTabId: tabId | undefined
    onChange: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const TabsBtns = (props: IProps) => {
    const {tabs, activeTabId, onChange} = props

    return <ButtonGroup className={styles.ButtonGroup}>
        {tabs.map((tab) => {
            let tabId = GetTabId(tab)
            return (
                <Button
                    key={tabId}
                    data-id={tabId}
                    themeColor={activeTabId === tabId ? 'primary' : undefined}
                    onClick={onChange}
                >
                    {GetTabName(tab)}
                </Button>
            )
        })}
    </ButtonGroup>
}

export default TabsBtns