import React, {Fragment} from 'react';
import {Deferred} from '../../../helpers/deffered';
import {simpleObject} from '../../../helpers/interfaces';
import commonStyles from '../../../assets/styles/common.module.scss'

export class CardsStackRef {
  static ref: any;
  static initializedRef = new Deferred();

  static setRef(ref: any) {
    this.ref = ref;
    this.initializedRef.resolve();
  }

  static async checkRefInit() {
    if (this.ref === undefined) await this.initializedRef;
  }

  static async showCard(card: any, params: simpleObject) {
    await this.checkRefInit();
    this.ref.showCard(card, params);
  }

  static async hideCard(id: string) {
    await this.checkRefInit();
    this.ref.hideCard(id);
  }

}

interface ICard {
  el: any,
  id: number
}

interface state {
  cards: Array<ICard>
}

export class CardsStack extends React.Component<{}, state> {
  constructor(props: {}) {
    super(props)
    this.state = {
      cards: []
    }
  }

  componentDidMount() {
    CardsStackRef.setRef(this);
  }

  render() {
    if (!this.state.cards.length) return null
    return <div
      className={this.state.cards.length ? commonStyles.IEfixIframeWrap : ''}>
      <iframe className={commonStyles.IEfixIframe} src="about:blank"
              title="iefix"></iframe>
      {this.state.cards.map((card) =>
        <Fragment key={card.id}>{card.el}</Fragment>
      )}</div>
  }

  showCard = (Component: any, params: simpleObject = {}) => {
    let cards = this.state.cards
    let cardId = cards.length ? cards[cards.length - 1].id + 1 : 1
    cards.push({
      id: cardId,
      el: <Component {...params} finally={() => {
        this.hideCard(cardId)
        if (params.finally) params.finally()
      }}/>
    })
    if (cards.length === 1) {
      document.addEventListener('keydown', this.ESCEvent)
    }
    this.setState({cards})
  }

  hideCard = (cardId: number) => {
    let cards = this.state.cards
    let index = cards.findIndex((card) => card.id === cardId)
    if (index > -1) cards.splice(index, 1)
    if (cards.length === 0) {
      document.removeEventListener('keydown', this.ESCEvent)
    }
    this.setState({cards})
  }

  ESCEvent = (e: any) => {
    if (e.which === 27) {
      let lastCard = this.state.cards[this.state.cards.length - 1]
      if (lastCard) {
        this.hideCard(lastCard.id)
      }
    }
  }

}
