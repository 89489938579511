import BaseComponent from '../../Components/BaseComponent';
import {IBPInfo} from './interfaces';
import {simpleObject} from '../../helpers/interfaces';
import styles from './bpdashboard.module.scss';

interface props {
  data: IBPInfo
  style?: simpleObject
}

interface state {
}

class BPHoursDetails extends BaseComponent<props, state> {

  render() {
    let data = this.props.data
    return <div style={this.props.style}>
      {this.renderDetailsRow('Budget', data.BudgetHours, 100, <span
        className="k-icon k-i-stop"
        style={{color: '#006494'}}></span>)}
      {this.renderDetailsRow('Actual', data.ActualHours, data.actualPercentage,
        <span className="k-icon">↖</span>)}
      {this.renderDetailsRow('Field Completion Estimate', data.estimateHours, data.EstPercentage,
        <span
          className="k-icon k-i-stop" style={{color: '#01baef'}}></span>)}

      {!!data.varianceHours && <>
        {this.renderDetailsRow('Current variance', data.varianceHours, data.varianceHoursPercentage!)}
        {this.renderDetailsRow('Projected Final Job Hours', data.estimateNewBudgetHours!, data.estimateNewBudgetHoursPercentage!)}
        {this.renderDetailsRow('Projected Final Variance', data.estimateFinalVarianceHours!, data.estimateFinalVarianceHoursPercentage!)}
      </>}
    </div>
  }

  renderDetailsRow = (name: string, hours: number, percentage: number, icon?: any) => {
    return <div className={styles.DetailsRow}>
      <div className={styles.DetailsIcon}>{icon}</div>
      <div className={styles.DetailsName}>{name}:</div>
      <div className={styles.DetailsValue}>{hours} Hrs</div>
      <div className={styles.DetailsValue}>{percentage}%</div>
    </div>
  }

}

export default BPHoursDetails;
