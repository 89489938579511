import {IColumnSetting} from '../../Components/Dashboard/interfaces';
import moment from "moment";

export const LEVELS = [
    {value: 5, text: 'Fatal'},
    {value: 4, text: 'Error'},
    {value: 3, text: 'Warn'},
    {value: 2, text: 'Info'},
    {value: 1, text: 'Debug'},
    {value: 0, text: 'Trace'}
];

export const PrepareData = (data: Array<any>) => {
    for (let item of data) {
        item.Level = LEVELS.find((level) => level.value === item.L)?.text || ''
        item.LocalTime = moment(item.D).format().slice(0, -6)
        item.DeviceTime = moment(item.D.slice(0, -6)).format().slice(0, -6)
    }
    return data
}

export const COLUMNS: Array<IColumnSetting> = [
    {
        field: 'D',
        title: 'Time',
        type: 'date',
        filterable: false,
        columnMenu: false,
        format: {
            type: 'time',
        }
    },
    {
        field: 'Level',
        title: 'Level',
        type: 'string',
        gridWidth: 75,
        filterable: false,
        format: {
            fieldId: 'L',
            type: 'string',
        },
    },
    {
        field: 'T',
        title: 'Type',
        type: 'string',
        gridWidth: 130,
        format: {
            type: 'string',
            fieldId: 'T'
        }
    },
    {
        field: 'M',
        type: 'string',
        title: 'Message',
        columnMenu: false
    },
    {
        field: 'B',
        title: 'Battery',
        type: 'number',
        gridWidth: 80,
        columnMenu: false,
        filterable: false
    }
];
