import React from 'react'
import BaseComponent from '../../Components/BaseComponent'
import Switch from '../../Components/Switch/Switch'
import {simpleObject} from '../../helpers/interfaces'
import {CalculateGroupedData, GetGroupedData, groupFDAmounts, PrepareStatement} from './helpers'
import {IDetailsProps, IDetailsRow, IPieItem, IStatementRow} from './interfaces'
import styles from './pl.module.scss'
import PLDetails from './PLDetailing'
import PLSummary from './PLSummary'

const TITLE_TO_COLOR_PIE: { [key: string]: string } = {
    'Profit Margin': '#88e292',
    'VIM Invoices': '#777ce2',
    'Time Keeping': '#ffaa2b',
    'BOM': '#22539c',
    'COD Purchases': '#ef5252',
    'Employee': '#c7ba4a',
    'Payroll Period': '#c7ba4a'
}

const OVERHEAD_COLORS = ['#ffd94f', '#F3E55C', '#E8B727', '#F3CD5C', '#F3E984']
let overheadIndex = 0;

interface props {
    isActive: boolean
    plProps?: IDetailsProps // if from PL Dashboard
    buildPlanId?: number // if from StatusDashboard or other
    pageId?: string

    onChangeGridData?(data: Array<simpleObject>): void
}

interface state {
    loading: boolean
    loadingPieChartData: boolean
    pieChartData: Array<IPieItem>
    totalRevenue: number
    tableData: Array<IDetailsRow>
    isSummaryMode: boolean
    isEnabledDetails: boolean
}

class PLStatement extends BaseComponent<props, state> {
    isPLDashboard: boolean = this.props.pageId === 'PLDashboard'
    bpId?: number = this.props.buildPlanId
    config?: IDetailsProps = this.props.plProps
    initedDetails: boolean = false
    detailsRef: any = React.createRef()
    summaryRef: any = React.createRef()
    isSummaryMode: boolean = true

    constructor(props: props) {
        super(props)
        this.state = {
            loading: false,
            loadingPieChartData: false,
            pieChartData: [],
            totalRevenue: 0,
            tableData: this.props.plProps ? this.GetTableData() : [],
            isSummaryMode: this.isSummaryMode,
            isEnabledDetails: false
        }
    }

    componentDidMount() {
        this.InitGettingData()
    }

    componentDidUpdate(prevprops: props) {
        if (!this.isPLDashboard) {
            if (this.props.isActive && this.bpId !== this.props.buildPlanId) {
                this.bpId = this.props.buildPlanId
                this.InitGettingData()
            }
        } else {
            if (this.props.isActive && this.props.plProps && this.props.plProps !== prevprops.plProps) {
                this.config = this.props.plProps
                this.InitGettingData()
                this.setState({tableData: this.GetTableData()})
            }
        }
    }

    InitGettingData = () => {
        if (this.props.plProps || this.bpId) this.LoadPieChartData()
        if (!this.isPLDashboard) this.LoadProps()
    }

    render() {
        return <>
            <PLSummary
                ref={this.summaryRef}
                className={!this.state.isSummaryMode ? styles.HiddenMode : undefined}
                loading={this.state.loading || this.state.loadingPieChartData}
                tableData={this.state.tableData}
                totalRevenue={this.state.totalRevenue}
                pieChartData={this.state.pieChartData}
                toolbarBefore={this.renderSwitchMode()}
            />
            {this.state.isEnabledDetails && this.initedDetails && !!this.config &&
                <PLDetails
                    ref={this.detailsRef}
                    className={this.state.isSummaryMode ? styles.HiddenMode : undefined}
                    queryProps={this.config}
                    toolbarBefore={this.renderSwitchMode()}
                    onChangeGridData={this.props.onChangeGridData}
                />
            }
        </>
    }

    renderSwitchMode = () => {
        if (!this.state.isEnabledDetails || this.isPLDashboard) return null // if not PL and details is available (server field)
        return <Switch
            checked={this.state.isSummaryMode}
            id={'plTabMode' + this.props.pageId}
            onChange={this.OnChangeMode}
            label={`Switch to ${this.state.isSummaryMode ? 'Details' : 'Summary'}`}
        />
    }

    GetTableRowData = (
        row: IStatementRow,
        level: number,
        totalRevenue: number,
        total: number,
        tableData: Array<IDetailsRow>): {
        item: IDetailsRow,
        total: number,
        totalRevenue: number
    } => {
        let plProps = this.config
        let dataItem = plProps!.dataItem
        let amountsList = plProps!.amounts
        let FAIDs = row.accountIds || []
        let percentageof = ''
        let percent = null
        let newTotalRevenue = totalRevenue
        let newTotal = total

        let amount = 0
        if (dataItem.data) {
            let BPIds = dataItem.data.map((item: simpleObject) => item.BPID)
            let amounts = []
            for (let BPID of BPIds) {
                for (let FAID of FAIDs) {
                    if (amountsList[BPID] && amountsList[BPID][FAID]) amounts.push(amountsList[BPID][FAID])
                }
            }
            if (row.type === 'FirstRow') {
                newTotalRevenue = amounts.reduce((sum: number, current) => sum + current, 0)
                amount = newTotalRevenue
            } else if (row.type === 'TopAccount') {
                let sum = -1 * amounts.reduce((sum: number, current) => sum - current, 0)
                amount = sum
                newTotal += sum
            } else if (row.type === 'Balance') {
                amount = totalRevenue + total
            } else if (row.type === 'ChildAccount') {
                amount = -1 * amounts.reduce((sum: number, current) => sum - current, 0)
            }
        }

        if (row.percentageOf !== null) {
            let rowOf = tableData.find((item) => item.number === row.percentageOf)
            if (rowOf) {
                percentageof = row.type === 'ChildAccount' ? '' : rowOf.title
                let ofValue = rowOf.type === 'ChildAccount' ? rowOf.amount : rowOf.total
                if (ofValue || ofValue === 0) {
                    percent = Math.abs(amount) / Math.abs(ofValue) * 100
                    if (amount < 0) percent *= -1
                }
            }
        }

        let item: IDetailsRow = {
            level: level,
            title: row.title,
            number: row.number,
            amount: row.type === 'ChildAccount' ? amount : null,
            total: row.type !== 'ChildAccount' ? amount : null,
            percentageof: percentageof,
            percent: percent,
            type: row.type
        }

        return {item, totalRevenue: newTotalRevenue, total: newTotal}
    }

    GetTableData = () => {
        let plProps = this.config
        let dataItem = plProps!.dataItem
        let rows = plProps!.statement.rows.filter((row: IStatementRow) => row.type !== 'Delimiter')
        let rowsData = this.PrepareTableData(rows)
        let hasServiceRevenueRow = plProps!.statement.hasServiceRevenueRow

        let percentOverheadAllowance = dataItem.data.reduce((sum: number, current: simpleObject) => sum + (current.OverheadAllowance || 0), 0) * -1
        let lastPercentageOf = rows[rows.length - 1].percentageOf
        let rowOf = rowsData.find((item) => item.number === lastPercentageOf)
        let ofTitle = rowOf?.title || ''
        let ofValue = rowOf?.total || 0

        let totalOverheadAllowance: null | number = null
        if ((percentOverheadAllowance || percentOverheadAllowance === 0) && rowOf) {
            totalOverheadAllowance = Math.abs(percentOverheadAllowance) * Math.abs(ofValue) / 100
            if (percentOverheadAllowance < 0) totalOverheadAllowance *= -1
        }

        let totalNetMargin = totalOverheadAllowance + dataItem.WIPAdjustedProfitMargin
        let revenueRow = rowsData.find((row) => row.title === (hasServiceRevenueRow ? 'Service Revenue' : 'Total Revenue'))
        let percentNetMargin: null | number = null
        if ((totalNetMargin || totalNetMargin === 0) && rowOf) {
            percentNetMargin = Math.abs(totalNetMargin) / Math.abs(revenueRow!.total! + dataItem.WipDelta) * 100
            if (totalNetMargin < 0) percentNetMargin *= -1
        }

        let percentWIPAdjustedProfitMargin = revenueRow ? dataItem.WIPAdjustedProfitMargin / (revenueRow.total! + dataItem.WipDelta) * 100 : null
        if (dataItem.WIPAdjustedProfitMargin < 0 && percentWIPAdjustedProfitMargin !== null) percentWIPAdjustedProfitMargin *= -1
        rowsData.push(
            {
                title: 'WIP',
                total: dataItem.WipDelta,
                percent: null,
                percentageof: '',
                type: 'WipDelta',
                level: 1,
            },
            {
                title: 'WIP Adjusted Profit Margin',
                total: dataItem.WIPAdjustedProfitMargin,
                percent: percentWIPAdjustedProfitMargin,
                percentageof: hasServiceRevenueRow ? 'Service Revenue + WIP' : 'Total Revenue + WIP',
                type: 'WIPAdjustedProfitMargin',
                level: 1,
            }
        )

        if (dataItem.BPID) {
            rowsData.push(
                {
                    title: 'Overhead Allowance',
                    total: totalOverheadAllowance,
                    percent: percentOverheadAllowance,
                    percentageof: ofTitle,
                    type: 'OverheadAllowance',
                    level: 1,
                },
                {
                    title: 'WIP Adjusted Net Margin',
                    total: totalNetMargin,
                    percent: percentNetMargin,
                    percentageof: ofTitle + ' + WIP',
                    type: 'NetMargin',
                    level: 1,
                }
            )
        }
        return rowsData
    }

    PrepareTableData = (
        rows: Array<IStatementRow>,
        level: number = 1,
        lastData: Array<IDetailsRow> = [],
        lastTotalRevenue: number = 0,
        lastTotal: number = 0
    ) => {
        let data = lastData
        let totalRevenue = lastTotalRevenue
        let total = lastTotal

        rows.forEach((row) => {
            let result = this.GetTableRowData(row, level, totalRevenue, total, data)
            totalRevenue = result.totalRevenue
            total = result.total
            if (result.item.level === 1 || result.item.amount !== 0) data.push(result.item)
            if (row.subRows && row.subRows.length) {
                data.concat(this.PrepareTableData(row.subRows, level + 1, data, totalRevenue, total))
            }
        })

        return data
    }

    LoadPieChartData = async () => {
        try {
            this.setState({loadingPieChartData: true})
            let params: simpleObject = {}
            if (!this.isPLDashboard) {
                params.buildPlanId = this.props.buildPlanId
            } else if (this.config) {
                let plProps = this.config
                let dataItem = plProps.dataItem
                params = {
                    dateFrom: plProps.dataFrom,
                    dateTo: plProps.dataTo,
                    periodType: plProps.periodType,
                    statementId: plProps.statement.id,
                    buildPlanId: dataItem.BPID === null ? 0 : dataItem.BPID,
                    projectId: dataItem.ProjectID === null ? 0 : dataItem.ProjectID,
                    regionId: dataItem.RegionID === null ? 0 : dataItem.RegionID,
                    profitCenterId: dataItem.ProfitCenterID === null ? 0 : dataItem.ProfitCenterID,
                    customerId: dataItem.CustomerID === null ? 0 : dataItem.CustomerID,
                    siteId: dataItem.SiteID === null ? 0 : dataItem.SiteID,
                    classId: dataItem.ClassID === null ? 0 : dataItem.ClassID,
                }
            }

            let data = await this.GetSQLData({
                method: 'GET',
                path: 'pnl',
                spName: 'GetExpensesBySources',
                params,
                paramsToQuery: true
            })

            let revenue = data.find((item: IPieItem) => item.type === 'Revenue')
            let pieChartData = data.filter((item: IPieItem) => item.type !== 'Revenue')
            let sum = pieChartData.reduce((sum: number, current: IPieItem) => sum + current.amount, 0)
            let difference = revenue ? revenue.amount - Math.abs(sum) : 0
            if (difference > 0) pieChartData.push({
                title: 'Profit Margin',
                amount: difference,
                isProfitMargin: true
            })
            overheadIndex = 0;
            pieChartData.forEach((item: IPieItem, i: number) => {
                item.amount = Math.abs(item.amount)
                item.color = this.GetColorInPieChart(item.title)
            })
            this.setState({
                pieChartData,
                totalRevenue: revenue ? revenue.amount : null
            })
        } finally {
            this.setState({loadingPieChartData: false})
        }
    }

    LoadStatement = () => {
        return this.GetSQLData({
            method: 'GET',
            path: 'pnl',
            spName: 'GetDefaultStatement'
        })
    }

    LoadGroupedFD = () => {
        return this.GetSQLData({
            spName: 'GetGroupedFD',
            params: {buildPlanId: this.props.buildPlanId}
        })
    }

    LoadTabsSettings = () => {
        // todo load only first time not load on refresh
        return this.GetSQLData({spName: 'PL_AvailableTabs'})
    }

    LoadProps = async () => {
        try {
            this.setState({loading: true})
            let results: Array<any> = await Promise.allSettled([
                this.LoadStatement(),
                this.LoadGroupedFD(),
                this.LoadTabsSettings()
            ])
            let isEnabledDetails = false
            if (results[2].status === 'fulfilled') {
                let tabsSettings = results[2].value[0]
                isEnabledDetails = tabsSettings.Detailing === true
            }
            if (results[0].status === 'fulfilled' && results[1].status === 'fulfilled') {
                let statement = results[0].value
                PrepareStatement(statement)
                let amounts = results[1].value[0]
                let groupedFDdata = results[1].value[1]
                let groupedAmounts = groupFDAmounts(amounts)
                let groupedData = GetGroupedData(groupedFDdata, statement.defaultDimensions)
                let gridData = CalculateGroupedData(groupedData, statement.columns, groupedAmounts, statement.hasServiceRevenueRow)
                this.config = {
                    statement,
                    amounts: groupedAmounts,
                    dataItem: gridData[0],
                    dataFrom: null, // ??
                    dataTo: null, // ??
                    periodType: 0 //??
                }
                this.setState({tableData: this.GetTableData(), isEnabledDetails})
            }
        } finally {
            this.setState({loading: false})
        }
    }

    GetColorInPieChart = (title: string) => {
        if (TITLE_TO_COLOR_PIE[title]) return TITLE_TO_COLOR_PIE[title]
        if (title.toLowerCase().indexOf('overhead') > -1) {
            let color = OVERHEAD_COLORS[overheadIndex];
            overheadIndex++
            return color
        }
        return 'grey'
    }

    OnChangeMode = (value: boolean) => {
        if (!value && !this.initedDetails) this.initedDetails = true
        this.isSummaryMode = value
        this.setState({isSummaryMode: value})
        if (this.props.onChangeGridData) this.props.onChangeGridData([])
    }

    HasSetdefaultsFilters = () => !this.isSummaryMode // external

    ExportToExcel = () => { // external
        let activeModeRef = this.isSummaryMode ? this.summaryRef.current : this.detailsRef.current?.gridRef?.current;
        if (activeModeRef?.ExportToExcel) activeModeRef?.ExportToExcel()
    }

    Refresh = () => { // external
        this.InitGettingData()
        if (this.initedDetails && this.detailsRef.current?.gridRef?.current?.Refresh) {
            this.detailsRef.current.gridRef.current.Refresh()
        }
    }

}

export default PLStatement
