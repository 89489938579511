import BaseComponent from '../../BaseComponent';
import {IBPInfo} from '../../../Pages/BPDashboard/interfaces';
import BPHoursGauge from '../../../Pages/BPDashboard/BPHoursGauge';
import Loader from '../../Common/Loader';
import BPHoursDetails from '../../../Pages/BPDashboard/BPHoursDetails';
import {CalculateDataInfo} from '../../../Pages/BPDashboard/helpers';
import commonStyles from '../../../assets/styles/common.module.scss';

interface props {
  buildPlanId: number
}

interface state {
  loading: boolean
  noData: boolean
  bpInfo: IBPInfo | undefined
}

class LaborHoursDetails extends BaseComponent<props, state> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      noData: false,
      bpInfo: undefined
    }
  }

  componentDidMount() {
    if (this.props.buildPlanId) this.LoadData();
  }

  componentDidUpdate(prevProps: props) {
    if (prevProps.buildPlanId !== this.props.buildPlanId) {
      this.LoadData();
    }
  }

  render() {
    if (this.state.loading) return <Loader/>
    const bpInfo = this.state.bpInfo;
    if (!bpInfo) return 'No Data';

    return <div className={commonStyles.TextCenter}>
      <BPHoursGauge key={this.props.buildPlanId + 'gauge'} data={bpInfo}/>
      <BPHoursDetails key={this.props.buildPlanId + 'details'} data={bpInfo}
                      style={{
                        width: 300,
                        display: "inline-block",
                        verticalAlign: 'middle',
                        textAlign: 'left',
                        margin: '16px 0'
                      }}/>
    </div>
  }

  LoadData = async () => {
    this.setState({loading: true})
    try {
      let params = {BuildPlanId: this.props.buildPlanId};
      let result = await this.GetSQLData({spName: 'DB_BP', params});
      let bpInfoServer = result[1] && result[1][0]
      let bpInfo = bpInfoServer && CalculateDataInfo(bpInfoServer);
      this.setState({bpInfo});
    } finally {
      this.setState({loading: false})
    }
  }

}

export default LaborHoursDetails;
