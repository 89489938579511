import React, {Component} from 'react'
import DashboardGrid from '../Dashboard/Grid'
import {IColumnSetting, IFilterSetting} from '../Dashboard/interfaces'
import {Button} from '@progress/kendo-react-buttons'
import {simpleObject} from '../../helpers/interfaces'
import CardManagement from '../Cards/CardManagement'
import {pageId} from '../../Pages/Home/interfaces'
import OpenCardLink from '../Common/Buttons/OpenCardLink'
import {RunScript} from '../../helpers/runscripts'

interface props {
    buildPlanId: number
    isActive: boolean
    pageId: pageId
    defaultColumnValuesFilter?: { field: string, values: any[] }[]

    onChangeGridData?(data: Array<simpleObject>): void
}

interface state {
}

class WorkOrders extends Component<props, state> {
    gridRef: any = React.createRef()
    woId: number | undefined = undefined
    bpId = this.props.buildPlanId
    isBPTab: boolean = !!this.props.buildPlanId
    setDefaultFilters: boolean = !this.isBPTab || this.props.pageId === 'LiveMap'
    COLUMNS: Array<IColumnSetting>
    FILTERS: Array<IFilterSetting> = [
        {
            id: 'filterPeriod',
            type: 'dateRange',
            serverFilter: {
                param: ''
            },
            dateRangeSettings: {
                dateFromParam: 'dateFrom',
                dateToParam: 'dateTo',
                defaultPeriod: 'last12months',
                allowEmptyValues: false
            },
            toolbarRow: 1
        }
    ]

    constructor(props: props) {
        super(props)
        this.COLUMNS = []
        if (!this.isBPTab) {
            this.COLUMNS.push(
                {
                    field: 'BuildPlanName',
                    title: 'Build Plan',
                    type: 'string',
                    gridWidth: 190,
                    format: {
                        type: 'link',
                        fieldId: 'BuildPlanId',
                        refName: 'FSMBuildPlans'
                    },
                    aggregate: 'count'
                },
                {
                    field: 'BPStatus',
                    title: 'BP Status',
                    type: 'string',
                    gridWidth: 90,
                    format: {
                        type: 'string',
                        fieldId: 'BPStatus'
                    }
                },
                {
                    field: 'BPOName',
                    title: 'BP Owner',
                    type: 'string',
                    gridWidth: 120,
                    format: {
                        type: 'string',
                        fieldId: 'BPOId'
                    }
                }
            )
        }
        this.COLUMNS.push(
            {
                title: 'Sales Orders QTY',
                field: 'SalesOrdersQTY',
                type: 'number',
                format: {
                    type: 'number',
                    customFormat: true,
                    getCustomFormat: this.renderSOQTYCell
                }
            },
            {
                title: 'Sales Orders Amount',
                field: 'SalesOrdersAmount',
                type: 'number',
                format: {
                    type: 'currency'
                }
            },
            {
                field: 'Number',
                title: 'Number',
                type: 'string',
                format: {
                    type: 'link',
                    refName: 'FSMWorkOrders',
                    fieldId: 'Id'
                }
            },
            {
                field: 'TypeName',
                title: 'Type',
                type: 'string',
                format: {
                    type: 'string',
                    fieldId: 'TypeId'
                }
            },
        )

        if (this.isBPTab) {
            this.COLUMNS.push(
                {
                    field: 'IsPrevailingWageStr',
                    title: 'PW',
                    type: 'string',
                    gridWidth: 70,
                    format: {
                        type: 'string',
                        fieldId: 'IsPrevailingWage',
                    },
                    aggregate: 'count'
                },
            )
        }

        this.COLUMNS.push(
            {
                field: 'CategoryName',
                title: 'Category',
                type: 'string',
                minWidth: 180,
                format: {
                    type: 'string',
                    fieldId: 'CategoryId'
                }
            },
            {
                field: 'Stage',
                title: 'Stage',
                type: 'string',
                gridWidth: 120,
                format: {
                    type: 'string',
                    fieldId: 'Stage'
                }
            },
            {
                field: 'LastPublishedDispatchDate',
                title: 'Last Published Dispatch',
                type: 'date',
                gridWidth: 110,
                columnMenu: false,
                format: {
                    type: 'date'
                }
            },
            {
                field: 'LastCrewLeadName',
                title: 'Assigned To',
                type: 'string',
                format: {
                    type: 'string',
                    fieldId: 'LastCrewLeadId'
                }
            },
            {
                field: 'LastOnSite',
                title: 'Last On Site',
                type: 'date',
                columnMenu: false,
                format: {
                    type: 'datetime'
                }
            },
            {
                field: 'SiteName',
                title: 'Site',
                type: 'string',
                format: {
                    type: 'link',
                    refName: 'FSMSites',
                    fieldId: 'SiteId'
                }
            },
            {
                field: 'LastComment',
                title: 'Last Comment',
                type: 'string',
                columnMenu: false,
                gridWidth: 110,
                format: {
                    type: 'icon',
                    iconClass: 'k-icon k-i-file-txt'
                }
            },
            {
                field: 'TotalHours',
                title: 'Total Hrs',
                gridWidth: 110,
                type: 'number',
                columnMenu: false,
                format: {
                    type: 'number'
                },
                aggregate: 'sum'
            },
            {
                field: 'Description',
                title: 'Description',
                type: 'string',
                gridWidth: 90,
                columnMenu: false,
                format: {
                    type: 'icon',
                    iconClass: 'k-icon k-i-file-txt'
                }
            },
            {
                field: 'CompletedDate',
                title: 'Completed',
                gridWidth: 110,
                type: 'date',
                columnMenu: false,
                format: {
                    type: 'date'
                }
            },
            {
                field: 'Status',
                title: 'Status',
                type: 'string',
                gridWidth: 90,
                format: {
                    type: 'string',
                    fieldId: 'Status'
                },
                values: [
                    {
                        Name: 'Active',
                        Id: 'Active',
                        FieldId: 'Status',
                        Selected: true
                    },
                    {
                        Name: 'Closed',
                        Id: 'Closed',
                        FieldId: 'Status',
                        Selected: true
                    }
                ]
            }
        )
    }

    render() {
        if (
            !this.isBPTab &&
            this.props.isActive &&
            this.bpId !== this.props.buildPlanId
        ) {
            this.bpId = this.props.buildPlanId
        }

        if (
            (this.isBPTab && !this.props.buildPlanId) ||
            (!this.props.isActive && this.bpId !== this.props.buildPlanId)
        )
            return null
        let defaultColumnValuesFilter: { field: string, values: any[] }[] | undefined = undefined
        if (this.props.defaultColumnValuesFilter) {
            defaultColumnValuesFilter = this.props.defaultColumnValuesFilter
        } else if (this.setDefaultFilters) {
            defaultColumnValuesFilter = [
                {
                    field: 'Status',
                    values: ['Active']
                },
                {
                    field: 'Stage',
                    values: ['Completed', 'Scheduled', 'Published']
                }
            ]
        }
        return (
            <DashboardGrid
                key={this.isBPTab ? this.props.buildPlanId : 'WorkOrdersReport'}
                ref={this.gridRef}
                isParentPage={!this.isBPTab}
                isActive={this.props.isActive}
                columnsSettings={this.COLUMNS}
                filters={!this.isBPTab ? this.FILTERS : undefined}
                getSqlParams={{
                    spName: 'DB_BuildPlanWorkOrders',
                    params: this.isBPTab
                        ? {buildPlanId: this.props.buildPlanId}
                        : undefined
                }}
                onMarkRow={this.OnMark}
                getRowKey={this.GetRowKey}
                initRefresh={this.InitRefresh}
                excelFileName={
                    this.isBPTab
                        ? 'Build Plan Work Orders.xlsx'
                        : 'Work Orders Report.xlsx'
                }
                columnMenu={{filters: false, columns: false}}
                prepareData={this.isBPTab ? this.PrepareData : undefined}
                toolbar={{
                    isSingleRowToolbar: true,
                    actionBtns: (
                        <>
                            <Button onClick={this.NewWO}>New WO</Button>
                            <Button onClick={this.NewDispatch}>New Dispatch</Button>
                        </>
                    )
                }}
                defaultColumnValuesFilter={defaultColumnValuesFilter}
                onChangeGridData={this.props.onChangeGridData}
            />
        )
    }

    renderSOQTYCell = (dataItem: simpleObject, field: string) => {
        if (!dataItem.SalesOrdersQTY) return null
        return (
            <OpenCardLink
                dataAttr={dataItem.Id}
                text={dataItem.SalesOrdersQTY}
                onClick={this.OpenSalesOrders}
                refName="TK_ShowSalesOrderForWO"
            />
        )
    }

    PrepareData = (result: any) => {
        let data = result[0]
        for (let row of data) {
            row.IsPrevailingWageStr = row.IsPrevailingWage ? 'Yes' : 'No'
        }
        return data
    }

    OpenSalesOrders = (
        event: React.BaseSyntheticEvent,
        woId: number,
        refName: string
    ) => {
        RunScript(refName, {WOID: woId})
    }

    Refresh = () => {
    }

    InitRefresh = (refresh: any) => {
        this.Refresh = refresh
    }

    GetRowKey = (dataItem: simpleObject) => dataItem.Id

    OnMark = (dataItem: simpleObject | null) => {
        // if (!this.isBPTab) this.bpId = dataItem?.BuildPlanId
        this.woId = dataItem ? dataItem.Id : undefined
    }

    NewWO = () => {
        CardManagement.NewWO(this.props.buildPlanId, this.Refresh)
    }

    NewDispatch = () => {
        CardManagement.OpenDispatchCard({
            newDispatch: true,
            buildPlanId: this.props.buildPlanId,
            woId: this.woId,
            afterSave: this.Refresh
        })
    }
}

export default WorkOrders
