import {Component} from 'react';
import {ComboBox, DropDownsPopupSettings} from '@progress/kendo-react-dropdowns'
import {IFilterSetting,} from './interfaces'
import {filterBy} from "@progress/kendo-data-query";
import {IComboboxItem} from '../../helpers/interfaces';

interface props {
  loading?: boolean
  filter: IFilterSetting
  defaultValue: any
  filterData?: Array<IComboboxItem>
  getData?: any
  popupSettings?: DropDownsPopupSettings
  style?: React.CSSProperties

  onChange(value: any, filter: any): void
}

interface state {
  filter: any
  filteredData: Array<IComboboxItem>
  loading: boolean
}

class ComboboxFilter extends Component<props, state> {
  data: Array<IComboboxItem> = this.props.filterData || []

  constructor(props: props) {
    super(props)
    this.state = {
      filter: null,
      filteredData: this.data,
      loading: false,
    }
  }

  componentDidMount() {
    if (this.props.getData) this.LoadData()
  }

  render() {
    let filter = this.props.filter;
    return <ComboBox
      id={filter.id}
      data={this.state.filteredData}
      placeholder={filter.placeholder}
      defaultValue={this.props.defaultValue}
      textField="Name"
      dataItemKey="Id"
      style={{width: filter.width || 200, ...(this.props.style || {})}}
      onChange={this.OnChangeCombobox}
      filterable={true}
      onFilterChange={this.FilterCombobox}
      onOpen={this.OnOpenCombobox}
      disabled={!!this.props.filter.disabled}
      popupSettings={this.props.popupSettings}
      loading={this.props.loading || this.state.loading}
    />
  }

  FilterCombobox = (e: any) => {
    let data = filterBy(this.data, e.filter)
    this.setState({filter: e.filter, filteredData: data})
  }

  OnOpenCombobox = () => {
    this.setState({filter: null})
  }

  OnChangeCombobox = (e: any) => {
    let value = e.value
    this.props.onChange(value, this.props.filter)
  }

  LoadData = async () => {
    this.setState({loading: true})
    if (this.props.filterData) {
      this.data = this.props.filterData
    } else if (this.props.getData) {
      let data = await this.props.getData()
      this.data = data
      this.setState({filteredData: this.data})
    }
    this.setState({loading: false})
  }
}

export default ComboboxFilter
