import FXCard from '../Common/FXCard/FXCard'
import {ILocationCardProps} from './interfaces'
import {IAddressInfo} from '../../Pages/ObjectMap/interfaces'
import ObjectMap from '../../Pages/ObjectMap/ObjectMap'
import formStyles from './card.module.scss'
import BaseComponent from '../BaseComponent'
import {Button} from '@progress/kendo-react-buttons'
import FilterCombobox from '../Common/Form/FilterCombobox'
import {Input, InputChangeEvent, TextAreaChangeEvent} from '@progress/kendo-react-inputs'
import Loader from '../Common/Loader'
import {IComboboxItem} from '../../helpers/interfaces'

import styles from '../../Pages/ObjectMap/objectmap.module.scss'
import {OpenRecord, RunScriptAsync, RunScriptLocalAsync} from '../../helpers/runscripts'
import OpenCardIconLink from './OpenCardIconLink'
import React from "react";
import {LoadLocationTypes} from "../../helpers/LoadLists";

interface IEditableInfo {
    LocationName: string
    LocationType: IComboboxItem | null
}

interface state {
    lastResizeCard: number
    loadedInitialData: boolean

    locationTypes: Array<IComboboxItem>

    processing: boolean
    remountKey: number
    invalidForm: boolean

    addressString: string
}

interface IServerLocationInfo {
    LocationId: number
    LocationName: string
    LocationTypeId: number
    AddressId: number
}

class LocationCard extends BaseComponent<ILocationCardProps, state> {
    isNewLocation = this.props.locationId === undefined
    EditableInfo: IEditableInfo = {
        LocationName: '',
        LocationType: null,
    }
    AddressInfo: IAddressInfo | null = null
    ServerLocationInfo: IServerLocationInfo | null = null

    constructor(props: ILocationCardProps) {
        super(props)
        this.state = {
            lastResizeCard: +new Date(),
            loadedInitialData: false,
            locationTypes: [],
            addressString: '',
            processing: false,
            remountKey: +new Date(),
            invalidForm: true,
        }
    }

    componentDidMount(): void {
        this.LoadInitialData()
    }

    render() {
        return (
            <FXCard
                title={<span className={formStyles.HeaderTitle}>{this.renderTitle()}</span>}
                onClose={this.Close}
                initialWidth={730}
                initialHeight={750}
                originalPaddings={true}
                onResize={this.OnCardResize}
                onStageChange={this.OnCardResize}>
                {this.renderCardContent()}
            </FXCard>
        )
    }

    renderTitle = () => {
        if (this.isNewLocation) return <>
            <OpenCardIconLink
                onClick={this.OpenCreateLocationCard} // todo do we need this btn?
                title="Open Create Location Card"
            />
            <span>New Location</span>
        </>
        return (
            <>
                <OpenCardIconLink
                    onClick={this.OpenLocationCard}
                    title="Open Location Card"
                />
                <span>
                  {!this.state.loadedInitialData
                      ? 'Loading...'
                      : this.ServerLocationInfo?.LocationName || 'Edit Location'}
                </span>
            </>
        )
    }

    renderCardContent = () => {
        if (!this.state.loadedInitialData) return <Loader/>
        return (
            <>
                {this.state.processing && <Loader/>}
                <div
                    className={formStyles.FormWrapper}
                    style={{position: 'relative'}}>

                    <div style={{display: 'flex'}}>
                        <div className={styles.Left}>
                            <Input
                                key={this.state.remountKey + 'name'}
                                className={`${formStyles.FormField} ${formStyles.TextField}`}
                                placeholder="Location Name"
                                defaultValue={this.EditableInfo.LocationName}
                                name="LocationName"
                                onChange={this.OnChangeTextField}
                            />
                        </div>
                        <div className={styles.Right}>
                            <FilterCombobox
                                key={this.state.remountKey + 'types'}
                                placeholder="Location Types *"
                                defaultValue={this.EditableInfo.LocationType}
                                data={this.state.locationTypes}
                                onChange={this.OnChangeField}
                                className={`${formStyles.FormField}`}
                                dataAttr={'LocationType'}
                                required={true}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative'
                        }}>
                        <ObjectMap
                            lastResize={this.state.lastResizeCard}
                            addressId={this.ServerLocationInfo?.AddressId}
                            coordinates={this.props.coordinates}
                            onChange={this.OnAddressChange}
                            objectType={'Location'}
                        />
                    </div>
                    {this.renderFooter()}
                </div>
            </>
        )
    }

    renderFooter = () => {
        return (
            <div
                className={`${formStyles.FormFooter} k-action-buttons`}
                style={{paddingLeft: 8, paddingRight: 8}}>
                <span className={formStyles.InvalidMessage}>
                  {this.state.invalidForm && <span>Fill all mandatory fields</span>}
                </span>
                <Button onClick={this.Close}>Cancel</Button>
                <Button
                    onClick={this.UpsertLocation}
                    themeColor="primary"
                    disabled={this.state.invalidForm}>
                    {this.isNewLocation ? 'Create Location' : 'Save'}
                </Button>
            </div>
        )
    }

    OnChangeTextField = (e: InputChangeEvent | TextAreaChangeEvent) => {
        let field = e.target.name! as keyof IEditableInfo /* : 'LocationName' */
        this.OnChangeField(e.value, field)
    }

    OnChangeField = (value: any, dataAttr: keyof IEditableInfo) => {
        this.EditableInfo[dataAttr] = value
        this.setState({invalidForm: this.IsInValidForm()})
    }

    IsInValidForm = () => {
        if (this.isNewLocation) {
            return !this.EditableInfo.LocationName || !this.EditableInfo.LocationType || !this.AddressInfo
        } else {
            return !this.EditableInfo.LocationName || !this.EditableInfo.LocationType
        }
    }

    LoadInitialData = async () => {
        try {
            let result: Array<PromiseSettledResult<any>> = await Promise.allSettled([
                LoadLocationTypes(),
                this.LoadLocationData()
            ])
            // @ts-ignore
            const [{value: locationTypes = []}, {value: locationInfo}]: [obj1: {
                value: IComboboxItem[]
            }, obj2: { value: IServerLocationInfo }] = result

            if (!this.isNewLocation && locationInfo) {
                const {LocationName, LocationTypeId} = locationInfo
                this.ServerLocationInfo = locationInfo
                this.EditableInfo = {
                    LocationName,
                    LocationType: locationTypes.find((type: IComboboxItem) => type.Id === LocationTypeId) || null,
                }
            }
            this.setState({
                locationTypes,
                invalidForm: this.IsInValidForm(),
                remountKey: +new Date()
            })
        } finally {
            this.setState({loadedInitialData: true})
        }
    }

    LoadLocationData = async () => {
        if (this.isNewLocation) return null
        let result: any = await this.GetSQLData({
            spName: 'Locations_GetData',
            params: {locationId: this.props.locationId}
        })
        return result[0][0] as IServerLocationInfo
    }

    OpenCreateLocationCard = async () => {
        await RunScriptLocalAsync('Locations_CreateNew', {
            Lat: this.props.coordinates?.lat,
            Lng: this.props.coordinates?.lng
        });
        this.Close()
    }

    OpenLocationCard = () => {
        if (this.props.locationId) OpenRecord('Locations', this.props.locationId)
    }

    UpsertLocation = async () => {
        try {
            this.setState({processing: true})
            let locationId = await RunScriptAsync('Locations_Upsert', {
                LocationId: this.props.locationId,
                LocationJSON: JSON.stringify({
                    Name: this.EditableInfo.LocationName,
                    TypeId: this.EditableInfo.LocationType?.Id || null,
                    AddressJSON: JSON.stringify(this.AddressInfo),
                })
            })
            if (this.props.onFinish) this.props.onFinish(this.props.locationId || locationId)
            this.Close()
        } finally {
            this.setState({processing: false})
        }
    }

    OnAddressChange = (
        AddressInfo: IAddressInfo | null
    ) => {
        this.AddressInfo = AddressInfo
        this.setState({
            invalidForm: this.IsInValidForm()
        })
    }

    OnCardResize = () => {
        this.setState({lastResizeCard: +new Date()})
    }

    Close = () => {
        this.props.finally!()
    }
}

export default LocationCard
