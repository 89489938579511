export default function GridRow(props: {
    props: any,
    row: any,
    gridProps?: { getRowClass?: any, OnRowContextMenu?: any }
}) {
    let rowClassName = props.row.props.className
    if (props.gridProps?.getRowClass) rowClassName = `${rowClassName} ${props.gridProps.getRowClass(props.props.dataItem)}`
    return (<tr
        {...props.row.props}
        onContextMenu={props.gridProps?.OnRowContextMenu}
        className={rowClassName}
        data-rowkey={props.props.dataItem.Key}
    >
        {props.row.props.children}
    </tr>);
}
