import React from "react";
import {IInitialTabSettings, tabId} from "../Components/Tabs/interfaces";
import {costTypeCode} from "../Pages/TKReview/interfaces";
import {simpleObject} from "./interfaces";
import {IOtherNodePoint} from "../Components/Map/interfaces";

let __DEV__ = window.location.host === "localhost:3000";
const IntegrationWithDesktop = "IntegrationWithDesktop";
const IntegrationWithDesktopContext = React.createContext({
    isIntegrationWithDesktop: false,
    toggleIntegrationWithDesktop: () => {
    },
});

declare global {
    interface Window {
        isIe: boolean;
        isTouchDevice: boolean;
        DataServerHost: string;
        vimBadgeText: string;
        tkReviewBadgeText: string;
        tkReviewCounterTimeout: any;
        helpers:
            | {
            isConterraFolder: boolean;
            isConterra: boolean;
            isObjectForm: boolean;
            currentObjectDataSet: any;
            currentObjectId?: string;
            currentObjectType?: string;
            openRecord(refName: string, id: number, params?: simpleObject): void;
            runScriptLocalAsync: any;
            runScript: any;
            runScriptAsync: any;
            getSQLData: any;
            getRecordLink: any;
            addParameterToURL: any;
            onInactive: any;
            openDispatchCard: any;
            newDispatch: any;
            openBPCard: any;
            openWebBPCard(
                buildPlanId: number,
                initialTab: tabId | IInitialTabSettings,
                onFinish?: any
            ): void;
            newWO: any;
            openClmReviewCard: any;
            liveMapPortal: any;
            liveMapPortalRemove: any;
            openTKLogsCard: any;
            openTCMapCard: any;
            openTCHistoryCard: any;
            timeCardPortal: any;
            timeCardPortalHide: any;
            timeCardPortalForceRefresh: any;
            solicitTKRequest: any;
            formatHoursDuration: any;
            openGBPCard: any;
            openLocationCard(locationId: number): void
            openNewLocationCard(coordinates: {
                lat: number,
                lng: number
            }, onFinish?: (locationId: number) => void): void
            openSiteCard(siteId: number): void
            livemapOpened: boolean;
            getColorByCostType(costType: costTypeCode): string;
            isDesktopUser: boolean;
            IsDesktopIntegrationOn(): boolean;
            CLPMRouteToReview?(woId: number, bpId: number): void;
            TKReviewUpdatePendingCount: () => void;
            getPolygonCoordinatesFromStrBoundaries: (
                boundaries: string
            ) => number[][];
            mapZIndexMarkers: { [key: string]: number }
            getAllSiteDetailsHtml(item: IOtherNodePoint): any
        }
            | simpleObject;
        $: any;
        MSPointerEvent: any;
        PointerEvent: any;
        livemap: any;
        geocodingControl: any;
        L: any;
        OverlappingMarkerSpiderfier: any;
    }
}
window.helpers = {};
const getParamsFromUrl = () => {
    var strParams = window.location.search.replace("?", "").split("&");
    var params: any = {};
    strParams.forEach(function (str) {
        var t = str.split("=");
        params[t[0]] = t[1];
    });
    return params;
};
const DataServerHost = getParamsFromUrl().serviceUrl
    || `fxapp.${__DEV__ ? "debug.fieldclix.com" : window.location.host}`;
const mapQuestKey = "SoMm2tfHQ6fueiRsj6gfFMhGiIA2BM4r";
const IS_IE = window.isIe;
const IS_TOUCH_DEVICE = window.isTouchDevice;

let external: any = window.external;
let isConterraForm = external?.Form !== undefined;
let isConterraFolder = external?.Folder !== undefined;
let isConterra = isConterraForm || isConterraFolder;
let isObjectForm = false;

let currentObjectId: string | undefined;
let currentObjectDataSet: any;
let currentObjectType: undefined | string;

if (isConterra) {
    window.MSPointerEvent = null;
    window.PointerEvent = null;
}

if (isConterraForm) {
    currentObjectDataSet =
        external.Form.Controls.FindControlsByType(9).Value.Requisite.Dataset;
    currentObjectId = currentObjectDataSet.Requisites("ISBID").Value;
    currentObjectType = currentObjectDataSet.Component.Code;
    isObjectForm = true;
}
let currentUserId: number;

//@ts-ignore
let isMobile =
    !isConterra &&
    window.matchMedia("only screen and (max-width: 1200px)").matches;

let application = () => {
    if (isConterraFolder) return external?.Folder.Application;
    if (isConterraForm)
        return external?.Form.Controls.FindControlsByType(9).Value.Requisite.Dataset
            .Component.Application;
};

window.helpers.isConterra = isConterra;
window.helpers.currentObjectDataSet = currentObjectDataSet;
window.helpers.currentObjectType = currentObjectType;
window.helpers.currentObjectId = currentObjectId;
window.helpers.isObjectForm = isObjectForm;
window.helpers.isConterraFolder = isConterraFolder;
window.helpers.isObjectForm = isObjectForm;
window.DataServerHost = DataServerHost;
window.helpers.isDesktopUser = isConterra || false;
window.vimBadgeText = "";
window.tkReviewBadgeText = "";
export {
    DataServerHost,
    IS_TOUCH_DEVICE,
    IS_IE,
    IntegrationWithDesktop,
    IntegrationWithDesktopContext,
    __DEV__,
    application,
    currentObjectDataSet,
    currentObjectId,
    currentObjectType,
    currentUserId,
    isConterra,
    isConterraFolder,
    isConterraForm,
    isMobile,
    isObjectForm,
    mapQuestKey,
};
