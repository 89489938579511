import {IntegrationWithDesktopContext} from '../../helpers/settings';
import Switch from '../../Components/Switch/Switch'
import styles from './Home.module.scss'

export default function SwitchDesktopIntegration() {
  return (<IntegrationWithDesktopContext.Consumer>
    {({isIntegrationWithDesktop, toggleIntegrationWithDesktop}) => {
      return <>
        <div className={styles.SwitchBox}>
          <Switch
            id='autorefresh'
            checked={isIntegrationWithDesktop}
            onChange={toggleIntegrationWithDesktop}
          ></Switch>
        </div>
        <label htmlFor='autorefresh' className={styles.DrawerItemText}>
          Integration with Desktop
        </label>
      </>
    }}
  </IntegrationWithDesktopContext.Consumer>);
}
