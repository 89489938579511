import React, {Component} from 'react';
import {simpleObject} from '../../helpers/interfaces';
import DashboardGrid from '../Dashboard/Grid';
import {IColumnSetting, IColumnValue, IDashboardChildProps} from '../Dashboard/interfaces';
import {formatDateColumn} from "../TC/helpers";
import UserInfo from '../../stores/User';
import CardManagement from "../Cards/CardManagement";

const WAGE_TYPES: Array<IColumnValue> = [
    {
        Name: 'Regular',
        Id: 'Regular',
        FieldId: 'WageType',
        Selected: true
    },
    {
        Name: 'Double',
        Id: 'Double',
        FieldId: 'WageType',
        Selected: true
    },
    {
        Name: 'Overtime',
        Id: 'Overtime',
        FieldId: 'WageType',
        Selected: true
    },
]

interface state {
    settingsInited: boolean
}

interface props {
    periodId?: number
    isActive: boolean
    wageType?: string
    pw?: boolean
    EmployeeId?: number
    ExternalCode?: string

    onChangeGridData?(data: Array<simpleObject>): void
}

class PayrollPeriod extends Component<props, state> {
    gridRef: any = React.createRef()
    periodId = this.props.periodId
    COLUMNS: Array<IColumnSetting> = []

    constructor(props: IDashboardChildProps) {
        super(props)
        this.state = {
            settingsInited: false
        }
    }

    componentDidMount(): void {
        this.InitSettings()
    }

    InitSettings = async () => {
        let IsFinancialDataAllow = await UserInfo.getIsFinancialDataAllow()
        this.COLUMNS = [
            {
                field: 'RowNum',
                title: '#',
                type: 'number',
                gridWidth: 40,
                filterable: false,
                columnMenu: false,
                aggregate: 'count',
            },
            {
                field: 'Date',
                title: 'Date',
                type: 'date',
                format: {
                    type: 'date',
                    customFormat: true,
                    getCustomFormat: formatDateColumn
                }
            },
            {
                field: 'CheckName',
                title: 'Payroll Check',
                type: 'string',
                gridWidth: 230,
                format: {
                    type: 'link',
                    fieldId: 'CheckId',
                    refName: 'TKPayrollChecks'
                },
            },
            {
                field: 'EmployeeName',
                title: 'Employee',
                type: 'string',
                gridWidth: 200,
                format: {
                    type: 'link',
                    fieldId: 'EmployeeId',
                    refName: 'Employees'
                },
            },
            {
                field: 'EmployeeFirstName',
                title: 'First Name',
                type: 'string',
                gridWidth: 100,
            },
            {
                field: 'EmployeeLastName',
                title: 'Last Name',
                type: 'string',
                gridWidth: 100,
            },
            {
                field: 'EmployeeExternalId',
                title: 'Employee Id',
                type: 'string',
                gridWidth: 100,
            },
            {
                field: 'SyncedStr',
                title: 'Synced',
                type: 'string',
                gridWidth: 90,
                format: {
                    type: 'string',
                    fieldId: 'Synced',
                }
            },
            {
                field: 'DepartmentName',
                title: 'Department',
                type: 'string',
                format: {
                    type: 'link',
                    fieldId: 'DepartmentId',
                    refName: 'Departments'
                },
            },
            {
                field: 'CategoryName',
                title: 'Employee Category',
                type: 'string',
                gridWidth: 130,
                format: {
                    type: 'link',
                    fieldId: 'CategoryId',
                    refName: 'EmployeeCategories'
                },
            },
            {
                field: 'PayrollStateName',
                title: 'State',
                type: 'string',
                gridWidth: 80,
                format: {
                    type: 'string',
                    fieldId: 'PayrollStateId',
                },
            },
            {
                field: 'ProjectName',
                title: 'Project',
                type: 'string',
                gridWidth: 150,
                format: {
                    type: 'string',
                    fieldId: 'ProjectId'
                }
            },
            {
                field: 'ExternalCode',
                title: 'External Code',
                type: 'string',
                gridWidth: 120,
                format: {
                    type: 'string',
                    fieldId: 'ExternalCode'
                }
            },
            {
                field: 'PWStr',
                title: 'PW',
                type: 'string',
                format: {
                    type: 'string',
                    fieldId: 'PW',
                },
            },
            {
                field: 'CostTypeName',
                title: 'Cost Type',
                type: 'string',
                gridWidth: 150,
                format: {
                    type: 'string',
                    fieldId: 'CostTypeId'
                },
            },
            {
                field: 'WageType',
                title: 'Wage Type',
                type: 'string',
                gridWidth: 150,
                values: WAGE_TYPES,
                format: {
                    type: 'string',
                    fieldId: 'WageType'
                },
            },
            {
                field: 'Hours',
                title: 'Hours',
                type: 'number',
                format: {
                    type: 'number'
                },
                aggregate: 'sum'
            },
            {
                field: 'HasMorningStr',
                title: 'Morning Overnight',
                type: 'string',
                format: {
                    type: 'string',
                    fieldId: 'HasMorning',
                },
            },
            {
                field: 'HasEveningStr',
                title: 'Evening Overnight',
                type: 'string',
                format: {
                    type: 'string',
                    fieldId: 'HasEvening'
                },
            },
            {
                field: 'BuildPlanName',
                title: 'Build Plan',
                type: 'string',
                gridWidth: 200,
                format: {
                    type: 'link',
                    fieldId: 'BuildPlanId',
                    refName: 'FSMBuildPlans'
                },
            },
            {
                field: 'WOTypeName',
                title: 'WO Type',
                type: 'string',
                gridWidth: 200,
                format: {
                    type: 'string',
                    fieldId: 'WOTypeId',
                },
            },
            {
                field: 'ReasonName',
                title: 'Reason',
                type: 'string',
                format: {
                    type: 'string',
                    fieldId: 'ReasonId'
                },
            },
            {
                field: 'ClassName',
                title: 'Class',
                type: 'string',
                format: {
                    type: 'string',
                    fieldId: 'ClassId'
                },
            },
            {
                field: 'ServiceItemName',
                title: 'Service Item',
                type: 'string',
                gridWidth: 150,
                format: {
                    type: 'link',
                    fieldId: 'ServiceItemId',
                    refName: 'ServiceItems'
                }
            },
            {
                field: 'FinancialAccountName',
                title: 'Financial Account',
                type: 'string',
                gridWidth: 150,
                format: {
                    type: 'link',
                    fieldId: 'FinancialAccountId',
                    refName: 'FAAccounts'
                }
            },
            {
                field: 'CustomerName',
                title: 'Customer',
                type: 'string',
                gridWidth: 150,
                format: {
                    type: 'link',
                    fieldId: 'CustomerId',
                    refName: 'Organizations'
                }
            },
            {
                field: 'CustomerJobName',
                title: 'Customer Job',
                type: 'string',
                gridWidth: 150,
                format: {
                    type: 'link',
                    fieldId: 'CustomerJobId',
                    refName: 'FSMCustomersJobs'
                }
            },
        ]
        if (IsFinancialDataAllow) {
            this.COLUMNS.push({
                    field: 'CostRate',
                    title: 'Cost Rate',
                    type: 'number',
                    format: {
                        type: 'currency'
                    }
                },
                {
                    field: 'CostRateSource',
                    title: 'Cost Rate Source',
                    type: 'string',
                    format: {
                        type: 'string',
                        fieldId: 'CostRateSource'
                    }
                },
                {
                    field: 'TotalAmount',
                    title: 'Total Amount',
                    type: 'number',
                    format: {
                        type: 'currency'
                    },
                    aggregate: 'sum'
                })
        }

        this.setState({settingsInited: true})
    }

    render() {
        if (!this.state.settingsInited) return null
        let propsPeriodId = this.props.periodId
        if (this.props.isActive && this.periodId !== propsPeriodId) {
            this.periodId = this.props.periodId
        }

        // @ts-ignore
        let filters = []
        const ssFilters = sessionStorage.getItem('PPDetailTabColumnValueFilters')
        if (ssFilters) {
            let {wageType, pw, EmployeeId, ExternalCode} = JSON.parse(ssFilters)
            if (wageType) {
                if (wageType !== 'PWTotal') {
                    if (wageType.indexOf('PW') > -1) wageType = wageType.slice(2)
                    filters.push({
                        field: 'WageType',
                        values: [wageType]
                    })
                }
            }
            if (pw) {
                filters.push({
                    field: 'PW',
                    values: [pw]
                })
            }
            if (EmployeeId) {
                filters.push({
                    field: 'EmployeeId',
                    values: [EmployeeId]
                })
            }
            if (ExternalCode) {
                filters.push({
                    field: 'ExternalCode',
                    values: [ExternalCode]
                })
            }
            sessionStorage.removeItem('PPDetailTabColumnValueFilters')
        }
        if (!propsPeriodId || !this.props.isActive && this.periodId !== propsPeriodId) return null
        return <DashboardGrid
            ref={this.gridRef}
            key={propsPeriodId}
            isActive={this.props.isActive}
            columnsSettings={this.COLUMNS}
            getSqlParams={{
                spName: 'DB_Payroll',
                params: {
                    periodId: this.periodId
                }
            }}
            excelFileName="PayrollPeriod.xlsx"
            columnMenu={{
                filters: true,
                columns: true
            }}
            columnValuesFilter={!!filters.length ? filters : undefined}
            prepareData={this.PrepareData}
            getRowKey={(dataItem: simpleObject) => dataItem.RowNum}
            selectSettings={{
                pageId: 'TKDashboard',
                onSelectRow: this.OpenTCCard
            }}
            initRefresh={this.InitRefresh}
            onChangeGridData={this.props.onChangeGridData}
        />
    }

    PrepareData = (result: any) => {
        let data = result[0]
        data.forEach((item: simpleObject) => {
            item.PWStr = item.PW ? 'Yes' : 'No'
            item.SyncedStr = item.Synced ? 'Yes' : 'No'
            item.HasEveningStr = item.HasEvening ? 'Yes' : 'No'
            item.HasMorningStr = item.HasMorning ? 'Yes' : 'No'
        })
        return data
    }

    Refresh = () => {
    }

    InitRefresh = (refresh: any) => {
        this.Refresh = refresh
    }

    OpenTCCard = (rowData: simpleObject | null) => {
        if (rowData) {
            let tcId = rowData.TCId
            if (tcId) CardManagement.OpenTCCard(tcId)
        }
    }
}

export default PayrollPeriod;
