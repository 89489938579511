import {Button, Toolbar, ToolbarSpacer} from '@progress/kendo-react-buttons';
import {DropDownList} from '@progress/kendo-react-dropdowns';
import {Grid, GridColumn as Column} from '@progress/kendo-react-grid';
import {
  Checkbox,
  CheckboxChangeEvent,
  TextArea,
  TextAreaChangeEvent
} from '@progress/kendo-react-inputs';
import {Tooltip} from '@progress/kendo-react-tooltip';
import moment from 'moment';
import {SplitPane} from "react-multi-split-pane";
import BaseComponent from '../../Components/BaseComponent';
import CardManagement from '../../Components/Cards/CardManagement';
import OpenCardIconLink from '../../Components/Cards/OpenCardIconLink';
import OpenCardLink from '../../Components/Common/Buttons/OpenCardLink';
import DocumentViewer
  from '../../Components/Common/DocumentViewer/DocumentViewer';
import {IMAGES_EXT} from '../../Components/Common/DocumentViewer/helpers';
import FilterMultiSelect from '../../Components/Common/Form/FilterMultiSelect';
import Loader from '../../Components/Common/Loader';
import {ModalRef} from '../../Components/Common/Modal/Modal';
import {GridRowHeight} from '../../Components/Dashboard/helpers';
import {LoadDocuments} from '../../Components/RelatedDocuments/helpers';
import {GetDocumentUrl} from '../../helpers/helpers';
import {IComboboxItem} from '../../helpers/interfaces';
import {
  OpenRecord,
  RunScriptAsync,
  RunScriptLocalAsync
} from '../../helpers/runscripts';
import {IS_IE} from '../../helpers/settings';
import {IDetails, IDocument} from './interfaces';
import styles from './vim.module.scss';

interface props {
  VIMId: number | null

  onRefresh(VIMId: number): any

  onDelete(VIMId: number): any

  unSelectRow?(): void
}

interface state {
  loadingInfo: boolean
  loadingDocuments: boolean
  details: IDetails | null
  panes: Array<any>
  documents: Array<IDocument>
  documentIndex: number
  documentUrl: string
  changingPanes: boolean
  documentLoading: boolean
  dropDownContainerRef: any
}

class VIMDetails extends BaseComponent<props, state> {

  constructor(props: props) {
    super(props);
    this.state = {
      loadingInfo: false,
      loadingDocuments: false,
      details: null,
      documents: [],
      documentIndex: 0,
      documentUrl: '',
      panes: [
        {size: '50%', min: '530px', max: '850px', resizable: !IS_IE},
        {scrollable: false}
      ],
      changingPanes: false,
      documentLoading: false,
      dropDownContainerRef: null
    }
  }

  componentDidMount() {
    this.LoadDetails();
    this.LoadDocuments();
  }

  componentDidUpdate(prevProps: props) {
    let VIMId = this.props.VIMId;
    if (VIMId && prevProps.VIMId !== VIMId) {
      super.componentWillUnmount();
      this.LoadDetails();
    }
  }

  InitDropdownContainerRef = (ref: any) => {
    let stateRef = this.state.dropDownContainerRef
    if (ref && (!stateRef || stateRef !== ref)) {
      this.setState({dropDownContainerRef: ref})
    }
  }

  render() {
    if (!this.props.VIMId) return null;

    return (<div className={styles.SplitterBox}>
      <SplitPane split="vertical" defaultSizes={[1, 1]} minSize={[400, 400]}>
        <div className={styles.SplitterPane}>{this.renderInfoPane()}</div>
        <div className={styles.SplitterPane}>{this.renderDocumentPane()}</div>
      </SplitPane>
    </div>)
  }

  renderDocumentPane = () => {
    if (this.state.loadingDocuments) return <Loader/>
    let documents = this.state.documents
    let documentIndex = this.state.documentIndex
    let document = documents[documentIndex]
    return <>
      <Toolbar className={styles.RightToolbar}>
        {!!documents.length && <>
            <Button
                data-action="prev"
                icon='arrow-60-left'
                disabled={documentIndex === 0}
                onClick={this.ChangeDocument}
            />
            <span>{documentIndex + 1} of {documents.length}</span>
            <Button
                data-action="next"
                icon='arrow-60-right'
                disabled={documentIndex === documents.length - 1}
                onClick={this.ChangeDocument}
            />
            <div className={styles.DropdownContainer}
                 ref={this.InitDropdownContainerRef}>
              {!!this.state.dropDownContainerRef && <DropDownList
                  data={documents}
                  value={document}
                  onChange={this.SelectDocument}
                  className={styles.DocumentsSelect}
                  textField="DocumentName"
                  dataItemKey="DocumentId"
                  popupSettings={{
                    appendTo: this.state.dropDownContainerRef,
                    className: `${styles.DropdownPopup} vim-popup`
                  }} // fix for iframe with PDF in IE
                  onOpen={this.OnDropdownOpen}
                  onClose={this.OnDropdownClose}
              />}
            </div>
        </>}
        <ToolbarSpacer/>
        {this.state.details?.Info.CanAddDocument &&
            <>
                <Button
                    icon="plus"
                    fillMode="flat"
                    onClick={this.AddDocument}
                    title="Add Document"
                ></Button>
            </>
        }
        {document && <>
            <Button
                className={styles.LinkBtn}
                icon="download"
                onClick={this.DownloadDocument}
                fillMode="flat"
            />
            <a
                href={document.PreviewUrl}
                target="__blank"
                className={[styles.LinkBtn, "k-button k-button-md k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icon-button"].join(' ')}
                title="Open Document in New Tab"
            >
                <span className="k-icon k-i-hyperlink-open"></span>
            </a>
        </>}
        {!!this.props.unSelectRow && <Button icon='close' style={{color: 'red'}}
                                             onClick={this.props.unSelectRow}/>}
        <iframe id="vim-fix-iframe" className={styles.ToolbarFixIframe}
                src="about:blank"></iframe>
      </Toolbar>
      <DocumentViewer
        containerId={'vim-preview'}
        document={document ? {
          id: document.Id,
          extension: document.Extension,
          previewUrl: document.PreviewUrl
        } : undefined}
        documentLoading={this.state.documentLoading}
      />
    </>
  }

  renderInfoPane = () => {
    if (this.state.loadingInfo) return <Loader/>
    if (!this.state.details) return <div className={styles.DetailsNoData}>No
      Data</div>;
    let info = this.state.details.Info
    return <>
      <Toolbar>
        {info.CanReject &&
            <Button
                icon="cancel"
                fillMode="flat"
                onClick={this.Reject}
                className={styles.ReturnBtn}
            >Reject</Button>
        }
        {info.CanReturn &&
            <Button
                icon="undo"
                fillMode="flat"
                onClick={this.Return}
                className={styles.ReturnBtn}
            >Return to Pending</Button>
        }
        {info.CanRecalculateApprovers &&
            <Button
                icon="rotate"
                fillMode="flat"
                title="Recalculate Approvers"
                onClick={this.Recalculate}
                className={styles.BlueBtn}
            >Recalculate Approvers</Button>
        }
        {info.CanSendForApproval &&
            <Button
                icon="arrow-right"
                fillMode="flat"
                title="Send for Approval"
                onClick={this.SendForApproval}
                className={styles.ApproveBtn}
            >Send for Approval</Button>
        }
        {info.CanApprove &&
            <Button
                icon="check"
                fillMode="flat"
                onClick={this.Approve}
                className={styles.ApproveBtn}
            >Approve</Button>
        }
        {info.CanForceApprove &&
            <Button
                iconClass="mdi mdi-check-all"
                fillMode="flat"
                onClick={this.ForceApprove}
                className={styles.ApproveBtn}
            >Force Approve</Button>
        }
        {info.CanValidate &&
            <Button
                icon="check"
                fillMode="flat"
                onClick={this.Validate}
                className={styles.ApproveBtn}
            >Validate</Button>
        }
        {info.CanValidateAndApprove &&
            <Button
                icon="check"
                fillMode="flat"
                onClick={this.Validate}
                className={styles.ApproveBtn}
                data-approve={true}
            >Validate and Approve</Button>
        }
        <ToolbarSpacer/>
        <span className={styles.DetailsStatus}>{info.Status}</span>
        {info.CanDelete &&
            <Button
                icon="trash"
                fillMode="flat"
                title="Delete"
                onClick={this.DeleteInvoice}
            ></Button>
        }
        <OpenCardIconLink
          onClick={this.OpenCard}
          title="Open Card"
          notCardHeaderBtn={true}
        />
        <Button
          icon="refresh"
          fillMode="flat"
          className={styles.RefreshBtn}
          title="Refresh"
          onClick={this.RefreshDetails}
        >
        </Button>
      </Toolbar>
      <div
        className={[styles.SplitterPaneContent, styles.SplitterLeftPaneContent].join(' ')}>
        <div className={styles.DetailsCard}>{this.renderBaseDetails()}</div>
        <div className={styles.DetailsCard}>
          <b className={styles.DetailsCardTitle}>FCX Allocation</b>
          {this.renderPOGrid()}
        </div>
        {this.renderSpace()}
        <div className={styles.DetailsCard}>
          <b className={styles.DetailsCardTitle}>Expenses Allocation</b>
          {this.renderGLGrid()}
        </div>
        {this.renderSpace()}
        <div className={styles.DetailsCard}>
          <b className={styles.DetailsCardTitle}>Approvers</b>
          {(!!this.state.details?.Info.ApprovalOverriddenAt || !!this.state.details?.Info.ApprovalOverriddenBy) &&
              <div
                  style={{color: 'red'}}>{moment(this.state.details?.Info.ApprovalOverriddenAt).format('L LT')} Approval
                  Overridden
                  by {this.state.details?.Info.ApprovalOverriddenBy}</div>
          }
          {this.renderApprovedGrid()}
        </div>
      </div>
    </>
  }

  renderBaseDetailsCell = (title: any, value: any) => {
    return (<div className={styles.BaseDetailsCell}>
      <span className={styles.DescriptionTitle}>{title}:&nbsp;</span>
      <span>{value !== null ? value : ''}</span>
    </div>)
  }

  renderSpace = () => <div className={styles.Space}></div>

  renderBaseDetails = () => {
    let data = this.state.details?.Info;
    if (!data) return;
    let ITJId = this.state.details?.Info.ITJId;
    let ITJ: IComboboxItem | null = ITJId ? {
      Id: ITJId,
      Name: this.state.details?.Info.ITJName || ''
    } : null

    return (<>
      <div className={styles.BaseDetailsBox}>
        <div className={styles.BaseDetailsCol}>
          {this.renderBaseDetailsCell(
            'Vendor',
            <OpenCardLink
              text={data.VendorName || ''}
              refName={'Organizations'}
              dataAttr={this.state.details?.Info.VendorId}
            />
          )}
          {this.renderBaseDetailsCell('Date', data.Date ? moment(data.Date).format('L') : '')}
          {data.Amount !== null && data.Total !== null && data.Amount !== data.Total ?
            this.renderBaseDetailsCell(<span
              className={styles.DetailsAmountWarning}>Amount</span>, `$${data.Amount}${(data.Amount !== data.Total ? ' of $' + data.Total : '')}`) :
            this.renderBaseDetailsCell('Amount', data.Amount !== null ? `$${data.Amount}` : '')
          }
          {this.renderSpace()}
        </div>
        <div className={styles.BaseDetailsCol}>
          {this.renderBaseDetailsCell('Responsible Dept', data.ResponsibleDepartment)}
          {this.renderBaseDetailsCell('Ship To', data.ShipTo)}
          {this.renderBaseDetailsCell('Responsible', data.Responsible)}
          {this.renderSpace()}
        </div>
        <div className={styles.BaseDetailsCol}>
          {this.renderBaseDetailsCell('Number', data.Number)}
          {this.renderBaseDetailsCell(
            'Category',
            data.CategoryName ? <OpenCardLink
              text={data.CategoryName}
              refName={'VIMInvoiceCategories'}
              dataAttr={this.state.details?.Info.CategoryId}
            /> : ''
          )}
          {this.renderBaseDetailsCell('Payment Term', data.PaymentTerm)}
          {this.renderBaseDetailsCell('Due Date', data.DueDate ? moment(data.DueDate).format('L') : '')}
          {this.renderSpace()}
        </div>
        <div className={styles.BaseDetailsCol}>
          {data.ClassName && this.renderBaseDetailsCell(
            'Class',
            data.ClassName
          )}
          {data.CODName && this.renderBaseDetailsCell(
            'COD',
            <OpenCardLink
              text={data.CODName}
              refName={'ExpenseCreditCards'}
              dataAttr={this.state.details?.Info.CODId}
            />
          )
          }
          {ITJ && this.renderBaseDetailsCell(
            'ITJ',
            <OpenCardLink
              text={ITJ.Name}
              dataAttr={ITJ.Id}
              refName={'ICTransactionsJournal'}
            />
          )}
          {data.RecurringBillName && this.renderBaseDetailsCell(
            'Recurring Bill',
            <OpenCardLink
              text={data.RecurringBillName}
              refName={'RecurringBills'}
              dataAttr={this.state.details?.Info.RecurringBillId}
            />
          )}
          {data.Memo && this.renderBaseDetailsCell('Memo', data.Memo)}
          {this.renderSpace()}
        </div>
        <div className={styles.BaseDetailsCol}>
          {data.Description &&
              <>
                {this.renderBaseDetailsCell('Description', data.Description)}
                {this.renderSpace()}
              </>
          }
        </div>
        <div className={styles.BaseDetailsCol}>
          {data.Comments &&
              <>
                {this.renderBaseDetailsCell('Comments', data.Comments)}
                {this.renderSpace()}
              </>
          }
        </div>
      </div>
    </>)
  }

  renderPOGrid = () => {
    if (!this.state.details) return;
    return (
      <Grid
        data={this.state.details.PO}
        filterable={false}
        scrollable="none"
        className={`${styles.FixedGridContainer} ${styles.POGrid}`}
        rowHeight={GridRowHeight}
      >
        <Column
          field="PONumber"
          title="Purchase Order"
          cell={(props) => {
            return (
              <td>
                {props.dataItem.PONumber &&
                    <OpenCardLink
                        text={props.dataItem.PONumber}
                        dataAttr={props.dataItem.POId}
                        refName={'VIMPurchaseOrders'}
                    />
                }
              </td>
            )
          }}
        />
        <Column
          field="POLine"
          title="Line #"
          width="60px"
        />
        <Column
          field="BPName"
          title="Build Plan"
          cell={(props) => {
            return (
              <td>
                {props.dataItem.BPName &&
                    <OpenCardLink
                        text={props.dataItem.BPName}
                        dataAttr={props.dataItem.BPId}
                        refName="FSMBuildPlans"
                    />
                }
              </td>
            )
          }}
        />
        <Column
          field="FAName"
          title="Financial Account"
        />
        <Column
          field="Amount"
          title="Amount"
          format="${0}"
          width="100px"
        />
        <Column
          field="Notes"
          title="Notes"
          width="60px"
          cell={(props) => {
            return (
              <td className={styles.NotesTooltipCell}>
                {props.dataItem.Notes &&
                    <Tooltip anchorElement="target" position="left"
                             style={{maxWidth: '300px'}}>
                        <div className={styles.NotesIconBox}
                             title={props.dataItem.Notes}>
                            <span className="k-icon k-i-file-txt"></span>
                        </div>
                    </Tooltip>}
              </td>
            )
          }}
        />
      </Grid>
    )
  }

  renderGLGrid = () => {
    if (!this.state.details) return;
    return (
      <Grid
        className={`${styles.FixedGridContainer} ${styles.GLGrid}`}
        data={this.state.details.GL}
        filterable={false}
        scrollable="none"
        rowHeight={GridRowHeight}
      >
        <Column
          field="GLCodeName"
          title="GL Code"
        />
        <Column
          field="BPName"
          title="Build Plan"
          cell={(props) => {
            return (
              <td>
                {props.dataItem.BPName &&
                    <OpenCardLink
                        text={props.dataItem.BPName}
                        dataAttr={props.dataItem.BPId}
                        refName="FSMBuildPlans"
                    />}
              </td>
            )
          }}
        />
        <Column
          field="ClassName"
          title="Class"
        />
        <Column
          field="Amount"
          title="Amount"
          format="${0}"
          width="100px"
        />
        <Column
          field="Notes"
          title="Notes"
          width="60px"
          cell={(props) => {
            return (
              <td className={styles.NotesTooltipCell}>
                {props.dataItem.Notes &&
                    <Tooltip anchorElement="target" position="left"
                             style={{maxWidth: '300px'}}>
                        <div className={styles.NotesIconBox}
                             title={props.dataItem.Notes}>
                            <span className="k-icon k-i-file-txt"></span>
                        </div>
                    </Tooltip>}
              </td>
            )
          }}
        />
      </Grid>
    )
  }

  renderApprovedGrid = () => {
    if (!this.state.details) return;
    return (
      <Grid
        className={styles.FixedGridContainer}
        data={this.state.details.Approved}
        filterable={false}
        scrollable="none"
        rowHeight={GridRowHeight}
        rowRender={(row: any, props: any) => {
          return (
            <tr
              className={`${row.props.className} ${props.dataItem.Approved === false ? styles.RedBorderRow : ''}`}
              style={row.props.style}>
              {row.props.children}
            </tr>
          )
        }}
      >
        <Column
          field="Level"
          title="Level"
          width="51px"
        />
        <Column
          field="UserName"
          title="User"
        />
        <Column
          field="CompletedDate"
          title="Date"
          width="110px"
          cell={(props) => {
            return (
              <td>
                {props.dataItem.CompletedDate ? moment(props.dataItem.CompletedDate).format('MM/DD LT') : ''}
              </td>
            )
          }}
        />
        <Column
          field="Approved"
          title="Approved"
          width="80px"
          cell={(props) => {
            return (
              <td
                className={props.dataItem.Approved === false ? styles.CellRed : ''}>
                {(props.dataItem.Approved === true && 'Yes') || (props.dataItem.Approved === false && 'No')}
              </td>
            )
          }}
        />
        <Column
          field="Comments"
          title="Comments"
        />
      </Grid>
    )
  }

  LoadDetails = async () => {
    let invoiceId = this.props.VIMId;
    if (!invoiceId) return;
    try {
      this.setState({loadingInfo: true});
      let result = await this.GetSQLData({
        spName: 'VIM_InvoiceDetails',
        params: {invoiceId}
      });
      if (!result[0] || !result[0].length) {
        this.setState({details: null})
        return;
      }

      let details = {
        Info: result[0][0],
        PO: result[1],
        GL: result[2],
        Approved: result[3]
      }
      this.setState({details});
    } finally {
      this.setState({loadingInfo: false});
    }
  }

  LoadDocuments = async () => {
    let SourceId = this.props.VIMId;
    if (!SourceId) return;
    try {
      this.setState({loadingDocuments: true})
      let {documents} = await LoadDocuments(SourceId);
      let resultDocuments: Array<IDocument> = []
      if (documents.length) {
        for (let i = 0; i < documents.length; i++) {
          let document = documents[i]
          let documentId = document.DocumentId;
          let PreviewUrl = await GetDocumentUrl(documentId, true)
          if ((document.Extension !== 'TXT' && document.Extension !== 'PDF') && !IMAGES_EXT.includes(document.Extension.toLowerCase())) {
            PreviewUrl = 'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(PreviewUrl);
          }
          let DownloadUrl = await GetDocumentUrl(documentId, false)
          let resultDocument: IDocument = {
            ...document,
            DocumentName: document.DocumentName + '.' + document.Extension.toLowerCase(),
            PreviewUrl,
            DownloadUrl,
            Index: i,
            Number: i + 1
          }
          resultDocuments.push(resultDocument)
        }
      }
      this.setState({
        documents: resultDocuments,
        documentIndex: 0,
      })
    } finally {
      this.setState({loadingDocuments: false})
    }
  }

  DownloadDocument = async () => {
    let documents = this.state.documents
    let documentIndex = this.state.documentIndex
    let documentId = documents[documentIndex]?.DocumentId
    if (documentId) {
      let DownloadUrl = await GetDocumentUrl(documentId, false)
      window.location.href = DownloadUrl
    }
  }

  ExternalRefreshDetails = () => {
    this.LoadDetails();
  }

  RefreshDetails = () => {
    this.LoadDetails();
    if (this.props.VIMId) this.props.onRefresh(this.props.VIMId);
  }

  OpenCard = () => {
    let id = this.props.VIMId;
    if (id) OpenRecord('VIMInvoices', id);
  }

  SendForApproval = () => {
    this.setState({loadingInfo: true});
    RunScriptAsync(
      'VIMInvoices_SendForApproval',
      {InvoiceID: this.props.VIMId}
    ).finally(this.RefreshDetails);
  }

  Recalculate = () => {
    RunScriptAsync(
      'VIMInvoices_CalculateApprovers',
      {InvoiceID: this.props.VIMId}
    ).finally(this.RefreshDetails);
  }

  Approve = () => {
    this.setState({loadingInfo: true});
    RunScriptAsync(
      `VIMInvoices_Approve`,
      {InvoiceID: this.props.VIMId}
    ).finally(this.RefreshDetails);
  }

  ForceApprove = () => {
    this.setState({loadingInfo: true});
    RunScriptAsync(
      `VIMInvoices_ForceApprove`,
      {InvoiceID: this.props.VIMId}
    ).finally(this.RefreshDetails);
  }

  Return = () => {
    let inputRef: any;
    ModalRef.showDialog({
      title: 'Comment',
      buttons: [
        {
          text: 'Ok',
          action: () => {
            let Comments = inputRef.element.current.value;
            if (Comments) {
              ModalRef.hideDialog();
              this.setState({loadingInfo: true});
              RunScriptAsync('VIMInvoices_Return', {
                InvoiceID: this.props.VIMId,
                Comments: Comments
              }).finally(this.RefreshDetails);
            }
          }
        },
        {
          text: 'Cancel',
          action: () => {
            ModalRef.hideDialog();
          }
        }
      ],
      children: <TextArea ref={(ref) => {
        inputRef = ref;
      }} rows={5} style={{width: '100%'}}/>
    });
  }

  Validate = (e: any) => {
    let VIMId = this.props.VIMId;
    if (VIMId) {
      this.setState({loadingInfo: true});
      RunScriptAsync(
        `VIMInvoices_Validate`,
        {
          InvoiceID: VIMId,
          Approve: !!e.currentTarget.dataset.approve
        }
      ).finally(this.RefreshDetails);
    }
  }

  LoadEmployees = async () => {
    let result = await this.GetSQLData({spName: 'GetEmployeesForNotification'});
    return result[0]
  }

  Reject = async () => {
    let employees: Array<IComboboxItem> = await this.LoadEmployees()
    let NotificationEmployees: Array<IComboboxItem> = []
    let Comments = ''
    let NotifyVendor = true
    ModalRef.showDialog({
      title: 'Reject Invoice',
      width: 400,
      buttons: [
        {
          text: 'Ok',
          color: 'primary',
          action: () => {
            if (Comments) {
              ModalRef.hideDialog();
              this.setState({loadingInfo: true});
              let NotificationEmployeeIds = NotificationEmployees.map((item) => item.Id).join(',')
              RunScriptAsync('VIMInvoices_Reject', {
                InvoiceID: this.props.VIMId,
                Comments,
                NotifyVendor,
                NotificationEmployeeIds
              }).finally(this.RefreshDetails);
            }
          }
        },
        {
          text: 'Cancel',
          action: () => {
            ModalRef.hideDialog();
          }
        }
      ],
      children: <div>
        <FilterMultiSelect
          placeholder="Employees"
          data={employees}
          className={styles.RejectMultiselect}
          onChange={(value: Array<IComboboxItem> | undefined) => {
            NotificationEmployees = value || []
          }}
        />
        <TextArea
          onChange={(event: TextAreaChangeEvent) => {
            Comments = event.value;
          }}
          placeholder="Comment"
          rows={5}
          style={{width: '100%', marginBottom: '10px'}}
          required={true}
        />
        <Checkbox
          onChange={(event: CheckboxChangeEvent) => {
            NotifyVendor = event.value
          }}
          defaultChecked={NotifyVendor}
          label={'Notify Vendor'}
          id="notifyVendor"
        />
      </div>
    });
  }

  AddDocument = () => {
    let sourceId = this.props.VIMId
    if (sourceId) CardManagement.UploadDocumentsCard(false, sourceId, this.LoadDocuments)
  }

  DeleteInvoice = async () => {
    let deleteId = this.props.VIMId;
    await RunScriptLocalAsync('VIMInvoices_Delete', {InvoiceID: deleteId})
    this.props.onDelete(deleteId!)
  }

  OnPanesChange = (event: any) => {
    this.setState({panes: event.newState, changingPanes: !event.isLast});
  }

  SelectDocument = (e: any) => {
    let index = +e.value.Index
    let documentIndex = this.state.documentIndex
    if (documentIndex !== index) {
      this.setState({documentIndex: index})
    }
  }

  ChangeDocument = (e: any) => {
    let documentIndex = this.state.documentIndex
    let action = e.currentTarget.getAttribute('data-action')
    if (action === 'prev') {
      documentIndex = documentIndex - 1
    } else if (action === 'next') {
      documentIndex = documentIndex + 1
    }
    this.setState({documentIndex})
  }

  OnDropdownOpen = () => {
    if (IS_IE) {
      setTimeout(this.ChangeFisIframeHeight, 100)
    }
  }

  ChangeFisIframeHeight = () => {
    let iframe = window.document.querySelector('#vim-fix-iframe')
    let popup = window.document.querySelector('.vim-popup')
    if (popup && iframe) {
      // @ts-ignore
      let popupHeight = popup.offsetHeight
      // @ts-ignore
      let popupWidth = popup.offsetWidth
      // @ts-ignore
      iframe.style.height = popupHeight + 40 + 'px'
      // @ts-ignore
      iframe.style.width = popupWidth + 10 + 'px'
    }
  }

  OnDropdownClose = () => {
    if (IS_IE) {
      let iframe = window.document.querySelector('#vim-fix-iframe')
      // @ts-ignore
      iframe.style.height = '0px'
    }
  }
}

export default VIMDetails;
