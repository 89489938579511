import {useRef, useState} from 'react'
import {Grid, GridCellProps, GridColumn as Column, GridHeaderCellProps} from '@progress/kendo-react-grid';
import {ITCHistoryItem} from '../interfaces';
import styles from './timecard.module.scss';
import moment from "moment";

interface IProps {
    history: ITCHistoryItem[]
}

interface IColumn {
    field: keyof ITCHistoryItem
    title: string
    width?: string
}

const TCHistory = ({history}: IProps) => {
    const [isHidden, setIsHidden] = useState(false)
    const columnsRef = useRef<IColumn[]>([
        {
            field: 'Date',
            title: 'Date',
            width: '130px',
        },
        {
            field: 'Comment',
            title: 'Comment',
        }
    ])

    const toggleGrid = () => {
        setIsHidden(!isHidden)
    }

    const renderCell = (column: IColumn, props: React.PropsWithChildren<GridCellProps>) => {
        const {field} = column
        const value = props.dataItem[field]
        if (field === 'Date') return <td>{moment(value).format('L LT')}</td>
        return <td style={{color: 'red'}}>{value}</td>
    }

    const rendreHeaderToggleCell = (props: React.PropsWithChildren<GridHeaderCellProps>) => {
        return <div
            className={styles.TCGridToggleCell}
            onClick={toggleGrid}
        >
            <span className={`k-icon ${isHidden ? 'k-i-arrow-chevron-right' : 'k-i-arrow-chevron-down'}`}></span>
            {isHidden ? 'COMMENTS' : 'Date'}
        </div>
    }

    if (!history.length) return null
    return <Grid
        data={history}
        filterable={false}
        scrollable="none"
        className={`${styles.TCGrid} ${styles.TCGridWithoutTotalRow} ${isHidden ? styles.TCGridHidden : ''}`}
    >
        {columnsRef.current.map((column) => {
            const {field, title, width} = column
            return <Column
                key={field}
                field={field}
                title={title}
                width={width}
                cell={(props) => renderCell(column, props)}
                headerCell={field === 'Date' ? rendreHeaderToggleCell : undefined}
            />
        })}
    </Grid>
}

export default TCHistory;
