import {Component} from 'react'
import {Grid, GridCellProps, GridColumn as Column} from '@progress/kendo-react-grid';
import {IPayroll} from '../interfaces';
import OpenCardLink from '../../../Components/Common/Buttons/OpenCardLink';
import {formatHoursDuration} from '../../../helpers/helpers';
import styles from './timecard.module.scss';

interface props {
    payrolls: Array<IPayroll> | null
    locked: boolean
    lunch: number | null
}

class TCPayrolls extends Component<props, {}> {

    render() {
        let payrolls = this.props.payrolls
        if (!payrolls) return null

        return <Grid
            data={payrolls}
            filterable={false}
            scrollable="none"
            className={styles.TCGrid}
        >
            <Column
                field="Total"
                headerCell={this.rendreHeaderTotalCell}
                cell={this.renderTotalCell}
            />
            <Column
                field="CostTypeName"
                title="Cost Type"
            />
            <Column
                field="State"
                title="State"
                cell={this.renderStateCell}
            />
            <Column
                field="RegularHours"
                title="Regular"
                width="65px"
            />
            <Column
                field="OvertimeHours"
                title="Overtime"
                width="65px"
            />
            <Column
                field="DoubleHours"
                title="Double"
                width="65px"
            />
            <Column
                field="PWStr"
                title="PW"
                width="40px"
            />
            <Column
                field="Overnight"
                title="Overnight"
                width="70px"
            />
        </Grid>
    }

    rendreHeaderTotalCell = () => {
        const locked = this.props.locked &&
            <span className={styles.TCLockedWageSummury}>&nbsp;Locked</span>

        return <span>WAGE SUMMARY{locked}</span>
    }

    renderTotalCell = (props: GridCellProps) => {
        const {isTotal, Total, WOName, WOId} = props.dataItem
        if (isTotal) {
            return <td>
                    <span style={{display: 'flex'}}>
					<span>Total: {Total}</span>
                        {this.props.lunch !== null &&
                            <span style={{marginLeft: 24}}>
                                Applied Lunch: {formatHoursDuration(this.props.lunch)}
                            </span>
                        }
                </span>
            </td>
        }

        return <td>
            <OpenCardLink
                text={WOName}
                dataAttr={WOId}
                refName="FSMWorkOrders"
            />
        </td>
    }

    renderStateCell = (props: GridCellProps) => {
        const {StateName, StateId, isTotal} = props.dataItem
        return <td>
            {isTotal ? null :
                <OpenCardLink
                    text={StateName}
                    dataAttr={StateId}
                    refName="States"
                />}
        </td>
    }
}

export default TCPayrolls;
