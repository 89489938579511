import React, {useState} from 'react';
import debounce from "lodash.debounce";
import styles from "./livemap.module.scss";

interface IProps {
    children: any
}

const WOsListContainer = (props: IProps) => {
    const [isOpened, setIsOpened] = useState<boolean>(true)

    const onToggle = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        toggle()
    }
    const toggle = debounce(() => {
        setIsOpened(!isOpened)
    }, 100)

    return <div className={styles.DeviceDispatches} style={isOpened ? undefined : {height: 'auto'}}>
        <div
            onClick={onToggle}
            style={{
                cursor: 'pointer',
                display: "flex",
                justifyContent: 'space-between',
                background: '#f8f8f8',
                lineHeight: '14px',
                padding: 4,
                gap: 5,
                fontSize: 12
            }}
        >
            <span>{isOpened ? 'Hide' : 'Show'} Work Orders</span>
            <span className={isOpened ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'}></span>
        </div>
        {isOpened && <div style={{
            padding: 4,
            overflow: 'auto'
        }}>{props.children}</div>}
    </div>

}

export default WOsListContainer