import {Component} from 'react';
import OpenCardLink from '../../Components/Common/Buttons/OpenCardLink';
import DashboardGrid from '../../Components/Dashboard/Grid';
import {
  IColumnSetting,
  IFilterSetting
} from '../../Components/Dashboard/interfaces';
import {simpleObject} from '../../helpers/interfaces';
import {Tooltip} from '@progress/kendo-react-tooltip';
import styles from './externalLinks.module.scss';
import {Button} from '@progress/kendo-react-buttons';
import {ModalRef} from '../../Components/Common/Modal/Modal';
import {RunScriptAsync} from '../../helpers/runscripts';
import UserInfo from '../../stores/User'

const filters: Array<IFilterSetting> = []

interface state {
  initedSettings: boolean
}

class ExternalLinks extends Component<{ isActive: boolean }, state> {
  isAdmin: boolean | undefined

  COLUMNS: Array<IColumnSetting> = [
    {
      field: 'SysCode',
      title: 'System Code',
      type: 'string',
      filterable: false,
      format: {
        type: 'string',
        fieldId: 'SysCode',
      },
      aggregate: 'count'
    },
    {
      field: 'ExtId',
      title: 'External Id',
      type: 'string',
    },
    {
      field: 'ExtRefCode',
      title: 'External Type',
      type: 'string',
      filterable: false,
      format: {
        type: 'string',
        fieldId: 'ExtRefCode'
      }
    },
    {
      field: 'ConterraId',
      title: 'Conterra Id',
      type: 'string',
    },
    {
      field: 'ConterraName',
      title: 'Conterra Name',
      type: 'string',
      format: {
        type: 'string',
        customFormat: true,
        getCustomFormat: (dataItem: simpleObject) => {
          return <OpenCardLink
            text={dataItem.ConterraName}
            refName={dataItem.ConterraRefCode}
            dataAttr={dataItem.ConterraId}
          />
        }
      }
    },
    {
      field: 'ConterraRefName',
      title: 'Conterra Type',
      type: 'string',
      filterable: false,
      format: {
        type: 'string',
        fieldId: 'ConterraRefName',
      }
    },
    {
      field: 'Warnings',
      title: 'Warnings',
      type: 'string',
      gridWidth: 80,
      format: {
        type: 'string',
        customFormat: true,
        getCustomFormat: (dataItem: simpleObject) => {
          return <div className={styles.WarningsCell}>
            {dataItem.Warnings &&
                <Tooltip anchorElement="target" position="left"
                         style={{maxWidth: '300px'}}>
                    <div className={styles.NotesIconBox}
                         title={dataItem.Warnings}>
                        <span className="k-icon k-i-file-txt"
                              style={{color: 'red'}}></span>
                    </div>
                </Tooltip>}
          </div>
        }
      }
    },
    {
      field: 'FirstCreated',
      title: 'First Created',
      type: 'date',
      gridWidth: 120,
      format: {
        type: 'datetime'
      }
    }
  ]

  constructor(props: any) {
    super(props)
    this.state = {
      initedSettings: false
    }
  }

  InitSettings = async () => {
    let userInfo = await UserInfo.getInfo()
    if (userInfo?.IsAdmin) {
      this.COLUMNS.push({
        field: 'FirstXRecID',
        title: ' ',
        type: 'number',
        filterable: false,
        columnMenu: false,
        gridWidth: 50,
        format: {
          type: 'number',
          customFormat: true,
          getCustomFormat: (dataItem) => {
            return <div style={{pointerEvents: 'all', textAlign: 'center'}}>
              <Button
                icon="trash"
                fillMode="flat"
                data-id={dataItem.FirstXRecID}
                onClick={this.ConfirmDelete}
              ></Button>
            </div>
          }
        }
      })
    }
    this.setState({initedSettings: true})
  }

  componentDidMount() {
    this.InitSettings()
  }

  render() {
    if (!this.state.initedSettings) return null

    return (<DashboardGrid
      isActive={this.props.isActive}
      isParentPage={true}
      columnsSettings={this.COLUMNS}
      filters={filters}
      getSqlParams={{spName: 'DB_ExternalLinks'}}
      excelFileName="ExternalLinks.xlsx"
      columnMenu={{
        filters: true,
        columns: true
      }}
      reorderable={true}
      initRefresh={this.InitRefresh}
      getRowKey={(dataItem: simpleObject) => dataItem.ConterraId}
    >
    </DashboardGrid>);
  }

  Refresh = () => {
    this.GridRefresh()
  }

  GridRefresh = () => {
  }

  InitRefresh = (refresh: any) => {
    this.GridRefresh = refresh
  }

  ConfirmDelete = (e: any) => {
    let xrecId = e.currentTarget.dataset.id
    ModalRef.showDialog({
      title: 'Confirmation',
      text: 'Do you want to delete this record?',
      buttons: [
        {
          text: 'Cancel',
          action: () => {
            ModalRef.hideDialog();
          }
        },
        {
          text: 'Ok',
          color: "primary",
          action: () => {
            ModalRef.hideDialog();
            this.DeleteRecord(+xrecId)
          }
        }
      ],
    });
  }

  DeleteRecord = async (XRecID: number) => {
    await RunScriptAsync('ADM_DeleteISOLRecord', {XRecID})
    this.Refresh()
  }
}

export default ExternalLinks;
