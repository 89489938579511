import {IDocumentItem} from './interfaces';
import BaseComponent from '../../../Components/BaseComponent';
import {mobileActionIcons} from './helpers';
import popupStyles
  from '../../../Components/Common/MobilePopup/index.module.scss';
import {Button} from '@progress/kendo-react-buttons';
import MobilePopup from '../../../Components/Common/MobilePopup/Index';
import {IComboboxItem} from '../../../helpers/interfaces';
import {
  mobilePopupBtnOuterHeight
} from '../../../Components/Common/MobilePopup/helpers';

interface props {
  settings: {
    dataItem: IDocumentItem;
    fromPreview?: boolean | undefined;
    fileId?: number | undefined;
  }
  actions: Array<IComboboxItem>

  onSelectAction(action: string, dataItem: IDocumentItem): void

  onHide(): void
}

interface state {
}

class MobileActionsPopup extends BaseComponent<props, state> {
  render() {
    let actions = this.props.actions
    return <MobilePopup
      onHide={this.props.onHide}
      height={mobilePopupBtnOuterHeight * actions.length + 32 - 4}  // 4 ? , 32?
      width={320}
    >
      {actions.map((item) => {
        let id = item.Id + ''
        // @ts-ignore
        if (!mobileActionIcons[id]) console.log(id, mobileActionIcons)
        return <Button
          key={id}
          id={id}
          className={popupStyles.MobilePopupBtn}
          size={'large'}
          // @ts-ignore
          iconClass={mobileActionIcons[id] || ''}
          onClick={this.DoAction}
        >{item.Name}</Button>
      })}
    </MobilePopup>
  }

  DoAction = (e: any) => {
    let action = e.currentTarget.id
    let {dataItem} = this.props.settings
    this.props.onSelectAction(action, dataItem)
  }
}

export default MobileActionsPopup;
