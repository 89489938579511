import React, {Component} from 'react';
import {simpleObject} from '../../helpers/interfaces';
import {ReferenceRecordsDataSource} from '../../helpers/queries';
import DashboardGrid from '../Dashboard/Grid';
import {IColumnSetting, IFilterSetting} from '../Dashboard/interfaces';
import {formatDateColumn} from './helpers';
import CardManagement from "../Cards/CardManagement";

interface props {
    tcId?: number
    isActive: boolean
    switcher?: any
    onChangeGridData?: (data: Array<simpleObject>) => void
}

interface state {
}

class TCAttestations extends Component<props, state> {
    gridRef: any = React.createRef()
    tcId = this.props.tcId
    isPage: boolean // or tab
    COLUMNS: Array<IColumnSetting>
    FILTERS: Array<IFilterSetting> = []

    constructor(props: props) {
        super(props)

        this.state = {}

        this.isPage = !!this.props.switcher

        if (this.isPage) {
            this.COLUMNS = [
                {
                    field: 'RowNum',
                    title: '#',
                    type: 'string',
                    gridWidth: 40,
                    filterable: false,
                    columnMenu: false,
                    aggregate: 'count'
                },
                {
                    field: 'Date',
                    title: 'Date',
                    type: 'date',
                    format: {
                        type: 'date',
                        customFormat: true,
                        getCustomFormat: formatDateColumn
                    }
                },
                {
                    field: 'EmployeeName',
                    title: 'Employee',
                    type: 'string',
                    gridWidth: 200,
                    format: {
                        type: 'link',
                        fieldId: 'EmployeeId',
                        refName: 'Employees'
                    }
                },
                {
                    field: 'PeriodName',
                    title: 'Payroll Period',
                    type: 'string',
                    gridWidth: 200,
                    format: {
                        type: 'string',
                        fieldId: 'PeriodId',
                    }
                },
                {
                    field: 'Shift',
                    title: '# Shift',
                    type: 'number',
                    format: {
                        type: 'integer',
                    }
                },
                {
                    field: 'ClockIn',
                    title: 'Clock In',
                    type: 'date',
                    gridWidth: 130,
                    format: {
                        type: 'datetime',
                    }
                },
                {
                    field: 'ClockOut',
                    title: 'Clock Out',
                    type: 'date',
                    gridWidth: 130,
                    format: {
                        type: 'datetime',
                    }
                },
                {
                    field: 'ClockedHours',
                    title: 'Clocked Hours',
                    type: 'number',
                    gridWidth: 90,
                    aggregate: 'sum',
                    format: {
                        type: 'number'
                    }
                },
                {
                    field: '',
                    title: 'Lunch Hours',
                    type: 'parent',
                    columns: [
                        {
                            field: 'AuditLunchHours',
                            title: 'Audit',
                            type: 'number',
                            gridWidth: 90,
                            format: {
                                type: 'number'
                            }
                        },
                        {
                            field: 'ManualLunchHours',
                            title: 'Manual',
                            type: 'number',
                            gridWidth: 90,
                            format: {
                                type: 'number'
                            }
                        },
                    ]
                },
                {
                    title: 'Attestations Detail',
                    type: 'parent',
                    columns: [
                        {
                            field: 'Type',
                            title: 'Type',
                            type: 'string',
                            gridWidth: 70,
                            format: {
                                type: 'string',
                                fieldId: 'Type'
                            }
                        },
                        {
                            field: 'Title',
                            title: 'Title',
                            type: 'string',
                            gridWidth: 200
                        },
                        {
                            field: 'Text',
                            title: 'Text',
                            type: 'string',
                            gridWidth: 70,
                            format: {
                                type: 'icon',
                                iconClass: 'k-icon k-i-file-txt'
                            }
                        },
                        {
                            field: 'ConfirmedText',
                            title: 'Confirmed',
                            type: 'string',
                            gridWidth: 110,
                            format: {
                                type: 'string',
                                fieldId: 'Confirmed',
                            },
                        },
                        {
                            field: 'Comment',
                            title: 'Comment',
                            type: 'string',
                            minWidth: 180
                        },
                    ]
                },
            ];

            this.FILTERS = [
                {
                    id: 'filterPeriod',
                    type: 'dateRange',
                    serverFilter: {
                        param: '',
                    },
                    dateRangeSettings: {
                        dateFromParam: 'dateFrom',
                        dateToParam: 'dateTo',
                        defaultPeriod: 'notApplied',
                        allowEmptyValues: true
                    },
                    localStoragePrefix: 'TKDashboard',
                    toolbarRow: 1
                },
                {
                    id: 'filterPP',
                    placeholder: 'Filter by Payroll Period',
                    type: 'combobox',
                    serverFilter: {
                        param: 'periodId',
                        getData: async () => {
                            let result = await ReferenceRecordsDataSource('TKPayrollPeriods', {activeOnly: false})
                            return result[0]
                        }
                    },
                    localStoragePrefix: 'TKDashboard',
                    width: 298,
                    toolbarRow: 2
                },
            ]
        } else {
            this.COLUMNS = [
                {
                    field: 'RowNum',
                    title: '#',
                    type: 'string',
                    aggregate: 'count',
                    gridWidth: 40,
                    filterable: false,
                    columnMenu: false
                },
                {
                    field: 'Title',
                    title: 'Attestation',
                    type: 'string',
                    format: {
                        type: 'string',
                        fieldId: 'AttestationId' // todo
                    }
                },
                {
                    field: 'ConfirmedText',
                    title: 'Confirmed',
                    gridWidth: 110,
                    type: 'string',
                    format: {
                        type: 'string',
                        fieldId: 'Confirmed',
                    }
                },
                {
                    field: 'Text',
                    title: 'Text',
                    gridWidth: 70,
                    type: 'string',
                    format: {
                        type: 'icon',
                        iconClass: 'k-icon k-i-file-txt'
                    }
                },
                {
                    field: 'Comment',
                    title: 'Comment',
                    type: 'string'
                },
            ]
        }
    }

    render() {
        if (this.props.isActive && this.tcId !== this.props.tcId) {
            this.tcId = this.props.tcId
        }

        if (!this.isPage && !this.props.tcId || !this.props.isActive && this.tcId !== this.props.tcId) return null
        return <DashboardGrid
            isActive={this.props.isActive}
            ref={this.gridRef}
            isParentPage={true}
            key={this.props.tcId}
            columnsSettings={this.COLUMNS}
            getSqlParams={{spName: 'DB_Attestations', params: {tcId: this.tcId}}}
            getRowKey={this.GetRowKey}
            saveSettings={{prefix: 'attestationsDash', saveFilters: !!this.isPage}}
            initRefresh={this.InitRefresh}
            excelFileName="Attestations.xlsx"
            columnMenu={{
                filters: true,
                columns: false
            }}
            prepareData={(result) => {
                let data = result[0]
                data.forEach((item: simpleObject) => {
                    item.ConfirmedText = item.Confirmed ? 'Yes' : 'No'
                })
                return data
            }}
            filters={this.FILTERS}
            toolbar={{
                switcher: this.props.switcher,
                hide: !this.isPage
            }}
            defaultColumnValuesFilter={this.isPage ? [
                {
                    field: 'Confirmed',
                    values: [false]
                }
            ] : undefined}
            selectSettings={{
                pageId: 'TKDashboard',
                onSelectRow: this.OpenTCCard
            }}
            onChangeGridData={this.props.onChangeGridData}
        />
    }

    Refresh = () => {
    }

    InitRefresh = (refresh: any) => {
        this.Refresh = refresh
    }

    GetRowKey = (dataItem: simpleObject) => dataItem.RowNum

    OpenTCCard = (rowData: simpleObject | null) => {
        if (rowData) {
            let tcId = rowData.TCId
            CardManagement.OpenTCCard(tcId)
        }
    }
}

export default TCAttestations;
