import React, {Component} from 'react'
import BOMDashboard from "./BOMDashboard";
import BOMDetails from "./BOMDetails";
import Switch from "../Switch/Switch";
import {tabId} from "../Tabs/interfaces";
import {simpleObject} from "../../helpers/interfaces";
import {pageId} from "../../Pages/Home/interfaces";
import {gridChangeDataAction} from "../Dashboard/interfaces";

interface IProps {
    buildPlanId?: number
    doNotSaveFilters?: boolean
    isActive: boolean
    pageId: pageId

    switchTab(tabId: tabId): void

    onChangeGridData?(data: Array<simpleObject>, action?: gridChangeDataAction): void
}

interface state {
    isSummaryMode: boolean
}

const hiddenStyles: React.CSSProperties = {
    position: 'absolute',
    zIndex: -1,
    visibility: 'hidden',
    top: -9999,
    left: -9999,
}

class BOMBPTab extends Component<IProps, state> {
    bpId = this.props.buildPlanId
    initedDetails: boolean = false
    detailsRef: any = React.createRef()
    summaryRef: any = React.createRef()
    isSummaryMode: boolean = true

    constructor(props: IProps) {
        super(props)
        this.state = {
            isSummaryMode: true,
        }
    }

    render() {
        if (this.props.isActive && this.bpId !== this.props.buildPlanId) {
            this.bpId = this.props.buildPlanId
        }

        if (!this.props.buildPlanId || !this.props.isActive && this.bpId !== this.props.buildPlanId) return null

        const {isSummaryMode} = this.state
        return <>
            <div style={{height: '100%', ...(!isSummaryMode ? hiddenStyles : {})}}>
                <BOMDashboard
                    ref={this.summaryRef}
                    {...this.props}
                    switchTab={(tabId: tabId) => null}
                    isActive={this.props.isActive && isSummaryMode}
                    switcher={this.renderSwitchMode()}
                />
            </div>
            {(this.initedDetails || !isSummaryMode) &&
                <div style={{height: '100%', ...(isSummaryMode ? hiddenStyles : {})}}>
                    <BOMDetails
                        ref={this.detailsRef}
                        isActive={this.props.isActive && !isSummaryMode}
                        parentId={this.props.pageId}
                        rowData={{BPID: this.bpId}}
                        props={{
                            switcher: this.renderSwitchMode()
                        }}
                        onChangeGridData={this.props.onChangeGridData}
                    />
                </div>}
        </>
    }

    renderSwitchMode = () => {
        return <Switch
            checked={this.state.isSummaryMode}
            id={'bomTabMode' + this.props.pageId}
            onChange={this.OnChangeMode}
            label={`Switch to ${this.state.isSummaryMode ? 'Details' : 'Summary'}`}
        />
    }

    OnChangeMode = (value: boolean) => {
        if (!value && !this.initedDetails) this.initedDetails = true
        this.isSummaryMode = value
        this.setState({isSummaryMode: value})
        if (this.props.onChangeGridData) this.props.onChangeGridData([], 'forceRestoreSettings')
    }

    ExportToExcel = () => { // external
        if (this.isSummaryMode) {
            this.summaryRef.current.gridRef.current.ExportToExcel()
        } else {
            this.detailsRef.current.gridRef.current.ExportToExcel()
        }
    }

    SetDefaultSettings = () => {
        if (this.isSummaryMode) {
            this.summaryRef.current.gridRef.current.SetDefaultSettings()
        } else {
            this.detailsRef.current.gridRef.current.SetDefaultSettings()
        }
    }

    IsFiltersInDefault = () => {
        if (this.isSummaryMode) {
            return this.summaryRef?.current?.gridRef?.current?.IsFiltersInDefault()
        } else {
            return this.detailsRef?.current?.gridRef?.current?.IsFiltersInDefault()
        }
    }

    Refresh = () => { // external
        if (this.isSummaryMode) {
            this.summaryRef.current.Refresh()
        } else {
            this.detailsRef.current.Refresh()
        }
    }
}

export default BOMBPTab
