import React from 'react';
import {RadialGauge} from '@progress/kendo-react-gauges';
import {IBPInfo} from './interfaces';

interface props {
  data: IBPInfo
}

interface state {
}

class BPHoursGauge extends React.Component<props, state> {
  render() {
    const bpInfo = this.props.data;

    let maxHours = Math.max(bpInfo.BudgetHours, bpInfo.ActualHours) * 1.1;
    maxHours = maxHours > 0 ? maxHours : 100;
    maxHours = Math.round(maxHours * 100) / 100;
    let needleColor = bpInfo.ActualHours > bpInfo.estimateHours ? '#ac3e31' : '#6ab187';
    let highlightToEstimate = bpInfo.estimateHours > maxHours ? maxHours : bpInfo.estimateHours;

    const radialOptions = {
      pointer: {
        value: bpInfo.ActualHours,
        color: needleColor,
        length: 1.2
      },
      scale: {
        min: 0,
        max: maxHours,
        startAngle: -30,
        minorUnit: 5,
        ranges: [
          {
            from: 0,
            to: bpInfo.BudgetHours,
            color: '#006494'
          },
          {
            from: 0,
            to: highlightToEstimate,
            color: '#01baef'
          }
        ]
      }
    };
    return <RadialGauge
      style={{
        display: 'inline-block',
        minWidth: '280px',
        verticalAlign: 'middle'
      }}
      {...radialOptions}
    />
  }
}

export default BPHoursGauge;
