import React, {Component} from 'react';
import DashboardGrid from '../../Components/Dashboard/Grid';
import {IColumnSetting} from '../../Components/Dashboard/interfaces';
import {simpleObject} from '../../helpers/interfaces';

const COLUMNS: Array<IColumnSetting> = [
    {
        field: 'Date',
        title: 'Date',
        type: 'date',
        gridWidth: 120,
        format: {
            type: 'datetime',
        },
    },
    {
        field: 'Employee',
        title: 'User Name',
        type: 'string',
        gridWidth: 150,
        filterable: false,
        format: {
            fieldId: 'EmployeeId',
            type: 'link',
            refName: 'Employees'
        },
    },
    {
        field: 'Event',
        title: 'Event',
        type: 'string',
        gridWidth: 80,
        format: {
            type: 'string',
            fieldId: 'Event'
        }
    },
    {
        field: 'Comment',
        title: 'Comment',
        columnMenu: false,
        type: 'string',
        format: {
            type: 'string',
            customFormat: true,
            getCustomFormat: (dataItem) => {
                return <div style={{whiteSpace: 'pre-line'}}>{dataItem.Comment}</div>
            }
        }
    },
    {
        field: 'Source',
        title: 'Source',
        type: 'string',
        gridWidth: 170,
        columnMenu: false,
    }
];

interface props {
    tcId: number
    isActive?: boolean

    onChangeGridData?(data: Array<simpleObject>): void
}

interface state {
    title: string
}

class TCHistory extends Component<props, state> {
    gridRef: any = React.createRef()
    tcId = this.props.tcId

    constructor(props: props) {
        super(props)
        this.state = {
            title: ''
        }
    }

    render() {
        if (!this.props.tcId) return null
        if (this.props.isActive && this.tcId !== this.props.tcId) {
            this.tcId = this.props.tcId
        }
        if (!this.props.tcId || !this.props.isActive && this.tcId !== this.props.tcId) return null
        return <DashboardGrid
            key={this.tcId}
            ref={this.gridRef}
            isActive={this.props.isActive === undefined ? true : this.props.isActive}
            columnsSettings={COLUMNS}
            getRowKey={(rowData: simpleObject) => rowData.RowNum}
            getSqlParams={{
                spName: 'TK_GetTimeCardHistory', params: {
                    TCId: this.props.tcId
                }
            }}
            toolbar={{
                hide: true
            }}
            initRefresh={this.InitRefresh}
            columnMenu={{
                filters: true,
                columns: false
            }}
            onChangeGridData={this.props.onChangeGridData}
        />
    }

    Refresh = () => {
    }

    InitRefresh = (refresh: any) => {
        this.Refresh = refresh
    }
}

export default TCHistory;
