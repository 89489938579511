import React, {Component} from 'react';
import DashboardGrid from '../Dashboard/Grid'
import {IColumnSetting, IFilterSetting} from '../Dashboard/interfaces'
import {ReferenceRecordsDataSource} from '../../helpers/queries'
import {Tooltip} from '@progress/kendo-react-tooltip'
import {simpleObject} from '../../helpers/interfaces'
import styles from './materials.module.scss';
import CardManagement from '../Cards/CardManagement';
import ButtonLink from '../Common/Buttons/ButtonLink';
import TransactionJournal from './TransactionJournal';
import {tabId} from '../Tabs/interfaces'

interface state {
    materialId: number | null
}

interface props {
    isActive: boolean

    switchTab(tabId: tabId): void

    onChangeGridData?(data: Array<simpleObject>): void
}

const FILTERS: Array<IFilterSetting> = [
    {
        id: 'filterCategory',
        placeholder: 'Filter by Category',
        type: 'combobox',
        width: 200,
        serverFilter: {
            param: 'categoryId',
            getData: async () => {
                let result = await ReferenceRecordsDataSource('MaterialCategories')
                return result[0]
            }
        },
        toolbarRow: 1
    }
];

const COLUMNS: Array<IColumnSetting> = [
    {
        field: 'RowNum',
        title: '#',
        type: 'number',
        gridWidth: 40,
        filterable: false,
        aggregate: 'count',
        columnMenu: false
    },
    {
        field: 'RootCategoryName',
        title: 'Root Category',
        type: 'string',
        gridWidth: 150,
        format: {
            type: 'string',
            fieldId: 'RootCategoryId'
        }
    },
    {
        field: 'CategoryName',
        title: 'Category',
        type: 'string',
        gridWidth: 150,
        format: {
            type: 'string',
            fieldId: 'CategoryId'
        }
    },
    {
        field: 'MAName',
        title: 'Material',
        type: 'string',
        minWidth: 300,
        format: {
            type: 'link',
            fieldId: 'MAId',
            refName: 'FSMMaterials'
        }
    },
    {
        field: 'CurrentBalance',
        title: 'QTY',
        type: 'number',
        gridWidth: 100,
        format: {
            type: 'number',
            customFormat: true,
            getCustomFormat: (dataItem: simpleObject) => {
                if (dataItem.CurrentBalance === undefined || dataItem.CurrentBalance === null) {
                    return <div className={styles.BalanceCell}>0</div>;
                }
                return <div className={styles.BalanceCell}>
                    <ButtonLink
                        text={dataItem.CurrentBalance.toFixed(2)}
                        onClick={() => CardManagement.ShowMaterialBalance(dataItem.MAId, dataItem.MAName)}/>
                </div>
            }
        }
    },
    {
        field: 'UOMName',
        title: 'UOM',
        type: 'string',
        gridWidth: 100,
        format: {
            type: 'string',
            fieldId: 'UOMName'
        }
    },
    {
        field: 'COGS',
        title: 'COGS $',
        type: 'number',
        gridWidth: 100,
        format: {
            type: 'currency'
        }
    },
    {
        field: 'InternalPartNumber',
        title: 'Internal Part #',
        type: 'string',
        gridWidth: 120,
        format: {
            type: 'string',
            fieldId: 'InternalPartNumber'
        }
    },
    {
        field: 'VendorName',
        title: 'Vendor',
        type: 'string',
        gridWidth: 180,
        format: {
            type: 'string',
            fieldId: 'VendorName'
        }
    },
    {
        field: 'VendorPartNumbers',
        title: 'Vendor Part #',
        type: 'string',
        gridWidth: 180,
        format: {
            type: 'string',
            fieldId: 'VendorPartNumbers'
        }
    },
    {
        field: 'Description',
        title: 'Descr.',
        type: 'string',
        gridWidth: 70,
        format: {
            type: 'string',
            customFormat: true,
            getCustomFormat: (dataItem: simpleObject) => {
                return <div className={styles.DescriptionCell}>
                    {dataItem.Description &&
                        <Tooltip anchorElement="target" position="left"
                                 style={{maxWidth: '300px'}}>
                            <div className={styles.NotesIconBox}
                                 title={dataItem.Description}>
                                <span className="k-icon k-i-file-txt"></span>
                            </div>
                        </Tooltip>}
                </div>
            }
        }
    }
];

class Materials extends Component<props, state> {
    gridRef: any = React.createRef()

    constructor(props: any) {
        super(props)
        this.state = {
            materialId: null,
        }
    }

    render() {
        return <DashboardGrid
            isActive={this.props.isActive}
            ref={this.gridRef}
            getSqlParams={{spName: 'DB_MaterialList'}}
            saveSettings={{prefix: 'Materials', saveFilters: true}}
            excelFileName="Materials.xlsx"
            initRefresh={this.InitRefresh}
            columnsSettings={COLUMNS}
            filters={FILTERS}
            columnMenu={{
                filters: true,
                columns: false
            }}
            getRowKey={(dataItem: simpleObject) => dataItem.MAId}
            selectSettings={{
                Component: TransactionJournal,
                pageId: 'InventoryDashboard'
            }}
            onChangeGridData={this.props.onChangeGridData}
        />
    }

    Refresh = () => {
    }

    InitRefresh = (refresh: any) => {
        this.Refresh = refresh
    }
}

export default Materials;
