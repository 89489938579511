import {Tooltip} from "@progress/kendo-react-tooltip";
import React, {Component} from "react";
import OpenCardLink from "../../../../Components/Common/Buttons/OpenCardLink";
import {GetTKSelfieUrl} from "../../../../helpers/helpers";
import {simpleObject} from "../../../../helpers/interfaces";
import {OpenRecord} from "../../../../helpers/runscripts";
import {costTypeCode, IOriginalTimeLineItem} from "../../interfaces";
import styles from "./daylog.module.scss";

interface props {
    data: IOriginalTimeLineItem;
    rowsLength?: number;
    IsHideUnscheduledVisitAlert: boolean

    openContextMenu(left: number, top: number, teId: number): void;
}

interface state {
    tooltipOpen: boolean;
    selfieUrl: string;
    isVisiblePhoto: boolean;
}

class TimeEntry extends Component<props, state> {
    selfieRef: any = React.createRef();
    tooltipRef: any = React.createRef();
    timerHide: any;

    constructor(props: props) {
        super(props);
        this.state = {
            tooltipOpen: false,
            selfieUrl: "",
            isVisiblePhoto: false,
        };
    }

    componentWillUnmount() {
        if (this.timerHide) clearTimeout(this.timerHide);
    }

    componentDidMount() {
        this.GetSelfieUrl();
    }

    render() {
        let data = this.props.data;
        let isClockInOut = data.Event.indexOf("CLOCK") > -1;
        let isLunch = data.Event === "LUNCH";
        let rowsLength = this.props.rowsLength || 1;
        let rowHeight = 30;
        let color = isClockInOut ? undefined : data.Color || "#f4f4f4";
        let costTypeBG =
            data.Event === "NO GPS"
                ? "repeating-linear-gradient(to bottom," +
                color +
                "," +
                color +
                " 3px,#fff 3px,#fff 6px)"
                : color;
        return (
            <div
                title="TE"
                className={`TE ${styles.TE} ${data.Event.toLowerCase()}`}
                style={
                    rowsLength ? {height: rowsLength * rowHeight + "px"} : undefined
                }
                data-teid={data.Id}
                onContextMenu={
                    data.SiteId === null && data.LocationId === null
                        ? this.OnTeContext
                        : undefined
                }
                onMouseOver={(event) => {
                    //@ts-ignore
                    let classList = event.target.classList[0];

                    if (
                        this.tooltipRef?.current?.handleMouseOver &&
                        (!classList || classList.indexOf("daylog_PhotoIcon") === -1)
                    ) {
                        this.tooltipRef.current &&
                        this.tooltipRef.current.handleMouseOver(event);
                    }
                }}
                onMouseOut={(event) => {
                    this.tooltipRef.current &&
                    this.tooltipRef.current.handleMouseOut(event);
                }}
            >
                <div className={styles.TEContent}>
                    {!isClockInOut && (
                        <div className={styles.TEText}>
                            {this.renderAllocation(data)}

                            {!this.props.IsHideUnscheduledVisitAlert && data.IsUnscheduledVisit && (
                                <span
                                    className={"mdi mdi-exclamation-thick"}
                                    style={{color: "red"}}
                                ></span>
                            )}
                            <span className={styles.TEDuration}>
                {data.ApprovedDurationString}
              </span>
                        </div>
                    )}
                </div>

                <Tooltip
                    ref={this.tooltipRef}
                    anchorElement="target"
                    position="top"
                    content={this.renderTETooltip}
                    parentTitle={true}
                    tooltipStyle={{marginBottom: "-10px"}}
                ></Tooltip>
                <span
                    onClick={this.OpenTE}
                    title="Open Time Entry"
                    className={`${styles.TECostType} ${
                        !isLunch && !data.ApprovedDuration
                            ? styles.thin
                            : ""
                    }`}
                    style={{color: data.Color}}
                    data-mark={data.DataMark}
                >
          <span
              className={styles.TECostTypeBefore}
              style={{background: costTypeBG}}
          ></span>
        </span>
                {isClockInOut && (
                    <div className={`${styles.TEPhotoRow}`}>
                        {data.HasPhoto && Boolean(this.state.selfieUrl) && (
                            <>
                                <Tooltip
                                    anchorElement="target"
                                    position="left"
                                    content={this.renderSelfieTooltip}
                                    onPosition={this.SelfieTooltipOnPosition}
                                    onOpen={() => this.setState({isVisiblePhoto: true})}
                                    onClose={() => this.setState({isVisiblePhoto: false})}
                                >
                  <span
                      className={`${styles.PhotoIcon} mdi mdi-account-box-outline`}
                      title="Photo"
                  >
                    {this.state.isVisiblePhoto && (
                        <div className={`${styles.PhotoBox}`}>
                            <img
                                alt="Selfie"
                                className={styles.Selfie}
                                src={this.state.selfieUrl}
                            />
                        </div>
                    )}
                  </span>
                                </Tooltip>
                            </>
                        )}
                    </div>
                )}
                <div className={styles.TESpace}></div>
            </div>
        );
    }

    renderAllocation = (data: simpleObject) => {
        if (data.Event === "LUNCH") {
            return (
                <span>
          ({data.ClockedDurationString}) {data.Event}
        </span>
            );
        } else if (data.WorkOrderId) {
            const costType: costTypeCode = data.CostTypeCode
            return (
                <div className={styles.WOAllocationBox}>
                    <OpenCardLink
                        className={styles.WOLinkName}
                        text={data.WorkOrderName || ""}
                        refName="FSMWorkOrders"
                        dataAttr={data.WorkOrderId}
                    />
                    {costType !== 'DRIVE' && costType !== 'INTDRIVE' && <OpenCardLink
                        className={styles.WOLinkNumber}
                        text={data.WorkOrderNumber || ""}
                        refName="FSMWorkOrders"
                        dataAttr={data.WorkOrderId}
                    />}
                </div>
            );
        } else if (data.ManualAllocation.length) {
            return <span>Manual Allocation</span>;
        } else {
            return "";
        }
    };

    renderSelfieTooltip = (e: any) => {
        return (
            <div
                className={`${styles.PhotoBox}`}
                style={{
                    height: e.target.children[0].clientHeight,
                    width: "200px",
                }}
            >
                <img
                    alt="Selfie"
                    className={styles.Selfie}
                    src={this.state.selfieUrl}
                />
            </div>
        );
    };

    renderTETooltip = () => {
        let data = this.props.data;
        if (!data) return null;
        return (
            <div className={`${styles.TEDetailsContent} tool`}>
                {data.IsUnscheduledVisit && <div>Unscheduled Visit</div>}
                <div>
                    {data.TimeStart}&nbsp;-&nbsp;{data.TimeFinish}
                </div>
                <div>Event: {data.Event}</div>
                <div>Cost Type: {data.CostTypeName || ""}</div>
                {!!data.LocationName && <div>Location: {data.LocationName}</div>}
                {!!data.SiteName && <div>Site: {data.SiteName}</div>}
                {!!data.WorkOrderSiteName &&
                    data.WorkOrderSiteName !== data.SiteName && (
                        <div>Work Order Site: {data.WorkOrderSiteName}</div>
                    )}
                <div>Duration: {data.ClockedDurationString}</div>
                <div>Approved: {data.ApprovedDurationString}</div>

                {!!data.ManualAllocation.length && (
                    <>
                        <br/>
                        <div>Allocation:</div>
                        <div className={styles.TEDetails}>
                            {data.ManualAllocation.map((item: any) => {
                                return (
                                    <div
                                        key={`${item.TEId}_${item.WorkOrderId}`}
                                        className={styles.TEDetailsRow}
                                    >
                                        <span>{item.Percentage} % -&nbsp;</span>
                                        <OpenCardLink
                                            text={item.WorkOrderName}
                                            dataAttr={item.WorkOrderId}
                                            refName="FSMWorkOrders"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
                {!!data.Comments && <div>Comment: {data.Comments}</div>}
            </div>
        );
    };

    GetSelfieUrl = async () => {
        let data = this.props.data;
        let id = data?.Id;
        let isClockInOut = data.Event.indexOf("CLOCK") > -1;
        if (!isClockInOut || !id) return;
        let selfieUrl: string | undefined = await GetTKSelfieUrl(id);
        this.setState({selfieUrl});
    };

    SelfieTooltipOnPosition = (e: any) => {
        let coords = e.targetElement.getBoundingClientRect();
        let tooltip = e.targetElement.children[0];
        let tooltipWidth = tooltip.clientWidth;
        let tooltipHeight = tooltip.clientHeight;

        let left = coords ? coords.left - tooltipWidth - 15 : 0;
        let top = coords
            ? coords.top - tooltipHeight / 2 + coords.height / 2 - 4
            : 0;
        let right = left + tooltipWidth;
        let bottom = top + tooltipHeight;
        if (right > window.outerWidth) {
            left = left - (right - window.outerWidth);
        }
        let documentHeight = window.document.body.clientHeight;
        if (bottom > documentHeight) {
            top = top - (bottom - documentHeight);
        }
        return {
            left: left < 0 ? 0 : left,
            top: top < 0 ? 0 : top,
        };
    };

    OnTeContext = (e: any) => {
        let teId = this.props.data?.Id;
        if (!teId) return;
        e.nativeEvent.preventDefault();
        let left = e.nativeEvent.clientX;
        let top = e.nativeEvent.clientY;
        this.props.openContextMenu(left, top, teId);
    };

    OpenTE = () => {
        let teId = this.props.data.Id;
        OpenRecord("TKTimeEntries", teId);
    };
}

export default TimeEntry;
