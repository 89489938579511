import React, {useRef} from 'react';
import styles from "./livemap.module.scss";

interface IProps {
    action: any
    listId: number
    clockInId: number
}

const EmptyRowWithPeriodToggle = (props: IProps) => {
    const {
        listId,
        clockInId,
    } = props

    const elementRef = useRef<HTMLDivElement | null>(null)

    return <div
        ref={elementRef}
        className={`${styles.TERow} device-te`}
        style={{marginTop: -10}}
        data-clockinid={clockInId}
        data-listid={listId}
    >
        <div className={styles.TERowLeft}>
            {props.action}
            <div className={styles.ClockInDevicePlatform}>
                <div className={styles.DevicePlatform} style={{color: '#c6c6c6'}}>
                    <i className="k-icon k-i-arrow-60-down" style={{
                        fontSize: 25,
                        position: 'relative',
                        left: -1
                    }}></i>
                </div>
            </div>
        </div>
    </div>
}

export default EmptyRowWithPeriodToggle
