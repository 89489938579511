import {Button} from '@progress/kendo-react-buttons';
import {TextArea} from '@progress/kendo-react-inputs'
import FXCard from '../Common/FXCard/FXCard';
import FilterCombobox from '../Common/Form/FilterCombobox'
import styles from './card.module.scss'
import {IFXCardProps} from './interfaces';
import React, {useCallback, useEffect, useState} from "react";
import {getSQLData} from "../../helpers/queries";
import {maxDate, minDate} from "../Dashboard/helpers";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import moment from "moment";
import {RunScriptAsync} from "../../helpers/runscripts";

interface props extends IFXCardProps {
  employeeId?: number
  date?: Date

  afterSave?(): void
}

interface Employee {
  Id: number,
  Name: string
}

interface RequestType {
  Id: string,
  Name: string
}

const REQUEST_TYPES = [
  {Id: 'TimeAdjustment', Name: 'Time Adjustment'},
  {Id: 'TimeOff', Name: 'Time Off'},
  {Id: 'PayrollItem', Name: 'Miscellaneous'},
]

export default function SolicitRequestCard(props: props) {
  const [employees, setEmployees] = useState<Array<Employee>>()
  const [employee, setEmployee] = useState<Employee | undefined>()
  const [date, setDate] = useState(props.date)
  const handleDateChange = useCallback((e: any) => {
    setDate((e.value || undefined) && new Date(e.value))
  }, [])
  const [requestType, setRequestType] = useState(REQUEST_TYPES[0])
  const [comment, setComment] = useState("")
  const handleCommentChange = useCallback((e: any) => {
    setComment(e.value)
  }, [])

  useEffect(() => {
    const loadData = async () => {
      const employees = (await getSQLData({
        spName: 'DB_SelectRefRecords',
        params: {refName: "Employees"}
      }))[0] as Array<Employee>;
      setEmployees(employees)
      if (props.employeeId) {
        const employee = employees.find(e => e.Id === props.employeeId)
        setEmployee(employee)
      }
    }
    loadData()
  }, [])

  const handleSolicit = useCallback(
    () => {
      if(!employee || !date || !comment || !requestType) return;
      RunScriptAsync('TKRequestFromMobile', {
        RequestJSON: JSON.stringify({Solicitation: {
            RequestType: requestType.Id,
            EmployeeID: employee.Id,
            RequestDate: moment(date).format('DD.MM.YYYY'),
            Comment: comment
          }})
      })
        .finally(() => {
          props.afterSave?.();
          props.finally?.();
        })
    },
    [employee, date, comment],
  );


  return <FXCard
    title={'Solicit Request'}
    onClose={props.finally}
    initialWidth={450}
    initialHeight={340}
    originalPaddings={true}
  >
    <div className={styles.FormWrapper}>
      <FilterCombobox
        placeholder="Employee *"
        data={employees || []}
        onChange={setEmployee}
        className={styles.FormField}
        loading={!employees}
        value={employee}
      />
      <div className={styles.DatePickerRow}>
      <DatePicker
        onChange={handleDateChange}
        className={styles.Datepicker}
        value={date}
        formatPlaceholder={{month: 'm', day: 'd', year: 'y'}}
      ></DatePicker>
      </div>
      <FilterCombobox
        placeholder="Type *"
        data={REQUEST_TYPES}
        onChange={setRequestType}
        className={styles.FormField}
        value={requestType}
      />
      <TextArea
        className={styles.TextArea}
        rows={8}
        placeholder="Comment"
        onChange={handleCommentChange}
      ></TextArea>
      <div className={`${styles.FormFooter} k-action-buttons`}>
        <Button onClick={props.finally}>Cancel</Button>
        <Button
          onClick={handleSolicit}
          themeColor="primary"
          disabled={!employee || !date || !comment || !requestType}
        >Solicit</Button>
      </div>
    </div>
  </FXCard>
}
