import {StartCheckClosureCardProps} from "./interfaces";
import FXCard from "../Common/FXCard/FXCard";
import formStyles from './card.module.scss'
import {DatePicker} from "@progress/kendo-react-dateinputs";
import {Button} from "@progress/kendo-react-buttons";
import {useBooleanState} from "../../helpers/hooks";
import React, {useEffect, useRef, useState} from "react";
import FilterMultiSelect from "../Common/Form/FilterMultiSelect";
import {getSQLData} from "../../helpers/queries";
import LoaderComponent from "../Common/Loader";
import {Checkbox, CheckboxHandle, Input, InputChangeEvent} from "@progress/kendo-react-inputs";
import {RunScriptAsync} from "../../helpers/runscripts";
import moment from "moment";
import {simpleObject} from "../../helpers/interfaces";

interface IEmployee {
    EmployeeId: number
    EmployeeName: string
}

export interface IActionParams {
    StartDate?: Date | null,
    FinishDate?: Date | null,
    CheckDate?: Date | null,
    EmployeeIds?: string,
    Title?: string,
}

const StartCheckClosureCard = (props: StartCheckClosureCardProps) => {
    const {rowData} = props
    const {PeriodId} = rowData
    const StartPeriod = moment(rowData.Start).toDate()
    const EndPeriod = moment(rowData.End).toDate()
    const isProcessing = useBooleanState(false)
    const isLoading = useBooleanState(false)
    const [employees, setEmployeess] = useState<IEmployee[]>([])
    const [isValid, setIsValid] = useState(false)
    const syncCheckBoxRef = useRef<CheckboxHandle>(null)
    const paramsRef = useRef<IActionParams>({
        StartDate: StartPeriod,
        FinishDate: moment(new Date()).isSameOrBefore(EndPeriod) ? new Date() : EndPeriod,
        CheckDate: new Date(),
    })
    const close = () => {
        props.finally!()
    }

    useEffect(() => {
        LoadEmployees()
    }, [])

    const LoadEmployees = async () => {
        try {
            isLoading.setTrue()
            let result: any = await getSQLData({
                spName: 'TKPayrollChecks_SelectEmployees',
                params: {periodId: PeriodId}
            });
            setEmployeess(result[0])
        } finally {
            isLoading.setFalse()
        }
    }

    const startCheckClosure = async () => {
        try {
            isProcessing.setTrue()
            const params: simpleObject = {
                PeriodId,
                StartDate: moment(paramsRef.current.StartDate).format('DD.MM.YYYY'),
                FinishDate: moment(paramsRef.current.FinishDate).format('DD.MM.YYYY'),
                CheckDate: moment(paramsRef.current.CheckDate).format('DD.MM.YYYY'),
                EmployeeIds: paramsRef.current.EmployeeIds,
                Title: paramsRef.current.Title
            }
            if (props.rowData.PeriodTypeNeedsSync) {
                params.SyncToPayroll = !!syncCheckBoxRef.current?.element?.checked
            }
            await RunScriptAsync('PayrollChecks_LaunchOffCycleCheck', params)

            props.refresh()
            close()
        } catch (e: any) {
            console.log(e)
        } finally {
            isProcessing.setFalse()
        }
    }

    const checkIsValid = () => {
        const isValid = !!paramsRef.current.Title && !!paramsRef.current.EmployeeIds &&
            !!paramsRef.current.StartDate && !!paramsRef.current.FinishDate && !!paramsRef.current.CheckDate
        setIsValid(isValid)
    }

    const onChangeTitle = (event: InputChangeEvent) => {
        paramsRef.current.Title = event.value
        checkIsValid()
    }

    const onChangeMultiselect = (selectedList?: Array<IEmployee>) => {
        const ids = selectedList
            ? selectedList.map((item) => item.EmployeeId).join(",")
            : "";
        paramsRef.current.EmployeeIds = ids
        checkIsValid()
    };

    const onChangeDate = async (e: any) => {
        const name = e.target.props.name as keyof IActionParams
        paramsRef.current[name] = e.value
        checkIsValid()
    };

    const renderFooter = () => {
        return <div className={`${formStyles.FormFooter} k-action-buttons`}>
            <span className={formStyles.InvalidMessage}>
				{!isValid && <span>Fill in all required fields</span>}
			</span>
            <Button onClick={close}>Cancel</Button>
            <Button
                onClick={startCheckClosure}
                themeColor="primary"
                disabled={!isValid}
            >Start Check Closure </Button>
        </div>
    }

    return <FXCard
        title={'New Off-Cycle Check'}
        initialWidth={400}
        initialHeight={400}
        originalPaddings={true}
        onClose={close}
    >
        <div className={formStyles.FormWrapper}>
            {isProcessing.value && <LoaderComponent/>}

            <div className={formStyles.Row}>
                <Input
                    className={`${formStyles.FormField}`}
                    placeholder="Title *"
                    onChange={onChangeTitle}
                    required={true}
                />
            </div>
            <div className={`${formStyles.Row}`}>
                <FilterMultiSelect
                    placeholder="Employees *"
                    data={employees}
                    className={formStyles.FormField}
                    onChange={onChangeMultiselect}
                    loading={isLoading.value}
                    textField={'EmployeeName'}
                    dataItemKey={'EmployeeId'}
                    required={true}
                />
            </div>

            <div className={formStyles.DatePickerRow}>
                <div className={formStyles.DatePickerLabel}>Start Date: *</div>
                <DatePicker
                    className={formStyles.Datepicker}
                    onChange={onChangeDate}
                    defaultValue={paramsRef.current.StartDate}
                    name={'StartDate'}
                    formatPlaceholder={{month: 'm', day: 'd', year: 'y'}}
                    required={true}
                    max={EndPeriod}
                    min={StartPeriod}
                ></DatePicker>
            </div>
            <div className={formStyles.DatePickerRow}>
                <div className={formStyles.DatePickerLabel}>End Date: *</div>
                <DatePicker
                    className={formStyles.Datepicker}
                    onChange={onChangeDate}
                    defaultValue={paramsRef.current.FinishDate}
                    name={'FinishDate'}
                    formatPlaceholder={{month: 'm', day: 'd', year: 'y'}}
                    required={true}
                    max={EndPeriod}
                    min={StartPeriod}
                ></DatePicker>
            </div>
            <div className={formStyles.DatePickerRow}>
                <div className={formStyles.DatePickerLabel}>Check Date: *</div>
                <DatePicker
                    className={formStyles.Datepicker}
                    onChange={onChangeDate}
                    defaultValue={paramsRef.current.CheckDate}
                    name={'CheckDate'}
                    formatPlaceholder={{month: 'm', day: 'd', year: 'y'}}
                    required={true}
                    min={StartPeriod}
                ></DatePicker>
            </div>

            {props.rowData.PeriodTypeNeedsSync && <div className={`${formStyles.Row}`}>
                <Checkbox
                    ref={syncCheckBoxRef}
                    label={'Sync To Payroll'}
                />
            </div>}

            {renderFooter()}
        </div>
    </FXCard>
}

export default StartCheckClosureCard;
