import moment from 'moment'
import {Grid, GridColumn as Column} from '@progress/kendo-react-grid'
import BaseComponent from '../BaseComponent'
import {GridRowHeight} from '../Dashboard/helpers'
import Loader from '../Common/Loader'
import FXCard from '../Common/FXCard/FXCard'
import {IFXCardProps} from './interfaces'

interface props extends IFXCardProps {
  bpid: number
  msid: number
  title?: string
}

interface state {
  history: Array<any>
  loading: boolean
}

class MilestoneHistory extends BaseComponent<props, state> {

  constructor(props: props) {
    super(props)
    this.state = {
      history: [],
      loading: true
    }
  }

  componentDidMount() {
    this.LoadHistory()
  }

  render() {
    return (
      <FXCard
        title={this.props.title}
        onClose={this.props.finally}
      >
        {this.state.loading && <Loader/>}
        <Grid
          style={{height: '100%'}}
          data={this.state.history}
          resizable={true}
          rowHeight={GridRowHeight}
        >
          <Column
            field='ChangeDate'
            title='Date'
            width='140px'
          />
          <Column
            field='ChangedByName'
            title='User'
            width='200px'
          />
          <Column
            field='ChangeFieldTitle'
            title='Field'
            width='100px'
          />
          <Column
            field='NewValue'
            title='Value'
            width='200px'
          />
          <Column
            field='OldValue'
            title='Previous Value'
            width='200px'
          />
        </Grid>
      </FXCard>
    )
  }

  LoadHistory = async () => {
    let bpid = this.props.bpid;
    let msid = this.props.msid;
    if (!bpid || !msid) return;
    try {
      this.setState({loading: true});
      let result = await this.GetSQLData({
        spName: 'FX_GetMilestoneHistory',
        params: {bpId: bpid, milestoneId: msid}
      });
      if (!result[0] || !result[0].length) {
        this.setState({history: []})
        return;
      }
      let history = result[0]
      for (let item of history) {
        if (item.ChangeDate) item.ChangeDate = moment(item.ChangeDate).format('L LT')
        if (item.Projected) item.Projected = moment(item.Projected).format('L')
        if (item.Actual) item.Actual = moment(item.Actual).format('L')
      }
      history.forEach((item: any, index: number) => {
        const prevHistory = history.slice(index + 1)
        switch (item.ChangeFieldName) {
          case 'IsApplicable':
            item.NewValue = item.IsApplicable
            item.OldValue = prevHistory.filter((i: any) => i.ChangeFieldName === 'IsApplicable')[0]?.IsApplicable
            item.ChangeFieldTitle = 'Applicable'
            break;
          case 'Actual':
            item.NewValue = item.Actual
            item.OldValue = prevHistory.filter((i: any) => i.ChangeFieldName === 'Actual')[0]?.Actual
            item.ChangeFieldTitle = 'Actual'
            break;
          case 'Projected':
            item.NewValue = item.Projected
            item.OldValue = prevHistory.filter((i: any) => i.ChangeFieldName === 'Projected')[0]?.Projected
            item.ChangeFieldTitle = 'Projected'
            break;
          case 'ResponsibleId':
            item.NewValue = item.ResponsibleName
            item.OldValue = prevHistory.filter((i: any) => i.ChangeFieldName === 'ResponsibleId')[0]?.ResponsibleName
            item.ChangeFieldTitle = 'Responsible'
            break;
          case 'Comments':
            item.NewValue = item.Comments
            item.OldValue = prevHistory.filter((i: any) => i.ChangeFieldName === 'Comments')[0]?.Comments
            item.ChangeFieldTitle = 'Comments'
            break;
        }
      })
      this.setState({history});
    } finally {
      this.setState({loading: false});
    }
  }

}

export default MilestoneHistory
