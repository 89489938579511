import {ModalRef} from '../Components/Common/Modal/Modal';
import {application, DataServerHost, isConterra} from './settings';
import {simpleObject} from './interfaces';
import SignalR from './signalR';
import {Deferred} from './deffered';
import AuthData from './authData';
import {axiosQuery, showRequestError} from './axiosHelpers';
import InstanceSettings from './instanceSettings'
import moment from 'moment'
import {AxiosRequestConfig} from 'axios';

const $ = window.$

let openISB = function (filename: string, text: string) {
    var blob = new Blob([text], {type: 'text/plain'});
    // @ts-ignore
    if (window.navigator.msSaveOrOpenBlob) {
        // @ts-ignore
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        var elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }
}

let getDoNotLock = function (doNotLock: boolean | null | undefined) {
    if (isConterra) return true;
    if (doNotLock === undefined || doNotLock === null) return localStorage.getItem('OpenRecordAutorefresh') === 'false';
    return !!doNotLock;
}

let lockExecutingScreen = function (callId: number) {
    let title = 'Waiting for completion of external action.';
    let text = 'Data will be refreshed automatically. <br/>If you hit &quot;Continue&quot; changes will not be automatically updated in dashboard.';
    ModalRef.showDialog({
        title, text, buttons: [{
            text: 'Continue', action: () => {
                unLockExecutingScreen(callId)
            }
        }]
    });
}

let unLockExecutingScreen = (callId?: number) => {
    ModalRef.hideDialog();
    if (callId) {
        SignalR.removeListener(callId);
    }
}

export const RunScriptLocalAsync = (scriptName: string, params?: simpleObject, doNotLockparam?: boolean) => {
    let defer = new Deferred();
    let doNotLock = getDoNotLock(doNotLockparam);
    let callId = (new Date()).valueOf();

    if (!doNotLock) {
        lockExecutingScreen(callId);
        defer.promise.finally(() => {
            unLockExecutingScreen();
        })
    }

    if (isConterra) {
        SignalR.addListener(callId, defer.resolve, defer.reject);
        RunScript(scriptName, params).then((result) => {
            SignalR.onResult({Success: true, CallId: callId, Result: result});
        }).catch(() => {
            SignalR.onResult({Success: false, CallId: callId});
        });
    } else {
        SignalR.initialization.finally(async () => {
            let systemCode = await InstanceSettings.getSystemCodeAsync()
            if (!systemCode) return
            var filename = 'Run_' + scriptName + '.isb';
            var paramsText = 'ScriptName=' + scriptName + '\n';
            if (!doNotLock) {
                if (SignalR.initializationState === 'resolved') {
                    SignalR.addListener(callId, defer.resolve, defer.reject);
                    paramsText += 'ConnectionID=' + SignalR.id + '\n' +
                        'CallID=' + callId + '\n';
                } else {
                    setTimeout(defer.reject, 3000);
                }
            }

            if (params) {
                Object.keys(params || {}).forEach(function (key) {
                    paramsText += key + '=' + (params[key] || '') + '\n';
                });
            }

            var text = 'Version=ISB7\n'
                + 'SystemCode=' + systemCode + '\n'
                + 'ComponentType=2\n'
                + 'ComponentCode=RunSignalRScript\n'
                + 'ID=\n'
                + 'ViewCode=\n'
                + paramsText;

            openISB(filename, text);
        });
    }

    return defer.promise;
}

export const RunScript = (scriptName: string, params?: simpleObject, modal?: boolean, doNotFail?: boolean) => {
    let func = new Promise((resolve, reject) => {
        params = params || {};

        if (isConterra) {
            let script = application().ScriptFactory.GetObjectByName(scriptName);
            if (params) {
                for (let paramName in params) {
                    script.Params.Add(paramName, params[paramName]);
                }
            }

            try {
                let result = script.Execute();
                resolve(result);
            } catch (e: any) {
                var str = e.message;

                if (str.indexOf('4AFB2AB8-BDFD-4094-B27E-D105C8A89EFB') > -1) {
                    resolve(null);
                } else {
                    str = e.message.replace('^', '');
                }
                reject(str);
            }
        } else {
            reject("RunScript is available only in desktop client");
        }
    })

    if (modal) {
        ModalRef.startProcessing();
        func.finally(ModalRef.stopProcessing);
    }

    if (!doNotFail) {
        func.catch((error: string) => {
            let title = 'The following error occured while executing conterra script ' + scriptName + ':';
            ModalRef.showDialog({title, text: error, type: 'error'});
        })
    }
    return func;
};

export const RunScriptAsync = async (scriptName: string, params: simpleObject, modal?: boolean, doNotFail?: boolean, cancelToken?: any) => {
    params = params || {};
    try {
        if (modal) ModalRef.startProcessing();
        let data = await AuthData.queueGetAuthToken();
        if (data && data.length && data[0] && data[1]) {
            let access_token = data[0];
            let token_type = data[1];
            let axiosConfig: AxiosRequestConfig = {
                method: 'post',
                url: `https://${DataServerHost}/api/scripts/${scriptName}`,
                data: params,
                headers: {
                    Authorization: `${token_type} ${access_token}`,
                    timestamp: moment().format()
                },
                cancelToken: cancelToken?.token
            };
            return await axiosQuery(axiosConfig);
        }
    } catch (e: any) {
        if (!doNotFail) {
            // Details 'The following error occured while executing async conterra script ' + scriptName + ':' +
            showRequestError(e)
        }
        throw e
    } finally {
        if (modal) ModalRef.stopProcessing();
    }
};

const callIdToRecord: simpleObject = {};

export const OpenRecord = (refName: string, id: number, params?: simpleObject, doNotLock?: boolean) => {
    let defer = new Deferred();
    var callId = (new Date()).valueOf();
    let notLock = getDoNotLock(doNotLock);

    if (!notLock) {
        lockExecutingScreen(callId);
        defer.promise.finally(unLockExecutingScreen)
    }

    if (isConterra) {
        var record = application().ReferencesFactory.ReferenceFactory(refName).GetComponent();
        record.AddWhere('MBAnalit.Analit = ' + id);
        if (params) {
            Object.keys(params).forEach(function (key) {
                record.Params.SetVar(key, params[key]);
            });
        }
        record.Form.ShowNoModal();
        if (!notLock) {
            SignalR.addListener(callId, defer.resolve, defer.reject);
            callIdToRecord[callId] = record;
            waitRecordClosed(callId);
        }
    } else {
        SignalR.initialization.finally(async function () {
            let systemCode = await InstanceSettings.getSystemCodeAsync()
            if (!systemCode) return

            var filename = 'OpenRecord_' + refName + '_' + id + '.isb';
            var paramsText = '';

            if (!notLock) {
                if (SignalR.initializationState === 'resolved') {
                    if (!notLock) SignalR.addListener(callId, defer.resolve, defer.reject);
                    paramsText += 'ConnectionID=' + SignalR.id + '\n' +
                        'CallID=' + callId + '\n';
                } else {
                    setTimeout(defer.reject, 3000);
                }
            }
            if (params) {
                Object.keys(params).forEach(function (key) {
                    paramsText += key + "=" + (params[key] || "") + "\n";
                });
            }

            var text = 'Version=ISB7\n'
                + 'SystemCode=' + systemCode + '\n'
                + 'ComponentType=1\n'
                + 'ComponentCode=' + refName + '\n'
                + 'ID=' + id + '\n'
                + 'ViewCode=\n'
                + paramsText;
            openISB(filename, text);
        });
    }

    return defer.promise;
};

export const OpenJob = async (jobId: number) => {
    if (isConterra) {
        application().JobFactory.GetObjectByID(jobId).Form.ShowNoModal();
    } else {
        let systemCode = await InstanceSettings.getSystemCodeAsync()
        if (!systemCode) return
        var filename = jobId + '.isb';
        var text = 'Version=ISB7\n'
            + 'SystemCode=' + systemCode + '\n'
            + 'ComponentType=10\n'
            + 'ComponentCode=\n'
            + 'ID=' + jobId + '\n'
            + 'ViewCode=\n';
        openISB(filename, text);
    }
};

let waitRecordClosed = (callId: number) => {
    var newcb = function () {
        if (callIdToRecord[callId] && callIdToRecord[callId].ActiveForm) waitRecordClosed(callId);
        else {
            delete callIdToRecord[callId];
            SignalR.onResult({Success: true, CallId: callId});
        }
    };
    if (SignalR.listeners[callId]) {
        setTimeout(newcb, 50);
    }
}

window.helpers.runScriptAsync = function (scriptName: string, params: simpleObject, modal?: boolean, doNotFail?: boolean) {
    var defer = $ && $.Deferred();
    RunScriptAsync(scriptName, params, modal, doNotFail)
        .then((result) => defer.resolve(result))
        .catch((error: any) => defer.reject(error));
    return defer.promise()
}

window.helpers.openRecord = function (refName: string, id: number, params?: simpleObject) {
    OpenRecord(refName, id, params)
}

window.helpers.runScriptLocalAsync = function (scriptName: string, params?: simpleObject, doNotLockparam?: boolean) {
    var defer = $ && $.Deferred();
    RunScriptLocalAsync(scriptName, params, doNotLockparam)
        .then((result: any) => defer.resolve(result))
        .catch((error: any) => defer.reject(error));
    return defer.promise()
}
