import {useContext} from "react";
import {
  IntegrationWithDesktopContext,
  isConterra,
} from "../../helpers/settings";

export default function DesktopActions({actions}: { actions: any }) {
  const theme = useContext(IntegrationWithDesktopContext);
  let showActions = isConterra || theme.isIntegrationWithDesktop;
  if (!showActions) return null;
  return actions;
}
