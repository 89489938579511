import {Button, Chip} from "@progress/kendo-react-buttons";
import {DatePicker, TimePicker} from "@progress/kendo-react-dateinputs";
import {DropDownList, TagData} from "@progress/kendo-react-dropdowns";
import {Checkbox, TextArea} from "@progress/kendo-react-inputs";
import {Card} from "@progress/kendo-react-layout";
import {Tooltip} from "@progress/kendo-react-tooltip";
import moment from "moment";
import React, {Fragment} from "react";
import {IComboboxItem, simpleObject} from "../../helpers/interfaces";
import {ReferenceRecordsDataSource} from "../../helpers/queries";
import {OpenRecord, RunScriptAsync} from "../../helpers/runscripts";
import {IS_IE} from "../../helpers/settings";
import BaseComponent from "../BaseComponent";
import OpenCardLink from "../Common/Buttons/OpenCardLink";
import FXCard from "../Common/FXCard/FXCard";
import ClearableDateInput from "../Common/Form/ClearableDateInput";
import FilterCombobox from "../Common/Form/FilterCombobox";
import FilterMultiSelect from "../Common/Form/FilterMultiSelect";
import Loader from "../Common/Loader";
import {ModalRef} from "../Common/Modal/Modal";
import {maxDate, minDate} from "../Dashboard/helpers";
import CardManagement from "./CardManagement";
import OpenCardIconLink from "./OpenCardIconLink";
import styles from "./card.module.scss";
import {IFXCardProps} from "./interfaces";

interface ICrewItem extends IComboboxItem {
    CrewLeadId: number;
    Members: Array<number>;
}

interface ICrewMemberItem extends IComboboxItem {
    CrewContainerId: number;
    CrewMemberId: number;
}

interface IWOItem extends IComboboxItem {
    AllowOvernight?: boolean;
    PrevailingWage?: boolean;
    WorkTypeTitle: string;
}

interface IEmployeeItem extends IComboboxItem {
    IsLimited: boolean;
    IsActive?: boolean;
}

interface IDispatchInfoFromServer {
    AllowOvernight: boolean;
    ArriveAfter?: string | null;
    ArriveBefore?: string | null;
    CanPublish: boolean;
    ClockInAfter: /* string |  */ Date | null;
    ClockOutBefore: /* string |  */ Date | null;
    Comments: string | null;
    CrewLeadId: number | null;
    CrewContainerId: number | null;
    CrewContainerName: string | null;
    Date: Date | /* string |  */ null;
    DepartAfter?: string | null;
    DepartBefore?: string | null;
    PrevailingWage: boolean;
    Published: boolean;
    RemoteHotel: boolean;
    WorkTypeTitle: string;
    WorkOrderId: number | null;
    WorkOrderName: string;
}

interface IDispatchInfo extends IDispatchInfoFromServer {
    Arrive: Date | null;
    ArriveType: "After" | "Before";
    CrewContainerId: number | null;
    CrewIDs: string;
    Depart: Date | null;
    DepartType: "After" | "Before";
    DateTo: Date | null;
    WorkDaysOnly: boolean;
}

interface props extends IFXCardProps {
    newDispatch: boolean;
    buildPlanId?: number | undefined;
    woId?: number;
    dsId?: number;
    date?: Date;
    crewLeadId?: number;

    afterSave?(): void;
}

interface state {
    workOrders: Array<IWOItem>;
    resources: Array<IEmployeeItem>;
    loading: boolean;
    selectedCrewMembers: IEmployeeItem[] | undefined;
    selectedWO: IWOItem | null;
    selectedCrewLead: IEmployeeItem | null;
    selectedCrewContainer: IComboboxItem | null
    isReadOnlyForm: boolean;
    remountKey: number;
    remountDateKey: number;
    crews: Array<ICrewItem>;
}

const MIN_HEIGHT = 440;
let minHeight =
    window.innerHeight < MIN_HEIGHT ? window.innerHeight : MIN_HEIGHT;

class DispatchCard extends BaseComponent<props, state> {
    DSId: number | null = this.props.dsId || null;
    AllResources: Array<IComboboxItem> | null = null;
    BPId: number | null = this.props.buildPlanId || null;
    InitialCrew: { CrewMemberId: number, CrewMemberName: string }[] = [];
    InitialCrewLeadId: number | null = this.props.crewLeadId || null;
    ScheduledEmployees: {
        [key: number]: { Name: string; Dates: Array<string> };
    } = {};
    ScheduledEmployeesReasons: {
        [key: number]: Array<{ Name: string; Date: string; Reason: string }>;
    } = {};
    ScheduledWODates: Array<{ Date: string; DispatchId: number }> = [];

    Info: IDispatchInfo = {
        AllowOvernight: false,
        CanPublish: false,
        ClockInAfter: null,
        ClockOutBefore: null,
        Comments: null,
        CrewContainerId: null,
        CrewContainerName: null,
        CrewLeadId: null,
        Date: this.props.date || (this.props.newDispatch ? new Date() : null),
        PrevailingWage: false,
        Published: false,
        RemoteHotel: false,
        WorkTypeTitle: "",
        WorkOrderId: null,
        WorkOrderName: "",

        Arrive: null,
        ArriveType: "After",
        CrewIDs: "",
        Depart: null,
        DepartType: "After",
        DateTo: null,
        WorkDaysOnly: false,
    };

    constructor(props: props) {
        super(props);
        this.state = {
            workOrders: [],
            resources: [],
            loading: false,
            selectedCrewMembers: undefined,
            selectedWO: null,
            selectedCrewLead: null,
            selectedCrewContainer: null,
            isReadOnlyForm: false,
            remountKey: +new Date(),
            remountDateKey: +new Date(),
            crews: [],
        };
    }

    componentDidMount() {
        if (this.props.newDispatch) this.InitNewDispatch();
        else this.InitEditDispatch();
    }

    InitNewDispatch = async () => {
        try {
            this.setState({loading: true});
            let results: Array<any> = await Promise.allSettled([
                this.LoadAvailableWorkOrders(),
                this.LoadDispatchInfo(),
                this.LoadEmployees(),
            ]);

            let workOrders: Array<IWOItem> = results[0].value[0] || [];
            let dispatchData = this.props.dsId && results[1];
            let dispatchInfo = dispatchData?.value[0];
            let dispatchCrew = dispatchData?.value[1];
            if (dispatchInfo && dispatchCrew) {
                dispatchInfo.Date = new Date();
                this.SetDispatchInfo(dispatchInfo, dispatchCrew);
            }

            let initialWoId = dispatchInfo
                ? dispatchInfo.WorkOrderId
                : this.props.woId;
            let selectedWO;
            if (initialWoId)
                selectedWO = workOrders.find((wo) => +wo.Id === +initialWoId);
            if (!selectedWO && workOrders.length === 1) selectedWO = workOrders[0];

            if (selectedWO) {
                this.Info.WorkOrderId = +selectedWO.Id;
                if (!dispatchData) {
                    this.Info.AllowOvernight = !!selectedWO.AllowOvernight;
                    this.Info.PrevailingWage = !!selectedWO.PrevailingWage;
                    this.Info.WorkTypeTitle = selectedWO.WorkTypeTitle;
                }
                await this.GetSetAvailableResources();
            }

            const {CrewContainerId, CrewContainerName} = this.Info
            this.setState({
                workOrders,
                selectedWO: selectedWO || null,
                isReadOnlyForm: false,
                selectedCrewContainer: CrewContainerId && CrewContainerName ? {
                    Id: CrewContainerId,
                    Name: CrewContainerName || ''
                } : null
                // remountKey: +new Date() // ??
            });
        } finally {
            this.setState({loading: false});
        }
    };

    InitEditDispatch = async () => {
        try {
            this.setState({loading: true});
            let results: Array<any> = await Promise.allSettled([
                this.LoadDispatchInfo(),
                this.LoadEmployees(),
            ]);
            let dispatchData = results[0];
            let dispatchInfo = dispatchData.value[0];
            let dispatchCrew = dispatchData.value[1];
            this.SetDispatchInfo(dispatchInfo, dispatchCrew);
            let selectedWO;
            selectedWO = {
                Id: dispatchInfo.WorkOrderId,
                Name: dispatchInfo.WorkOrderName,
                WorkTypeTitle: dispatchInfo.WorkTypeTitle,
            };
            this.Info.WorkOrderId = dispatchInfo.WorkOrderId;
            this.Info.Date = moment(dispatchInfo.Date).toDate();
            await this.GetSetAvailableResources();
            const {CrewContainerId, CrewContainerName} = this.Info
            this.setState({
                workOrders: [selectedWO],
                selectedWO,
                selectedCrewContainer: CrewContainerId && CrewContainerName ? {
                    Id: CrewContainerId,
                    Name: CrewContainerName || ''
                } : null,
                isReadOnlyForm: !dispatchInfo.Active,
                remountDateKey: +new Date(),
            });
        } finally {
            this.setState({loading: false});
        }
    };

    SetDispatchInfo = (
        dispatchInfo: IDispatchInfoFromServer,
        dispatchCrew: {
            CrewMemberId: number;
            CrewMemberName: string;
        }[]
    ) => {
        this.InitialCrewLeadId = dispatchInfo.CrewLeadId;
        this.Info.CanPublish = dispatchInfo.CanPublish;
        this.Info.Published = dispatchInfo.Published;
        this.Info.Comments = dispatchInfo.Comments;
        this.Info.CrewContainerId = dispatchInfo.CrewContainerId
        this.Info.CrewContainerName = dispatchInfo.CrewContainerName
        this.Info.AllowOvernight = dispatchInfo.AllowOvernight;
        this.Info.PrevailingWage = dispatchInfo.PrevailingWage;
        this.Info.RemoteHotel = dispatchInfo.RemoteHotel;
        this.Info.WorkTypeTitle = dispatchInfo.WorkTypeTitle;
        this.Info.ClockInAfter = dispatchInfo.ClockInAfter
            ? moment(dispatchInfo.ClockInAfter).toDate()
            : null;
        this.Info.ClockOutBefore = dispatchInfo.ClockOutBefore
            ? moment(dispatchInfo.ClockOutBefore).toDate()
            : null;
        this.Info.Date = dispatchInfo.Date
            ? moment(dispatchInfo.Date).toDate()
            : null;

        let arrive = dispatchInfo.ArriveAfter || dispatchInfo.ArriveBefore;
        if (arrive) {
            this.Info.Arrive = moment(arrive).toDate();
            this.Info.ArriveType = dispatchInfo.ArriveAfter ? "After" : "Before";
        } else {
            this.Info.Arrive = null;
            this.Info.ArriveType = "After";
        }

        let depart = dispatchInfo.DepartAfter || dispatchInfo.DepartBefore;
        if (depart) {
            this.Info.Depart = moment(depart).toDate();
            this.Info.DepartType = dispatchInfo.DepartAfter ? "After" : "Before";
        } else {
            this.Info.Depart = null;
            this.Info.DepartType = "After";
        }

        this.InitialCrew = [...dispatchCrew]
    };

    render() {
        let checkBoxClassName = styles.Checkbox;
        if (this.state.isReadOnlyForm)
            checkBoxClassName += " " + styles.CheckboxReadonly;
        return (
            <FXCard
                title={this.renderTitle()}
                onClose={this.Close}
                initialWidth={585}
                initialHeight={minHeight}
                originalPaddings={true}
                style={{
                    height:
                        window.innerHeight < MIN_HEIGHT
                            ? window.innerHeight
                            : "auto !important",
                    minHeight: window.innerHeight < MIN_HEIGHT ? "auto" : minHeight,
                }}
            >
                <div className={styles.FormWrapper}>
                    {this.state.loading && (
                        <div style={IS_IE ? {height: 150 + "px"} : undefined}>
                            <Loader/>
                        </div>
                    )}
                    {!this.state.loading && (
                        <>
                            <FilterCombobox
                                placeholder="Work Order *"
                                data={this.state.workOrders}
                                onChange={this.OnChangeWO}
                                className={styles.FormField}
                                value={this.state.selectedWO}
                                disabled={!this.props.newDispatch}
                            />
                            <Fragment key={this.state.remountKey}>
                                <div className={styles.DatePickerRow}>
                                    <DatePicker
                                        id={"Date"}
                                        onChange={this.OnDateChange}
                                        min={minDate}
                                        max={maxDate}
                                        className={styles.Datepicker}
                                        defaultValue={this.Info.Date || undefined}
                                        disabled={!this.props.newDispatch || !this.Info.WorkOrderId}
                                        formatPlaceholder={{month: "m", day: "d", year: "y"}}
                                        key={"date" + this.state.remountDateKey}
                                    ></DatePicker>
                                    {this.props.newDispatch && (
                                        <>
                                            <span className={styles.DateSeparator}>-</span>
                                            <DatePicker
                                                id={"DateTo"}
                                                onChange={this.OnDateChange}
                                                min={minDate}
                                                max={maxDate}
                                                className={styles.Datepicker}
                                                disabled={!this.Info.WorkOrderId}
                                                defaultValue={this.Info.DateTo || undefined}
                                                formatPlaceholder={{month: "m", day: "d", year: "y"}}
                                            ></DatePicker>
                                            <Checkbox
                                                id="WorkDaysOnly"
                                                defaultChecked={this.Info.WorkDaysOnly}
                                                label="Work Days Only"
                                                className={styles.Checkbox}
                                                onChange={this.OnChangeCheckbox}
                                                disabled={!this.Info.WorkOrderId}
                                            />
                                        </>
                                    )}
                                </div>
                                <FilterCombobox
                                    placeholder="Crew Container"
                                    data={this.state.crews}
                                    onChange={this.OnChangeCrew}
                                    className={styles.FormField}
                                    disabled={this.state.isReadOnlyForm || !this.Info.WorkOrderId}
                                    value={this.state.selectedCrewContainer}
                                />
                                <FilterCombobox
                                    placeholder="Crew Lead *"
                                    data={this.state.resources}
                                    onChange={this.OnChangeCrewLead}
                                    className={styles.FormField}
                                    value={this.state.selectedCrewLead}
                                    disabled={this.state.isReadOnlyForm || !this.Info.WorkOrderId}
                                    itemRender={this.renderEmployeeListItem}
                                />
                                <FilterMultiSelect
                                    placeholder="Crew Members"
                                    data={this.state.resources}
                                    className={styles.FormField}
                                    onChange={this.OnChangeMultiselect}
                                    value={this.state.selectedCrewMembers}
                                    disabled={this.state.isReadOnlyForm || !this.Info.WorkOrderId}
                                    itemRender={this.renderEmployeeListItem}
                                    tagRender={this.renderTagSelectedCrewMember}
                                />
                                <div
                                    style={{display: "flex", justifyContent: "space-between"}}
                                >
                                    <div style={{width: "425px"}}>
                                        <TextArea
                                            className={styles.TextArea}
                                            rows={6}
                                            placeholder="Comments"
                                            onChange={this.OnChangeComments}
                                            disabled={!this.Info.WorkOrderId}
                                            readOnly={this.state.isReadOnlyForm}
                                            defaultValue={this.Info.Comments || undefined}
                                        ></TextArea>
                                    </div>
                                    <div
                                        style={{
                                            width: "120px",
                                            textAlign: "right",
                                            marginTop: IS_IE ? -8 : 0,
                                        }}
                                    >
                                        <div className={styles.CheckboxRow}>
                                            <Checkbox
                                                id="AllowOvernight"
                                                defaultChecked={this.Info.AllowOvernight}
                                                label="Allow Overnight"
                                                className={checkBoxClassName}
                                                onChange={this.OnChangeCheckbox}
                                                disabled={
                                                    this.state.isReadOnlyForm || !this.Info.WorkOrderId
                                                }
                                                dir={"RTL"}
                                            />
                                        </div>
                                        <div className={styles.CheckboxRow}>
                                            <Checkbox
                                                id="RemoteHotel"
                                                defaultChecked={this.Info.RemoteHotel}
                                                label="Remote Hotel"
                                                className={checkBoxClassName}
                                                onChange={this.OnChangeCheckbox}
                                                disabled={
                                                    this.state.isReadOnlyForm || !this.Info.WorkOrderId
                                                }
                                                dir={"RTL"}
                                            />
                                        </div>
                                        <div className={styles.CheckboxRow}>
                                            <Checkbox
                                                id="PrevailingWage"
                                                defaultChecked={this.Info.PrevailingWage}
                                                label="Prevailing Wage"
                                                className={checkBoxClassName}
                                                onChange={this.OnChangeCheckbox}
                                                disabled={
                                                    this.state.isReadOnlyForm || !this.Info.WorkOrderId
                                                }
                                                dir={"RTL"}
                                            />
                                        </div>
                                        <div className={styles.CheckboxRow}>
                                            <span>Work Type:&nbsp;</span>
                                            {this.Info.WorkTypeTitle}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{display: "flex", justifyContent: "space-between"}}
                                >
                                    <div style={{width: "225px"}}>
                                        <div className={styles.TimePickerRow}>
                                            <div className={`${styles.TimePickerLabel}`}>
                                                ClockIn Expected
                                            </div>
                                            <TimePicker
                                                className={styles.TimePicker}
                                                formatPlaceholder={{hour: "hh", minute: "mm"}}
                                                id="ClockInAfter"
                                                // @ts-ignore
                                                defaultValue={this.Info.ClockInAfter}
                                                onChange={this.OnChangeTime}
                                                disabled={
                                                    this.state.isReadOnlyForm || !this.Info.WorkOrderId
                                                }
                                                dateInput={this.renderDateTimeInput}
                                            />
                                        </div>
                                        <div className={styles.TimePickerRow}>
                                            <div className={`${styles.TimePickerLabel}`}>
                                                ClockOut Expected
                                            </div>
                                            <TimePicker
                                                className={styles.TimePicker}
                                                formatPlaceholder={{hour: "hh", minute: "mm"}}
                                                id="ClockOutBefore"
                                                // @ts-ignore
                                                defaultValue={this.Info.ClockOutBefore}
                                                onChange={this.OnChangeTime}
                                                disabled={
                                                    this.state.isReadOnlyForm || !this.Info.WorkOrderId
                                                }
                                                dateInput={this.renderDateTimeInput}
                                            />
                                        </div>
                                    </div>
                                    <div style={{width: "315px"}}>
                                        <div className={styles.TimePickerRow}>
                                            <div className={styles.TimePickerLabel}>
                                                Arrival Expected
                                            </div>
                                            <DropDownList
                                                className={styles.TimePickerDropDown}
                                                data={["Before", "After"]}
                                                id="ArriveType"
                                                defaultValue={this.Info.ArriveType}
                                                disabled={
                                                    this.state.isReadOnlyForm || !this.Info.WorkOrderId
                                                }
                                                onChange={this.OnChangeTimeType}
                                            />
                                            <TimePicker
                                                className={styles.TimePicker}
                                                id="Arrive"
                                                formatPlaceholder={{hour: "hh", minute: "mm"}}
                                                // @ts-ignore
                                                defaultValue={this.Info.Arrive}
                                                onChange={this.OnChangeTime}
                                                disabled={
                                                    this.state.isReadOnlyForm || !this.Info.WorkOrderId
                                                }
                                                dateInput={this.renderDateTimeInput}
                                            />
                                        </div>
                                        <div className={styles.TimePickerRow}>
                      <span className={styles.TimePickerLabel}>
                        Departure Expected
                      </span>
                                            <DropDownList
                                                className={styles.TimePickerDropDown}
                                                data={["Before", "After"]}
                                                id="DepartType"
                                                defaultValue={this.Info.DepartType}
                                                disabled={
                                                    this.state.isReadOnlyForm || !this.Info.WorkOrderId
                                                }
                                                onChange={this.OnChangeTimeType}
                                            />
                                            <TimePicker
                                                className={styles.TimePicker}
                                                id="Depart"
                                                formatPlaceholder={{hour: "hh", minute: "mm"}}
                                                // @ts-ignore
                                                defaultValue={this.Info.Depart}
                                                onChange={this.OnChangeTime}
                                                disabled={
                                                    this.state.isReadOnlyForm || !this.Info.WorkOrderId
                                                }
                                                dateInput={this.renderDateTimeInput}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {this.HasWarnings() && (
                                    <div className={styles.WarningsList}>
                                        {this.props.newDispatch &&
                                            !!this.ScheduledWODates.length && (
                                                <Card
                                                    className={`${styles.WarningInfoCard} k-card-warning`}
                                                >
                                                    <div className={styles.WarningInfoRow}>
                                                        <span>WO is already scheduled for</span>
                                                        {this.ScheduledWODates.map((item) => {
                                                            return (
                                                                <>
                                                                    &nbsp;
                                                                    <OpenCardLink
                                                                        key={item.Date}
                                                                        onClick={this.ChangeDispatchCard}
                                                                        dataAttr={item.DispatchId}
                                                                        style={{textDecoration: "underline"}}
                                                                        text={item.Date}
                                                                    />
                                                                </>
                                                            );
                                                        })}
                                                    </div>
                                                </Card>
                                            )}
                                        {this.HasCrewLeadWarning() && (
                                            <Card
                                                className={`${styles.WarningInfoCard} k-card-warning`}
                                            >
                                                {this.renderEmployeeWarning(this.Info.CrewLeadId!)}
                                            </Card>
                                        )}
                                        {this.HasCrewWarnings() && (
                                            <Card
                                                className={`${styles.WarningInfoCard} k-card-warning`}
                                            >
                                                {this.Info.CrewIDs.split(",").map(
                                                    (id) =>
                                                        !!this.ScheduledEmployees[+id] &&
                                                        this.renderEmployeeWarning(+id)
                                                )}
                                            </Card>
                                        )}
                                        {this.HasReasonWarnings() && this.renderReasonWarnings()}
                                    </div>
                                )}
                                <div className={`${styles.FormFooter} k-action-buttons`}>
                                    <Button onClick={this.Close}>Cancel</Button>
                                    {this.props.newDispatch && (
                                        <>
                                            <Button
                                                onClick={this.AddDispatchOnly}
                                                themeColor="primary"
                                                disabled={
                                                    !this.Info.WorkOrderId || !this.state.selectedCrewLead
                                                }
                                            >
                                                Create
                                            </Button>
                                            <Button
                                                onClick={this.AddAndPublishDispatch}
                                                themeColor="primary"
                                                disabled={
                                                    !this.Info.WorkOrderId || !this.state.selectedCrewLead
                                                }
                                            >
                                                Create and Publish
                                            </Button>
                                        </>
                                    )}

                                    {!this.props.newDispatch && (
                                        <>
                                            <Button
                                                onClick={this.AddDispatchOnly}
                                                themeColor="primary"
                                                disabled={!this.state.selectedCrewLead}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                onClick={this.AddAndPublishDispatch}
                                                themeColor="primary"
                                                disabled={
                                                    !this.Info.CanPublish || !this.state.selectedCrewLead
                                                }
                                            >
                                                Save and Publish
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </Fragment>
                        </>
                    )}
                </div>
            </FXCard>
        );
    }

    renderTitle = () => {
        if (this.props.newDispatch) return "New Dispatch";
        return (
            <>
                <OpenCardIconLink onClick={this.OpenDispatch} title="Open Dispatch"/>
                <span className={styles.HeaderTitle}>Edit Dispatch</span>
            </>
        );
    };

    renderDateTimeInput = (props: any) => (
        <ClearableDateInput defaultProps={props} clear={this.OnChangeTime}/>
    );

    renderEmployeeListItem = (
        el: React.ReactElement<
            HTMLLIElement,
            string | React.JSXElementConstructor<any>
        >,
        props: any
    ) => {
        let id = props.dataItem.Id;
        let name = props.dataItem.Name;
        let isLimited = props.dataItem.IsLimited;
        let isScheduled = false;
        let scheduledItem = this.ScheduledEmployees[id];
        let scheduledReasonItem = this.ScheduledEmployeesReasons[id];
        let warnings = [];
        if (isLimited)
            warnings.push(
                `Restricted from regular activities due to expired certifications or injury. Please contact HR Manager before scheduling`
            ); // used twice
        if (scheduledItem) {
            warnings.push(
                `Already scheduled for another WOs for ${scheduledItem.Dates.join(
                    ", "
                )}`
            );
            isScheduled = true;
        }
        if (scheduledReasonItem) {
            for (let item of scheduledReasonItem) {
                warnings.push(
                    `${item.Name} is not available for dispatching ${item.Date} reason ${item.Reason}`
                );
                isScheduled = true;
            }
        }
        if (warnings.length || isLimited) {
            let itemStyle: simpleObject = el.props.style;
            //@ts-ignore
            let onClick = el.props.onClick;
            return (
                <li
                    key={id}
                    className={el.props.className}
                    style={itemStyle}
                    id={el.props.id}
                    onClick={
                        isLimited
                            ? (e: any) => {
                                this.OnSelectEmployee(e, onClick);
                            }
                            : onClick
                    }
                >
                    <div title={warnings.join(",/n ")} style={{width: "100%"}}>
                        <Tooltip
                            style={{maxWidth: "300px"}}
                            parentTitle={true}
                            content={this.renderEmployeeTooltipContent}
                        >
                            <div style={{display: "flex", alignItems: "center"}}>
                                {name}
                                <span className={styles.WarningListItemIcons}>
                  {isLimited && (
                      <span
                          className={`mdi mdi-exclamation-thick ${styles.ExclamationListItemIcon}`}
                      ></span>
                  )}
                                    {isScheduled && (
                                        <span
                                            className={`mdi mdi-alert-outline ${styles.AlertListItemIcon}`}
                                        ></span>
                                    )}
                </span>
                            </div>
                        </Tooltip>
                    </div>
                </li>
            );
        }
        return el;
    };

    renderEmployeeWarning = (employeeId: number) => {
        let data = this.ScheduledEmployees[employeeId];
        return (
            <div className={styles.WarningInfoRow} key={employeeId}>
                {data.Name}&nbsp;is already scheduled for another WOs for&nbsp;
                {data.Dates.join(", ")}
            </div>
        );
    };

    renderReasonWarnings = () => {
        let ids = this.Info.CrewIDs ? this.Info.CrewIDs.split(",") : [];
        if (this.Info.CrewLeadId) ids.push(this.Info.CrewLeadId.toString());
        return (
            <Card className={`${styles.WarningInfoCard} k-card-warning`}>
                {ids.map((id) => this.renderEmployeeReasonWarning(+id))}
            </Card>
        );
    };

    renderEmployeeReasonWarning = (employeeId: number) => {
        let data = this.ScheduledEmployeesReasons[employeeId];
        if (!data) return null;
        return data.map((item) => (
            <div className={styles.WarningInfoRow} key={item.Date + employeeId}>
                {item.Name}&nbsp;is not available for dispatching,&nbsp;{item.Date}
                &nbsp;reason&nbsp;{item.Reason}
            </div>
        ));
    };

    renderEmployeeTooltipContent = (e: any) => {
        let warnings = e.title.split(",/n ");
        return (
            <div>
                {warnings.map((warning: string, i: number) => {
                    return <div key={i}>{warning}.</div>;
                })}
            </div>
        );
    };

    renderTagSelectedCrewMember = (tagData: TagData, tag: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => {
        const props = tag.props
        const id = tagData.data[0].Id
        const isActive = tagData.data[0].IsActive
        if (isActive === true || isActive === undefined) return tag

        return <Chip
            key={id + ''}
            {...props}
            fillMode="outline"
            className={styles.CrewLeadChips}
            style={{opacity: 0.5}}
        />
    }

    OnSelectEmployee = (e: any, onClick: any) => {
        let classname = e.currentTarget.attributes.class.value;
        let employee = e.target.textContent;
        if (classname.indexOf("k-selected") === -1) {
            ModalRef.showDialog({
                title: "Warning",
                text: `${employee} restricted from regular activities due to expired certifications or injury. Please contact HR Manager before scheduling.`, // used twice
                type: "warning",
            });
        }
        onClick(e);
    };

    HasReasonWarnings = () => {
        let ids = this.Info.CrewIDs ? this.Info.CrewIDs.split(",") : [];
        if (this.Info.CrewLeadId) ids.push(this.Info.CrewLeadId.toString());
        let hasWarnings =
            ids.length &&
            ids.findIndex((id) => !!this.ScheduledEmployeesReasons[+id]) > -1;
        return !!hasWarnings;
    };

    ChangeDispatchCard = (e: any, dispatchid: number) => {
        CardManagement.OpenDispatchCard({
            newDispatch: false,
            dsId: dispatchid,
            afterSave: this.props.afterSave,
        });
        this.Close();
    };

    HasWarnings = () => {
        return (
            !!this.ScheduledWODates.length ||
            this.HasCrewLeadWarning() ||
            this.HasCrewWarnings() ||
            this.HasReasonWarnings()
        );
    };

    HasCrewLeadWarning = () => {
        return (
            !!this.Info.CrewLeadId && !!this.ScheduledEmployees[this.Info.CrewLeadId]
        );
    };

    HasCrewWarnings = () => {
        return (
            !!this.Info.CrewIDs.length &&
            this.Info.CrewIDs.split(",").findIndex(
                (id) => !!this.ScheduledEmployees[+id]
            ) > -1
        );
    };

    OnChangeTimeType = (e: any) => {
        let field: "ArriveType" | "DepartType" = e.target.props.id;
        this.Info[field] = e.value;
    };

    OnChangeTime = (e: any, isClear?: boolean) => {
        let field: "Arrive" | "Depart" | "ClockInAfter" | "ClockOutBefore" =
            e.target.props.id;
        this.Info[field] = isClear ? undefined : e.value;
        if (isClear) this.setState({remountKey: +new Date()});
    };

    OnChangeComments = (e: any) => {
        this.Info.Comments = e.value;
    };

    OnChangeMultiselect = (selectedCrewMembers?: Array<IEmployeeItem>) => {
        this.Info.CrewIDs = selectedCrewMembers
            ? selectedCrewMembers.map((item) => item.Id).join(",")
            : "";
        this.setState({selectedCrewMembers});
    };

    OnChangeCheckbox = (e: any) => {
        let field:
            | "WorkDaysOnly"
            | "PrevailingWage"
            | "RemoteHotel"
            | "AllowOvernight" = e.target.element.id;
        this.Info[field] = e.value;
    };

    OnDateChange = async (e: any) => {
        let field: "DateTo" | "Date" = e.target.props.id;
        this.Info[field] = e.value;
        let result = await this.LoadWarningsInfo();
        if (result) {
            this.CheckWarnings(result);
        }
    };

    OnChangeWO = async (value: IWOItem | null) => {
        this.setState({loading: true});
        this.Info.WorkOrderId = value ? +value.Id : null;
        if (value) {
            await this.GetSetAvailableResources();
            if (this.DSId) this.DSId = null;
            this.Info.AllowOvernight = !!value.AllowOvernight;
            this.Info.PrevailingWage = !!value.PrevailingWage;
            this.Info.WorkTypeTitle = value.WorkTypeTitle;
        } else {
            this.ScheduledEmployees = [];
            this.ScheduledWODates = [];
        }
        this.setState({selectedWO: value, loading: false});
    };

    OnChangeCrew = (value: ICrewItem | null) => {
        let selectedCrewLead;
        const selectedCrewMembers: IEmployeeItem[] = [];
        this.Info.CrewContainerId = value ? +value.Id : null;
        if (value) {
            for (let item of this.state.resources) {
                if (
                    value.CrewLeadId &&
                    !selectedCrewLead &&
                    item.Id === value.CrewLeadId
                ) {
                    selectedCrewLead = item;
                    continue;
                }
                if (
                    value.Members &&
                    value.Members.length !== selectedCrewMembers.length &&
                    value.Members.indexOf(+item.Id) > -1
                ) {
                    selectedCrewMembers.push(item);
                }
                if (
                    (!value.CrewLeadId || selectedCrewLead) &&
                    (!value.Members ||
                        value.Members.length === selectedCrewMembers.length)
                ) {
                    break;
                }
            }
        }
        this.OnChangeMultiselect(selectedCrewMembers);
        this.OnChangeCrewLead(selectedCrewLead || null);
        this.setState({selectedCrewContainer: value})
    };

    OnChangeCrewLead = (value: IEmployeeItem | null) => {
        this.Info.CrewLeadId = value ? +value.Id : null;
        this.setState({selectedCrewLead: value});
    };

    GetSetAvailableResources = async () => {
        if (!this.Info.WorkOrderId) return;
        try {
            let results: Array<any> = await Promise.allSettled([
                this.LoadAvailableResources(),
                this.LoadWarningsInfo(),
            ]);
            let availableResources: Array<{
                Id: number;
                IsLimited: boolean;
            }> = results[0].value[0];
            let crews = results[0].value[1] || [];
            let crewMembers = results[0].value[2] || [];
            let warningsInfo = results[1].value;
            if (!this.props.newDispatch || this.DSId || this.props.crewLeadId) {
                for (let {CrewMemberId} of this.InitialCrew) {
                    if (
                        !this.props.newDispatch &&
                        !availableResources.find((item) => item.Id === CrewMemberId)
                    )
                        availableResources.push({
                            Id: CrewMemberId,
                            IsLimited: false,
                        });
                }
                if (
                    this.InitialCrewLeadId &&
                    !this.props.newDispatch &&
                    !availableResources.find((item) => item.Id === this.InitialCrewLeadId)
                ) {
                    availableResources.push({
                        Id: this.InitialCrewLeadId,
                        IsLimited: false,
                    });
                }
            }

            let allResources = this.AllResources || [];
            let resources: Array<IEmployeeItem> = [];

            for (let resource of allResources) {
                let res = availableResources.find((item) => item.Id === resource.Id);
                if (res) resources.push({...res, Name: resource.Name});
            }

            let selectedCrewLead;
            if (this.InitialCrewLeadId) {
                selectedCrewLead = resources.find(
                    (item: IComboboxItem) => item.Id === this.InitialCrewLeadId
                );
            }
            let crewIds = [];
            const selectedCrewMembers: IEmployeeItem[] = [];
            for (let {CrewMemberId, CrewMemberName} of this.InitialCrew) {
                const crewMember = resources.find(
                    (item: IComboboxItem) => item.Id === CrewMemberId
                )
                selectedCrewMembers.push({
                    Id: CrewMemberId,
                    Name: CrewMemberName,
                    IsLimited: crewMember?.IsLimited || true,
                    IsActive: !!crewMember
                });
                crewIds.push(CrewMemberId);
            }
            this.Info.CrewIDs = crewIds.join(",");
            this.Info.CrewLeadId = selectedCrewLead ? +selectedCrewLead.Id : null;
            crews.forEach((crew: ICrewItem) => {
                crewMembers.forEach((item: ICrewMemberItem) => {
                    if (!crew.Members) crew.Members = [];
                    if (crew.Id === item.CrewContainerId)
                        crew.Members.push(item.CrewMemberId);
                });
            });
            if (warningsInfo) this.CheckWarnings(warningsInfo);
            this.setState({
                resources,
                selectedCrewLead: selectedCrewLead || null,
                selectedCrewMembers,
                crews,
            });
        } finally {
        }
    };

    LoadAvailableWorkOrders = () => {
        return this.GetSQLData({
            spName: "AddDispatchAction_WorkOrders",
            params: {bpId: this.props.buildPlanId},
        });
    };

    LoadAvailableResources = () => {
        return this.GetSQLData({
            spName: "AddDispatchAction_Resources",
            params: {woId: this.Info.WorkOrderId},
        });
    };

    LoadDispatchInfo = () => {
        if (!this.props.dsId) return;
        return this.GetSQLData({
            spName: "AddDispatchAction_DispatchInfo",
            params: {dsId: this.props.dsId},
        });
    };

    LoadEmployees = async () => {
        if (this.AllResources) return;
        let result = await ReferenceRecordsDataSource("Employees");
        this.AllResources = result[0];
    };

    AddDispatchOnly = () => {
        this.AddDispatch(false);
    };

    AddAndPublishDispatch = () => {
        this.AddDispatch(true);
    };

    LoadWarningsInfo = () => {
        if (!this.Info.WorkOrderId) return;
        let dateFrom = this.Info.Date
            ? moment(this.Info.Date).format().slice(0, 19)
            : null;
        let dateTo = this.Info.DateTo
            ? moment(this.Info.DateTo).format().slice(0, 19)
            : null;
        return this.GetSQLData({
            spName: "AddDispatchAction_WarningsInfo",
            params: {
                woId: this.Info.WorkOrderId,
                dateFrom,
                dateTo: dateTo || dateFrom,
            },
        });
    };

    CheckWarnings = async (
        result: [
            Array<{ Date: string; DispatchId: number }>,
            Array<{ EmployeeId: number; Date: string }>,
            Array<{ EmployeeId: number; Date: string; ReasonName: string }>
        ]
    ) => {
        this.ScheduledWODates = result[0].map((item) => ({
            Date: moment(item.Date).format("L"),
            DispatchId: item.DispatchId,
        }));
        this.ScheduledEmployees = {};
        let list = result[1];
        for (let item of list) {
            let id: number = item.EmployeeId;
            if (!this.ScheduledEmployees[id]) {
                this.ScheduledEmployees[id] = {
                    Name:
                        this.AllResources?.find((resource) => resource.Id === id)?.Name ||
                        "",
                    Dates: [],
                };
            }
            this.ScheduledEmployees[id].Dates.push(moment(item.Date).format("L"));
        }

        this.ScheduledEmployeesReasons = {};
        let list2 = result[2];
        for (let item of list2) {
            let id: number = item.EmployeeId;
            if (!this.ScheduledEmployeesReasons[id]) {
                this.ScheduledEmployeesReasons[id] = [];
            }
            let Name =
                this.AllResources?.find((resource) => resource.Id === id)?.Name || "";
            this.ScheduledEmployeesReasons[id].push({
                Name,
                Date: moment(item.Date).format("L"),
                Reason: item.ReasonName,
            });
        }
        this.forceUpdate();
    };

    AddDispatch = async (Publish: boolean) => {
        let dispatchInfo = this.Info;
        if (
            !dispatchInfo.WorkOrderId ||
            !dispatchInfo.CrewLeadId ||
            !dispatchInfo.Date
        )
            return;
        try {
            this.setState({loading: true});
            let timeFormat = "LT";
            let params: simpleObject = {
                WOId: dispatchInfo.WorkOrderId,
                CrewContainerID: dispatchInfo.CrewContainerId || null,
                CrewLeadID: dispatchInfo.CrewLeadId || null,
                CrewIDs: this.Info.CrewIDs || null,
                DateFrom: dispatchInfo.Date
                    ? moment(dispatchInfo.Date).format("DD.MM.YYYY")
                    : null,
                DateTo: dispatchInfo.DateTo
                    ? moment(dispatchInfo.DateTo).format("DD.MM.YYYY")
                    : null,
                WorkDaysOnly: dispatchInfo.WorkDaysOnly,
                Publish,
                AllowOvernight: dispatchInfo.AllowOvernight,
                RemoteHotel: dispatchInfo.RemoteHotel,
                PrevailingWage: dispatchInfo.PrevailingWage,
                Comments: dispatchInfo.Comments,
                ClockInAfter: dispatchInfo.ClockInAfter
                    ? moment(dispatchInfo.ClockInAfter).format(timeFormat)
                    : null,
                ClockOutBefore: dispatchInfo.ClockOutBefore
                    ? moment(dispatchInfo.ClockOutBefore).format(timeFormat)
                    : null,
                ["Depart" + dispatchInfo.DepartType]: dispatchInfo.Depart
                    ? moment(dispatchInfo.Depart).format(timeFormat)
                    : null,
                ["Arrive" + dispatchInfo.ArriveType]: dispatchInfo.Arrive
                    ? moment(dispatchInfo.Arrive).format(timeFormat)
                    : null,
            };

            await RunScriptAsync("Calendar_AddDispatch", params);
            if (this.props.afterSave) this.props.afterSave();
            if (this.props.finally) this.props.finally();
        } finally {
            this.setState({loading: false});
        }
    };

    Close = () => {
        if (this.props.finally) this.props.finally();
    };

    OpenDispatch = () => {
        if (this.props.dsId) OpenRecord("FSMDispatchSchedule", this.props.dsId);
    };
}

export default DispatchCard;
