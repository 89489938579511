import {Grid, GridColumn as Column} from '@progress/kendo-react-grid';
import {process} from '@progress/kendo-data-query';
import {ProgressBar} from "@progress/kendo-react-progressbars";

import {OpenJob} from '../../../helpers/runscripts';
import {simpleObject} from '../../../helpers/interfaces';
import BaseComponent from '../../BaseComponent';

import OpenCardLink from '../../Common/Buttons/OpenCardLink';
import AccessDenied from '../../Common/AccessDenied';
import NoSelectedBP from '../../Common/NoSelectedBP';
import LoaderComponent from '../../Common/Loader';
import commonStyles from '../../../assets/styles/common.module.scss';
import styles from './budget.module.scss';
import {IBPItem, sectionName} from '../../../Pages/BudgetDashboard/interfaces';
import {getValues, IsGroupWithTotal, IsRevenueGroup, OpenBudget} from '../../../Pages/BudgetDashboard/helpers';
import ButtonLink from '../../Common/Buttons/ButtonLink';
import UserInfo from '../../../stores/User'
import CardManagement from '../../Cards/CardManagement';

interface IRowItemServer {
    Actual: number | null
    Remaining: number | null
    Revised: number | null
    Title: String
    Initial?: number | null
    POBalance?: number | null
}

interface props {
    isActive: boolean
    buildPlanId: number
    isLabor?: boolean
}

interface state {
    loading: boolean
    accessDenied: boolean
    gridData: { data: Array<any>, total: number }
    budgetInfo?: IBPItem | null
}

const columnNotice: { [key: string]: string } = {
    POBalance: 'Remaining Balance of Client POs, Vendor POs and BOMs (as is applicable)',
    Actual: 'Financial Distributions for applicable transactions',
    Remaining: 'Revised Budget - Committed - Actual',
    UsedofPlanned: '(Committed + Actual) / Revised Budget',
    UsedofTotalPlanned: '(Committed + Actual) / Revised Budget Total'
}

class BPBudget extends BaseComponent<props, state> {
    bpId: number = this.props.buildPlanId
    group: Array<{ field: string }> = [{field: 'Section'}]
    sort: Array<{ field: string, dir: 'desc' | 'asc' }> = [{
        field: 'SectionOrder',
        dir: 'asc'
    }]
    expandedGroup: { [key in sectionName]: boolean } = {
        '1. Planned Revenue': false,
        '1. Labor Cost ($)': false,
        '2. Planned Expenses': true,
        '3. Totals': true,
        '4. Profit Margins': true,
        '5. Labor Hours': false
    }
    jobId: number | null = null
    UseEasyBudget: boolean = false

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            gridData: {data: [], total: 0},
            accessDenied: false,
            budgetInfo: null
        }
    }

    async componentDidMount() {
        let settings = await UserInfo.getInfo()
        this.UseEasyBudget = !!settings?.UseEasyBudget;
        this.LoadData();
    }

    componentDidUpdate() {
        if (this.props.isActive && this.bpId !== this.props.buildPlanId) {
            this.bpId = this.props.buildPlanId
            this.LoadData()
        }
    }

    render() {
        if (!this.props.buildPlanId) return <NoSelectedBP/>;
        if (this.state.accessDenied) return <AccessDenied/>;
        if (this.state.loading) return <LoaderComponent/>
        return <Grid
            data={this.state.gridData}
            filterable={false}
            groupable={false}
            group={this.group}
            onExpandChange={this.expandChange}
            expandField="expanded"
            sort={this.sort}
            className={commonStyles.SmallKendoGrid}
            cellRender={this.renderCell}
            rowRender={this.renderGridRow}
            style={{height: '100%'}}
            headerCellRender={this.renderGridHeaderCell}
        >
            <Column
                field="Title"
                title=" "
                headerCell={() => (
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        {!this.UseEasyBudget ?
                            <OpenCardLink
                                text="Open Budget"
                                showOnlyAsLink={true}
                                onClick={this.OpenBudget}/> :
                            <ButtonLink text="Open Budget" onClick={this.OpenEasyBudget}/>
                        }
                        {this.jobId && <OpenCardLink text="Pending Approval Job"
                                                     onClick={this.OpenApprovalJob}/>}
                    </div>)}
                width={220}
            />
            <Column
                field="Pending"
                title="Pending Budget"
                width={100}
            />
            <Column
                field="Initial"
                title="Initial Budget"
                headerClassName={commonStyles.TextCenter}
                width={100}
            />
            <Column
                field="Revised"
                title="Current Budget"
                headerClassName={commonStyles.TextCenter}
                width={100}
            />
            <Column
                field="POBalance"
                title="Committed"
                headerClassName={commonStyles.TextCenter}
                width={100}
            />
            <Column
                field="Actual"
                title="Actual"
                headerClassName={commonStyles.TextCenter}
                width={100}
            />
            <Column
                field="Remaining"
                title="Remaining"
                headerClassName={commonStyles.TextCenter}
                width={100}
            />
            <Column
                field="UsedofPlanned"
                title="% of Planned"
                cell={this.renderCellWithChart}
                headerClassName={commonStyles.TextCenter}
                width={85}
            />
            <Column
                field="UsedofTotalPlanned"
                title="% of Total"
                cell={this.renderCellWithChart}
                headerClassName={commonStyles.TextCenter}
                width={85}
            />
        </Grid>
    }

    expandChange = (event: any) => {
        let GroupName: sectionName = event.dataItem.value
        this.expandedGroup[GroupName] = event.value
        event.dataItem[event.target.props.expandField] = event.value;
        this.forceUpdate()
    }

    async LoadData() {
        try {
            if (!this.props.buildPlanId) return;
            this.setState({loading: true});
            let result: any = await this.GetSQLData({
                spName: 'DB_Budget',
                params: {buildPlanId: this.props.buildPlanId}
            });
            if (!result.length) {
                this.setState({accessDenied: true});
                return;
            }

            let gridData: Array<any> = []
            if (!this.props.isLabor) {
                let plannedRevenue = this.prepareSectionData([{
                    IsBoldRow: true,
                    ...result[2][0]
                }], this.UseEasyBudget ? ' Labor Cost ($)' : ' Planned Revenue', 1, 'financial');

                let plannedExpenses = result[3];
                let hasPlannedExpenses = !!plannedExpenses.length
                let totals: Array<simpleObject> = [];
                let totalPlannedExpenses = this.getTotalRowData(plannedExpenses);
                plannedExpenses.push(totalPlannedExpenses);
                plannedExpenses = this.prepareSectionData(plannedExpenses, 'Planned Expenses', 2, 'financial');

                if (hasPlannedExpenses) {
                    let revenueGross: simpleObject = this.getRevenueGrossRowData(plannedRevenue[0], totalPlannedExpenses);
                    totals.push(revenueGross);
                    let currentWIP = result[6] && result[6].length && result[6][0];
                    if (currentWIP) {
                        totals.push({
                            IsBoldRow: true,
                            ...currentWIP
                        });
                        let adjustedPLRow = {
                            Title: 'Adjusted P&L',
                            Actual: currentWIP.Actual + revenueGross.Actual,
                            Initial: undefined,
                            POBalance: undefined,
                            Remaining: undefined,
                            Revised: undefined,
                            IsBoldRow: true
                        }
                        totals.push(adjustedPLRow);
                    }
                }
                totals = this.prepareSectionData(totals, 'Totals', 3, 'financial');
                let profitMargins = this.prepareSectionData(result[4], 'Profit Margins', 4, 'percentages')
                gridData = [
                    ...plannedRevenue,
                    ...plannedExpenses,
                    ...totals,
                    ...profitMargins,
                ];
            }
            let laborHours = result[5];
            laborHours.push(this.getTotalRowData(laborHours));
            laborHours = this.prepareSectionData(laborHours, 'Labor Hours', 5, 'hr');
            gridData = gridData.concat(laborHours);
            let finalGridData = process(gridData, {
                group: this.group,
                sort: this.sort
            })
            finalGridData.data.forEach((group) => {
                let groupName: sectionName = group.value
                group.expanded = this.expandedGroup[groupName]
            })
            if (typeof result[7] === 'object') {
                this.jobId = result[7].ApprovalJobId
            } else {
                this.jobId = null
            }
            this.setState({gridData: finalGridData, budgetInfo: result[0][0]});
        } finally {
            this.setState({loading: false})
        }
    }

    prepareSectionData = (data: Array<simpleObject>, name: string, order: number, format: string) => {
        let result = data.map((item: simpleObject) => {
            item.Section = `${order}. ${name}`;
            item.Format = format;
            return item;
        })
        return result;
    }

    getTotalRowData = (data: Array<any>) => {
        // TODO if data.length ??
        let rowTotal: simpleObject = {
            Title: 'Total',
            IsTotalRow: true,
            IsBoldRow: true
        };
        data.forEach((item: IRowItemServer) => {
            let key: keyof typeof item;
            for (key in item) {
                if (key !== 'Title' && (item[key] || item[key] === 0)) {
                    if (!rowTotal[key]) rowTotal[key] = 0;
                    rowTotal[key] += item[key];
                } else if (!rowTotal[key] && rowTotal[key] !== 0) {
                    rowTotal[key] = item[key];
                }
            }
        });
        return rowTotal;
    }

    getRevenueGrossRowData = (plannedRevenue: simpleObject, totalPlannedRevenue: simpleObject) => {
        let revenueGross: simpleObject = {
            Actual: undefined,
            Initial: undefined,
            POBalance: undefined,
            Revised: undefined
        }
        let key: keyof typeof revenueGross;
        for (key in revenueGross) {
            if (
                plannedRevenue[key] !== null &&
                plannedRevenue[key] !== undefined &&
                totalPlannedRevenue[key] !== null &&
                totalPlannedRevenue !== undefined
            ) {
                revenueGross[key] = plannedRevenue[key] - totalPlannedRevenue[key];
            } else if (totalPlannedRevenue[key] || totalPlannedRevenue[key] === 0) {
                revenueGross[key] = 0 - totalPlannedRevenue[key];
            } else if (plannedRevenue[key] || plannedRevenue[key] === 0) {
                revenueGross[key] = plannedRevenue[key];
            } else {
                revenueGross[key] = null;
            }
        }
        return {
            Title: 'Total Revenue Gross Profit Margin $',
            IsBoldRow: true,
            ...revenueGross
        };
    }

    renderGridRow = (row: any, props: any) => {
        if (props.rowType !== 'groupHeader' && IsRevenueGroup(props.dataItem.Section) && props.dataItem.Title === 'Total') {
            return null
        }
        let className = row.props.className.indexOf('k-alt') > -1 ? 'k-master-row' : row.props.className
        return (
            <tr className={className}>
                {row.props.children}
            </tr>
        )
    }

    renderGridHeaderCell = (el: any, props: any) => {
        return <div title={columnNotice[props.field] || ''}>{el}</div>
    }

    renderCellWithChart = (props: simpleObject) => {
        let columnField = props.field
        let groupName: sectionName = props.rowType === 'groupHeader' ? props.dataItem.value : props.dataItem.Section
        if (props.rowType === 'groupHeader') {
            if (
                (!IsGroupWithTotal(groupName) && !IsRevenueGroup(groupName)) ||
                (IsGroupWithTotal(groupName) && this.expandedGroup[groupName])
            ) {
                return null
            }
        }
        if (
            (props.rowType !== 'groupHeader' && !IsGroupWithTotal(groupName) && !IsRevenueGroup(groupName)) ||
            (props.rowType === 'groupHeader' && props.field === 'UsedofTotalPlanned' && this.expandedGroup[groupName])
        ) {
            return <td></td>
        }


        let values: { total: number | null, value: number | null } = {
            total: null,
            value: null
        }

        let rowData = null
        if (props.rowType === 'groupHeader') {
            if (IsRevenueGroup(groupName)) {
                rowData = props.dataItem.items[0]
            } else if (IsGroupWithTotal(groupName)) {
                if (this.expandedGroup[groupName]) return null
                let totalRow = props.dataItem.items[props.dataItem.items.length - 1]
                if (totalRow && totalRow.IsTotalRow) rowData = totalRow
            }
        } else {
            rowData = props.dataItem
        }
        let sectionName = rowData.Section
        let sectionData = this.state.gridData.data.find((section) => section.value === sectionName)
        values = getValues(rowData, columnField, sectionData?.items) || values

        let percentage = values.value !== null && values.total ? (values.value / values.total * 100) : null
        if (percentage === null) return <td></td>
        percentage = +(percentage.toFixed(0))
        let isRed = IsRevenueGroup(groupName) ? percentage < 100 : percentage > 100
        return (
            <td className={commonStyles.TextRight} style={{padding: 0}}>
                <ProgressBar
                    min={0}
                    max={100}
                    value={percentage}
                    label={(props) => (
                        <span>{percentage !== null && (isNaN(percentage) ? 'n/a' : percentage + '%')}</span>)} // ??
                    className={`${styles.ProgressBar} ${isRed ? styles.ProgressBarOver : ''}`}
                />
            </td>
        )
    }

    renderCell = (el: any, props: simpleObject) => {
        let field = props.field
        let value = props.dataItem[field];
        let style: simpleObject = {};
        if (props.dataItem.IsBoldRow) style.fontWeight = '700';
        let format;
        let groupName: sectionName = props.rowType === 'groupHeader' ? props.dataItem.value : props.dataItem.Section
        if (props.rowType === 'groupHeader') {
            if (el) {
                let colspan = ((IsGroupWithTotal(groupName) && !this.expandedGroup[groupName]) || IsRevenueGroup(groupName)) ? 2 : el.props.colSpan
                return <td colSpan={colspan}>{el.props.children}</td>
            } else if (
                field === 'Title' ||
                (IsGroupWithTotal(groupName) && this.expandedGroup[groupName]) ||
                (!IsGroupWithTotal(groupName) && !IsRevenueGroup(groupName))
            ) {
                return null
            } else {
                let row;
                if (IsRevenueGroup(groupName)) {
                    row = props.dataItem.items[0]
                    value = row[field]
                } else if (IsGroupWithTotal(groupName)) {
                    row = props.dataItem.items[props.dataItem.items.length - 1]
                    if (row && row.IsTotalRow) {
                        value = row[field]
                    }
                }
                format = row.Format
            }
        } else {
            format = props.dataItem.Format
        }


        let defProps = el ? el.props : {}
        if (field === 'Remaining') {
            if (IsRevenueGroup(groupName)) style.color = value > 0 ? 'red' : 'green';
            else style.color = value > 0 ? 'green' : 'red';
        }
        return (
            <td
                {...defProps}
                className={`${field !== 'Title' && commonStyles.TextRight} ${(field === 'Revised' || field === 'Actual') && styles.MarkedColumn}`}
                style={style}
            >{field === 'Title' ? value : this.formatValueCell(value, format)}
            </td>
        )
    }

    formatValueCell = (value: number | null | undefined, format: string) => {
        let valueString = '';
        if (value === null) {
            valueString = '--';
        } else if (value === undefined) {
            valueString = '';
        } else {
            if (format === 'financial') {
                valueString = '$' + this.formatFinancial(value);
            } else {
                let sign = format === 'percentages' ? '%' : 'hr';
                valueString = value.toFixed(2) + sign;
            }
        }
        return valueString;
    }

    formatFinancial = (value: number) => {
        var val = +Math.abs(value).toFixed(2);
        var format = val.toLocaleString('en');
        var float = format.split('.')[1];
        float = float ? (float.length === 2 ? float : float + '0') : '00';
        var result = format.split('.')[0] + '.' + float;
        if (value < 0) {
            return '(' + result + ')';
        }
        return result;
    }

    OpenBudget = () => {
        OpenBudget(this.props.buildPlanId, this.Refresh)
    }

    OpenEasyBudget = () => {
        CardManagement.EasyBudgetCard(this.props.buildPlanId, this.Refresh)
    }

    OpenApprovalJob = () => {
        if (this.jobId) OpenJob(this.jobId)
    }

    Refresh = () => {
        this.LoadData()
    }
}

export default BPBudget;
