import {Button} from '@progress/kendo-react-buttons';
import {useContext} from 'react';
import {simpleObject} from '../../../helpers/interfaces';
import {OpenRecord} from '../../../helpers/runscripts';
import {IntegrationWithDesktopContext, isConterra} from '../../../helpers/settings';
import CardManagement from '../../Cards/CardManagement';

interface props {
    text: string
    dataAttr?: string | number | null
    refName?: string
    params?: simpleObject
    style?: simpleObject
    className?: string
    isBtn?: boolean
    showOnlyAsLink?: boolean

    onClick?(e?: React.BaseSyntheticEvent, dataAttr?: any, refName?: any): any

    refresh?(): any
}

export default function OpenCardLink(props: props) {
    const isLinkToWebCard = props.refName === 'FSMBuildPlans' || props.refName === 'TKTimeCards' || props.refName === 'FSMSites'
    const theme = useContext(IntegrationWithDesktopContext);
    const onClick = (event: React.BaseSyntheticEvent) => {
        let id = props.dataAttr
        let refName = props.refName
        if (props.onClick) props.onClick(event, id, refName);
        else if (id && refName) {
            if (!isLinkToWebCard || isConterra) OpenRecord(refName, +id, props.params)
            else {
                if (props.refName === 'TKTimeCards') CardManagement.OpenTCCard(+id, props.refresh)
                else if (props.refName === 'FSMBuildPlans') CardManagement.OpenBPCard(+id, props.refresh)
                else if (props.refName === 'FSMSites') CardManagement.OpenSiteCard({
                    siteId: +id, onFinish: props.refresh
                })
            }
        }
    };
    let showAsLink = isConterra || isLinkToWebCard || theme.isIntegrationWithDesktop

    if (props.showOnlyAsLink && !showAsLink) return null
    let className = showAsLink ? 'link' : ''
    if (props.className) className += ` ${props.className}`
    if (props.isBtn) {
        return <Button
            icon="hyperlink-open"
            title={props.text}
            style={props.style}
            onClick={onClick}
        />
    }
    return (
        <span className={className}
              style={{position: 'relative', pointerEvents: 'all', ...props.style}}
              onClick={showAsLink ? onClick : undefined}
        >{props.text}</span>
    );
}
