import {Component} from 'react'
import InventoryDashboardTabs from '../Components/Tabs/IDTabs'
import style from '../Components/Dashboard/dashboard.module.scss'
import BOMDashboard from "../Components/BP/BOMDashboard"
import InventoryBalance from "../Components/Dashboards/InventoryBalance"
import Materials from "../Components/Dashboards/Materials"
import Utilization from "../Components/Dashboards/Utilization"
import {tabId} from '../Components/Tabs/interfaces'
import {IComboboxItem} from '../helpers/interfaces'

const TABS: Array<{
  Name: string;
  Id: tabId;
  Component: React.ComponentClass<{
    isActive: boolean
    switchTab(tabId: tabId): void
    warehouses?: Array<IComboboxItem>
  }, any>;
}> = [
  {Id: 'BOM', Name: 'BOM', Component: BOMDashboard},
  {
    Id: 'InventoryBalance',
    Name: 'Inventory Balance',
    Component: InventoryBalance
  },
  {Id: 'Materials', Name: 'Materials', Component: Materials},
  {Id: 'Utilization', Name: 'Utilization', Component: Utilization}
]

class InventoryDashboard extends Component<{ isActive: boolean }> {
  render() {
    return <div className={style.Container}>
      <InventoryDashboardTabs
        isActive={this.props.isActive}
        tabs={TABS}
      />
    </div>
  }
}

export default InventoryDashboard
