import React, {Component} from 'react';
import DashboardGrid from '../Dashboard/Grid'
import {IColumnSetting, IFilterSetting} from '../Dashboard/interfaces'
import {IComboboxItem, simpleObject} from '../../helpers/interfaces';
import TransactionJournal from "./TransactionJournal"
import ButtonLink from '../Common/Buttons/ButtonLink';
import {tabId} from '../Tabs/interfaces'
import {LoadMaterialCategories, LoadWarehouses} from '../../helpers/LoadLists';

interface props {
    materialId?: number
    isActive: boolean
    isCard?: boolean
    isModalCard?: boolean

    switchTab(tabId: tabId): void

    onChangeGridData?(data: Array<simpleObject>): void
}

interface state {
    locationId: number | null
    materialId: number | null
}

const FILTERS: Array<IFilterSetting> = [
    {
        id: 'filterMaterial',
        placeholder: 'Filter by Material',
        type: 'combobox',
        width: 300,
        serverFilter: {
            param: 'materialId',
        },
        localStoragePrefix: 'ID',
        virtual: {
            refName: 'FSMMaterials',
        },
        toolbarRow: 1
    },
    {
        id: 'filterWH',
        placeholder: 'Filter by Warehouse',
        width: 300,
        type: 'combobox',
        serverFilter: {
            param: 'locationId',
            getData: LoadWarehouses
        },
        localStoragePrefix: 'ID',
        toolbarRow: 1
    },
    {
        id: 'filterCategory',
        placeholder: 'Filter by Category',
        type: 'combobox',
        width: 200,
        serverFilter: {
            param: 'categoryId',
            getData: LoadMaterialCategories
        },
        toolbarRow: 1
    },
    {
        id: 'filterDate',
        placeholder: 'Show data on',
        type: 'date',
        width: 110,
        serverFilter: {
            param: 'date',
        },
        toolbarRow: 1
    }
];

class InventoryBalance extends Component<props, state> {
    gridRef: any = React.createRef()
    FILTERS: Array<IFilterSetting> = this.props.materialId ? FILTERS.filter(f => f.id === 'filterDate') : FILTERS
    COLUMNS_SIMPLIFIED: Array<IColumnSetting>

    constructor(props: any) {
        super(props)
        this.state = {
            locationId: null,
            materialId: null,
        }
        this.COLUMNS_SIMPLIFIED = this.COLUMNS.filter(c => c.field !== 'MAName')
    }

    render() {
        let materialId = this.props.materialId
        let sqlParams = materialId ? ({materialId}) : undefined;
        return <DashboardGrid
            ref={this.gridRef}
            isActive={this.props.isActive}
            getSqlParams={{spName: 'DB_ICBalance', params: sqlParams}}
            saveSettings={{prefix: 'ICBalance', saveFilters: true}}
            excelFileName="ICBalance.xlsx"
            initRefresh={this.InitRefresh}
            columnsSettings={materialId ? this.COLUMNS_SIMPLIFIED : this.COLUMNS}
            filters={this.FILTERS}
            columnMenu={{filters: true, columns: false}}
            getRowKey={(dataItem: simpleObject) => `${dataItem.LocationId}-${dataItem.MAId}`}
            selectSettings={{
                Component: TransactionJournal,
                pageId: 'InventoryDashboard'
            }}
            onChangeGridData={this.props.onChangeGridData}
        />
    }

    OnSelectRow = (row: any) => {
        let locationId = row && row.LocationId;
        let materialId = row && row.MAId
        this.setState({locationId, materialId})
    }

    Refresh = () => {
    }

    InitRefresh = (refresh: any) => {
        this.Refresh = refresh
    }

    GoToBOMUnkitted = (e: React.BaseSyntheticEvent<object, any, any> | undefined, dataAttr: Array<IComboboxItem>) => {
        this.GoToBOM("Pending", dataAttr)
    }

    GoToBOMKitted = (e: React.BaseSyntheticEvent<object, any, any> | undefined, dataAttr: Array<IComboboxItem>) => {
        this.GoToBOM("Kitted", dataAttr)
    }

    COLUMNS: Array<IColumnSetting> = [
        {
            field: 'RowNum',
            title: '#',
            type: 'number',
            gridWidth: 40,
            filterable: false,
            aggregate: 'count',
            columnMenu: false
        },
        {
            field: 'LocationName',
            title: 'Location',
            type: 'string',
            minWidth: 300,
            format: {
                type: 'link',
                fieldId: 'LocationId',
                refName: 'Locations'
            }
        },
        {
            field: 'PartNumber',
            title: 'Part Number',
            type: 'string',
            gridWidth: 120,
            format: {
                type: 'string',
                fieldId: 'PartNumber'
            }
        },
        {
            field: 'MAName',
            title: 'Material',
            type: 'string',
            minWidth: 300,
            format: {
                type: 'link',
                fieldId: 'MAId',
                refName: 'FSMMaterials'
            }
        },
        {
            field: 'CategoryName',
            title: 'Category',
            type: 'string',
            minWidth: 150,
            format: {
                type: 'string',
                fieldId: 'CategoryName'
            }
        },
        {
            field: 'BinLocation',
            title: 'Bin Location',
            type: 'string',
            gridWidth: 150,
            format: {
                type: 'string',
                fieldId: 'BinLocation'
            }
        },
        {
            field: 'UOMName',
            title: 'UOM',
            type: 'string',
            gridWidth: 100,
            format: {
                type: 'string',
                fieldId: 'UOMName'
            }
        },
        {
            field: 'QuantityActual',
            title: 'QTY Actual',
            type: 'number',
            format: {
                type: 'number'
            }
        },
        {
            field: 'QuantityInProgress',
            title: 'QTY In Progress',
            type: 'number',
            gridWidth: 140,
            format: {
                type: 'number',
                /* customFormat: true,
                getCustomFormat: (dataItem: simpleObject) => {
                  let value = dataItem.QuantityInProgress
                  let text = value !== null && value !== undefined ? value.toFixed(2) : ''
                  if (value === 0) return text
                  return <ButtonLink
                    text={text}
                    dataAttr={{ Id: dataItem.MAId, Name: dataItem.MAName }}
                    onClick={(e: React.BaseSyntheticEvent<object, any, any> | undefined, dataAttr: IComboboxItem) => {
                      localStorage.setItem('BOM_filterMaterial', JSON.stringify(dataAttr))
                      localStorage.setItem('BOM_ValuesFilter', JSON.stringify({
                        TJStage: [{ value: "Executed", field: "TJStage", operator: "eq" }]
                      }))
                      this.props.switchTab('BOM')
                    }}
                  />
                } */
            }
        },
        {
            field: 'Unkitted',
            title: 'Pending BOMs',
            type: 'number',
            format: {
                type: 'number',
                customFormat: !this.props.isModalCard,
                getCustomFormat: !this.props.isModalCard ? (dataItem: simpleObject) => {
                    let value = dataItem.Unkitted
                    let text = value !== null && value !== undefined ? value.toFixed(2) : ''
                    return <div style={{textAlign: 'center'}}>
                        {value === 0 ? text : <ButtonLink
                            text={text}
                            dataAttr={[
                                {Id: dataItem.MAId, Name: dataItem.MAName},
                                {Id: dataItem.LocationId, Name: dataItem.LocationName}
                            ]}
                            onClick={this.GoToBOMUnkitted}
                        />}
                    </div>
                } : undefined
            }
        },
        {
            field: 'Surplus',
            title: 'Deficit/Surplus',
            type: 'number',
            format: {
                type: 'number'
            }
        },
        {
            field: 'Kitted',
            title: 'Kitted BOMs',
            type: 'number',
            format: {
                type: 'number',
                customFormat: !this.props.isModalCard,
                getCustomFormat: !this.props.isModalCard ? (dataItem: simpleObject) => {
                    let value = dataItem.Kitted
                    let text = value !== null && value !== undefined ? value.toFixed(2) : ''
                    return <div style={{textAlign: 'center'}}>
                        {value === 0 ? text : <ButtonLink
                            text={text}
                            dataAttr={[{Id: dataItem.MAId, Name: dataItem.MAName}, {
                                Id: dataItem.LocationId,
                                Name: dataItem.LocationName
                            }]}
                            onClick={this.GoToBOMKitted}
                        />}
                    </div>
                } : undefined
            }
        },
        {
            field: 'COGS',
            title: 'COGS $',
            type: 'number',
            format: {
                type: 'currency'
            }
        },
        {
            field: 'KittedTotal',
            title: 'Kitted Total $',
            type: 'number',
            aggregate: 'sum',
            format: {
                type: 'currency'
            }
        },
        {
            field: 'InventoryTotal',
            title: 'Inventory Total $',
            type: 'number',
            aggregate: 'sum',
            format: {
                type: 'currency'
            }
        }
    ];

    GoToBOM = (bomStageValue: string, dataAttr: Array<IComboboxItem>) => {
        localStorage.removeItem('BOM_filterBPO')
        localStorage.setItem('ID_filterMaterial', JSON.stringify(dataAttr[0]))
        localStorage.setItem('ID_filterWH', JSON.stringify(dataAttr[1]))
        localStorage.setItem('BOM_ValuesFilter', JSON.stringify({
            BOMStage: [{value: bomStageValue, field: "BOMStage", operator: "eq"}]
        }))
        this.props.switchTab('BOM')
    }

}

export default InventoryBalance;
