import {Component} from 'react'
import {Button} from '@progress/kendo-react-buttons'
import {DateInput, DateInputProps} from '@progress/kendo-react-dateinputs'

interface props {
  defaultProps: DateInputProps
  isDate?: boolean

  clear(e: { target: { props: DateInputProps } }, isClear: boolean): void
}

interface state {
}

class ClearableDateInput extends Component<props, state> {
  render() {
    return <>
      <DateInput {...this.props.defaultProps}
                 formatPlaceholder={this.props.isDate ? {
                   month: 'm',
                   day: 'd',
                   year: 'y'
                 } : this.props.defaultProps.formatPlaceholder}/>
      {this.props.defaultProps.value && (
        <Button
          icon='close'
          fillMode="flat"
          onClick={this.ClearFilter}
          style={{position: 'absolute', right: '25px', top: 0, zIndex: 1}}
        ></Button>
      )}
    </>
  }

  ClearFilter = () => {
    this.props.clear({target: {props: this.props.defaultProps}}, true)
  }
}

export default ClearableDateInput
