import {Button} from '@progress/kendo-react-buttons';
import {Component} from 'react';
import {IComboboxItem} from '../../helpers/interfaces';
import {ReferenceRecordsDataSource} from '../../helpers/queries';
import {RunScriptAsync} from '../../helpers/runscripts';
import {isMobile} from '../../helpers/settings';
import FXCard from '../Common/FXCard/FXCard';
import MobilePopup from '../Common/MobilePopup/Index';
import ComboboxFilter from '../Dashboard/ComboboxFilter';
import styles from './card.module.scss';
import {IChangeDocumenCardProps} from './interfaces';

import Loader from '../Common/Loader';
import {
  mobilePopupBtnMargin,
  mobilePopupBtnOuterHeight,
  mobilePopupPaddings
} from '../Common/MobilePopup/helpers';
import popupStyles from '../Common/MobilePopup/index.module.scss';

interface state {
  processing: boolean
  kinds: Array<IComboboxItem>
  remountKey: number
}

class ChangeDocumentKindCard extends Component<IChangeDocumenCardProps, state> {
  kind: IComboboxItem | null = null

  constructor(props: IChangeDocumenCardProps) {
    super(props)
    this.state = {
      processing: false,
      kinds: [],
      remountKey: +new Date(),
    }
  }

  componentDidMount(): void {
    this.LoadKinds()
  }

  render() {
    if (isMobile) {
      let kinds = this.kind ? this.state.kinds.filter((k) => k.Id != this.kind?.Id) : this.state.kinds
      let btnsHeight = mobilePopupBtnOuterHeight * kinds.length
      let currentKindHeight = 33
      let popupHeight = this.state.processing ? 200 : currentKindHeight + btnsHeight - mobilePopupBtnMargin + (mobilePopupPaddings * 2) // + currentkind height
      if (this.state.processing) return <Loader
        style={{background: 'transparent'}}/>
      return <MobilePopup
        key={this.state.remountKey + 'kinds'}
        onHide={this.Close}
        height={popupHeight}
        width={320}
      >
        <div style={{
          marginBottom: 8,
          fontSize: 16,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <span>Current Kind: </span>
          <b>{this.kind?.Name || 'Not Selected'}</b>
        </div>
        {kinds.map((k) => {
          return <Button
            key={k.Id}
            className={popupStyles.MobilePopupBtn}
            size={'large'}
            id={k.Id + ''}
            onClick={this.SelectKind}
          >{k.Name}</Button>
        })}
      </MobilePopup>
    }

    return <FXCard
      title={'Change Kind'}
      onClose={this.Close}
      initialWidth={300}
      initialHeight={150}
      originalPaddings={true}
    >
      <div className={styles.FormWrapper}>
        <ComboboxFilter
          key={this.state.remountKey + 'kind'}
          defaultValue={this.kind}
          filterData={this.state.kinds}
          loading={this.state.processing}
          filter={{
            id: 'documentKind',
            placeholder: 'Select Document Kind',
            type: 'combobox',
            width: '100%',
          }}
          onChange={this.OnChangeCombobox}
        />
        <div className={`${styles.FormFooter} k-action-buttons`}>
          <Button onClick={this.Close}>Cancel</Button>
          <Button
            onClick={this.ChangeKind}
            themeColor="primary"
            disabled={!this.kind || this.state.processing}
          >Ok</Button>
        </div>
      </div>
    </FXCard>
  }

  LoadKinds = async () => {
    this.setState({processing: true})
    let result = await ReferenceRecordsDataSource('BuildPlanDocumentKinds')
    let kinds: Array<IComboboxItem> = result[0]
    this.kind = this.props.kindId && kinds.find((k) => k.Id == this.props.kindId) || null
    this.setState({processing: false, kinds, remountKey: +new Date()})
  }

  SelectKind = (e: any) => {
    let kindId = +e.currentTarget.id
    let kind = this.state.kinds.find((k) => k.Id == kindId) || null
    this.kind = kind
    this.ChangeKind()
  }

  OnChangeCombobox = (value: IComboboxItem | null) => {
    this.kind = value
    this.forceUpdate()
  }

  ChangeKind = async () => {
    this.setState({processing: true})
    await RunScriptAsync('Documents_ChangeDocKind', {
      Id: this.props.documentId,
      DocKindId: this.kind?.Id
    })
    this.setState({processing: false})
    this.props.onFinish()
    this.Close()
  }

  Close = () => {
    this.props.finally!()
  }

}

export default ChangeDocumentKindCard;
