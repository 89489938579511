import React, {useRef, useState} from 'react';
import {Button} from '@progress/kendo-react-buttons';
import styles from './map.module.scss'
import {getSQLData} from "../../helpers/queries";
import {ICONS} from "./helpers";

interface IProps {
    map: any
    addressId: number
    className: string
}

interface IStop {
    Lat: number
    Lng: number
}

const LastStopPointsControl = (props: IProps) => {
    const {map, addressId} = props
    const [isOn, setState] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const stopsGroupRef = useRef<any>(new window.L.FeatureGroup())

    const show = () => {
        setState(true)
        if (stopsGroupRef.current.getLayers().length) stopsGroupRef.current.addTo(map)
        else loadLastStops()
    }

    const hideNodes = (e: any) => {
        setState(false)
        stopsGroupRef.current.removeFrom(map)
    }

    const loadLastStops = () => {
        setIsLoading(true)
        getSQLData({
            spName: "TK_GetLastStopPoints",
            params: {addressId},
        }).then((result: any) => {
            drawNodes(result[0])
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const getMarkerHTML = (primaryColor: string) => {
        return `<div class="" style="box-sizing: border-box; width: 16px; height: 16px; border-radius: 50%; border: 2px solid #fff; background: ${primaryColor};"></div>`
    }

    const drawNodes = (points: IStop[]) => {
        for (let point of points) {
            const coords = [point.Lat, point.Lng];
            const MarkerLayer = window.L.marker(
                coords,
                {
                    icon: window.L.divIcon({
                        className: "-icon-box",
                        iconSize: [16, 16],
                        iconAnchor: [8, 16],
                        html: getMarkerHTML(ICONS.Current.primaryColor),
                    }),
                    riseOnHover: true,
                })
            MarkerLayer.addTo(stopsGroupRef.current);
        }
        stopsGroupRef.current.addTo(map)
    };

    return <div className={`${styles.Controls} ${props.className}`}>
        <div style={{display: "flex", alignItems: 'center'}}>
            <Button
                className={`${isLoading ? styles.InProcess : ''}`}
                iconClass={"mdi mdi-dots-hexagon"}
                title={"Show All Stops"}
                onClick={show}
            />
            {isOn && <Button
                style={{marginLeft: 10}}
                iconClass="mdi mdi-eye-off-outline"
                title={`Hide All Stops`}
                onClick={hideNodes}
            />}
        </div>
    </div>
}


export default LastStopPointsControl