import {Button, Toolbar, ToolbarSpacer} from "@progress/kendo-react-buttons";
import BaseComponent from "../../../Components/BaseComponent";
import DocumentViewer
  from "../../../Components/Common/DocumentViewer/DocumentViewer";
import {
  DOCS_EXT,
  IMAGES_EXT
} from "../../../Components/Common/DocumentViewer/helpers";
import Switch from "../../../Components/Switch/Switch";
import {GetCLPMChecklistDocumentUrl} from "../../../helpers/helpers";
import {IS_IE} from "../../../helpers/settings";
import {ICLMSettings} from "../../../stores/interfaces";
import {GetCheckListSampleUrl} from "../helpers";
import styles from "./checklistResults.module.scss";
import {IsDisableEditStatus, OnChangeStatus} from "./helpers";
import {
  IDocumentItem,
  IFileItem,
  IPreviewDocument,
  IPreviewItem
} from "./interfaces";

interface props {
  dataItem: IDocumentItem;
  buildPlanId: number;
  rebuildBtn: any;
  pageId: string;
  settings: ICLMSettings;
  mobile?: {
    onPreviewClose(dataItem: null): void;
    onPopupShow(dataItem: IDocumentItem, fileId?: number): void;
    onChangeStatusPopupShow(dataItem: IDocumentItem, fileId?: number): void;
    arrows: any;
    refreshList(): void;
  };

  onSelectFile(fileId: number): void;

  editComments(dataItem: IDocumentItem): void;

  cancelToken(): any;
}

interface state {
  loading: boolean;
  documentLoading: boolean;
  previewDocument: IPreviewDocument | null;
  files: Array<IFileItem>;
  previews: Array<IPreviewItem>;
  showOriginal: boolean;
}

class ChecklistResultCarousel extends BaseComponent<props, state> {
  fileId: number | null = null;
  timerStatusUpdate: any;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      documentLoading: false,
      previewDocument: null,
      files: this.props.dataItem.files,
      previews: [],
      showOriginal: false,
    };
  }

  async componentDidMount() {
    let dataItem = this.props.dataItem;

    let mobile = this.props.mobile;
    if (mobile) {
      if (
        !this.props.settings.IsSubmitter &&
        dataItem.resultId &&
        dataItem.status === "Pending"
      ) {
        this.timerStatusUpdate = setTimeout(async () => {
          let dataItem = this.props.dataItem;
          await OnChangeStatus(
            "Reviewed",
            dataItem,
            this.fileId,
            this.props.cancelToken()
          );
          this.props.mobile?.refreshList();
        }, 3000);
      }
    }
    let files = dataItem.files;
    if (!files.length && dataItem.sampleExt) {
      this.ShowSample(dataItem);
    } else if (files.length) {
      const sortPreview = (a: IFileItem, b: IFileItem) => b.v - a.v;
      files.sort(sortPreview);
      let file = files[0];
      if (files.length > 1) {
        if (dataItem.approvedFileId)
          file =
            files.find((file) => file.id === dataItem.approvedFileId) ||
            files[0];
        this.SetPreviews(files);
      }

      this.ShowDocument(file);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.CancelLoadPreview();
  }

  render() {
    let dataItem = this.props.dataItem;
    let mobile = this.props.mobile;
    if (mobile) {
      let isDisableChangeStatus =
        IsDisableEditStatus(dataItem, this.props.settings) ||
        (this.props.settings.IsReviewer &&
          dataItem.status === "CustomerRejected") ||
        dataItem.resultId === null;
      return (
        <div className={styles.PreviewerCarouselBox}>
          <Toolbar className={`${styles.MobileToolbar} ${styles.Toolbar}`}>
            <div style={{flex: 1, flexDirection: "column"}}>
              <b>{dataItem.groupName}</b>
              <b>{dataItem.cliName}</b>
              {!!dataItem.cliDesc && (
                <div>
                  <b>Description: </b>
                  {dataItem.cliDesc}
                </div>
              )}
            </div>
          </Toolbar>
          <div className={styles.PreviewerCarouselBox}>
            {this.renderDocumentPreview()}
          </div>
          <div className={styles.PreviewList}>
            {!!this.state.previews.length &&
              this.state.previews.map((item) => {
                let selected = this.state.previewDocument?.id === item.fileId;
                return (
                  <img
                    key={item.fileId}
                    src={item.src}
                    className={`${styles.PreviewItem} ${
                      selected && styles.PreviewItemActive
                    }`}
                    data-id={item.fileId}
                    onClick={this.SelectPreview}
                  />
                );
              })}
          </div>
          <Toolbar className={`${styles.MobileToolbar} ${styles.Toolbar}`}>
            {mobile.arrows}
            {this.state.previewDocument && (
              <>
                <Button
                  title="Download Document"
                  icon="download"
                  onClick={this.DownloadDocument}
                  fillMode="flat"
                />
                <a
                  className="k-button k-button-md k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icon-button"
                  href={this.state.previewDocument.originalSrc}
                  target="__blank"
                  title="Open Document in New Tab"
                >
                  <span className="k-icon k-i-hyperlink-open"></span>
                </a>
              </>
            )}
            {!!this.state.previewDocument?.originalSrc &&
              !!this.state.previewDocument.previewImgSrc && (
                <Button
                  iconClass="mdi mdi-quality-high"
                  fillMode={'flat'}
                  themeColor={this.state.showOriginal ? "success" : undefined}
                  onClick={this.ToggleOriginal}
                />
              )}

            {!isDisableChangeStatus && (
              <Button onClick={this.OpenChangeStatusPopup}>Status</Button>
            )}
            <Button
              title=""
              iconClass="mdi mdi-dots-horizontal"
              onClick={this.ShowMobileActions}
              fillMode="flat"
            />
            <ToolbarSpacer/>
            <Button
              title="Close Preview"
              icon="close"
              onClick={() => mobile?.onPreviewClose(null)}
              fillMode="flat"
            />
          </Toolbar>
        </div>
      );
    }

    return (
      <div className={styles.PreviewerCarouselBox}>
        <Toolbar className={styles.Toolbar}>
          <div style={{flex: 1, flexDirection: "column"}}>
            <b>{dataItem.cliName}</b>
            {!!dataItem.cliDesc && (
              <div>
                <b>Description: </b>
                {dataItem.cliDesc}
              </div>
            )}
          </div>
          <div style={{flexDirection: "column", alignItems: "flex-end"}}>
            <div>
              {this.props.settings.IsReviewer && (
                <Button
                  title="Edit Comments"
                  icon="edit"
                  fillMode="flat"
                  onClick={this.EditComments}
                />
              )}
              {this.state.previewDocument && (
                <>
                  <Button
                    title="Download Document"
                    icon="download"
                    onClick={this.DownloadDocument}
                    fillMode="flat"
                  />
                  <a
                    className="k-button k-button-md k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icon-button"
                    href={this.state.previewDocument.originalSrc}
                    target="__blank"
                    title="Open Document in New Tab"
                  >
                    <span className="k-icon k-i-hyperlink-open"></span>
                  </a>
                </>
              )}
            </div>
            <div style={{height: 27, display: "flex"}}>
              {!!this.state.previewDocument?.originalSrc &&
                !!this.state.previewDocument.previewImgSrc && (
                  <Switch
                    checked={this.state.showOriginal}
                    id="showOriginal"
                    onChange={this.ToggleOriginal}
                    label="Original"
                    rtl={true}
                  />
                )}
            </div>
          </div>

          {!!dataItem.comments && (
            <div className={styles.CLIComments}>
              <b>Comments: </b>
              {dataItem.comments}
            </div>
          )}
        </Toolbar>
        <div className={styles.PreviewerCarouselBox}>
          {this.renderDocumentPreview()}
        </div>
        <div className={styles.PreviewList}>
          {!!this.state.previews.length &&
            this.state.previews.map((item) => {
              let selected = this.state.previewDocument?.id === item.fileId;
              return (
                <img
                  key={item.fileId}
                  src={item.src}
                  className={`${styles.PreviewItem} ${
                    selected && styles.PreviewItemActive
                  }`}
                  data-id={item.fileId}
                  onClick={this.SelectPreview}
                />
              );
            })}
        </div>
      </div>
    );
  }

  renderDocumentPreview = () => {
    let document = this.state.previewDocument;
    let previewUrl = "";
    if (document) {
      previewUrl =
        !this.state.showOriginal && document.previewImgSrc
          ? document.previewImgSrc
          : document.originalSrc;
    }
    return (
      <DocumentViewer
        containerId={this.props.pageId}
        key={"document"}
        document={
          document
            ? {
              id: document.id,
              extension: document.ext,
              previewUrl,
              alt: document.name,
            }
            : undefined
        }
        documentLoading={this.state.documentLoading}
        viewerBtn={this.props.rebuildBtn}
      />
    );
  };

  ShowMobileActions = () => {
    let selectedFile = this.state.previewDocument;
    let mobile = this.props.mobile;
    if (mobile) mobile.onPopupShow(this.props.dataItem, selectedFile?.id);
  };

  OpenChangeStatusPopup = () => {
    let selectedFile = this.state.previewDocument;
    let mobile = this.props.mobile;
    if (mobile)
      mobile.onChangeStatusPopupShow(this.props.dataItem, selectedFile?.id);
  };

  ShowSample = (dataItem: IDocumentItem) => {
    this.CancelLoadPreview();
    let ext = dataItem.sampleExt.toLowerCase();
    let isDoc = DOCS_EXT.indexOf(ext) > -1;
    let src = GetCheckListSampleUrl(dataItem.cliId, true);
    if (isDoc) {
      src =
        "https://view.officeapps.live.com/op/embed.aspx?src=" +
        encodeURIComponent(src);
    }
    this.setState({
      previewDocument: {
        id: 0,
        originalSrc: src,
        previewImgSrc: null,
        ext: dataItem.sampleExt,
        name: "Example ",
      },
    });
  };

  SetPreviews = (files: Array<IFileItem>) => {
    let previews: Array<IPreviewItem> = [];
    const sincForeach = async (files: Array<IFileItem>, index: number) => {
      let fileId = files[index].id;
      let previewUrl = await GetCLPMChecklistDocumentUrl(
        fileId,
        this.props.buildPlanId,
        true,
        true
      );
      previews.push({fileId, src: previewUrl});
      if (files[index + 1]) sincForeach(files, index + 1);
      else this.setState({previews});
    };
    sincForeach(files, 0);
  };

  CancelLoadPreview = () => {
    if (IS_IE) {
      window.document.execCommand("Stop");
    } else {
      window.stop();
    }
  };

  ShowDocument = async (file: IFileItem, approvedText?: string) => {
    this.fileId = file.id;
    let ext = file.e.toLowerCase();
    this.CancelLoadPreview();
    this.setState({documentLoading: true});
    this.props.onSelectFile(file.id);
    let isIMG = IMAGES_EXT.indexOf(ext) > -1;
    let isDoc = DOCS_EXT.indexOf(ext) > -1;
    let originalSrc = await GetCLPMChecklistDocumentUrl(
      file.id,
      this.props.buildPlanId,
      false,
      true
    );

    let previewSrc = isIMG
      ? await GetCLPMChecklistDocumentUrl(
        file.id,
        this.props.buildPlanId,
        true,
        true
      )
      : null;
    if (isDoc) {
      originalSrc =
        "https://view.officeapps.live.com/op/embed.aspx?src=" +
        encodeURIComponent(originalSrc);
    }
    this.setState({
      previewDocument: {
        id: file.id,
        ext: file.e,
        originalSrc: originalSrc,
        previewImgSrc: previewSrc,
        name: approvedText || file.name,
      },
      documentLoading: false,
    });
  };

  SelectPreview = async (e: any) => {
    let fileId = e.nativeEvent.target.dataset.id;
    let file = this.props.dataItem.files.find((f) => +f.id === +fileId);
    if (!file) return;
    let approvedText =
      this.props.dataItem.approvedFileId == file.id ? "Approved " : "";
    this.ShowDocument(file, approvedText + file.name);
  };

  DownloadDocument = async () => {
    let dataItem = this.props.dataItem;
    let fileId = this.state.previewDocument!.id;
    if (fileId === 0) {
      // sample
      window.location.href = GetCheckListSampleUrl(dataItem.cliId, false);
    } else {
      let originalSrc = await GetCLPMChecklistDocumentUrl(
        fileId,
        this.props.buildPlanId,
        false,
        false
      );
      window.location.href = originalSrc;
    }
  };

  EditComments = () => {
    this.props.editComments(this.props.dataItem);
  };

  ToggleOriginal = () => {
    this.setState((state) => ({
      showOriginal: !state.showOriginal,
    }));
  }
}

export default ChecklistResultCarousel;
