import {Component} from 'react';
import {IAddTCCardProps} from './interfaces';
import {Button} from '@progress/kendo-react-buttons';
import {RunScriptAsync} from '../../helpers/runscripts';
import Loader from '../Common/Loader';
import FXCard from '../Common/FXCard/FXCard';
import formStyles from './card.module.scss'
import styles from './card.module.scss'
import {IComboboxItem} from '../../helpers/interfaces';
import FilterCombobox from '../Common/Form/FilterCombobox';
import {ReferenceRecordsDataSource} from '../../helpers/queries';
import ClearableDateFilter from '../Common/Form/ClearableDateFilter';
import moment from 'moment';

interface IInfo {
  Date: Date | null
  Employee: IComboboxItem | null
  Reason: IComboboxItem | null
}

interface state {
  loading: boolean
  processing: boolean
  remountKey: number
  invalidForm: boolean
  employees: Array<IComboboxItem>
  reasons: Array<IComboboxItem>
}

class AddTCCard extends Component<IAddTCCardProps, state> {

  Info: IInfo = {
    Date: null,
    Employee: null,
    Reason: null
  }

  constructor(props: IAddTCCardProps) {
    super(props)
    this.state = {
      loading: false,
      processing: false,
      remountKey: +new Date(),
      invalidForm: true,
      employees: [],
      reasons: []
    }
  }

  componentDidMount() {
    this.LoadData()
  }

  render() {
    return <FXCard
      title={<span className={formStyles.HeaderTitle}>New Time Card</span>}
      onClose={this.Close}
      initialWidth={400}
      initialHeight={220}
      originalPaddings={true}
    >
      <div className={formStyles.FormWrapper}>
        {(this.state.processing) && <Loader/>}
        <ClearableDateFilter
          key={this.state.remountKey + 'date'}
          className={styles.FormField}
          defaultValue={this.Info.Date}
          onChange={this.OnChangeDate}
          clear={this.OnClearDate}
          required={true}
          filter={{placeholder: ''}}
        />
        <FilterCombobox
          key={'employee' + this.state.remountKey}
          className={styles.FormField}
          placeholder="Employee *"
          defaultValue={this.Info.Employee}
          data={this.state.employees}
          loading={this.state.loading}
          onChange={this.OnChangeField}
          dataAttr="Employee"
          required={true}
        />
        <FilterCombobox
          key={'reason' + this.state.remountKey}
          className={styles.FormField}
          placeholder="Reason"
          defaultValue={this.Info.Reason}
          data={this.state.reasons}
          loading={this.state.loading}
          onChange={this.OnChangeField}
          dataAttr="Reason"
        />
        {this.renderFooter()}
      </div>
    </FXCard>
  }

  LoadData = async () => {
    try {
      this.setState({loading: true});
      let result: Array<PromiseSettledResult<any>> = await Promise.allSettled([
        ReferenceRecordsDataSource('Employees'),
        ReferenceRecordsDataSource('TKTimeCardReasons')
      ])

      let [employeesResult, reasonsResult] = result
      // @ts-ignore
      let [employees, reasons] = [employeesResult.value[0] || [], reasonsResult.value[0] || []]
      this.setState({
        employees,
        reasons,
        remountKey: +new Date()
      })
    } finally {
      this.setState({loading: false});
    }
  }

  OnClearDate = () => {
    this.OnChangeField(null, 'Date')
  }

  OnChangeDate = (value: Date) => {
    this.OnChangeField(value, 'Date')
  }

  OnChangeField = (value: any, dataAttr: keyof IInfo) => {
    this.Info[dataAttr] = value
    this.ValidateForm()
  }

  ValidateForm = () => {
    let invalidForm = !this.Info.Date || !this.Info.Employee
    this.setState({invalidForm})
  }

  renderFooter = () => {
    return <div className={`${formStyles.FormFooter} k-action-buttons`}>
			<span className={formStyles.InvalidMessage}>
				{this.state.invalidForm && <span>Fill all mandatory fields</span>}
			</span>
      <Button onClick={this.Close}>Cancel</Button>
      <Button
        onClick={this.CreateTimeCard}
        themeColor="primary"
        disabled={this.state.invalidForm}
      >Create Time Card</Button>
    </div>
  }

  CreateTimeCard = async () => {
    try {
      this.setState({processing: true})
      let params = {
        Date: moment(this.Info.Date).format('DD.MM.YYYY'),
        EmployeeId: this.Info.Employee!.Id,
        ReasonId: this.Info.Reason?.Id || null
      }
      let newTCId = await RunScriptAsync('TKTimeCards_CreateNew', params);
      if (newTCId && this.props.onFinish) {
        this.props.onFinish(newTCId)
        this.Close()
      } else {
        this.setState({processing: false})
      }
    } finally {
    }
  }

  Close = () => {
    this.props.finally!()
  }
}

export default AddTCCard;
