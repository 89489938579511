import {Component} from 'react';
import {IVIMInvoice} from './interfaces';
import styles from './vim.module.scss';

interface props {
  invoice: IVIMInvoice
  selected: boolean

  onSelect(e: any): void
}

interface state {
}

class Invoice extends Component<props, state> {

  render() {
    let invoice = this.props.invoice
    return (<div
      key={invoice.Id}
      className={styles.ListItem}
      data-selected={this.props.selected}
      data-id={invoice.Id}
      data-name={invoice.Name}
      onClick={this.props.onSelect}
    >
      <div className={styles.Row}>
        <div className={styles.Date}>
          {invoice.Date ? invoice.DateFormatted :
            <span className={styles.ListPlaceholder}>Date</span>}
        </div>
        <div>{invoice.Amount !== null ? `$${invoice.Amount}` :
          <span className={styles.ListPlaceholder}>Amount</span>}</div>
        <div className={styles.RightCell}>
          {invoice.Number !== null ? `#${invoice.Number}` :
            <span className={styles.ListPlaceholder}>Number</span>}
        </div>
      </div>
      <div className={styles.Row}>
        <div>
          {invoice.VendorName ||
              <span className={styles.ListPlaceholder}>Vendor</span>}
          {invoice.CODRequesterName ? ` - ${invoice.CODRequesterName}` : ''}
        </div>
        <div className={styles.RightCell}>
          {invoice.IsSuspended &&
              <span className={styles.ListItemSuspended}>Suspended</span>}
          {invoice.Classifier && <span>&nbsp;{invoice.Classifier}</span>}
        </div>
      </div>
    </div>)
  }
}

export default Invoice;
