import React, {Component} from 'react';
import DashboardGrid from '../Dashboard/Grid';
import {IColumnSetting} from '../Dashboard/interfaces';
import {RunScriptLocalAsync} from '../../helpers/runscripts';
import {Button} from '@progress/kendo-react-buttons'
import {simpleObject} from '../../helpers/interfaces';
import DesktopActions from '../Common/DesktopActions';

function getCustomAggregate(field: string, gridData: Array<simpleObject>) {
  let Ids: Array<string> = [];
  let sum = gridData.reduce(function (sum, current) {
    let id = current.POId
    let isUnic = Ids.indexOf(id) === -1
    let isActive = current.POStatus !== 'Rejected' && current.POStatus !== 'Cancelled';
    if (!isActive || !isUnic) return sum
    let value = current[field] || 0;
    Ids.push(id)
    return sum + value;
  }, 0);
  return sum;
}

const COLUMNS: Array<IColumnSetting> = [
  {
    field: 'Vendor',
    title: 'Vendor',
    type: 'string',
    format: {
      type: 'string',
      fieldId: 'VendorID',
    }
  },
  {
    field: 'Store',
    title: 'Store',
    type: 'string',
    format: {
      type: 'string',
      fieldId: 'Store',
    }
  },
  {
    field: 'Origination',
    title: 'Origination',
    type: 'string',
    format: {
      type: 'string',
      fieldId: 'Origination',
    }
  },
  {
    field: 'InvoiceDate',
    title: 'Vendor Invoice Date',
    type: 'date',
    gridWidth: 110,
    format: {
      type: 'date'
    }
  },
  {
    field: 'InvoiceNumber',
    title: 'Invoice #',
    type: 'string',
    format: {
      type: 'link',
      refName: 'VIMInvoices',
      fieldId: 'InvoiceId',
    }
  },
  {
    field: 'InvoiceAmount',
    title: 'Invoice Amount',
    type: 'number',
    aggregate: 'sum',
    format: {
      type: 'currency',
      customTotal: true,
      getCustomTotal: (gridData: Array<simpleObject>) => {
        let sum = gridData.reduce(function (sum, current) {
          let isActive = current.InvoiceStatus !== 'Cancelled' && current.InvoiceStatus !== 'Rejected'
          if (!isActive) return sum
          let value = current.InvoiceAmount || 0;
          return sum + value;
        }, 0);
        return sum;
      }
    }
  },
  {
    field: 'InvoiceStatus',
    title: 'Invoice Stage',
    type: 'string',
    gridWidth: 110,
    format: {
      type: 'string',
      fieldId: 'InvoiceStatus',
    }
  },
  {
    field: 'PONumber',
    title: 'FCX PO #',
    type: 'string',
    format: {
      type: 'link',
      refName: 'VIMPurchaseOrders',
      fieldId: 'POId',
    }
  },
  {
    field: 'PODate',
    title: 'PO Date',
    type: 'date',
    format: {
      type: 'date'
    }
  },
  {
    field: 'POTotal',
    title: 'Total PO Value',
    type: 'number',
    aggregate: 'sum',
    format: {
      type: 'currency',
      customTotal: true,
      getCustomTotal: (gridData: Array<simpleObject>) => {
        return getCustomAggregate('POTotal', gridData)
      }
    }
  },
  {
    field: 'POBalance',
    title: 'Remaining Balance',
    type: 'number',
    aggregate: 'sum',
    minWidth: 135,
    format: {
      type: 'currency',
      customTotal: true,
      getCustomTotal: (gridData: Array<simpleObject>) => {
        return getCustomAggregate('POBalance', gridData)
      }
    }
  },
  {
    field: 'POStatus',
    title: 'PO Stage',
    type: 'string',
    gridWidth: 90,
    format: {
      type: 'string',
      fieldId: 'POStatus',
    }
  }
];

interface props {
  buildPlanId: number
  isActive: boolean

  onChangeGridData?(data: Array<simpleObject>): void
}

class VIMSummary extends Component<props> {
  bpId: number = this.props.buildPlanId
  gridRef: any = React.createRef()

  render() {
    if (this.props.isActive && this.bpId !== this.props.buildPlanId) {
      this.bpId = this.props.buildPlanId
    }

    if (!this.props.buildPlanId || !this.props.isActive && this.bpId !== this.props.buildPlanId) return null
    return <DashboardGrid
      ref={this.gridRef}
      isActive={this.props.isActive}
      key={this.props.buildPlanId}
      columnsSettings={COLUMNS}
      getSqlParams={{
        spName: 'DB_VIM',
        params: {buildPlanId: this.props.buildPlanId}
      }}
      initRefresh={this.InitRefresh}
      excelFileName="VIM Summary.xlsx"
      columnMenu={{
        filters: true,
        columns: false
      }}
      toolbar={{
        actionBtns: (
          <DesktopActions
            actions={
              <Button onClick={this.CreateNewVendor}>New Vendor PO</Button>
            }
          />
        ),
      }}
      getRowKey={(dataItem: simpleObject) => dataItem.RowNum}
      onChangeGridData={this.props.onChangeGridData}
    />
  }

  Refresh = () => {
  }

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh
  }

  CreateNewVendor = async () => {
    try {
      await RunScriptLocalAsync('VIMPurchaseOrders_CreateNew', {BPID: this.props.buildPlanId});
    } finally {
      this.Refresh()
    }
  }
}

export default VIMSummary;
