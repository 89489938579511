import BaseComponent from '../../Components/BaseComponent';
import styles from './bpdashboard.module.scss';
import {Button, Toolbar, ToolbarSpacer} from '@progress/kendo-react-buttons';
import FilterCombobox from '../../Components/Common/Form/FilterCombobox'
import ClearableInput from '../../Components/Common/Form/ClearableInput';
import moment from 'moment';
import {IComboboxItem, simpleObject} from '../../helpers/interfaces';
import {InputChangeEvent} from '@progress/kendo-react-inputs';
import {IBPInfo, IBPInfoFromServer} from './interfaces';
import BPItem from './BPItem';
import Loader from '../../Components/Common/Loader';
import SwitchDates from './SwitchDates';
import {CalculateDataInfo} from './helpers'

interface props {
  isActive: boolean
}

interface state {
  loading: boolean
  bpOwners: Array<IComboboxItem>
  remountKey: number
  listData: Array<IBPInfo>
}

const BPOwner_LocalStorage = 'BPDashboard_BPO'

class BPDashboard extends BaseComponent<props, state> {
  weekOffset: number = 0
  bpOwner: IComboboxItem | null = null
  bpName: string = ''
  bpNameChangeTimeout: any

  constructor(props: props) {
    super(props)
    this.state = {
      loading: false,
      bpOwners: [],
      remountKey: +new Date(),
      listData: []
    }
    let savedViewName = localStorage.getItem(BPOwner_LocalStorage)
    if (savedViewName) {
      this.bpOwner = JSON.parse(savedViewName)
    }
  }

  componentDidMount() {
    this.LoadData()
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    if (this.bpNameChangeTimeout) clearTimeout(this.bpNameChangeTimeout)
  }

  render() {
    return <div className={styles.Container}>
      <Toolbar className={styles.Toolbar}>
        <FilterCombobox
          key={this.state.remountKey + 'bpowner'}
          data={this.state.bpOwners}
          placeholder='Filter by BP Owner'
          defaultValue={this.bpOwner}
          onChange={this.OnChangeBPOwner}
          className={styles.BPOwnerFilter}
          loading={this.state.loading}
        />
        <ClearableInput
          key={this.state.remountKey + 'bpname'}
          defaultValue={this.bpName}
          placeholder="Search by BP Name"
          onChange={this.OnChangeBPName}
          clear={this.OnClearBPName}
          className={styles.BPNameFilter}
        />
        <div className={styles.Legend}>
          <span className="k-icon k-i-stop" style={{color: '#006494'}}></span>
          Budget
          <span className="k-icon k-i-stop" style={{color: '#01baef'}}></span>
          Estimate
          <span className="k-icon">↖</span>
          Actual
        </div>
        <ToolbarSpacer/>
        {!this.bpOwner && !this.bpName &&
            <SwitchDates weekOffset={this.weekOffset}
                         getWeekDates={this.GetWeekDates}
                         onChangeDate={this.OnChangeDate}/>}
        <Button icon="refresh" style={{position: 'relative', zIndex: 2}}
                onClick={this.Refresh}></Button>
      </Toolbar>
      <div className={styles.Content}>
        {this.state.loading && <Loader/>}
        {!this.state.loading && this.state.listData.map((item) => <BPItem
          key={item.Id} data={item}/>)}
      </div>
    </div>
  }

  LoadData = async () => {
    try {
      this.setState({loading: true})
      let params: simpleObject = {
        bpName: this.bpName || null,
        bpOwnerId: this.bpOwner?.Id || null
      }
      if (!params.bpName && !params.bpOwnerId) {
        let [beginDate, endDate] = this.GetWeekDates(this.weekOffset)
        params.beginDate = beginDate.format()
        params.endDate = endDate.format()
      }
      let result: any = await this.GetSQLData({spName: 'DB_BP', params});
      let bpOwners = result[0]
      let weekData = this.CalculateBPInfo(result[1]);
      this.setState({bpOwners, listData: weekData})
    } finally {
      this.setState({loading: false})
    }
  }

  CalculateBPInfo = (bpData: Array<IBPInfoFromServer>) => {
    let weekData: Array<IBPInfo> = []
    for (let bpInfo of bpData) {
      weekData.push(CalculateDataInfo(bpInfo))
    }
    return weekData;
  }

  GetWeekDates = (weekOffset: number) => {
    return [
      moment().add(weekOffset, 'weeks').startOf('week'),
      moment().add(weekOffset, 'weeks').endOf('week')
    ]
  }

  OnChangeDate = (weekOffset: number) => {
    this.weekOffset = weekOffset;
    this.LoadData()
  }

  OnChangeBPOwner = (value: IComboboxItem | null) => {
    this.bpOwner = value
    if (value) localStorage.setItem(BPOwner_LocalStorage, JSON.stringify(value))
    else localStorage.removeItem(BPOwner_LocalStorage)
    this.LoadData()
  }

  OnChangeBPName = (e: InputChangeEvent | undefined) => {
    this.bpName = e?.value || ''
    if (this.bpNameChangeTimeout) clearTimeout(this.bpNameChangeTimeout)
    this.bpNameChangeTimeout = setTimeout(this.LoadData, 1000)
  }

  OnClearBPName = () => {
    this.bpName = ''
    this.LoadData()
  }

  Refresh = () => {
    this.LoadData()
  }

}

export default BPDashboard;
