import {Component} from 'react';
import {Button} from '@progress/kendo-react-buttons';
import {Input, InputChangeEvent} from '@progress/kendo-react-inputs';
import {simpleObject} from '../../../helpers/interfaces'

interface props {
  defaultValue: string
  name?: string
  placeholder?: string
  dataprops?: any
  style?: simpleObject
  className?: string

  onChange(e?: InputChangeEvent, dataprops?: any): any

  clear?(dataprops?: any): any
}

interface state {
  value: any
}

class ClearableInput extends Component<props, state> {
  constructor(props: props) {
    super(props)
    this.state = {
      value: this.props.defaultValue === undefined || this.props.defaultValue === null ? '' : this.props.defaultValue
    }
  }

  render() {
    return (<div style={{position: 'relative', ...this.props.style}}
                 className={this.props.className}>
      <Input
        value={this.state.value}
        style={{width: "100%"}}
        name={this.props.name || ''}
        placeholder={this.props.placeholder || ''}
        onChange={this.OnChange}
      />
      {!!this.state.value.toString() && !!this.props.clear &&
          <Button
              icon="close"
              fillMode="flat"
              onClick={this.ClearValue}
              style={{position: 'absolute', right: 0, top: 0}}
          ></Button>
      }
    </div>);
  }

  OnChange = (e: InputChangeEvent) => {
    this.setState({value: e.value})
    this.props.onChange(e, this.props.dataprops);
  }

  ClearValue = () => {
    if (this.props.clear) {
      this.setState({value: ''})
      this.props.clear(this.props.dataprops);
    }
  }
}

export default ClearableInput;
