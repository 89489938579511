import React, {Component} from 'react';
import DashboardGrid from '../Dashboard/Grid';
import {IColumnSetting} from '../Dashboard/interfaces';
import {RunScriptAsync} from '../../helpers/runscripts';
import {Button} from '@progress/kendo-react-buttons';
import {TextArea} from '@progress/kendo-react-inputs';
import {currentObjectId, currentObjectType} from '../../helpers/settings';
import moment from 'moment';
import {ModalRef} from '../../Components/Common/Modal/Modal';
import {simpleObject} from '../../helpers/interfaces';

const isWOCard = currentObjectType === 'FSMWorkOrders';

const COLUMNS: Array<IColumnSetting> = [
  {
    field: 'ServerTime',
    title: 'Time',
    type: 'date',
    filterable: false,
    gridWidth: 125,
    columnMenu: false,
    format: {
      type: 'datetime',
      customFormat: true,
      getCustomFormat: function (dataItem) {
        let localTime = dataItem.LocalTime
          ? moment(dataItem.LocalTime.slice(0, -6)).format('MM/DD/YY LT')
          : '';
        let serverTime = dataItem.ServerTime ? moment(dataItem.ServerTime).format('MM/DD/YY LT') : '';
        localTime = localTime === serverTime ? ' = Server Time' : ': ' + localTime;

        return (
          <span title={`Local Time${localTime}`}
                style={{pointerEvents: 'all'}}>{serverTime}</span>
        );
      }
    }
  },
  {
    field: 'EmployeeName',
    title: 'User',
    type: 'string',
    gridWidth: 150,
    format: {
      type: 'link',
      fieldId: 'EmployeeId',
      refName: 'Employees'
    }
  },
  {
    field: 'EventType',
    title: 'Event Type',
    type: 'string',
    gridWidth: 120,
    format: {
      type: 'string',
      fieldId: 'EventType',
    }
  },
  {
    field: 'Details',
    title: 'Details',
    type: 'string',
    minWidth: 230
  }
];

if (!isWOCard) {
  COLUMNS.push({
    field: 'WorkOrderName',
    title: 'Work Order',
    type: 'string',
    gridWidth: 100,
    format: {
      type: 'link',
      fieldId: 'WorkOrderId',
      refName: 'FSMWorkOrders'
    }
  });
}

interface props {
  isPage: boolean
  buildPlanId?: number
  isActive?: boolean
  doNotSaveFilters?: boolean

  onChangeGridData?(data: Array<simpleObject>): void
}

class BPHistory extends Component<props> {
  gridRef: any = React.createRef()
  buildPlanId: number | string | undefined = isWOCard ? currentObjectId : this.props.buildPlanId

  render() {
    if (this.props.isPage && !this.buildPlanId) return null
    if (!this.props.isPage && this.props.isActive && this.buildPlanId !== this.props.buildPlanId) {
      this.buildPlanId = this.props.buildPlanId
    }
    if (!this.props.isPage && !this.props.buildPlanId || !this.props.isActive && this.buildPlanId !== this.props.buildPlanId) return null

    return <DashboardGrid
      ref={this.gridRef}
      isActive={this.props.isActive === undefined ? true : this.props.isActive}
      key={this.props.buildPlanId || this.buildPlanId}
      columnsSettings={COLUMNS}
      getSqlParams={{
        spName: 'DB_BuildPlanHistory',
        params: {objectId: this.buildPlanId}
      }}
      excelFileName="Build Plan History.xlsx"
      initRefresh={this.InitRefresh}
      columnMenu={{
        filters: true,
        columns: false
      }}
      toolbar={{
        actionBtns: !isWOCard ?
          <Button onClick={this.ShowDialog}>New Notes</Button> : undefined
      }}
      prepareData={this.PrepareData}
      getRowKey={(dataItem: simpleObject) => dataItem.RowNum}
      onChangeGridData={this.props.onChangeGridData}
    />
  }

  PrepareData = (result: any) => {
    let data = result[0]
    data.sort((itemA: simpleObject, itemB: simpleObject) => moment(itemA.ServerTime).isBefore(moment(itemB.ServerTime)) ? -1 : 1)
    return data
  }

  Refresh = () => {
  }

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh
  }

  ShowDialog = () => {
    let notesTextRef: any;
    ModalRef.showDialog({
      title: 'Notes',
      buttons: [
        {
          text: 'Cancel',
          action: () => {
            ModalRef.hideDialog();
          }
        },
        {
          text: 'Ok',
          color: 'primary',
          action: () => {
            let notesText = notesTextRef.element.current.value;
            if (notesText) {
              ModalRef.hideDialog();
              this.AddNotes(notesText)
            }
          }
        },
      ],
      children: <TextArea
        ref={(ref) => {
          notesTextRef = ref;
          if (ref && ref.focus) {
            ref?.focus()
          }
        }}
        rows={5}
        style={{width: '100%'}}
      />
    });
  }

  AddNotes = (notesText: string) => {
    RunScriptAsync('FSMBuildPlans_AddNotes', {
      BPID: this.buildPlanId,
      Notes: notesText
    })
      .finally(this.Refresh)
  }
}

export default BPHistory;
