import React from 'react';
import styles from "./livemap.module.scss";
import CardManagement from "../../Components/Cards/CardManagement";
import {OpenRecord} from "../../helpers/runscripts";

interface IProps {
    listId: number
    id: number
    name: string
    wasOnSite: boolean
    refName: "FSMDispatchSchedule" | "FSMWorkOrders"
    onDispatchMouseEvent: (e: React.MouseEvent<HTMLSpanElement>) => void
}

const WoRow = (props: IProps) => {
    const {listId, id, name, refName, wasOnSite} = props

    const OpenDispatchCard = (e: any) => {
        e.nativeEvent.preventDefault();
        if (refName === "FSMDispatchSchedule") {
            CardManagement.OpenDispatchCard({
                newDispatch: false,
                dsId: +id,
            });
        } else OpenRecord(refName, +id);
    };


    return <div className={`${styles.DeviceDispatch} ${wasOnSite ? styles.DeviceDispatchWasOnSite : ""}`}>
            <span
                className={styles.DeviceDispatchWO}
                data-listid={listId}
                data-dispatchid={id}
                onContextMenu={OpenDispatchCard}
                onClick={props.onDispatchMouseEvent}
                onMouseOver={props.onDispatchMouseEvent}
                onMouseOut={props.onDispatchMouseEvent}
            >
                {name}
            </span>
    </div>
}

export default WoRow