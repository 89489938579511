import {IAddressInfo} from "./interfaces";
import axios from "axios";
import {mapQuestKey} from "../../helpers/settings";
import {showRequestError} from "../../helpers/axiosHelpers";
import booleanClockwise from "@turf/boolean-clockwise";

export const DEFAULT_LOCATION_RADIUS = 100;
export const DEFAULT_SITE_RADIUS = 300;

export const GetFullAddressString = (addressInfo: IAddressInfo) => {
    const strElements = [];
    if (addressInfo.LineOne) strElements.push(addressInfo.LineOne);
    if (addressInfo.LineTwo) strElements.push(addressInfo.LineTwo);
    if (addressInfo.City) strElements.push(addressInfo.City);
    const stateZipEls = [];
    if (addressInfo.State) stateZipEls.push(addressInfo.State);
    if (addressInfo.ZIP) stateZipEls.push(addressInfo.ZIP);
    if (strElements.length) strElements.push(stateZipEls.join(" "));
    if (addressInfo.Country) strElements.push(addressInfo.Country);
    return strElements.join(", ");
};

export const GetAddressFromPlace = (place: any) => {
    const addressResult = place.properties;
    const [Lng, Lat] = place.geometry.coordinates;
    return {
        County: addressResult.county,
        City: addressResult.city,
        ZIP: addressResult.postalCode,
        LineOne: addressResult.street,
        LineTwo: "",
        Country: addressResult.countryCode,
        State: addressResult.stateCode,
        Lat,
        Lng,
    } as IAddressInfo;
}

export const GetDefaultBoundaries = (map: any) => {
    const bounds = map.getBounds().pad(-0.1),
        latlngs = [bounds.getSouthWest(), bounds.getSouthEast(),
            bounds.getNorthEast(), bounds.getNorthWest()];

    return polygonPointsToBoundaries(latlngs)
}

export const polygonPointsToBoundaries = (points: IPoint[]) => {
    const ring = [...points, points[0]]
    if (booleanClockwise(ring.map(p => [p.lng, p.lat]))) {
        ring.reverse();
    }
    return ring
        .map(({lat, lng}) => `${lng.toFixed(7)} ${lat.toFixed(7)}`)
        .join(', ')
}

export const LoadAddressInfo = async (lat: number, lng: number) => {
    try {
        let result = await axios({
            url: `https://www.mapquestapi.com/geocoding/v1/address`,
            method: "GET",
            params: {
                key: mapQuestKey,
                location: `${lat},${lng}`,
            },
        });
        let eventResponse = result.data;
        const addressResult = eventResponse.results[0].locations[0];
        let {lat: Lat, lng: Lng} = addressResult.displayLatLng;
        return {
            County: addressResult.adminArea4,
            City: addressResult.adminArea5,
            ZIP: addressResult.postalCode,
            LineOne: addressResult.street,
            LineTwo: "",
            Country: addressResult.adminArea1,
            State: addressResult.adminArea3,
            Lat,
            Lng,
        } as IAddressInfo;
    } catch (e: any) {
        showRequestError(e, "Get Address Info Error");
    }
};
export const getMaxRadiusBoundaries = (center: any, points: any[]) => {
    let maxRadius = 100

    points.forEach(point => maxRadius = Math.max(maxRadius, center.distanceTo(point)))

    return Math.round(maxRadius)
}

export interface IPoint {
    lat: number
    lng: number
}
