import {Checkbox, CheckboxChangeEvent} from '@progress/kendo-react-inputs';
import {ISetIncludeProps} from './interfaces';

interface props {
  groupName: string
  checked: boolean
  disabled: boolean
  queryProps: ISetIncludeProps
  onChange: any
  className?: string
}

export default function IncludeCheckbox(props: props) {
  const OnChange = (event: CheckboxChangeEvent) => {
    props.onChange({...props.queryProps, Included: event.value})
  }
  return <Checkbox
    id={props.groupName}
    checked={props.checked}
    disabled={props.disabled}
    onChange={OnChange}
    className={props.className}
    label=" "
  />;
}
