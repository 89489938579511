import React, {useRef, useState} from 'react';
import styles from "./livemap.module.scss";
import {Popover} from "@progress/kendo-react-tooltip";
import {ITE, ITimeLineRowRenderItem} from "./interfaces";

interface IProps {
    isShownStateAllocation?: boolean
    action: any
    item: ITimeLineRowRenderItem
    pinnedColor: string | null
    listId: number
    clockInId: number
    onTrackItemMouseEvent: (e: React.MouseEvent<HTMLDivElement>) => void
    tcTE?: ITE
}

const TERow = (props: IProps) => {
    const {
        listId,
        clockInId,
        item,
        pinnedColor,
        tcTE,
    } = props
    const {
        Id,
        Start,
        Finish,
        SiteName,
        Duration,
        Type,
        IsUnscheduledSite,
        CostTypeColor,
    } = item
    const [isShownPopover, setIsShownPopover] = useState(false)
    const elementRef = useRef<HTMLDivElement | null>(null)

    const renderPopoverContent = () => {
        if (tcTE) {
            return <div style={{maxWidth: 200}}>
                {IsUnscheduledSite && <b>Unscheduled visit</b>}
                {!!item.ActualDurationString && <div>Duration: {item.ActualDurationString}</div>}
                {!!item.ApprovedDurationString && <div>Approved: {item.ApprovedDurationString}</div>}
                <div>Event: {tcTE.Event}</div>
                {!!tcTE.CostTypeTitle && <div>Cost Type: {tcTE.CostTypeTitle}</div>}
                {!!tcTE.SiteTitle && <div>Site: {tcTE.SiteTitle}</div>}
                {(!!tcTE.WorkOrderSiteTitle && tcTE.WorkOrderSiteTitle !== tcTE.SiteTitle) &&
                    <div>Work Order Site: {tcTE.WorkOrderSiteTitle}</div>}
                {!!tcTE.WorkOrderTitle && <div>Work Order: {tcTE.WorkOrderTitle}</div>}
                {!!tcTE.LocationTitle && <div>Location: {tcTE.LocationTitle}</div>}
            </div>
        }
        if (IsUnscheduledSite) return <div>Unscheduled visit</div>
    }

    const isClockInOut = Type === 'OUT' || Type === 'IN'
    let content = ''
    switch (Type) {
        case 'B':
            content = 'BAD GPS'
            break;
        case 'N':
            content = 'No GPS'
            break;
        case 'S':
            content = SiteName || ''
            break;
        case 'OUT':
            content = (item.ClockOutType !== 'CLOCKOUT' && item.ClockOutType) || ''
            break;
        case 'LUNCH':
            content = 'LUNCH'
            break;
    }

    const onMouseEvent = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isClockInOut && (IsUnscheduledSite || tcTE)) {
            const value = e.type === "mouseover"
            if (isShownPopover === value) return
            setIsShownPopover(value)
        }
        props.onTrackItemMouseEvent(e)
    }
    const isClockIn = Type === 'IN'
    const isClockOut = Type === 'OUT'
    let costTypeBG =
        item.Type === "N"
            ? "repeating-linear-gradient(to bottom," +
            CostTypeColor +
            "," +
            CostTypeColor +
            " 3px,#fff 3px,#fff 6px)"
            : CostTypeColor;
    return <React.Fragment>
        {!!Start && <span className={styles.DevicePeriodTime}>{Start}</span>}
        <div
            key={Id}
            ref={elementRef}
            className={`${styles.TERow} device-te ${isClockInOut ? styles.TERowClockType : ''}`}
            style={isClockOut ? {marginBottom: -15} : isClockIn ? {marginTop: -10} : undefined}
            data-periodid={Id}
            data-clockinid={clockInId}
            data-listid={listId}
            data-eventtype={Type}
            data-mainobjectid={item.MainObjectId}
            onClick={props.onTrackItemMouseEvent}
            onMouseOut={onMouseEvent}
            onMouseOver={onMouseEvent}
        >
            <div className={styles.TERowLeft}>
                {props.action}
                {Type === 'IN' &&
                    <div className={styles.ClockInDevicePlatform}>
                        <div className={styles.DevicePlatform}>
                            {props.item.ClockInPlatform === "ios" ?
                                <i className="fi-social-apple"></i>
                                : <i className="fi-social-android"></i>
                            }
                        </div>
                    </div>}
                {Type === 'OUT' &&
                    <span className={styles.DevicePeriodClockOut}></span>
                }
                {!isClockInOut &&
                    <span
                        className={`${styles.DevicePeriodTELine} ${
                            !item.IsApprovedHours
                                ? styles.Thin
                                : ""
                        }`}
                        style={{background: costTypeBG}}
                    >
                        {item.Number !== undefined && <span
                            className={styles.DevicePeriodNumber}
                            style={{
                                background: CostTypeColor,
                                borderColor: pinnedColor || '#555' // ??
                            }}
                        >{item.Number}</span>}
                    </span>
                }
            </div>
            {!!Duration && <span className={styles.ListItemDuration}>{Duration}</span>}
            <span className={styles.DevicePeriodLocation}>
                {IsUnscheduledSite &&
                    <span className={'mdi mdi-exclamation'} style={{color: '#ff4566'}}></span>}
                <span>{content}</span>
            </span>
        </div>
        {(!!IsUnscheduledSite || !!tcTE) && <Popover
            animate={false}
            show={isShownPopover}
            position={'left'}
            anchor={elementRef && elementRef.current}
            margin={props.isShownStateAllocation ? {horizontal: 28, vertical: 0} : undefined}
        >
            {renderPopoverContent()}
        </Popover>}
        {!!Finish && <span className={styles.DevicePeriodTime}>{Finish}</span>}
    </React.Fragment>

}

export default TERow