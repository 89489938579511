import {Button} from '@progress/kendo-react-buttons';
import {useContext} from 'react';
import {
  IntegrationWithDesktopContext,
  isConterra
} from '../../helpers/settings';
import styles from './card.module.scss'

interface props {
  title: string
  notCardHeaderBtn?: boolean

  onClick(): any
}

export default function OpenCardIconLink(props: props) {
  const theme = useContext(IntegrationWithDesktopContext);
  if (!isConterra && !theme.isIntegrationWithDesktop) return null
  return <Button
    className={props.notCardHeaderBtn ? undefined : styles.HeaderLinkBtn}
    icon="hyperlink-open"
    fillMode="flat"
    onClick={props.onClick}
    title={props.title}
  ></Button>
}
