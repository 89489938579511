import {IAdjustmentAllocation} from "../../../Pages/TKReview/interfaces";
import {IListWOItem} from "../../../Components/Cards/interfaces";

export const getDefaultStateCode = (allocation: IAdjustmentAllocation[], workOrders: IListWOItem[]) => {
    // if (data.StateCode !== null) return data.StateCode
    const currentWorkOrders = workOrders.filter(({Id}) => allocation.some(({WorkOrderId}) => WorkOrderId === Id))
    if (!currentWorkOrders.length) return null
    if (currentWorkOrders.length === 1) return currentWorkOrders[0].DefaultStateCode

    const uniqueStatesCodes = new Set(currentWorkOrders.map(({DefaultStateCode}) => DefaultStateCode))
    if (uniqueStatesCodes.size === 1) return Array.from(uniqueStatesCodes)[0]
    return null
}