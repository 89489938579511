import {Component} from 'react';
import moment from 'moment';
import {Button} from '@progress/kendo-react-buttons';
import styles from './bpdashboard.module.scss';

interface props {
  weekOffset: number

  onChangeDate(weekOffset: number): void

  getWeekDates(weekOffset: number): Array<moment.Moment>
}

interface state {
  currentWeek: string
}

class SwitchDates extends Component<props, state> {
  weekOffset: number = this.props.weekOffset

  constructor(props: props) {
    super(props)
    this.state = {
      currentWeek: this.GetWeekDate()
    }
  }

  render() {
    return <>
      <Button onClick={this.SetCurrentWeek} data-action="today"
              style={{position: 'relative', zIndex: 2}}>Today</Button>
      <Button icon="arrow-60-left" onClick={this.SetPrevWeek} data-action="prev"
              style={{position: 'relative', zIndex: 2}}></Button>
      <div className={styles.CurrentWeek} style={{
        position: 'relative',
        zIndex: 2
      }}>{this.state.currentWeek}</div>
      <Button icon="arrow-60-right" onClick={this.SetNextWeek}
              data-action="next"
              style={{position: 'relative', zIndex: 2}}></Button>
    </>
  }

  SetCurrentWeek = () => {
    this.weekOffset = 0
    this.OnChangeWeek()
  }

  GetWeekDate = () => {
    let [beginDate, endDate] = this.props.getWeekDates(this.weekOffset)
    return beginDate.format('MMM D') + ' - ' + endDate.format('MMM D');
  }

  SetPrevWeek = () => {
    this.weekOffset -= 1;
    this.OnChangeWeek()
  }

  SetNextWeek = () => {
    this.weekOffset += 1;
    this.OnChangeWeek()
  }

  OnChangeWeek = () => {
    this.setState({currentWeek: this.GetWeekDate()})
    this.props.onChangeDate(this.weekOffset)
  }

}

export default SwitchDates;
