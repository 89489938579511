import {Component} from 'react';
import {IGroupedVIMLIst} from './interfaces';
import ExpandedHeader
  from '../../Components/Common/ExpandedPanel/ExpandedHeader'

interface props {
  className?: string
  expanded: boolean
  header: string | any
  level: 1 | 2
  headerDataText?: string
  headerDataId: string
  dataAttr: any
  groupedList: IGroupedVIMLIst

  onToggleExpand(): void
}

interface state {
  expanded: boolean
}

class VIMExpandedPanel extends Component<props, state> {

  constructor(props: any) {
    super(props);
    this.state = {
      expanded: this.props.expanded
    }
  }

  componentDidUpdate(prevProps: props) {
    if (prevProps.expanded !== this.props.expanded && this.props.expanded !== this.state.expanded) {
      this.setState({expanded: this.props.expanded});
    }
  }

  render() {
    return (
      <ExpandedHeader
        expanded={this.state.expanded}
        className={this.props.className}
        level={this.props.level}
        dataText={this.props.headerDataText}
        dataId={this.props.headerDataId}
        onToggle={this.TogglePanel}
        content={this.props.header}
      />
    )
  }

  TogglePanel = () => {
    this.props.onToggleExpand()
    let expanded = true
    this.setState((state) => {
      expanded = !state.expanded
      this.props.dataAttr.Expanded = expanded
      let row = this.props.dataAttr
      let userId = row.UserId
      let userData = this.props.groupedList[userId]
      if (row.type === 'user') {
        userData.expanded = expanded
      } else if (userData.groupsByStatus[row.name]) {
        userData.groupsByStatus[row.name].expanded = expanded
      }
      return {expanded}
    });

  }

  ChangeExpanded = (expanded: boolean) => {
    this.setState({expanded})
  }
}

export default VIMExpandedPanel;
