import React from "react";
import DashboardGrid from "../Components/Dashboard/Grid";
import {aggregateType, IColumnSetting, IFilterSetting,} from "../Components/Dashboard/interfaces";
import {IComboboxItem, simpleObject} from "../helpers/interfaces";
import BPTabsPage from "./BPTabs";

import UserInfo from '../stores/User';
import {Button} from "@progress/kendo-react-buttons";
import BaseComponent from "../Components/BaseComponent";
import CardManagement from "../Components/Cards/CardManagement";
import ButtonLink from "../Components/Common/Buttons/ButtonLink";
import LoaderComponent from "../Components/Common/Loader";
import style from "../Components/Dashboard/dashboard.module.scss";
import {LoadBuildPlans} from "../helpers/LoadLists";
import {GetComboboxYearsList} from "../helpers/helpers";

interface Milestone {
    Code: string;
    Name: string;
}

interface state {
    milestones: Array<Milestone> | null;
}

const numberColumnWidth = 100;

class StatusDashboard extends BaseComponent<{ isActive: boolean }, state> {
    gridRef: any = React.createRef();
    tabsRef: any = React.createRef();
    canGenerateBP: boolean = false
    filters: Array<IFilterSetting> = [];

    COLUMNS: Array<IColumnSetting> = [
        {
            field: "RowNum",
            title: "#",
            type: "number",
            gridWidth: 40,
            filterable: false,
            columnMenu: false,
            locked: true,
            reorderable: false,
            aggregate: "count",
        },
        {
            field: "BPNumber",
            title: "Build Plan",
            type: "string",
            gridWidth: 200,
            format: {
                type: "link",
                fieldId: "BPID",
                refName: "FSMBuildPlans",
            },
            locked: true,
            reorderable: false,
        },
        {
            field: "BPStateName",
            title: "BP State",
            type: "string",
            gridWidth: 100,
            format: {
                type: "string",
                fieldId: "BPState",
            },
            locked: true,
            reorderable: false,
        },
        {
            field: "ProjectName",
            title: "Project",
            type: "string",
            gridWidth: 120,
            locked: true,
            format: {
                type: "string",
                fieldId: "ProjectID",
            },
        },
        {
            field: "ScenarioName",
            title: "Scenario",
            type: "string",
            gridWidth: 120,
            locked: true,
            hiddenMode: true,
            format: {
                type: "string",
                fieldId: "ScenarioID",
            },
        },
        {
            field: "RegionName",
            title: "Region",
            type: "string",
            gridWidth: 120,
            locked: true,
            hiddenMode: true,
            format: {
                type: "string",
                fieldId: "RegionID",
            },
        },
        {
            field: "MarketName",
            title: "Market",
            type: "string",
            gridWidth: 100,
            locked: true,
            format: {
                type: "string",
                fieldId: "MarketID",
            },
        },
        {
            field: "CustomerName",
            title: "Customer",
            type: "string",
            gridWidth: 120,
            locked: true,
            hiddenMode: true,
            format: {
                type: "string",
                fieldId: "CustomerID",
            },
        },
        {
            field: "CustomerJobName",
            title: "CustomerJob",
            type: "string",
            gridWidth: 180,
            locked: true,
            hiddenMode: true,
            format: {
                type: "string",
                fieldId: "CustomerJobID",
            },
        },
        {
            field: "CarrierName",
            title: "Carrier",
            type: "string",
            gridWidth: 120,
            locked: true,
            hiddenMode: true,
            format: {
                type: "string",
                fieldId: "CarrierID",
            },
        },
        {
            field: "ClassName",
            title: "Class",
            type: "string",
            gridWidth: 120,
            locked: true,
            hiddenMode: true,
            format: {
                type: "string",
                fieldId: "ClassID",
            },
        },
        {
            field: "CategoryName",
            title: "Category",
            type: "string",
            gridWidth: 180,
            locked: true,
            hiddenMode: true,
            format: {
                type: "string",
                fieldId: "CategoryID",
            },
        },
        {
            field: "SiteName",
            title: "Site ID",
            type: "string",
            gridWidth: 120,
            locked: true,
            hiddenMode: true,
            format: {
                type: "link",
                fieldId: "SiteID",
                refName: "FSMSites",
            },
        },
        {
            field: "SiteAddress",
            title: "Site Address",
            type: "string",
            gridWidth: 180,
            locked: true,
            hiddenMode: true,
        },
        {
            field: "BPOName",
            title: "Build Plan Owner",
            type: "string",
            gridWidth: 150,
            locked: true,
            format: {
                type: "string",
                fieldId: "BPOID",
            },
        },
        {
            field: "ProfitCenterName",
            title: "Profit Center",
            type: "string",
            gridWidth: 120,
            locked: true,
            hiddenMode: true,
            format: {
                type: "string",
                fieldId: "ProfitCenterID",
            },
        },
        {
            field: "CrewLeadName",
            title: "Crew Lead",
            type: "string",
            gridWidth: 120,
            locked: true,
            hiddenMode: true,
            format: {
                type: "string",
                fieldId: "CrewLeadID",
            },
        },
        {
            field: "COPLeadName",
            title: "COP Lead",
            type: "string",
            gridWidth: 120,
            locked: true,
            hiddenMode: true,
            format: {
                type: "string",
                fieldId: "COPLeadID",
            },
        },
        {
            field: "PermitStatusName",
            title: "Permit Status",
            type: "string",
            gridWidth: 120,
            hiddenMode: true,
            format: {
                type: "string",
                fieldId: "PermitStatusID",
            },
        },
        {
            field: "PermitStatusComment",
            title: "Permit Status Comment",
            type: "string",
            minWidth: 300,
            minResizableWidth: 300,
            hiddenMode: true,
        },
        {
            field: "CXStatusName",
            title: "CX Status",
            type: "string",
            gridWidth: 120,
            hiddenMode: true,
            format: {
                type: "string",
                fieldId: "CXStatusID",
            },
        },
        {
            field: "CXStatusComment",
            title: "CX Status Comment",
            type: "string",
            minWidth: 300,
            minResizableWidth: 300,
            hiddenMode: true,
        },
        {
            field: "Started",
            title: "Started",
            type: "date",
            format: {
                type: "date",
            },
        },
        {
            field: "SalesOrder",
            title: "Sales Order Total",
            type: "number",
            gridWidth: 130,
            format: {
                type: "currency",
            },
            aggregate: "sum",
            headerHint: "Total of all Sales Orders in Build Plan",
        },
        {
            field: "LastQuoteDate",
            title: "Sales Order Date",
            type: "date",
            format: {
                type: "date",
            },
            hiddenMode: true,
        },
        {
            field: "LastQuoteSubmitted",
            title: "Submitted to Customer",
            type: "date",
            format: {
                type: "date",
            },
            hiddenMode: true,
        },
        {
            field: "LastPODate",
            title: "PO Received",
            type: "date",
            format: {
                type: "date",
            },
            hiddenMode: true,
        },
        {
            field: "UnallocatedCustomerPO",
            title: "Unallocated Customer PO Total",
            type: "number",
            gridWidth: 105,
            format: {
                type: "currency",
            },
        },
        {
            field: "AllocatedCustomerPO",
            title: "Allocated Customer PO Total",
            type: "number",
            gridWidth: 105,
            format: {
                type: "currency",
            },
            aggregate: "sum",
        },
        {
            field: "SOPOVariance",
            title: "Sales Order & PO Variance",
            type: "number",
            gridWidth: 130,
            format: {
                type: "currency",
            },
            hiddenMode: true,
            headerHint: "SO Total - Unallocated PO Total - Allocated PO Total",
        },
        {
            field: "SOProjectedRevenueVariance",
            title: "Sales Order and Budget Revenue Variance",
            type: "number",
            format: {
                type: "currency",
            },
            hiddenMode: true,
            headerHint: "Sales Order Total - Budget Total Revenue",
        },
        {
            field: "ProjectedRevenue",
            title: "Budget Total Revenue",
            type: "number",
            gridWidth: 105,
            format: {
                type: "currency",
            },
            headerHint: "Total Planned Revenue from Budget Dashboard",
        },
        {
            field: "BudgetStatus",
            title: "Budget Status",
            type: "string",
            gridWidth: 70,
            format: {
                type: "string",
                fieldId: "BudgetStatus",
            },
        },
        {
            field: "BudgetApproved",
            title: "Budget Approved",
            type: "date",
            format: {
                type: "date",
            },
            hiddenMode: true,
        },
        {
            field: "InitilalBomSentWO",
            title: "BOM Sent to Warehouse",
            type: "date",
            format: {
                type: "date",
            },
            hiddenMode: true,
        },
        {
            field: "BomTotal",
            title: "BOM Total",
            type: "number",
            gridWidth: 125,
            format: {
                type: "currency",
            },
            headerHint: "Total of All BOMs Amount Associated to Build Plan",
            hiddenMode: true,
        },
        {
            field: "KittedBOM",
            title: "Kitted to Date",
            type: "number",
            format: {
                type: "currency",
                customColor: true,
                getColor: function (dataItem) {
                    if (dataItem.KittedBOM > dataItem.BomTotal) return "red";
                    return "";
                },
            },
            headerHint: "Total of All Kitted BOMs",
            hiddenMode: true,
        },
        {
            field: "ms_SteelOrder_Projected",
            title: "Steel Ordered Projected",
            type: "date",
            hiddenMode: true,
            format: {
                type: "date",
            },
        },
        {
            field: "ms_SteelOrder_Actual",
            title: "Steel Ordered Actual",
            type: "date",
            hiddenMode: true,
            format: {
                type: "date",
            },
        },
        {
            field: "ms_ConstStart_Projected",
            title: "Construction Start Projected",
            type: "date",
            format: {
                type: "date",
                customColor: true,
                getColor: function (dataItem) {
                    if (
                        dataItem.ms_ConstStart_Projected < new Date() &&
                        dataItem.ms_ConstStart_Actual === null
                    )
                        return "red";
                    return "";
                },
            },
            hiddenMode: true,
        },
        {
            field: "ms_ConstStart_Actual",
            title: "Construction Start Actual",
            type: "date",
            format: {
                type: "date",
            },
        },
        {
            field: "ms_CraneLift_Projected",
            title: "Crane Lift Projected",
            type: "date",
            hiddenMode: true,
            format: {
                type: "date",
            },
        },
        {
            field: "ms_CraneLift_Actual",
            title: "Crane Lift Actual",
            type: "date",
            hiddenMode: true,
            format: {
                type: "date",
            },
        },
        {
            field: "ms_CommStart_Projected",
            title: "Commission Start Projected",
            type: "date",
            hiddenMode: true,
            format: {
                type: "date",
            },
        },
        {
            field: "ms_CommStart_Actual",
            title: "Commission Start Actual",
            type: "date",
            hiddenMode: true,
            format: {
                type: "date",
            },
        },
        {
            field: "ms_CommCompl_Projected",
            title: "Commission Complete Projected",
            type: "date",
            hiddenMode: true,
            format: {
                type: "date",
            },
        },
        {
            field: "ms_CommCompl_Actual",
            title: "Commission Complete Actual",
            type: "date",
            hiddenMode: true,
            format: {
                type: "date",
            },
        },
        {
            field: "ms_IntegStart_Projected",
            title: "Integration Start Projected",
            type: "date",
            hiddenMode: true,
            format: {
                type: "date",
            },
        },
        {
            field: "ms_IntegStart_Actual",
            title: "Integration Start Actual",
            type: "date",
            hiddenMode: true,
            format: {
                type: "date",
            },
        },
        {
            field: "ms_IntegCompl_Projected",
            title: "Integration Complete Projected",
            type: "date",
            hiddenMode: true,
            format: {
                type: "date",
            },
        },
        {
            field: "ms_IntegCompl_Actual",
            title: "Integration Complete Actual",
            type: "date",
            hiddenMode: true,
            format: {
                type: "date",
            },
        },
        {
            field: "BudgetHrs",
            title: "Budgeted Field Labor Hours",
            type: "number",
            gridWidth: 120,
            format: {
                type: "number",
            },
            headerHint: "Total Planned Labor Hours from Budget Dashboard",
        },
        {
            field: "HoursConsumed",
            title: "Hours Consumed",
            type: "number",
            gridWidth: 100,
            format: {
                type: "number",
                customColor: true,
                getColor: function (dataItem) {
                    if (dataItem.HoursConsumed > dataItem.BudgetHrs) return "red";
                    return "";
                },
            },
            headerHint: "Total Spend Labor Hours Allocated to Build Plan",
        },
        {
            field: "HoursRemaining",
            title: "Remaining Hours to Complete",
            type: "number",
            gridWidth: 130,
            format: {
                type: "number",
            },
            headerHint: "Budgeted Field Labor Hours - Hours Consumed",
        },
        {
            title: "PW by default",
            field: "PwByDefaultStr",
            type: "string",
            format: {
                type: "string",
                fieldId: "PwByDefault",
            },
            hiddenMode: true,
        },
        {
            title: "PW last update",
            field: "PwLastUpdate",
            type: "date",
            format: {
                type: "datetime",
            },
            hiddenMode: true,
        },
        {
            field: "Completion",
            title: "Estimated Completion",
            type: "number",
            gridWidth: 100,
            format: {
                type: "percent",
            },
            headerHint: "% as Noted in Build Plan",
        },
        {
            field: "ms_ConsCompl_Projected",
            title: "Construction Complete Proj.",
            type: "date",
            format: {
                type: "date",
                customColor: true,
                getColor: (dataItem) => {
                    if (
                        dataItem.ms_ConsCompl_Projected < new Date() &&
                        dataItem.ms_ConsCompl_Actual === null
                    ) {
                        return "red";
                    }
                    return "";
                },
            },
            hiddenMode: true,
        },
        {
            field: "ms_ConsCompl_Actual",
            title: "Construction Complete Actual",
            type: "date",
            format: {
                type: "date",
            },
        },
        {
            field: "ms_COPSubmit_Projected",
            title: "COP Submitted Projected",
            type: "date",
            hiddenMode: true,
            format: {
                type: "date",
            },
        },
        {
            field: "ms_COPSubmit_Actual",
            title: "COP Submitted Actual",
            type: "date",
            hiddenMode: true,
            format: {
                type: "date",
            },
        },
        {
            field: "ms_COPReject_Actual",
            title: "COP Rejected Actual",
            type: "date",
            hiddenMode: true,
            format: {
                type: "date",
            },
        },
        {
            field: "ms_COPResubm_Actual",
            title: "COP Resubmitted Actual",
            type: "date",
            hiddenMode: true,
            format: {
                type: "date",
            },
        },
        {
            field: "ms_COPAppr_Projected",
            title: "COP Approved Projected",
            type: "date",
            hiddenMode: true,
            format: {
                type: "date",
            },
        },
        {
            field: "ms_COPAppr_Actual",
            title: "COP Approved Actual",
            type: "date",
            hiddenMode: true,
            format: {
                type: "date",
            },
        },
        {
            field: "ProjectedSpend",
            title: "Projected Spend",
            type: "number",
            gridWidth: 115,
            format: {
                type: "currency",
            },
            headerHint: "Current Planned Spend from Budget Dashboard",
        },
        {
            field: "OpenBOMs",
            title: "Open BOMs",
            type: "number",
            format: {
                type: "integer",
            },
            hiddenMode: true,
        },
        {
            field: "VIMPoBalance",
            title: "VIM PO Balance",
            type: "number",
            format: {
                type: "number",
            },
            hiddenMode: true,
        },
        {
            field: "TotalSpend",
            title: "Total Spend to Date",
            type: "number",
            gridWidth: 135,
            format: {
                type: "currency",
            },
            aggregate: "sum",
            headerHint: "Total Actual Spend from Budget Dashboard",
        },
        {
            field: "TotalSpendPercent",
            title: "% of Budgeted Spend",
            type: "number",
            gridWidth: 145,
            format: {
                type: "percent",
            },
            headerHint: "%, Total Spend to Date/Projected Spend",
        },
        {
            field: "RemainingSpend",
            title: "Remaining Spend",
            type: "number",
            gridWidth: 100,
            format: {
                type: "currency",
                negativeColor: "red",
            },
            aggregate: "sum",
            headerHint: "Projected Spend - Total Spend To Date",
        },
        {
            field: "SubmittedInvoices",
            title: "Total Invoiced to Date",
            type: "number",
            gridWidth: 115,
            format: {
                type: "currency",
            },
            aggregate: "sum",
            headerHint: "Total of Receivable and Paid Total from Sales Orders",
        },
        {
            field: "LastInvoiceDate",
            title: "Invoice Submitted",
            type: "date",
            format: {
                type: "date",
            },
            hiddenMode: true,
        },
        {
            field: "RemainingToInvoice",
            title: "Remaining to Invoice",
            type: "number",
            gridWidth: 140,
            format: {
                type: "currency",
            },
            aggregate: "sum",
            headerHint: "Total of Remaining To Bill in Sales Orders",
        },
        {
            field: "ReadyToBill",
            title: "Ready To Bill",
            type: "number",
            format: {
                type: "currency",
            },
            hiddenMode: true,
        },
        {
            field: "ProjectedBillingDate",
            title: "Projected Billing Date",
            type: "date",
            gridWidth: 115,
            format: {
                type: "date",
                customColor: true,
                getColor: (dataItem) => {
                    if (
                        dataItem.ProjectedBillingDate < new Date() &&
                        dataItem.RemainingToInvoice > 0
                    ) {
                        return "red";
                    }
                    return "";
                },
            },
            hiddenMode: true,
        },
        {
            field: "ms_FinInvSubm_Projected",
            title: "Projected Final Invoice Submitted",
            type: "date",
            format: {
                type: "date",
            },
            hiddenMode: true,
        },
        {
            field: "WIP",
            title: "WIP",
            type: "number",
            format: {
                type: "currency",
            },
            aggregate: "sum",
            headerHint:
                "Variance Under Spend - Variance Under Billed, as Calculated in WIP Dashboard",
        },
        {
            field: "ExcludeFromWIPStr",
            title: "Exclude from WIP",
            type: "string",
            format: {
                type: "string",
                fieldId: "ExcludeFromWIP"
            },
        },

    ];

    BPOGridColumns: Array<{
        field: string;
        title: string;
        titleHTML?: any;
        headerClassName?: string;
        getColumnValue(row: simpleObject): boolean;
        width?: number;
        aggregate?: aggregateType;
        renderCell?(e: any, props: any): any;
        renderFooterCell?(props: any, data: Array<simpleObject>): any;
    }> = [];

    constructor(props: any) {
        super(props);
        this.BPOGridColumns = [
            {
                field: "BPOName",
                title: "Build Plan Owner",
                width: 180,
                getColumnValue: () => true,
            },
            {
                field: "ActiveBPs", // 1
                title: "Active Build Plans",
                titleHTML: (<span>Active <br/> Build Plans</span>),
                getColumnValue: (row: simpleObject) => row.BPState === "A",
                aggregate: "sum",
                width: numberColumnWidth,
                renderCell: this.renderGroupModeCell,
                renderFooterCell: this.renderGroupModeFooterCell,
            },
            {
                field: "SOEmpty", // 2
                title: "No Sales Order",
                getColumnValue: (row: simpleObject) => row.SalesOrder === null,
                aggregate: "sum",
                width: numberColumnWidth,
                renderCell: this.renderGroupModeCell,
                renderFooterCell: this.renderGroupModeFooterCell,
            },
            {
                field: "BudgetNotApproved", // 3
                title: "Budget not Approved",
                titleHTML: (<span>Budget <br/> not Approved</span>
                ),
                getColumnValue: (row: simpleObject) => (
                    row.BudgetApproved === null &&
                    !row.ExcludeFromWIP
                ),
                aggregate: "sum",
                width: numberColumnWidth,
                renderCell: this.renderGroupModeCell,
                renderFooterCell: this.renderGroupModeFooterCell,
            },
            {
                field: "BudgetNotApprovedHasSpend", // 4
                title: `Budget Not Approved, Has Spend`,
                titleHTML: (<span>Budget<br/>Not Approved, <br/>Has Spend</span>
                ),
                getColumnValue: (row: simpleObject) => (
                    row.BudgetApproved === null &&
                    row.TotalSpend > 0 &&
                    !row.ExcludeFromWIP
                ),
                aggregate: "sum",
                width: numberColumnWidth,
                renderCell: this.renderGroupModeCell,
                renderFooterCell: this.renderGroupModeFooterCell,
            },
            {
                field: "BudgetNotApprovedHasRevenue", // 5
                title: "Budget Not Approved, Has Revenue",
                titleHTML: (<span>Budget<br/>Not Approved,<br/>Has Revenue</span>),
                getColumnValue: (row: simpleObject) => (
                    row.BudgetApproved === null &&
                    row.SubmittedInvoices > 0 &&
                    !row.ExcludeFromWIP
                ),
                aggregate: "sum",
                width: numberColumnWidth,
                renderCell: this.renderGroupModeCell,
                renderFooterCell: this.renderGroupModeFooterCell,
            },
            {
                field: "BudgetNotEqualSalesOrder", // 'SOProjectedRevenueVariance',
                title: "Budget doesn't equal Sales Order",
                titleHTML: (<span>Budget<br/>doesn't equal<br/>Sales Order</span>
                ),
                getColumnValue: (row: simpleObject) => {
                    return (
                        row.SOProjectedRevenueVariance !== null &&
                        row.SOProjectedRevenueVariance !== 0 &&
                        !row.ExcludeFromWIP
                    )
                },
                aggregate: "sum",
                width: numberColumnWidth,
                renderCell: this.renderGroupModeCell,
                renderFooterCell: this.renderGroupModeFooterCell,
            },
            {
                field: "SpendExceedsBudget", // 6
                title: "Spend Exceeds Budget",
                titleHTML: (<span>Spend Exceeds<br/>Budget</span>
                ),
                getColumnValue: (row: simpleObject) => (
                    row.BudgetApproved !== null &&
                    row.TotalSpend > row.ProjectedSpend &&
                    !row.ExcludeFromWIP
                ),
                aggregate: "sum",
                width: numberColumnWidth,
                renderCell: this.renderGroupModeCell,
                renderFooterCell: this.renderGroupModeFooterCell,
            },
            {
                field: "RevenueExceedsBudget", // 7
                title: "Revenue Exceeds Budget",
                titleHTML: (<span>Revenue Exceeds<br/>Budget</span>
                ),
                getColumnValue: (row: simpleObject) => (
                    row.BudgetApproved !== null &&
                    row.SubmittedInvoices > row.ProjectedRevenue &&
                    !row.ExcludeFromWIP
                ),
                aggregate: "sum",
                width: numberColumnWidth,
                renderCell: this.renderGroupModeCell,
                renderFooterCell: this.renderGroupModeFooterCell,
            },
            {
                field: "CXStartPending", // 8 +
                title: "CX Start Pending",
                headerClassName: `${style.THWithArrow} ${style.DarkBlue}`,
                getColumnValue: (row: simpleObject) => {
                    return row.ms_ConstStart_Actual === null;
                },
                aggregate: "sum",
                width: numberColumnWidth,
                renderCell: this.renderGroupModeCell,
                renderFooterCell: this.renderGroupModeFooterCell,
            },
            {
                field: "CXStartActualCXCompletePending", // 9 +
                title: "CX Started and not Completed",
                titleHTML: (
                    <span>
            CX Started
            <br/>
            and
            <br/>
            not Completed
          </span>
                ),
                headerClassName: `${style.THWithArrow} ${style.GreyPink}`,
                getColumnValue: (row: simpleObject) => {
                    return (
                        row.ms_ConstStart_Actual !== null &&
                        row.ms_ConsCompl_Actual === null
                    );
                },
                aggregate: "sum",
                width: numberColumnWidth + 20,
                renderCell: this.renderGroupModeCell,
                renderFooterCell: this.renderGroupModeFooterCell,
            },
            {
                field: "CXCompleteActualCOPApprovedPending", // 10
                title: "CX Completed, COP not Approved",
                titleHTML: (
                    <span>
            CX Completed,
            <br/>
            COP
            <br/>
            not Approved
          </span>
                ),
                headerClassName: `${style.THWithArrow} ${style.Blue}`,
                getColumnValue: (row: simpleObject) => {
                    return (
                        row.ms_ConsCompl_Actual !== null &&
                        row.ms_COPAppr_Actual === null
                    );
                },
                aggregate: "sum",
                width: numberColumnWidth + 20,
                renderCell: this.renderGroupModeCell,
                renderFooterCell: this.renderGroupModeFooterCell,
            },
            {
                field: "COPApprovedRemainingToInvoice", // 11
                title: "COP Approved, w/ Remaining to Invoice",
                titleHTML: (
                    <span>
            COP Approved,
            <br/>
            w/ Remaining
            <br/>
            to Invoice
          </span>
                ),
                headerClassName: `${style.THWithArrow} ${style.GreenBlue}`,
                getColumnValue: (row: simpleObject) => {
                    return row.ms_COPAppr_Actual !== null && row.RemainingToInvoice > 0;
                },
                aggregate: "sum",
                width: numberColumnWidth + 20,
                renderCell: this.renderGroupModeCell,
                renderFooterCell: this.renderGroupModeFooterCell,
            },
            {
                field: "COPApprovedNoRemainingToInvoice",
                title: "COP Approved, No Remaining to Invoice",
                titleHTML: (
                    <span>
            COP Approved,
            <br/>
            No Remaining
            <br/>
            to Invoice
          </span>
                ),
                headerClassName: `${style.THWithArrow} ${style.Orange}`,
                getColumnValue: (row: simpleObject) => {
                    return (
                        row.ms_COPAppr_Actual !== null &&
                        (row.RemainingToInvoice === 0 || row.RemainingToInvoice === null)
                    );
                },
                aggregate: "sum",
                width: numberColumnWidth + 20,
                renderCell: this.renderGroupModeCell,
                renderFooterCell: this.renderGroupModeFooterCell,
            },
            {
                field: "COPApprovedReadyToClose", // 'COPApprovedReadyToClose',
                title: "COP Approved Ready to Close",
                titleHTML: (
                    <span>
            COP Approved
            <br/>
            Ready to Close
          </span>
                ),
                headerClassName: `${style.THWithArrow} ${style.Red}`,
                getColumnValue: (row: simpleObject) => {
                    return (
                        row.ms_COPAppr_Actual !== null &&
                        (row.OpenBOMs === 0 || row.OpenBOMs === null) &&
                        (row.VIMPoBalance === 0 || row.VIMPoBalance === null) &&
                        (row.RemainingToInvoice === 0 || row.RemainingToInvoice === null)
                    );
                },
                aggregate: "sum",
                width: numberColumnWidth + 20,
                renderCell: this.renderGroupModeCell,
                renderFooterCell: this.renderGroupModeFooterCell,
            },
        ];

        let predefinedFilterValues: Array<IComboboxItem> = [];
        this.BPOGridColumns.forEach((column) => {
            if (column.field !== "BPOName" && column.field !== "ActiveBPs") {
                predefinedFilterValues.push({
                    Id: column.field,
                    Name: column.title,
                });
            }
        });

        this.filters = [
            {
                id: "filterYear",
                placeholder: "Filter by Year",
                type: "combobox",
                values: GetComboboxYearsList(),
                serverFilter: {
                    param: "yearNum",
                },
                toolbarRow: 1,
            },
            {
                id: "filterBP",
                placeholder: "Filter by Build Plan",
                type: "combobox",
                isExclusive: true,
                showOnlyNoMode: true,
                serverFilter: {
                    param: "bpId",
                    getData: LoadBuildPlans,
                },
                toolbarRow: 2,
            },
            {
                id: "filterBPO",
                placeholder: "Filter by Owner",
                idColumn: "BPOID",
                labelColumn: "BPOName",
                showOnlyNoMode: true,
                type: "combobox",
                toolbarRow: 1,
            },
            {
                id: "predefinedFilters",
                placeholder: "Predefined Filters",
                type: "combobox",
                values: predefinedFilterValues,
                showOnlyNoMode: true,
                filterByCustom: this.FilterByPredefinedFilter,
                toolbarRow: 2,
            },
            // {
            //   id: "filterDate",
            //   placeholder: "Show data on",
            //   type: "date",
            //   width: 110,
            //   serverFilter: {
            //     param: "onDate",
            //   },
            //   toolbarRow: 1,
            // },
        ];

        this.state = {
            milestones: null,
        };
    }

    componentDidMount = async () => {
        this.canGenerateBP = await UserInfo.canGenerateBP()
        const milestones = (
            await this.GetSQLData({
                spName: "FX_GetMilestoneRecords",
            })
        )[0] as Array<Milestone>;
        const alreadyUsedMilestones = this.COLUMNS.filter((col) =>
            col.field?.startsWith("ms_")
        ).map((col) => col.field);
        milestones.forEach((ms) => {
            // Projected
            if (!alreadyUsedMilestones.includes("ms_" + ms.Code + "_Projected")) {
                this.COLUMNS.push({
                    field: "ms_" + ms.Code + "_Projected",
                    title: ms.Name + " Projected",
                    type: "date",
                    hiddenMode: true,
                    format: {
                        type: "date",
                    },
                });
            }
            // Actual
            if (!alreadyUsedMilestones.includes("ms_" + ms.Code + "_Actual")) {
                this.COLUMNS.push({
                    field: "ms_" + ms.Code + "_Actual",
                    title: ms.Name + " Actual",
                    type: "date",
                    hiddenMode: true,
                    format: {
                        type: "date",
                    },
                });
            }
        });

        this.setState({milestones: milestones});
    };

    render() {
        if (!this.state.milestones) return <LoaderComponent/>;
        return (
            <DashboardGrid
                ref={this.gridRef}
                isActive={this.props.isActive}
                isParentPage={true}
                columnsSettings={this.COLUMNS}
                filters={this.filters}
                getSqlParams={{spName: "DB_Status"}}
                saveSettings={{
                    prefix: "status",
                    saveFilters: true,
                    saveGridSettings: true,
                }}
                excelFileName="Status.xlsx"
                toolbar={this.canGenerateBP ? {
                    actionBtns: <Button
                        onClick={this.AddNewBuildPlan}
                    >New Build Plan</Button>,
                } : undefined}
                getRowKey={(dataItem: simpleObject) => dataItem.BPID}
                columnMenu={{filters: true, columns: true}}
                reorderable={true}
                initRefresh={this.InitRefresh}
                modeSettings={{
                    modeType: "group",
                    name: "Group By Owner",
                    id: "groupByOwner",
                    group: {
                        getGridData: this.GetModeGridData,
                        columns: this.BPOGridColumns,
                    },
                }}
                selectSettings={{
                    pageId: "StatusDashboard",
                    Component: BPTabsPage,
                }}
                prepareData={this.PrepareData}
            ></DashboardGrid>
        );
    }

    renderGroupModeCell = (el: any) => {
        let field = el.field;
        let data = el.dataItem;
        let value = data[field];
        let column = this.BPOGridColumns.find((column) => column.field === field);
        let columnTitle = column?.title || "";
        return (
            <td>
                {value > 0
                    ? this.renderButtonLink(
                        field,
                        columnTitle,
                        value,
                        data.BPOID,
                        data.BPOName
                    )
                    : undefined}
            </td>
        );
    };

    renderGroupModeFooterCell = (props: any, modeData: Array<simpleObject>) => {
        let field = props.field;
        let column = this.BPOGridColumns.find((column) => column.field === field);
        let columnTitle = column?.title || "";
        let sum = modeData.reduce((value, item) => value + (item[field] || 0), 0);
        return (
            <td>{sum > 0 ? this.renderButtonLink(field, columnTitle, sum) : sum}</td>
        );
    };

    renderButtonLink = (
        field: string,
        columnTitle: string,
        sum: number,
        BPOID?: number,
        BPOName?: string
    ) => {
        return (
            <ButtonLink
                text={sum + ""}
                dataAttr={{field, title: columnTitle, BPOID, BPOName}}
                onClick={this.OnClickValue}
            />
        );
    };

    OnClickValue = (e: any, dataAttr: any) => {
        if (this.gridRef?.current?.ExternalFilterGrid) {
            let filters = [
                {
                    filterId: "predefinedFilters",
                    filterValue: {Id: dataAttr.field, Name: dataAttr.title},
                },
                {
                    filterId: "filterBPState",
                    filterValue: {Id: "A", Name: "Active"},
                },
            ];
            if (dataAttr.BPOID) {
                // if !footerCell
                filters.push({
                    filterId: "filterBPO",
                    filterValue: {Id: +dataAttr.BPOID, Name: dataAttr.BPOName},
                });
            }
            this.gridRef.current.ExternalFilterGrid(filters);
        }
    };

    PrepareData = (result: any) => {
        let data = result[0];
        for (let row of data) {
            row.PwByDefaultStr = row.PwByDefault ? "Yes" : "No";
            row.ExcludeFromWIPStr = row.ExcludeFromWIP ? "Yes" : "No";
            row.BPStateName = row.BPState === "A" ? "Active" : "Closed"; // 'A' | 'C'
            for (let column of this.BPOGridColumns) {
                if (column.field === "BPOName") continue
                row[column.field] = column.getColumnValue(row)
                    ? column.field
                    : undefined;
            }
        }
        return data;
    };

    FilterByPredefinedFilter = (row: any, value: IComboboxItem) => {
        return row[value.Id] === value.Id;
    };

    GetModeGridData = (data: Array<simpleObject>) => {
        let bpoUnic: { [key: number]: Array<simpleObject> } = {};
        data.forEach((row: simpleObject) => {
            if (row.BPState === "A") {
                // ??
                if (!bpoUnic[row.BPOID]) {
                    bpoUnic[row.BPOID] = [];
                }
                bpoUnic[row.BPOID].push(row);
            }
        });

        let BPOGroupData = [];
        for (let bpoId in bpoUnic) {
            let rows = bpoUnic[bpoId];
            let data: simpleObject = {
                BPOID: bpoId,
                BPOName: rows[0].BPOName,
            };

            for (let row of rows) {
                for (let column of this.BPOGridColumns) {
                    if (column.field !== "BPOName") {
                        if (data[column.field] === undefined) {
                            data[column.field] = 0;
                        }
                        if (row[column.field]) data[column.field] += 1;
                    }
                }
            }
            BPOGroupData.push(data);
        }
        return BPOGroupData.sort((a, b) => a.BPOName.localeCompare(b.BPOName));
    };

    AddNewBuildPlan = () => {
        CardManagement.OpenGBPCard();
    };

    Refresh = () => {
        this.GridRefresh();
        if (this.tabsRef.current && this.tabsRef.current.RefreshActiveTab) {
            this.tabsRef.current.RefreshActiveTab();
        }
    };

    GridRefresh = () => {
    };

    InitRefresh = (refresh: any) => {
        this.GridRefresh = refresh;
    };
}

export default StatusDashboard;
