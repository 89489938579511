import {Component} from 'react'
import OpenCardLink from '../../../Components/Common/Buttons/OpenCardLink';
import {Grid, GridColumn as Column} from '@progress/kendo-react-grid';
import {INonWage} from '../interfaces';
import styles from './timecard.module.scss';

interface props {
  nonwages: Array<INonWage> | null
}

class TCNonWages extends Component<props, {}> {
  render() {
    let nonwages = this.props.nonwages
    if (!nonwages || !nonwages.length) return null

    return <Grid
      data={nonwages}
      filterable={false}
      scrollable="none"
      className={`${styles.TCGrid} ${styles.TCGridWithoutTotalRow}`}
    >
      <Column
        field="Total"
        title="NON WAGE SUMMARY"
        cell={(props: any) => {
          let data = props.dataItem
          return <td>
            <OpenCardLink
              text={data.WOName}
              dataAttr={data.WOId}
              refName="FSMWorkOrders"
            />
          </td>
        }}
      />
      <Column
        field="PIName"
        title="Payroll Item"
        width="280px"
      />
      <Column
        field="Quantity"
        title="Quantity"
        width="110px"
      />
    </Grid>
  }
}

export default TCNonWages;
