import React, {useState} from 'react';
import {Button, ButtonGroup} from "@progress/kendo-react-buttons";

interface IProps {
    initialState: boolean

    onChange(value: boolean): void
}

const ClockedOnlyToggle = (props: IProps) => {
    const [isClockedInOnly, setIsClockedInOnly] = useState(props.initialState)

    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const value = e.currentTarget.id === 'on'
        if (isClockedInOnly === value) return
        setIsClockedInOnly(value)
        props.onChange(value)
    }

    return <ButtonGroup>
        <Button
            key={'on'}
            id={'on'}
            size={'small'}
            onClick={onClick}
            togglable={true}
            selected={isClockedInOnly}
            style={{width: 70}}
            rounded={'full'}
        >Clocked In</Button>
        <Button
            key={'off'}
            id={'off'}
            size={'small'}
            onClick={onClick}
            togglable={true}
            selected={!isClockedInOnly}
            style={{width: 40}}
            rounded={'full'}
        >All</Button>
    </ButtonGroup>

}

export default ClockedOnlyToggle