import {Component, ComponentClass} from 'react'
import {Button, ButtonGroup, Toolbar} from '@progress/kendo-react-buttons'
import styles from './tabs.module.scss'
import {tabId} from './interfaces'
import {TABS} from './helpers'
import dashboardStyles from '../Dashboard/dashboard.module.scss'
import {simpleObject} from '../../helpers/interfaces'
import {gridChangeDataAction} from '../Dashboard/interfaces'

interface props {
  tabs: Array<{
    Name: string, Id: tabId, Component: ComponentClass<{
      isActive: boolean
      switchTab(tabId: tabId): void
      onChangeChildGridData?(data: Array<simpleObject>): void
    }>
  }>
  isActive: boolean
}

interface state {
  selectedId: tabId
}

class InventoryDashboardTabs extends Component<props, state> {
  initedTabs: Array<string> = []
  tabsRefs: {
    [key in tabId]?: any
  } = {}

  constructor(props: any) {
    super(props)
    let selectedId = this.props.tabs[0].Id;
    this.initedTabs.push(selectedId)
    this.state = {selectedId}
  }

  render() {
    let activeTabRef = this.state.selectedId && this.tabsRefs[this.state.selectedId]
    let activetab = TABS[this.state.selectedId]
    let hasExcelExport = !!activetab.hasExportToExcel
    let hasSetDefaultFilters = !!activetab.hasSetDefaultFilters
    let IsFiltersInDefault = true
    if (hasSetDefaultFilters) {
      let func = activeTabRef?.gridRef.current?.IsFiltersInDefault
      if (!!func) IsFiltersInDefault = func()
    }
    return (
      <>
        <Toolbar className={styles.Toolbar}>
          <ButtonGroup className={styles.ButtonGroup}>
            {this.props.tabs.map((tab) => {
              let id = tab.Id
              return (
                <Button
                  key={id}
                  id={id}
                  themeColor={this.state.selectedId === id ? 'primary' : undefined}
                  onClick={this.OnChange}
                >
                  {tab.Name}
                </Button>
              )
            })}
          </ButtonGroup>
          <div className={styles.ToolbarRight}>
            {hasSetDefaultFilters && <Button
                onClick={this.SetDefaultSettings}
                title="Set Default Filters"
                icon="filter-clear"
                className={IsFiltersInDefault ? '' : dashboardStyles.BlueResetBtn}
            />}
            {hasExcelExport && <Button icon="file-excel" title="Export to Excel"
                                       onClick={this.ExportToExcel}/>}
            <Button icon='refresh' onClick={this.RefreshActiveTab}/>
          </div>
        </Toolbar>
        {this.props.tabs.map((tab) => {
          let id = tab.Id
          if (this.initedTabs.indexOf(id) === -1) return;
          let isSelected = this.state.selectedId === id;
          if (!isSelected) return
          let Component = tab.Component
          let props = {
            isActive: this.props.isActive,
            switchTab: this.ChangeTab,
            onChangeGridData: this.onChangeChildGridData
          }

          return (
            <div
              key={id}
              className={`${styles.ContentWrap} ${isSelected ? '' : styles.HiddenTab}`}
            >
              <div className={styles.Content}>
                <Component
                  key={id}
                  {...props}
                  ref={(ref: any) => {
                    if (!this.tabsRefs[id] || this.tabsRefs[id] !== ref) {
                      this.tabsRefs[id] = ref
                    }
                  }}
                />
              </div>
            </div>
          )
        })}
      </>
    )
  }

  OnChange = (e: any) => {
    let tabId = e.currentTarget.id
    this.ChangeTab(tabId)
  }

  ChangeTab = (tabId: tabId) => {
    if (this.initedTabs.indexOf(tabId) === -1) this.initedTabs.push(tabId)
    this.setState({selectedId: tabId});
  }

  RefreshActiveTab = () => {
    let active = this.state.selectedId
    let refreshFunc = this.tabsRefs[active]?.Refresh
    if (refreshFunc) refreshFunc()
  }

  ExportToExcel = () => {
    let active = this.state.selectedId
    let exportFunc = this.tabsRefs[active]?.gridRef?.current?.ExportToExcel
    if (exportFunc) exportFunc()
  }

  SetDefaultSettings = () => {
    let active = this.state.selectedId
    let SetDefaultSettingsFunc = this.tabsRefs[active]?.gridRef?.current?.SetDefaultSettings
    if (SetDefaultSettingsFunc) SetDefaultSettingsFunc()
  }

  onChangeChildGridData = (data: Array<simpleObject>, action: gridChangeDataAction) => {
    if (action === 'filterChanged' || action === 'forceRestoreSettings' || action === 'dataLoaded') this.forceUpdate()
  }
}

export default InventoryDashboardTabs
