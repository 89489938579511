import * as React from 'react';
import {
  GridColumnMenuFilter,
  GridColumnMenuItem,
  GridColumnMenuItemContent,
  GridColumnMenuItemGroup,
} from '@progress/kendo-react-grid';
import {Button} from '@progress/kendo-react-buttons';
import {
  IColumnValue,
  IGridFilter,
  IGridFilterItem,
  IHiddenColumn
} from './interfaces';
import {IsComplexGridFilter} from './helpers';
import {Checkbox} from '@progress/kendo-react-inputs';
import styles from './dashboard.module.scss'

interface props {
  defaultProps: any
  filterable?: boolean
  columns?: IHiddenColumn
  fieldId?: string

  onColumnsSubmit?(columns: IHiddenColumn): void

  onCloseMenu?(): void

  getDefaultColumns?(): IHiddenColumn

  filterSubmit?(filter: IGridFilter, fieldId: string, fieldName: string): void

  filterByValues?(filters: Array<IGridFilterItem>, values: Array<IColumnValue>, fieldId: string, fieldName: string): void

  getColumnValues?(field: string, fieldId: string): Array<IColumnValue>
}

interface state {
  columns: IHiddenColumn
  columnsExpanded: boolean
  filtersExpanded: boolean
  values: Array<IColumnValue>
  valuesExpanded: boolean
}

export default class CustomColumnMenu extends React.Component<props, state> {
  constructor(props: props) {
    super(props);

    this.state = {
      columns: this.GetInitialColumnsSettings(),
      columnsExpanded: !(this.props.filterable || this.props.fieldId),
      filtersExpanded: !this.props.fieldId,
      valuesExpanded: true,
      values: this.GetInitialValuesSettings(),
    };
  }

  componentWillUnmount() {
    if (this.props.onCloseMenu) {
      this.props.onCloseMenu();
    }
  }

  OnToggleColumn = (e: any) => {
    let key = e.nativeEvent.target.dataset.id;
    let columns = this.state.columns;
    columns[key].hidden = !e.value
    this.forceUpdate();
  }

  OnResetColumnsSettings = (event: any) => {
    event.preventDefault();
    this.setState({columns: this.GetInitialColumnsSettings()})
  }

  GetInitialColumnsSettings = () => {
    let stateColumns: IHiddenColumn = {}
    if (this.props.columns) {
      for (let key in this.props.columns) {
        stateColumns[key] = {...this.props.columns[key]}
      }
    }
    return stateColumns
  }

  GetInitialValuesSettings = () => {
    let stateValues = []
    if (this.props.getColumnValues) {
      let values = this.props.getColumnValues(this.props.defaultProps.column.field, this.props.fieldId!)
      for (let value of values) {
        stateValues.push({...value})
      }
    }
    return stateValues
  }

  OnSubmitColumns = (event?: any) => {
    if (!this.props.onColumnsSubmit) return
    if (event) event.preventDefault();
    this.props.onColumnsSubmit(this.state.columns);
    this.props.defaultProps.onCloseMenu();
    if (this.props.onCloseMenu) {
      this.props.onCloseMenu();
    }
  }

  FilterByValues = (event?: any) => {
    if (event) event.preventDefault();
    if (!this.props.filterByValues) return
    let filters: Array<IGridFilterItem> = []
    for (let item of this.state.values) {
      if (item.Selected) filters.push({
        value: item.Id,
        field: item.FieldId,
        operator: 'eq'
      })
    }
    let field = this.props.defaultProps.column.field
    if (this.props.defaultProps.column.filter === 'date') field += '-string'
    this.props.filterByValues(filters, this.state.values, this.props.fieldId!, field)
    this.props.defaultProps.onCloseMenu();
    if (this.props.onCloseMenu) {
      this.props.onCloseMenu();
    }
  }

  OnToggleValue = (e: any) => {
    let index = e.nativeEvent.target.dataset.index;
    let values = this.state.values;
    values[index].Selected = e.value
    this.forceUpdate();
  }

  OnFilterSubmit = (filter: IGridFilter) => {
    let field = this.props.defaultProps.column.field
    if (this.props.defaultProps.column.filter === 'date') field += '-string'
    if (this.props.filterSubmit) this.props.filterSubmit(filter, this.props.fieldId!, field)
  }

  SelectAll = (event: any) => {
    event.preventDefault();
    this.SetValues(true)
  }

  ClearAll = (event: any) => {
    event.preventDefault();
    this.SetValues(false)
  }

  SetValues = (value: boolean) => {
    let values = this.state.values
    for (let key in values) {
      values[key].Selected = value
    }
    this.setState({values})
  }

  ColumnsExpandChange = () => {
    this.setState((state) => ({columnsExpanded: !state.columnsExpanded}));
  }

  FilterExpandChange = () => {
    this.setState((state) => ({filtersExpanded: !state.filtersExpanded}))
  }

  ValuesExpandChange = () => {
    this.setState((state) => ({valuesExpanded: !state.valuesExpanded}))
  }

  SetDefaultColumns = () => {
    if (this.props.getDefaultColumns) this.setState({columns: this.props.getDefaultColumns()})
  }

  render() {
    return (<div style={{minWidth: 230}}>
      {this.props.fieldId && this.renderFilterByValues()}
      {this.props.columns && this.renderColumnMenuGroup()}
      {this.props.filterable && this.renderFilterMenu()}
    </div>);
  }

  renderColumnMenuGroup = () => {
    return <div
      className={this.state.columnsExpanded ? 'columnsExpanded' : undefined}>
      <GridColumnMenuItemGroup>
        <GridColumnMenuItem
          title={'Columns'}
          iconClass={'k-i-columns'}
          onClick={this.ColumnsExpandChange}
        />
        <GridColumnMenuItemContent show={this.state.columnsExpanded}>
          <div className="k-column-list-wrapper">
            <form onSubmit={this.OnSubmitColumns}
                  onReset={this.OnResetColumnsSettings}>
              <div className="k-column-list">
                {Object.keys(this.state.columns).map((key: string) => {
                    if (this.state.columns[key].readonly) return null
                    let hidden = this.state.columns[key].hidden
                    return <div key={key} className="k-column-list-item">
                      <Checkbox
                        id={`column-visibility-show-${key}`}
                        data-id={key}
                        readOnly={true}
                        checked={!hidden}
                        onChange={this.OnToggleColumn}
                        label={this.state.columns[key].name}
                      />
                    </div>
                  }
                )}
              </div>
              <div className="k-columnmenu-actions">
                <Button type="reset">Cancel</Button>
                <Button themeColor="primary">Save</Button>
                <Button
                  title="Set Default Columns Settings"
                  onClick={this.SetDefaultColumns}
                >Default</Button>
              </div>
            </form>
          </div>
        </GridColumnMenuItemContent>
      </GridColumnMenuItemGroup>
    </div>
  }

  renderFilterByValues = () => {
    let hasUnSelected = this.state.values.findIndex((item) => !item.Selected) > -1
    let selectedClassName = hasUnSelected ? 'filtered' : ''
    let expandedClassName = this.state.valuesExpanded ? 'valuesExpanded' : ''
    return <div
      className={`columnMenuExpandPanel ${selectedClassName} ${expandedClassName}`}>
      <GridColumnMenuItemGroup>
        <GridColumnMenuItem
          title={'Filter by Value'}
          iconClass={'k-i-filter'}
          onClick={this.ValuesExpandChange}
        ></GridColumnMenuItem>
        <GridColumnMenuItemContent show={this.state.valuesExpanded}>
          <div className="k-column-list-wrapper">
            <form onSubmit={this.FilterByValues}>
              <div className="k-column-list" style={{maxHeight: '350px'}}>
                {this.state.values.map((item, i) => {
                    let key = item.Id
                    return <div key={key} className="k-column-list-item">
                      <Checkbox
                        id={`column-visibility-show-${key}`}
                        data-index={i}
                        checked={item.Selected}
                        onChange={this.OnToggleValue}
                        label={item.Name === null ? 'null' : item.Name}
                      />
                    </div>
                  }
                )}
              </div>
              <div className="k-columnmenu-actions">
                <Button onClick={this.SelectAll}>Select All</Button>
                <Button onClick={this.ClearAll}>Clear All</Button>
                <Button themeColor="primary">Apply</Button>
              </div>
            </form>
          </div>
        </GridColumnMenuItemContent>
      </GridColumnMenuItemGroup>
    </div>
  }

  renderFilterMenu = () => {
    let props = this.props.defaultProps
    let gridFilters: IGridFilter = props.filter
    let isFiltered = gridFilters && gridFilters.filters && gridFilters.filters.find((filter) => IsComplexGridFilter(filter) && !IsComplexGridFilter(filter.filters[0]) && filter.filters[0].field === props.column.field)
    let filteredClassName = isFiltered ? 'filtered' : ''
    let expandedClassName = this.state.filtersExpanded ? 'filtersExpanded' : ''
    return <div
      className={`${styles.ColumnmenuFilter} columnMenuExpandPanel ${filteredClassName} ${expandedClassName}`}>
      <GridColumnMenuFilter
        {...this.props.defaultProps}
        onExpandChange={this.FilterExpandChange}
        expanded={this.state.filtersExpanded}
        onFilterChange={this.OnFilterSubmit}
      /></div>
  }
}
