import React, {useEffect, useRef} from 'react';
import {GetAddressFromPlace, GetFullAddressString, LoadAddressInfo} from "../../Pages/ObjectMap/helpers";

interface IProps {
    map: any
}

const MapSearchAddressesControl = (props: IProps) => {
    const {map} = props;
    const controlRef = useRef(window.L.mapquest.geocodingControl({
            position: "topright",
            removePreviousMarker: true,
            placeholderText: "Enter address",
            pointZoom: 15,
            keepOpen: true,
            searchAhead: true,
            marker: {
                icon: 'flag',
                iconOptions: {
                    primaryColor: '#0cb06f',
                    secondaryColor: '#0cb06f',
                    size: 'sm',
                    shadow: true,
                },
            },
            searchAheadOptions: {
                collection: "address",
            },
        })
            .on("searchahead_selected", (e: any) => {
                const addressInfo = GetAddressFromPlace(e.result.place)
                e.target._marker.bindTooltip(GetFullAddressString(addressInfo)).openTooltip()
            })
            .on("geocode_response", async (e: any) => {
                const {lat, lng} = e.results[0].locations[0].latLng;
                const addressInfo = await LoadAddressInfo(lat, lng)
                if (addressInfo) e.target._marker.bindTooltip(GetFullAddressString(addressInfo)).openTooltip()
            })
    )

    useEffect(() => {
        if (map && controlRef.current) {
            map.addControl(controlRef.current);
        }
        return () => {
            if (map) {
                map.removeControl(controlRef.current);
            }
        }
    }, [map])

    return null
}

export default MapSearchAddressesControl