import {ITabSetting, tabId} from '../../Components/Tabs/interfaces'
import BPTabs from '../../Components/Tabs/BPTabs'
import dashboardStyles from '../../Components/Dashboard/dashboard.module.scss'
import BaseComponent from '../../Components/BaseComponent'
import {ModalRef} from '../../Components/Common/Modal/Modal';
import Loader from '../../Components/Common/Loader'
import {isConterra} from '../../helpers/settings'
import CLPMSettings from '../../stores/CLPMSettings'
import {IsTabExist} from "../../Components/Tabs/helpers";

interface props {
    isActive: boolean
}

interface state {
    initialization: boolean
}

class CLM extends BaseComponent<props, state> {
    tabs: Array<ITabSetting | tabId> = []
    tabsRef: any

    constructor(props: props) {
        super(props)
        this.state = {
            initialization: true,
        }
    }

    componentDidMount() {
        this.InitSettings()
    }

    componentWillUnmount() {
        super.componentWillUnmount()
        CLPMSettings.deleteRouteToReview()
    }

    InitTabsRef = (ref: any) => {
        this.tabsRef = ref
        if (ref && ref.RouteToCLMReviewInterface) {
            CLPMSettings.setRouteToReview(ref.RouteToCLMReviewInterface)
        }
    }

    render() {
        return <div className={dashboardStyles.Container}>
            {this.state.initialization && <Loader/>}
            {!this.state.initialization && !!this.tabs.length &&
                <BPTabs
                    ref={this.InitTabsRef}
                    rowData={null}
                    isActive={this.props.isActive}
                    parentId="CLPM"
                    props={{tabs: this.tabs}}
                />}
        </div>
    }

    InitSettings = async () => {
        try {
            let settings = await CLPMSettings.getSettings()
            let tabs = settings.Tabs?.split(',')
            this.tabs = []
            if (tabs.length && settings.UserRole) {
                for (let tabId of tabs) {
                    if (tabId === 'RelatedDocuments' || tabId === 'ReviewInterface') {
                        let id: tabId = tabId === 'RelatedDocuments' ? 'Documents' : 'ReviewInterface'
                        this.tabs.push({id, props: {isCLM: true}})
                    } else if (tabId === 'RegularCalendar') {
                        if (isConterra) this.tabs.push('Calendar') // ?? only in conterra?
                    } else if (IsTabExist(tabId)) {
                        this.tabs.push(tabId as tabId)
                    }
                }
            } else {
                ModalRef.showDialog({
                    title: 'No Available Tabs',
                    text: 'CLPM Role is not assigned or there are no available Tabs'
                })
            }
        } finally {
            this.setState({initialization: false});
        }
    }
}

export default CLM
