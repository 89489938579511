import {Component} from 'react';
import {ComboBox, DropDownsPopupSettings} from '@progress/kendo-react-dropdowns'
import {ReferenceRecordsDataSource} from '../../helpers/queries';
import {filterBy} from '@progress/kendo-data-query';
import {IComboboxItem} from '../../helpers/interfaces';

interface props {
  data?: Array<IComboboxItem>
  filter?: any
  width?: number
  placeholder?: string
  defaultValue?: any
  refName?: string
  className?: string
  loading?: boolean
  disabled?: boolean
  required?: boolean
  valueRender?: (rendering: React.ReactElement<HTMLSpanElement>, e?: any) => React.ReactNode
  opened?: boolean
  popupSettings?: DropDownsPopupSettings

  onChange(value: any, filter: any): void
}

interface state {
  data: Array<IComboboxItem>
  loading: boolean
  value: null | IComboboxItem
}

class ComboboxFilterVirtual extends Component<props, state> {
  data: Array<IComboboxItem> = this.props.data || []
  filteredData: Array<IComboboxItem> = []
  filter: any
  skip: number = 0
  total: number = 0
  pageSize: number = 11
  filterTimeout: any

  constructor(props: props) {
    super(props)
    this.state = {
      data: this.data,
      loading: false,
      value: this.props.data && this.props.defaultValue ? this.props.defaultValue : null
    }
  }

  componentDidMount() {
    if (!this.props.data) this.LoadData()
    else this.ProcessingData()
  }

  render() {
    let filter = this.props.filter;
    return <ComboBox
      required={this.props.required}
      className={this.props.className}
      loading={this.state.loading || this.props.loading}
      virtual={{
        total: this.filteredData.length,
        pageSize: this.pageSize,
        skip: this.skip,
      }}
      onPageChange={this.OnPageChange}
      data={this.state.data}
      placeholder={filter?.placeholder || this.props.placeholder}
      value={this.state.value}
      textField="Name"
      dataItemKey="Id"
      opened={this.props.opened}
      style={{width: filter?.width || this.props.placeholder}}
      onChange={this.OnChangeCombobox}
      filterable={true}
      onFilterChange={this.FilterCombobox}
      disabled={this.props.filter.disabled || this.props.disabled}
      valueRender={this.props.valueRender}
      popupSettings={this.props.popupSettings}
    />
  }

  OnPageChange = (event: any) => {
    this.skip = event.page.skip
    this.SetData()
  };

  FilterCombobox = (e: any) => {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout)
    }
    this.filter = e.filter
    this.filterTimeout = setTimeout(() => {
      this.filteredData = filterBy(this.data, this.filter)
      this.skip = 0
      this.total = this.filteredData.length
      this.SetData()
    }, 1000)
  }

  OnChangeCombobox = (e: any) => {
    let value = e.value
    this.setState({value})
    this.props.onChange(value, this.props.filter)
  }

  SetData = async (value?: IComboboxItem) => {
    const newSubsetData = this.filteredData.slice(this.skip, this.skip + this.pageSize);
    this.setState({data: newSubsetData});
    if (value !== undefined) this.setState({value})
  }

  LoadData = async () => {
    if (!this.props.refName) return
    try {
      this.setState({loading: true})
      let result = await ReferenceRecordsDataSource(this.props.refName)
      this.data = result[0]
      this.ProcessingData()
    } finally {
      this.setState({loading: false})
    }
  }

  ProcessingData = () => {
    this.filteredData = this.data
    this.total = this.data.length
    this.pageSize = this.total <= 11 ? this.total : 11
    let value;
    if (this.props.defaultValue) {
      let index = this.filteredData.findIndex((item) => item.Id === this.props.defaultValue.Id)
      if (index > -1) {
        this.skip = index
        value = this.filteredData[index]
      }
    }
    this.SetData(value)
  }
}

export default ComboboxFilterVirtual
