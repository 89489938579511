import {Component} from 'react';
import {Button} from '@progress/kendo-react-buttons';
import styles from './vim.module.scss';

interface props {
  defaultPrev: boolean
  defaultNext: boolean

  onPrev(): void

  onNext(): void
}

interface state {
  disabledNext: boolean
  disabledPrev: boolean
}

class Arrow extends Component<props, state> {

  constructor(props: props) {
    super(props)
    this.state = {
      disabledPrev: this.props.defaultPrev,
      disabledNext: this.props.defaultNext,
    }
  }

  render() {
    return <div className={styles.SidebarArrows}>
      <Button
        className={styles.ListArrow}
        icon="arrow-60-up"
        fillMode="flat"
        onClick={this.props.onPrev}
        disabled={this.state.disabledPrev}></Button>
      <Button
        className={styles.ListArrow}
        icon="arrow-60-down"
        fillMode="flat"
        onClick={this.props.onNext}
        disabled={this.state.disabledNext}></Button>
    </div>
  }

  DisableArrows = (disabledPrev: boolean, disabledNext: boolean) => {
    this.setState({disabledPrev, disabledNext})
  }
}

export default Arrow;
