import {Component} from 'react';
import {DataResult} from '@progress/kendo-data-query';
import popupStyles from '../Common/MobilePopup/index.module.scss';
import {IRelatedDocumentItem} from './interfaces';
import {VariableSizeTree as Tree} from 'react-vtree';
import ExpandedHeader from '../Common/ExpandedPanel/ExpandedHeader';
import ListItem from './MobileListItem'
import {Button} from '@progress/kendo-react-buttons';
import {
  documentAction,
  GetDataItemFromGroup,
  GetDocumentCountFromGroup,
  groupFieldName
} from './helpers';
import MobilePopup from '../Common/MobilePopup/Index';
import {
  mobilePopupBtnOuterHeight,
  mobilePopupPaddings
} from '../Common/MobilePopup/helpers';
import {Group_Height, LIST_ITEM_HEIGHT} from '../Common/ExpandedPanel/helpers';

interface props {
  data: DataResult
  remountListKey: number
  collapsedKey: number
  expandedSettings: { [key: string]: boolean }
  group: Array<{ field: keyof IRelatedDocumentItem, dir: 'asc' | 'desc' }>

  onChangePublish(documentId: number, value: boolean): void

  showDocument(): void

  onAction(action: documentAction, dataItem: IRelatedDocumentItem): void

  onMobileExpandeChange(id: string): void

  onItemClick(dataItem: IRelatedDocumentItem): void
}

interface state {
  popupItem: null | IRelatedDocumentItem
}

const TOOLBAR_HEIGHT = 45

class RelatedDocumentsMobileList extends Component<props, state> {
  selectedEl: null | any = null
  scrollOffset: number = 0
  mobileCollapsedKey: number = this.props.collapsedKey

  constructor(props: any) {
    super(props);
    this.state = {
      popupItem: null
    }
    this.treeWalker = this.treeWalker.bind(this)
  }

  treeWalker = function* (refresh: boolean) {
    const stack = [];
    for (let ch of this.props.data.data) {
      stack.unshift({nestingLevel: 0, node: ch});
    }

    while (stack.length !== 0) {
      // @ts-ignore
      let item = stack.pop()
      // @ts-ignore
      const {node: {items = [], field}} = item;

      let id = ''
      let name = ''
      let nestingLevel = 0

      if (!field) {
        id = item.node.DocumentId
        name = item.node.DocumentName
        nestingLevel = this.props.group.length
      } else if (field) {
        let dataItem = GetDataItemFromGroup(item.node)
        let documentNumber = GetDocumentCountFromGroup(item.node)
        for (let i = 0; i < this.props.group.length; i++) {
          let groupField: string = this.props.group[i].field
          id = `${id}_${dataItem[groupField]}`
          if (groupField === field) {
            nestingLevel = i
            name = dataItem[groupFieldName[groupField]] + ` (${documentNumber})`
            break;
          }
        }
      }

      id += ''
      // @ts-ignore
      const isOpened = yield refresh ? {
        defaultHeight: nestingLevel === this.props.group.length ? LIST_ITEM_HEIGHT : Group_Height,
        id,
        isLeaf: items.length === 0,
        isOpenByDefault: this.props.expandedSettings[id],
        name,
        nestingLevel,
        rowData: item.node
      } : id;

      if (items.length !== 0 && isOpened) {
        for (let i = items.length - 1; i >= 0; i--) {
          stack.push({
            nestingLevel,
            node: items[i],
          });
        }
      }
    }
  }

  render() {
    if (this.mobileCollapsedKey !== this.props.collapsedKey) {
      this.mobileCollapsedKey = this.props.collapsedKey
      this.scrollOffset = 0
    }

    return <>
      <Tree
        key={`${this.props.remountListKey}_${this.mobileCollapsedKey}`}
        treeWalker={this.treeWalker}
        height={window.innerHeight - TOOLBAR_HEIGHT}
        width={'100%'}
        onScroll={this.OnScroll}
        initialScrollOffset={this.scrollOffset}
      >
        {this.renderRow}
      </Tree>
      {this.renderPopupContent()}
    </>
  }

  renderPopupContent = () => {
    let dataItem = this.state.popupItem
    if (!dataItem) return null
    let actions = []
    if (!!dataItem.AllowPublish) actions.push({text: 'publish'})
    actions.push({Name: 'Show document', Icon: 'mdi mdi-file-document-outline'})
    actions.push('download')
    if (dataItem.AllowUpload) actions.push('upload')
    if (dataItem.AllowChangeKind) actions.push('ChangeKind')
    if (dataItem.AllowDelete) actions.push('delete')
    return <MobilePopup
      onHide={this.HidePopup}
      height={(mobilePopupBtnOuterHeight * actions.length) + (mobilePopupPaddings * 2)}
      width={320}
    >
      {!!dataItem.AllowPublish && <Button
          className={popupStyles.MobilePopupBtn}
          size={'large'}
          iconClass={dataItem.IsPublished ? "mdi mdi-cancel" : 'mdi mdi-arrow-right-bold-outline'}
          onClick={this.ChangePublish}
      >{dataItem.IsPublished ? 'Unpublish for Field' : 'Publish for Field'}</Button>}
      <Button
        className={popupStyles.MobilePopupBtn}
        size={'large'}
        iconClass="mdi mdi-eye"
        onClick={this.ShowDocument}
      >Show Document</Button>
      <Button
        id="Download"
        className={popupStyles.MobilePopupBtn}
        size={'large'}
        iconClass="mdi mdi-download-outline"
        onClick={this.DoAction}
      >Download Document</Button>
      {dataItem.AllowUpload && <Button
          id="Upload"
          className={popupStyles.MobilePopupBtn}
          size={'large'}
          iconClass="mdi mdi-upload-outline"
          onClick={this.DoAction}
      >Upload new File</Button>}
      {dataItem.AllowChangeKind && <Button
          id="ChangeKind"
          className={popupStyles.MobilePopupBtn}
          size={'large'}
          iconClass="mdi mdi-form-select"
          onClick={this.DoAction}
      >Change Kind</Button>}
      {dataItem.AllowDelete && <Button
          id="Delete"
          className={popupStyles.MobilePopupBtn}
          size={'large'}
          iconClass="mdi mdi-trash-can-outline"
          onClick={this.DoAction}
      >Delete Document</Button>}
    </MobilePopup>
  }

  renderRow = (props: any) => {
    let rowData = props.data.rowData
    return (
      <div style={props.style}>
        {rowData?.field ? this.renderExpandHeader(props.data, props.isOpen, props.toggle) : this.renderCard(rowData)}
      </div>
    )
  }

  renderExpandHeader = (item: any, isOpen: boolean, toggle: any) => {
    return <ExpandedHeader
      expanded={isOpen}
      level={item.nestingLevel + 1}
      dataId={item.id}
      onToggle={(id: string) => {
        this.props.onMobileExpandeChange(id)
        toggle()
      }}
      content={item.name}
    />
  }

  renderCard = (item: IRelatedDocumentItem) => {
    return <ListItem
      data={item}
      onClick={this.ShowPopup}
    />
  }

  OnScroll = (e: any) => {
    this.scrollOffset = e.scrollOffset
  }

  ShowDocument = () => {
    this.props.showDocument()
    this.HidePopup()
  }

  DoAction = async (e: any) => {
    let action: documentAction = e.currentTarget.id
    if (this.state.popupItem && action) await this.props.onAction(action, this.state.popupItem)
    this.HidePopup()
  }

  ShowPopup = (popupItem: IRelatedDocumentItem) => {
    this.props.onItemClick(popupItem)
    this.setState({popupItem})
  }

  HidePopup = () => {
    this.selectedEl = null
    this.setState({popupItem: null})
  }

  ChangePublish = () => {
    let dataItem = this.state.popupItem;
    if (dataItem) this.props.onChangePublish(dataItem.DocumentId, !dataItem.IsPublished)
    this.HidePopup()
  }
}

export default RelatedDocumentsMobileList;
