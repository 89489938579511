import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import 'hammerjs'; // kendo chart need this dependency.
import './helpers/ie-polyfills.ts'

import '@mdi/font/css/materialdesignicons.min.css'
import './assets/icons/foundationIcons/css/foundation-icons.css';
import './App.scss'
import App from './App';
import reportWebVitals from './reportWebVitals';
import InstanceSettings from './helpers/instanceSettings'

InstanceSettings.loadSystemCode()

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
