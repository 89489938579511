import {Component, createRef} from 'react';
import {Button, ButtonGroup, Toolbar, ToolbarSpacer} from '@progress/kendo-react-buttons';
import CardManagement from '../../Components/Cards/CardManagement';
import OpenCardLink from '../../Components/Common/Buttons/OpenCardLink';
import DailyStatus from './DailyStatus';
import commonStyles from '../../assets/styles/common.module.scss';
import styles from './daily.module.scss';
import {simpleObject} from '../../helpers/interfaces';
import {tabId} from '../../Components/Tabs/interfaces';
import {TABS} from '../../Components/Tabs/helpers';
import tabsStyles from '../../Components/Tabs/tabs.module.scss';
import {SplitPane} from 'react-multi-split-pane';
import CLPMSettings from '../../stores/CLPMSettings'
import moment from "moment";

interface props {
    buildPlanId: number
    buildPlanName: string
}

interface state {
    selected: Array<string>
    tabSettingsInited: boolean
}

class DailyTabs extends Component<props, state> {
    tabs: Array<tabId> = ['Daily', 'Labor', 'Milestones', 'Tasks', 'ChecklistResults', 'Documents', 'Map', 'WorkOrders', 'Calendar']
    rightTabs: Array<tabId> = ['Labor', 'Milestones', 'Tasks']
    fullSizeTabs: Array<tabId> = ['ChecklistResults', 'WorkOrders', 'Documents', 'Calendar', 'Map']
    panes: Array<any> = [{size: '800px', resizable: false}, {}]
    calendarBPId: number | null = null
    statusDate: string = ''
    tabRefs: { [key in tabId]?: any } = {
        Daily: createRef(),
        Labor: createRef(),
        Milestones: createRef(),
        Tasks: createRef(),
        ChecklistResults: createRef(),
        Documents: createRef(),
        Map: createRef(),
        WorkOrders: createRef(),
        Calendar: createRef(),
    }

    constructor(props: any) {
        super(props);
        this.state = {
            selected: ['Daily', 'Labor'],
            tabSettingsInited: false
        }
    }

    componentDidMount() {
        this.initTabSettings()
    }

    initTabSettings = async () => {
        let Settings = await CLPMSettings.getSettings()

        if (!Settings?.IsReviewer && !Settings?.IsCustomer) {
            let index = this.tabs.findIndex((tabId) => tabId === 'ChecklistResults')
            this.tabs.splice(index, 1)
        }
        this.setState({tabSettingsInited: true})
    }

    render() {
        if (!this.state.tabSettingsInited) return null
        let selected = this.state.selected;
        let bpID = this.props.buildPlanId;
        let fullSizeTabSelected = selected.length === 1 && selected[0] !== 'Daily'
        if (this.state.selected.includes('Calendar')) this.calendarBPId = this.props.buildPlanId
        let hasActiveRightTab = this.rightTabs.find((tabId) => selected.includes(tabId))
        return (<div className={commonStyles.ScreenHeightContainerWithToolbar}
                     style={{zIndex: 4}}>
            <Toolbar>
                <ButtonGroup>
                    {this.tabs.map((tabId) =>
                        <Button
                            data-name={tabId}
                            onClick={this.OnSelectTab}
                            themeColor={selected.includes(tabId) ? 'primary' : undefined}
                            key={tabId}
                            className={selected.includes(tabId) ? 'k-state-active' : ''}
                        >
                            {TABS[tabId].name}
                        </Button>
                    )}
                </ButtonGroup>
                <ToolbarSpacer/>
                <OpenCardLink
                    text={this.props.buildPlanName}
                    dataAttr={this.props.buildPlanId}
                    refName={'FSMBuildPlans'}
                />
                <Button
                    icon="hyperlink-open"
                    fillMode="flat"
                    title="Open Review Interface"
                    onClick={this.OpenClmReviewInterface}
                />
            </Toolbar>
            <div
                className={`${styles.Content} ${fullSizeTabSelected ? styles.ContentHidden : ''}`}>
                <SplitPane split="vertical" defaultSizes={[1, 1]} minSize={[400, 400]}>
                    <div className={styles.SplitterPanes}>
                        <DailyStatus
                            ref={this.tabRefs.Daily}
                            key={'Daily'}
                            isActive={selected.includes('Daily')}
                            buildPlanId={bpID}
                            onChangeDate={this.OnChangeStatusDate}
                        ></DailyStatus>
                    </div>
                    <div
                        className={`${styles.SplitterPanes} fixed-height-container-for-grid`}
                        style={!hasActiveRightTab ? {overflow: 'hidden'} : undefined}>
                        {this.rightTabs.map((tabId) => {
                            let isActiveTab = selected.includes(tabId)
                            let isInit = !!this.tabRefs[tabId]?.current
                            if (!isActiveTab && !isInit) return null
                            let TabComponent = TABS[tabId].component
                            return (
                                <div key={tabId}
                                     className={`${styles.SplitterPanes} ${tabsStyles.ContentWrap} ${!isActiveTab ? tabsStyles.HiddenTab + ' ' + tabsStyles.ContentWrap : ''}`}>
                                    <TabComponent
                                        isActive={isActiveTab}
                                        buildPlanId={bpID}
                                        ref={this.tabRefs[tabId]}
                                        doNotsaveSettings={tabId === 'Milestones' || undefined}
                                        pageId={"DailyDashboard"}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </SplitPane>
            </div>
            {this.fullSizeTabs.map((tabId) => {
                let isActiveTab = selected.includes(tabId)
                let isInit = !!this.tabRefs[tabId]?.current
                let TabComponent = TABS[tabId].component
                if (!isActiveTab && !isInit) return null
                let props: simpleObject = {
                    isActive: isActiveTab,
                    pageId: 'daily' /* "DailyDashboard" */
                }
                if (tabId !== 'Map') {
                    props.buildPlanId = bpID
                    if (tabId === 'Documents') props.isDaily = true
                } else {
                    props = {
                        isActive: isActiveTab,
                        date: moment(this.statusDate).toDate(),
                        bpId: bpID,
                    }
                }

                return (
                    <div
                        key={tabId}
                        className={`${styles.Content} ${!isActiveTab ? tabsStyles.HiddenTab + ' ' + tabsStyles.ContentWrap : ''}`}
                        style={tabId === 'Map' ? {overflow: 'hidden'} : undefined}
                    >
                        <TabComponent
                            {...props}
                            ref={this.tabRefs[tabId]}
                        />
                    </div>
                )
            })}
        </div>)
    }

    OnChangeStatusDate = (date: string) => this.statusDate = date

    IsFullSizeTab = (tabId: tabId) => {
        return this.fullSizeTabs.indexOf(tabId) > -1
    }

    OnSelectTab = (e: any) => {
        let tabName = e.currentTarget.dataset.name;
        let selected = this.state.selected;
        if (selected.includes(tabName)) {
            if (tabName !== 'Daily') selected = ['Daily']
        } else if (this.IsFullSizeTab(tabName)) {
            selected = [tabName];
        } else {
            selected = ['Daily', tabName]
        }

        this.setState({selected});
    }

    RefreshActiveTabs = () => {
        let active = this.state.selected
        for (let tabId of active) {
            // @ts-ignore
            let ref = this.tabRefs[tabId]
            if (ref && ref.current && ref.current.Refresh) {
                ref.current.Refresh()
            }
        }
    }

    OpenClmReviewInterface = () => {
        CardManagement.OpenClmReviewInterface(this.props.buildPlanId)
    }

}

export default DailyTabs;
