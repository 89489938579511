import {IOtherNodePoint, nodeType} from "./interfaces";
import CardManagement from "../Cards/CardManagement";
import {getSQLData} from "../../helpers/queries";
import {IAddressInfo} from "../../Pages/ObjectMap/interfaces";
import {simpleObject} from "../../helpers/interfaces";

export const MARKERS_COLORS = {
    BLACK: '#000000',
    WHITE: '#FFFFFF',
    GRAY: '#555555',
    PINK: '#C400AB',
    AQUA: '#00c4ae',
    ORANGE: '#ff6e60',
    MAGENTA: '#f032e6',
    NAVY: '#000075',
    FX: '#0cb06f',
    GREEN: '#00d442',
    BLUE: "#0075ff"
}
export const DRIVE_COLOR = '#f5e263'
export const ICONS = {
    Site: {
        primaryColor: MARKERS_COLORS.PINK,
        secondaryColor: MARKERS_COLORS.PINK,
        size: "md",
    },
    RelatedSite: {
        primaryColor: MARKERS_COLORS.WHITE,
        secondaryColor: MARKERS_COLORS.PINK,
        size: "sm",
    },
    Location: {
        primaryColor: MARKERS_COLORS.AQUA,
        secondaryColor: MARKERS_COLORS.AQUA,
        size: "sm",
    },
    Search: {
        primaryColor: MARKERS_COLORS.WHITE,
        secondaryColor: MARKERS_COLORS.GRAY,
        size: "sm",
    },
    New: {
        primaryColor: MARKERS_COLORS.BLACK,
        secondaryColor: MARKERS_COLORS.BLACK,
        size: "sm",
    },
    Current: {
        primaryColor: MARKERS_COLORS.GREEN,
        secondaryColor: MARKERS_COLORS.GREEN,
        size: "sm",
    }
};

export const zIndexMarkers = {
    default: 0,
    initNearestSiteMarker: 0,
    otherNodes: 999,
    initRelatedSiteMarker: 1000,
    initSiteMarker: 2000,
    initStopMarker: 3000,
    initClockMarker: 4000,
    initLastStopMarker: 5000,

    markedNearestSiteMarker: 0,
    markedRelatedSiteMarker: 10000,
    markedSiteMarker: 20000,
    markedStopMarker: 30000,
    markedClockMarker: 40000,
    markedLastStopMarker: 50000,

    searchGroup: 60000
}
window.helpers.mapZIndexMarkers = zIndexMarkers
export const GetAllSiteDetailsHtml = (item: IOtherNodePoint) => {
    const ConstructionStatus = item.ConstructionStatusName ? `<div>Construction Status: ${item.ConstructionStatusName}</div>` : ''
    const CXStatusComment = item.CXStatusComment ? `<div>CX Status Comment: ${item.CXStatusComment}</div>` : ''
    const PermitStatus = item.PermitStatusName ? `<div>Permit Status: ${item.PermitStatusName}</div>` : ''
    const PermitStatusComment = item.PermitStatusComment ? `<div>Permit Status Comment: ${item.PermitStatusComment}</div>` : ''
    return `<div class="details-group">
        <div>
          <a href="#bpcard" onclick="window.helpers.openWebBPCard(
              ${item.BPId}, 
              {id: 'WorkOrders', defaultColumnValuesFilter: [{
                    field: 'Stage',
                    values: ['Ready for Dispatch', 'Scheduled', 'Published']
                  }]
              }); return false;" target="__blank">
          ${item.BPName}
          </a>
      </div>
      <div>Project: ${item.ProjectName}</div>
      <div>Scenario: ${item.ScenarioName}</div>
      <div>Customer: ${item.CustomerName}</div>
      <div>BPO: ${item.BPOName}</div>
      ${ConstructionStatus}
      ${CXStatusComment}
      ${PermitStatus}
      ${PermitStatusComment}
  </div>`;
};
window.helpers.getAllSiteDetailsHtml = GetAllSiteDetailsHtml
export const GetPolygonCoordinatesFromStrBoundaries = (boundaries: string) => {
    const arrStrCoords = boundaries.split(",");
    const latlngs: number[][] = [];
    for (let str of arrStrCoords) {
        const [lng, lat] = str.trim().split(" ");
        latlngs.push([+lat, +lng]);
    }
    return latlngs;
};

export const OpenObject = (e: any, onFinish?: (id: number) => void) => {
    let {refName, objectId} = e.target.options;
    if (!objectId || !refName) return
    if (refName === 'FSMSites') {
        CardManagement.OpenSiteCard({siteId: objectId, onFinish})
    } else if (refName === 'Locations') {
        CardManagement.OpenLocationCard({locationId: objectId, onFinish})
    }
}

export const addSearchLayer = (map: any, category: string, markerOptions = {}) => {
    return new Promise((resolve, reject) => {
        const bounds = map.getBounds()
        const north = bounds._northEast
        const south = bounds._southWest
        const MIN_LNG = -180
        const MAX_LNG = 180

        if ((south.lng < MIN_LNG || south.lng > MAX_LNG) || (north.lng < MIN_LNG || north.lng > MAX_LNG)) {
            reject(null)
            return
        }

        const createSearchLayer = (err: any, response: any) => {
            if (typeof err === "object" && Object.keys(err).length > 0) {
                reject(null)
                return
            }

            const layer = window.L.mapquest.searchLayer({
                searchResponse: response,
                updateResultsOnMapMove: false,
                marker: {...markerOptions}
            })

            map.addLayer(layer)
            resolve(layer)
        }

        window.L.mapquest.search().place({
            q: category,
            sort: "relevance",
            bbox: bounds,
            pageSize: 50
        }, createSearchLayer)
    })
}


export const GetRegularNodeTooltipContent = (objectName: string, address: string) => {
    return "<div><b>" + objectName + "</b></div><div>" + address + "</div>"
}

export const BindNodeTooltip = (marker: any, objectName: string, address: string) => {
    const {refName, objectId} = marker.options
    const isSite = refName === 'FSMSites'
    // refName ("FSMSites" | "Locations")
    if (isSite) {
        marker.options.objectName = objectName
        marker.options.address = address
    }
    const regularTooltipContent = GetRegularNodeTooltipContent(objectName, address)
    const tooltipOptions = isSite ? {
        siteId: objectId,
        marker: marker,
        doGetTooltipInfo: true
    } : undefined;

    marker.bindTooltip(
        "<div style='max-width: 400px;'>" + regularTooltipContent + "</div>",
        tooltipOptions
    )
}

export const getObjectMapData = async (objectId?: number, addressId?: number) => {
    try {
        const data = await getSQLData({
            spName: "GetObjectMapData",
            params: {
                objectId,
                addressId
            },
        })
        const mainAddress: IAddressInfo = data[0].find((n: IAddressInfo) => n.IsMainObject)
        const relatedAddresses: IAddressInfo[] = data[0].filter((n: IAddressInfo) => !n.IsMainObject)
        return {mainAddress, relatedAddresses}
    } catch (e) {
        throw new Error('Params objectId or addressId is required')
    }
}

export const getFigureObjectLayer = (
    objectId: number, objectType: nodeType,
    fillColor: string, color: string,
    coords: number[], boundaries?: string | null, radius?: number | null,
    otherOptions?: simpleObject,
    zIndex?: number
) => {
    if (boundaries) {
        const latlngs = GetPolygonCoordinatesFromStrBoundaries(boundaries);
        return window.L.polygon(latlngs, {
            color: "black",
            stroke: true,
            weight: 1.5,
            fillOpacity: 0,
            fillColor,
            objectId,
            objectType,
            zIndexOffset: zIndex,
            ...otherOptions || {}
        })
    }
    return window.L.circle(coords, {
        radius: radius || 0,
        color: "black",
        stroke: true,
        weight: 1.5,
        fillOpacity: 0,
        fillColor,
        objectId,
        objectType,
        zIndexOffset: zIndex,
        ...otherOptions || {}
    })
}

export const fitBoundsGroup = (group: any, map: any, paddings: number = 50) => {
    const layers = group.getLayers()
    if (layers.length === 0) return
    try {
        const bounds = group.getBounds();
        if (bounds && bounds._northEast) {
            map.fitBounds(bounds, {
                padding: [paddings, paddings],
            });
        } else {
            let latLng: any
            group.eachLayer((layer: any) => {
                if (latLng) return
                const coords = layer.getLatLng?.()
                if (coords) {
                    latLng = coords
                    map.setView(coords);
                }
            })
        }
    } catch {
    }
}
