import React, {Component} from 'react'
import FXCard from '../Common/FXCard/FXCard'
import BPHistoryChart from '../BP/HistoryChart'
import {OpenRecord} from '../../helpers/runscripts'
import {IFXCardProps} from './interfaces'
import OpenCardIconLink from './OpenCardIconLink'

interface props extends IFXCardProps {
  buildPlanId: number
  buildPlanName: string
}

interface state {
}

class RecordHistory extends Component<props, state> {
  chartRef: any = React.createRef()

  render() {
    return (
      <FXCard
        title={<>
          <OpenCardIconLink onClick={this.OpenBP} title="Open BP"/>
          {this.props.buildPlanName}
        </>}
        initialHeight={400}
        onClose={this.props.finally}
        onResize={this.OnResizeCard}
        onStageChange={this.RerenderChart}
      >
        <BPHistoryChart buildPlanId={this.props.buildPlanId} autoResize={true}
                        ref={this.chartRef}/>
      </FXCard>
    )
  }

  OnResizeCard = (e: any) => {
    if (e.end === true) this.RerenderChart()
  }

  RerenderChart = () => {
    let chartRef = this.chartRef.current
    if (chartRef && chartRef.OnCardResize) {
      chartRef.OnCardResize()
    }
  }

  OpenBP = () => {
    OpenRecord('FSMBuildPlans', this.props.buildPlanId)
  }
}

export default RecordHistory
