import {Button, Toolbar, ToolbarSpacer} from '@progress/kendo-react-buttons';
import {Grid, GridColumn as Column} from '@progress/kendo-react-grid';
import moment from 'moment';

import {GridRowHeight} from '../../Components/Dashboard/helpers'
import {OpenRecord} from '../../helpers/runscripts';
import BaseComponent from '../../Components/BaseComponent';

import tgIcon from '../../assets/img/telegram-icon.svg';
import SwitchDates from './SwitchDates';
import OpenCardLink from '../../Components/Common/Buttons/OpenCardLink';
import NoSelectedBP from '../../Components/Common/NoSelectedBP';
import Loader from '../../Components/Common/Loader';
import {ICrewMember, IPercentageItem, ITaskItem} from './interfaces';
import styles from './daily.module.scss';
import CardManagement from '../../Components/Cards/CardManagement';
import commonStyles from '../../assets/styles/common.module.scss';
import ButtonLink from '../../Components/Common/Buttons/ButtonLink';
import {ModalRef} from '../../Components/Common/Modal/Modal';

interface props {
    buildPlanId: number
    isActive?: boolean
    onChangeDate?: (date: string) => void
}

interface state {
    loading: boolean;
    dates: Array<{ Date: string }>;
    currentDate: string;
    crew: Array<ICrewMember>;
    percentagesListData: Array<IPercentageItem>;
    status: {
        dailyUpdateComment: string;
        crewLead: { id: number, name: string };
        telegramLink: string | null;
        dispatchId: number | null;
    } | null;
    stars: null | Array<{
        title: string
        value: boolean | null
    }>;
    crewLeadScore: {
        dailyBudget: number
        absoluteValue: number
        relativeValue: number
    } | null;
    tasks: Array<ITaskItem>
}

class DailyStatus extends BaseComponent<props, state> {
    chatWindow: any;
    bpId = this.props.buildPlanId

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            dates: [],
            currentDate: '',
            crew: [],
            percentagesListData: [],
            status: null,
            stars: null,
            crewLeadScore: null,
            tasks: []
        }
    }

    componentDidMount() {
        this.LoadData();
    }

    componentDidUpdate(prevProps: props) {
        if (this.props.isActive && this.props.buildPlanId && this.bpId !== this.props.buildPlanId) {
            this.bpId = this.props.buildPlanId
            this.LoadData();
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount()
        if (this.chatWindow && !this.chatWindow.closed) this.chatWindow.close()
    }

    render() {
        if (!this.props.buildPlanId) return <NoSelectedBP/>;
        if (this.state.loading) return <Loader/>

        return <>
            <Toolbar>
                {this.state.currentDate &&
                    <SwitchDates currentDate={this.state.currentDate}
                                 dates={this.state.dates}
                                 onChangeDate={this.ChangeCurrentDate}></SwitchDates>}
                <ToolbarSpacer/>
                <span className="crew-lead-link">Crew Lead:&nbsp;
                    {this.state.status && <OpenCardLink
                        refName='Employees'
                        dataAttr={this.state.status?.crewLead.id}
                        text={this.state.status?.crewLead.name}/>}
				</span>
                {this.state.status && this.state.status.telegramLink &&
                    <Button
                        title="Open Telegram Chat"
                        onClick={this.OpenChat}
                        imageUrl={tgIcon}
                    />}
            </Toolbar>
            <Toolbar>
                {this.renderStars()}
                {this.renderCrewLeadScore()}
                <ToolbarSpacer/>
                {this.state.status && this.state.status.dispatchId &&
                    <ButtonLink onClick={this.OpenDispatch} text="Open Dispatch"/>
                }
            </Toolbar>
            <div className={styles.DailyStatusContent}>
                {this.renderSpace()}
                {this.renderCrewGrid()}
                {this.renderSpace()}
                {this.renderPercentagesGrid()}
                {this.renderSpace()}
                <div className={styles.Title}>Daily Update Comment</div>
                <div
                    className={styles.DailyUpdateComment}>{this.state.status?.dailyUpdateComment || ''}</div>
                {this.renderSpace()}
                {this.renderTasksGrid()}
            </div>
        </>
    }

    renderSpace = () => {
        return <div className={styles.Spacer}></div>
    }
    renderStars = () => {
        return (this.state.stars && this.state.stars.map((star, i) => {
            let className = 'star k-icon ';
            let color = 'inherit';
            if (star.value === null) {
                className += 'k-i-tri-state-null';
                color = 'grey';
            } else if (star.value) {
                className += 'k-i-checkbox-checked';
                color = '#3ac600';
            } else {
                className += 'k-i-checkbox';
                color = 'red';
            }
            return <span key={i} className={className} style={{color}}
                         title={star.title}></span>
        }))
    }
    renderCrewLeadScore = () => {
        if (this.state.crewLeadScore) {
            return (
                <span
                    style={{color: this.state.crewLeadScore.absoluteValue > 0 ? 'red' : 'green'}}>
					Used {this.state.crewLeadScore.relativeValue.toFixed(2)}% of the {this.state.crewLeadScore.dailyBudget.toFixed(2)} Daily Budget Hours
				</span>
            )
        }
    }
    renderTasksGrid = () => {
        return <>
            <div className={styles.Title}>Task Updates</div>
            <Grid
                data={this.state.tasks}
                filterable={false}
                scrollable="none"
                rowHeight={GridRowHeight}
            >
                <Column
                    field="TaskName"
                    title="Task"
                    width="200"
                />
                <Column
                    field="IsCompleted"
                    title="Completed"
                    width="80"
                    cell={(props) => {
                        return (
                            <td
                                className={commonStyles.TextCenter}>{props.dataItem.IsCompleted &&
                                <span className="k-icon k-i-check"></span>}</td>
                        )
                    }}
                    headerCell={(props) => (
                        <div className={commonStyles.TextCenter}>{props.title}</div>)}
                />
                <Column
                    field="StatusText"
                    title="Comments"
                />
            </Grid>
        </>
    }
    renderCrewGrid = () => {
        return <Grid
            className="daily-status__crew-grid"
            data={this.state.crew}
            filterable={false}
            scrollable="none"
            rowHeight={GridRowHeight}
            rowRender={(row: any, props: any) => {
                return (
                    <tr
                        className={`${row.props.className} ${props.dataItem.EmployeeName === "Total Hours:" ? styles.TotalRow : ''}`}
                        style={row.props.style}>
                        {row.props.children}
                    </tr>
                )
            }}
        >
            <Column
                field="EmployeeName"
                title="Crew"
                headerCell={(props) => (<div>{props.title}</div>)}
                cell={(props) => (
                    <td>
                        {
                            props.dataItem.TimeCardId ?
                                <OpenCardLink onClick={this.OpenTC}
                                              dataAttr={props.dataItem.TimeCardId}
                                              text={props.dataItem.EmployeeName}></OpenCardLink> :
                                <span>{props.dataItem.EmployeeName}</span>
                        }
                    </td>
                )}
            />
            <Column
                field="WOTypeName"
                title="WO Type"
                headerCell={(props) => (<div>{props.title}</div>)}
            />
            <Column
                field="Hours"
                title="Hours"
                width="100px"
                format="{0:n2}"
                cell={(props) => (
                    <td
                        className={styles.TextRight}>{props.dataItem.Hours !== null ? props.dataItem.Hours.toFixed(2) : ''}</td>
                )}
                headerCell={(props) => (
                    <div className={styles.TextRight}>{props.title}</div>)}
            />
        </Grid>
    }
    renderPercentagesGrid = () => {
        return <Grid
            data={this.state.percentagesListData}
            filterable={false}
            scrollable="none"
            rowHeight={GridRowHeight}
        >
            <Column
                field="RowName"
                title=" "
            />
            <Column
                field="ThisValue"
                title="This Day"
                format="{0:n2}"
                headerCell={(props) => (
                    <div className={styles.TextRight}>{props.title}</div>)}
                cell={(props) => (
                    <td
                        className={`${styles.TextRight} ${props.dataItem.ThisValueOver && styles.ValueOverCell}`}>
            <span
                title={props.dataItem.ThisValueOver || ''}>{props.dataItem.ThisValue}</span>
                    </td>
                )}
            />
            <Column
                field="ToValue"
                title="To Date"
                headerCell={(props) => (
                    <div className={styles.TextRight}>{props.title}</div>)}
                cell={(props) => (
                    <td
                        className={`${styles.TextRight} ${props.dataItem.ToValueOver && styles.ValueOverCell}`}>
            <span
                title={props.dataItem.ToValueOver || ''}>{props.dataItem.ToValue}</span>
                    </td>
                )}
            />
        </Grid>
    }

    LoadData = async (date?: string, force?: boolean) => {
        if (!this.bpId) return;

        try {
            this.setState({loading: true});
            let crew = [];
            let stars = [
                {value: false, title: 'Daily Comments Done'},
                {value: false, title: 'OnSite Hours > Target'},
                {value: false, title: 'Est. Completion > 0%'},
                {value: null, title: 'Recurring Checklists Done'},
                {value: false, title: 'Daily Labor Actual < Budget'}
            ];
            let percentagesListData: Array<IPercentageItem> = [];
            let status = null;
            let crewLeadScore = null;
            let tasks = [];
            let params = {BuildPlanId: this.bpId, Date: date};
            let result: any = await this.GetSQLData({
                spName: 'DB_BuildPlanDaily',
                params
            });
            const dates: { Date: string }[] = result[0] || [];

            let currentDate = (date && dates.find((item) => item.Date === date)) || dates[dates.length - 1];
            crew = this.prepareCrewData(result[1]);
            let dataHours = result[2] && result[2].length && result[2][0];
            let telegramLink: string | null = null
            if (dataHours) {
                status = {
                    telegramLink: dataHours.TelegramInviteLink,
                    dailyUpdateComment: dataHours.StatusText || '',
                    crewLead: {id: dataHours.CrewLeadId, name: dataHours.CrewLeadName},
                    dispatchId: dataHours.DispatchId
                }
                telegramLink = dataHours.TelegramInviteLink
                let dailyBudget = dataHours.ThisDatePercentage / 100 * dataHours.BudgetHours;
                let dailyActual = crew[crew.length - 1].Hours || 0;
                let ThisDateOvertimePercentage = dataHours.ThisDateOvertimeHours && dataHours.ThisDateTotalHours ? (dataHours.ThisDateOvertimeHours / dataHours.ThisDateTotalHours * 100).toFixed(0) : 0;
                let ThisDateOnsitePercentage = dataHours.ThisDateOnsiteHours && dataHours.ThisDateTotalHours ? (dataHours.ThisDateOnsiteHours / dataHours.ThisDateTotalHours * 100).toFixed(0) : 0;
                let ToDateOvertimePercentage = dataHours.ToDateOvertimeHours && dataHours.ToDateTotalHours ? (dataHours.ToDateOvertimeHours / dataHours.ToDateTotalHours * 100).toFixed(0) : 0;
                let ToDateOnsitePercentage = dataHours.ToDateOnsiteHours && dataHours.ToDateTotalHours ? (dataHours.ToDateOnsiteHours / dataHours.ToDateTotalHours * 100).toFixed(0) : 0;

                let actualHoursConsumed = dataHours.BudgetHours !== null && dataHours.ToDateTotalHours !== null ? (dataHours.BudgetHours && 100 * dataHours.ToDateTotalHours / dataHours.BudgetHours).toFixed(0) : 0;
                percentagesListData = [
                    {
                        RowName: 'Est. completion',
                        ThisValue: dataHours.ThisDatePercentage !== null ? dataHours.ThisDatePercentage.toFixed(0) + '%' : '',
                        ToValue: dataHours.ToDatePercentage !== null ? dataHours.ToDatePercentage.toFixed(0) + '%' : '',
                        ToValueOver: actualHoursConsumed && actualHoursConsumed > dataHours.ToDatePercentage ? 'Actual Hours Consumed: ' + actualHoursConsumed + '%' : false
                    },
                    {
                        RowName: 'Overtime Hrs %',
                        ThisValue: ThisDateOvertimePercentage + '%',
                        ThisValueOver: ThisDateOvertimePercentage > dataHours.OvertimeMax ? 'Goal is Maximum: ' + dataHours.OvertimeMax.toFixed(0) + '%' : false,
                        ToValue: ToDateOvertimePercentage + '%',
                        ToValueOver: ToDateOvertimePercentage > dataHours.OvertimeMax ? 'Goal is Maximum: ' + dataHours.OvertimeMax.toFixed(0) + '%' : false
                    },
                    {
                        RowName: 'On Site Hours %',
                        ThisValue: ThisDateOnsitePercentage + '%',
                        ThisValueOver: ThisDateOnsitePercentage < dataHours.OnsiteMin ? 'Goal is Minimum: ' + dataHours.OnsiteMin + '%' : false,
                        ToValue: ToDateOnsitePercentage + '%',
                        ToValueOver: ToDateOnsitePercentage < dataHours.OnsiteMin ? 'Goal is Minimum: ' + dataHours.OnsiteMin + '%' : false
                    },
                    {
                        RowName: 'Days On Site',
                        ThisValue: '',
                        ToValue: dataHours.ToDateOnsiteDays.toFixed(0),
                        ToValueOver: dataHours.PlannedDays && dataHours.ToDateOnsiteDays > dataHours.PlannedDays ? 'Goal is: ' + dataHours.PlannedDays.toFixed(0) : false
                    }
                ];

                stars[0].value = !!dataHours.StatusText;
                stars[1].title = 'OnSite Hours > ' + (dataHours.OnsiteMin ? dataHours.OnsiteMin : 0) + '%';
                if (
                    dataHours.ThisDateTotalHours !== null &&
                    dataHours.ThisDateOnsiteHours !== null &&
                    dataHours.OnsiteMin !== null &&
                    dataHours.ThisDateTotalHours > 0 &&
                    100 * dataHours.ThisDateOnsiteHours / dataHours.ThisDateTotalHours > dataHours.OnsiteMin
                ) {
                    stars[1].value = true;
                }
                stars[2].value = dataHours.ThisDatePercentage > 0;
                if (dataHours.RecurringCount) stars[3].value = dataHours.IncompleteRecurring === 0;
                stars[4].value = dailyBudget ? dailyActual <= dailyBudget : null;

                let crewLeadAbsolute = dailyBudget ? (dailyActual - dailyBudget).toFixed(2) : null;
                crewLeadScore = crewLeadAbsolute === null ? null : {
                    dailyBudget: dailyBudget ? dailyBudget : 0,
                    absoluteValue: +crewLeadAbsolute,
                    relativeValue: (dailyActual / dailyBudget * 100)
                };
            }
            tasks = this.prepareTasksGridData(result[3] || [], currentDate.Date);

            if (this.chatWindow && !this.chatWindow.closed) {
                if (telegramLink) {
                    this.OpenChat(telegramLink);
                } else {
                    this.chatWindow.close()
                }
            }
            if (this.props.onChangeDate) this.props.onChangeDate(currentDate.Date)
            this.setState({
                dates,
                currentDate: currentDate.Date,
                crew,
                percentagesListData,
                status,
                crewLeadScore,
                stars,
                tasks
            });

        } catch (e: any) {
            ModalRef.showDialog({
                type: 'error',
                title: 'Error',
                width: 600,
                children: <div>
                    <div>{e.message}</div>
                    <div>{e.stack}</div>
                </div>
            })
        } finally {
            this.setState({loading: false})
        }
    }

    prepareCrewData = (crew: Array<ICrewMember>) => {
        let crewGridFooterRow: ICrewMember = {
            EmployeeName: 'Total Hours:',
            WOTypeName: '',
            Hours: null
        };
        crew.forEach(function (member) {
            if (member.Hours !== null) {
                if (crewGridFooterRow.Hours === null) crewGridFooterRow.Hours = 0;
                crewGridFooterRow.Hours += member.Hours;
            }
        });
        crew.push(crewGridFooterRow);
        return crew;
    }

    prepareTasksGridData = (data: Array<ITaskItem>, currentDate: string) => {
        return data.filter((task) => {
            return !!(task.UpdateDate && (moment(task.UpdateDate).format('L') === moment(currentDate).format('L')))
        });
    }

    ChangeCurrentDate = (date: string) => {
        if (this.props.onChangeDate) this.props.onChangeDate(date)
        this.LoadData(date);
    }

    OpenChat = async (e: string | React.MouseEvent<HTMLButtonElement>) => {
        var windowParams = 'width=700,height=800,left=40,top=150';
        let telegramLink = typeof e === 'string' ? e : this.state.status?.telegramLink
        if (telegramLink) {
            if (this.chatWindow && !this.chatWindow.closed) {
                this.chatWindow.location = telegramLink;
                if (e) this.chatWindow.focus();
            } else {
                this.chatWindow = window.open(telegramLink, 'Chat telegram', windowParams);
                this.chatWindow.focus();
            }
        }
    }

    OpenDispatch = () => {
        let dsId = this.state.status?.dispatchId;
        if (dsId) {
            CardManagement.OpenDispatchCard({
                newDispatch: false,
                dsId
            })
        }
    }

    OpenTC = (e: any, tcId: number) => {
        if (tcId) OpenRecord('TKTimeCards', tcId).finally(this.Refresh);
    }

    Refresh = () => {
        let date = this.state.currentDate;
        this.LoadData(date);
    }
}

export default DailyStatus;
