import {Loader} from '@progress/kendo-react-indicators';
import styles from '../../Components/Common/Form/form.module.scss'
import React from "react";
import debounce from "lodash.debounce";

interface IProps {
    isPinned: boolean
    color: string | null
    isLoading: boolean
    onClick: () => void
}

const PinToggle = (props: IProps) => {
    const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if (props.isLoading) return false
        click()
    }

    const click = debounce(() => {
        props.onClick()
    }, 100)

    return <div
        style={{
            width: 20,
            height: 20,
            margin: '0px 8px 0 4px',
            borderRadius: '50%',
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            background: (!props.isLoading && props.color) || 'transparent',
            cursor: "pointer",
            position: 'relative',
            lineHeight: 1,
        }}
        onClick={onClick}
    >{props.isLoading &&
        <Loader
            className={styles.ProcessingButtonLoader}
            themeColor={"dark"}
            type='converging-spinner'
            size="small"
        />}
        {!props.isLoading && <span
            className={`${props.isPinned ? 'mdi mdi-pin' : 'mdi mdi-pin-outline'}`}
            style={{
                color: props.isPinned ? '#fff' : '#ebebeb',
                fontSize: props.isPinned ? 12 : 16
            }}
        ></span>}
    </div>
}

export default PinToggle;
