import {Component} from 'react'
import {Grid, GridColumn as Column} from '@progress/kendo-react-grid';
import OpenCardLink from '../../../Components/Common/Buttons/OpenCardLink';
import {ITimeEntry} from '../interfaces';
import styles from './timecard.module.scss';

interface props {
  timeEntries: Array<ITimeEntry> | null
}

interface state {
  hidden: boolean
}

class TCTESummery extends Component<props, state> {

  constructor(props: props) {
    super(props)
    this.state = {
      hidden: true
    }
  }

  render() {
    let timeentries = this.props.timeEntries
    if (!timeentries || !timeentries.length) return null

    return <Grid
      data={timeentries}
      filterable={false}
      scrollable="none"
      className={`${styles.TCGrid} ${this.state.hidden ? styles.TCGridHidden : undefined}`}
    >
      <Column
        field="LocationName"
        cell={(props) => {
          let data = props.dataItem
          return <td>
            {data.isTotal ? <>Total:</> :
              <OpenCardLink
                text={data.LocationName}
                dataAttr={data.LocationId}
                refName={data.LocationRefName}
              />}
          </td>
        }}
        headerCell={() => {
          return <div
            className={styles.TCGridToggleCell}
            onClick={this.ToggleGrid}
          >
            <span
              className={`k-icon ${this.state.hidden ? 'k-i-arrow-chevron-right' : 'k-i-arrow-chevron-down'}`}></span>
            TIME ENTRIES SUMMARY
          </div>
        }}
      />
      <Column
        field="CostTypeName"
        title="Cost Type"
        width="260px"
        cell={(props) => {
          let data = props.dataItem
          return <td>
            {data.CostTypeName}
            {data.IsPrevailingWage && <sup className={styles.PrevailingWage}
                                           title="Prevailing wage">PW</sup>}
          </td>
        }}
      />
      <Column
        field="Clocked"
        title="Clocked"
        width="65px"
      />
      <Column
        field="Approved"
        title="Approved"
        width="65px"
      />
    </Grid>
  }

  ToggleGrid = () => {
    this.setState((state) => ({
      hidden: !state.hidden
    }))
  }
}

export default TCTESummery;
