import {
    costTypeCode,
    IAdjustedTimeLineItem,
    IAdjustedTimeLineItemServer,
    IAdjustmentAllocation,
    IAllocation,
    IDrawAdjustedTE,
    IDrawMileage,
    IDrawOriginalTE,
    IDuration,
    IMileage,
    IOriginalTimeLineItem,
    IOriginalTimeLineItemServer,
    ITCSummaryInfo,
    ITimeLineAdjustedItem,
    ITimeLineOriginalItem,
} from './interfaces'
import moment from "moment";
import {formatHoursDuration} from "../../helpers/helpers";

export const costTypeToColor: {
    [key in costTypeCode]: string
} = {
    // AUTHSTOP: '#FF86AB', // '#ff007f', // pink
    UNAUTHSTOP: '#e80000', // '#E5383B', // red

    LUNCH: '#FF8200', // orange
    WLUNCH: '#f7bb7d', // soft orange
    HRLYNW: 'aquamarine',
    SNW: 'aquamarine',
    ONSITE: '#08b100', // green
    OFFICE: '#6D00C4', // violet
    INTDRIVE: '#B4EB65', // yellowgreen
    DRIVE: 'yellow', // yellow
    EMPTY: '#e0e0e0', // grey
    OTHER: '#2196f3', // $primary
    DST: '#ffe505', // beteween yellow and orange
}
const UNAUTHSTOP_KNOWN_COLOR = '#e8009f';

export function getColorByCostType(costType: costTypeCode | null | undefined, IsKnownLocation?: boolean, IsWarehouse?: boolean) {
    if (IsWarehouse) return costTypeToColor.OFFICE
    if (costType === 'UNAUTHSTOP' && IsKnownLocation) return UNAUTHSTOP_KNOWN_COLOR
    return !!costType ? (costTypeToColor[costType] || costTypeToColor.OTHER) : costTypeToColor.EMPTY
}

window.helpers.getColorByCostType = getColorByCostType;


export const SortByDate = (timeA: string, timeB: string) => (moment(timeA).isAfter(moment(timeB)) ? 1 : -1)

export const ResetSecondsDateString = (dateTime: string) => dateTime.slice(0, -2) + '00'

export const getDuration = (start: string, finish: string): IDuration => {
    const [, startTime] = start.split('T')
    const [, finishTime] = finish.split('T')
    const [startHoursStr, startMinutesStr] = startTime.split(':')
    const [finishHoursStr, finishMinutesStr] = finishTime.split(':')
    let diffHours = (+finishHoursStr) - (+startHoursStr)
    let diffMinutes = (+finishMinutesStr) - (+startMinutesStr)
    if (diffMinutes < 0) {
        diffHours -= 1
        const finishMinutes = (+finishMinutesStr) + 60
        diffMinutes = finishMinutes - (+startMinutesStr)
    }
    return {
        h: diffHours,
        m: diffMinutes
    }
}

export const GetPreparedTimeLine = (
    dayLog: IOriginalTimeLineItemServer[],
    dayLogPopup: Array<IAllocation>,
    adjustments: IAdjustedTimeLineItemServer[],
    adjustmentsAllocations: Array<IAdjustmentAllocation>,
    mileages: Array<IMileage>
): {
    adjustments: IAdjustedTimeLineItem[],
    waivedLunches: string[],
    totalClocked: number,
    totalApproved: number,
    totalClockedAdjustments: number,
    totalApprovedAdjustments: number,
    drawOriginal: IDrawOriginalTE[],
    drawAdjusted: IDrawAdjustedTE[],
    drawMileages: IDrawMileage[],
} => {
    let waivedLunches: string[] = []
    let totalClocked = 0
    let totalApproved = 0
    const preparedDayLog: IOriginalTimeLineItem[] = []
    const uniqueTimes: { [key: string]: string } = {}
    const uniqueTimeArray: string[] = []
    // prepare original TEs
    for (let i = 0; i < dayLog.length; i++) {
        let isLunch = dayLog[i].Event === 'LUNCH'
        const IsWaivedLunch = isLunch && dayLog[i].Finish === dayLog[i].Start
        const item: IOriginalTimeLineItem = {
            ...dayLog[i],
            ApprovedDurationString: '',
            ClockedDurationString: '',
            ManualAllocation: [],
            TimeStart: '',
            TimeFinish: '',
            Color: '',
            IsWaivedLunch
        }
        preparedDayLog.push(item)
        const startValue = ResetSecondsDateString(item.Start)
        const finishValue = ResetSecondsDateString(item.Finish)
        item.Finish = finishValue
        item.Start = startValue
        const startMoment = moment(startValue)
        const finishMoment = moment(finishValue)
        let startValueFormatted = startMoment.format('LT')
        let finishValueFormatted = finishMoment.format('LT')
        let isClockIn = item.Event.indexOf('CLOCKIN') > -1
        let isClockOut = item.Event.indexOf('CLOCKOUT') > -1
        let isClockInOut = isClockIn || isClockOut
        item.TimeStart = startValueFormatted;
        item.TimeFinish = finishValueFormatted;
        totalClocked += item.ClockedDuration
        totalApproved += item.ApprovedDuration
        if (!isClockInOut) {
            item.ApprovedDurationString = formatHoursDuration(item.ApprovedDuration);
            item.ClockedDurationString = formatHoursDuration(item.ClockedDuration);
        }
        if (isClockOut) {
            if (item.Event.indexOf('REMOTE') > -1) {
                item.DataMark = 'R';
            } else if (item.Event.indexOf('FORCE') > -1) {
                item.DataMark = 'F';
            }
        }
        let CostTypeCode: costTypeCode | null = IsWaivedLunch ? 'WLUNCH' : item.CostTypeCode
        if (item.CostTypeColor) item.Color = item.CostTypeColor
        else if (CostTypeCode) item.Color = getColorByCostType(CostTypeCode, item.IsKnownLocation);

        item.ManualAllocation = !isLunch ? dayLogPopup.filter((detail: any) => detail.TEId === item.Id) : [];
        if (IsWaivedLunch) {
            waivedLunches.push(item.TimeStart)
        } else {
            if (!uniqueTimes[startValue]) {
                uniqueTimes[startValue] = startValue
                uniqueTimeArray.push(startValue)
            }
            if (!uniqueTimes[finishValue]) {
                uniqueTimes[finishValue] = finishValue
                uniqueTimeArray.push(finishValue)
            }
        }
    }

    let totalClockedAdjustments = 0
    let totalApprovedAdjustments = 0
    const preparedAdjustments: IAdjustedTimeLineItem[] = []
    // prepare adjusted TEs
    adjustments.sort((itemA: any, itemB: any) => SortByDate(itemA.Start, itemB.Start))
    for (let i = 0; i < adjustments.length; i++) {
        const isLunch = adjustments[i].CostTypeCode === 'LUNCH'
        const IsWaivedLunch = isLunch && adjustments[i].Finish === adjustments[i].Start
        let item: IAdjustedTimeLineItem = {
            ...adjustments[i],
            ApprovedDurationString: '',
            ActualDurationString: '',
            TimeStart: '',
            TimeFinish: '',
            ManualAllocation: [],
            Color: '',
            sortNumber: i + 1,
            IsWaivedLunch
        }
        const startValue = ResetSecondsDateString(item.Start)
        const finishValue = ResetSecondsDateString(item.Finish)
        if (!IsWaivedLunch) {
            item.Finish = finishValue
            item.Start = startValue
            const startMoment = moment(startValue)
            const finishMoment = moment(finishValue)
            let startValueFormatted = startMoment.format('LT')
            let finishValueFormatted = finishMoment.format('LT')
            if (!uniqueTimes[startValue]) {
                uniqueTimes[startValue] = startValue
                uniqueTimeArray.push(startValue)
            }
            if (!uniqueTimes[finishValue]) {
                uniqueTimes[finishValue] = finishValue
                uniqueTimeArray.push(finishValue)
            }
            let CostTypeCode: costTypeCode | null = IsWaivedLunch ? 'WLUNCH' : item.CostTypeCode

            if (item.CostTypeColor) item.Color = item.CostTypeColor
            else if (CostTypeCode) item.Color = getColorByCostType(CostTypeCode);

            item.ManualAllocation = !isLunch ? adjustmentsAllocations.filter((detail) => detail.RowNumber === item.RowNumber) : [];
            item.ActualDurationString = formatHoursDuration(item.ActualDuration);
            if (isLunch) {
                item.ApprovedDuration = 0
                item.ApprovedDurationString = '00:00'
            } else {
                item.ApprovedDurationString = formatHoursDuration(item.ApprovedDuration);
            }

            totalClockedAdjustments += item.ActualDuration
            totalApprovedAdjustments += item.ApprovedDuration

            item.TimeStart = startValueFormatted;
            item.TimeFinish = finishValueFormatted;
        }
        preparedAdjustments.push(item)
    }

    uniqueTimeArray.sort(SortByDate)
    const timeLineOriginal: ITimeLineOriginalItem[] = []
    for (let TE of preparedDayLog) {
        let Start = ResetSecondsDateString(TE.Start)
        let Finish = ResetSecondsDateString(TE.Finish)
        timeLineOriginal.push({
            StartTimeIndex: uniqueTimeArray.findIndex((time) => time === Start),
            FinishTimeIndex: uniqueTimeArray.findIndex((time) => time === Finish),
            te: TE,
        })
    }
    const timeLineAdjusted: ITimeLineAdjustedItem[] = []
    for (let TE of preparedAdjustments) {
        let Start = ResetSecondsDateString(TE.Start)
        let Finish = ResetSecondsDateString(TE.Finish)
        timeLineAdjusted.push({
            StartTimeIndex: uniqueTimeArray.findIndex((time) => time === Start),
            FinishTimeIndex: uniqueTimeArray.findIndex((time) => time === Finish),
            te: TE,
        })
    }

    const drawOriginal: IDrawOriginalTE[] = []
    for (let i = 0; i < timeLineOriginal.length; i++) {
        const te = timeLineOriginal[i]
        const isClock = te.te.Event.indexOf('CLOCK') > -1
        const prevTE = timeLineOriginal[i - 1]
        const prevFinishTimeIndex = prevTE ? prevTE.FinishTimeIndex : 0
        if (prevFinishTimeIndex !== te.StartTimeIndex) { // prev empty rows
            const prevAdjustedTEs = timeLineAdjusted.filter((adjTE) => {
                if (isClock && adjTE.FinishTimeIndex === te.StartTimeIndex) return false
                if (adjTE.StartTimeIndex >= prevFinishTimeIndex && adjTE.FinishTimeIndex <= te.StartTimeIndex) return true
                return false
            })
            let emptyRows = 0
            for (let adjTE of prevAdjustedTEs) {
                // todo if в этом промежутке встречаются подряд клокин и клокаут то добавить еще + 1 к emptyRows ????
                let count = adjTE.FinishTimeIndex - adjTE.StartTimeIndex || 1
                emptyRows += count
            }

            if (emptyRows > 0) {
                drawOriginal.push({
                    // empty
                    StartTimeIndex: te.StartTimeIndex,
                    FinishTimeIndex: te.FinishTimeIndex,
                    rows: emptyRows,
                })
            }
        }

        if (i > 0) {
            const prevTE = timeLineOriginal[i - 1].te
            if (prevTE && te.te) {
                let isPrevTEClockIn = prevTE.Event.indexOf('CLOCKIN') > -1
                let isClockOut = te.te.Event.indexOf('CLOCKOUT') > -1
                if (isPrevTEClockIn && isClockOut) {
                    drawOriginal.push({ // empty
                        Start: prevTE.TimeStart,
                        Finish: te.te.TimeFinish,
                        StartTimeIndex: te.StartTimeIndex,
                        FinishTimeIndex: te.FinishTimeIndex,
                        rows: 1,
                    })
                }
            }
        }

        let rows = te.FinishTimeIndex - te.StartTimeIndex
        if (te.FinishTimeIndex === te.StartTimeIndex) rows += 1

        drawOriginal.push({
            ...te,
            Start: te.te.TimeStart,
            Finish: te.te.TimeFinish,
            rows: rows
        })
    }

    const drawAdjusted: IDrawAdjustedTE[] = []
    for (let i = 0; i < timeLineAdjusted.length; i++) {
        const te = timeLineAdjusted[i]
        const prevTE = timeLineAdjusted[i - 1]
        const prevFinishTimeIndex = prevTE ? prevTE.FinishTimeIndex : 0
        let prevOriginalTEs: ITimeLineOriginalItem[] = []
        if (prevFinishTimeIndex !== te.StartTimeIndex) { // prev empty rows
            let emptyRows = te.StartTimeIndex - prevFinishTimeIndex
            prevOriginalTEs = timeLineOriginal.filter((orTE) => {
                if (orTE.StartTimeIndex >= prevFinishTimeIndex && orTE.FinishTimeIndex <= te.StartTimeIndex) {
                    const isClock = orTE.te.Event.indexOf('CLOCK') > -1
                    if (isClock) {
                        emptyRows += 1
                    }
                    return true
                }
                return false
            })

            if (emptyRows > 0) {
                drawAdjusted.push({ // empty
                    StartTimeIndex: prevFinishTimeIndex,
                    FinishTimeIndex: te.FinishTimeIndex,
                    rows: emptyRows,
                })
            }
        }

        const clockinOutOriginalTEs = timeLineOriginal.filter((orTE) => {
            const isClockInOut = orTE.te.Event.indexOf('CLOCK') > -1
            const index = prevOriginalTEs.findIndex((prevOrTE) => prevOrTE === orTE)
            return index === -1 && isClockInOut && orTE.StartTimeIndex === te.StartTimeIndex && prevTE?.FinishTimeIndex !== te.StartTimeIndex
        })
        if (clockinOutOriginalTEs.length) {
            let emptyRows = clockinOutOriginalTEs.length
            if (emptyRows === 1 && i === 1 && prevTE?.FinishTimeIndex === te.StartTimeIndex) {
                // do nothing
            } else {
                drawAdjusted.push({ // empty
                    StartTimeIndex: te.StartTimeIndex,
                    FinishTimeIndex: te.FinishTimeIndex,
                    rows: emptyRows,
                })
            }
        }

        let rows = te.FinishTimeIndex - te.StartTimeIndex
        const allOriginalTEs = timeLineOriginal.filter((orTE) => (
            orTE.StartTimeIndex >= te.StartTimeIndex &&
            orTE.FinishTimeIndex <= te.FinishTimeIndex
        ))
        // 1. find подряд клокин и клокаут внутри промежутка. Между ними в оригинальной таймлайн рисуется еще один пустой кусочек
        // find подряд клокаут и клокин и понять сколько строк нужно добавить дополнительно
        for (let adjI = 0; adjI < allOriginalTEs.length; adjI++) {
            if (adjI === 0) continue // don't have prev te yet
            const orTE = allOriginalTEs[adjI]
            const prevORTE = allOriginalTEs[adjI - 1]
            const isPrevTEClockIn = prevORTE.te.Event.indexOf('CLOCKIN') > -1
            const isPrevTEClock = prevORTE.te.Event.indexOf('CLOCK') > -1
            const isClockOut = orTE.te.Event.indexOf('CLOCKOUT') > -1
            const isClock = orTE.te.Event.indexOf('CLOCK') > -1
            // if (isPrevTEClockIn && isClockOut) rows += 1 // подряд клокин и клокаут внутри промежутка. Между ними в оригинальной таймлайн рисуется еще один пустой кусочек

            if (isPrevTEClock && isClock) rows += 1
            if (prevORTE.StartTimeIndex === orTE.StartTimeIndex) {
                const clockTE = isPrevTEClock ? prevORTE : isClock ? orTE : undefined
                if (clockTE) {
                    const indexClockTe = isPrevTEClock ? adjI - 1 : adjI
                    const isNotFirst = indexClockTe > 1
                    const isLast = indexClockTe === allOriginalTEs.length - 1
                    if (isNotFirst && !isLast) {
                        rows += 1
                    }
                }
            }
        }
        drawAdjusted.push({
            ...te,
            te: te.te,
            Start: te.te?.TimeStart,
            Finish: te.te?.TimeFinish,
            rows: rows
        })
    }

    const drawMileages: IDrawMileage[] = []
    let lastFinishItemIndex = 0
    for (let i = 0; i < mileages.length; i++) {
        const ME = mileages[i]
        const startItemIndex = drawOriginal.findIndex((te) => ME.StartTEId === te.te?.Id);
        const finishItemIndex = drawOriginal.findIndex((te) => ME.FinishTEId === te.te?.Id);
        const finishItem = finishItemIndex > -1 && drawOriginal[finishItemIndex]
        const startItem = startItemIndex > -1 && drawOriginal[startItemIndex]
        if (!startItem || !finishItem) {
            continue
        }
        let prevOriginalTEs: IDrawOriginalTE[] = []
        if (startItemIndex > lastFinishItemIndex) {
            let emptyRows = 0
            drawOriginal.forEach((orTE, index) => {
                if (
                    (i === 0 && index >= lastFinishItemIndex && index < startItemIndex) ||
                    (index > lastFinishItemIndex && index < startItemIndex)
                ) {
                    emptyRows += orTE.rows
                    prevOriginalTEs.push(orTE)
                }
            })
            drawMileages.push({ // empty
                StartTimeIndex: drawOriginal[lastFinishItemIndex].FinishTimeIndex,
                FinishTimeIndex: startItem.StartTimeIndex,
                StartRows: 0,
                FinishRows: 0,
                CenterRows: emptyRows,
            })
        }

        const clockinOutOriginalTEs = drawOriginal.filter((orItem, currentIndex) => {
            if (orItem === startItem || orItem === finishItem) return false
            const orTE = orItem.te
            const isClockInOut = !!orTE && orTE.Event.indexOf('CLOCK') > -1
            const index = prevOriginalTEs.findIndex((prevOrTE) => prevOrTE.te === orTE)

            return index === -1 && isClockInOut && orItem.StartTimeIndex === startItem.StartTimeIndex
        })
        if (clockinOutOriginalTEs.length) {
            drawMileages.push({ // empty
                StartTimeIndex: startItem.StartTimeIndex,
                FinishTimeIndex: startItem.StartTimeIndex,
                StartRows: 0,
                FinishRows: 0,
                CenterRows: clockinOutOriginalTEs.length,
            })
        }

        let centerRows = 0
        const centertes: any[] = []
        drawOriginal.forEach((orTE, index) => {
            if (index > startItemIndex && index < finishItemIndex) {
                centerRows += orTE.rows
                centertes.push(orTE)
            }
        })

        drawMileages.push({ // empty
            StartTimeIndex: startItem.StartTimeIndex,
            FinishTimeIndex: finishItem.FinishTimeIndex,
            Start: startItem.Start,
            Finish: finishItem.Finish,
            StartRows: startItem.rows,
            FinishRows: finishItem.rows,
            CenterRows: centerRows,
            me: ME,
        })
        lastFinishItemIndex = finishItemIndex
    }

    return {
        adjustments: preparedAdjustments,
        waivedLunches,
        totalClocked,
        totalApproved,
        totalClockedAdjustments,
        totalApprovedAdjustments,
        drawOriginal,
        drawAdjusted,
        drawMileages,
    }
}

export const GetPreparedInfo = (info?: ITCSummaryInfo) => {
    if (!info) return null
    info.Date = moment(info.Date).format('L');
    let firstClockInMoment = moment(info.FirstClockIn)
    let lastClockOutMoment = moment(info.LastClockOut)
    if (info.FirstClockIn) {
        info.OverClockIn = firstClockInMoment.format('L') !== info.Date;
        info.FirstClockIn = firstClockInMoment.format('LT');
    }
    if (info.LastClockOut) {
        info.OverClockOut = lastClockOutMoment.format('L') !== info.Date;
        info.LastClockOut = lastClockOutMoment.format('LT');
    }
    return info
}
