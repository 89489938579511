import {Component} from 'react'
import {DateInputProps, DatePicker} from '@progress/kendo-react-dateinputs'
import moment from 'moment'
import {IFilterSetting} from './interfaces'
import {maxDate, minDate} from './helpers'
import ClearableDateInput from '../Common/Form/ClearableDateInput';

interface props {
  defaultValue: any
  filter: IFilterSetting

  onChange(value: any, filter: IFilterSetting): void

  clear?(filter: IFilterSetting): void
}

interface state {
}

class DateFilter extends Component<props, state> {
  dateChangeTimeout: any
  value: any

  render() {
    let filter = this.props.filter
    return (
      <DatePicker
        data-param={filter.placeholder}
        id={filter.id}
        onChange={this.OnDatePickerChange}
        defaultValue={this.props.defaultValue}
        min={minDate}
        max={maxDate}
        dateInput={this.props.clear && this.renderClearableDateInput}
      ></DatePicker>
    )
  }

  renderClearableDateInput = (props: DateInputProps) => {
    return <ClearableDateInput
      defaultProps={props}
      isDate={true}
      clear={this.ClearFilter}
    />
  }

  ClearFilter = () => {
    if (this.props.clear) this.props.clear(this.props.filter)
  }

  ChangeDatePicker = () => {
    this.props.onChange(this.value, this.props.filter)
  }

  OnDatePickerChange = (e: any) => {
    let value = e.value
    if (!value || moment(value).isBefore(minDate) || moment(value).isAfter(maxDate)) return
    this.value = e.value
    if (this.dateChangeTimeout) clearTimeout(this.dateChangeTimeout)
    if (e.syntheticEvent.type === 'click') this.ChangeDatePicker()
    else {
      this.dateChangeTimeout = setTimeout(this.ChangeDatePicker, 1000)
    }
  }
}

export default DateFilter
