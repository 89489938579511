import React from 'react';
import {Deferred} from '../../../helpers/deffered';
import styles from './portalLiveMap.module.scss';
import commonStyles from '../../../assets/styles/common.module.scss';
import {ILiveMapProps} from '../../../Pages/LiveMap/interfaces';
import LiveMap from '../../../Pages/LiveMap/LiveMap';
import {calendarId} from '../../../helpers/interfaces';

interface IPosition {
  left: number
  top: number
  width: number
  height: number
}

export class ModalComponentInIframeRef {
  static ref: any;
  static initializedRef = new Deferred();

  static setRef(ref: any) {
    this.ref = ref;
    this.initializedRef.resolve();
  }

  static async checkRefInit() {
    if (this.ref === undefined) await this.initializedRef;
  }

  static async showLiveMap(
    mode: calendarId,
    props?: ILiveMapProps) {
    await this.checkRefInit();
    this.ref.ShowLiveMap(mode, props);
  }

  static async hideLiveMap() {
    await this.checkRefInit();
    this.ref.hideLiveMap();
  }
}

interface props {
}

interface state {
  coords: IPosition | null
  props: ILiveMapProps | undefined
  show: boolean
}

export class ModalComponentInIframe extends React.Component<props, state> {
  mode: calendarId | undefined

  constructor(props: any) {
    super(props)
    this.state = {
      coords: null,
      props: undefined,
      show: true
    }
  }

  componentDidMount() {
    ModalComponentInIframeRef.setRef(this);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.ChangeCoords);
  }

  render() {
    let props = this.state.props
    let coords = this.state.coords
    if (!props || !coords) return null
    let className = !this.state.show ? styles.HiddenPortal : '';
    className = `${className} ${commonStyles.IEfixIframeWrap}`;
    return (
      <div
        className={className}
        style={{
          top: coords.top,
          left: coords.left,
          height: coords.height,
          width: coords.width,
          zIndex: 10001
        }}
      >
        {<LiveMap {...props} />}
        <iframe className={commonStyles.IEfixIframe} src="about:blank"
                title="iefix"></iframe>
      </div>
    )
  }

  ShowLiveMap = (mode: calendarId, props?: ILiveMapProps) => { // props is not required?
    this.mode = mode
    let coords = this.GetCoords()
    this.setState({coords, props: props || undefined, show: true});
    window.addEventListener('resize', this.ChangeCoords);
  }

  hideLiveMap = () => {
    window.removeEventListener('resize', this.ChangeCoords);
    this.setState((state) => ({
      show: false,
      props: state.props ? {...state.props, isActive: false} : state.props
    }))
  }

  ChangeCoords = () => {
    this.setState({coords: this.GetCoords()})
  }

  GetCoords = () => {
    let calendarWrapId = this.mode === 'page' ? 'Calendar' : `Calendar_${this.mode}`
    let calendarIframe = document.getElementById('CalendarIframe_' + this.mode)
    let calendarPageWrap = document.getElementById(calendarWrapId)
    if (calendarIframe) {
      // @ts-ignore
      let iframeWindow = calendarIframe.contentWindow
      if (iframeWindow) {
        let mapContainer = iframeWindow.document.querySelector('#FrameLiveMap')
        if (mapContainer) {
          let calendarCoords = calendarIframe.getBoundingClientRect()
          let coords = mapContainer.getBoundingClientRect()
          let styles = window.getComputedStyle(calendarPageWrap!)
          let isHidden = styles.visibility === "hidden"
          let liveMapCoords = {
            left: coords.left + calendarCoords.left,
            top: coords.top + calendarCoords.top,
            width: coords.width,
            height: coords.height
          }
          if (isHidden) {
            liveMapCoords.top = liveMapCoords.top - window.innerHeight
            liveMapCoords.left = liveMapCoords.left - 46 //?? todo
          }
          return liveMapCoords
        }
      }
    }
    return null
  }
}
