import {Component} from 'react';

import NoSelectedBP from '../../Common/NoSelectedBP';
import LaborHoursGuage from './LaborHoursDetails';
import BPHistoryChart from '../HistoryChart';
import BPCostTypeCharts from '../CostTypeCharts';
import {GetNewRemountKey} from '../../Dashboard/helpers';
import {pageId} from '../../../Pages/Home/interfaces';
import UserInfo from '../../../stores/User'
import ButonLink from '../../Common/Buttons/ButtonLink';
import CardManagement from '../../Cards/CardManagement';

interface props {
    buildPlanId?: number
    isActive: boolean
    doNotSaveFilters?: boolean
    pageId: pageId
}

interface state {
    remountKey: number
    showOpenBudgetLink: boolean
}

class LaborHours extends Component<props, state> {
    bpId = this.props.buildPlanId
    UseEasyBudget: boolean = false

    constructor(props: props) {
        super(props)
        this.state = {
            remountKey: GetNewRemountKey(),
            showOpenBudgetLink: false
        }
    }

    async componentDidMount() {
        if (this.props.pageId === 'DailyDashboard') {
            let settings = await UserInfo.getInfo()

            if (!!settings?.UseEasyBudget) {
                this.setState({showOpenBudgetLink: true})
            }
        }
    }

    /* shouldComponentUpdate(nextProps: Readonly<props>, nextState: Readonly<state>, nextContext: any): boolean {
      // todo instead set this.bpId in render
      if (this.props.isActive && this.bpId !== this.props.buildPlanId) {
        this.bpId = this.props.buildPlanId
        return true
      }
      return false
    } */

    render() {
        if (this.props.isActive && this.bpId !== this.props.buildPlanId) {
            this.bpId = this.props.buildPlanId
        }
        if (!this.bpId) return <NoSelectedBP/>;
        return <>
            {this.state.showOpenBudgetLink &&
                <div style={{padding: '4px 8px'}}><ButonLink text="Open Budget"
                                                             onClick={this.OpenEasyBudget}/>
                </div>}
            <LaborHoursGuage key={this.state.remountKey + 'gauge'}
                             buildPlanId={this.bpId}/>
            <BPHistoryChart key={this.state.remountKey + 'historychart'}
                            buildPlanId={this.bpId}/>
            <BPCostTypeCharts key={this.state.remountKey + 'piecharts'}
                              buildPlanId={this.bpId}/>
        </>
    }

    Refresh = () => {
        this.setState({remountKey: GetNewRemountKey()})
    }

    OpenEasyBudget = () => {
        if (this.bpId) CardManagement.EasyBudgetCard(this.bpId, this.Refresh)
    }
}

export default LaborHours;
