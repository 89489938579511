import {Button, Toolbar} from '@progress/kendo-react-buttons'
import {simpleObject} from '../../helpers/interfaces'
import {tabId} from './interfaces'
import {GetTabId, TABS} from './helpers'
import styles from '../Tabs/tabs.module.scss'
import {ModalRef} from '../Common/Modal/Modal'
import {TextArea} from '@progress/kendo-react-inputs'
import {RunScriptAsync} from '../../helpers/runscripts'
import {gridChangeDataAction, IDashboardChildProps} from '../Dashboard/interfaces'
import BaseComponent from '../BaseComponent'
import Loader from '../Common/Loader';
import ReprocessingTCButton from "./ReprocessingTCButton";
import TabsBtns from "./TabsBtns";
import TabsToolbarRight from "./TabsToolbarRight";
import React from "react";

interface ITCTabsSettings {
    CanReprocess: boolean
    CanReturn: boolean
    CanComplete: boolean
}

interface state {
    activeTabId: tabId | undefined
    loading: boolean
    tabs: Array<tabId>
    tcMaprefreshKey: number
    tcInfo: ITCTabsSettings | null
}

class TCTabs extends BaseComponent<IDashboardChildProps, state> {
    isTKReview = this.props.parentId === 'TKAuditCover'
    initedTabs: Array<tabId> = []
    tcId?: number | null
    tabsRefs: {
        [key in tabId]?: any
    } = {}

    constructor(props: IDashboardChildProps) {
        super(props)
        this.tcId = this.props.rowData?.TCId // TODO everyWhere tcId field name shouldBe the same from server
        this.state = {
            loading: false,
            activeTabId: undefined,
            tabs: [],
            tcMaprefreshKey: +new Date(),
            tcInfo: null,
        }
    }

    async componentDidMount() {
        if (!this.isTKReview) await this.LoadTCInfo()
        this.LoadAvailableTabs()
    }

    componentDidUpdate(prevProps: IDashboardChildProps) {
        if (this.props.rowData?.TCId && prevProps.rowData?.TCId !== this.props.rowData?.TCId) {
            this.tcId = this.props.rowData?.TCId
            if (!this.isTKReview) this.LoadTCInfo()
        }
    }

    render() {
        const {loading, tabs, activeTabId, tcInfo} = this.state
        if (loading) return <Loader/>
        if (tabs.length === 0 || (!this.isTKReview && !tcInfo)) return null
        const {TCId} = this.props.rowData || {}
        return (
            <>
                <Toolbar className={styles.Toolbar} style={!TCId ? {display: 'none'} : undefined}>
                    <TabsBtns
                        tabs={this.state.tabs}
                        activeTabId={activeTabId}
                        onChange={this.OnChange}
                    />
                    <TabsToolbarRight
                        activeTabId={activeTabId}
                        tabsRefs={this.tabsRefs}
                        exportToExcel={this.ExportToExcel}
                        refresh={this.RefreshActiveTab}
                        unSelectRow={this.props.unSelectRow}
                    >
                        {this.renderActionButtons()}
                    </TabsToolbarRight>
                </Toolbar>
                {tabs.map((tab) => {
                    let tabId = GetTabId(tab)
                    let isActiveTabId = tabId === activeTabId
                    let isInit = this.initedTabs.indexOf(tabId) > -1
                    let tabSetting = TABS[tabId]
                    let TabComponent = tabSetting.component
                    if (!isActiveTabId && !isInit) return null
                    let key = tabId
                    let props: simpleObject = {
                        tcId: TCId,
                        isActive: isActiveTabId,
                        doNotSaveFilters: true
                    }
                    if (this.props.parentId) props.pageId = this.props.parentId
                    if (tabSetting.hasSetDefaultFilters) {
                        props.onChangeGridData = this.onChangeChildGridData
                    }
                    if (tabId === 'TCMap') {
                        props.adjustmentsPanelOpened = false
                    }

                    return (
                        <div
                            key={key}
                            className={`${styles.ContentWrap} ${!isActiveTabId ? styles.HiddenTab : ''}`}
                        >
                            <div className={styles.Content}>
                                <TabComponent
                                    {...props}
                                    refreshTCMapKey={tabId === 'TCMap' ? this.state.tcMaprefreshKey : undefined}
                                    ref={tabId !== 'TCMap' ? (ref: any) => {
                                        if (!this.tabsRefs[tabId] || this.tabsRefs[tabId] !== ref) {
                                            this.tabsRefs[tabId] = ref
                                        }
                                    } : undefined}
                                />
                            </div>
                        </div>
                    )
                })}
            </>
        )
    }

    renderActionButtons = () => {
        if (this.isTKReview) return null

        const {activeTabId, tcInfo} = this.state
        if (activeTabId !== 'TC') return null
        const {TCId} = this.props.rowData || {}

        if (!tcInfo || !TCId) return null
        const {CanComplete, CanReturn, CanReprocess} = tcInfo

        return <>
            {CanReturn &&
                <Button
                    onClick={this.RevertTCToPending}>
                    Return to Pending
                </Button>}
            {CanComplete &&
                <Button onClick={this.CompleteTc}>Complete Time Card</Button>}
            {CanReprocess &&
                <ReprocessingTCButton
                    tcId={TCId}
                    refreshActiveTab={this.RefreshActiveTab}
                />}
        </>
    }

    OnChange = (e: any) => {
        let tabId: tabId = e.currentTarget.dataset.id
        if (this.state.activeTabId === tabId) return
        if (this.initedTabs.indexOf(tabId) === -1) this.initedTabs.push(tabId)
        this.SetActiveTabAndProps(tabId)
    }

    RefreshActiveTab = () => {
        let active = this.state.activeTabId
        if (active === 'TCMap') {
            this.setState({tcMaprefreshKey: +new Date()})
            return
        }
        let refreshFunc = active ? this.tabsRefs[active]?.Refresh : null
        if (refreshFunc) refreshFunc()
    }

    ExportToExcel = () => {
        let active = this.state.activeTabId
        let exportFunc = active ? this.tabsRefs[active]?.gridRef?.current?.ExportToExcel : null
        if (exportFunc) exportFunc()
    }

    SetActiveTabAndProps = (activeTabId: tabId) => {
        this.setState({activeTabId})
    }

    RevertTCToPending = () => {
        let commentRef: any;
        ModalRef.showDialog({
            title: 'Return to Pending',
            buttons: [
                {
                    text: 'Cancel',
                    action: () => {
                        ModalRef.hideDialog();
                    }
                },
                {
                    text: 'Ok',
                    color: 'primary',
                    action: () => {
                        let comment = commentRef.element.current.value;
                        ModalRef.hideDialog();
                        this.TcAction(comment, 'Reject', 'Return to Pending')
                    }
                },
            ],
            children: <TextArea
                ref={(ref) => {
                    commentRef = ref;
                    if (ref && ref.focus) {
                        ref?.focus()
                    }
                }}
                rows={5}
                style={{width: '100%'}}
            />
        });
    }

    CompleteTc = () => {
        let commentRef: any;
        ModalRef.showDialog({
            title: 'Complete Time Card',
            buttons: [
                {
                    text: 'Cancel',
                    action: () => {
                        ModalRef.hideDialog();
                    }
                },
                {
                    text: 'Ok',
                    color: 'primary',
                    action: () => {
                        let comment = commentRef.element.current.value;
                        ModalRef.hideDialog();
                        this.TcAction(comment, 'Complete', 'Complete Time Card')
                    }
                },
            ],
            children: <TextArea
                ref={(ref) => {
                    commentRef = ref;
                    if (ref && ref.focus) {
                        ref?.focus()
                    }
                }}
                rows={5}
                style={{width: '100%'}}
            />
        });
    }

    TcAction = async (comment: string, ActionName: 'Complete' | 'Reject', message?: string) => {
        try {
            ModalRef.startProcessing(message)
            let params = {
                TimeCardIDs: this.props.rowData?.TCId + '',
                Comment: comment || ActionName,
                ActionName,
            }
            const response = await RunScriptAsync('TK_COVER_PerformAction', params)
            if (this.props.props?.refreshParent) this.props.props.refreshParent()
            await this.LoadTCInfo()
            this.RefreshActiveTab()
            if (response !== null) {
                ModalRef.showDialog({
                    width: 400,
                    title: 'Error',
                    text: response
                });
            }
        } finally {
            ModalRef.stopProcessing()
        }
    }

    LoadTCInfo = async () => {
        try {
            const {TCId} = this.props.rowData || {}
            if (!TCId) return
            // this.setState({loading: true})
            let result = await this.GetSQLData({
                spName: 'GetInfo_TKTimeCards',
                params: {tcId: TCId}
            })
            this.setState({tcInfo: result[0][0]})
        } finally {
            // this.setState({loading: false})
        }
    }

    LoadAvailableTabs = async () => {
        try {
            this.setState({loading: true})
            let result = await this.GetSQLData({spName: 'TC_AvailableTabs'})
            let keys: { [key in tabId]: boolean } = result[0][0] || {}
            let tabs: Array<tabId> = []
            // @ts-ignore
            let keysArray: Array<tabId> = Object.keys(keys)
            keysArray.forEach((tabId: tabId) => {
                if (tabId === 'TCMap' && this.isTKReview) return
                let isAvailable = keys[tabId]
                if (isAvailable) {
                    tabs.push(tabId)
                }
            })
            let activeTabId = GetTabId(tabs[0])
            this.initedTabs.push(activeTabId)
            this.setState({tabs, activeTabId})
        } finally {
            this.setState({loading: false})
        }
    }

    onChangeChildGridData = (data: Array<simpleObject>, action: gridChangeDataAction) => {
        if (action === 'filterChanged' || action === 'forceRestoreSettings' || action === 'dataLoaded') this.forceUpdate()
    }
}

export default TCTabs
