import React, {useRef, useState} from 'react';
import {Button} from "@progress/kendo-react-buttons";


import {addSearchLayer, MARKERS_COLORS} from "./helpers";

import styles from "./map.module.scss";

type TProps = {
    map: any
    className: string
}

const SEARCH_LOCATION_SETTINGS = [{
    type: "hotels",
    markerOptions: {
        icon: "via",
        iconOptions: {
            primaryColor: MARKERS_COLORS.PINK,
            secondaryColor: MARKERS_COLORS.PINK,
            size: "md",
        }
    },
}, {
    type: "gas",
    markerOptions: {
        icon: "via",
        iconOptions:  {
            primaryColor: MARKERS_COLORS.ORANGE,
            secondaryColor: MARKERS_COLORS.ORANGE,
            size: "md",
        }
    },
},
    {
        type: "food",
        markerOptions: {
            icon: "via",
            iconOptions:  {
                primaryColor: MARKERS_COLORS.AQUA,
                secondaryColor: MARKERS_COLORS.AQUA,
                size: "md",
            }
        },
    },
    {
        type: "shopping",
        markerOptions: {
            icon: "via",
            iconOptions:  {
                primaryColor: MARKERS_COLORS.FX,
                secondaryColor: MARKERS_COLORS.FX,
                size: "md",
            }
        },
    },
    {
        type: "coffee shops",
        markerOptions: {
            icon: "via",
            iconOptions:  {
                primaryColor: MARKERS_COLORS.GRAY,
                secondaryColor: MARKERS_COLORS.GRAY,
                size: "md",
            }
        },
    },
    {
        type: "grocery stores",
        markerOptions: {
            icon: "via",
            iconOptions:  {
                primaryColor: MARKERS_COLORS.MAGENTA,
                secondaryColor: MARKERS_COLORS.MAGENTA,
                size: "md",
            }
        },
    }
]

type ITEMS_SETTINGS  = typeof SEARCH_LOCATION_SETTINGS

function SearchNodesLocation(props: TProps) {
    const {map, className} = props

    const [isVisible, setVisible] = useState(false)
    const [isLoading, setLoading] = useState (false)

    const layersByCategories = useRef<unknown[]>([])

    const handleClickLocation = (settings: ITEMS_SETTINGS) => async () => {
           try {
             setLoading(true)

             const layers = await Promise.all(settings.map(async(setting) => addSearchLayer(map, setting.type, setting.markerOptions)))

             if(layersByCategories.current.length > 0) {
              layersByCategories.current.forEach((layer) => map.removeLayer(layer))
             }

             layersByCategories.current = layers
 
             setVisible(true)
             setLoading(false)
           } catch (error) {
             setLoading(false)
           }
    }

    const handleClickHiddenCategory = () => {
        layersByCategories.current.forEach((layer) => map.removeLayer(layer))

        layersByCategories.current = []
        setVisible(false)
        setLoading(false)
    }

    return (
        <div className={`${styles.Controls} ${className}`}>
            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                <div className={`${styles.GroupBtns}`}>
                    {!isVisible ?
                        <Button
                            iconClass="mdi mdi-office-building"
                            onClick={handleClickLocation(SEARCH_LOCATION_SETTINGS)}
                            disabled={isLoading}
                        /> :
                        <>
                          <Button
                            className={ `${isLoading ? styles.InProcess : ''} ${styles.NotActualBtn}`}
                            iconClass="mdi mdi-refresh"
                            onClick={handleClickLocation(SEARCH_LOCATION_SETTINGS)}
                            disabled={isLoading}
                           />
                          <Button
                            iconClass="mdi mdi-eye-off-outline"
                            onClick={handleClickHiddenCategory}
                         />
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default SearchNodesLocation;
