import {simpleObject} from './interfaces';
import {DataServerHost, isConterra} from './settings';

type state = 'pending' | 'rejected' | 'resolved';

class SignalR {
    listeners: simpleObject;
    initialization: any
    initializationState: state
    id: any
    connection: any
    initResolve: any;
    initReject: any;

    constructor() {
        this.listeners = {};
        this.initializationState = 'pending';
        this.initialization = new Promise((resolve, reject) => {
            this.initResolve = resolve;
            this.initReject = reject;
        }).then(
            () => {
                this.initializationState = 'resolved'
            },
            () => {
                this.initializationState = 'rejected'
            }
        );

        if (!isConterra) {
            // @ts-ignore
            this.connection = $.connection('https://' + DataServerHost + '/ScriptResults');
            this.connection.received(this.onResult.bind(this));
            this.connection.start(isConterra && {jsonp: true}).done((result: any) => {
                this.id = result.id;
                this.initResolve(result);
            }).fail((result: any) => {
                this.initReject(result);
            });
        } else {
            this.initReject();
        }
    }

    addListener(callId: number, resolve: any, reject: any) {
        this.listeners[callId] = {resolve: resolve, reject: reject};
    }

    removeListener(callId: number) {
        if (this.listeners[callId]) delete this.listeners[callId];
    }

    onResult(data: {
        CallId: number,
        Success?: boolean,
        Result?: any
    }) {
        let listener = this.listeners[data.CallId];
        if (listener) {
            if (data.Success) listener.resolve && listener.resolve(data.Result);
            else listener.reject && listener.reject();
            delete this.listeners[data.CallId];
        }
    };
}

export default new SignalR();
