import React, {Component} from 'react';
import {simpleObject} from '../../helpers/interfaces';
import DashboardGrid from '../Dashboard/Grid'
import {IColumnSetting} from '../Dashboard/interfaces';

interface props {
    isActive: boolean
    urlParams?: simpleObject
    periodId?: number // if PP tab

    onChangeGridData?(): void
}

interface state {
}

const IsWarningRow = (row: simpleObject) => row.LastSynced !== null && row.CostRatesSource !== 'External Rate' && row.CostRatesSource !== 'Reconciled Rate'

const COLUMNS: Array<IColumnSetting> = [
    {
        field: 'PeriodName',
        title: 'Payroll Period',
        type: 'string',
        minWidth: 200,
        format: {
            type: 'link',
            fieldId: 'PeriodId',
            refName: 'TKPayrollPeriods'
        }
    },
    {
        field: 'EmployeeName',
        title: 'Employee',
        type: 'string',
        gridWidth: 200,
        format: {
            type: 'link',
            fieldId: 'EmployeeId',
            refName: 'Employees'
        },
    },
    {
        field: 'PayrollItemName',
        title: 'Payroll Item',
        type: 'string',
        minWidth: 120,
        format: {
            type: 'string',
            fieldId: 'PayrollItemName'
        }
    },
    {
        field: 'Type',
        title: 'Type',
        type: 'string',
        gridWidth: 70,
        format: {
            type: 'string',
            fieldId: 'Type'
        }
    },
    {
        field: 'Hours',
        title: 'Hours',
        type: 'number',
        format: {
            type: 'number',
        },
        aggregate: 'sum'
    },
    {
        field: 'QTY',
        title: 'QTY',
        type: 'number',
        format: {
            type: 'number',
        },
        aggregate: 'sum'
    },
    {
        field: 'CostRates',
        title: 'Cost Rates',
        type: 'number',
        format: {
            type: 'number',
        }
    },
    {
        field: 'Total',
        title: 'Total',
        type: 'number',
        gridWidth: 90,
        format: {
            type: 'number',
        },
        aggregate: 'sum'
    },
    {
        field: 'CostRatesSource',
        title: 'Cost Rates Source',
        type: 'string',
        minWidth: 180,
        format: {
            type: 'string',
            fieldId: 'CostRatesSource'
        }
    },
    {
        field: 'PayrollCheckName',
        title: 'Payroll Check Name',
        type: 'string',
        gridWidth: 200,
        format: {
            type: 'string',
            fieldId: 'PayrollCheckName'
        }
    },
    {
        field: 'LastSynced',
        title: 'Last Synced',
        type: 'date',
        format: {
            type: 'datetime'
        }
    },
    {
        field: 'HasWarningsStr',
        title: 'Warn.',
        type: 'string',
        gridWidth: 100,
        filterable: false,
        format: {
            type: 'icon',
            customFormat: true,
            getCustomFormat: (dataItem: simpleObject) => {
                if (!dataItem.HasWarnings) return null
                return <div style={{textAlign: 'center'}}>
                    <span className="fx-warning-color mdi mdi-alert-outline"></span>
                </div>
            },
            fieldId: 'HasWarnings'
        }
    },
]

class LaborCosts extends Component<props, state> {
    gridRef: any = React.createRef()
    periodId = this.props.periodId

    componentDidMount() {
        let isExternalBrowser = !!this.props.urlParams
        if (!isExternalBrowser) {
            COLUMNS[0].hiddenColumn = true
        }
    }

    render() {
        let urlParams = this.props.urlParams
        let isExternalBrowser = !!urlParams
        let propsPeriodId = this.props.periodId
        if (!isExternalBrowser && this.props.isActive && propsPeriodId && this.periodId !== propsPeriodId) {
            this.periodId = this.props.periodId
        }
        let params = isExternalBrowser ? {
            periodId: urlParams?.payrollPeriodId || null,
            bpId: urlParams?.buildPlanId || null,
            checkId: urlParams?.checkId || null,
            tcId: urlParams?.tcId || null
        } : {
            periodId: this.periodId || null,
        }

        return (
            <DashboardGrid
                key={this.periodId}
                isActive={this.props.isActive}
                isParentPage={isExternalBrowser}
                ref={this.gridRef}
                columnsSettings={COLUMNS}
                getSqlParams={{spName: 'DB_LaborCosts', params}}
                excelFileName="Labor Costs.xlsx"
                getRowKey={(dataItem: simpleObject) => dataItem.RowNum}
                columnMenu={{
                    filters: true,
                    columns: false
                }}
                prepareData={this.PrepareData}
                initRefresh={this.InitRefresh}
                onChangeGridData={this.props.onChangeGridData}
                defaultSort={[{
                    dir: 'desc',
                    field: 'HasWarningsStr'
                },
                    {
                        dir: 'asc',
                        field: 'EmployeeName'
                    }]}
            />
        )
    }

    PrepareData = (result: [Array<simpleObject>]) => {
        let data = result[0]
        for (let row of data) {
            row.HasWarnings = IsWarningRow(row)
            row.HasWarningsStr = row.HasWarnings ? 'Yes' : 'No'
        }
        return data
    }

    Refresh = () => {
    }

    InitRefresh = (refresh: any) => {
        this.Refresh = refresh
    }
}

export default LaborCosts
