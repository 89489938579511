import {IComboboxItem} from '../../helpers/interfaces'

export type objectType = 'Site' | 'Location';

export interface IEditableSiteInfo {
    IsCustomName: boolean
    SiteId: string
    SiteName: string
    Market: IComboboxItem | null
    Region: IComboboxItem | null
    Carrier: IComboboxItem | null
    Address: IAddressInfo | null
    DirectionsFromLocalOffice: string
    SpecialAccessInstruction: string
    Notes: string
}

export interface IAddressInfo {
    County?: string
    City?: string
    ZIP?: string
    LineOne?: string
    LineTwo?: string
    Country?: string
    State?: string
    Lat: number,
    Lng: number,
    LocationAbbr: string | null
    LocationColor: string | null
    Radius?: number
    Boundaries?: string | null
    AddressId: number
    AddressString: string
    IsMainObject: boolean
    ObjectId: number
    ObjectName: string
    ObjectType: "Location" | 'Site'
}

export enum EObjectType {
    Site = "Site",
    Location = "Location"
}

export enum EFigureType {
    circle,
    polygon,
}

export interface ICoordinates {
    Lat: number,
    Lng: number
}
