import {Button, Toolbar, ToolbarSpacer} from "@progress/kendo-react-buttons";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Checkbox} from "@progress/kendo-react-inputs";
import {Grid, GridCellProps, GridColumn as Column,} from "@progress/kendo-react-grid";
import {ExcelExport, ExcelExportColumn, ExcelExportColumnGroup,} from "@progress/kendo-react-excel-export";
import {filterBy, orderBy} from "@progress/kendo-data-query";
import moment from "moment";
import BaseComponent from "../../Components/BaseComponent";
import {IComboboxItem, ISortGridItem, simpleObject,} from "../../helpers/interfaces";
import {
    CUSTOM_PERIOD_ID,
    FILTER_OPERATORS,
    GetDefaultDateFrom,
    GetDefaultDateTo,
    GetDefaultGridFilter,
    GetGridHeight,
    GetGridPageSize,
    GetNewRemountKey,
    GetPeriodDates,
    getPeriods,
    GridRowHeight,
    IsComplexGridFilter,
} from "../../Components/Dashboard/helpers";
import {
    IColumnValue,
    IFilterSetting,
    IGridFilter,
    IGridFilterItem,
    IPeriod,
} from "../../Components/Dashboard/interfaces";
import GridRow from "../../Components/Dashboard/GridRow";
import DateFilter from "../../Components/Dashboard/DateFilter";
import GridFilterCell from "../../Components/Dashboard/GridFilterCell";
import {formatFinancial, PLFormatPercentage, TYPE_TO_EXCEL_FORMAT,} from "../../helpers/helpers";
import OpenCardLink from "../../Components/Common/Buttons/OpenCardLink";
import Loader from "../../Components/Common/Loader";
import {
    dimension,
    IColumn,
    IGroupedFDAmounts,
    IGroupedFDDataItem,
    IPLParams,
    IStatement,
    IStatementColumn,
    periodTypeId,
} from "./interfaces";
import CustomColumnMenu from "./ColumnMenu";
import {IS_IE} from "../../helpers/settings";
import styles from "./pl.module.scss";
import gridStyles from "../../Components/Dashboard/dashboard.module.scss";
import dashboardStyles from "../../Components/Dashboard/dashboard.module.scss";
import commonStyles from "../../assets/styles/common.module.scss";
import BPTabs from "../../Components/Tabs/BPTabs";
import {ITabSetting} from "../../Components/Tabs/interfaces";
import {
    CalculateGroupedData,
    GetGroupedData,
    GetStatementColumns,
    groupFDAmounts,
    PrepareStatement,
    STATEMENT_COLUMNS,
} from "./helpers";

type columnValuesFieldId =
    | "RegionID"
    | "ProfitCenterID"
    | "ProjectID"
    | "CustomerID"
    | "BPID"
    | "SiteID"
    | "ClassID";

const DATE_FORMAT = "YYYY-MM-DD";
const STORAGE_FIELDS = {
    periodType: "PLDS-periodType",
    dateFrom: "PLDS-date-from",
    dateTo: "PLDS-date-to",
    statement: "PLDS-statement",
    periodId: "PLDS-period-type",
    onlyWithValues: "PLDS-only-with-values",
    gridFilter: "PLDS-gridFilter",
    confirmedOnly: "PLDS-confirmedOnly",
};
const PERIOD_TYPES: Array<IComboboxItem> = [
    {Name: "In the Period for all BPs", Id: 0},
    {Name: "In the Period Only for Closed BPs", Id: 1},
    {Name: "For BPs Closed in the Period", Id: 2},
];
const PERIOS_TYPES_TITLES = {
    0: "All Transactions w/in Date Parameters (Active and Closed)",
    1: "Transactions w/in Date Parameters for Closed BPs Only",
    2: "All Transactions for BPs Closed w/in Date Parameters",
};
const PERIODS = getPeriods();
const DEFAULT_PERIOD_ID = "curr-year-to-date";
const PERIOD_TYPE_DEFAULT = PERIOD_TYPES[0];
const ONLY_WITH_VALUES_DEFAULT = true;
const CONFIRMED_ONLY_DEFAULT = false;

interface props {
    isActive: boolean;
}

interface state {
    loading: boolean;
    statements: Array<IComboboxItem>;
    gridColumns: Array<IStatementColumn | IColumn>;
    sort: Array<ISortGridItem>;
    gridData: Array<simpleObject>;
    selectedRow: simpleObject | null;
    gridFilter: any;
    reMountKey: number;
    skip: number;
    tabs: Array<ITabSetting>;
}

class PLDashboard extends BaseComponent<props, state> {
    tabsSettings: {
        Detailing: boolean
        IsDisablePassThru: boolean
        Statement: boolean
    } | null = null
    serverSettings!: IPLParams;
    statements: Array<IStatement> = [];
    statement: IComboboxItem | null = null;
    dimensions: Array<dimension> | null = null;
    groupedFDAmounts: IGroupedFDAmounts = {};
    groupedFDData: Array<IGroupedFDDataItem> = [];
    gridData: Array<simpleObject> = [];
    filteredGridData: Array<simpleObject> = [];
    excelGridRef: any;
    gridRef: any;
    isUserSort: boolean = false;
    dateFrom!: Date;
    dateTo!: Date;
    period!: IPeriod;
    onlyWithValues!: boolean;
    preventSkip: number | null = null;
    preventScrolltop: number | null = null;
    columnValues: { [key in columnValuesFieldId]?: Array<IColumnValue> } = {};
    columnValuesFilter: { [key: string]: Array<IGridFilterItem> } = {};
    collapsedColumns: { [key: string]: boolean } = {};
    markedRowKey: string | number = "";

    constructor(props: props) {
        super(props);
        this.RestoreSettings(false);
        this.state = {
            tabs: [],
            statements: [],
            gridData: [],
            loading: false,
            gridColumns: [],
            sort: [],
            selectedRow: null,
            gridFilter: JSON.parse(localStorage.getItem(STORAGE_FIELDS.gridFilter)!),
            reMountKey: GetNewRemountKey(),
            skip: 0,
        };
    }

    componentDidMount() {
        this.LoadConfig();
    }

    initExportRef = (ref: any) => {
        if (!this.excelGridRef) this.excelGridRef = ref;
    };

    initGridRef = (ref: any) => {
        if (!this.gridRef) this.gridRef = ref;
    };

    render() {
        let pageSize = this.state.selectedRow ? 1 : GetGridPageSize(this.gridRef);
        return (
            <div className={styles.Container}>
                {this.state.loading && <Loader/>}
                <Toolbar>
                    <div className={gridStyles.ToolbarContent}>
                        <div className={gridStyles.ToolbarRow}>
                            <DropDownList
                                key={"period" + this.state.reMountKey}
                                data={PERIODS}
                                textField="Name"
                                dataItemKey="Id"
                                defaultValue={this.period}
                                onChange={this.OnChangePeriod}
                                style={{width: "180px"}}
                            ></DropDownList>
                            <DateFilter
                                key={"from" + this.state.reMountKey}
                                defaultValue={this.dateFrom}
                                filter={{
                                    id: "dateFrom",
                                    type: "date",
                                }}
                                onChange={this.OnDateChange}
                            />
                            <DateFilter
                                key={"to" + this.state.reMountKey}
                                defaultValue={this.dateTo}
                                filter={{
                                    id: "dateTo",
                                    type: "date",
                                }}
                                onChange={this.OnDateChange}
                            />
                            <span style={{width: 78}}>Transactions:</span>
                            <DropDownList
                                key={"periodTypes" + this.state.reMountKey}
                                data={PERIOD_TYPES}
                                textField="Name"
                                dataItemKey="Id"
                                defaultValue={PERIOD_TYPES[this.serverSettings.periodType]}
                                onChange={this.OnChangePeriodType}
                                style={{width: "220px"}}
                                popupSettings={{height: "500px"}}
                                itemRender={(el, props) => {
                                    let id: periodTypeId = props.dataItem.Id;
                                    return (
                                        <li
                                            id={props.id}
                                            role="option"
                                            aria-selected={props.selected}
                                            className={el.props.className}
                                            // @ts-ignore
                                            onClick={el.props.onClick}
                                            title={PERIOS_TYPES_TITLES[id]}
                                        >
                                            {props.dataItem.Name}
                                        </li>
                                    );
                                }}
                            ></DropDownList>
                            <ToolbarSpacer/>
                            <div className={dashboardStyles.RightBtns}>
                                <Button
                                    onClick={this.SetDefaultSettings}
                                    title="Set Default Filters"
                                    icon="filter-clear"
                                    className={
                                        this.state.loading || this.IsFiltersInDefault()
                                            ? ""
                                            : dashboardStyles.BlueResetBtn
                                    }
                                />
                                <Button
                                    icon="file-excel"
                                    title="Export to Excel"
                                    onClick={this.ExportToExcel}
                                ></Button>
                                <Button icon="refresh" onClick={this.Refresh}></Button>
                            </div>
                        </div>
                        <div className={gridStyles.ToolbarRow}>
                            <DropDownList
                                key={"statement" + this.state.reMountKey}
                                data={this.state.statements}
                                textField="Name"
                                dataItemKey="Id"
                                defaultValue={this.statement}
                                onChange={this.OnChangeStatement}
                                style={{width: "180px"}}
                            ></DropDownList>

                            <Checkbox
                                id="onlywithValues"
                                key={"onlyValues" + this.state.reMountKey}
                                label={"Show only with Values"}
                                defaultChecked={this.onlyWithValues}
                                onChange={this.OnChangeOnlyWithValues}
                            />
                            <Checkbox
                                id="confirmedOnly"
                                key={"confirmedOnly" + this.state.reMountKey}
                                label={"Confirmed Only"}
                                defaultChecked={this.serverSettings.confirmedOnly}
                                onChange={this.OnChangeConfirmedOnly}
                            />
                        </div>
                    </div>
                </Toolbar>
                {!!this.state.gridColumns.length && (
                    <div className={styles.GridContainer}>
                        <ExcelExport
                            data={this.state.gridData}
                            ref={this.initExportRef}
                            fileName="PL.xlsx"
                        >
                            <ExcelExportColumn
                                key="num"
                                field="ProductID"
                                width={45}
                                title="#"
                                locked={true}
                                headerCellOptions={{wrap: true}}
                            />
                            {this.state.gridColumns.map((column) => {
                                if (this.IsStatementColumn(column)) {
                                    if (column.visible) {
                                        return (
                                            <ExcelExportColumn
                                                key={column.field}
                                                field={column.field}
                                                width={column.width || 120}
                                                title={column.title}
                                                locked={true}
                                                headerCellOptions={{wrap: true}}
                                            />
                                        );
                                    } else {
                                        return null;
                                    }
                                }
                                if (column.subColumns) {
                                    return (
                                        <ExcelExportColumnGroup
                                            key={column.field}
                                            title={column.title}
                                            headerCellOptions={{wrap: true}}
                                        >
                                            {column.subColumns.map((subColumn: IColumn) => (
                                                <ExcelExportColumn
                                                    key={subColumn.field}
                                                    field={subColumn.field}
                                                    width={150}
                                                    title={subColumn.title}
                                                    locked={false}
                                                    headerCellOptions={{wrap: true}}
                                                    cellOptions={{
                                                        format:
                                                            TYPE_TO_EXCEL_FORMAT[
                                                                subColumn.type === "Percent"
                                                                    ? "percent"
                                                                    : "currency"
                                                                ],
                                                        textAlign:
                                                            subColumn.type === "Percent" ? "center" : "right",
                                                    }}
                                                />
                                            ))}
                                        </ExcelExportColumnGroup>
                                    );
                                }
                                return (
                                    <ExcelExportColumn
                                        key={column.field}
                                        field={column.field}
                                        title={column.title}
                                        width={120}
                                        headerCellOptions={{wrap: true}}
                                        cellOptions={{
                                            format:
                                                TYPE_TO_EXCEL_FORMAT[
                                                    column.type === "Percent" ? "percent" : "currency"
                                                    ],
                                            textAlign: column.type === "Percent" ? "center" : "right",
                                        }}
                                    />
                                );
                            })}
                        </ExcelExport>
                        <Grid
                            data={
                                this.state.selectedRow
                                    ? [{...this.state.selectedRow, marked: true}]
                                    : this.state.gridData.slice(
                                        this.state.skip,
                                        this.state.skip + pageSize + 2
                                    )
                            }
                            rowHeight={GridRowHeight}
                            pageSize={pageSize}
                            skip={this.state.skip}
                            total={this.state.selectedRow ? 1 : this.state.gridData.length}
                            scrollable={"virtual"}
                            onPageChange={this.OnChangePage}
                            style={{
                                height: GetGridHeight(this.state.selectedRow, this.gridRef),
                            }}
                            className={`${styles.PLGrid} ${
                                this.state.selectedRow && styles.PLGridCollapse
                            }`}
                            resizable={true}
                            selectedField="marked"
                            onRowClick={this.OnMarkRow}
                            onRowDoubleClick={this.OnSelectRow}
                            sortable={{mode: "multiple"}}
                            sort={this.state.sort}
                            onSortChange={this.OnSortGrid}
                            ref={this.initGridRef}
                            filterable={true}
                            filter={this.state.gridFilter}
                            filterOperators={FILTER_OPERATORS}
                            rowRender={this.renderRow}
                        >
                            <Column
                                key="num"
                                width={45}
                                title="#"
                                locked={true}
                                sortable={true}
                                columnMenu={this.renderColumnMenu}
                                field="RowNum"
                                filterable={false}
                                footerCell={(props) => (
                                    <td
                                        colSpan={props.colSpan}
                                        // @ts-ignore
                                        className={props.className}
                                        style={props.style}
                                    >
                                        {this.state.gridData.length}
                                    </td>
                                )}
                                headerClassName="RowNum"
                            />
                            {this.state.gridColumns.map((column) => {
                                if (this.IsStatementColumn(column)) {
                                    if (column.visible) {
                                        return (
                                            <Column
                                                key={column.field}
                                                field={column.field}
                                                width={column.width || 120}
                                                title={column.title}
                                                locked={true}
                                                columnMenu={this.renderColumnMenu}
                                                resizable={true}
                                                format={JSON.stringify(column.linkFormat)}
                                                cell={this.renderStatementCell}
                                                filterCell={this.renderFilterCell}
                                                headerClassName={`${
                                                    column.field
                                                } ${this.GetColumnHeaderClass(column.field)}`}
                                            ></Column>
                                        );
                                    } else {
                                        return null;
                                    }
                                }
                                if (column.subColumns && column.subColumns.length) {
                                    let isCollapsedParentColumn =
                                        !!this.collapsedColumns[column.title || ""];
                                    let children: Array<JSX.Element> = [];

                                    for (let subColumn of column.subColumns) {
                                        if (
                                            !isCollapsedParentColumn ||
                                            subColumn.title === "Total"
                                        ) {
                                            children.push(
                                                <Column
                                                    key={subColumn.field}
                                                    field={subColumn.field}
                                                    width={155}
                                                    title={subColumn.title}
                                                    locked={false}
                                                    className={subColumn.type}
                                                    resizable={true}
                                                    cell={this.renderFormatCell}
                                                    footerCell={this.renderFormatCell}
                                                    filterCell={this.renderFilterCell}
                                                    columnMenu={this.renderColumnMenu}
                                                    headerClassName={`${
                                                        subColumn.field
                                                    } ${this.GetColumnHeaderClass(subColumn.field)}`}
                                                    filter="numeric"
                                                />
                                            );
                                        }
                                    }

                                    return (
                                        <Column
                                            key={column.field}
                                            title={column.title}
                                            resizable={true}
                                            filterable={false}
                                            headerClassName={commonStyles.TextCenter}
                                            headerCell={(props) => {
                                                let icon = this.collapsedColumns[props.title || ""]
                                                    ? "plus" /* 'window-restore' */
                                                    : "minus"; /* 'window-minimize' */
                                                return (
                                                    <div
                                                        style={{display: "flex", alignItems: "center"}}
                                                    >
                                                        <Button
                                                            size="small"
                                                            icon={icon}
                                                            fillMode="flat"
                                                            style={{marginRight: 8}}
                                                            data-column={props.title}
                                                            onClick={this.ToggleParentColumn}
                                                        ></Button>
                                                        {props.title}
                                                    </div>
                                                );
                                            }}
                                        >
                                            {children}
                                        </Column>
                                    );
                                }

                                return (
                                    <Column
                                        key={column.field}
                                        field={column.field}
                                        title={column.title}
                                        width={155}
                                        className={column.type}
                                        cell={this.renderFormatCell}
                                        resizable={true}
                                        footerCell={this.renderFormatCell}
                                        filterCell={this.renderFilterCell}
                                        columnMenu={this.renderColumnMenu}
                                        headerClassName={`${
                                            column.field
                                        } ${this.GetColumnHeaderClass(column.field)}`}
                                        filter="numeric"
                                    />
                                );
                            })}
                        </Grid>
                        <div className={dashboardStyles.Container}>
                            {!!this.state.tabs.length && <BPTabs
                                key={this.state.selectedRow?.Key || 0}
                                isActive={this.props.isActive}
                                props={{
                                    tabs: this.state.tabs,
                                    doNotSaveFilters: true,
                                }}
                                parentId="PLDashboard"
                                rowData={this.state.selectedRow}
                                unSelectRow={this.UnSelectRow}
                            />}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    IsFiltersInDefault = () => {
        let filterBycolumnValues = 0;
        for (let column in this.columnValuesFilter) {
            let filter = this.columnValuesFilter[column];
            filterBycolumnValues += filter.length;
        }

        let gridFilter = JSON.parse(
            localStorage.getItem(STORAGE_FIELDS.gridFilter)!
        );
        return !(filterBycolumnValues ||
            this.period.Id !== DEFAULT_PERIOD_ID ||
            this.onlyWithValues !== ONLY_WITH_VALUES_DEFAULT ||
            this.serverSettings.confirmedOnly !== CONFIRMED_ONLY_DEFAULT ||
            (this.statement &&
                !this.statement.Name.toLowerCase().includes("default")) || // разобраться почему нет statement
            gridFilter?.filters?.length);

    };

    ToggleParentColumn = (e: any) => {
        let column = e.currentTarget.getAttribute("data-column");
        this.collapsedColumns[column] = !this.collapsedColumns[column];
        this.forceUpdate();
    };

    GetColumnHeaderClass = (field: string) => {
        let statementColumn = STATEMENT_COLUMNS.find(
            (item) => item.field === field
        );
        let filterbyValue =
            statementColumn && this.columnValuesFilter[statementColumn.fieldId];
        if (filterbyValue && filterbyValue.length)
            return gridStyles.FilteredColumnTH;

        let filtered = this.state.gridFilter?.filters.find(
            (filter: IGridFilter | IGridFilterItem) => {
                if (IsComplexGridFilter(filter)) {
                    let firstFilter = filter.filters[0];
                    if (!IsComplexGridFilter(firstFilter) && firstFilter.field === field)
                        return true;
                }
                return false;
            }
        );
        return filtered ? gridStyles.FilteredColumnTH : "";
    };

    renderRow = (row: any, props: any) => {
        return <GridRow row={row} props={props}/>;
    };

    renderFormatCell = (props: simpleObject) => {
        let isFooter = !props.dataItem;
        let field = props.field || "";
        let column = this.state.gridColumns.find((col) => col.field === field);
        if (column && this.IsStatementColumn(column)) return <td></td>; // for ts
        let format = column?.type;
        let isPercentage = format === "Percent";
        let isFinancialMarkCell =
            format === "Balance" ||
            format === "FirstRow" ||
            format === "WIPAdjustedProfitMargin" ||
            format === "WipDelta";
        let hasServiceRevenueRow = this.GetCurrentStatement()?.hasServiceRevenueRow;

        let value;
        if (isFooter) {
            if (isPercentage) {
                let columnOfField = "";
                if (column && column.field === "WIPAdjustedProfitMarginPercent") {
                    columnOfField = hasServiceRevenueRow
                        ? "Service Revenue"
                        : "Total Revenue";
                } else {
                    let columnOf = this.state.gridColumns.find((col) => {
                        if (
                            !this.IsStatementColumn(col) &&
                            column &&
                            !this.IsStatementColumn(column)
                        ) {
                            return (
                                col.number !== null &&
                                col.number !== undefined &&
                                +col.number === column.percentageOf
                            );
                        }
                        return false;
                    });
                    if (
                        column &&
                        !this.IsStatementColumn(column) &&
                        columnOf &&
                        !this.IsStatementColumn(columnOf)
                    ) {
                        columnOfField = columnOf!.field;
                    }

                    /* if (columnOfField === "Service Revenue" && !hasServiceRevenueRow) {
                      columnOfField = "Total Revenue";
                    } */
                }

                let columnOfVal = this.state.gridData.reduce(
                    (sum: number, item: simpleObject) => {
                        let val = item[columnOfField];
                        if (!isNaN(val) && isFinite(val)) return sum + val;
                        return sum;
                    },
                    0
                );

                let valSum = this.state.gridData.reduce(
                    (sum: number, item: simpleObject) => {
                        if (
                            column &&
                            !this.IsStatementColumn(column) &&
                            column.valueField
                        ) {
                            let val = item[column.valueField];
                            if (!isNaN(val) && isFinite(val)) return sum + val;
                        }
                        return sum;
                    },
                    0
                );

                if (column && column.field === "WIPAdjustedProfitMarginPercent") {
                    let revenue = columnOfVal;
                    let WIPAdjustedProfitMargin = valSum;
                    let WipDelta = this.state.gridData.reduce(
                        (sum: number, item: simpleObject) => {
                            let val = item.WipDelta;
                            if (!isNaN(val) && isFinite(val)) return sum + val;
                            return sum;
                        },
                        0
                    );
                    let percentWIPAdjustedProfitMargin =
                        revenue !== null
                            ? (WIPAdjustedProfitMargin / (revenue + WipDelta)) * 100
                            : null;
                    if (
                        WIPAdjustedProfitMargin < 0 &&
                        percentWIPAdjustedProfitMargin !== null
                    )
                        percentWIPAdjustedProfitMargin *= -1;
                    value = percentWIPAdjustedProfitMargin;
                } else {
                    value = (Math.abs(valSum) / Math.abs(columnOfVal)) * 100;
                    if (valSum < 0) value *= -1;
                }
            } else {
                value = this.state.gridData.reduce(
                    (sum: number, item: simpleObject) => {
                        let val = item[field];
                        if (!isNaN(val) && isFinite(val)) return sum + val;
                        return sum;
                    },
                    0
                );
            }
        } else {
            value = props.dataItem[field];
        }

        let style: simpleObject = {
            pointerEvents: "none",
            textAlign: isPercentage ? "center" : "right",
        };
        if (isFinancialMarkCell) style.color = value >= 0 ? "green" : "red";
        if (isFinancialMarkCell || isPercentage) style.fontWeight = 700;

        return (
            <td
                colSpan={props.colSpan}
                className={`${props.className}`}
                style={props.style}
            >
                <div style={style} className={`${!isPercentage && styles.CurrencyBox}`}>
                    {!isPercentage ? <span className={styles.Currency}>$</span> : ""}
                    {isPercentage ? PLFormatPercentage(value) : formatFinancial(value)}
                </div>
            </td>
        );
    };

    renderStatementCell = (props: GridCellProps) => {
        let format = props.format && JSON.parse(props.format);
        let text = props.dataItem[props.field || ""];
        let inner = format ? (
            <OpenCardLink
                text={text}
                dataAttr={props.dataItem[format.fieldId]}
                refName={format.refName}
                refresh={this.Refresh}
            />
        ) : (
            text
        );
        return (
            <td className={props.className} style={props.style}>
                {inner}
            </td>
        );
    };

    renderLinkCell = (props: any) => {
        return (
            <td className={props.className}>
                <OpenCardLink
                    text={props.dataItem[props.dataItem.field]}
                    dataAttr={props.dataItem[props.dataItem.fieldId]}
                    onClick={props.dataItem.openLink}
                />
            </td>
        );
    };

    renderFilterCell = (props: any) => {
        return (
            <GridFilterCell
                key={props.field + this.state.reMountKey}
                props={props}
                defaultValue={props.value}
                field={props.field}
                onChange={
                    props.filterType === "text"
                        ? this.OnGridTextFilterChange
                        : this.OnGridNumericFilterChange
                }
                onOperatorChange={this.OnGridFilterOperatorChange}
                clear={this.ClearGridFilter}
            />
        );
    };

    renderColumnMenu = (props: any) => {
        let selectedStatement = this.statements.find(
            (statement) => statement.id === this.serverSettings.statementId
        );
        this.DisableColumnMenuBtn(props.column.field);
        let statementColumn = STATEMENT_COLUMNS.find(
            (item) => item.field === props.column.field
        );
        return (
            <CustomColumnMenu
                {...props}
                defaultProps={props}
                columns={
                    statementColumn
                        ? this.state.gridColumns.filter((col) =>
                            this.IsStatementColumn(col)
                        )
                        : undefined
                }
                onColumnsSubmit={statementColumn ? this.OnColumnsSubmit : undefined}
                defaultColumnsId={
                    statementColumn ? selectedStatement!.defaultDimensions : undefined
                }
                onCloseMenu={this.OnCloseColumnMenu}
                onFilterChange={this.OnComplexFilterChange}
                fieldId={statementColumn ? statementColumn.fieldId : undefined}
                getColumnValues={statementColumn ? this.GetColumnValues : undefined}
                filterByValues={this.FilterByValues}
            />
        );
    };

    GetColumnValues = (
        field: string,
        fieldId: columnValuesFieldId
    ): Array<IColumnValue> => {
        return this.columnValues[fieldId] || [];
    };

    FilterByValues = (
        filters: Array<IGridFilterItem>,
        values: Array<IColumnValue>,
        fieldId: columnValuesFieldId,
        fieldName: string
    ) => {
        this.OnSelectRow(null);
        this.columnValuesFilter[fieldId] = filters;
        this.columnValues[fieldId] = values;
        let gridFilter = this.state.gridFilter;
        if (gridFilter) {
            gridFilter.filters = gridFilter.filters.filter(
                (f: IGridFilterItem | IGridFilter) => {
                    // remove quickfilter
                    if (!IsComplexGridFilter(f)) {
                        return f.field !== fieldName;
                    } else {
                        // remove complex filter
                        let firstFilter = f.filters[0];
                        if (
                            IsComplexGridFilter(f) &&
                            !IsComplexGridFilter(firstFilter) &&
                            firstFilter.field !== fieldName
                        )
                            return true;
                    }
                    return false;
                }
            );
        }
        this.SetGridFilter(gridFilter, true);
    };

    OnChangePage = (event: any) => {
        if (!this.state.selectedRow) {
            let skip = this.preventSkip;
            if (skip !== null) {
                if (this.gridRef && this.gridRef.vs.container.scroll) {
                    this.gridRef.vs.container.scroll(0, this.preventScrolltop);
                    this.preventScrolltop = null;
                } else if (this.gridRef.vs.container) {
                    this.gridRef.vs.attendedSkip = skip;
                    this.gridRef.vs.prevScrollPos = this.preventScrolltop;
                    this.gridRef.vs.propsSkip = skip;
                    this.gridRef.vs.realSkip = skip;
                    this.preventScrolltop = null;
                }
            }
            this.setState({skip: skip !== null ? skip : event.page.skip});
            this.preventSkip = null;
        }
    };

    OnCloseColumnMenu = () => {
        this.DisableColumnMenuBtn();
    };

    DisableColumnMenuBtn = (field?: string) => {
        if (IS_IE) {
            let container = this.gridRef.element;
            let className = gridStyles.DisabledColumnMenuBtn;
            if (container && container.querySelector) {
                if (field) {
                    let btn = container.querySelector(
                        "th." + field + " .k-icon.k-i-more-vertical"
                    );
                    let parentBtn = btn ? btn.parentElement : null;
                    if (
                        parentBtn &&
                        parentBtn?.classList !== undefined &&
                        !parentBtn.classList.contains(className)
                    ) {
                        parentBtn.classList.add(className);
                    }
                } else {
                    let btn = container.querySelector("." + className);
                    if (
                        btn?.classList !== undefined &&
                        btn.classList.contains(className)
                    ) {
                        btn.classList.remove(className);
                    }
                }
            }
        }
    };

    IsStatementColumn(col: IStatementColumn | IColumn): col is IStatementColumn {
        return (col as IStatementColumn).isStatementColumn !== undefined;
    }

    LoadStatements = () => {
        return this.GetSQLData({
            method: "GET",
            path: "pnl",
            spName: "GetPnlStatements",
        });
    };

    LoadTabsSettings = () => {
        return this.GetSQLData({spName: "PL_AvailableTabs"});
    };

    LoadConfig = async () => {
        try {
            this.setState({loading: true});
            let results: Array<any> = await Promise.allSettled([
                this.LoadStatements(),
                this.LoadTabsSettings(),
            ]);
            if (results[1].status === "fulfilled") {
                this.tabsSettings = results[1].value[0]
            }
            if (results[0].status === "fulfilled") {
                this.SetStatementsConfig(results[0].value);
            }
        } finally {
            this.setState({loading: false});
        }
    };

    SetStatementsConfig = (statements: Array<any>) => {
        if (!statements) return;
        statements.forEach(PrepareStatement);
        this.statements = statements;
        let statementsList = statements.map((statement: IStatement) => ({
            Id: statement.id,
            Name: statement.title,
        }));

        let selectedStatementId = this.statement?.Id;
        if (!selectedStatementId) {
            let oldSelectedStatementId = localStorage.getItem("PLDS-statement-id");
            if (oldSelectedStatementId) {
                localStorage.removeItem("PLDS-statement-id");
                selectedStatementId = oldSelectedStatementId;
            }
        }
        let selectedStatement = statementsList.find((statement: IComboboxItem) =>
            selectedStatementId
                ? +selectedStatementId === statement.Id
                : statement.Name.toLowerCase().includes("default")
        );

        if (
            !this.statement ||
            (selectedStatement && this.statement.Id !== selectedStatement.Id)
        ) {
            this.statement = selectedStatement || null;
            if (selectedStatement)
                this.serverSettings.statementId = selectedStatement?.Id;
            this.setState({reMountKey: GetNewRemountKey()});
        }
        this.dimensions = this.GetCurrentStatement()!.defaultDimensions;
        this.setState({statements: statementsList});
        this.LoadData();
    };

    LoadData = async () => {
        this.serverSettings.dateFrom = moment(this.dateFrom).format(DATE_FORMAT);
        this.serverSettings.dateTo = moment(this.dateTo).format(DATE_FORMAT);
        this.columnValuesFilter = {};
        this.columnValues = {};

        try {
            this.setState({loading: true, selectedRow: null});
            let groupedFD = await this.GetSQLData({
                spName: "GetGroupedFD",
                params: this.serverSettings,
            });
            let amounts = groupedFD[0] || [];
            let data = groupedFD[1] || [];
            let currentStatement = this.GetCurrentStatement();
            let statementColumns = GetStatementColumns(
                this.dimensions || currentStatement!.defaultDimensions
            );
            let columns = currentStatement!.columns;
            if (!this.tabsSettings?.Statement) {
                const excludedColumns = ['Total Revenue', 'WIP', 'WIP Adjusted Profit Margin', 'WIP Adjusted Profit Margin %']
                columns = columns.filter((column) => excludedColumns.findIndex((title: string) => title === column.title) === -1)
            }
            for (let column of columns) {
                if (column.subColumns?.length) {
                    let title = column.title;
                    if (this.collapsedColumns[title] === undefined)
                        this.collapsedColumns[title] = true;
                }
            }
            let gridColumns = statementColumns.concat(columns);
            this.groupedFDAmounts = groupFDAmounts(amounts);
            this.groupedFDData = data;
            this.filteredGridData = this.PrepareGridData();
            let sort = this.isUserSort
                ? this.state.sort
                : currentStatement!.defaultSort;
            let gridData = this.GetFinalGridData(sort, this.state.gridFilter);
            this.SetcolumnValuesData(data);
            this.setState({gridColumns, gridData, sort});
        } finally {
            this.setState({loading: false});
        }
    };

    SetcolumnValuesData = (data: Array<simpleObject>) => {
        this.columnValues = {};

        const valuesFields: Array<[columnValuesFieldId, string]> = [
            ["RegionID", "RegionName"],
            ["ProfitCenterID", "ProfitCenterName"],
            ["ProjectID", "ProjectName"],
            ["CustomerID", "CustomerName"],
            ["BPID", "BPName"],
            ["SiteID", "SiteName"],
            ["ClassID", "ClassName"],
        ];

        let unicValues: {
            [key: string]: {
                // fieldId
                [key: number]: boolean;
            };
        } = {};

        data.forEach((item) => {
            for (let fields of valuesFields) {
                let FieldId = fields[0];
                if (!unicValues[FieldId]) unicValues[FieldId] = {};
                if (!this.columnValues[FieldId]) this.columnValues[FieldId] = [];
                if (!this.columnValues[FieldId]) this.columnValues[FieldId] = [];
                let Id = item[FieldId];
                if (!unicValues[FieldId][Id]) {
                    let fieldName = fields[1];
                    unicValues[FieldId][Id] = true;
                    this.columnValues[FieldId]?.push({
                        Id,
                        Name: item[fieldName],
                        Selected: true,
                        FieldId,
                    });
                }
            }
        });
    };

    RestoreSettings = (force: boolean) => {
        if (force) this.isUserSort = false;

        let state: simpleObject = {};
        if (!force) {
            let savedDateFrom = localStorage.getItem(STORAGE_FIELDS.dateFrom);
            let savedDateTo = localStorage.getItem(STORAGE_FIELDS.dateTo);
            // if old P&L localstorage
            if (savedDateFrom) {
                let format =
                    savedDateFrom?.indexOf("/") > -1 ? "MM/DD/YYYY" : DATE_FORMAT;
                state.dateFrom = moment(savedDateFrom, format);
            }
            if (savedDateTo) {
                let format = savedDateTo.indexOf("/") > -1 ? "MM/DD/YYYY" : DATE_FORMAT;
                state.dateTo = moment(savedDateTo, format);
            }
            let periodId = localStorage.getItem(STORAGE_FIELDS.periodId);
            if (periodId)
                state.period = PERIODS.find((period) => period.Id === periodId);
            state.gridFilter = localStorage.getItem(STORAGE_FIELDS.gridFilter);
            let periodType = localStorage.getItem(STORAGE_FIELDS.periodType);
            if (periodType) state.periodType = JSON.parse(periodType);
            let onlyWithValues = localStorage.getItem(STORAGE_FIELDS.onlyWithValues);
            if (onlyWithValues) state.onlyWithValues = JSON.parse(onlyWithValues);
            let confirmedOnly = localStorage.getItem(STORAGE_FIELDS.confirmedOnly);
            if (confirmedOnly) state.confirmedOnly = JSON.parse(confirmedOnly);
            let gridFilter = localStorage.getItem(STORAGE_FIELDS.gridFilter);
            if (gridFilter) state.gridFilter = JSON.parse(gridFilter);
            let statement = localStorage.getItem(STORAGE_FIELDS.statement);
            if (statement) state.statement = JSON.parse(statement);
        }

        if (force || !state.dateFrom) state.dateFrom = GetDefaultDateFrom();
        if (force || !state.dateTo) state.dateTo = GetDefaultDateTo();
        if (force || !state.period)
            state.period = PERIODS.find((period) => period.Id === DEFAULT_PERIOD_ID);
        if (force || state.periodType === undefined)
            state.periodType = PERIOD_TYPE_DEFAULT;
        if (force || state.onlyWithValues === undefined)
            state.onlyWithValues = ONLY_WITH_VALUES_DEFAULT;
        if (force || state.confirmedOnly === undefined)
            state.confirmedOnly = CONFIRMED_ONLY_DEFAULT;
        if (force || !state.gridFilter) state.gridFilter = GetDefaultGridFilter();
        if (force || !state.statement) state.statement = this.GetDefaultStatement(); // ??

        let formattedDateFrom = state.dateFrom.format(DATE_FORMAT);
        let formattedDateTo = state.dateTo.format(DATE_FORMAT);

        localStorage.setItem(STORAGE_FIELDS.dateFrom, formattedDateFrom);
        localStorage.setItem(STORAGE_FIELDS.dateTo, formattedDateTo);
        localStorage.setItem(STORAGE_FIELDS.periodId, state.period.Id);
        localStorage.setItem(
            STORAGE_FIELDS.periodType,
            JSON.stringify(state.periodType)
        );
        localStorage.setItem(
            STORAGE_FIELDS.confirmedOnly,
            state.confirmedOnly.toString()
        );
        localStorage.setItem(
            STORAGE_FIELDS.onlyWithValues,
            state.onlyWithValues.toString()
        );
        if (state.statement)
            localStorage.setItem(
                STORAGE_FIELDS.statement,
                JSON.stringify(state.statement)
            );
        localStorage.setItem(
            STORAGE_FIELDS.gridFilter,
            JSON.stringify(state.gridFilter)
        );

        this.dateFrom = state.dateFrom.toDate();
        this.dateTo = state.dateTo.toDate();
        this.period = state.period;
        this.onlyWithValues = state.onlyWithValues;
        if (state.statement) this.statement = state.statement;

        this.serverSettings = {
            dateFrom: formattedDateFrom,
            dateTo: formattedDateTo,
            periodType: state.periodType.Id,
            statementId: state.statement?.Id,
            confirmedOnly: state.confirmedOnly,
        };
        if (force) {
            this.setState({
                reMountKey: GetNewRemountKey(),
                gridFilter: state.gridFilter,
            });
            this.LoadData();
        }
    };

    GetCurrentStatement = () => {
        return this.statements.find(
            (statement: IStatement) =>
                statement.id === this.serverSettings.statementId
        );
    };

    PrepareGridData = () => {
        let currentStatement = this.GetCurrentStatement();
        let columns = currentStatement!.columns;
        let groupedData = GetGroupedData(
            this.groupedFDData,
            this.dimensions || this.GetCurrentStatement()!.defaultDimensions
        );
        this.gridData = CalculateGroupedData(
            groupedData,
            columns,
            this.groupedFDAmounts,
            !!currentStatement?.hasServiceRevenueRow
        );
        return this.GetFilteredGridData();
    };

    GetFilteredGridData = () => {
        if (localStorage.getItem(STORAGE_FIELDS.onlyWithValues) === "false")
            return this.gridData;
        let currentStatement = this.GetCurrentStatement();
        let columns = currentStatement!.columns;
        let gridData: Array<simpleObject> = this.gridData.filter((row) => {
            let isVisible = false;
            columns.forEach((column) => {
                if (column.type !== "Percent" && !isVisible) {
                    let value = row[column.field];
                    isVisible = value !== null && value !== 0;
                }
            });
            return isVisible;
        });

        return orderBy(gridData, this.state.sort);
    };

    Refresh = () => {
        this.LoadData();
    };

    OnColumnsSubmit = (columns: Array<IStatementColumn | IColumn>) => {
        let dimensions: Array<dimension> = [];
        this.state.gridColumns.forEach((column) => {
            if (this.IsStatementColumn(column)) {
                let newColumn = columns.find((col) => column.title === col.title);
                column.visible = !!(
                    newColumn &&
                    this.IsStatementColumn(newColumn) &&
                    newColumn.visible
                );
                if (column.visible) dimensions.push(column.id);
            }
        });
        // this.isUserSort = true; ??
        this.dimensions = dimensions;
        this.filteredGridData = this.PrepareGridData();
        this.SetGridFilter(undefined, true);
    };

    OnDateChange = (value: any, filter: IFilterSetting) => {
        if (filter.id === "dateFrom") this.dateFrom = value;
        if (filter.id === "dateTo") this.dateTo = value;

        localStorage.setItem(
            STORAGE_FIELDS.dateFrom,
            moment(this.dateFrom).format(DATE_FORMAT)
        );
        localStorage.setItem(
            STORAGE_FIELDS.dateTo,
            moment(this.dateTo).format(DATE_FORMAT)
        );
        if (this.period.Id !== CUSTOM_PERIOD_ID) {
            localStorage.setItem(STORAGE_FIELDS.periodId, CUSTOM_PERIOD_ID);
            this.period = PERIODS.find((period) => period.Id === CUSTOM_PERIOD_ID)!;
            this.setState({reMountKey: GetNewRemountKey()});
        }
        this.LoadData();
    };

    OnChangePeriod = (e: any) => {
        let value = e.target.value;
        let periodId = value.Id;
        if (localStorage.getItem(STORAGE_FIELDS.periodId) === periodId) return;
        let [dateFrom, dateTo] = GetPeriodDates(periodId);
        if (dateFrom && dateTo) {
            this.dateFrom = dateFrom;
            this.dateTo = dateTo;
        }
        this.period = value;
        localStorage.setItem(STORAGE_FIELDS.periodId, periodId);
        let formatDateFrom = moment(dateFrom).format(DATE_FORMAT);
        let formatDateTo = moment(dateTo).format(DATE_FORMAT);
        localStorage.setItem(STORAGE_FIELDS.dateFrom, formatDateFrom);
        localStorage.setItem(STORAGE_FIELDS.dateTo, formatDateTo);
        this.setState({reMountKey: GetNewRemountKey()});
        this.LoadData();
    };

    OnChangeStatement = (e: any) => {
        this.isUserSort = false;
        this.dimensions = null;
        let value = e.target.value;
        this.serverSettings.statementId = value.Id;
        this.statement = value;
        localStorage.setItem(STORAGE_FIELDS.statement, JSON.stringify(value));
        this.SetGridFilter(undefined);
        this.LoadData();
    };

    OnChangePeriodType = (e: any) => {
        let value = e.value;
        this.serverSettings.periodType = value.Id;
        localStorage.setItem(STORAGE_FIELDS.periodType, JSON.stringify(value));
        this.LoadData();
    };

    OnChangeOnlyWithValues = (e: any) => {
        localStorage.setItem(STORAGE_FIELDS.onlyWithValues, e.value);
        this.onlyWithValues = e.value;
        this.filteredGridData = this.GetFilteredGridData();
        let gridData = this.GetFinalGridData(
            this.state.sort,
            this.state.gridFilter
        );
        this.setState({gridData, selectedRow: null});
    };

    OnChangeConfirmedOnly = (e: any) => {
        localStorage.setItem(STORAGE_FIELDS.confirmedOnly, e.value);
        this.serverSettings.confirmedOnly = e.value;
        this.LoadData();
    };

    GetDefaultStatement = () => {
        if (!this.state || !this.statements || !this.statements.length)
            return undefined;
        return (
            this.state.statements.find((item: IComboboxItem) =>
                item.Name.toLowerCase().includes("default")
            ) || this.state.statements[0]
        );
    };

    SetDefaultSettings = () => {
        this.RestoreSettings(true);
    };

    OnMarkRow = (e: { dataItem: simpleObject }) => {
        let lastMarkedKey = this.markedRowKey;
        let rowData: simpleObject = e.dataItem;
        let key = rowData.Key;
        if (lastMarkedKey && lastMarkedKey === key) return; // or delete mark??
        // todo save marked row ???
        let gridData = this.GetFinalGridData(
            this.state.sort,
            this.state.gridFilter
        );
        this.markedRowKey = key;
        this.setState({gridData});
    };

    UnSelectRow = () => this.OnSelectRow(null)

    OnSelectRow = (e: { dataItem: simpleObject } | null) => {
        let lastSelectedKey = this.state.selectedRow && this.state.selectedRow.Key;
        let selectedRow: simpleObject | null = e?.dataItem || null;
        if (
            selectedRow === null ||
            (lastSelectedKey && lastSelectedKey === selectedRow.Key)
        ) {
            selectedRow = null;
        }
        if (selectedRow?.Key) this.markedRowKey = selectedRow.Key;
        let skip = this.state.skip;
        let scroll = this.gridRef.vs.prevScrollPos;
        let gridData = this.GetFinalGridData(
            this.state.sort,
            this.state.gridFilter
        );
        let detailsProps = {
            dataItem: selectedRow,
            statement: this.GetCurrentStatement()!,
            amounts: this.groupedFDAmounts,
            dataFrom: this.serverSettings.dateFrom,
            dataTo: this.serverSettings.dateTo,
            periodType: this.serverSettings.periodType,
        };
        let tabs: ITabSetting[] = [];
        if (selectedRow !== null) {
            if (this.tabsSettings?.Statement) {
                tabs.push({
                    id: "PL",
                    name: "Statement",
                    props: {plProps: detailsProps}
                });
            }
            if (this.tabsSettings?.Detailing) {
                tabs.push({
                    id: "PLDetails",
                    name: "Details",
                    props: {queryProps: detailsProps}
                });
            }
        }
        this.setState({selectedRow, gridData, tabs});
        if (selectedRow === null) {
            //@ts-ignore
            let el = $(this.gridRef.element).find(
                ".k-grid-content.k-virtual-content"
            );
            let scrollLeft = el.scrollLeft();
            el.scrollLeft(0).scrollLeft(100).scrollLeft(scrollLeft);
        } else {
            this.preventSkip = skip;
            this.preventScrolltop = scroll;
        }
    };

    OnSortGrid = (e: simpleObject) => {
        this.isUserSort = true;
        let gridData = this.GetFinalGridData(e.sort, this.state.gridFilter);
        this.setState({
            sort: e.sort,
            gridData: gridData,
        });
    };

    GetFinalGridData = (sort: any, filter: any) => {
        for (let row of this.filteredGridData) {
            row.marked = row.Key === this.markedRowKey;
        }
        let filterByGridFilters = filterBy(this.filteredGridData, filter);
        let filterByValues = GetDefaultGridFilter();
        for (let key in this.columnValuesFilter) {
            let filter = this.columnValuesFilter[key];
            if (filter.length)
                filterByValues.filters.push({filters: filter, logic: "or"});
        }
        let data = orderBy(filterBy(filterByGridFilters, filterByValues), sort);
        data.forEach((row: simpleObject, i: number) => {
            row.RowNum = i + 1;
        });
        return data;
    };

    GetGridFilterClearedComplexFilter = (field: string) => {
        let gridFilter = this.state.gridFilter;
        if (!gridFilter) gridFilter = GetDefaultGridFilter();
        else {
            gridFilter.filters = gridFilter.filters.filter(
                (f: IGridFilterItem | IGridFilter) => {
                    if (!IsComplexGridFilter(f)) return true;
                    else {
                        let firstFilter = f.filters[0];
                        if (
                            IsComplexGridFilter(f) &&
                            !IsComplexGridFilter(firstFilter) &&
                            firstFilter.field !== field
                        )
                            return true;
                    }
                    return false;
                }
            );
        }
        return gridFilter;
    };

    ClearValuesFilter = (fieldId: columnValuesFieldId) => {
        if (this.columnValuesFilter[fieldId]) {
            delete this.columnValuesFilter[fieldId];
            let values = this.columnValues[fieldId];
            if (values) {
                for (let val of values) {
                    val.Selected = false;
                }
            }
        }
    };

    OnGridNumericFilterChange = (value: any, filter: IGridFilterItem) => {
        let field = filter.field;
        let gridFilter = this.GetGridFilterClearedComplexFilter(field);
        if (value) {
            value = +value;
            let old;
            if (gridFilter.filters.length) {
                old = gridFilter.filters.find((filter: any) => filter.field === field);
            }
            if (old) old.value = value;
            else {
                gridFilter.filters.push({
                    field,
                    value,
                    operator: "gte",
                });
            }
        } else {
            gridFilter.filters = gridFilter.filters.filter(
                (filter: any) => filter.field !== field
            );
        }
        this.SetGridFilter(gridFilter);
    };

    OnGridTextFilterChange = (value: any, filter: IGridFilterItem) => {
        let field = filter.field;
        let statementColumn = STATEMENT_COLUMNS.find(
            (item) => item.field === field
        );
        // @ts-ignore
        let fieldId: columnValuesFieldId =
            statementColumn && statementColumn.fieldId;
        if (fieldId) this.ClearValuesFilter(fieldId);
        let gridFilter = this.GetGridFilterClearedComplexFilter(field);

        let old;
        if (gridFilter.filters.length) {
            old = gridFilter.filters.find((filter: any) => filter.field === field);
        }
        if (old) old.value = value;
        else {
            gridFilter.filters.push({
                field,
                value,
                operator: "contains",
            });
        }
        this.SetGridFilter(gridFilter);
    };

    OnComplexFilterChange = (
        gridFilter: IGridFilter,
        fieldId: string | columnValuesFieldId,
        fieldName: string
    ) => {
        // @ts-ignore
        if (fieldId as columnValuesFieldId) this.ClearValuesFilter(fieldId);
        if (gridFilter) {
            gridFilter.filters = gridFilter.filters.filter(
                (f: IGridFilterItem | IGridFilter) => {
                    if (!IsComplexGridFilter(f)) return f.field !== fieldName;
                    return true;
                }
            );
        }
        this.SetGridFilter(gridFilter, true);
    };

    OnGridFilterOperatorChange = (props: any) => {
        let field = props.item.field;
        let operator = props.item.operator;
        let gridFilter = this.GetGridFilterClearedComplexFilter(field);
        let oldFilterIndex = gridFilter.filters.findIndex(
            (filter: any) => filter.field === field
        );
        let oldFilter = oldFilterIndex !== -1 && gridFilter.filters[oldFilterIndex];
        if (oldFilter) {
            if (oldFilter.operator === operator && oldFilter.value === null) {
                gridFilter.filters.splice(oldFilterIndex, 1);
            } else oldFilter.operator = operator;
        } else {
            gridFilter.filters.push({
                field,
                value: null,
                operator,
            });
        }
        this.SetGridFilter(gridFilter);
    };

    SetGridFilter = (
        gridFilter: IGridFilter | undefined,
        unselectRow?: boolean
    ) => {
        if (gridFilter === undefined) gridFilter = GetDefaultGridFilter();
        localStorage.setItem(STORAGE_FIELDS.gridFilter, JSON.stringify(gridFilter));
        let gridData = this.GetFinalGridData(this.state.sort, gridFilter);
        this.setState((state) => ({
            gridFilter,
            gridData,
            selectedRow: unselectRow ? null : state.selectedRow,
            reMountKey: +new Date(),
        }));
    };

    ClearGridFilter = (filter: IGridFilterItem) => {
        let field = filter.field;
        let gridFilter = this.state.gridFilter;
        gridFilter.filters = gridFilter.filters.filter(
            (filter: any) => filter.field !== field
        );
        this.setState({reMountKey: GetNewRemountKey()});
        this.SetGridFilter(gridFilter);
    };

    ExportToExcel = () => {
        this.excelGridRef.save();
    };
}

export default PLDashboard;
