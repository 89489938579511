import {Popup} from "@progress/kendo-react-popup";
import {Menu, MenuItem} from '@progress/kendo-react-layout';
import {useRef, useState} from "react";
import styles from "./livemap.module.scss";
import {SHOW_LOGS_DIV_CLASS_NAME} from "./helpers";
import CardManagement from "../../Components/Cards/CardManagement";

const ListContainer = (props: { date: Date, htmlId: string, children: any }) => {
    const [position, setPosition] = useState<{ left: number; top: number } | undefined>(undefined)
    const deviceInfoRef = useRef<{ deviceId: number, date?: string } | undefined>(undefined)
    const closeContextMenu = () => {
        setPosition(undefined)
        document.removeEventListener("click", closeContextMenu);
    };

    const openContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        // @ts-ignore
        const container = e.target.classList.contains(SHOW_LOGS_DIV_CLASS_NAME) ? e.target : e.nativeEvent.target.closest("." + SHOW_LOGS_DIV_CLASS_NAME)
        if (!container) return
        const {deviceid} = container.dataset
        if (!deviceid) return

        e.nativeEvent.preventDefault();
        deviceInfoRef.current = {
            deviceId: +deviceid
        }
        const {clientX: left, clientY: top} = e
        setPosition({left, top})
        document.addEventListener("click", closeContextMenu);
    };

    const onSelect = () => {
        const {deviceId} = deviceInfoRef.current || {}
        if (!deviceId) return
        CardManagement.OpenDeviceLogsCard(deviceId, props.date)
    }

    return (<>
        <div
            id={props.htmlId}
            className={styles.DevicesList}
            onContextMenu={openContextMenu}
        >
            {props.children}
        </div>
        <Popup
            offset={position || undefined}
            show={!!position}
        >
            <Menu
                vertical={true}
                onSelect={onSelect}
                className={'k-context-menu'}
            >
                <MenuItem
                    text="Show Logs"
                ></MenuItem>
            </Menu>
        </Popup>
    </>)
}

export default ListContainer
