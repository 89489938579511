import React from 'react';
import {Loader} from '@progress/kendo-react-indicators';
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Deferred} from '../../../helpers/deffered';
import {IDialogParams} from '../../../helpers/interfaces';
import styles from './Modal.module.scss';
import commonStyles from '../../../assets/styles/common.module.scss'
import FXExpansionPanel from '../FXExpansionPanel';
import {Button} from '@progress/kendo-react-buttons';

export class ModalRef {
    static ref: any;
    static initializedRef = new Deferred();

    static setRef(ref: any) {
        this.ref = ref;
        this.initializedRef.resolve();
    }

    static async checkRefInit() {
        if (this.ref === undefined) await this.initializedRef;
    }

    static async startProcessing(text?: string, background?: string) {
        await this.checkRefInit();
        this.ref.startProcessing(text, background);
    }

    static async stopProcessing() {
        await this.checkRefInit();
        this.ref.stopProcessing();
    }

    static async showDialog(params: IDialogParams) {
        await this.checkRefInit();
        this.ref.showDialog(params);
    }

    static async hideDialog() {
        await this.checkRefInit();
        this.ref.hideDialog();
    }
}

interface state {
    processing: {
        text?: string
        background?: string
    } | null;
    dialog: IDialogParams | null;
}

export class Modal extends React.Component<{}, state> {
    constructor(props: any) {
        super(props)
        this.state = {
            processing: null,
            dialog: null
        }
    }

    componentDidMount() {
        ModalRef.setRef(this);
    }

    render() {
        let className = styles.Modal;
        if (this.state.processing || this.state.dialog) className = `${className} ${commonStyles.IEfixIframeWrap}`;
        return (
            <div className={className}>
                {this.state.processing && this.renderProcessing()}
                {this.renderDialog()}
                <iframe className={commonStyles.IEfixIframe} src="about:blank"
                        title="iefix"></iframe>
            </div>
        )
    }

    renderProcessing = () => {
        const {text, background} = this.state.processing || {}
        return (
            <div className={styles.ModalWrapper} style={{background}}>
                <Loader themeColor="dark" type='converging-spinner'/>
                {!!text && <span>{text}</span>}
            </div>
        )
    }

    renderDialog = () => {
        let dialog = this.state.dialog
        if (!dialog) return null;
        let classNameType = dialog.type ? styles[`ModalType-${dialog.type}`] : '';
        return (
            <Dialog
                title={dialog.title || ' '}
                onClose={this.hideDialog}
                minWidth={dialog.minWidth || "400px"}
                width={dialog.width}
                contentStyle={{maxWidth: '100%'}}
                className={classNameType}
            >
                {dialog.text && <div dangerouslySetInnerHTML={{__html: dialog.text}}/>}
                {dialog.children}
                {dialog.details && <FXExpansionPanel className={styles.ExpandedDialog}
                                                     title={dialog.details.title}
                                                     content={dialog.details.content}></FXExpansionPanel>}
                {dialog.buttons &&
                    <DialogActionsBar layout='end'>
                        {dialog.footerBefore}
                        {dialog.buttons.map((btn) => (
                            <Button
                                key={btn.text}
                                themeColor={btn.color}
                                onClick={btn.action}
                                disabled={btn.disabled}
                            >{btn.text}</Button>
                        ))}
                    </DialogActionsBar>
                }
            </Dialog>
        )
    }
    startProcessing = (text?: string, background?: string) => {
        this.setState({processing: {text, background}});
    }
    stopProcessing = () => {
        this.setState({processing: null});
    }
    showDialog = (dialogParams: IDialogParams) => {
        this.setState({dialog: dialogParams});
    }
    hideDialog = () => {
        let dialogParams = this.state.dialog
        if (dialogParams?.beforeClose) {
            dialogParams.beforeClose()
        }
        this.setState({dialog: null})
    }

}
