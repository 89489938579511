import React, {Component} from 'react';
import {ListOnScrollProps} from 'react-window';
import {VariableSizeTree as Tree} from 'react-vtree';
import {simpleObject} from '../../helpers/interfaces';
import {IGroupedVIMLIst} from './interfaces';
import ExpandedHeader from './ExpandedHeader';
import Invoice from './Invoice';
import CurrentListGroup from './CurrentListGroup';
import styles from './vim.module.scss';
import Arrows from './Arrows';

interface props {
  remountListKey: number
  usersIds: Array<number> // treewalker uses it
  statuses: Array<string> // treewalker uses it
  renderTree: Array<simpleObject> // treewalker uses it
  currentUserId: number | undefined
  groupedList: IGroupedVIMLIst
  selectedId: number | null

  onSelect(id: number | null, name: string | null): any
}

interface state {
}

class VIMList extends Component<props, state> {
  CurrentGroupRef: any = React.createRef()
  TreeRef: any = React.createRef()
  selectedId: number | null = this.props.selectedId;
  arrowsRef: any = React.createRef()
  prevDisabled: boolean = false
  nextDisabled: boolean = false

  constructor(props: props) {
    super(props)
    this.treeWalker = this.treeWalker.bind(this)
  }

  componentDidUpdate(prevProps: props) {
    if (prevProps.renderTree !== this.props.renderTree) {
      this.RecomputeTree()
      this.DisableArrows();
    }
  }

  treeWalker = function* (refresh: boolean) {
    const stack = [];
    for (let ch of this.props.renderTree) {
      stack.push({
        nestingLevel: 0,
        node: ch,
      });
    }

    while (stack.length !== 0) {
      // @ts-ignore
      let item = stack.pop()
      // @ts-ignore
      const {node: {children = [], id, name}, nestingLevel,} = item;
      // @ts-ignore
      const isOpened = yield refresh ? {
        defaultHeight: item.node.type ? 27 : 48,
        id,
        isLeaf: children.length === 0,
        isOpenByDefault: item.node.type ? item.node.Expanded : true,
        name,
        nestingLevel,
        rowData: item.node
      } : id;

      if (children.length !== 0 && isOpened) {
        for (let i = children.length - 1; i >= 0; i--) {
          stack.push({
            nestingLevel: nestingLevel + 1,
            node: children[i],
          });
        }
      }
    }
  }

  render() {
    return <>
      <CurrentListGroup ref={this.CurrentGroupRef}/>
      {this.selectedId &&
          <Arrows
              ref={this.arrowsRef}
              defaultPrev={this.prevDisabled}
              defaultNext={this.nextDisabled}
              onPrev={this.OnSelectPrev}
              onNext={this.OnSelectNext}
          />
      }
      <Tree
        key={'list' + this.props.remountListKey}
        ref={this.TreeRef}
        className="VIMList"
        treeWalker={this.treeWalker}
        height={1000}
        width={400}
        onScroll={this.OnScroll}
      >
        {this.renderRow}
      </Tree>
    </>
  }

  renderRow = (props: any) => {
    let rowData = props.data.rowData
    return (
      <div style={props.style}>
        {rowData.type ? this.renderExpandHeader(rowData, props.isOpen, props.toggle) : this.renderInvoice(rowData)}
      </div>
    )
  }

  renderExpandHeader = (row: simpleObject, isOpen: boolean, onExpand: any) => {
    let userId = row.UserId;
    let isUserRow = row.type === 'user'
    let userData = this.props.groupedList[userId]
    if (!userData) return null
    let lengthInvoices = isUserRow ? this.props.groupedList[userId].visibleInvoices : this.props.groupedList[userId].groupsByStatus[row.name].visibleInvoices
    let groupTitle = `${row.name} - ${lengthInvoices}`;
    let currentUserId = this.props.currentUserId
    let header: any = groupTitle
    if (isUserRow && userId === currentUserId) {
      header = <span className={styles.ListMyGroupName}>{groupTitle}</span>
    }
    return <ExpandedHeader
      expanded={isOpen}
      className="VIMList-item"
      headerDataText={isUserRow ? groupTitle : `${this.props.groupedList[userId].UserName} - ${groupTitle}`}
      headerDataId={row.id}
      header={header}
      level={isUserRow ? 1 : 2}
      onToggleExpand={onExpand}
      dataAttr={row}
      groupedList={this.props.groupedList}
    />
  }

  renderInvoice = (row: simpleObject) => {
    let selected = row.Id === this.selectedId
    return <Invoice
      key={row.Id}
      selected={selected}
      userId={row.UserId}
      // @ts-ignore
      invoice={row}
      onSelect={this.OnInvoiceClick}
    />
  }

  OnInvoiceClick = (e: any) => {
    let id = +e.currentTarget.dataset.id;
    let name = e.currentTarget.dataset.name;
    if (id) {
      this.Select(id, name);
    }
  }

  SelectById = (id: string) => {
    let invoiceData = this.TreeRef.current.list.current.props.itemData.records[id]
    let {Id, Name} = invoiceData.data.rowData
    this.Select(Id, Name);
  }

  Select = (id: number | null, name: string | null) => {
    this.selectedId = id;
    this.RecomputeTree();
    this.props.onSelect(id, name);
    this.DisableArrows();
  }

  GetInvoiceById = (id: number) => {
    let invoiceData = this.TreeRef.current.list.current.props.itemData.records[id]
    return invoiceData.data.rowData
  }

  SelectNextOrUnselect = () => { // external call
    let {index, list} = this.GetSelectedInfo(this.selectedId!);
    let nextInvoice = undefined
    if (index > -1 && list.length - 1 > index) {
      for (let i = index + 1; index < list.length; i++) {
        let idString = list[i]
        if (idString.indexOf('_user') === -1 && idString.indexOf('_status') === -1) {
          nextInvoice = idString
          break;
        }
      }
    }
    if (nextInvoice) this.SelectById(nextInvoice)
    else this.Select(null, null)
  }

  OnSelectPrev = () => {
    let {index, list} = this.GetSelectedInfo(this.selectedId!);
    let prevInvoice = undefined
    if (index > 0) {
      for (let i = index - 1; index > -1; i--) {
        let idString = list[i]
        if (idString.indexOf('_user') === -1 && idString.indexOf('_status') === -1) {
          prevInvoice = idString
          break;
        }
      }
    }
    if (prevInvoice) this.SelectById(prevInvoice)
  }

  OnSelectNext = () => {
    let {index, list} = this.GetSelectedInfo(this.selectedId!);
    let nextInvoice = undefined
    if (index > -1 && list.length - 1 > index) {
      for (let i = index + 1; index < list.length; i++) {
        let idString = list[i]
        if (idString.indexOf('_user') === -1 && idString.indexOf('_status') === -1) {
          nextInvoice = idString
          break;
        }
      }
    }
    if (nextInvoice) this.SelectById(nextInvoice)
  }

  DisableArrows = () => {
    let {index, list} = this.GetSelectedInfo(this.selectedId!);
    let firstInvoiceIndex = list.findIndex((id: string) => (id.indexOf('_user') === -1 && id.indexOf('_status') === -1))
    this.prevDisabled = index > -1 && index === firstInvoiceIndex;
    this.nextDisabled = index > -1 && index === list.length - 1;
    if (this.arrowsRef.current && this.arrowsRef.current.DisableArrows) {
      this.arrowsRef.current.DisableArrows(this.prevDisabled, this.nextDisabled)
    }
  }

  GetSelectedInfo = (selectedId: number) => {
    let rowsIds = this.TreeRef.current.list.current.props.itemData.order
    let index = rowsIds.findIndex((id: string) => id === (selectedId + ''))
    return {index, list: rowsIds};
  }

  OnScroll = (e: ListOnScrollProps) => {
    if (this.CurrentGroupRef.current && this.TreeRef.current) {
      this.CurrentGroupRef.current.Scroll(
        e.scrollOffset,
        e.scrollDirection,
        this.TreeRef.current.list.current.props.itemData,
        this.props.groupedList
      )
    }
  }

  RecomputeTree = () => {
    this.TreeRef.current.list.current.props.itemData.recomputeTree({
      // [this.props.currentUserId + '_user']: false,
    })
  }
}

export default VIMList;
