import {Component} from 'react';
import {
  Button,
  DropDownButton,
  DropDownButtonItem
} from '@progress/kendo-react-buttons'
import ClearableInput from '../Common/Form/ClearableInput'
import styles from './dashboard.module.scss'
import {simpleObject} from '../../helpers/interfaces'
import {DatePicker, DateTimePicker} from '@progress/kendo-react-dateinputs';
import {formatCell} from './interfaces';
import {maxDate, minDate} from './helpers'

interface props {
  props: any
  defaultValue: any
  field: string
  dateFormat?: formatCell

  onChange(value: any, props: any, dateFormat?: formatCell): void

  onOperatorChange?(e: any): void

  clear(e: any): any
}

interface state {
}

class GridFilterCell extends Component<props, state> {
  filterChangeTimeout: any
  value: any

  componentWillUnmount() {
    if (this.filterChangeTimeout) clearTimeout(this.filterChangeTimeout)
  }

  render() {
    let props = this.props.props
    if (props.filterType === 'date') {
      props.operators.forEach((item: simpleObject) => {
        item.field = props.field
        item.selected = item.operator === props.operator
      })
      let value = props.value;
      let dateFormat = this.props.dateFormat
      return <div style={{display: 'flex', alignItems: 'center'}}>
        {dateFormat === 'datetime' ?
          <DateTimePicker
            className={styles.QuickFilterDatepicker}
            defaultValue={value}
            onChange={this.OnChange}
            dateInput={() => null}
            min={minDate}
            max={maxDate}
          /> :
          <DatePicker
            className={styles.QuickFilterDatepicker}
            onChange={this.OnChange}
            defaultValue={value}
            min={minDate}
            max={maxDate}
            dateInput={() => null}
          />}
        <div style={{width: 4, display: 'inline-block'}}></div>
        <DropDownButton
          icon='filter'
          items={props.operators}
          textField='text'
          data-field={props.field}
          onItemClick={this.props.onOperatorChange}
          className={`${styles.AlignTop} ${props.operator !== undefined ? styles.BlueFilter : ''}`}
        >
          {props.operators.map((item: any) => <DropDownButtonItem
            key={item.text}
            text={item.text}
            selected={item.selected}
          ></DropDownButtonItem>)}
        </DropDownButton>
        <div style={{width: 4, display: 'inline-block'}}></div>
        <Button
          onClick={this.ClearDateFilter}
          title="Clear Filter"
          icon="filter-clear"
        />
      </div>
    } else if (props.filterType === 'text') {
      return <ClearableInput
        defaultValue={this.props.defaultValue}
        onChange={this.OnChange}
        clear={this.props.clear}
        dataprops={{field: this.props.field}}
      />
    } else if (props.filterType === 'numeric') {
      props.operators.forEach((item: simpleObject) => {
        item.field = props.field
        item.selected = item.operator === props.operator
      })
      let value = props.value;
      let renderValue = value;
      if (renderValue === '') renderValue = ''
      else if (renderValue === null) {
        if (props.operator) renderValue = 0
        else renderValue = ''
      }
      return <>
        <ClearableInput
          defaultValue={renderValue}
          onChange={this.OnChange}
          clear={this.props.clear}
          dataprops={{field: props.field, type: 'numeric'}}
          style={{width: 'calc(100% - 32px)', display: 'inline-block'}}
        />
        <div style={{width: 4, display: 'inline-block'}}></div>
        <DropDownButton
          icon='filter'
          items={props.operators}
          textField='text'
          data-field={props.field}
          onItemClick={this.props.onOperatorChange}
          className={`${styles.AlignTop} ${props.operator !== undefined ? styles.BlueFilter : ''}`}
        >
          {props.operators.map((item: any) => <DropDownButtonItem
            key={item.text}
            text={item.text}
            selected={item.selected}
          ></DropDownButtonItem>)}
        </DropDownButton>
      </>
    } else {
      return null
    }
  }

  OnChange = (e: any) => {
    let value = e.value
    this.value = value
    let props = this.props.props
    if (props.filterType === 'date') {
      this.ChangeFilter()
    } else {
      if (this.filterChangeTimeout) clearTimeout(this.filterChangeTimeout)
      this.filterChangeTimeout = setTimeout(this.ChangeFilter, 1000)
    }
  }

  ChangeFilter = () => {
    this.props.onChange(this.value, this.props.props, this.props.dateFormat)
  }

  ClearDateFilter = () => {
    this.props.clear(this.props.props)
  }
}

export default GridFilterCell
