import {AxiosRequestConfig} from 'axios';
import moment from 'moment';
import {ModalRef} from '../Components/Common/Modal/Modal';
import AuthData from './authData';
import {axiosQuery, showRequestError} from './axiosHelpers';
import {addParamsToUrl} from './helpers';
import {IGetSQLDataParams, simpleObject} from './interfaces';
import {DataServerHost} from './settings';

const $ = window.$
const TKREVIEW_COUNTER_UPDATING_INTERVAL = 30

export async function getSQLData(params: IGetSQLDataParams) {
    let data = await AuthData.queueGetAuthToken();
    if (data && data.length && data[0] && data[1]) {
        let access_token = data[0];
        let token_type = data[1];
        let path = params.path || 'dataproxy'
        let method = params.method || 'POST'
        let axiosConfig: AxiosRequestConfig = {
            method: method,
            url: `https://${DataServerHost}/api/${path}/${params.spName}`,
            headers: {
                Authorization: `${token_type} ${access_token}`,
                timestamp: moment().format()
            },
            cancelToken: this?.cancelToken?.token
        };
        if (params.paramsToQuery) {
            axiosConfig.params = params.params
            axiosConfig.paramsSerializer = (params: simpleObject) => {
                return addParamsToUrl(params, '').slice(1)
            }
        } else axiosConfig.data = params.params

        try {
            if (params.modal) ModalRef.startProcessing();
            return await axiosQuery(axiosConfig);
        } catch (e: any) {
            showRequestError(e, `Get SQL Data Error ${params.spName}:`)
            throw e
        } finally {
            if (params.modal) ModalRef.stopProcessing();
        }
    }
}

export async function UploadResultVersion(resultId: number, file: any) {
    return UploadFile(`/api/Clm/UploadResultVersion?resultId=${resultId}`, file)
}

export async function UploadFile(path: string, file: any) {
    let data = await AuthData.queueGetAuthToken();
    if (data && data.length && data[0] && data[1]) {
        let access_token = data[0];
        let token_type = data[1];
        let axiosConfig: AxiosRequestConfig = {
            method: 'post',
            url: `https://${DataServerHost}${path}`,
            data: file,
            headers: {
                Authorization: `${token_type} ${access_token}`,
                timestamp: moment().format()
            },
            cancelToken: this?.cancelToken?.token
        };
        try {
            return await axiosQuery(axiosConfig);
        } catch (e: any) {
            showRequestError(e, 'Upload File Error:')
        }
    }
}

export async function ReferenceRecordsDataSource(refName: string, params: simpleObject = {}) { // by default server return activeOnly === true
    try {
        return getSQLData({
            spName: 'DB_SelectRefRecords',
            params: {refName, ...params}
        })
    } catch (e: any) {
    }
}

export async function GetReferenceRecordName(recordId: number) {
    try {
        let result = await getSQLData({
            spName: 'DB_SelectRefRecords',
            params: {recordId}
        })
        return result[0] && result[0][0] && result[0][0].Name || 'No Name'
    } catch (e) {
        return 'No Name'
    }
}

window.helpers.getSQLData = function (spName: string, params?: simpleObject, modal?: boolean) {
    var defer = $.Deferred();
    getSQLData({
        spName: spName,
        params: params,
        modal: modal
    }).then((result) => defer.resolve(result)).catch((e) => defer.reject(e));
    return defer.promise()
}

window.helpers.TKReviewUpdatePendingCount = async function () {
    let result = await getSQLData({spName: 'TK_GetTCsInfo'})
    let enableTCsCount = result[0].length;
    window.tkReviewBadgeText = enableTCsCount > 0 ? enableTCsCount + '' : ''
    let badges = window.document.getElementsByClassName('tkReview-badge')
    if (badges && badges.length) {
        // @ts-ignore
        for (let el of badges) {
            el.innerHTML = window.tkReviewBadgeText
        }
    }

    if (window.tkReviewCounterTimeout) clearTimeout(window.tkReviewCounterTimeout)
    window.tkReviewCounterTimeout = setTimeout(window.helpers.TKReviewUpdatePendingCount, (TKREVIEW_COUNTER_UPDATING_INTERVAL * 60 * 1000))
}
