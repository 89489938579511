import {Button} from '@progress/kendo-react-buttons'
import React, {Component} from 'react'
import {LoadWarehouses} from '../../helpers/LoadLists'
import {simpleObject} from '../../helpers/interfaces'
import {RunScriptLocalAsync} from '../../helpers/runscripts'
import DesktopActions from '../Common/DesktopActions'
import DashboardGrid from '../Dashboard/Grid'
import {gridChangeDataAction, IColumnSetting, IFilterSetting} from '../Dashboard/interfaces'
import {tabId} from '../Tabs/interfaces'

interface state {
}

interface props {
    buildPlanId?: number
    doNotSaveFilters?: boolean
    isActive: boolean
    switcher?: any

    switchTab(tabId: tabId): void

    onChangeGridData?(data: Array<simpleObject>, action?: gridChangeDataAction): void
}

class BOMDashboard extends Component<props, state> {
    gridRef: any = React.createRef()
    isBPTab: boolean = !!this.props.doNotSaveFilters
    bpId = this.props.buildPlanId
    filters: Array<IFilterSetting>
    columns: Array<IColumnSetting>

    constructor(props: any) {
        super(props)
        this.filters = []
        let bpId = this.props.buildPlanId
        if (!bpId) {
            this.filters = [
                {
                    id: 'filterMaterial',
                    placeholder: 'Filter by Material',
                    type: 'combobox',
                    width: 300,
                    serverFilter: {
                        param: 'materialId',
                    },
                    localStoragePrefix: 'ID',
                    virtual: {
                        refName: 'FSMMaterials',
                    },
                    toolbarRow: 1,
                },
                {
                    id: 'filterWH',
                    placeholder: 'Filter by Warehouse',
                    width: 300,
                    type: 'combobox',
                    serverFilter: {
                        param: 'locationId',
                        getData: LoadWarehouses,
                    },
                    localStoragePrefix: 'ID',
                    toolbarRow: !bpId ? 1 : 2,
                },
                {
                    id: 'filterBPO',
                    placeholder: 'Filter by Owner',
                    idColumn: 'BPOID',
                    labelColumn: 'BPOName',
                    type: 'combobox',
                    toolbarRow: 1,
                },
                {
                    id: 'filterBPState',
                    placeholder: 'BP State',
                    idColumn: 'BPState',
                    width: '105px',
                    type: 'combobox',
                    values: [
                        {Name: 'All', Id: ''},
                        {Name: 'Active', Id: 'A'},
                        {Name: 'Closed', Id: 'C'},
                    ],
                    toolbarRow: 1,
                }
            ]
        }

        this.columns = [
            {
                field: 'RowNum',
                title: '#',
                type: 'number',
                gridWidth: 40,
                filterable: false,
                columnMenu: false,
                locked: true,
                aggregate: 'count',
            },
        ]
        if (!bpId) {
            this.columns.push(
                {
                    field: 'ProjectName',
                    title: 'Project',
                    type: 'string',
                    gridWidth: 135,
                    locked: true,
                    format: {
                        type: 'string',
                        fieldId: 'ProjectID',
                    },
                },
                {
                    field: 'BPName',
                    title: 'Build Plan Name',
                    type: 'string',
                    gridWidth: 200,
                    locked: true,
                    format: {
                        type: 'link',
                        fieldId: 'BPID',
                        refName: 'FSMBuildPlans',
                    },
                },
                {
                    field: 'SiteName',
                    title: 'Site ID',
                    type: 'string',
                    gridWidth: 100,
                    locked: true,
                    format: {
                        type: 'link',
                        fieldId: 'SiteID',
                        refName: 'FSMSites',
                    },
                }
            )
        }

        this.columns.push(
            {
                field: 'BOMNum',
                title: 'BOM #',
                type: 'string',
                gridWidth: 100,
                locked: true,
                format: {
                    type: 'link',
                    fieldId: 'BOMID',
                    refName: 'FSMBOM',
                },
            },
            {
                field: 'BOMName',
                title: 'BOM Name',
                type: 'string',
                gridWidth: 200,
                locked: true,
            },
            {
                field: 'BOMType',
                title: 'Type',
                type: 'string',
                gridWidth: 100,
                locked: true,
                format: {
                    type: 'string',
                    fieldId: 'BOMType',
                },
            },
            {
                field: 'BOMTotalAmount',
                title: 'Total Amount ($)',
                type: 'number',
                gridWidth: 125,
                locked: true,
                aggregate: 'sum',
                format: {
                    type: 'currency',
                    customTotal: true,
                    getCustomTotal: (gridData: Array<simpleObject>) => {
                        let unicBOMIds: simpleObject = {}
                        let sum = gridData.reduce(
                            (accumulator: number, current: simpleObject) => {
                                if (unicBOMIds[current.BOMID]) return accumulator
                                unicBOMIds[current.BOMID] = true
                                return accumulator + current.BOMTotalAmount
                            },
                            0
                        )
                        return sum
                    },
                },
            },
            {
                title: 'BOM Details',
                type: 'parent',
                columns: [
                    {
                        field: 'BOMDueDate',
                        title: 'Due Date',
                        type: 'date',
                        format: {
                            type: 'date',
                            customColor: true,
                            getColor: function (dataItem: simpleObject) {
                                if (!dataItem.BOMDueDate) return
                                var today = new Date()
                                if (
                                    today > dataItem.BOMDueDate &&
                                    (dataItem.BOMStage === 'Pending' ||
                                        dataItem.BOMStage === 'Sent to Warehouse')
                                ) {
                                    return 'red'
                                } else {
                                    return ''
                                }
                            },
                        },
                    },
                    {
                        field: 'BOMStage',
                        title: 'Stage',
                        type: 'string',
                        gridWidth: 100,
                        format: {
                            type: 'string',
                            fieldId: 'BOMStage',
                        },
                    },
                    {
                        field: 'BOMSentToWH',
                        title: 'Sent to Warehouse',
                        type: 'date',
                        gridWidth: 135,
                        format: {
                            type: 'date',
                        },
                        minResizableWidth: 125,
                    },
                    {
                        field: 'BOMKitted',
                        title: 'Kitted',
                        type: 'date',
                        format: {
                            type: 'date',
                            customColor: true,
                            getColor: function (dataItem: simpleObject) {
                                if (
                                    !dataItem.BOMDueDate ||
                                    !dataItem.BOMKitted ||
                                    dataItem.BOMStage
                                )
                                    return
                                if (
                                    dataItem.BOMDueDate < dataItem.BOMKitted &&
                                    dataItem.BOMStage !== 'Completed'
                                ) {
                                    return 'red'
                                } else if (
                                    dataItem.BOMDueDate >= dataItem.BOMKitted &&
                                    dataItem.BOMKitted !== ''
                                ) {
                                    return 'green'
                                } else {
                                    return ''
                                }
                            },
                        },
                    },
                    {
                        field: 'BOMCompleted',
                        title: 'Completed',
                        type: 'date',
                        format: {
                            type: 'date',
                        },
                    },
                ],
            },
            {
                title: 'Material Request',
                type: 'parent',
                columns: [
                    {
                        field: 'MRNum',
                        title: '#',
                        type: 'string',
                        gridWidth: 100,
                        format: {
                            type: 'link',
                            fieldId: 'MRID',
                            refName: 'ICPurchaseRequests',
                        },
                    },
                    {
                        field: 'MRRequested',
                        title: 'Requested',
                        type: 'date',
                        format: {
                            type: 'date',
                        },
                    },
                    {
                        field: 'MRApproved',
                        title: 'Approved',
                        type: 'date',
                        format: {
                            type: 'date',
                        },
                    },
                    {
                        field: 'MRStage',
                        title: 'Stage',
                        type: 'string',
                        gridWidth: 100,
                        format: {
                            type: 'string',
                            fieldId: 'MRStage',
                        },
                    },
                ],
            },
            {
                title: 'Inventory',
                type: 'parent',
                columns: [
                    {
                        field: 'TJNum',
                        title: 'Transaction #',
                        type: 'string',
                        gridWidth: 100,
                        format: {
                            type: 'link',
                            fieldId: 'TJID',
                            refName: 'ICTransactionsJournal',
                        },
                    },
                    {
                        field: 'TJType',
                        title: 'Type',
                        type: 'string',
                        gridWidth: 100,
                        format: {
                            type: 'string',
                            fieldId: 'TJType',
                        },
                    },
                    {
                        field: 'TJDate',
                        title: 'Date',
                        type: 'date',
                        format: {
                            type: 'date',
                        },
                    },
                    {
                        field: 'TJVendor',
                        title: 'Vendor',
                        type: 'string',
                        gridWidth: 100,
                        format: {
                            type: 'string',
                            fieldId: 'TJVendorID',
                        },
                    },
                    {
                        field: 'TJStage',
                        title: 'Stage',
                        type: 'string',
                        gridWidth: 100,
                        format: {
                            type: 'string',
                            fieldId: 'TJStage',
                        },
                    },
                    {
                        field: 'TJAmount',
                        title: 'Amount',
                        type: 'number',
                        gridWidth: 100,
                        format: {
                            type: 'currency',
                        },
                        aggregate: 'sum',
                    },
                    {
                        field: 'TJEstDelivery',
                        title: 'Est. Delivery',
                        type: 'date',
                        format: {
                            type: 'date',
                        },
                    },
                    {
                        field: 'TJCompleted',
                        title: 'Completed',
                        type: 'date',
                        format: {
                            type: 'date',
                        },
                    },
                ],
            }
        )
    }

    render() {
        if (
            this.isBPTab &&
            this.props.isActive &&
            this.bpId !== this.props.buildPlanId
        ) {
            this.bpId = this.props.buildPlanId
        }

        let bpId = this.props.buildPlanId
        let key = this.isBPTab ? bpId : 'bom'
        if (this.isBPTab && !bpId) return null
        if (
            this.isBPTab &&
            (!this.props.buildPlanId ||
                (!this.props.isActive && this.bpId !== this.props.buildPlanId))
        )
            return null
        let params = bpId ? {buildPlanId: bpId} : undefined
        return (
            <DashboardGrid
                isActive={this.props.isActive}
                ref={this.gridRef}
                key={key}
                columnsSettings={this.columns}
                filters={this.filters}
                getSqlParams={{spName: 'DB_BOM', params}}
                saveSettings={{
                    prefix: 'BOM',
                    saveFilters: !this.props.doNotSaveFilters,
                }}
                excelFileName="BOM.xlsx"
                initRefresh={this.InitRefresh}
                columnMenu={{
                    filters: true,
                    columns: false,
                }}
                getRowKey={(dataItem: simpleObject) => dataItem.RowNum}
                toolbar={{
                    switcher: this.props.switcher,
                    actionBtns: bpId ? (
                        <DesktopActions
                            actions={<Button onClick={this.NewBom}>New BOM</Button>}
                        />
                    ) : undefined,
                }}
                onChangeGridData={this.props.onChangeGridData}
            />
        )
    }

    Refresh = () => {
    }

    InitRefresh = (refresh: any) => {
        this.Refresh = refresh
    }

    NewBom = () => {
        RunScriptLocalAsync('FSMBOM_CreateNew', {
            BPID: this.props.buildPlanId,
        }).finally(this.Refresh)
    }
}

export default BOMDashboard
