import {Component} from 'react';
import styles from './checklistResults.module.scss';
import {Card} from "@progress/kendo-react-layout";
import {IDocumentItem} from './interfaces';
import {ICLMSettings} from '../../../stores/interfaces';
import expandedPanelStyles
  from '../../../Components/Common/ExpandedPanel/ExpandedPanel.module.scss';

interface props {
  data: IDocumentItem
  settings: ICLMSettings

  onClick(item: IDocumentItem, domElement: HTMLDivElement): void
}

interface state {
}

class RelatedDocumentsMobileListItem extends Component<props, state> {
  render() {
    let dataItem = this.props.data
    return <div onClick={this.OnClick} style={{height: '100%'}}>
      <Card
        className={`${dataItem.Selected ? expandedPanelStyles.MobileListItemSelected : ""} ${expandedPanelStyles.MobileListItem}`}>
        <div className={styles.MobileSingleRowText}
             style={{fontWeight: 600}}>{dataItem.index} {dataItem.cliName}</div>
        <div style={{display: 'flex',}}>
          <span style={{width: 40}}>Detail: </span>
          <span>{dataItem.FieldDetail}</span>
        </div>
        <div style={{display: 'flex', marginBottom: 'auto'}}>
          <span style={{width: 40}}> Media: </span>
          <span>
            {!!dataItem.files.length ? `${dataItem.files.length} File${dataItem.files.length > 1 ? 's' : ''}` : 'No Media'}
          </span>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{display: 'flex', alignItems: 'center', color: 'red'}}>
            {(this.props.settings.IsReviewer || this.props.settings.IsCustomer) && dataItem.included === false &&
                <span style={{marginRight: 8}}>Disabled</span>}
          </div>
          <div style={{textAlign: 'right'}}>
            {dataItem.statusName}
          </div>
        </div>
      </Card>
    </div>
  }

  OnClick = (e: any) => {
    this.props.onClick(this.props.data, e.currentTarget.firstChild)
  }
}

export default RelatedDocumentsMobileListItem;
