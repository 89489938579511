import {Popup} from "@progress/kendo-react-popup";
import {Menu, MenuItem, MenuSelectEvent} from '@progress/kendo-react-layout';
import {useEffect, useRef, useState} from "react";
import CardManagement from "../../Components/Cards/CardManagement";
import {IS_TOUCH_DEVICE} from "../../helpers/settings";
import {ModalRef} from "../Common/Modal/Modal";
import {
    BindNodeTooltip,
    fitBoundsGroup,
    getFigureObjectLayer,
    getObjectMapData,
    MARKERS_COLORS,
    OpenObject
} from "./helpers";

const MapContextMenu = (props: { map: any, refresh(): void }) => {
    const {map} = props
    const [position, setPosition] = useState<{ left: number; top: number } | undefined>(undefined)
    const newLocationCoordsRef = useRef<{ lat: number, lng: number } | undefined>(undefined)
    const mapTapHoldTimeoutRef = useRef<any>(null)
    const createdNodesMarkersRef = useRef<{
        [objectId: number]: {
            MarkerLayer: any
            FigureLayer: any
        }
    }>({})

    useEffect(() => {
            /*map.on('touchend', (e: any) => {
                // doesn't fired in leaflet
                if (mapTapHoldTimeoutRef.current) {
                    console.log(mapTapHoldTimeoutRef.current, 'clear time out')
                    clearTimeout(mapTapHoldTimeoutRef.current);
                }
            });*/
            map.on("contextmenu", openMapContextMenu);
            if (IS_TOUCH_DEVICE) map.on('touchstart', onTouchStart)

            return () => {
                if (map) map.off("contextmenu", openMapContextMenu);
                document.removeEventListener("click", closeContextMenu);
            }
        }, [map]
    )

    const OpenNodeCard = (e: any) => {
        const markerLayer = e.target
        let {refName, objectId} = markerLayer.options;
        if (!objectId || !refName) return
        OpenObject(e, onSaveObject)
    }

    const onSaveObject = async (objectId: number) => {
        try {
            ModalRef.startProcessing('', 'rgba(255, 255, 255, 0.2)')
            const {mainAddress} = await getObjectMapData(objectId)
            const {
                Lat,
                Lng,
                ObjectId,
                ObjectName,
                ObjectType,
                LocationColor,
                AddressString,
                Boundaries,
                Radius
            } = mainAddress
            if (!Lat || !Lng) return
            const coords = [Lat, Lng]
            const {MarkerLayer, FigureLayer} = createdNodesMarkersRef.current[ObjectId] || {}
            FigureLayer.removeFrom(map)
            if (MarkerLayer) {
                MarkerLayer.setLatLng(coords)
                MarkerLayer.unbindTooltip()
                BindNodeTooltip(MarkerLayer, ObjectName, AddressString)
            }
            const isLocation = ObjectType === "Location"
            const primaryColor = isLocation ? LocationColor || MARKERS_COLORS.AQUA : MARKERS_COLORS.GRAY
            const newFigureLayer = getFigureObjectLayer(
                ObjectId, ObjectType, 'black', primaryColor,
                coords, Boundaries, Radius
            );
            newFigureLayer.addTo(map)
            createdNodesMarkersRef.current[ObjectId] = {MarkerLayer, FigureLayer: newFigureLayer}
            fitBoundsGroup(new window.L.FeatureGroup([MarkerLayer, newFigureLayer]), map)
        } finally {
            ModalRef.stopProcessing()
        }
    }

    const onCreateObject = async (objectId: number) => {
        try {
            ModalRef.startProcessing('', 'rgba(255, 255, 255, 0.2)')
            const {mainAddress} = await getObjectMapData(objectId)
            const {
                Lat,
                Lng,
                ObjectId,
                ObjectName,
                ObjectType,
                LocationColor,
                AddressString,
                Boundaries,
                Radius
            } = mainAddress
            if (!Lat || !Lng) return
            const coords = [Lat, Lng]
            const isLocation = ObjectType === "Location"
            const primaryColor = isLocation ? LocationColor || MARKERS_COLORS.AQUA : MARKERS_COLORS.GRAY
            const MarkerLayer = window.L.mapquest
                .textMarker(coords, {
                    position: "right",
                    type: "marker",
                    icon: {
                        primaryColor,
                        secondaryColor: MARKERS_COLORS.GREEN,
                        size: "sm",
                    },
                    draggable: false,
                    refName: isLocation ? "Locations" : "FSMSites",
                    objectId: ObjectId,
                })
                .on("contextmenu", OpenNodeCard)
                .addTo(map)
            BindNodeTooltip(MarkerLayer, ObjectName, AddressString)

            const FigureLayer = getFigureObjectLayer(
                ObjectId, ObjectType, 'black', primaryColor,
                coords, Boundaries, Radius
            );
            FigureLayer.addTo(map)
            createdNodesMarkersRef.current[mainAddress.ObjectId] = {MarkerLayer, FigureLayer}
            fitBoundsGroup(new window.L.FeatureGroup([MarkerLayer, FigureLayer]), map)
        } finally {
            ModalRef.stopProcessing()
        }
    }

    const onSelect = (event: MenuSelectEvent) => {
        const action = event.item.data
        if (action === 'location') {
            CardManagement.OpenLocationCard({
                coordinates: newLocationCoordsRef.current,
                onFinish: onCreateObject
            })
        } else if (action === 'site') {
            CardManagement.OpenSiteCard({
                coordinates: newLocationCoordsRef.current,
                onFinish: onCreateObject
            })
        }
    }

    const openMapContextMenu = (e: any) => {
        const {clientX: left, clientY: top} = e.originalEvent;
        const {lat, lng} = e.latlng
        newLocationCoordsRef.current = {lat, lng,};
        setPosition({left, top})
        document.addEventListener("click", closeContextMenu);
    };

    const closeContextMenu = () => {
        setPosition(undefined)
        document.removeEventListener("click", closeContextMenu);
    };

    const onTouchStart = (e: any) => {
        if (e.originalEvent.pointerType === 'touch') {
            if (e.originalEvent.preventDefault) e.originalEvent.preventDefault()
            mapTapHoldTimeoutRef.current = setTimeout(function () {
                openMapContextMenu(e)
            }, 1500);
        }
    }

    return (
        <Popup
            offset={position || undefined}
            show={!!position}
        >
            <Menu
                vertical={true}
                onSelect={onSelect}
                className={'k-context-menu'}
            >
                <MenuItem
                    key={'location'}
                    data={'location'}
                    text="Create New Location"></MenuItem>
                <MenuItem
                    key={'site'}
                    data={'site'}
                    text="Create New Site"></MenuItem>
            </Menu>
        </Popup>)
}

export default MapContextMenu
