import React from 'react';
import {Button} from '@progress/kendo-react-buttons';
import BaseComponent from '../../Components/BaseComponent';
import CardManagement from '../../Components/Cards/CardManagement';
import DashboardGrid from '../../Components/Dashboard/Grid';
import {
  IColumnSetting,
  IToolbarSettings
} from '../../Components/Dashboard/interfaces';
import {simpleObject} from '../../helpers/interfaces';
import {IResource} from './interfaces';

interface props {
  isActive: boolean
  toolbar?: IToolbarSettings

  onChangeGridData?(data: Array<simpleObject>): void
}

interface state {
}

class CLMResources extends BaseComponent<props, state> {
  gridRef: any = React.createRef()
  columns: Array<IColumnSetting> = [
    {
      field: "name",
      title: "Name",
      type: 'string',
    },
    {
      field: "pin",
      title: "PIN",
      gridWidth: 100,
      type: 'string',
    },
    {
      field: "phone",
      title: "Phone",
      gridWidth: 150,
      type: 'string',
    },
    {
      field: "email",
      title: "Email",
      type: 'string',
    },
    {
      field: "status",
      title: "Status",
      gridWidth: 90,
      type: 'string',
      format: {
        type: 'string',
        fieldId: 'status'
      }
    }
  ]

  render() {
    return <DashboardGrid
      ref={this.gridRef}
      isActive={this.props.isActive}
      columnsSettings={this.columns}
      getSqlParams={{spName: 'CLPM_GetResources'}}
      saveSettings={{prefix: 'CLPM_Resources', saveFilters: true}}
      initRefresh={this.InitRefresh}
      columnMenu={{
        filters: true,
        columns: false
      }}
      getRowKey={(dataItem: simpleObject) => dataItem.id}
      toolbar={{
        actionBtns: <Button iconClass="plus" onClick={this.NewRecordCard}>New
          Record</Button>,
      }}
      selectSettings={{
        pageId: 'CLPM',
        onSelectRow: this.OnSelectWO,
      }}
      onChangeGridData={this.props.onChangeGridData}
    />
  }

  Refresh = () => {
  }

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh
  }

  OnSelectWO = (dataItem: IResource | null) => {
    if (dataItem) CardManagement.OpenResourceCard(dataItem, this.Refresh)
  }

  NewRecordCard = () => {
    CardManagement.OpenResourceCard(undefined, this.Refresh)
  }
}

export default CLMResources;
