import moment from 'moment';
import BaseComponent from '../../../Components/BaseComponent';
import {Button, Toolbar, ToolbarSpacer} from '@progress/kendo-react-buttons';
import {Loader as KendoLoader} from '@progress/kendo-react-indicators';
import {Tooltip} from '@progress/kendo-react-tooltip';
import ProcessingButton from '../../../Components/Common/Form/ProcessingButton';
import {IS_IE} from '../../../helpers/settings';
import {RunScriptAsync} from '../../../helpers/runscripts';
import {formatHoursDuration} from '../../../helpers/helpers';
import CardManagement from '../../../Components/Cards/CardManagement';
import {
    IAdjustedTimeLineItem,
    IAdjustedTimeLineItemServer,
    IAdjustmentsInfo,
    IDrawAdjustedTE,
    IDrawMileage,
    IDrawOriginalTE,
    INonWage,
    IOriginalTimeLineItemServer,
    IPayroll,
    ITCHistoryItem,
    ITCSummaryInfo,
    ITimeEntry,
    TimeCardProps,
    TStateData,
    TStateItem
} from '../interfaces';
import {simpleObject} from '../../../helpers/interfaces';
import Loader from '../../../Components/Common/Loader';
import {ModalRef} from '../../../Components/Common/Modal/Modal';

import TCInfo from './TCInfo';
import TCPayrolls from './TCPayrolls';
import TCNonWages from './TCNonWages';
import TCTESummary from './TCTESummary';
import Mileages from "./Mileages/Mileages";
import DayLog from "./DayLog/DayLog";
import Adjustments from "../../../Components/TC/Adjustments/Adjustments";
import TotalDurations from "../../../Components/TC/TotalDurations";
import AdjustmentsComment from "../../../Components/TC/AdjustmentsComment";
import {GapsIslandsContainer} from "../../../Components/TC/GapsIslandsContainer";
import styles from './timecard.module.scss';
import {GetPreparedInfo, GetPreparedTimeLine} from "../helpers";
import UserInfo from "../../../stores/User";
import TCHistory from "./TCHistory";

interface state {
    loading: boolean
    createProcessing: boolean
    resetProcessing: boolean
    manageProcessing: boolean
    tcInfo: ITCSummaryInfo | null
    payrolls: Array<IPayroll> | null
    nonwages: Array<INonWage> | null
    timeEntries: Array<ITimeEntry> | null
    adjustmentsInfo: IAdjustmentsInfo | null
    totalClocked: number
    totalApproved: number
    totalClockedAdjustments: number
    totalApprovedAdjustments: number
    waivedLunches: string[]
    drawOriginal: IDrawOriginalTE[]
    drawAdjusted: IDrawAdjustedTE[]
    drawMileages: IDrawMileage[]
    originalStateData: TStateItem[]
    adjustedStateData: TStateItem[]
    history: ITCHistoryItem[]
}

const getStateData = (originalData: IDrawOriginalTE[] | IDrawAdjustedTE[], isOriginal?: boolean) => {
    let stateCodes: TStateData = []
    let newState: TStateItem | null = null
    for (let i = 0; i < originalData.length; i++) {
        const item = originalData[i]
        const te = item.te

        // @ts-ignore
        const event: string = te?.Event || ''
        const isClockFlag = !te || event.indexOf('CLOCK') > -1

        const doStartNewState = !!newState && (!te || te.StateCode !== newState.stateCode)
        if (newState && (isClockFlag || doStartNewState)) {
            stateCodes.push(newState)
            newState = null
        }
        if (!te || (isOriginal && isClockFlag)) {
            stateCodes.push({
                heightMultiplier: item.rows,
                isClockFlag: isClockFlag
            })
        } else {
            if (!newState) {
                newState = {
                    heightMultiplier: 0,
                    stateCode: te.StateCode,
                }
            }
            newState.heightMultiplier += item.rows
        }
        if (newState && originalData.length - 1 === i) stateCodes.push(newState)
    }
    return stateCodes
}

class TImeCard extends BaseComponent<TimeCardProps, state> {
    IsHideUnscheduledVisitAlert: boolean = false;
    tcId: number = this.props.tcId
    isReviewInterface: boolean
    adjustments: Array<IAdjustedTimeLineItem> = []
    ShowStateAllocation: boolean = false

    constructor(props: TimeCardProps) {
        super(props)
        this.state = {
            loading: false,
            createProcessing: false,
            resetProcessing: false,
            manageProcessing: false,
            tcInfo: null,
            payrolls: null,
            nonwages: null,
            timeEntries: null,
            adjustmentsInfo: null,
            totalClocked: 0,
            totalApproved: 0,
            totalClockedAdjustments: 0,
            totalApprovedAdjustments: 0,
            waivedLunches: [],
            drawOriginal: [],
            drawAdjusted: [],
            drawMileages: [],
            originalStateData: [],
            adjustedStateData: [],
            history: [],
        }
        let tkReviewInterface = window.document.getElementById('TKAuditCoverFrame')
        this.isReviewInterface = tkReviewInterface !== null
    }

    componentDidMount() {
        this.LoadData()
    }

    componentDidUpdate() {
        if (this.tcId !== this.props.tcId && this.props.isActive) {
            this.tcId = this.props.tcId
            this.LoadData()
        }
    }

    render() {
        if (this.state.loading) return <Loader/>

        const {adjustmentsInfo, tcInfo} = this.state
        const isShowAdjustments = !!adjustmentsInfo && !!adjustmentsInfo.Id
        const isHorizontalMode = this.props.pageId === "TKDashboard" || this.props.pageId === 'TCCard'

        return <div
            className={`${styles.TCContainer} ${isHorizontalMode ? styles.TCContainerHorozontal : ''}`}
            style={!this.isReviewInterface ? {overflow: 'auto'} : undefined}
        >
            <div>
                <TCInfo
                    key={this.tcId}
                    info={this.state.tcInfo}
                    tcId={this.tcId}
                    refresh={this.Refresh}
                />
                <TCPayrolls
                    payrolls={this.state.payrolls}
                    locked={!!this.state.tcInfo?.IsWageAllocationLocked}
                    lunch={this.state.tcInfo?.AppliedLunchDuration || null}
                />
                <TCNonWages nonwages={this.state.nonwages}/>
                <TCTESummary timeEntries={this.state.timeEntries}/>
                <TCHistory history={this.state.history}/>
            </div>
            <div>
                <div className={styles.TCWidgets}>
                    <div className={styles.TCTimeLine}>
                        <Toolbar style={{justifyContent: 'flex-end'}}>
                            <div>GPS Timeline</div>
                        </Toolbar>
                        <TotalDurations
                            clocked={this.state.totalClocked}
                            approved={this.state.totalApproved}
                            lunch={this.state.tcInfo?.AuditLunchDuration || undefined}
                            className={styles.OriginalTotalDurations}
                            isOriginal={true}
                        />
                        {!!this.state.waivedLunches.length && <div style={{paddingLeft: 10}}>
                            Waived Lunches: {
                            this.state.waivedLunches.map((l) => <span className={styles.WaivedLunch} key={l}>{l}</span>)
                        }
                        </div>}

                    </div>
                    <div className={styles.TCMileage}>
                        <Toolbar style={{justifyContent: 'center'}}> Locations &
                            Mileage </Toolbar>
                    </div>
                    {!!adjustmentsInfo &&
                        <div className={styles.TCTimeLine}>
                            <Toolbar>
                                <div>Adjusted{(!adjustmentsInfo.CanChange && !!adjustmentsInfo.Id) && this.renderAdjustmentsStatus(adjustmentsInfo)}</div>
                                {(adjustmentsInfo.CanChange && !adjustmentsInfo.Id) &&
                                    <div>{this.state.createProcessing ?
                                        <KendoLoader themeColor={"dark"}
                                                     type='converging-spinner' size="small"/> :
                                        <span
                                            className="link"
                                            onClick={this.CreateAdjustment}
                                        >Create</span>
                                    }</div>
                                }
                                <ToolbarSpacer/>
                                {!!adjustmentsInfo.Id && <Tooltip
                                    anchorElement="target"
                                    position="top"
                                    parentTitle={true}
                                    style={{width: '190px'}}
                                    openDelay={400}
                                    showCallout={adjustmentsInfo.CanChange}
                                    onPosition={this.TooltipInfoOnPosition}
                                    content={this.renderAdjustmentsInfoTooltip}>
                                    <Button
                                        iconClass="mdi mdi-information-outline"
                                        fillMode="flat"
                                        onClick={this.ShowAdjustmentsHistory}
                                        className={styles.InfoBtn}
                                        title={adjustmentsInfo.AuthorName}
                                    ></Button>
                                </Tooltip>}
                                {!!adjustmentsInfo.Id && adjustmentsInfo.CanChange && <>
                                    <ProcessingButton
                                        ButtonProps={{
                                            iconClass: "mdi mdi-backup-restore",
                                            fillMode: "flat",
                                            title: "Reset",
                                            onClick: this.ResetAdjustmentConfirmation,
                                            className: styles.ResetBtn
                                        }}
                                        processing={this.state.resetProcessing}
                                    />
                                    <Button icon="edit" fillMode="flat" title="Edit"
                                            onClick={this.EditAdjustment}></Button>
                                </>}
                                {(!!adjustmentsInfo.Id && (adjustmentsInfo.CanChange || adjustmentsInfo.CanDelete)) &&
                                    <ProcessingButton
                                        ButtonProps={{
                                            icon: !adjustmentsInfo.IsActive ? "check" : adjustmentsInfo.CanDelete ? 'trash' : "cancel",
                                            fillMode: "flat",
                                            title: !adjustmentsInfo.IsActive ? "Apply" : adjustmentsInfo.CanDelete ? 'Delete' : "Reject",
                                            onClick: this.ManageAdjustment
                                        }}
                                        processing={this.state.manageProcessing}
                                    />
                                }
                            </Toolbar>
                            {(adjustmentsInfo.CanChange && !!adjustmentsInfo.Id) &&
                                <div style={!adjustmentsInfo.IsActive ? {opacity: 0.5} : undefined}>
                                    <TotalDurations
                                        clocked={this.state.totalClockedAdjustments}
                                        approved={this.state.totalApprovedAdjustments}
                                        lunch={adjustmentsInfo.LunchDuration || undefined}
                                    />
                                    <AdjustmentsComment
                                        comment={adjustmentsInfo.Comment || null}
                                    />
                                </div>}
                        </div>}
                </div>

                <div className={styles.TCWidgets}>
                    <div className={styles.TCTimeLine}>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <div style={IS_IE ? {width: '100%'} : undefined}>
                                <DayLog
                                    refresh={this.LoadData} drawOriginal={this.state.drawOriginal}
                                    IsHideUnscheduledVisitAlert={this.IsHideUnscheduledVisitAlert}
                                />
                            </div>
                        </div>
                    </div>

                    {this.ShowStateAllocation &&
                        <GapsIslandsContainer stateData={this.state.originalStateData} isLeftSide={true}/>}

                    <div className={styles.TCMileage}>
                        <Mileages drawMileages={this.state.drawMileages} refreshTC={this.LoadData}/>
                    </div>

                    {this.ShowStateAllocation && isShowAdjustments &&
                        <GapsIslandsContainer stateData={this.state.adjustedStateData} isLeftSide={false}/>}

                    <div className={styles.TCTimeLine}
                         style={!adjustmentsInfo?.IsActive ? {opacity: 0.5} : undefined}>
                        {isShowAdjustments &&
                            <div style={{height: '100%'}}>
                                <div style={IS_IE ? {width: '100%'} : undefined}>
                                    <Adjustments
                                        drawAdjusted={this.state.drawAdjusted}
                                        refresh={this.LoadData}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }

    renderAdjustmentsStatus = (info: IAdjustmentsInfo) => {
        let className = info.IsActive ? styles.AdjustmentsApplied : styles.AdjustmentsRejected
        let text = info.IsActive ? 'Applied' : 'Rejected'
        return <>:&nbsp;<span className={className}>{text}</span></>
    }

    renderAdjustmentsInfoTooltip = () => {
        let info = this.state.adjustmentsInfo
        if (!info) return null
        return <>
            <div>Author: {info.AuthorName}</div>
            <div>Last
                Change {info.LastChangeDate ? moment(info.LastChangeDate).format('L LT') : ''} by {info.LastChangeByName}</div>
            <div style={{
                marginTop: 8,
                textAlign: 'center',
                opacity: 0.6,
                fontSize: '90%'
            }}>Click to Open History
            </div>
        </>
    }

    TooltipInfoOnPosition = (e: any) => {
        let coords = e.targetElement?.closest('button')?.getBoundingClientRect()
        let tooltip = e.element
        let left = coords ? coords.left - (tooltip.offsetWidth / 2) + (coords.width / 2) : 0
        let top = coords ? coords.top - 5 - tooltip.offsetHeight : 0
        let right = left + (tooltip.offsetWidth)
        if (right > window.outerWidth) {
            left = left - (right - window.outerWidth)
        }
        return {
            left: left < 0 ? 0 : left,
            top: top < 0 ? 0 : top
        }
    }

    LoadData = async () => {
        if (!this.props.tcId) return
        try {
            this.setState({loading: true});
            const userInfo = await UserInfo.getInfo()
            this.ShowStateAllocation = !!userInfo?.ShowStateAllocation

            const data = await this.GetSQLData({
                spName: 'TK_GetTCSummary',
                params: {TCId: this.props.tcId}
            });
            if (!data) {
                return // TKAuditCover do remount timeCard several times
            }
            let tcInfo = GetPreparedInfo(data[0][0]);
            let payrolls = data[1];
            let nonwages = data[2];
            let timeEntries = data[3];
            let dayLog = data[4] as IOriginalTimeLineItemServer[];
            let dayLogPopup = data[5];
            let mileages = data[6]
            let adjustmentsInfo = data[7][0]
            let rareAdjustments = data[8] as IAdjustedTimeLineItemServer[]
            let adjustmentsAllocations = data[9]
            this.IsHideUnscheduledVisitAlert = data[10][0].IsHideUnscheduledVisitAlert
            const history = data[11]
            const {
                adjustments,
                drawOriginal,
                drawAdjusted,
                ...stateFields
            } = GetPreparedTimeLine(dayLog, dayLogPopup, rareAdjustments, adjustmentsAllocations, mileages)
            this.adjustments = adjustments
            this.setState({
                tcInfo,
                payrolls: this.GetPreparedPayrolls(payrolls),
                nonwages,
                history,
                timeEntries: this.GetPreparedTimeEntries(timeEntries),
                adjustmentsInfo,
                originalStateData: getStateData(drawOriginal, true),
                adjustedStateData: getStateData(drawAdjusted),
                drawOriginal,
                drawAdjusted,
                ...stateFields,
            })
        } finally {
            this.setState({loading: false});
        }
    }

    GetPreparedPayrolls = (payrolls?: Array<IPayroll>) => {
        if (!payrolls) return null;
        let payrollsTotal: IPayroll = {
            RegularHours: 0,
            OvertimeHours: 0,
            DoubleHours: 0,
            Total: 0,
            isTotal: true,
            StateId: null,
            StateName: null
        };

        for (let payroll of payrolls) {
            payroll.PWStr = payroll.PW === true ? 'Yes' : 'No'
            for (let key in payroll) {
                if (key === 'StateName' || key === 'StateId') continue
                // @ts-ignore
                let value = payroll[key]
                // @ts-ignore
                let totalValue = payrollsTotal[key]

                if (value !== null && value !== undefined && totalValue !== undefined) {
                    // @ts-ignore
                    payrollsTotal[key] += value;
                    // @ts-ignore
                    payrollsTotal.Total += value;
                    // @ts-ignore
                    payroll[key] = formatHoursDuration(value);
                }
            }
        }

        for (let key in payrollsTotal) {
            // @ts-ignore
            payrollsTotal[key] = formatHoursDuration(payrollsTotal[key]);
        }

        payrolls.push(payrollsTotal);
        return payrolls
    }

    GetPreparedTimeEntries = (timeentries: Array<ITimeEntry>) => {
        if (!timeentries.length) return []

        let timeentriesTotal: simpleObject = {
            Approved: 0,
            Clocked: 0,
        };

        for (let timeentry of timeentries) {
            let approvedHours = timeentry.Approved
            let hours = timeentry.Clocked
            if (approvedHours) {
                timeentriesTotal.Approved += approvedHours
                timeentry.Approved = formatHoursDuration(+approvedHours)
            }
            if (hours) {
                timeentriesTotal.Clocked += hours
                timeentry.Clocked = formatHoursDuration(+hours)
            }
        }

        timeentries.push({
            isTotal: true,
            Approved: formatHoursDuration(timeentriesTotal.Approved),
            Clocked: formatHoursDuration(timeentriesTotal.Clocked)
        });

        return timeentries
    }

    ShowAdjustmentsHistory = () => {
        const info = this.state.adjustmentsInfo
        const Id = info?.Id
        const tcInfo = this.state.tcInfo
        const title = tcInfo ? `${moment(tcInfo.Date).format("LL")} ${tcInfo.EmployeeName}` : ''
        if (Id) CardManagement.OpenRecordHistoryCard(Id, title)
    }

    ResetAdjustmentConfirmation = () => {
        ModalRef.showDialog({
            title: 'Confirmation',
            text: 'Do you confirm reset adjustments?',
            minWidth: 250,
            buttons: [
                {
                    text: 'Cancel',
                    action: () => {
                        ModalRef.hideDialog()
                    }
                },
                {
                    text: 'Yes',
                    color: 'primary',
                    action: () => {
                        ModalRef.hideDialog();
                        this.ResetAdjustment()
                    }
                },
            ]
        });
    }

    ResetAdjustment = async () => {
        let info = this.state.adjustmentsInfo
        if (!info) return
        try {
            this.setState({resetProcessing: true})
            await RunScriptAsync('TKAdjustments_Reset', {AdjID: info.Id})
            this.LoadData()
        } finally {
            this.setState({resetProcessing: false})
        }
    }

    EditAdjustment = async () => {
        CardManagement.OpenTCMapCard(this.props.tcId, true, this.Refresh)
    }

    ManageAdjustment = async () => {
        let info = this.state.adjustmentsInfo
        if (!info) return
        try {
            this.setState({manageProcessing: true})
            await RunScriptAsync('TKAdjustments_Manage', {
                AdjID: info.Id,
                Action: !info.IsActive ? 'Apply' : info.CanDelete ? 'Delete' : 'Reject'
            })
            this.LoadData()
        } finally {
            this.setState({manageProcessing: false})
        }
    }

    CreateAdjustment = async () => {
        CardManagement.OpenTCMapCard(this.props.tcId, true, this.Refresh)
    }

    Refresh = () => {
        this.LoadData()
    }
}

export default TImeCard;
