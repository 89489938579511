import moment from 'moment';
import {Component} from 'react';
import {GetAuthDataUrlParams} from '../../helpers/helpers';
import {GetReferenceRecordName} from '../../helpers/queries';
import DBeviceLogs from '../../Pages/Logs/DeviceLogs';
import FXCard from '../Common/FXCard/FXCard';
import {Button} from '@progress/kendo-react-buttons';
import {IFXCardProps} from './interfaces';
import styles from './card.module.scss'

interface props extends IFXCardProps {
    deviceId: number
    date: Date
}

interface state {
    Name: string
}

class DeviceLogsCard extends Component<props, state> {

    constructor(props: props) {
        super(props)
        this.state = {
            Name: 'Loading ...'
        }
    }

    componentDidMount() {
        this.LoadTitle()
    }

    render() {
        return (<FXCard
            title={<>
                <Button
                    className={styles.HeaderLinkBtn}
                    icon="hyperlink-open"
                    fillMode="flat"
                    onClick={this.OpenLogInNewTab}
                    title="Open Logs in a New Tab"
                ></Button>
                {this.state.Name}
            </>}
            onClose={this.props.finally}
        >
            <DBeviceLogs {...this.props} isModalCard={true}/>
        </FXCard>);
    }

    LoadTitle = async () => {
        let Name = await GetReferenceRecordName(+this.props.deviceId)
        this.setState({Name})
    }

    OpenLogInNewTab = async () => {
        let authParams = await GetAuthDataUrlParams()
        let href = `/?folder=DeviceLog&deviceId=${this.props.deviceId}`
        if (this.props.date) {
            href += `&date=${moment(this.props.date).format()}`
        }
        href += authParams
        //@ts-ignore
        window.open(href, '_blank').focus();
    }
}

export default DeviceLogsCard;
