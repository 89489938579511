export class Deferred {
  promise: any;
  reject: any;
  resolve: any;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject
      this.resolve = resolve
    })
  }
}
