import {Component} from 'react';
import {IAddCustomerJobCardProps} from './interfaces';
import {Input, InputChangeEvent} from '@progress/kendo-react-inputs';
import {Button} from '@progress/kendo-react-buttons';
import {RunScriptAsync} from '../../helpers/runscripts';
import Loader from '../Common/Loader';
import FXCard from '../Common/FXCard/FXCard';
import formStyles from './card.module.scss'
import FilterCombobox from '../Common/Form/FilterCombobox';
import {IComboboxItem} from '../../helpers/interfaces';

interface state {
  processing: boolean
  remountKey: number
  invalidForm: boolean
}

class AddCustomerJobCard extends Component<IAddCustomerJobCardProps, state> {
  name: string = ''

  constructor(props: IAddCustomerJobCardProps) {
    super(props)
    this.state = {
      processing: false,
      remountKey: +new Date(),
      invalidForm: true,
    }
  }

  render() {
    let Project: IComboboxItem = {
      Id: this.props.scenario.ProjectId,
      Name: this.props.scenario.ProjectName
    }
    return <FXCard
      title={<span className={formStyles.HeaderTitle}>New Customer Job</span>}
      onClose={this.Close}
      initialWidth={400}
      initialHeight={220}
      originalPaddings={true}
    >
      <div className={formStyles.FormWrapper}>
        {(this.state.processing) && <Loader/>}
        <FilterCombobox
          className={formStyles.FormField}
          placeholder="Customer"
          defaultValue={this.props.customer}
          data={[this.props.customer]}
          onChange={() => {
          }}
          disabled={true}
        />
        <FilterCombobox
          className={formStyles.FormField}
          placeholder="Jod Type"
          defaultValue={Project}
          data={[Project]}
          onChange={() => {
          }}
          disabled={true}
        />
        <Input
          key={this.state.remountKey + 'name'}
          className={`${formStyles.FormField} ${formStyles.TextField}`}
          placeholder="Name *"
          defaultValue={this.name}
          onChange={this.OnChangeTextField}
          name="CustomerJobName"
          required={true}
        />
        {this.renderFooter()}
      </div>
    </FXCard>
  }

  OnChangeTextField = (e: InputChangeEvent) => {
    this.name = e.value
    this.ValidateForm()
  }

  ValidateForm = () => {
    this.setState({invalidForm: !this.name})
  }

  renderFooter = () => {
    return <div className={`${formStyles.FormFooter} k-action-buttons`}>
			<span className={formStyles.InvalidMessage}>
				{this.state.invalidForm && <span>Fill all mandatory fields</span>}
			</span>
      <Button onClick={this.Close}>Cancel</Button>
      <Button
        onClick={this.CreateCustomerJob}
        themeColor="primary"
        disabled={this.state.invalidForm}
      >Create Customer Job</Button>
    </div>
  }

  CreateCustomerJob = async () => {
    try {
      this.setState({processing: true})
      let params = {
        CustomerId: this.props.customer.Id,
        CustomerJobName: this.name,
        ProjectId: this.props.scenario.ProjectId
      }
      let newCustomerJob = await RunScriptAsync('CustomersJobs_CreateNew', params);
      if (newCustomerJob && this.props.onFinish) {
        this.props.onFinish(newCustomerJob)
        this.Close()
      }
    } finally {
      this.setState({processing: false})
    }
  }

  Close = () => {
    this.props.finally!()
  }
}

export default AddCustomerJobCard;
