import {Component} from 'react';
import {simpleObject} from '../../helpers/interfaces'
import {formatFinancial} from '../../helpers/helpers'
import {IColumnSetting} from './interfaces'
import styles from './dashboard.module.scss'

interface props {
    gridCellProps: simpleObject,
    gridData: Array<any>,

    getColumn(field: string): IColumnSetting | undefined
}

class GridFooterCell extends Component<props, {}> {
    render() {
        let props = this.props.gridCellProps
        let inner: any = ''
        let style: simpleObject = {}
        let field = props.field || ''
        let column: IColumnSetting | undefined = this.props.getColumn(field)
        let value: any;
        if (column && column.aggregate) {
            let data = this.props.gridData
            if (column.aggregate === 'sum') {
                let format = column.format
                if (column.format && column.format.getCustomTotal) {
                    value = column.format.getCustomTotal(this.props.gridData, field)
                } else {
                    value = data.reduce((sum: number, current) => sum + (current[field] || 0), 0)
                }

                if (format?.type === 'currency') {
                    if (value !== null && value !== undefined) {
                        inner = <div className={styles.CurrencyBox}>
                            <span className={styles.Currency}>$</span>{formatFinancial(value)}
                        </div>
                    } else {
                        inner = ''
                    }
                } else if (value !== null && value !== undefined) {
                    style.textAlign = 'center'
                    inner = format?.type === 'integer' ? value.toFixed(0) : value.toFixed(2)
                }

                if (format && !isNaN(value)) {
                    if (value > 0 && format.positiveColor) {
                        style.color = format.positiveColor
                    } else if (value < 0 && format.negativeColor) {
                        style.color = format.negativeColor
                    }
                }

                if (
                    format?.type === 'icon' ||
                    format?.type === 'percent' || format?.type === 'number' || format?.type === 'integer' ||
                    format?.type === 'date' || format?.type === 'datetime' || format?.type === 'time'
                ) {
                    style.textAlign = 'center'
                }
            } else if (column.aggregate === 'count') {
                value = data.length
                inner = value
            }

            if (column.format && column.format.customFormat && column.format.getCustomTotalFormat) {
                inner = column.format.getCustomTotalFormat(data, props.field!, value)
            }
        }

        return <td
            style={{...props.style, ...style}}
            className={props.className}>
            {inner}
        </td>
    }
}

export default GridFooterCell
