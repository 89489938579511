import {Button, ToolbarSpacer} from '@progress/kendo-react-buttons';
import {
  Grid,
  GridColumn as Column,
  GridToolbar
} from '@progress/kendo-react-grid';
import {CompositeFilterDescriptor, process} from '@progress/kendo-data-query';
import BaseComponent from '../../Components/BaseComponent';
import OpenCardLink from '../../Components/Common/Buttons/OpenCardLink';
import Loader from '../../Components/Common/Loader';
import ComboboxFilter from '../../Components/Dashboard/ComboboxFilter';
import {simpleObject} from '../../helpers/interfaces';
import {GridRowHeight} from '../../Components/Dashboard/helpers';

interface props {
  recordId: number
}

interface state {
  loading: boolean;
  recordName: string
  gridData: { data: Array<simpleObject>, total: number }
}

class ReportRecordUsage extends BaseComponent<props, state> {
  filter: undefined | CompositeFilterDescriptor
  data: Array<simpleObject> = []
  group: any = [
    {field: "ReferenceName"},
    {field: "RequisiteName"},
  ]
  expandAllReference: boolean = true
  expandAllRequisite: boolean = true

  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      recordName: '',
      gridData: {data: [], total: 0}
    }
  }

  componentDidMount() {
    this.LoadData();
  }

  render() {
    if (this.state.loading) return <Loader/>;
    return (
      <Grid
        data={this.state.gridData}
        group={this.group}
        style={{height: '100vh'}}
        scrollable='scrollable'
        groupable={false}
        onExpandChange={this.ExpandChange}
        expandField="expanded"
        rowHeight={GridRowHeight}
        rowRender={(row, props) => {
          if (props.rowType !== "groupHeader") return row
          if (props.dataItem.field === "ReferenceName") {
            if (props.dataItem.value.indexOf('Reference') === -1) {
              let length = 0
              for (let list of props.dataItem.items) {
                length += list.items.length
              }
              props.dataItem.value = `Reference: ${props.dataItem.value} - ${length}`
            }
            return (
              // @ts-ignore
              <tr className={`${row.props.className}`} style={row.props.style}>
                {row.props.children[0]}
              </tr>)
          } else {
            if (props.dataItem.value.indexOf('Requisite') === -1) {
              let length = props.dataItem.items.length
              props.dataItem.value = `Requisite: ${props.dataItem.value} - ${length}`
            }

            return (
              // @ts-ignore
              <tr className={`${row.props.className}`} style={row.props.style}>
                {row.props.children[0]}
                {row.props.children[1]}
              </tr>)
          }

        }}
      >
        <GridToolbar>
          <Button
            title={`${this.expandAllReference ? 'Collapse' : 'Expand'}`}
            fillMode="flat"
            iconClass={`mdi mdi-${this.expandAllReference ? 'collapse-all-outline' : 'expand-all-outline'}`}
            onClick={() => {
              this.expandAllReference = !this.expandAllReference
              let data = this.state.gridData
              for (let group of data.data) {
                group.expanded = this.expandAllReference
              }
              this.setState({gridData: data})
            }}>{/* {`${this.expandAllReference ? 'Collapse' : 'Expand'}`} */} References</Button>
          <Button
            title={`${this.expandAllRequisite ? 'Collapse' : 'Expand'}`}
            fillMode="flat"
            iconClass={`mdi mdi-${this.expandAllRequisite ? 'collapse-all-outline' : 'expand-all-outline'}`}
            onClick={() => {
              this.expandAllRequisite = !this.expandAllRequisite
              let data = this.state.gridData
              for (let group of data.data) {
                for (let subGroup of group.items) {
                  subGroup.expanded = this.expandAllRequisite
                }
              }
              this.setState({gridData: data})
            }}>{/* {`${this.expandAllRequisite ? 'Collapse' : 'Expand'}`} */} Requisites</Button>
          <ComboboxFilter
            defaultValue={null}
            filter={{
              id: 'filterState',
              placeholder: 'Filter by Status',
              type: 'combobox',
              width: 105
            }}
            onChange={this.OnToolbarFilterChange}
            filterData={[
              {Name: 'Active', Id: 'A'},
              {Name: 'Closed', Id: 'C'}
            ]}
          />
          <ToolbarSpacer/>
          <div>Reference Record Usage "{this.state.recordName}"</div>
          <Button icon="reload" onClick={this.Refresh}></Button>
        </GridToolbar>
        <Column
          field="RecordName"
          title="Record Name"
          cell={(props) => {
            if (props.rowType === 'groupHeader') return null;
            return (
              <td><OpenCardLink
                text={props.dataItem.RecordName}
                refName={props.dataItem.ReferenceType}
                dataAttr={props.dataItem.RecordId}
              /></td>
            )
          }}
        />
        <Column
          field="IsActive"
          title="Status"
          width="80"
          cell={(props) => {
            if (props.rowType === 'groupHeader') return null;
            return (
              <td
                style={{textAlign: 'center'}}>{props.dataItem.IsActive ? 'Active' : 'Close'}</td>
            )
          }}
        />
      </Grid>
    )
  }

  OnToolbarFilterChange = (value: any) => {
    this.filter = value ? {
      filters: [{field: 'IsActive', operator: 'eq', value: value.Id === 'A'}],
      logic: 'and'
    } : undefined
    this.setState({
      gridData: process(this.data, {
        group: this.group,
        filter: this.filter
      })
    })
  }

  ExpandChange = (event: any) => {
    event.dataItem[event.target.props.expandField] = event.value;
    this.setState({});
  }

  async LoadData() {
    this.setState({loading: true,});
    try {
      let result: any = await this.GetSQLData({
        spName: 'Report_RecordUsage',
        params: {recordId: this.props.recordId /* 437656 */}
      });
      this.data = result[1]
      this.setState({
        recordName: result[0].RecordName,
        gridData: process(this.data, {group: this.group, filter: this.filter})
      });
    } catch (e) {
    } finally {
      this.setState({loading: false})
    }
  }

  Refresh = () => {
    this.LoadData();
  }

}

export default ReportRecordUsage;
