import {Chart, ChartLegend, ChartSeries, ChartSeriesItem,} from "@progress/kendo-react-charts";
import BaseComponent from '../BaseComponent';
import NoSelectedBP from '../Common/NoSelectedBP';
import Loader from '../Common/Loader';
import {simpleObject} from '../../helpers/interfaces';
import commonStyles from '../../assets/styles/common.module.scss';
import {getColorByCostType} from "../../Pages/TKReview/helpers";
import {costTypeCode} from "../../Pages/TKReview/interfaces";

interface ICostType {
    CostTypeCode: string
    CostTypeTitle: string
    Hours: number
}

interface ITimeType {
    Hours: number
    TimeTypeId: string
    TimeTypeName: string
}

interface IChartItem {
    value: string,
    category: string
    color: string
}

interface props {
    buildPlanId: number
}

interface state {
    loading: boolean
    costTypes: Array<IChartItem>
    timeTypes: Array<IChartItem>
}

const CHARTS_STYLE = {
    display: 'inline-block',
    width: '50%',
    verticalAlign: 'top',
    height: '150px',
    minWidth: '250px',
    maxWidth: '350px',
    margin: '5px 0 0',
};

class BPCostTypeCharts extends BaseComponent<props, state> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            costTypes: [],
            timeTypes: []
        }
    }

    componentDidMount() {
        if (this.props.buildPlanId) this.LoadData();
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.buildPlanId !== this.props.buildPlanId) {
            this.LoadData();
        }
    }

    render() {
        if (!this.props.buildPlanId) return <NoSelectedBP/>;
        if (this.state.loading) return <Loader/>;

        return <div className={commonStyles.TextCenter}>
            <Chart style={CHARTS_STYLE}>
                <ChartLegend position="left"/>
                <ChartSeries>
                    <ChartSeriesItem
                        type="pie"
                        data={this.state.costTypes}
                        field="value"
                        categoryField="category"
                        colorField="color"
                        labels={{visible: false}}
                    />
                </ChartSeries>
            </Chart>

            <Chart style={CHARTS_STYLE}>
                <ChartLegend position="right"/>
                <ChartSeries>
                    <ChartSeriesItem
                        type="pie"
                        data={this.state.timeTypes}
                        field="value"
                        categoryField="category"
                        colorField="color"
                        labels={{visible: false}}
                    />
                </ChartSeries>
            </Chart>
        </div>
    }

    LoadData = async () => {
        this.setState({loading: true})
        try {
            let params = {BuildPlanId: this.props.buildPlanId};
            let result: any = await this.GetSQLData({
                spName: 'DB_BPCostTypes',
                params
            });

            var timeTypeToColor: simpleObject = {
                R: '#88e292',
                O: '#ecef78',
                D: '#ef5252',
                P: '#777ce2'
            };
            let costTypes = result[0] || [];
            let timeTypes = result[1] || [];
            let costChartSumHours = costTypes.reduce((sum: number, current: ICostType) => sum + current.Hours, 0);
            let timeChartSumHours = timeTypes.reduce((sum: number, current: ITimeType) => sum + current.Hours, 0);
            let costTypesResult = costTypes.map((data: ICostType) => ({
                category: data.CostTypeTitle || "EMPTY",
                value: +(data.Hours / costChartSumHours * 100).toFixed(2),
                color: getColorByCostType(data.CostTypeCode as costTypeCode)
            }));
            let timeTypesResult = timeTypes.map((data: ITimeType) => ({
                category: data.TimeTypeName,
                value: +(data.Hours / timeChartSumHours * 100).toFixed(2),
                color: timeTypeToColor[data.TimeTypeId]
            }));
            this.setState({costTypes: costTypesResult, timeTypes: timeTypesResult});
        } finally {
            this.setState({loading: false})
        }
    }
}

export default BPCostTypeCharts;
