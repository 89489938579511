import {Component} from 'react';
import {GetAuthDataUrlParams} from '../../helpers/helpers';
import {GetReferenceRecordName} from '../../helpers/queries';
import TKTCLogs from '../../Pages/Logs/TKTCLogs';
import FXCard from '../Common/FXCard/FXCard';
import {Button} from '@progress/kendo-react-buttons';
import {IFXCardProps} from './interfaces';
import styles from './card.module.scss'

interface props extends IFXCardProps {
  tcId: number
}

interface state {
  Name: string
}

class TKLogsCard extends Component<props, state> {

  constructor(props: props) {
    super(props)
    this.state = {
      Name: 'Loading ...'
    }
  }

  componentDidMount() {
    this.LoadName()
  }

  render() {
    return (<FXCard
      title={<>
        <Button
          className={styles.HeaderLinkBtn}
          icon="hyperlink-open"
          fillMode="flat"
          onClick={this.OpenLogInNewTab}
          title="Open Logs in a New Tab"
        ></Button>
        {this.state.Name}
      </>}
      onClose={this.props.finally}
    >
      <TKTCLogs tcId={this.props.tcId} isModalCard={true}/>
    </FXCard>);
  }

  LoadName = async () => {
    let result = await GetReferenceRecordName(+this.props.tcId)
    this.setState({Name: result})
  }

  OpenLogInNewTab = async () => {
    let authParams = await GetAuthDataUrlParams()
    let href = `/?folder=TKTimeCardLog&tcId=${this.props.tcId}${authParams}`
    //@ts-ignore
    window.open(href, '_blank').focus();
  }

}

export default TKLogsCard;
