import moment from 'moment'
import {simpleObject} from '../../helpers/interfaces'
import OpenCardLink from '../Common/Buttons/OpenCardLink';
import {IFilterSetting} from "../Dashboard/interfaces";

export const formatDateColumn = (rowData: simpleObject) => {
    return <OpenCardLink
        text={moment(rowData.Date).format('L')}
        refName="TKTimeCards"
        dataAttr={rowData.TCId}
    />
}

export type TTCReviewStateId = 'P' | 'D' | 'A' | 'T' | 'C' | 'NotCompleted' | 'ActiveRequestState' | 'PendingTARs' |
    'PendingTimeOff' | 'PendingMisc' | 'HasReason' | 'HasAdjustedTime' | 'HasMealPremium' | 'ClosureAuditsState' |
    'MissingCostType' | 'MissingWOAllocation' | 'MissingPayrollItemAllocation';

export const filterByReviewStateSettings: IFilterSetting = {
    id: 'filterState',
    placeholder: 'Filter by Review State',
    type: 'combobox',
    values: [
        {Id: 'P', Name: 'Pending'},
        {Id: 'D', Name: 'Adjusted'},
        {Id: 'A', Name: 'Acknowledged'},
        {Id: 'T', Name: 'Change Time'},
        {Id: 'C', Name: 'Completed'},
        {Id: 'NotCompleted', Name: 'All not Completed'},
        {Id: 'ActiveRequestState', Name: 'Active Request'},
        {Id: 'PendingTARs', Name: 'Pending TARs'},
        {Id: 'PendingTimeOff', Name: 'Pending Time Off'},
        {Id: 'PendingMisc', Name: 'Pending Misc'},
        {Id: 'HasReason', Name: 'Has Reason'},
        {Id: 'HasAdjustedTime', Name: 'Has Adjusted Time'},
        {Id: 'HasMealPremium', Name: 'Has Meal Premium'},
        {Id: 'HasPW', Name: 'Has PW'},
        {Id: 'ClosureAuditsState', Name: 'Incomplete Allocation'},
        {Id: 'MissingCostType', Name: 'Missing Cost Type'},
        {Id: 'MissingWOAllocation', Name: 'Missing WO Allocation'},
        {Id: 'MissingPayrollItemAllocation', Name: 'Missing Payroll Item'},
    ],
    serverFilter: {
        param: 'reviewStateId',
    },
    toolbarRow: 2,
    width: 170
}
