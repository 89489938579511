import FXCard from '../Common/FXCard/FXCard'
import {ISiteCardProps} from './interfaces'
import {IAddressInfo, IEditableSiteInfo} from '../../Pages/ObjectMap/interfaces'
import ObjectMap from '../../Pages/ObjectMap/ObjectMap'
import formStyles from './card.module.scss'
import BaseComponent from '../BaseComponent'
import {Button, Toolbar} from '@progress/kendo-react-buttons'
import FilterCombobox from '../Common/Form/FilterCombobox'
import {
    Checkbox,
    CheckboxChangeEvent,
    Input,
    InputChangeEvent,
    TextArea,
    TextAreaChangeEvent
} from '@progress/kendo-react-inputs'
import Loader from '../Common/Loader'
import {IComboboxItem} from '../../helpers/interfaces'

import styles from '../../Pages/ObjectMap/objectmap.module.scss'
import {OpenRecord, RunScriptAsync} from '../../helpers/runscripts'
import {LoadCarriers, LoadMarkets} from '../../helpers/LoadLists'
import OpenCardIconLink from './OpenCardIconLink'
import {sortByNameCombobox} from '../../helpers/helpers'
import React from "react";
import CardManagement from "./CardManagement";
import UserInfo from "../../stores/User";
import {IServerSiteInfo} from "../Map/interfaces";

type tabId = 'General' | 'AccessProtocols'

interface state {
    lastResizeCard: number

    loadedInitialData: boolean
    contextMenu: null | { left: number; top: number }

    carriers: Array<IComboboxItem>
    markets: Array<IComboboxItem>

    processing: boolean
    remountKey: number
    invalidForm: boolean

    addressString: string
    activeTab: tabId
}

const tabContainerStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'absolute',
    left: 8,
    right: 8,
    top: 0,
    bottom: 0,
    zIndex: -1,
    opacity: 0
}

const tabContainerActiveStyles: React.CSSProperties = {
    zIndex: 2,
    opacity: 1
}

class SiteCard extends BaseComponent<ISiteCardProps, state> {
    isNewSiteCard = this.props.siteId === undefined
    EditableSiteInfo: IEditableSiteInfo = {
        IsCustomName: true,
        SiteId: '',
        SiteName: '',
        Market: null,
        Region: null,
        Carrier: null,
        Address: null,
        DirectionsFromLocalOffice: '',
        SpecialAccessInstruction: '',
        Notes: '',
    }

    ServerSiteInfo: IServerSiteInfo | null = null
    canGenerateBP: boolean = false

    constructor(props: ISiteCardProps) {
        super(props)
        this.state = {
            lastResizeCard: +new Date(),

            loadedInitialData: false,
            contextMenu: null,

            carriers: [],
            markets: [],

            addressString: '',

            processing: false,
            remountKey: +new Date(),
            invalidForm: true,
            activeTab: 'General',
        }
    }

    componentDidMount(): void {
        this.LoadInitialData()
    }

    render() {
        return (
            <FXCard
                title={this.renderCardTitle()}
                onClose={this.Close}
                initialWidth={730}
                initialHeight={750}
                originalPaddings={false}
                onResize={this.OnCardResize}
                onStageChange={this.OnCardResize}>
                {!this.state.loadedInitialData ? (
                    <Loader/>
                ) : (
                    <>
                        {this.state.processing && <Loader/>}
                        <Toolbar style={{marginBottom: 8, flex: '0 0 auto'}}>
                            <Button
                                themeColor={
                                    this.state.activeTab === 'General' ? 'primary' : undefined
                                }
                                id={'General'}
                                onClick={this.ChangeTab}>
                                General
                            </Button>
                            <Button
                                themeColor={
                                    this.state.activeTab === 'AccessProtocols'
                                        ? 'primary'
                                        : undefined
                                }
                                id={'AccessProtocols'}
                                onClick={this.ChangeTab}>
                                Access Protocols
                            </Button>
                        </Toolbar>
                        <div
                            className={formStyles.FormWrapper}
                            style={{position: 'relative'}}>
                            <div
                                style={{
                                    ...tabContainerStyles,
                                    ...(this.state.activeTab === 'General'
                                        ? tabContainerActiveStyles
                                        : {})
                                }}>
                                <div style={{display: 'flex'}}>
                                    <div className={styles.Left}>
                                        <Input
                                            key={this.state.remountKey + 'siteId'}
                                            className={`${formStyles.FormField} ${formStyles.TextField}`}
                                            placeholder="Site ID *"
                                            defaultValue={this.EditableSiteInfo.SiteId}
                                            onChange={this.OnChangeTextField}
                                            name="SiteId"
                                            required={true}
                                        />
                                        {this.EditableSiteInfo.IsCustomName && <Input
                                            key={this.state.remountKey + 'sitename'}
                                            className={`${formStyles.FormField} ${formStyles.TextField}`}
                                            placeholder="Site Name"
                                            defaultValue={this.EditableSiteInfo.SiteName}
                                            name="SiteName"
                                            onChange={this.OnChangeTextField}
                                            disabled={!this.EditableSiteInfo.IsCustomName}
                                        />}
                                        <div className={formStyles.CheckboxRow}>
                                            <Checkbox
                                                defaultValue={this.EditableSiteInfo.IsCustomName}
                                                label={'Use Custom Name'}
                                                onChange={this.OnChangeUseCustomName}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.Right}>
                                        <FilterCombobox
                                            key={this.state.remountKey + 'market'}
                                            placeholder="Market *"
                                            defaultValue={this.EditableSiteInfo.Market}
                                            data={this.state.markets}
                                            onChange={this.OnChangeField}
                                            className={`${formStyles.FormField}`}
                                            dataAttr={'Market'}
                                            required={true}
                                        />
                                        <FilterCombobox
                                            key={this.state.remountKey + 'carrier'}
                                            placeholder="Carrier *"
                                            defaultValue={this.EditableSiteInfo.Carrier}
                                            data={this.state.carriers}
                                            onChange={this.OnChangeField}
                                            className={`${formStyles.FormField}`}
                                            dataAttr={'Carrier'}
                                            required={true}
                                        />

                                    </div>
                                </div>
                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        position: 'relative'
                                    }}>
                                    <ObjectMap
                                        lastResize={this.state.lastResizeCard}
                                        addressId={this.ServerSiteInfo?.AddressId}
                                        coordinates={this.props.coordinates}
                                        onChange={this.OnAddressChange}
                                        objectType={'Site'}
                                    />
                                </div>
                            </div>

                            <div
                                style={{
                                    ...tabContainerStyles,
                                    ...(this.state.activeTab === 'AccessProtocols'
                                        ? tabContainerActiveStyles
                                        : {})
                                }}>
                                <div style={{marginBottom: 4}}>Special Access Instruction:</div>
                                <TextArea
                                    key={this.state.remountKey + 'SpecialAccessInstruction'}
                                    className={`${formStyles.FormField} ${formStyles.TextArea}`}
                                    style={{height: 130}}
                                    defaultValue={this.EditableSiteInfo.SpecialAccessInstruction}
                                    name="SpecialAccessInstruction"
                                    onChange={this.OnChangeTextField}></TextArea>
                                <div style={{marginBottom: 4, marginTop: 4}}>
                                    Directions from Local Office:
                                </div>
                                <TextArea
                                    key={this.state.remountKey + 'DirectionsFromLocalOffice'}
                                    className={`${formStyles.FormField} ${formStyles.TextArea}`}
                                    style={{height: 130}}
                                    defaultValue={this.EditableSiteInfo.DirectionsFromLocalOffice}
                                    name="DirectionsFromLocalOffice"
                                    onChange={this.OnChangeTextField}></TextArea>
                                <div style={{marginBottom: 4, marginTop: 4}}>Notes:</div>
                                <TextArea
                                    key={this.state.remountKey + 'Notes'}
                                    className={`${formStyles.FormField} ${formStyles.TextArea}`}
                                    style={{height: 130}}
                                    defaultValue={this.EditableSiteInfo.Notes}
                                    name="Notes"
                                    onChange={this.OnChangeTextField}></TextArea>
                            </div>
                        </div>
                        {this.renderFooter()}
                    </>
                )}
            </FXCard>
        )
    }

    renderCardTitle = () => {
        let content: any;
        if (this.isNewSiteCard) content = 'New Site'
        else {
            content = <>
                <OpenCardIconLink
                    onClick={this.OpenSite}
                    title="Open Site Card"
                />
                <span className={styles.HeaderTitle}>
                  {!this.state.loadedInitialData
                      ? 'Loading...'
                      : this.ServerSiteInfo?.SiteId || 'Edit Site'}
                </span>
            </>
        }
        return <span className={formStyles.HeaderTitle}>{content} </span>
    }

    renderFooter = () => {
        return (
            <div
                className={`${formStyles.FormFooter} k-action-buttons`}
                style={{paddingLeft: 8, paddingRight: 8}}
            >
                <span className={formStyles.InvalidMessage}>
                  {this.state.invalidForm && <span>Fill all mandatory fields</span>}
                </span>
                <Button onClick={this.Close}>Cancel</Button>
                {this.canGenerateBP && <Button
                    onClick={this.isNewSiteCard ? this.CreateSiteAndGBP : this.SaveSiteAndGBP}
                    themeColor="primary"
                    disabled={this.state.invalidForm}>
                    {this.isNewSiteCard ? 'Create ' : 'Save '}and Generate BP
                </Button>}
                {this.isNewSiteCard ? (
                    <Button
                        onClick={this.OnClickCreateSite}
                        themeColor="primary"
                        disabled={this.state.invalidForm}>
                        Create Site
                    </Button>
                ) : (
                    <Button
                        onClick={this.OnClickSaveSite}
                        themeColor="primary"
                        disabled={this.state.invalidForm}>
                        Save
                    </Button>
                )}
            </div>
        )
    }

    ChangeTab = (e: any) => {
        let tabId = e.currentTarget.id
        let activeTab = this.state.activeTab
        if (activeTab !== tabId) this.setState({activeTab: tabId})
    }

    OnChangeTextField = (e: InputChangeEvent | TextAreaChangeEvent) => {
        let field = e.target.name! as keyof IEditableSiteInfo; //: 'SiteId' | 'SiteName' | 'Notes' | 'SpecialAccessInstruction' | 'DirectionsFromLocalOffice'
        this.OnChangeField(e.value, field)
    }

    OnChangeUseCustomName = (e: CheckboxChangeEvent) => {
        this.EditableSiteInfo.IsCustomName = e.value
        this.EditableSiteInfo.SiteName = this.EditableSiteInfo.SiteId
        this.setState({remountKey: +new Date()})
    }

    OnChangeField = (value: any, dataAttr: keyof IEditableSiteInfo) => {
        // @ts-ignore
        this.EditableSiteInfo[dataAttr] = value
        if (dataAttr === 'Market') {
            if (value) {
                this.EditableSiteInfo.Region = {Id: value.HighLvlId, Name: value.HighLvlName}
                this.setState({remountKey: +new Date()})
            }
        } else if (dataAttr === 'SiteId' && !this.EditableSiteInfo.IsCustomName) {
            this.EditableSiteInfo.SiteName = value
        }
        this.setState({invalidForm: this.IsInValidForm()})
    }

    IsInValidForm = () => {
        if (this.isNewSiteCard) {
            return (
                !this.EditableSiteInfo.SiteId ||
                !this.EditableSiteInfo.Market ||
                !this.EditableSiteInfo.Region ||
                !this.EditableSiteInfo.Carrier ||
                !this.EditableSiteInfo.Address
            )
        } else {
            return (
                !this.EditableSiteInfo.SiteId ||
                !this.EditableSiteInfo.Market ||
                !this.EditableSiteInfo.Region ||
                !this.EditableSiteInfo.Carrier
            )
        }
    }

    LoadInitialData = async () => {
        try {
            this.canGenerateBP = await UserInfo.canGenerateBP()
            let result: Array<PromiseSettledResult<any>> = await Promise.allSettled([
                LoadMarkets(),
                LoadCarriers(),
                this.LoadSiteData()
            ])
            let [marketsResult, carriersResult, siteDataResult] = result
            let [markets, carriers, siteInfo] = [
                // @ts-ignore
                marketsResult.value || [],
                // @ts-ignore
                carriersResult.value || [],
                // @ts-ignore
                siteDataResult.value
            ]
            if (!this.isNewSiteCard && siteInfo) {
                this.ProcessingSiteData(siteInfo)
            }
            markets.forEach((item: IComboboxItem) => {
                item.Name = this.GetMarketName(item.HighLvlName!, item.Name)
            })
            markets.sort(sortByNameCombobox)
            this.setState({
                markets,
                carriers,
                invalidForm: this.IsInValidForm(),
                remountKey: +new Date()
            })
        } finally {
            this.setState({loadedInitialData: true})
        }
    }

    GetMarketName = (regionName: string, marketName: string) =>
        `${regionName}: ${marketName}`

    ProcessingSiteData = (siteInfo: IServerSiteInfo) => {
        this.ServerSiteInfo = siteInfo
        this.EditableSiteInfo.SiteId = siteInfo.SiteId || ''
        this.EditableSiteInfo.SiteName = siteInfo.SiteName || ''
        this.EditableSiteInfo.IsCustomName = siteInfo.IsCustomName
        if (siteInfo.CarrierId && siteInfo.CarrierName)
            this.EditableSiteInfo.Carrier = {
                Id: siteInfo.CarrierId,
                Name: siteInfo.CarrierName
            }
        if (
            siteInfo.MarketId &&
            siteInfo.MarketName &&
            siteInfo.RegionId &&
            siteInfo.RegionName
        ) {
            this.EditableSiteInfo.Market = {
                Id: siteInfo.MarketId,
                Name: this.GetMarketName(siteInfo.RegionName, siteInfo.MarketName)
            }
            this.EditableSiteInfo.Region = {
                Id: siteInfo.RegionId,
                Name: siteInfo.RegionName
            }
        }
        this.EditableSiteInfo.Notes = siteInfo.Notes || ''
        this.EditableSiteInfo.SpecialAccessInstruction =
            siteInfo.SpecialAccessInstruction || ''
        this.EditableSiteInfo.DirectionsFromLocalOffice =
            siteInfo.DirectionsFromLocalOffice || ''
    }

    LoadSiteData = async () => {
        if (this.isNewSiteCard) return null
        let result: any = await this.GetSQLData({
            spName: 'FSMSites_GetData',
            params: {siteId: this.props.siteId}
        })
        return result[0][0] as IServerSiteInfo
    }

    OpenSite = () => {
        if (this.props.siteId) OpenRecord('FSMSites', this.props.siteId)
    }

    CreateSiteAndGBP = () => this.CreateSite(true)

    SaveSiteAndGBP = () => this.SaveSite(true)

    OnClickCreateSite = () => this.CreateSite()

    OnClickSaveSite = () => this.SaveSite()

    CreateSite = async (gbp?: boolean) => {
        try {
            this.setState({processing: true})
            let newSiteId = await RunScriptAsync('FSMSites_Upsert', {
                SiteJSON: this.GetSiteJSON()
            })
            if (this.props.onFinish) this.props.onFinish(newSiteId)
            if (gbp) {
                CardManagement.OpenGBPCard({siteId: newSiteId})
            }
            this.Close()
        } finally {
            this.setState({processing: false})
        }
    }

    OnAddressChange = (
        AddressInfo: IAddressInfo | null
    ) => {
        this.EditableSiteInfo.Address = AddressInfo
        this.setState({
            invalidForm: this.IsInValidForm()
        })
    }

    GetSiteJSON = () => {
        const {
            IsCustomName,
            SiteName,
            SiteId,
            DirectionsFromLocalOffice,
            SpecialAccessInstruction,
            Notes,
            Carrier,
            Region,
            Market,
            Address,
        } = this.EditableSiteInfo;

        return JSON.stringify({
            IsCustomName,
            SiteName,
            SiteId,
            DirectionsFromLocalOffice,
            SpecialAccessInstruction,
            Notes,
            CarrierId: Carrier?.Id,
            RegionId: Region?.Id,
            MarketId: Market?.Id,
            AddressJSON: JSON.stringify(Address),
        })
    }

    SaveSite = async (gbp?: boolean) => {
        try {
            this.setState({processing: true})

            await RunScriptAsync('FSMSites_Upsert', {
                SiteId: this.props.siteId,
                SiteJSON: this.GetSiteJSON()
            })
            if (this.props.onFinish && this.props.siteId) {
                this.props.onFinish(this.props.siteId)
            }
            if (gbp) {
                CardManagement.OpenGBPCard({siteId: this.props.siteId})
            }
            this.Close()
        } catch (e) {
            // console.log(e)
        } finally {
            this.setState({processing: false})
        }
    }

    OnCardResize = () => {
        this.setState({lastResizeCard: +new Date()})
    }

    Close = () => {
        this.props.finally!()
    }
}

export default SiteCard
