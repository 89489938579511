import FXCard from '../Common/FXCard/FXCard';
import {IFXCardProps} from './interfaces';
import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import {windowStage} from "@progress/kendo-react-dialogs/dist/npm/StageEnum";
import {WindowActionsEvent} from "@progress/kendo-react-dialogs";
import TCMap from "../TC/Map";
import {ITCInfo} from "../../Pages/LiveMap/interfaces";
import SideBarToggleBtn from "../SideBar/SideBarToggleBtn";

interface IProps extends IFXCardProps {
    tcId: number
    adjustmentsPanelOpened: boolean
    tcRefresh?: () => void
}

const TCMapCard = (props: IProps) => {
    const {tcId} = props
    const defaultWindowStage = props.adjustmentsPanelOpened ? windowStage.DEFAULT : windowStage.FULLSCREEN
    const [title, setTitle] = useState('')
    const [stage, setStage] = useState<string>(defaultWindowStage)
    const [isMapHidden, setMapHidden] = useState(props.adjustmentsPanelOpened)
    const containerRef = useRef<HTMLDivElement | null>(null)
    const [boxHeight, setBoxHeight] = useState(0)

    useEffect(() => {
        setBoxHeight(containerRef.current?.clientHeight || 100)
    }, [containerRef.current])

    const onCardStageChange = (stage: WindowActionsEvent) => {
        setStage(stage.state || windowStage.FULLSCREEN)
    }

    const onClose = () => {
        if (props.finally) props.finally()
    }

    const onLoadDetails = (info: ITCInfo) => {
        setTitle(`${moment(info.Date).format("LL")} ${info.Employee}`)
    }

    const toggleMap = () => {
        const value = !isMapHidden
        setMapHidden(value)
        setStage(value ? windowStage.DEFAULT : windowStage.FULLSCREEN)
    }

    const renderToggleMapBtn = () => {
        if (!props.adjustmentsPanelOpened) return null
        return <div style={{width: 30, position: "relative"}}>
            <SideBarToggleBtn
                toggle={toggleMap}
                btnText={'Map'}
                boxHeight={boxHeight}
                opened={!isMapHidden}
                rtl={true}
            />
        </div>
    }

    return (<FXCard
        title={title || 'Loading...'}
        onClose={onClose}
        stage={stage}
        onStageChange={onCardStageChange}
        initialWidth={890}
    >
        <div
            ref={containerRef}
            style={{width: '100%', height: '100%', display: 'flex'}}
        >
            {renderToggleMapBtn()}
            <div style={{flex: 1}}>
                <TCMap
                    tcId={tcId}
                    adjustmentsPanelOpened={props.adjustmentsPanelOpened}
                    onLoadTCMapDetails={onLoadDetails}
                    tcRefresh={props.tcRefresh}
                    isMapHidden={isMapHidden}
                    toggleMapBtn={renderToggleMapBtn()}
                    modalTCMapWindowStage={stage}
                /></div>
        </div>
    </FXCard>);
}

export default TCMapCard;
