import moment from 'moment';
import React, {Component} from 'react';
import {simpleObject} from '../../helpers/interfaces';
import OpenCardLink from '../Common/Buttons/OpenCardLink';
import DashboardGrid from '../Dashboard/Grid';
import {IColumnSetting} from '../Dashboard/interfaces';

interface props {
  buildPlanId?: number
  isActive: boolean

  onChangeGridData?(data: Array<simpleObject>): void
}

class CODSummary extends Component<props> {
  gridRef: any = React.createRef()
  bpId = this.props.buildPlanId

  render() {
    if (this.props.isActive && this.bpId !== this.props.buildPlanId) {
      this.bpId = this.props.buildPlanId
    }

    if (!this.props.buildPlanId || !this.props.isActive && this.bpId !== this.props.buildPlanId) return null
    return <DashboardGrid
      ref={this.gridRef}
      isActive={this.props.isActive}
      key={this.props.buildPlanId}
      columnsSettings={this.COLUMNS}
      getSqlParams={{
        spName: 'DB_BuildPlanCOD',
        params: {buildPlanId: this.props.buildPlanId}
      }}
      initRefresh={this.InitRefresh}
      excelFileName="COD Purchases.xlsx"
      columnMenu={{
        filters: true,
        columns: false
      }}
      getRowKey={(dataItem: simpleObject) => dataItem.Id}
      onChangeGridData={this.props.onChangeGridData}
    />
  }

  Refresh = () => {
  }

  COLUMNS: Array<IColumnSetting> = [
    {
      field: 'PurchaseDate',
      title: 'Purchase Date',
      type: 'date',
      gridWidth: 115,
      format: {
        type: 'link',
        fieldId: 'Id',
        refName: 'ExpenseCreditCards',
        customFormat: true,
        getCustomFormat: (dataItem: simpleObject) => {
          return <OpenCardLink
            text={moment(dataItem.PurchaseDate).format('L')}
            refName="ExpenseCreditCards"
            dataAttr={dataItem.Id}
            refresh={this.Refresh}
          />
        }
      }
    },
    {
      field: 'AccountName',
      title: 'Account',
      type: 'string',
      format: {
        type: 'link',
        fieldId: 'AccountId',
        refName: 'Cards',
      }
    },
    {
      field: 'AccountTypeName',
      title: 'Account Type',
      type: 'string',
      format: {
        type: 'string',
        fieldId: 'AccountTypeID'
      }
    },
    {
      field: 'CategoryName',
      title: 'Purchase Category',
      type: 'string',
      format: {
        type: 'string',
        fieldId: 'CategoryID'
      }
    },
    {
      field: 'FinancialAccount',
      title: 'Financial Account',
      type: 'string',
      format: {
        type: 'string',
        fieldId: 'FinancialAccountID'
      }
    },
    {
      field: 'Store',
      title: 'Store',
      type: 'string',
      format: {
        type: 'string',
        fieldId: 'Store'
      }
    },
    {
      field: 'Amount',
      title: 'Total Amount',
      type: 'number',
      gridWidth: 150,
      aggregate: 'sum',
      format: {
        type: 'currency',
      }
    },
    {
      field: 'Stage',
      title: 'Stage',
      type: 'string',
      format: {
        type: 'string',
        fieldId: 'Stage'
      }
    }
  ]

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh
  }
}

export default CODSummary;
