import {Range} from '@progress/kendo-react-inputs';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import BaseComponent from '../../Components/BaseComponent';
import styles from './dashboard.module.scss';
import {IFilterSetting} from './interfaces';

interface props {
    defaultValue: Range
    filter: IFilterSetting

    onChange(value: Range, filter: IFilterSetting): void
}

interface state {
    rangeValue: Range
}

class RangeTimeFilter extends BaseComponent<props, state> {
    constructor(props: props) {
        super(props)
        this.state = {
            rangeValue: this.props.defaultValue || {start: 0, end: 24},
        }
    }

    render() {
        return <div className={styles.TimeRangeSlider}>
            <Slider
                range={true}
                step={1}
                min={0}
                max={24}
                defaultValue={[this.props.defaultValue?.start || 0, this.props.defaultValue?.end || 23]}
                allowCross={false}
                onAfterChange={this.OnChangeEnd}
                onChange={this.OnChange}
                handleStyle={{borderColor: '#2196f3', boxShadow: "none"}}
            />
            <div className={styles.TimeRangeSliderValue}>
                {this.GetTime(this.state.rangeValue.start)} - {this.GetTime(this.state.rangeValue.end)}
            </div>
        </div>
    }

    GetTime = (hour: number) => {
        if (hour === 0) {
            return '12 AM'
        } else if (hour < 12) {
            return hour + ' AM'
        } else if (hour === 12) {
            return '12 PM'
        } else if (hour === 24) {
            return '12 AM'
        } else {
            return (hour - 12) + ' PM'
        }
    }

    OnChange = (e: any) => {
        let rangeValue: Range = {start: e[0], end: e[1]}
        this.setState({rangeValue})
    }

    OnChangeEnd = (e: any) => {
        let rangeValue: Range = {start: e[0], end: e[1]}
        this.props.onChange(rangeValue, this.props.filter)
    }
}

export default RangeTimeFilter;
