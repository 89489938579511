import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesDefaults,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import {Grid, GridColumn as Column} from '@progress/kendo-react-grid';
import {Button, Toolbar, ToolbarSpacer} from '@progress/kendo-react-buttons';
import BaseComponent from '../../Components/BaseComponent';
import FilterComboBox from '../../Components/Common/Form/FilterCombobox';
import Loader from '../../Components/Common/Loader';
import moment from 'moment';
import {IComboboxItem, simpleObject} from '../../helpers/interfaces';
import {formatFinancial, formatFinancialRound} from '../../helpers/helpers';
import ClearableDateFilter
  from '../../Components/Common/Form/ClearableDateFilter';
import dashboardStyles from '../../Components/Dashboard/dashboard.module.scss'
import styles from './executive.module.scss'
import commonStyles from '../../assets/styles/common.module.scss'

interface IRow {
  MonthToDate?: number
  Name: string
  ThisDay?: number
  YearToDate?: number
  isRowBold?: boolean
  paddingLeft?: boolean
  isColored?: boolean
}

const COLORS = {
  margin: '#000000',
  net: '#4b8ab9',
  pl: '#4cc124'
};

interface ISeriesItem {
  type?: 'column'
  name: string
  color: string
  colorField?: string
  field?: string
  data: Array<number | null>,
  tooltip: {
    visible: boolean,
    format: string
  },
  markersAverage?: boolean,
  visibleInLegend?: boolean
}

interface state {
  loading: boolean
  profitCenters: Array<IComboboxItem> | null
  projects: Array<IComboboxItem> | null
  classes: Array<IComboboxItem> | null
  customers: Array<IComboboxItem> | null
  bpOwners: Array<IComboboxItem> | null
  gridData: Array<IRow>
  // chart
  months: Array<string>
  margins: Array<string>
  plData: Array<string>
  wipData: Array<string>
  netData: Array<string>
  series: Array<ISeriesItem>
  minValue: number
  remountKey: number
}

interface IComboboxFilterSetting {
  id: string
  placeholder: string
  param: string
  dataStateKey: keyof state
}

const comboboxFilters: Array<IComboboxFilterSetting> = [
  {
    id: 'BpOwner',
    placeholder: 'Select Build Plan Owner',
    param: 'bpOwnerId',
    dataStateKey: 'bpOwners'
  },
  {
    id: 'ProfitCenter',
    placeholder: 'Select Profit Center',
    param: 'profitCenterId',
    dataStateKey: 'profitCenters'
  },
  {
    id: 'Project',
    placeholder: 'Select Project',
    param: 'projectId',
    dataStateKey: 'projects'
  },
  {
    id: 'Customer',
    placeholder: 'Select Customer',
    param: 'customerId',
    dataStateKey: 'customers'
  },
  {
    id: 'Class',
    placeholder: 'Select Class',
    param: 'classId',
    dataStateKey: 'classes'
  },
]

const localStorageDateKey = 'Executive-Date'
const DATE_FORMAT = 'YYYY-MM-DDT00:00:00'

class ExecutiveDailyDashboard extends BaseComponent<{}, state> {
  filters: { [key: string]: IComboboxItem | null } = {}
  date: string | null

  constructor(props: any) {
    super(props)
    this.state = {
      loading: false,
      profitCenters: null,
      projects: null,
      classes: null,
      customers: null,
      bpOwners: null,
      gridData: [],
      // chart
      months: [],
      margins: [],
      plData: [],
      wipData: [],
      netData: [],
      series: [],
      minValue: -100,
      remountKey: +new Date()
    }

    let date;
    let savedDate = localStorage.getItem(localStorageDateKey)
    if (savedDate) {
      let dateInfo = JSON.parse(savedDate)
      if (moment().format('L') === dateInfo.expired) date = dateInfo.date
    }
    if (!date) date = this.GetDefaultDay()
    this.date = date

    comboboxFilters.forEach((filter) => {
      let savedValue = localStorage.getItem(this.GetLocalStorageFilterKey(filter))
      if (savedValue) this.filters[filter.id] = JSON.parse(savedValue)
    })
  }

  componentDidMount() {
    this.LoadData();
  }

  render() {
    let IsFiltersInDefault = this.IsFiltersInDefault()

    return <div className={commonStyles.ScreenHeightContainerWithToolbar}>
      {this.state.loading && <Loader/>}
      <Toolbar>
        {comboboxFilters.map((filter) => <FilterComboBox<IComboboxItem>
          key={this.state.remountKey + filter.id}
          width={230}
          placeholder={filter.placeholder}
          defaultValue={this.filters[filter.id]}
          /* @ts-ignore */
          data={this.state[filter.dataStateKey] || []}
          onChange={this.OnComboboxChange}
          dataAttr={filter}
        />)}
        <ClearableDateFilter
          key={this.state.remountKey + 'date'}
          defaultValue={this.date ? moment(this.date).toDate() : undefined}
          filter=''
          onChange={this.OnChangeDate}
          clear={this.OnClearDate}
        />
        <ToolbarSpacer/>
        <Button
          onClick={this.SetDefaultFilters}
          title="Set Default Filters"
          icon="filter-clear"
          className={IsFiltersInDefault ? '' : dashboardStyles.BlueResetBtn}
        />
        <Button
          icon="refresh"
          onClick={this.Refresh}
        />
      </Toolbar>
      <div
        className={`${styles.Container} ${commonStyles.ScrollableContainer}`}>
        <Grid
          data={this.state.gridData}
          className={`${commonStyles.SmallKendoGrid} ${styles.Grid} executive-grid`}
          scrollable="none"
          rowRender={this.renderRow}
        >
          <Column
            field="Name" // ??
            title="P&L"
            width={350}
          />
          <Column
            field="ThisDay"
            title="This Day"
            width={100}
            cell={this.renderCell}
          />
          <Column
            field="MonthToDate"
            title="Month To Date"
            width={100}
            cell={this.renderCell}
          />
          <Column
            field="YearToDate"
            title="Past 12 month"
            width={100}
            cell={this.renderCell}
          />
        </Grid>
        {/* <div className={styles.scaleBtns}><button id="scalePlus" title="Scale +" ><span ></span></button><button id="scaleNo" title="No Scale"><span ></span></button><button id="scaleMinus" title="Scale -" ><span ></span></button></div> */}

        <Chart
          style={{marginBottom: '15px'}}
          zoomable={true}
          pannable={true}
          onRender={(e: any) => {
            let el = e.target.getTarget().element
            let ch = el && el.children && el.children[0]
            let chD = ch && ch.children && ch.children[1]
            let drawWrapper = document.querySelector('.k-drawer-wrapper')
            let drawWrapperWidth = drawWrapper && drawWrapper.clientWidth
            let grid = document.querySelector('.executive-grid')
            if (chD && chD.children && drawWrapperWidth && grid) {
              let left = chD.children[1].getBoundingClientRect().left - drawWrapperWidth - 1
              // @ts-ignore
              grid.style.marginLeft = left + 'px';
            }
          }}>
          <ChartSeriesDefaults type="line"/>
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              categories={this.state.months}
              axisCrossingValue={[0, 13]}
              line={{width: 3, color: '#656565'}}
              labels={{visible: false}}
              majorTicks={{visible: false}}
            />
            <ChartCategoryAxisItem
              name='month'
              categories={this.state.months}
              axisCrossingValue={[0, 13]}
              labels={{font: '300 normal 12px sans-serif', margin: {top: -5}}}
              majorTicks={{size: 5}}
            />
            <ChartCategoryAxisItem
              name='Percent'
              categories={this.state.margins}
              axisCrossingValue={[0, 13]}
              title={this.getTitleSettings('GM \n Percent', 1, this.state.margins)}
              labels={this.GetLabelSettings('Percent')}
              majorTicks={{size: 30}}

            />
            <ChartCategoryAxisItem
              name='P&L'
              categories={this.state.plData}
              axisCrossingValue={[0, 13]}
              title={this.getTitleSettings('P&L', 2, this.state.plData)}
              majorTicks={{size: 30}}
              labels={this.GetLabelSettings()}
            />
            <ChartCategoryAxisItem
              name='WIPDelta'
              categories={this.state.wipData}
              axisCrossingValue={[0, 13]}
              title={this.getTitleSettings('WIP \n Delta', 3, this.state.wipData)}
              labels={this.GetLabelSettings('WIPDelta')}
              majorTicks={{size: 30}}
            />
            <ChartCategoryAxisItem
              name='AdjustedP&L'
              categories={this.state.netData}
              axisCrossingValue={[0, 13]}
              title={this.getTitleSettings('Adjusted \n P&L', 4, this.state.netData)}
              labels={this.GetLabelSettings()}
              majorTicks={{size: 30}}
            />
          </ChartCategoryAxis>

          <ChartLegend position="top" align="end"/>

          <ChartSeries>
            {this.state.series.map((item) => (
              <ChartSeriesItem
                key={item.name}
                name={item.name}
                type={item.type}
                data={item.data}
                color={item.color}
                missingValues="gap"
                colorField={item.colorField}
                field={item.field}
                visibleInLegend={item.visibleInLegend}
                highlight={item.markersAverage ? {visible: false} : undefined}
                markers={item.markersAverage ? {
                  type: 'triangle',
                  visible: true,
                  background: item.color,
                  size: 20,
                  rotation: 90,
                  border: {
                    width: 0,
                  },
                  visual: function (e) {
                    var el = e.createVisual();
                    let shiftSize = e.series.tooltip.format.indexOf('Adjusted') > -1 ? 35 : 45
                    //@ts-ignore
                    el.options.transform._matrix.e = el.options.transform._matrix.e + shiftSize;
                    return el;
                  }
                } : undefined}
              >
                <ChartSeriesItemTooltip
                  visible={true}
                  format={item.tooltip.format}
                  render={(e: any) => e.point.format + '$' + formatFinancialRound(e.point.dataItem)}
                />
              </ChartSeriesItem>)
            )}
          </ChartSeries>
          <ChartValueAxis>
            <ChartValueAxisItem
              key={this.state.minValue + 'a'}
              axisCrossingValue={[0, this.state.minValue, this.state.minValue, this.state.minValue, this.state.minValue, this.state.minValue]}
              min={this.state.minValue === -100 ? -100 : undefined}
              max={this.state.minValue === -100 ? 100 : undefined}
              labels={{
                font: '300 normal 12px sans-serif',
                format: 'n2',
                content: (e: any) => '$' + formatFinancial(e.value),
                visual: (e: any) => {
                  let el: any = e.createVisual();
                  if (e.value < 0) el.children[0].options.fill.color = 'red'
                  return el
                }
              }}
            />
          </ChartValueAxis>
        </Chart>
      </div>
    </div>
  }

  renderRow = (row: any, props: simpleObject) => {
    let className = 'k-master-row'
    if (props.dataItem.isRowBold) className += ` ${styles.BoldRow}`
    return (
      <tr className={className}>
        {row.props.children}
      </tr>
    )
  }

  renderCell = (props: simpleObject) => {
    let value = props.dataItem[props.field]
    let className = props.dataItem.isColored ? (value < 0 ? styles.RedCell : styles.GreenCell) : ''
    return <td
      className={className}>{value !== undefined ? '$' + formatFinancial(value) : undefined}</td>
  }

  IsFiltersInDefault = () => {
    return !Object.keys(this.filters).length && !this.date
  }

  GetLocalStorageFilterKey = (filter: IComboboxFilterSetting) => `Executive-${filter.id}`

  GetLabelSettings = (format?: 'Percent' | 'WIPDelta') => {
    return {
      font: '300 normal 12px sans-serif',
      margin: {top: -25},
      format: format,
      content: (e: any) => {
        if (e.value.toString().length) {
          return e.format === 'Percent' ? e.value + '%' : '$' + formatFinancial(e.value)
        } else if (format === 'Percent') {
          return 'N/A'
        }
        return ''
      },
      visual: (e: any) => {
        let el: any = e.createVisual();
        if (e.format === 'Percent' && e.text === 'N/A') el.children[0].options.fill.color = 'red'
        else if (e.format !== 'WIPDelta') el.children[0].options.fill.color = e.value < 0 ? 'red' : 'green';
        return el
      }
    }
  }

  getTitleSettings = (title: string, rowNum: number, data: Array<string | null>): object => {
    let valuesLength = data.filter((value) => value !== null && value !== '').length
    let marginTop = rowNum === 2 ? -48 : -50
    return {
      text: title,
      position: 'left',
      font: '700 normal 11px sans-serif',
      color: '#000000',
      background: '#fff',
      margin: {
        top: !valuesLength ? -35 : marginTop,
        left: -65
      },
      padding: {
        right: 8,
        left: 8,
        top: rowNum === 2 ? 10 : 8,
        bottom: rowNum === 2 ? 8 : 0
      }
    }
  }

  LoadData = async (crewLeadId?: number | null) => {
    try {
      this.setState({loading: true});
      let params: simpleObject = {crewLeadId}
      if (this.date) params.date = this.date
      comboboxFilters.forEach((filter) => {
        let value = this.filters[filter.id]
        if (value) params[filter.param] = value.Id
      })
      let result = await this.GetSQLData({spName: 'DB_Executive', params});
      let gridData = this.GetPreparedGridData(result)
      let profitCenters = result[0] || []
      let projects = result[1] || []
      let classes = result[2] || []
      let customers = result[3] || []
      let bpOwners = result[4] || []

      // chart
      var months: Array<string> = [];
      var margins: Array<string> = [];
      var pls: Array<string> = [];
      let wips: Array<string> = [];
      let nets: Array<string> = [];
      var PL = result[8];
      var WIP = result[9];
      var Margin = result[10];
      var maxValueAbs = 0;
      var PLAverage: number | null = PL.length ? 0 : null;
      var NetAverage: number | null = PL.length ? 0 : null;
      PL.forEach(function (item: { Value: number, month: string }, i: number) {
        PLAverage = PL[i].Value !== null ? PL[i].Value + PLAverage : PLAverage;
        NetAverage = PL[i].Value !== null && WIP[i].Value !== null ? NetAverage + PL[i].Value + WIP[i].Value : NetAverage;
        var data: {
          Margin: string | null //"94.44"
          Month: string //"August"
          Net: string | null //"-19744.48"
          PL: string | null //"43818.92"
          WIP: string //"-63563.40"
        } = {
          PL: (PL[i].Value !== null && PL[i].Value.toFixed(2)) || null,
          WIP: (WIP[i].Value !== null && WIP[i].Value.toFixed(2)) || null,
          Net: PL[i].Value !== null && WIP[i].Value !== null ? (PL[i].Value + WIP[i].Value).toFixed(2) : null,
          Margin: (Margin[i].Value !== null && Margin[i].Value.toFixed(2)) || null,
          Month: moment.months(moment(PL[i].month).get('month'))
        };
        // chartData[i] = data; // ????
        months.push(data.Month);
        margins.push(data.Margin || '')
        pls.push(data.PL || '')
        wips.push(data.WIP || '')
        nets.push(data.Net || '')
        for (let key in data) {
          if (key !== 'PL' && key !== 'Net' && key !== 'Margin') continue;
          let value = data[key]
          if (value !== null) {
            var valueAbs = Math.abs(+value);
            maxValueAbs = Math.max(valueAbs, maxValueAbs);
          }
        }
      });

      PLAverage = PLAverage !== null ? PLAverage / 12 : null;
      NetAverage = NetAverage !== null ? NetAverage / 12 : null;
      let series: Array<ISeriesItem> = [
        {
          type: 'column',
          name: 'P&L',
          color: COLORS.pl,
          colorField: 'valueColor',
          field: 'value',
          data: pls.map((item: string) => item ? +item : null),
          tooltip: {
            visible: true,
            format: 'P&L: '
          },
        },
        {
          type: 'column',
          name: 'Adjusted P&L',
          color: COLORS.net,
          data: nets.map((item: string) => item ? +item : null),
          tooltip: {
            visible: true,
            format: 'Adjusted P&L: '
          }
        },
        {
          name: 'WIP Delta',
          color: COLORS.margin,
          data: wips.map((item: string) => item ? +item : null),
          tooltip: {
            visible: true,
            format: 'WIP Delta: '
          },
        },
        {
          name: 'Net Average',
          color: COLORS.net,
          data: [null, null, null, null, null, null, null, null, null, null, null, NetAverage],
          tooltip: {
            visible: true,
            format: 'Adjusted P&L \n Average: \n'
          },
          markersAverage: true,
          visibleInLegend: false
        },
        {
          name: 'PL Average',
          color: COLORS.pl,
          data: [null, null, null, null, null, null, null, null, null, null, null, PLAverage],
          tooltip: {
            visible: true,
            format: 'P&L \n Average: \n'
          },
          markersAverage: true,
          visibleInLegend: false
        },
      ]
      let minValue = maxValueAbs < 100 ? -100 : maxValueAbs * 2 * -1
      this.setState({
        projects,
        profitCenters,
        classes,
        customers,
        bpOwners,
        gridData,
        months,
        margins,
        plData: pls,
        wipData: wips,
        netData: nets,
        series,
        minValue,
        remountKey: +new Date()
      })
    } finally {
      this.setState({loading: false});
    }
  }

  GetPreparedGridData = (result: Array<any>) => {
    let gridData: Array<IRow> = [];
    if (result[5].length && result[6].length && result[7].length) {
      let revenueData = result[5][0];
      let revenue = {
        Name: 'Revenue',
        ThisDay: revenueData.thisDate,
        MonthToDate: revenueData.monthToDate,
        YearToDate: revenueData.yearToDate
      };
      gridData.push(revenue);
      let spend = result[6];
      let gross = {
        Name: 'Gross',
        ThisDay: 0,
        MonthToDate: 0,
        YearToDate: 0,
        isRowBold: true,
        isColored: true
      };
      gridData.push({Name: 'Spend', isRowBold: true});
      spend.forEach((item: simpleObject) => {
        let row = {
          Name: item.SourceTypeName,
          ThisDay: item.thisDate,
          MonthToDate: item.monthToDate,
          YearToDate: item.yearToDate,
          paddingLeft: true
        };
        gridData.push(row);
        gross.ThisDay += row.ThisDay;
        gross.MonthToDate += row.MonthToDate;
        gross.YearToDate += row.YearToDate;
      });

      gross.ThisDay = revenue.ThisDay - gross.ThisDay;
      gross.MonthToDate = revenue.MonthToDate - gross.MonthToDate;
      gross.YearToDate = revenue.YearToDate - gross.YearToDate;
      gridData.push(gross);

      let wipDelta = result[7][0];
      let wipDeltaGrid = {
        Name: 'WIP Delta',
        ThisDay: wipDelta.thisDate,
        MonthToDate: wipDelta.monthToDate,
        YearToDate: wipDelta.yearToDate
      };
      gridData.push(wipDeltaGrid);

      gridData.push({
        Name: 'WIP Adjusted P&L',
        ThisDay: wipDeltaGrid.ThisDay + gross.ThisDay,
        MonthToDate: wipDeltaGrid.MonthToDate + gross.MonthToDate,
        YearToDate: wipDeltaGrid.YearToDate + gross.YearToDate,
        isColored: true
      });
    }
    return gridData
  }

  GetDefaultDay = () => {
    return moment().subtract(1, 'day').format(DATE_FORMAT)
  }

  OnComboboxChange = (value: IComboboxItem | null, filter: IComboboxFilterSetting) => {
    let lsKey = this.GetLocalStorageFilterKey(filter)
    if (value) {
      localStorage.setItem(lsKey, JSON.stringify(value))
      this.filters[filter.id] = value
    } else {
      localStorage.removeItem(lsKey)
      delete this.filters[filter.id]
    }
    this.LoadData()
  }

  OnChangeDate = (value: any) => {
    if (value) {
      this.date = moment(value).format(DATE_FORMAT)
      localStorage.setItem(localStorageDateKey, JSON.stringify({
        date: this.date,
        expired: moment(new Date()).format('L')
      }))
      this.LoadData()
    } else {
      this.OnClearDate()
    }
  }

  OnClearDate = () => {
    this.date = null
    localStorage.removeItem(localStorageDateKey)
    this.LoadData()
  }

  SetDefaultFilters = () => {
    this.filters = {}
    comboboxFilters.forEach((filter) => {
      localStorage.removeItem(this.GetLocalStorageFilterKey(filter))
    })
    localStorage.removeItem(localStorageDateKey)
    this.date = this.GetDefaultDay()
    this.LoadData()
  }

  Refresh = () => {
    this.LoadData()
  }
}

export default ExecutiveDailyDashboard;
