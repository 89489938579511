import {Button, Chip, ToolbarSpacer} from '@progress/kendo-react-buttons'
import {
    Grid,
    GridCellProps,
    GridColumn as Column,
    GridColumnProps,
    GridColumnReorderEvent,
    GridSortChangeEvent,
    GridToolbar
} from '@progress/kendo-react-grid'
import {ExcelExport, ExcelExportColumn, ExcelExportColumnGroup} from '@progress/kendo-react-excel-export'
import {filterBy, orderBy} from '@progress/kendo-data-query'
import moment from 'moment'
import BaseComponent from '../../Components/BaseComponent'
import Loader from '../../Components/Common/Loader'

import {IComboboxItem, IGetSQLDataParams, ISortGridItem, simpleObject} from '../../helpers/interfaces'
import {
    formatCell,
    gridChangeDataAction,
    IColumnFilterItem,
    IColumnSetting,
    IColumnValue,
    IFiltersData,
    IFilterSetting,
    IFiltersValue,
    IGridFilter,
    IGridFilterItem,
    IHiddenColumn,
    ISelectSettings,
    IToolbarSettings,
    periodId
} from './interfaces'
import {TYPE_TO_EXCEL_FORMAT} from '../../helpers/helpers'
import {
    DateColumnWidthDefault,
    DateTimeColumnWidthDefault,
    DEFAULT_OPERATOR,
    DisableColumnMenuBtn,
    FILTER_OPERATORS,
    GetDefaultGridFilter,
    GetGridHeight,
    GetGridPageSize,
    GetNewRemountKey,
    GetPeriodDates,
    GridRowHeight,
    IconColumnWidthDefault,
    IsComplexGridFilter,
    NumberColumnWidthDefault,
    StringColumnMinWidthDefault,
} from './helpers'
import styles from './dashboard.module.scss'
import commonStyles from '../../assets/styles/common.module.scss'
import ComboboxFilter from './ComboboxFilter'
import ComboboxFilterVirtual from './ComboboxFilterVirtual'
import DateFilter from './DateFilter'
import DateRangeFilter from './DateRangeFilter'
import CheckboxFilter from './CheckboxFilter'
import GridFilterCell from './GridFilterCell'
import GridCell from './GridCell'
import GridFooterCell from './GridFooterCell'
import CustomColumnMenu from './ColumnMenu'
import RangeTimeFilter from './RangeTimeFilter'
import GridRow from './GridRow';
import Switch from '../Switch/Switch'
import DashboardModeGrid from './ModeGrid'
import FilterMultiSelect from '../Common/Form/FilterMultiSelect'
import {TagData} from '@progress/kendo-react-dropdowns'
import React from 'react'

type textAlign = 'left' | 'center' | 'right'

interface props {
    isParentPage?: boolean
    toolbar?: IToolbarSettings
    filters?: Array<IFilterSetting>
    isActive: boolean
    saveSettings?: {
        prefix: string
        saveFilters?: boolean
        saveGridSettings?: boolean
    }
    data?: Array<simpleObject>
    getSqlParams?: IGetSQLDataParams
    columnsSettings: Array<IColumnSetting>
    excelFileName?: string
    selectSettings?: ISelectSettings
    className?: string
    containerClassName?: string
    columnMenu?: {
        filters: boolean
        columns: boolean
    }
    reorderable?: boolean
    modeSettings?: {
        columns?: Array<IColumnSetting>,
        group?: {
            getGridData?(data: Array<simpleObject>): Array<simpleObject>
            columns: Array<simpleObject>
        },
        modeType: 'group' | 'columns',
        id: string,
        name: string
    }
    defaultColumnValuesFilter?: IColumnFilterItem[]
    defaultComplexGridFilters?: Array<IGridFilter>
    complexGridFilters?: Array<IGridFilter>
    columnValuesFilter?: IColumnFilterItem[]
    defaultSort?: Array<ISortGridItem>

    onMarkRow?(dataItem: simpleObject | null): any

    getRowKey(dataItem: simpleObject): string

    getRowClass?(dataItem: simpleObject): string

    initRefresh?(refresh: any): any

    prepareData?(data: any): Array<simpleObject>

    onRowContextMenu?(dataRow: simpleObject, e: any): void

    onChangeGridData?(data: Array<simpleObject>, action: gridChangeDataAction): void

    isValidFilters?(toolbarFilters: IFiltersValue): boolean
}

interface state {
    loading: boolean
    sort: Array<ISortGridItem>
    selectedRow: simpleObject | null
    gridData: Array<simpleObject>
    gridFilter: IGridFilter
    gridColumnsSettings: IHiddenColumn
    gridColumns: Array<IColumnSetting>
    modeGridColumnsSettings: IHiddenColumn
    modeGridColumns: Array<IColumnSetting>
    reMountKey: number
    skip: number
    isGridWide: boolean
    mode: boolean
}

class DashboardGrid extends BaseComponent<props, state> {
    export: any
    modeExport: any
    gridRef: any = React.createRef()
    prefix: string = this.props.saveSettings?.prefix || ''
    initialData: Array<simpleObject> = [];
    filteredGridData: Array<simpleObject> = []
    toolbarClientFiltersData: IFiltersData = {}
    toolbarFilter: IFiltersValue = {}
    hasServerFilters: boolean = !!this.props.filters && !!this.props.filters.find((filter) => !!filter.serverFilter)
    previousSkip: number | null = null
    previousScrolltop: number | null = null
    minGridWidth: number = 0
    flexibleColumnsLength: number = 0
    columnValues: {
        [key: string/* fieldId */]: Array<IColumnValue>
    } = {}
    columnValuesFilter: { [key: string]: Array<IGridFilterItem> } = {}
    quickGridFilter: Array<IGridFilterItem> = []
    complexGridFilter: Array<IGridFilter> | null = this.props.complexGridFilters || this.props.defaultComplexGridFilters || null
    fixVirtualTimer: any
    lastPageSize: number = 0
    dateFields: Array<IColumnSetting> = []
    gridWidth: number = 0
    childRef: any = React.createRef()
    gridContainerRef: any = React.createRef()
    markedRowKey: string | number = ''
    expandedColumns: { [key: string]: boolean } = {}

    constructor(props: props) {
        super(props)
        if (this.props.initRefresh) this.props.initRefresh(this.Refresh)
        this.dateFields = this.GetDateFields()
        this.SetDefaultColumnValuesFilter()
        this.RestoreSettings(false)
        this.props.columnsSettings.forEach(({
                                                type,
                                                columns,
                                                title,
                                                expandedByDefault
                                            }) => {
            if (type === 'parent' && columns) {
                const hasTotalSubCol = columns.findIndex((subCol) => subCol.title === 'Total') > -1
                if (hasTotalSubCol) this.expandedColumns[title || ''] = !!expandedByDefault
            }
        })
        this.state = {
            loading: false,
            sort: this.props.defaultSort || [],
            selectedRow: null,
            gridData: [],
            gridFilter: this.GetFullGridFilters(true),
            ...this.GetGridColumnInitialStateSettings(),
            reMountKey: GetNewRemountKey(),
            skip: 0,
            isGridWide: false,
            mode: false,
        }
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.columnsSettings !== this.props.columnsSettings) {
            this.setState(this.GetGridColumnInitialStateSettings())
            this.RestoreSettings(true)
        }
        if (this.props.columnValuesFilter && prevProps.columnValuesFilter !== this.props.columnValuesFilter) {
            this.RestoreSettings(false)
        }
    }

    GetGridColumnInitialStateSettings = () => {
        let gridColumns = this.props.columnsSettings
        let gridColumnsSettings: IHiddenColumn = {};
        if (this.props.columnMenu || this.props.reorderable) {
            let savedGridColumnsSettings = !!this.props.saveSettings?.saveGridSettings && localStorage.getItem(this.GetColumnsSettingLocalStorageName())
            if (savedGridColumnsSettings) {
                gridColumnsSettings = JSON.parse(savedGridColumnsSettings)
                gridColumns = this.GetGridColumns(gridColumnsSettings)
            }
        }
        let modeGridColumnsSettings: IHiddenColumn = {}

        let modeGridColumns = this.props.modeSettings && this.props.modeSettings.modeType === 'columns' && this.props.modeSettings.columns || []
        return {
            gridColumnsSettings,
            gridColumns,
            modeGridColumnsSettings,
            modeGridColumns
        }
    }

    RestoreSettings = (force: boolean) => {
        let filters = this.props.filters
        let exclusiveFilter = filters && filters.find((filter) => filter.isExclusive)
        let savedExclusedFilterValue = exclusiveFilter && localStorage.getItem(this.GetToolbarFilterLocalStorageName(exclusiveFilter))
        let saveFilters = this.props.saveSettings?.saveFilters
        if (!force) {
            if (saveFilters) {
                let savedQuickGridFilter = localStorage.getItem(this.GetQuickGridFilterLocalStorageName())
                if (savedQuickGridFilter) {
                    this.quickGridFilter = JSON.parse(savedQuickGridFilter)
                    if (this.dateFields.length && this.quickGridFilter.length) {
                        for (let filter of this.quickGridFilter) {
                            let isDateColumn = this.dateFields.find((columnSetting) => columnSetting.field === filter.field)
                            if (isDateColumn) filter.value = moment(filter.value).toDate()
                        }
                    }
                }
                let savedComplexGridFilter = localStorage.getItem(this.GetComplexGridFilterLocalStorageName())
                if (savedComplexGridFilter) {
                    let complexGridFilter = JSON.parse(savedComplexGridFilter)
                    complexGridFilter.forEach((filter: IGridFilter) => {
                        if (IsComplexGridFilter(filter)) {
                            filter.filters.forEach((subfilter) => {
                                if (!IsComplexGridFilter(subfilter) && subfilter.value && isNaN(subfilter.value)) {
                                    let value = moment(subfilter.value)
                                    if (value.isValid()) {
                                        subfilter.value = value.toDate()
                                    }
                                }
                            })
                        }
                    })
                    this.complexGridFilter = complexGridFilter
                }
                let savedValuesJSON = localStorage.getItem(this.GetFilterValuesLocalStorageName())
                if (savedValuesJSON) this.columnValuesFilter = JSON.parse(savedValuesJSON)
            }

            if (this.props.columnValuesFilter) {
                this.columnValuesFilter = {}
                for (let column of this.props.columnValuesFilter) {
                    let field = column.field
                    let filters: Array<IGridFilterItem> = []
                    for (let value of column.values) {
                        filters.push({
                            value,
                            field,
                            operator: "eq"
                        })
                    }
                    this.columnValuesFilter[field] = filters
                }
            }

            if (filters) {
                filters.forEach((filter) => {
                    let savedValue = saveFilters && localStorage.getItem(this.GetToolbarFilterLocalStorageName(filter))
                    let value: any;
                    if (savedValue) {
                        if (filter.type === 'date') {
                            value = moment(savedValue).toDate()
                        } else if (filter.type === 'dateRange') {
                            let rangeValues = JSON.parse(savedValue)
                            value = this.GetDateRangeValue(rangeValues, 'date')
                        } else {
                            value = JSON.parse(savedValue)
                        }
                    } else if (filter.defValue) {
                        if (!savedExclusedFilterValue) {
                            if (filter.values) value = filter.values.find((item) => item.Id === filter.defValue)
                            else value = filter.defValue
                        }
                    } else if (filter.type === 'dateRange') {
                        let periodId = filter.dateRangeSettings?.defaultPeriod
                        if (periodId) {
                            let [from, to] = GetPeriodDates(periodId)
                            value = {periodId, from, to}
                        }
                    } else {
                        value = null
                    }

                    if (saveFilters && value) {
                        let lsValue
                        if (filter.type === 'date') {
                            lsValue = moment(value).format()
                        } else if (filter.type === 'dateRange') {
                            let rangeFormatValues = this.GetDateRangeValue(value, 'string')
                            lsValue = JSON.stringify(rangeFormatValues)
                        } else {
                            lsValue = JSON.stringify(value)
                        }
                        localStorage.setItem(this.GetToolbarFilterLocalStorageName(filter), lsValue)
                    }
                    this.toolbarFilter[filter.id] = value
                })
            }

            if (this.props.saveSettings?.saveGridSettings) {
                let gridColumnsSettings: IHiddenColumn = {}
                if (this.props.columnMenu || this.props.reorderable) {
                    let savedColumnsSetting = localStorage.getItem(this.GetColumnsSettingLocalStorageName())
                    if (savedColumnsSetting) {
                        gridColumnsSettings = JSON.parse(savedColumnsSetting)
                    } else {
                        gridColumnsSettings = this.GetDefaultGridColumnsSetting()
                    }

                    localStorage.setItem(this.GetColumnsSettingLocalStorageName(), JSON.stringify(gridColumnsSettings))
                }
            }
        }

        // IF FORCE OR
        if (filters) {
            filters.forEach((filter) => {
                if (force || (this.toolbarFilter[filter.id] === undefined || this.toolbarFilter[filter.id] === null)) {
                    let value;
                    if (filter.defValue !== undefined) {
                        if (!savedExclusedFilterValue) {
                            if (filter.values) {
                                value = filter.values.find((item: IComboboxItem) => item.Id === filter.defValue) || null
                            } else {
                                value = filter.defValue
                            }
                            this.toolbarFilter[filter.id] = value
                            if (saveFilters) {
                                let lsValue = value instanceof Date ? moment(value).format() : JSON.stringify(value)
                                localStorage.setItem(this.GetToolbarFilterLocalStorageName(filter), lsValue)
                            }
                        }
                    } else if (filter.type === 'dateRange') {
                        this.SetDefaultDateRangeFilter(filter)
                    } else if (this.toolbarFilter[filter.id]) {
                        delete this.toolbarFilter[filter.id]
                        if (saveFilters) localStorage.removeItem(this.GetToolbarFilterLocalStorageName(filter))
                    }
                }
            })
        }

        if (force) {
            this.SelectRow(null)
            this.quickGridFilter = []
            this.complexGridFilter = this.props.defaultComplexGridFilters || null
            this.columnValuesFilter = {}
            this.columnValues = {}

            this.SetDefaultColumnValuesFilter()

            if (saveFilters) {
                localStorage.removeItem(this.GetFilterValuesLocalStorageName())
                localStorage.removeItem(this.GetComplexGridFilterLocalStorageName())
                localStorage.removeItem(this.GetQuickGridFilterLocalStorageName())
            }

            let gridFilter = this.GetFullGridFilters(true)
            let state = {
                mode: false,
                gridFilter,
                reMountKey: GetNewRemountKey(),
                skip: 0
            }
            if (filters && this.hasServerFilters) {
                filters.forEach((filter) => {
                    if (filter.serverFilter) {
                        if (filter.defValue) {
                            let value = filter.defValue
                            /* if (filter.values) {
                              value = filter.values.find((item: IComboboxItem) => item.Id === filter.defValue) || null
                            } else {
                              value = filter.defValue
                            } */
                            this.toolbarFilter[filter.id] = value
                            if (saveFilters) {
                                let lsValue = value instanceof Date ? moment(value).format() : JSON.stringify(value)
                                localStorage.setItem(this.GetToolbarFilterLocalStorageName(filter), lsValue)
                            }
                        } else if (filter.type === 'dateRange') {
                            this.SetDefaultDateRangeFilter(filter)
                        } else {
                            delete this.toolbarFilter[filter.id]
                            if (saveFilters) localStorage.removeItem(this.GetToolbarFilterLocalStorageName(filter))
                        }
                    }
                })
                this.setState({...state})
                this.LoadData()
            } else {
                this.filteredGridData = this.GetGridDataFilteredByToolbarFilter()
                let gridData = this.GetFinalGridData(this.state.sort)
                if (this.props.onChangeGridData) this.props.onChangeGridData(gridData, 'forceRestoreSettings')
                this.setState({...state, gridData})
            }
        } else if (this.props.columnValuesFilter) {
            this.filteredGridData = this.GetGridDataFilteredByToolbarFilter()
            let gridData = this.GetFinalGridData(this.state?.sort || [])
            if (this.props.onChangeGridData) this.props.onChangeGridData(gridData, 'restoreSettings')
            this.setState({gridData})
        }
    }

    SetDefaultColumnValuesFilter = () => {
        if (this.props.defaultColumnValuesFilter && !Object.keys(this.columnValuesFilter).length) {
            for (let column of this.props.defaultColumnValuesFilter) {
                let field = column.field
                let filters: Array<IGridFilterItem> = []
                for (let value of column.values) {
                    filters.push({
                        value,
                        field,
                        operator: "eq"
                    })
                }
                this.columnValuesFilter[field] = filters
            }
        }
    }

    SetDefaultDateRangeFilter = (filter: IFilterSetting) => {
        let periodId = filter.dateRangeSettings?.defaultPeriod
        if (periodId) {
            let [from, to] = GetPeriodDates(periodId)
            let value = {periodId, from, to}
            this.toolbarFilter[filter.id] = value
            if (this.props.saveSettings?.saveFilters) {
                let lsFilter = JSON.stringify(this.GetDateRangeValue(value, 'string'))
                localStorage.setItem(this.GetToolbarFilterLocalStorageName(filter), lsFilter)
            }
        }
    }

    componentDidMount() {
        this.SetGridSizeSettings()
        if (this.props.data) {
            this.initialData = this.props.data
            this.PrepareSetGridData()
        } else {
            this.LoadData()
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount()
        window.removeEventListener('resize', this.SetGridSizeSettings);
        if (this.fixVirtualTimer) clearInterval(this.fixVirtualTimer)
    }

    initExportRef = (exporter: any) => (this.export = exporter)

    initModeExportRef = (exporter: any) => (this.modeExport = exporter);

    initGridRef = (ref: any) => {
        let isFirstGridRef = this.gridRef === undefined
        this.gridRef = ref
        if (isFirstGridRef) {
            window.addEventListener('resize', this.SetGridSizeSettings);
        }
    }

    render() {
        let selectedRow = this.state.selectedRow
        let collapsedGrid = !!this.props.selectSettings?.Component && !!selectedRow
        let pageSize = GetGridPageSize(this.gridRef)
        let groupModeOn = this.state.mode && this.props.modeSettings?.modeType === 'group'
        if (!this.state.mode) { // if (!groupModeOn) {
            if (pageSize) this.lastPageSize = pageSize
            else if (this.lastPageSize) pageSize = this.lastPageSize
        }
        let gridHeight = GetGridHeight(collapsedGrid ? selectedRow : null, this.gridRef);
        let modeData = groupModeOn && this.props.modeSettings?.group?.getGridData?.(this.state.gridData) || this.state.gridData
        let gridColumns = this.state.mode && this.props.modeSettings?.modeType === 'columns' ? this.state.modeGridColumns : this.state.gridColumns
        let modeColumns = this.state.mode && this.props.modeSettings?.group?.columns || this.props.columnsSettings
        return (
            <div
                className={`${styles.Container} ${this.props.containerClassName}`}
                ref={this.gridContainerRef}
            >
                {this.state.loading && <Loader/>}
                {!groupModeOn && <>
                    <Grid
                        ref={this.initGridRef}
                        className={`${styles.Grid} ${this.props.className || ''} ${collapsedGrid ? styles.GridCollapse : ''}`}
                        style={{width: '100%', height: gridHeight, flex: '0 0 auto'}}
                        data={collapsedGrid ? [selectedRow] : this.state.gridData.slice(this.state.skip, this.state.skip + pageSize + 2)}
                        rowHeight={GridRowHeight}
                        pageSize={pageSize}
                        skip={this.state.skip}
                        total={collapsedGrid ? 1 : this.state.gridData.length}
                        scrollable={'virtual'}
                        onPageChange={this.OnChangePage}
                        resizable={true}
                        filterable={true}
                        filter={this.state.gridFilter}
                        filterOperators={FILTER_OPERATORS}
                        sortable={{
                            mode: 'multiple'
                        }}
                        sort={this.state.sort}
                        onSortChange={this.OnSort}
                        selectedField="marked"
                        onRowClick={this.OnMarkRow}
                        onRowDoubleClick={this.HandleSelectRow}
                        reorderable={this.props.reorderable}
                        onColumnReorder={this.OnColumnReorder}
                        headerCellRender={this.renderColumnHeader}
                        rowRender={this.renderRow}
                    >
                        {this.renderToolbar()}
                        {gridColumns.map((column, index) => {
                            if (column.hiddenColumn) return null
                            if (column.columns) {
                                let totalSubColumn = column.columns.find((column) => column.title === 'Total')
                                let isExpandedParentColumn = !!totalSubColumn && !!this.expandedColumns[column.title || '']
                                let subcolumns = !isExpandedParentColumn && totalSubColumn ? [totalSubColumn] : column.columns

                                const props = this.GetColumnProps(column, index)
                                return <Column
                                    key={column.title}
                                    title={column.title}
                                    locked={column.locked}
                                    headerClassName={commonStyles.TextCenter}
                                    headerCell={!!totalSubColumn ? this.renderHeaderCollapsibleGroupCell : undefined}
                                    orderIndex={props.orderIndex}
                                >
                                    {subcolumns.map((subColumn) => <Column
                                            key={subColumn.title + column.title}
                                            {...this.GetColumnProps(subColumn)}
                                        />
                                    )}
                                </Column>
                            }
                            return <Column
                                key={column.title} {...this.GetColumnProps(column, index)} />
                        })}
                    </Grid>

                    {!!this.props.excelFileName &&
                        <ExcelExport
                            data={this.state.gridData}
                            ref={this.initExportRef}
                            fileName={this.props.excelFileName}
                            filterable={true}
                            // collapsible={true}
                        >
                            {
                                gridColumns.map((column) => {
                                    if ((column.columns)) {
                                        return (<ExcelExportColumnGroup
                                            key={column.title}
                                            title={column.title}
                                            headerCellOptions={{wrap: false}}
                                        >
                                            {column.columns.map((subColumn) => (
                                                <ExcelExportColumn
                                                    key={subColumn.title + column.title}
                                                    field={subColumn.field}
                                                    width={150}
                                                    title={subColumn.title}
                                                    locked={false}
                                                    headerCellOptions={{wrap: false}}
                                                    cellOptions={this.GetExcelCellOptions(subColumn)}
                                                />))}
                                        </ExcelExportColumnGroup>)
                                    }

                                    return (<ExcelExportColumn
                                        key={column.title}
                                        field={column.field}
                                        title={column.title}
                                        width={this.GetColumnSettingWidth(column)}
                                        headerCellOptions={{wrap: false}}
                                        locked={column.locked}
                                        cellOptions={this.GetExcelCellOptions(column)}
                                    />)
                                })
                            }
                        </ExcelExport>}
                    {this.renderChildComponent()}
                </>
                }
                {groupModeOn && <>
                    <DashboardModeGrid
                        gridData={modeData}
                        columns={modeColumns}
                        excelFileName={this.props.modeSettings?.name + ' ' + this.props.excelFileName}
                        toolbar={this.renderToolbar()}
                        initModeExportRef={this.initModeExportRef}
                    />
                </>}
            </div>
        )
    }

    renderColumnHeader = (el: any, props: any) => {
        let field = props.field
        let column = this.GetColumn(field)
        if (column?.titleHTML) {
            let icon = el.props.children[0].props.children[1]
            return <span className="k-cell-inner" onClick={props.onClick}>
              <span className="k-link">
                <span
                    className="k-column-title">{column?.titleHTML}</span>
                  {icon}
              </span>
            </span>
        }

        return el
    }

    renderRow = (row: any, props: any) => {
        return (<GridRow
            row={row}
            props={props}
            gridProps={{
                getRowClass: this.props.getRowClass,
                OnRowContextMenu: this.props.onRowContextMenu ? this.OnRowContextMenu : undefined
            }}
        />);
    }

    renderToolbar = () => {
        let isEmptyToolbar = !this.props.filters?.length &&
            !this.props.modeSettings &&
            !this.props.toolbar?.switcher &&
            !this.props.isParentPage &&
            !this.props.toolbar?.actionBtns;
        if (this.props.toolbar?.hide || isEmptyToolbar) return null
        let IsFiltersInDefault = this.IsFiltersInDefault()

        if (this.props.toolbar?.isSingleRowToolbar) {
            return (<GridToolbar>
                    {this.props.toolbar?.switcher}
                    <div className={styles.ToolbarContent}>
                        <div className={styles.ToolbarRow}>
                            {this.renderToolbarFilters()}
                            {!this.state.mode && this.props.toolbar?.notModeTitle}
                            <ToolbarSpacer/>
                            {this.props.modeSettings &&
                                <div className={styles.ModeSwitchContainer}>
                                    <Switch
                                        checked={this.state.mode}
                                        style={{margin: '0 4px'}}
                                        id={this.props.modeSettings.id}
                                        onChange={this.OnSwitchMode}
                                        label={this.props.modeSettings?.name}
                                        rtl={true}
                                    />
                                </div>}

                            <div className={styles.RightBtns}>
                                {this.props.toolbar?.actionBtns}
                                {!!this.props.isParentPage && <>
                                    <Button
                                        onClick={this.SetDefaultSettings}
                                        title="Set Default Filters"
                                        icon="filter-clear"
                                        className={IsFiltersInDefault ? '' : styles.BlueResetBtn}
                                    />
                                    {!!this.props.excelFileName &&
                                        <Button icon="file-excel" title="Export to Excel"
                                                onClick={this.ExportToExcel}></Button>
                                    }
                                    <Button icon="refresh" onClick={this.Refresh}></Button>
                                </>}
                            </div>
                        </div>
                    </div>
                </GridToolbar>
            )
        }

        let firstRowFilter = this.props.filters?.findIndex((filter) => filter.toolbarRow === 1)
        let secondRowFilter = this.props.filters?.findIndex((filter) => filter.toolbarRow === 2)
        let showFirstRow = !!this.props.isParentPage || (firstRowFilter !== undefined && firstRowFilter > -1) || (!this.state.mode && this.props.toolbar?.notModeTitle)
        let showSecondRow = (secondRowFilter !== undefined && secondRowFilter > -1) || !!this.props.toolbar?.actionBtns

        return (
            <GridToolbar>
                {this.props.toolbar?.switcher}
                <div className={styles.ToolbarContent}>
                    {showFirstRow && <div className={styles.ToolbarRow}>
                        {this.renderToolbarFilters(1)}
                        {!this.state.mode && this.props.toolbar?.notModeTitle}
                        <ToolbarSpacer/>
                        {this.props.modeSettings &&
                            <div className={styles.ModeSwitchContainer}>
                                <Switch
                                    checked={this.state.mode}
                                    style={{margin: '0 4px'}}
                                    id={this.props.modeSettings.id}
                                    onChange={this.OnSwitchMode}
                                    label={this.props.modeSettings?.name}
                                    rtl={true}
                                />
                            </div>}
                        {!!this.props.isParentPage &&
                            <div className={styles.RightBtns}>
                                <Button
                                    onClick={this.SetDefaultSettings}
                                    title="Set Default Filters"
                                    icon="filter-clear"
                                    className={IsFiltersInDefault ? '' : styles.BlueResetBtn}
                                />

                                {!!this.props.excelFileName &&
                                    <Button icon="file-excel" title="Export to Excel"
                                            onClick={this.ExportToExcel}></Button>
                                }
                                <Button icon="refresh" onClick={this.Refresh}></Button>
                                {!!this.props.toolbar?.unSelectRow &&
                                    <Button icon='close' style={{color: 'red'}}
                                            onClick={this.props.toolbar.unSelectRow}/>}
                            </div>}
                    </div>}
                    {showSecondRow && <div className={styles.ToolbarRow}>
                        {this.renderToolbarFilters(2)}
                        <ToolbarSpacer/>
                        <div className={styles.RightBtns}>
                            {this.props.toolbar?.actionBtns}
                        </div>
                    </div>}
                </div>
            </GridToolbar>
        )
    }

    renderChildComponent = () => {
        const {Component: ChildComponent, pageId, componentProps} = this.props.selectSettings || {}
        if (!ChildComponent || !pageId) return null

        return <ChildComponent
            ref={this.childRef}
            isActive={this.props.isActive}
            rowData={this.state.selectedRow}
            unSelectRow={this.UnSelectRow}
            parentId={pageId}
            props={componentProps}
        />
    }

    IsFiltersInDefault = () => { // EXTERNAL
        if (this.complexGridFilter || this.quickGridFilter.length) return false
        let isFilterByValues = false
        for (let filterId in this.columnValuesFilter) {
            if (!isFilterByValues) {
                let values = this.columnValues[filterId]
                let filterValues = this.columnValuesFilter[filterId]
                isFilterByValues = (!values && !!filterValues?.length) || (!!filterValues && !!values && filterValues.length < values.length)
            }
        }
        if (isFilterByValues) return false
        for (let key in this.toolbarFilter) {
            let filter = this.toolbarFilter[key]
            if (filter) {
                let isFilterValue = filter?.periodId ? (!!filter?.from || !!filter?.to) : !!filter
                if (isFilterValue) {
                    return false
                }
            }
        }
        return true
    }

    OnSwitchMode = (mode: boolean) => {
        let state = {mode, skip: 0}
        if (mode) {
            if (this.props.filters) {
                for (let filter of this.props.filters) {
                    if (filter.showOnlyNoMode && this.toolbarFilter[filter.id]) {
                        delete this.toolbarFilter[filter.id]
                        if (this.props.saveSettings?.saveFilters) localStorage.removeItem(this.GetToolbarFilterLocalStorageName(filter))
                    }
                }
            }

            this.SelectRow(null);
            this.filteredGridData = this.GetGridDataFilteredByToolbarFilter()
            let gridData = this.GetFinalGridData(this.state.sort)
            if (this.props.onChangeGridData) this.props.onChangeGridData(gridData, 'switchMode')
            this.setState({gridData, ...state})
        } else {
            this.setState({...state})
        }
    }

    GetExcelCellOptions = (column: IColumnSetting) => {
        let textAlign: textAlign = 'left'
        let format = column.format
        if (format && (format.type === 'number' || format.type === 'integer' || format.type === 'currency' || format.type === 'date' || format.type === 'datetime' || format.type === 'time')) textAlign = 'right'
        else if (format && (format.type === 'percent')) textAlign = 'center'
        let excelFormat = (format && format.type !== 'link' && format.type !== 'string' && format.type !== 'icon') ? TYPE_TO_EXCEL_FORMAT[format.type] : undefined // boolean type todo
        return {
            format: excelFormat,
            textAlign: textAlign,
        }
    }

    IsFlexibleColumn = (col: IColumnSetting) => (col.type === 'string' && col.format?.type !== 'icon') || col.type === 'parent'

    GetFlexibleColumnsLength = () => {
        let count = 0;
        for (let col of this.props.columnsSettings) {
            let isFlexibleType = this.IsFlexibleColumn(col)
            if (!col.hiddenColumn && !col.hiddenMode && col.gridWidth === undefined && isFlexibleType) count++
        }
        return count
    }

    GetMinGridWidth = (columns: Array<IColumnSetting>): number => {
        let sum = 0;
        for (let column of columns) {
            if (column.columns) return sum += this.GetMinGridWidth(column.columns)
            let width = (column.hiddenMode || column.hiddenColumn) ? 0 : this.GetColumnSettingWidth(column);
            sum += width
        }
        return sum
    }

    SetGridSizeSettings = () => {
        let gridEl = this.gridRef.element
        if (!gridEl) return
        // let gridContainerWidth = gridEl.offsetWidth - 2
        this.minGridWidth = this.GetMinGridWidth(this.props.columnsSettings)
        this.flexibleColumnsLength = this.GetFlexibleColumnsLength()
        this.setState({isGridWide: (gridEl.offsetWidth < this.minGridWidth)});
    }

    GetColumnSettingWidth = (column: IColumnSetting) => {
        let columnWidth = 0
        if (column.gridWidth) {
            columnWidth = column.gridWidth
        } else if (column.minWidth) {
            columnWidth = column.minWidth
        } else if (column.type === 'date') {
            columnWidth = column.format?.type === 'datetime' ? DateTimeColumnWidthDefault : DateColumnWidthDefault
        } else if (column.type === 'number') {
            columnWidth = NumberColumnWidthDefault
        } else if (column.type === 'string') {
            if (column.format?.type === 'icon') columnWidth = IconColumnWidthDefault
            else columnWidth = StringColumnMinWidthDefault
        }/*  else if (column.type === 'boolean') { TODO
      columnWidth = BooleanColumnWidthDefault
    } */

        return columnWidth
    }

    GetColumnWidth = (column: IColumnSetting) => {
        let isFlexibleType = this.IsFlexibleColumn(column)
        let containerWidth = this.gridContainerRef.current?.offsetWidth
        if (containerWidth) this.gridWidth = containerWidth - 2
        if (!this.gridWidth) return
        let minWidth = this.GetColumnSettingWidth(column)
        let resultwidth = 0
        if (this.state.isGridWide || column.gridWidth || !isFlexibleType || column.minWidth) {
            return minWidth
        } else {
            resultwidth = minWidth + ((this.gridWidth - this.minGridWidth) / this.flexibleColumnsLength);
            return resultwidth - (18 / this.flexibleColumnsLength)
        }
    }

    GetColumnProps = (column: IColumnSetting, index?: number) => {
        let gridColumnsSettings = this.state.gridColumnsSettings
        let props: GridColumnProps = {
            field: column.field,
            title: column.title,
            locked: column.locked,
            width: this.GetColumnWidth(column),
            filterable: column.filterable !== false,
            format: JSON.stringify(column.format),
            minResizableWidth: column.minResizableWidth,
            headerClassName: `${column.field} ${column.headerClassName}`,
            reorderable: column.reorderable,
            sortable: column.sortable
        }

        if (column.format) props.cell = this.renderFormatCell
        if (column.aggregate) props.footerCell = this.renderFooterCell
        if (column.headerHint) props.headerCell = this.renderHeaderCell
        if (column.type === 'date') props.filter = 'date'
        if (column.filterable !== false /* && column.type !== 'boolean' TODO */) props.filterCell = this.renderGridFilterCell
        if (column.type === 'number') props.filter = 'numeric'
        // if (column.type === 'boolean') props.filter = 'boolean' // TODO
        if (column.highlight) props.className = styles.HighlightColumn
        if (this.props.columnMenu && column.columnMenu !== false) {
            let headerClassName = '';
            if (this.props.columnMenu.filters && this.state.gridFilter) {
                let filters = this.state.gridFilter.filters
                let isFilteredByComplexFilters = filters.find((filter) => IsComplexGridFilter(filter) && !IsComplexGridFilter(filter.filters[0]) && filter.filters[0].field === column.field)
                if (isFilteredByComplexFilters) headerClassName = styles.FilteredColumnTH
            }

            if (!headerClassName && column.format?.fieldId) {
                let fieldId = column.format?.fieldId
                let values = this.columnValues[fieldId]
                let filterValues = this.columnValuesFilter[fieldId]
                if ((!values && filterValues?.length) || (filterValues && values && filterValues.length < values.length)) {
                    headerClassName = styles.FilteredColumnTH
                }
            }
            if (headerClassName) props.headerClassName += ' ' + headerClassName
            props.columnMenu = this.renderColumnMenu
        }
        if (this.props.reorderable && column.reorderable !== false) {
            let columnSetting = gridColumnsSettings[this.GetColumnKey(column)]
            if (!columnSetting) {
                columnSetting = {
                    hidden: false,
                    name: column.title,
                    order: index,
                    readonly: false
                }
                gridColumnsSettings[this.GetColumnKey(column)] = columnSetting
            }
            props.orderIndex = columnSetting.order
        }
        return props
    }

    GetDefaultGridColumnsSetting = () => {
        let gridColumnsSettings: IHiddenColumn = {}
        this.props.columnsSettings.forEach((column, i) => {
            gridColumnsSettings[this.GetColumnKey(column)] = {
                hidden: column.hiddenMode === true,
                name: column.title,
                order: i,
                readonly: column.columnMenu === false
            }
        })
        return gridColumnsSettings
    }

    GetColumnKey = (column: IColumnSetting | simpleObject) => {
        return `${column.field}__${column.title}`
    }

    GetDateRangeValue = (
        value: {
            periodId: periodId,
            from: null | string | Date,
            to: null | string | Date
        },
        formatDateMode: 'string' | 'date'
    ) => {
        let fromMoment = value.from ? moment(value.from) : null
        let toMoment = value.to ? moment(value.to) : null
        return {
            periodId: value.periodId,
            from: fromMoment && (formatDateMode === 'date' ? fromMoment.toDate() : fromMoment.format().slice(0, -6)),
            to: toMoment && (formatDateMode === 'date' ? toMoment.toDate() : toMoment.format().slice(0, -6)),
        }
    }

    ToggleParentColumn = (e: any) => {
        let column = e.currentTarget.getAttribute('data-column')
        this.expandedColumns[column] = !this.expandedColumns[column]
        this.forceUpdate()
    }

    renderToolbarFilters = (rowNumber?: 1 | 2) => {
        if (!this.props.filters) return null
        return this.props.filters.map((filter) => {
            if (
                (rowNumber && filter.toolbarRow !== rowNumber) ||
                filter.showOnlyNoMode && this.state.mode
            ) return null
            let dateRangeSettings = filter.dateRangeSettings
            if (filter.type === 'dateRange' && dateRangeSettings) {
                let value = this.toolbarFilter[filter.id]
                let periodId = (value && value.periodId) || dateRangeSettings.defaultPeriod;
                return <DateRangeFilter
                    key={filter.id + this.state.reMountKey}
                    defaultPeriodId={periodId}
                    defaultDateFrom={value?.from}
                    defaultDateTo={value?.to}
                    allowEmpty={dateRangeSettings.allowEmptyValues}
                    filter={filter}
                    onChange={this.OnToolbarFilterChange}
                />
            }

            let commonProps = {
                key: filter.id + this.state.reMountKey,
                defaultValue: this.toolbarFilter[filter.id],
                filter: filter,
                onChange: this.OnToolbarFilterChange
            }
            if (filter.type === 'rangetime') {
                return <RangeTimeFilter {...commonProps} />
            } else if (filter.type === 'checkbox') {
                return <CheckboxFilter {...commonProps} />
            } else if (filter.type === 'combobox') {
                if (filter.virtual) {
                    return <ComboboxFilterVirtual
                        refName={filter.virtual.refName!}
                        {...commonProps}
                    />
                }
                return <ComboboxFilter
                    filterData={filter.values || this.toolbarClientFiltersData[filter.id]}
                    getData={filter.serverFilter?.getData}
                    {...commonProps}
                />
            } else if (filter.type === 'date') {
                return <DateFilter
                    clear={filter.dateSettings?.clearable !== false ? this.ClearServerFilter : undefined}
                    {...commonProps}
                />
            } else if (filter.type === 'multiselect') {
                let val = commonProps.defaultValue
                return <FilterMultiSelect
                    {...commonProps}
                    placeholder={filter.placeholder || ''}
                    data={filter.values || this.toolbarClientFiltersData[filter.id]}
                    getData={filter.serverFilter?.getData}
                    width={filter.width}
                    autoClose={false}
                    tags={[{text: ` `, data: []}]}
                    tagRender={(tagData: TagData, tag: any) => {
                        let filtersLength = !!val && val.length
                        if (!filtersLength) return null
                        return <Chip
                            key={'selected' + filter.id}
                            text={`${filtersLength} ${filter.itemName}${!!filter.itemName && filtersLength > 1 ? 's' : ''} Selected`}
                        />
                        return tag // for ts
                    }}
                />
            }
        })
    }

    renderFormatCell = (props: GridCellProps) => {
        return <GridCell
            gridCellProps={props}
            getColumn={this.GetColumn}
            refresh={this.Refresh}
        />
    }

    renderFooterCell = (props: any) => {
        return <GridFooterCell
            gridCellProps={props}
            gridData={this.state.gridData}
            getColumn={this.GetColumn}
        />
    }

    renderHeaderCollapsibleGroupCell = (props: any) => {
        let icon = this.expandedColumns[props.title || ''] ? 'minus' : 'plus';
        return <div style={{display: 'flex', alignItems: 'center'}}>
            <Button
                size="small"
                icon={icon}
                fillMode="flat"
                style={{marginRight: 8}}
                data-column={props.title}
                onClick={this.ToggleParentColumn}
            ></Button>
            {props.title}
        </div>
    }

    renderHeaderCell = (props: any) => {
        return <span
            onClick={props.onClick}
            className="k-link"
            title={this.GetColumnHint(props.field)}
        >
      {props.title}{props.children}
    </span>
    }

    renderGridFilterCell = (props: any) => {
        let field = props.field
        let defaultValue = props.value
        let dateFormat = undefined
        if (props.filterType === 'date') {
            let column = this.GetColumn(field)
            if (column && column.format) dateFormat = column.format.type
        }
        return <GridFilterCell
            key={props.field + this.state.reMountKey}
            props={props}
            defaultValue={defaultValue}
            field={field}
            onChange={this.OnQuickFilterChange}
            onOperatorChange={this.OnQuickFilterOperatorChange}
            clear={this.ClearQuickGridFilter}
            dateFormat={dateFormat}
        />
    }

    renderColumnMenu = (props: any) => {
        DisableColumnMenuBtn(this.gridRef, styles.DisabledColumnMenuBtn, props.column.field)
        let column = this.GetColumn(props.column.field)
        let filterableByValues = column && column?.format?.fieldId && !column?.doNotFilterByValue;
        return (
            <CustomColumnMenu
                defaultProps={props}
                filterable={column?.filterable !== undefined ? column?.filterable : this.props.columnMenu?.filters}
                columns={this.props.columnMenu?.columns ? this.state.gridColumnsSettings : undefined}
                onColumnsSubmit={this.OnColumnsSubmit}
                getDefaultColumns={this.GetDefaultGridColumnsSetting}
                filterSubmit={this.SubmitComplexGridFilter}
                onCloseMenu={this.OnCloseColumnMenu}
                getColumnValues={filterableByValues ? this.GetColumnValues : undefined}
                filterByValues={this.SubmitFilterByColumnValues}
                fieldId={filterableByValues ? column?.format?.fieldId : undefined}
            />)
    }

    OnCloseColumnMenu = () => {
        DisableColumnMenuBtn(this.gridRef, styles.DisabledColumnMenuBtn)
    }

    OnChangePage = (event: any) => {
        if (!this.props.selectSettings?.Component) {
            this.setState({skip: event.page.skip});
        } else if (!this.state.selectedRow) {
            let skip = this.previousSkip
            if (skip !== null) {
                let container = this.gridRef?.vs?.container
                if (container?.scroll) {
                    container.scroll(0, this.previousScrolltop);
                    this.previousScrolltop = null
                } else if (container) {
                    this.gridRef.vs.attendedSkip = skip
                    this.gridRef.vs.prevScrollPos = this.previousScrolltop
                    this.gridRef.vs.propsSkip = skip
                    this.gridRef.vs.realSkip = skip
                    this.previousScrolltop = null
                }
            }
            this.setState({skip: skip !== null ? skip : event.page.skip});
            this.previousSkip = null
        }
    }

    SubmitComplexGridFilter = (filter: IGridFilter, fieldId: string, fieldName: string) => {
        this.SelectRow(null);
        // @ts-ignore
        this.complexGridFilter = filter && filter.filters.length ?
            filter.filters.filter((item) =>
                IsComplexGridFilter(item) &&
                !IsComplexGridFilter(item.filters[0]) &&
                item.filters[0].field !== fieldId
            ) :
            null
        let doRemountQuickFilters = !!this.complexGridFilter && !!this.complexGridFilter.length && this.quickGridFilter.findIndex((filter) => filter.field === fieldName) > -1
        this.RemoveColumnValuesFilter(fieldId)
        this.RemoveQuickColumnFilter(fieldName)
        this.SetSaveGridFilters(doRemountQuickFilters)
    }

    SubmitFilterByColumnValues = (filters: Array<IGridFilterItem>, values: Array<IColumnValue>, fieldId: string, fieldName: string) => {
        this.SelectRow(null);
        this.columnValuesFilter[fieldId] = filters
        this.columnValues[fieldId] = values
        let doRemountQuickFilters = !!filters.length && this.quickGridFilter.findIndex((filter) => filter.field === fieldName) > -1
        this.RemoveQuickColumnFilter(fieldName)
        this.RemoveComplexColumnFilter(fieldName)
        this.SetSaveGridFilters(doRemountQuickFilters)
    }

    GetColumnValues = (field: string, fieldId: string): Array<IColumnValue> => {
        let valuesUnic: { [key: string]: boolean } = {}
        let values: Array<IColumnValue> = []

        let selectValues = this.columnValuesFilter[fieldId]
        if (this.columnValues[fieldId]) return this.columnValues[fieldId]
        let column = this.GetColumn(field)
        if (column && column.values) {
            values = column.values
            for (let value of values) {
                value.Selected = selectValues ? selectValues.findIndex((item) => item.value === value.Id && item.field === value.FieldId) > -1 : false
            }
        } else {
            for (let row of this.filteredGridData) {
                let value = row[field] || column?.format?.notAssignedValueName || 'Not Assigned';
                let valueId = row[fieldId]
                let Select = selectValues ? selectValues.findIndex((item) => item.value === valueId) > -1 : true
                if (!valuesUnic[valueId]) {
                    valuesUnic[valueId] = true
                    values.push({
                        Name: value,
                        Selected: Select,
                        FieldId: fieldId,
                        Id: valueId
                    })
                }
            }
            let sortByName = (a: IColumnValue, b: IColumnValue) => {
                if (a.Name > b.Name) return 1
                else if (a.Name < b.Name) return -1
                else return 0
            }
            values = values.sort(sortByName)
        }
        this.columnValues[fieldId] = values
        return values
    }

    GetColumnHint = (field: string) => {
        for (let i = 0; i < this.props.columnsSettings.length; i++) {
            let col = this.props.columnsSettings[i]
            if (col.field === field) return col.headerHint
            else if (col.columns) {
                let subCol: undefined | IColumnSetting = col.columns.find((col) => col.field === field)
                if (subCol) {
                    return subCol.headerHint
                }
            }
        }
        return ''
    }

    GetColumn = (field: string) => {
        let column: IColumnSetting | undefined
        let columnsSettings = this.state.mode && this.props.modeSettings?.modeType === 'columns' ? this.state.modeGridColumns : this.state.gridColumns
        columnsSettings.forEach((col) => {
            if (!column) {
                if (col.columns) column = col.columns.find((col) => col.field === field)
                else if (col.field === field) column = col
            }
        })
        return column
    }

    OnColumnReorder = (event: GridColumnReorderEvent) => {
        let gridColumnsSettings = this.state.gridColumnsSettings
        event.columns.forEach((column) => {
            let key = this.GetColumnKey(column)
            let setting = gridColumnsSettings[key]
            if (setting) setting.order = column.orderIndex
        })
        if (this.props.saveSettings?.saveGridSettings) {
            localStorage.setItem(this.GetColumnsSettingLocalStorageName(), JSON.stringify(gridColumnsSettings))
        }

        this.setState({
            gridColumnsSettings,
            gridColumns: this.GetGridColumns(gridColumnsSettings)
        })
    }

    OnColumnsSubmit = (gridColumnsSettings: IHiddenColumn) => {
        let doRemountQuickFilters = !!this.quickGridFilter.length
        this.complexGridFilter = this.props.defaultComplexGridFilters || null
        this.quickGridFilter = []
        this.columnValuesFilter = {}
        for (let key in this.columnValues) {
            let list = this.columnValues[key]
            for (let value of list) value.Selected = true
        }

        this.SetSaveGridFilters(doRemountQuickFilters)
        let gridColumns = this.GetGridColumns(gridColumnsSettings)
        if (this.props.saveSettings?.saveGridSettings && this.props.columnMenu?.columns !== false) {
            localStorage.setItem(this.GetColumnsSettingLocalStorageName(), JSON.stringify(gridColumnsSettings))
        }
        this.props.selectSettings?.onSelectRow?.(null)
        this.setState({gridColumnsSettings, gridColumns, selectedRow: null});
        this.SetGridSizeSettings()
    }

    GetGridColumns = (hiddenColumns: IHiddenColumn) => {
        // TODO let columnsSettings = this.state.mode && this.props.modeSettings?.modeType === 'columns' ? this.props..modeGridColumns : this.state.gridColumns
        let columns = this.props.columnsSettings.filter((column) => {
            return (
                !column.hiddenColumn &&
                (column.columnMenu === false ||
                    !hiddenColumns[this.GetColumnKey(column)]?.hidden)
            )
        })

        if (Object.keys(hiddenColumns).length) {
            columns.sort((ac: IColumnSetting, bc: IColumnSetting) => {
                let aorder = hiddenColumns[this.GetColumnKey(ac)]?.order || 0
                let border = hiddenColumns[this.GetColumnKey(bc)]?.order || 0
                return aorder - border
            })
        }
        return columns
    }

    GetDateFields = () => {
        let dateFields: Array<IColumnSetting> = []
        this.props.columnsSettings.forEach((col) => {
            if (col.type === 'date' && col.field) dateFields.push(col)
            if (col.columns) {
                col.columns.forEach((subCol) => {
                    if (subCol.type === 'date' && subCol.field) dateFields.push(subCol)
                })
            }
        });
        return dateFields
    }

    OnQuickFilterOperatorChange = (props: any) => {
        let quickFilters = this.quickGridFilter
        let field = props.item.field
        let operator = props.item.operator
        let oldFilterIndex = quickFilters.findIndex((filter: any) => filter.field === field)
        let oldFilter = oldFilterIndex !== -1 && quickFilters[oldFilterIndex]
        if (oldFilter) {
            if (oldFilter.operator === operator && oldFilter.value === null) {
                quickFilters.splice(oldFilterIndex, 1)
            } else {
                oldFilter.operator = operator
            }
        } else {
            quickFilters.push({
                field, value: null, operator
            })
        }
        this.quickGridFilter = quickFilters
        this.SetSaveGridFilters(false)
    }

    OnQuickFilterChange = (value: any, props: simpleObject, dateFormat?: formatCell) => {
        let quickFilters = this.quickGridFilter
        let type: string = dateFormat || props.filterType || 'text'
        let field = props.field || ''
        if (value) {
            let column = this.GetColumn(props.field)
            let fieldId = column?.format?.fieldId
            if (fieldId) this.RemoveColumnValuesFilter(fieldId)
            this.RemoveComplexColumnFilter(props.field)
            if (type === 'numeric') value = +value
            let oldFilter = quickFilters.find((filter) => filter.field === field)
            if (oldFilter) {
                oldFilter.value = value
            } else {
                quickFilters.push({
                    field, value, operator: DEFAULT_OPERATOR[type]
                })
            }
        } else {
            quickFilters = quickFilters.filter((filter: any) => filter.field !== field)
        }
        this.quickGridFilter = quickFilters
        this.SetSaveGridFilters(false)
    }

    RemoveColumnValuesFilter = (fieldId: string) => {
        delete this.columnValuesFilter[fieldId]
        if (this.columnValues[fieldId]) {
            for (let value of this.columnValues[fieldId]) value.Selected = true
        }
    }

    RemoveQuickColumnFilter = (fieldName: string) => {
        if (this.quickGridFilter.length) {
            this.quickGridFilter = this.quickGridFilter.filter((filter) => filter.field !== fieldName)
        }
    }

    RemoveComplexColumnFilter = (fieldName: string) => {
        let field = fieldName
        if (this.complexGridFilter) {
            this.complexGridFilter = this.complexGridFilter.filter((filter) =>
                !IsComplexGridFilter(filter.filters[0]) && filter.filters[0].field !== field
            )
        }
    }

    RestoreGridFilters = () => { // externall call
        let gridFilter = this.GetFullGridFilters(true)
        this.setState({gridFilter})
    }

    GetFullGridFilters = (forState: boolean) => {
        let gridFilter = GetDefaultGridFilter()
        if (this.quickGridFilter.length) gridFilter.filters.push(...this.quickGridFilter)
        if (this.complexGridFilter?.length) gridFilter.filters.push(...this.complexGridFilter)
        if (!forState) {
            for (let key in this.columnValuesFilter) {
                let filter = this.columnValuesFilter[key]
                let values = this.columnValues[key]
                if ((!values && filter?.length) || (values && filter && values.length > filter.length)) gridFilter.filters.push({
                    filters: filter,
                    logic: 'or'
                })
            }
        }
        return gridFilter
    }

    SetSaveGridFilters = (remountFilters: boolean) => {
        let gridFilter = this.GetFullGridFilters(true)
        if (this.props.saveSettings?.saveFilters) {
            let quickFiltersToSave = []
            if (this.dateFields.length && this.quickGridFilter.length) {
                for (let filter of this.quickGridFilter) {
                    let isDateColumn = this.dateFields.find((columnSetting) => columnSetting.field === filter.field)
                    quickFiltersToSave.push(isDateColumn ?
                        {
                            ...filter,
                            value: moment(filter.value).format()
                        } : filter)
                }
            } else quickFiltersToSave = this.quickGridFilter

            localStorage.setItem(this.GetQuickGridFilterLocalStorageName(), JSON.stringify(quickFiltersToSave))
            localStorage.setItem(this.GetFilterValuesLocalStorageName(), JSON.stringify(this.columnValuesFilter))
            if (this.complexGridFilter && this.complexGridFilter.length) {
                localStorage.setItem(this.GetComplexGridFilterLocalStorageName(), JSON.stringify(this.complexGridFilter));
            } else {
                localStorage.removeItem(this.GetComplexGridFilterLocalStorageName());
            }
        }
        let gridData = this.GetFinalGridData(this.state.sort);
        this.setState((state) => ({
            skip: 0,
            gridFilter,
            gridData,
            reMountKey: remountFilters ? GetNewRemountKey() : state.reMountKey // for not losing focus quick grid filters
        }))
        if (this.props.onChangeGridData) this.props.onChangeGridData(gridData, 'filterChanged')
    }

    FormatDateForServer = (date: Date | null) => {
        if (date === null) return null
        return moment(date).format().slice(0, -6)
    }

    OnToolbarFilterChange = (value: any, filter: IFilterSetting) => {
        this.columnValues = {}
        this.columnValuesFilter = {}
        localStorage.removeItem(this.GetFilterValuesLocalStorageName())
        this.SetDefaultColumnValuesFilter()
        let state: Partial<state> = {}
        let exclusiveServerFilterWasChanged = false
        let exclusiveFilterWasChanged = false
        let saveFilters = this.props.saveSettings?.saveFilters
        if (saveFilters && !filter.filterByCustom) {
            if (value !== null && value !== undefined) {
                let lsFilter;
                if (filter.type === 'date') {
                    lsFilter = moment(value).format().slice(0, -6)
                } else if (filter.type === 'dateRange') {
                    lsFilter = JSON.stringify(this.GetDateRangeValue(value, 'string'))
                } else {
                    lsFilter = JSON.stringify(value)
                }
                localStorage.setItem(this.GetToolbarFilterLocalStorageName(filter), lsFilter)
            } else {
                localStorage.removeItem(this.GetToolbarFilterLocalStorageName(filter))
            }
        }
        this.toolbarFilter[filter.id] = value
        let filters = this.props.filters
        let isExclusiveFilterChanged = filter.isExclusive
        let chagedFilter = filter
        if (filters) {
            filters.forEach((filter) => {
                if (this.toolbarFilter[filter.id]) {
                    let removeFilter = false
                    if (isExclusiveFilterChanged && chagedFilter.id !== filter.id) {
                        removeFilter = true
                    } else if (!isExclusiveFilterChanged && filter.isExclusive) {
                        removeFilter = true
                        exclusiveFilterWasChanged = true
                        exclusiveServerFilterWasChanged = !!filter.serverFilter
                    }
                    if (removeFilter) {
                        delete this.toolbarFilter[filter.id]
                        if (saveFilters) localStorage.removeItem(this.GetToolbarFilterLocalStorageName(filter))
                    }
                }
            })
        }

        if (isExclusiveFilterChanged) {
            this.quickGridFilter = []
            this.complexGridFilter = this.props.defaultComplexGridFilters || null // or just null??
            this.columnValuesFilter = {}
            this.columnValues = {}
            if (saveFilters) {
                localStorage.removeItem(this.GetFilterValuesLocalStorageName())
                localStorage.removeItem(this.GetComplexGridFilterLocalStorageName())
                localStorage.removeItem(this.GetQuickGridFilterLocalStorageName())
            }
            state.gridFilter = this.GetFullGridFilters(true)
        }
        if (exclusiveFilterWasChanged) this.setState({reMountKey: GetNewRemountKey()})
        if (exclusiveFilterWasChanged) state.reMountKey = GetNewRemountKey()

        this.SelectRow(null)
        if (filter.serverFilter || exclusiveServerFilterWasChanged) {
            this.LoadData()
        } else {
            this.filteredGridData = this.GetGridDataFilteredByToolbarFilter()

            let gridData = this.GetFinalGridData(this.state.sort)
            state.gridData = gridData
            state.skip = 0
            if (this.props.onChangeGridData) this.props.onChangeGridData(gridData, 'filterChanged')
        }

        // @ts-ignore
        if (Object.keys(state).length) this.setState(state)
    }

    ExternalFilterGrid = (filters: Array<{
        filterValue: IComboboxItem,
        filterId: string
    }>) => { // external
        for (let filter of filters) {
            this.toolbarFilter[filter.filterId] = filter.filterValue
        }
        this.SelectRow(null)
        this.filteredGridData = this.GetGridDataFilteredByToolbarFilter()
        let gridData = this.GetFinalGridData(this.state.sort)
        this.setState({gridData, mode: false, skip: 0})
        if (this.props.onChangeGridData) this.props.onChangeGridData(gridData, 'filterChanged')
    }

    OnSort = (e: GridSortChangeEvent) => {
        let gridData = this.GetFinalGridData(e.sort);
        this.setState({sort: e.sort, gridData, skip: 0})
        if (this.props.onChangeGridData) this.props.onChangeGridData(gridData, 'sort')
    }

    ClearQuickGridFilter = (props: simpleObject) => {
        let field = props.field
        this.quickGridFilter = this.quickGridFilter.filter((filter: any) => filter.field !== field)
        this.SetSaveGridFilters(false)
    }

    ClearServerFilter = (filter: IFilterSetting) => {
        this.toolbarFilter[filter.id] = null
        if (this.props.saveSettings?.saveFilters) {
            localStorage.removeItem(this.GetToolbarFilterLocalStorageName(filter))
        }
        this.LoadData()
    }

    GetToolbarFilterLocalStorageName = (filter: IFilterSetting | simpleObject) => {
        return `${filter.localStoragePrefix ? filter.localStoragePrefix : this.prefix}_${filter.id}`
    };

    GetComplexGridFilterLocalStorageName = () => `${this.prefix}_ComplexFilter`

    GetQuickGridFilterLocalStorageName = () => `${this.prefix}_QuickFilter`

    GetFilterValuesLocalStorageName = () => `${this.prefix}_ValuesFilter`

    GetColumnsSettingLocalStorageName = () => `${this.prefix}_ColumnsSetting`

    GetSelectRowLocalStorageName = () => `${this.prefix}_SelectedRowId`;

    GetLoadDataParams = () => {
        if (!this.props.getSqlParams) return
        let loadDataParams = this.props.getSqlParams;
        let params: simpleObject = this.props.getSqlParams.params ? {...this.props.getSqlParams.params} : {}
        let filters = this.props.filters
        if (filters) {
            filters.forEach((filter) => {
                if (filter.serverFilter) {
                    let value = this.toolbarFilter[filter.id]
                    if (value && filter.type === 'multiselect') value = value.map((item: IComboboxItem) => item.Id).join(',')
                    if (value && filter.type === 'combobox') value = value.Id
                    // if (value && filter.type === 'checkbox') value = value
                    if (filter.type === 'dateRange') {
                        if (value && filter.dateRangeSettings) {
                            params[filter.dateRangeSettings.dateFromParam] = this.FormatDateForServer(value.from)
                            params[filter.dateRangeSettings.dateToParam] = this.FormatDateForServer(value.to)
                        }
                    } else if (filter.type === 'date') {
                        if (filter.dateSettings?.dateFormat) {
                            params[filter.serverFilter.param] = value ? filter.dateSettings.dateFormat(value) : value
                        } else {
                            params[filter.serverFilter.param] = this.FormatDateForServer(value)
                        }
                    } else if (filter.serverFilter.param) {
                        params[filter.serverFilter.param] = value
                    }
                }
            })
        }
        loadDataParams.params = params
        return loadDataParams
    }

    LoadData = async () => {
        if (!this.props.getSqlParams) return
        try {
            this.setState({loading: true, selectedRow: null})
            let params = this.GetLoadDataParams()
            if (!params) return
            if (this.props.isValidFilters && !this.props.isValidFilters(params)) {
                this.initialData = []
            } else {
                let result = await this.GetSQLData(params)
                if (this.props.prepareData) this.initialData = this.props.prepareData(result)
                else this.initialData = result[0] || []
            }
            this.PrepareSetGridData()
        } catch (e) {
            this.SelectRow(null)
        } finally {
            this.setState({loading: false})
        }
    }

    PrepareSetGridData = () => {
        this.columnValues = {}
        let clientFiltersData: IFiltersData = {}
        let filtersDataUnic: {
            [key: string]: {
                [key: string]: IComboboxItem
            }
        } = {}

        let filters = this.props.filters
        this.initialData.forEach((row: any) => {
            if (this.dateFields.length) {
                this.dateFields.forEach((col) => {
                    if (!col.field) return
                    let value = row[col.field]
                    if (value) {
                        let isTimeFormat = col.format?.type === 'time' || col.format?.type === 'datetime'
                        let momentValue = value ? moment(value) : value
                        if (isTimeFormat) row[col.field] = momentValue.set('ms', 0).toDate()
                        else if (value) row[col.field] = momentValue.startOf('day').toDate()
                    }
                })
            }
            row.Key = this.props.getRowKey(row)
            if (filters) {
                filters.forEach((filter) => {
                    if (!filter.serverFilter || !filter.serverFilter.getData) {
                        if (!filter.values && filter.type !== 'checkbox') {
                            if (!clientFiltersData[filter.id]) clientFiltersData[filter.id] = []
                            if (!filtersDataUnic[filter.id]) filtersDataUnic[filter.id] = {}
                        }

                        if (!filter.values && filter.labelColumn && filter.idColumn) {
                            let value = row[filter.idColumn] // TODO toString
                            /* if(value === null) {
                              row[filter.idColumn] = 'null';
                              value = 'null';
                            } */
                            if (value && !filtersDataUnic[filter.id][value]) { // TODO add null??
                                filtersDataUnic[filter.id][value] = value
                                clientFiltersData[filter.id].push({
                                    Name: row[filter.labelColumn],
                                    Id: value
                                })
                            }
                        }
                    }
                })
            }
        })
        let saveFilters = this.props.saveSettings?.saveFilters
        if (filters) {
            filters.forEach((filter) => {
                if (!filter.serverFilter) {
                    let value = this.toolbarFilter[filter.id]
                    if (filter.type === 'combobox' && !filter.values && value) {
                        let hasSelectedValue = clientFiltersData[filter.id].find((item) => item.Id === value.Id)
                        if (!hasSelectedValue) {
                            this.toolbarFilter[filter.id] = null
                            if (saveFilters) localStorage.removeItem(this.GetToolbarFilterLocalStorageName(filter))
                        }
                    }
                }
            })
        }
        let selectedKey = !!saveFilters ? localStorage.getItem(this.GetSelectRowLocalStorageName()) : null
        let exclusiveFilter = this.props.filters?.find((filter) => filter.isExclusive)
        if (exclusiveFilter && this.toolbarFilter[exclusiveFilter.id] && this.initialData.length === 1) {
            let row = this.initialData[0]
            selectedKey = row.BPID
        }
        this.filteredGridData = this.GetGridDataFilteredByToolbarFilter()
        if (selectedKey) this.markedRowKey = selectedKey
        let gridData = this.GetFinalGridData(this.state.sort)
        let selectedRow = null
        if (selectedKey) {
            selectedRow = gridData.find((row: any) => row.Key == selectedKey) || null
            if (this.props.selectSettings?.Component) {
                this.props.selectSettings?.onSelectRow?.(selectedRow)
            }
        }
        this.toolbarClientFiltersData = clientFiltersData
        this.setState({
            gridData,
            selectedRow,
            reMountKey: GetNewRemountKey(),
            skip: 0
        })
        if (this.props.onChangeGridData) this.props.onChangeGridData(gridData, 'dataLoaded')
    }

    GetGridDataFilteredByToolbarFilter = () => {
        let filters = this.props.filters
        if (!filters) return this.initialData
        return this.initialData.filter((row) => {
            let result = true
            let filters = this.props.filters
            if (filters) {
                for (let i = 0; i < filters.length; i++) {
                    let filter = filters[i];
                    let filterValue = this.toolbarFilter[filter.id]
                    if (!!filter.filterByCustom) {
                        result = !filterValue || filter.filterByCustom(row, filterValue);
                    } else if (!filter.serverFilter) {
                        if (filter.type === 'rangetime') {
                            if (filter.idColumn && filterValue) {
                                let rowTime: moment.Moment = moment(row[filter.idColumn])
                                let startTime = rowTime.clone().set('h', filterValue.start).set('m', 0).set('s', 0).set('ms', 0)
                                let endTime = rowTime.clone().set('h', filterValue.end).set('m', 0).set('s', 0).set('ms', 0)
                                result = rowTime.isSameOrAfter(startTime) && rowTime.isSameOrBefore(endTime);
                            }
                        } else {
                            let filterValueId = filterValue && typeof filterValue === 'object' && filterValue.Id;
                            result = !filterValueId || (filter.idColumn && row[filter.idColumn] && row[filter.idColumn] == filterValueId)
                        }
                        if (!result) return false
                    }
                }
            }
            return result
        })
    }

    GetFinalGridData = (sort: any) => {
        let gridFilter = this.GetFullGridFilters(false)
        let data = orderBy(filterBy(this.filteredGridData, gridFilter), sort)
        for (let item of data) {
            if (this.markedRowKey) item.marked = item.Key == this.markedRowKey
        }
        return data
    }

    SetDefaultSettings = () => {
        this.RestoreSettings(true)
    }

    SyncFilters = () => { // external calling
        let filters = this.props.filters
        if (this.props.saveSettings?.saveFilters && filters) {
            let changedFilter = false;
            for (let filter of filters) {
                if (filter.localStoragePrefix) {
                    let currentValue = this.toolbarFilter[filter.id]
                    let savedValue: any = localStorage.getItem(this.GetToolbarFilterLocalStorageName(filter))
                    if (!!savedValue !== !!currentValue) {
                        changedFilter = true
                        break;
                    }

                    if (savedValue) {
                        /* if (filter.type === 'date') { // TODO
                          savedValue = moment(savedValue).format()//.toDate()
                          if (saved)
                        } else  */
                        if (filter.type === 'dateRange') {
                            let rangeValues = JSON.parse(savedValue)
                            savedValue = this.GetDateRangeValue(rangeValues, 'string')
                            let currentFrom = currentValue.from ? moment(currentValue.from).format().slice(0, -6) : null
                            let currentTo = currentValue.to ? moment(currentValue.to).format().slice(0, -6) : null
                            let currentPeriodId = currentValue.periodId
                            changedFilter = currentPeriodId !== savedValue.periodId || currentFrom !== savedValue.from || currentTo !== savedValue.to
                        } else {
                            changedFilter = savedValue !== JSON.stringify(currentValue)
                        }
                        if (changedFilter) break
                    }
                }
            }

            this.setState({reMountKey: +new Date()})
            if (changedFilter) {
                this.RestoreSettings(false)
                this.LoadData()
            }
        }
    }

    OnMarkRow = (e: { dataItem: simpleObject }) => {
        let lastMarkedKey = this.markedRowKey
        let rowData: simpleObject = e.dataItem
        let key = rowData.Key
        if (lastMarkedKey && lastMarkedKey === key) return // or delete mark??
        // todo save marked row ???
        this.markedRowKey = key
        let gridData = this.GetFinalGridData(this.state.sort);
        if (this.props.onMarkRow) this.props.onMarkRow(rowData)
        // if (this.props.onChangeGridData) this.props.onChangeGridData(gridData, 'markedRow')
        this.setState({gridData})
    }

    HandleSelectRow = (e: { dataItem: simpleObject }) => {
        this.SelectRow(e.dataItem)
    }

    UnSelectRow = () => this.SelectRow(null)

    SelectRow = (selectedRow: simpleObject | null, forceSelect?: boolean) => { // externalCall
        let settings = this.props.selectSettings
        if (settings) {
            if (selectedRow) this.markedRowKey = selectedRow.Key
            settings?.onSelectRow?.(selectedRow)

            if (settings?.Component) {
                let unselectRow = false
                let lastSelectedKey = this.state.selectedRow?.Key
                if (selectedRow === null && lastSelectedKey === null) return
                if (!forceSelect && selectedRow && lastSelectedKey && lastSelectedKey === selectedRow.Key) {
                    selectedRow = null
                    unselectRow = true
                }
                let skip = this.state.skip
                let scroll = this.gridRef?.vs?.prevScrollPos
                if (this.props.saveSettings?.saveFilters) {
                    if (selectedRow) localStorage.setItem(this.GetSelectRowLocalStorageName(), selectedRow.Key)
                    else localStorage.removeItem(this.GetSelectRowLocalStorageName())
                }
                let gridData = this.GetFinalGridData(this.state.sort);
                this.setState({selectedRow, gridData})
                if (this.props.onChangeGridData) this.props.onChangeGridData(gridData, unselectRow ? 'unselectRow' : 'selectRow')
                if (selectedRow === null) {
                    //@ts-ignore
                    let el = $(this.gridRef?.element).find('.k-grid-content.k-virtual-content')
                    let scrollLeft = el.scrollLeft()
                    el.scrollLeft(0).scrollLeft(100).scrollLeft(scrollLeft)
                } else {
                    this.previousSkip = skip
                    this.previousScrolltop = scroll
                }
            }
        }
    }

    OnRowContextMenu = (e: any) => {
        if (this.props.onRowContextMenu) {
            e.preventDefault()
            let tr = e.target.closest('tr[role="row"]');
            if (!tr) return
            let grid = e.target.closest('.k-grid')
            let headerGrid = grid.querySelector('.k-grid-header-wrap')
            let headerTrLength = headerGrid.querySelectorAll('tr').length
            let index = +tr.getAttribute('aria-rowindex') - (headerTrLength + 1)
            let dataItem = this.state.gridData[index]
            this.props.onRowContextMenu(dataItem, e)
        }
    }

    Refresh = () => {
        this.LoadData()
        this.childRef?.current?.Refresh?.()
    }

    ExportToExcel = () => {
        if (this.modeExport && this.state.mode) this.modeExport.save()
        else if (this.export) this.export.save()
    }

}

export default DashboardGrid
