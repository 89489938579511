import React, {useState} from 'react';
import {Button} from '@progress/kendo-react-buttons';
import {IAdjustedTimeLineItem, IAdjustmentAllocation, ITCSummaryInfo} from '../../../Pages/TKReview/interfaces';
import styles from './adjustmentsEdit.module.scss';
import CardManagement from "../../../Components/Cards/CardManagement";
import {IListWOItem} from "../../../Components/Cards/interfaces";
import FilterCombobox from "../../../Components/Common/Form/FilterCombobox";
import {IComboboxItem} from "../../../helpers/interfaces";
import {getDefaultStateCode} from "./helpers";

interface IProps {
    tcId: number
    tcInfo: ITCSummaryInfo
    data: IAdjustedTimeLineItem
    workOrders: IListWOItem[]
    updateAllocation: (oldData: IAdjustedTimeLineItem, allocation: Array<IAdjustmentAllocation>, stateCode: string | null) => void
    onCloseAllocationCard: (workOrders: IListWOItem[]) => void
}

const AllocationInfo = (props: IProps) => {
    const [isEditMode, setEditMode] = useState(false)
    const {data, tcInfo, tcId, workOrders} = props

    const turnOnEditMode = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        setEditMode(true)
        document.addEventListener("click", turnOffEditMode);
    }

    const turnOffEditMode = () => {
        setEditMode(false)
        document.removeEventListener("click", turnOffEditMode);
    }

    const onSaveMultiAllocation = (result: IAdjustmentAllocation[], _oldAllocation: IAdjustmentAllocation[], workOrders: IListWOItem[]) => {
        const stateCode = data.StateCode || getDefaultStateCode(result, workOrders)
        props.updateAllocation(data, result, stateCode)
    }

    const onChangeSingleWOAllocation = (value: IComboboxItem | null, dataAttr?: any) => {
        const allocation: IAdjustmentAllocation[] = []
        const wo = value as IListWOItem || undefined
        if (wo) {
            allocation.push({
                Percentage: 100,
                RowNumber: 0, //?
                WorkOrderCode: wo.Code,
                WorkOrderId: wo.Id,
                WorkOrderName: wo.Name,
                WorkOrderNumber: wo.Number
            })
        }
        const stateCode = data.StateCode || getDefaultStateCode(allocation, workOrders)
        props.updateAllocation(data, allocation, stateCode)
    }

    const editAllocation = () => {
        CardManagement.WOAllocationCard({
            tcId,
            tcInfo,
            onResult: onSaveMultiAllocation,
            allocation: data.ManualAllocation,
            onClose: props.onCloseAllocationCard
        })
    }
    if (data.isStaticAllocation) {
        return <div className={styles.TEEditWOAllocationBox}>
            <span> {data.ManualAllocation[0]?.WorkOrderName}</span>
        </div>
    }

    if (data.ManualAllocation.length > 1) {
        return <div
            className={styles.TEEditWOAllocationBox}
            onClick={editAllocation}
        >
            <span> Multiple Work Orders ({data.ManualAllocation?.length})</span>
        </div>
    }

    const allocation = data.ManualAllocation[0]
    const wo = allocation && {
        Code: allocation.WorkOrderCode,
        Number: allocation.WorkOrderNumber,
        DefaultStateCode: null,
        Id: allocation.WorkOrderId,
        IsScheduled: true,
        Name: allocation.WorkOrderName,
    }
    return <div
        className={styles.TEEditWOAllocationBox}
        onClick={turnOnEditMode}
    >
        {isEditMode ? <FilterCombobox
            placeholder={'Is Not Allocated'}
            className={styles.AllocationDropDown}
            data={workOrders}
            value={wo}
            onChange={onChangeSingleWOAllocation}
            size={'small'}
            popupSettings={{
                width: 350
            }}
        /> : <div
            style={{
                flex: 1,
                height: 25,
                display: 'flex',
                alignItems: 'center',
            }}
        >{allocation?.WorkOrderName || 'Is Not Allocated'}</div>}

        <Button
            onClick={editAllocation}
            iconClass={'mdi mdi-call-split'}
            title={'Multiple Allocation'}
            fillMode={'flat'}
        />
    </div>
}

export default AllocationInfo;
