import {ITab, ITabSetting, tabId} from "./interfaces";
import BPBudget from "../BP/Budget/Budget";
import LaborHours from "../BP/Labor/Labor";
import CIMSummary from "../BP/CIMSummary";
import VIMSummary from "../BP/VIMSummary";
import BPHistory from "../BP/History";
import BPChatHistory from "../BP/ChatHistory/ChatHistory";
import BPTasks from "../BP/Tasks/BPTasks";
import CODSummary from "../BP/CODSummary";
import WorkOrders from "../BP/WorkOrders";
import RelatedDocuments from "../RelatedDocuments/RelatedDocuments";
import BPGeneral from "../BP/General/BPGeneral";
import LiveMap from "../../Pages/LiveMap/LiveMap";
import CalendarComponent from "../Frames/CalendarComponent";
import Milestones from "../../Pages/Milestones/Milestones";
import BOMBPTab from "../BP/BOMTab";
import InventoryBalance from "../Dashboards/InventoryBalance";
import Utilization from "../../Components/Dashboards/Utilization";
import Materials from "../Dashboards/Materials";
import DailyStatus from "../../Pages/DailyDashboard/DailyStatus";
import SimplifiedCalendarComponent from "../Frames/SimplifiedCalendarComponent";
import CheckListResults from "../../Pages/CLM/ChecklistResults/ChecklistResults";
import Checklists from "../../Pages/CLM/Checklists";
import CLMStatusDashboard from "../../Pages/CLM/StatusDashboard";
import CLMResources from "../../Pages/CLM/Resources";
import PLStatement from "../../Pages/PLDashboard/PLStatement";
import PLDetails from "../../Pages/PLDashboard/PLDetailing";
import TimeCard from "../../Pages/TKReview/TimeCard/TimeCard";
import TKTCLogs from "../../Pages/Logs/TKTCLogs";
import TCHistory from "../TC/History";
import TCAttestations from "../../Components/TC/Attestations";
import TCAudits from "../../Components/TC/AuditResults";
import TCRequests from "../../Components/TC/Requests";
import LaborCosts from "../../Components/TC/LaborCosts";
import TCMap from "../TC/Map";
import CostRates from "../../Components/PP/CostRates";
import PayrollPeriod from "../../Components/PP/PayrollPeriod";
import PPSummary from "../../Components/PP/PayrollSummury";
import PayrollStatus from "../PP/PayrollStatus";
import TimeCardsGrid from "../TC/TimeCardsGrid";
import PayrollChecks from "../PP/PayrollChecks";

export const TABS: {
    [key in tabId]: ITab;
} = {
    Budget: {
        name: "Budget",
        component: BPBudget,
    },
    CIM: {
        name: "CIM",
        component: CIMSummary,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    VIM: {
        name: "VIM",
        component: VIMSummary,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    Milestones: {
        name: "Milestones",
        component: Milestones,
        hasSetDefaultFilters: true,
    },
    BOM: {
        name: "BOM",
        component: BOMBPTab,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    Documents: {
        name: "Documents",
        component: RelatedDocuments,
    },
    History: {
        name: "BP History",
        component: BPHistory,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    ChatHistory: {
        name: "Chat History",
        component: BPChatHistory,
    },
    Labor: {
        name: "Labor",
        component: LaborHours,
    },
    Tasks: {
        name: "Tasks",
        component: BPTasks,
    },
    Map: {
        name: "Map",
        component: LiveMap,
    },
    COD: {
        name: "COD",
        component: CODSummary,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    WorkOrders: {
        name: "Work Orders",
        component: WorkOrders,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    Calendar: {
        name: "Calendar",
        component: CalendarComponent,
    },
    Daily: {
        name: "Daily Status",
        component: DailyStatus,
    },
    InventoryBalance: {
        name: "Inventory Balance",
        component: InventoryBalance,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    General: {
        name: "General",
        component: BPGeneral,
    },
    // CLM tabs
    SimplifiedCalendar: {
        name: "Calendar",
        component: SimplifiedCalendarComponent,
    },
    StatusDashboard: {
        name: "Status Dashboard",
        component: CLMStatusDashboard,
        hasSetDefaultFilters: true,
    },
    ReviewInterface: {
        // Close Out ReviewInterface
        name: "Review Interface",
        component: CheckListResults,
    },
    ChecklistResults: {
        name: "Checklists",
        component: CheckListResults,
    },
    Checklists: {
        name: "Checklists",
        component: Checklists,
    },
    Resources: {
        name: "Resources",
        component: CLMResources,
        hasSetDefaultFilters: true,
    },
    PL: {
        name: "P&L",
        component: PLStatement,
        hasExportToExcel: true,
    },
    PLDetails: {
        name: "P&L Details",
        component: PLDetails,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    Materials: {
        name: "Materials",
        component: Materials,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    Utilization: {
        name: "Utilization",
        component: Utilization,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    TC: {
        name: "Time Card",
        component: TimeCard,
    },
    TCMap: {
        name: "Map",
        component: TCMap,
    },
    TCLogs: {
        name: "Logs",
        component: TKTCLogs,
        hasSetDefaultFilters: true,
    },
    TCHistory: {
        name: "History",
        component: TCHistory,
        hasSetDefaultFilters: true,
    },
    TCAttestations: {
        name: "Attestations",
        component: TCAttestations,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    TCAudits: {
        name: "Audit Results",
        component: TCAudits,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    TCRequests: {
        name: "Requests",
        component: TCRequests,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    PPStatus: {
        name: 'Status',
        component: PayrollStatus,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    PPTimeCards: {
        name: 'Time Cards',
        component: TimeCardsGrid,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    PPSummary: {
        name: "Summary",
        component: PPSummary,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    PPDetails: {
        name: "Details",
        component: PayrollPeriod,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    PPLaborCost: {
        name: "Labor Cost",
        component: LaborCosts,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    PPCostRates: {
        name: "Cost Rates",
        component: CostRates,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    },
    PPChecks: {
        name: "Closure Process",
        component: PayrollChecks,
        hasExportToExcel: true,
        hasSetDefaultFilters: true,
    }
};

export const GetTabId = (tab: tabId | ITabSetting) => {
    return typeof tab === "string" ? tab : tab.id;
};

export const GetTabName = (tab: tabId | ITabSetting) => {
    if (typeof tab === 'object' && tab.name) return tab.name
    return TABS[GetTabId(tab)].name
}

export const IsTabExist = (tab: string | ITabSetting) => {
    const id = typeof tab === "string" ? tab : tab.id;
    return !!TABS[id as tabId]
}
