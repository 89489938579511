import {Component} from 'react'
import {DocumentExtentions} from './interfaces'
import {DOCS_EXT, EMBED_BROWSER_EXT, IMAGES_EXT, VIDEOS_EXT} from './helpers'
import styles from './documentViewer.module.scss';
import Loader from '../Loader';
import Viewer from 'react-viewer';
import {Button} from '@progress/kendo-react-buttons';
import {isConterra} from '../../../helpers/settings';

interface props {
  containerId: string
  document: {
    id: number
    extension: DocumentExtentions
    previewUrl?: string
    alt?: string
  } | undefined
  documentLoading: boolean
  viewerBtn?: any
}

interface state {
  imgLoading: boolean
}

class DocumentViewer extends Component<props, state> {
  containerId: string = `${this.props.containerId}-viewer`

  constructor(props: props) {
    super(props)
    let ext = this.props.document?.extension.toLowerCase()
    this.state = {
      imgLoading: (ext && IMAGES_EXT.indexOf(ext) > -1) || false
    }
  }

  render() {
    return <div className={styles.DocumentContainer} id={this.containerId}>
      {this.props.documentLoading ?
        <Loader/> : this.props.document ? this.renderDocumentPreview() :
          <div className={styles.DetailsNoData}>No Document</div>}
    </div>
  }

  renderDocumentPreview = () => {
    let document = this.props.document
    if (!document || !document.previewUrl) return null
    let ext = document.extension.toLowerCase()
    if (IMAGES_EXT.indexOf(ext) > -1) {
      let cont = window.document.getElementById(this.containerId)
      // if (!cont) return null
      return <><Viewer
        // key={this.containerId}
        container={cont || undefined}
        visible={true}
        images={[{src: document?.previewUrl, alt: document?.alt || ''}]}
        noClose={true}
        noNavbar={true}
        changeable={false}
        showTotal={false}
        scalable={false}
        zoomSpeed={0.65}
        loop={false}
        minScale={1}
        disableKeyboardSupport={true}
      />
        {this.props.viewerBtn}
        <Button
          key={'imgbtn' + document.id}
          title='Print'
          className={styles.PrintBtn}
          icon="print"
          onClick={this.PrintImg}
        />
      </>
    } else if (DOCS_EXT.indexOf(ext) > -1 || EMBED_BROWSER_EXT.indexOf(ext) > -1) {
      return <iframe
        title="Document"
        src={document.previewUrl}
        width="100%"
        height="100%"
        frameBorder="0"
      ></iframe>
    } else if (VIDEOS_EXT.indexOf(ext.toLowerCase()) > -1) {
      if (isConterra) {
        return <iframe
          ref={(ref) => {
            let doc = ref?.contentWindow?.document;
            if (doc) {
              doc.open();
              doc.write(
                '<html><body style="margin:45px 0 130px 0;padding:0;"><embed id="video-player" src="' +
                this.props.document?.previewUrl +
                '" type="video/' +
                this.props.document?.extension +
                '" style="width:100%;height:100%;"/></body><html>'
              );
              doc.close();
            }
          }}
          id="video-player-frame"
          width="100%"
          height="100%"
          frameBorder="0"></iframe>
      } else {
        return <video
          data-file-id={document.id}
          id="video-player"
          src={document.previewUrl}
          controls
          style={{width: '100%', height: '100%'}}
        />
      }
    } else {
      return <div>File preview is not supported. Please use download
        button.</div>
    }
  }

  OnImgLoad = () => {
    this.setState({imgLoading: false})
  }

  PrintImg = (e: any) => {
    let document = this.props.document
    if (!document || !document.previewUrl) return
    let win = window.open();
    if (win) {
      win.document.write('<img style="max-width: 100; min-width: 300px;" onload="imgOnload()" src="' + document.previewUrl + '"><script>window.imgOnload = function(){ window.print(); }</script>');
      win.document.close();
      win.focus()
      win.onafterprint = win.close
    }
  }

}

export default DocumentViewer
