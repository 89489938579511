import {Component} from 'react';
import {Button, ButtonProps} from '@progress/kendo-react-buttons';
import {simpleObject} from '../../../helpers/interfaces'
import {Loader, LoaderThemeColor} from '@progress/kendo-react-indicators';
import styles from './form.module.scss'

interface props {
    ButtonProps: ButtonProps
    processing: boolean
    style?: simpleObject
    className?: string
    loaderThemeColor?: LoaderThemeColor
}

class ProcessingButton extends Component<props> {
    render() {
        return (<div
            className={`${styles.ProcessingButtonBox} ${this.props.className}`}
            style={this.props.style}
        >
            {this.props.processing &&
                <Loader
                    className={styles.ProcessingButtonLoader}
                    themeColor={this.props.loaderThemeColor || "dark"}
                    type='converging-spinner'
                    size="small"
                />}
            <Button
                {...this.props.ButtonProps}
                style={this.props.processing ? {opacity: 0, zIndex: -1} : undefined}
            ></Button>
        </div>);
    }
}

export default ProcessingButton;
