import React, {Component} from 'react';
import {Button} from '@progress/kendo-react-buttons';
import {formatHoursDuration, formattedHoursToDuration, getActualDuration} from '../../../helpers/helpers';
import styles from './form.module.scss';

interface props {
    start: string
    finish: string
    duration: number | null // float
    onChange(hours: number | null, value: string): any
}

interface state {
    hours: string
    minutes: string
}

class DurationInput extends Component<props, state> {
    InutRef: any = React.createRef()
    cursorPosition: number = 0
    FullInutRef: any = React.createRef()
    MinutesInutRef: any = React.createRef()
    HoursInutRef: any = React.createRef()
    hours: number | null
    minutes: number | null

    constructor(props: props) {
        super(props)
        let durationString = this.props.duration ? formatHoursDuration(this.props.duration) : ''
        let [hours, minutes] = durationString.split(':')
        if (hours && !minutes) minutes = '00'
        this.hours = +hours
        this.minutes = +minutes
        this.state = {
            hours,
            minutes
        }
    }

    render() {
        let showClearBtn = (!!this.props.start && !!this.props.finish) || (!!this.state.hours || !!this.state.minutes)
        return <div className={styles.DurationInputBox}>
            <input
                ref={this.HoursInutRef}
                className={styles.DurationInputHours}
                type="number"
                min={0}
                value={this.state.hours}
                placeholder={'--'}
                onClick={this.OnHoursInputClick}
                onKeyDown={this.OnHoursInputKeyDown}
                onKeyPress={this.OnHoursInputKeyPress}
                onChange={this.OnHoursInputChange}
            />
            <span className={styles.DurationInputSeparator}>:</span>
            <input
                ref={this.MinutesInutRef}
                className={styles.DurationInputMinutes}
                value={this.state.minutes}
                type="number"
                min={0}
                max={59}
                placeholder={'--'}
                onClick={this.OnMinutesInputClick}
                onKeyDown={this.OnMinutesInputKeyDown}
                onChange={this.OnMinutesInputChange}
            />
            <div className={styles.DurationInputButtons}>
                {!!showClearBtn && <Button
                    className={styles.DurationInputClear}
                    key="clear"
                    icon="close"
                    fillMode="flat"
                    onClick={this.ClearValue}
                ></Button>}
                {!!this.props.start && !!this.props.finish && <Button
                    className={styles.DurationInputReset}
                    key="reset"
                    icon="reset"
                    fillMode="flat"
                    title="Restore Hours"
                    onClick={this.RestoreValue}
                ></Button>}
            </div>
            <input
                ref={this.FullInutRef}
                onClick={this.OnFullInputClick}
                className={styles.DurationInputBgInput}
            />
        </div>
    }

    IsDotOrComa = (key: number) => {
        return key === 44 || key === 46 // dot 46, comma 44, semicolon 58
    }

    OnHoursInputClick = () => {
        if (this.HoursInutRef.current) {
            this.HoursInutRef.current.select()
        }
    }

    OnMinutesInputClick = () => {
        if (this.MinutesInutRef.current) {
            this.MinutesInutRef.current.select()
        }
    }

    OnFullInputClick = () => {
        if (this.HoursInutRef.current) {
            this.HoursInutRef.current.focus()
        }
    }

    OnHoursInputKeyDown = (e: any) => {
        let key = e.nativeEvent.which || e.nativeEvent.keyCode;
        if (this.IsDotOrComa(key)) {
            e.preventDefault()
        } else if (key == 39) { // arrow right
            if (this.MinutesInutRef.current) {
                this.MinutesInutRef.current.focus()
                this.MinutesInutRef.current.select()
                e.preventDefault()
            }
        }
    }

    OnMinutesInputKeyDown = (e: any) => {
        var key = e.nativeEvent.which || e.nativeEvent.keyCode;
        if (this.IsDotOrComa(key)) {
            e.preventDefault()
        } else if (key == 37) { // arrow left
            if (this.HoursInutRef.current) {
                this.HoursInutRef.current.select()
                this.HoursInutRef.current.focus()
                e.preventDefault()
            }
        }
    }

    OnHoursInputKeyPress = (e: any) => {
        var key = e.nativeEvent.which || e.nativeEvent.keyCode;
        if (key == 58 || this.IsDotOrComa(key)) { //semicolon 58
            if (this.MinutesInutRef.current) this.MinutesInutRef.current.focus()
            e.preventDefault()
        }
    }

    OnHoursInputChange = (e: any) => {
        let value = e.target.value
        this.hours = +value
        if (this.hours && this.minutes === null) this.minutes = 0
        this.setState((state) => ({
            hours: value,
            minutes: this.minutes ? state.minutes : '00'
        }))
        this.OnChange()
    }

    OnMinutesInputChange = (e: any) => {
        let value = e.target.value
        if (+value > 59) {
            value = '59'
        }
        this.minutes = +value
        if (this.minutes && this.hours === null) this.hours = 0
        this.setState((state) => ({
            minutes: value,
            hours: this.hours ? state.hours : '00'
        }))
        this.OnChange()
    }

    OnChange = () => {
        if (this.hours !== null && this.minutes !== null) {
            let hoursString = this.hours.toString().length === 1 ? ('0' + this.hours) : this.hours
            let minutesString = this.minutes.toString().length === 1 ? ('0' + this.minutes) : this.minutes //??
            if (hoursString && !minutesString) minutesString = '00'
            let timeString = `${hoursString}:${minutesString}`
            let hours = formattedHoursToDuration(timeString)
            this.props.onChange(hours, timeString)
        } else {
            this.props.onChange(null, '')
        }
    }

    ClearValue = () => {
        this.hours = this.props.start ? 0 : null
        this.minutes = this.props.start ? 0 : null
        let clearValue = ''
        this.setState({hours: clearValue, minutes: clearValue})
        this.OnChange()
    }

    RestoreValue = () => {
        let [hoursDuration, timeString] = getActualDuration(this.props.start, this.props.finish)
        let [hours, minutes] = timeString.split(':')
        this.hours = +hours
        this.minutes = +minutes
        this.setState({
            hours: hours == '00' && minutes == '00' ? '' : hours,
            minutes: hours == '00' && minutes == '00' ? '' : minutes
        })
        this.props.onChange(hoursDuration, timeString)
    }

}

export default DurationInput;
