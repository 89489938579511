import {filterBy, orderBy, process} from "@progress/kendo-data-query";
import {Button, Chip, ToolbarSpacer} from "@progress/kendo-react-buttons";
import {ExcelExport, ExcelExportColumn,} from "@progress/kendo-react-excel-export";
import {Grid, GridCellProps, GridColumn as Column, GridRowProps, GridToolbar,} from "@progress/kendo-react-grid";
import {Checkbox, Input, TextArea} from "@progress/kendo-react-inputs";
import moment from "moment";
import React from "react";
import BaseComponent from "../../Components/BaseComponent";
import OpenCardLink from "../../Components/Common/Buttons/OpenCardLink";
import FilterCombobox from "../../Components/Common/Form/FilterCombobox";
import MultiSelectTree from "../../Components/Common/Form/MultiSelectTree";
import Loader from "../../Components/Common/Loader";
import {ModalRef} from "../../Components/Common/Modal/Modal";
import BooleanFilter from "../../Components/Dashboard/BooleanFilter";
import CustomColumnMenu from "../../Components/Dashboard/ColumnMenu";
import GridFilterCell from "../../Components/Dashboard/GridFilterCell";
import {default as dashboardStyles, default as gridStyles,} from "../../Components/Dashboard/dashboard.module.scss";
import {
    DisableColumnMenuBtn,
    GetDefaultGridFilter,
    GridRowHeight,
    IsComplexGridFilter,
} from "../../Components/Dashboard/helpers";
import {
    gridChangeDataAction,
    IColumnValue,
    IGridFilter,
    IGridFilterItem,
    IHiddenColumn,
} from "../../Components/Dashboard/interfaces";
import Switch from "../../Components/Switch/Switch";
import commonStyles from "../../assets/styles/common.module.scss";
import {IComboboxItem, ISortGridItem, simpleObject,} from "../../helpers/interfaces";
import {ReferenceRecordsDataSource} from "../../helpers/queries";
import {RunScriptAsync} from "../../helpers/runscripts";
import GridDatePicker from "./GridDatePicker";
import {
    groupKeys,
    IColumn,
    IGroupRow,
    IMilestoneItem,
    IMSColumn,
    IMSGroup,
    IMSGroups,
    IUpdateParams,
    msKeys,
    rowType,
} from "./interfaces";
import styles from "./milestones.module.scss";
import CardManagement from "../../Components/Cards/CardManagement";

interface props {
    buildPlanId?: number;
    doNotSaveFilters?: boolean;
    isActive: boolean;

    onChangeGridData?(
        data: Array<simpleObject>,
        action: gridChangeDataAction
    ): void;
}

interface state {
    loading: boolean;
    loadingFiltersData: boolean;
    gridData: Array<IMilestoneItem | IGroupRow | simpleObject>;
    editKey: string | null;
    projects: Array<IComboboxItem>;
    scenarios: Array<IComboboxItem>;
    regions: Array<IComboboxItem>;
    classes: Array<IComboboxItem>;
    gridFilter: IGridFilter;
    sort: Array<ISortGridItem>;
    buildPlans: Array<IComboboxItem>;
    mailstonesFilter: Array<IComboboxItem>;
    employees: Array<IComboboxItem>;
    cxStatuses: Array<IComboboxItem>;
    permitStatuses: Array<IComboboxItem>;
    skip: number;
    pageSize: number;
    shownGridInfo: [number, number];
    remountKey: number;
    grouped: boolean;
}

const dateRangeValues: Array<IComboboxItem> = [
    {
        Id: "today",
        Name: "Today",
    },
    {
        Id: "thisWeek",
        Name: "This Week",
    },
    {
        Id: "last7Days",
        Name: "Last 7 Days",
    },
    {
        Id: "last30Days",
        Name: "Last 30 Days",
    },
    {
        Id: "thisMonth",
        Name: "This Month",
    },
    {
        Id: "tomorrow",
        Name: "Tomorrow",
    },
    {
        Id: "next7Days",
        Name: "Next 7 Days",
    },
    {
        Id: "thisYear",
        Name: "This Year",
    },
    {
        Id: "isNotNull",
        Name: "Is not Null",
    },
    {
        Id: "isNull",
        Name: "Is Null",
    },
];

const sortByName = (a: IColumnValue, b: IColumnValue) =>
    a.Name.localeCompare(b.Name);
const GROUP_FILTERS_LS = "MS_GroupFilter";
const MS_FILTERS_LS = "MS_MSFilter";
const MS_FILTER_VALUES_LS = "MS_ColumnValuesFilter";
const MS_COMPLEX_FILTER_LS = "MS_ComplexFilter";

class Milestones extends BaseComponent<props, state> {
    gridRef: any;
    exportGridRef: any;
    exportGridNoGroupRef: any;
    isPage = !this.props.doNotSaveFilters;
    bpId = this.props.buildPlanId;
    groups: IMSGroups = {};
    columnValues: { [key in msKeys]?: Array<IColumnValue> } = {};
    MSFilter: {
        [key in msKeys]?:
        | IComboboxItem
        | boolean
        | string
        | {
        operator: string;
        value: number | null;
    };
    } = {};
    groupFilter: { [key in groupKeys]?: IComboboxItem };
    columnValuesFilter: { [key: string]: Array<IGridFilterItem> } = {};
    complexGridFilter: Array<IGridFilter> | null = null;
    ResponsibleMSFilters: { [key: number]: Array<number> };
    MSColumnsFilter: { MSId: Array<number>; MSColumns: Array<string> };
    groupMode: boolean = this.props.buildPlanId
        ? true
        : localStorage.getItem("MS_Group") !== "false";
    BPColumns: Array<IColumn>;
    MSColumns: Array<IMSColumn>;
    BPMenuColumns: IHiddenColumn;

    constructor(props: any) {
        super(props);
        this.ResponsibleMSFilters = {};
        if (!this.props.doNotSaveFilters) {
            let savedMSFilters = localStorage.getItem(MS_FILTERS_LS);
            if (savedMSFilters) this.MSFilter = JSON.parse(savedMSFilters);
            let savedValuesJSON = localStorage.getItem(MS_FILTER_VALUES_LS);
            if (savedValuesJSON) {
                this.columnValuesFilter = JSON.parse(savedValuesJSON);
                if (!this.groupMode) {
                    for (let field in this.columnValuesFilter) {
                        let msId = field.split("_")[1];
                        if (msId) {
                            this.ResponsibleMSFilters[+msId] = this.columnValuesFilter[
                                field
                                ].map((filter) => filter.value);
                        }
                    }
                }
            }
            let savedComplexFilter = localStorage.getItem(MS_COMPLEX_FILTER_LS);
            if (savedComplexFilter)
                this.complexGridFilter = JSON.parse(savedComplexFilter);
        }

        this.state = {
            loading: true,
            loadingFiltersData: true,
            gridData: [],
            editKey: null,
            gridFilter: this.GetFullGridFilters(true),
            sort: [],
            projects: [],
            scenarios: [],
            regions: [],
            classes: [],
            buildPlans: [],
            mailstonesFilter: [],
            employees: [],
            cxStatuses: [],
            permitStatuses: [],
            skip: 0,
            pageSize: 20,
            shownGridInfo: [0, 0],
            remountKey: +new Date(),
            grouped: this.groupMode,
        };

        let savedGroupFilter = !this.props.buildPlanId
            ? localStorage.getItem(GROUP_FILTERS_LS)
            : null;
        this.groupFilter = savedGroupFilter ? JSON.parse(savedGroupFilter) : {};

        this.BPColumns = [
            {
                field: "PermitStatusName",
                fieldId: "PermitStatusId",
                title: "Permit Status",
                width: 200,
                columnMenu: true,
            },
            {
                field: "PermitStatusComment",
                title: "Permit Status Comment",
                width: 200,
            },
            {
                field: "CxStatusName",
                fieldId: "CxStatusId",
                title: "Cx Status",
                width: 200,
                columnMenu: true,
            },
            {
                field: "CxStatusComment",
                title: "Cx Status Comment",
                width: 200,
            },
            {
                field: "ActualDaysOnSite",
                title: "Days on Site",
                width: 120,
                type: "numeric",
            },
            {
                field: "PlannedDaysOnSite",
                title: "Planned Days on Site",
                width: 120,
                type: "numeric",
            },
            {
                field: "Progress",
                title: "Progress",
                width: 120,
                type: "numeric",
            },
        ];

        let savedBPMenuColumns = localStorage.getItem("MS_BPColumnsSettings");
        if (savedBPMenuColumns) {
            this.BPMenuColumns = JSON.parse(savedBPMenuColumns);
        } else {
            this.BPMenuColumns = {
                BPName: {
                    hidden: false,
                    name: "Build Plan",
                    order: undefined,
                    readonly: true,
                },
            };
            for (let column of this.BPColumns) {
                this.BPMenuColumns[column.field] = {
                    hidden: false,
                    name: column.title,
                    order: undefined,
                    readonly: false,
                };
            }
        }

        this.MSColumns = [
            {
                field: "ProjectedDate",
                title: "Projected",
                width: 140,
                filterable: true,
                renderCell: this.renderDateCell,
                select: false,
            },
            {
                field: "ActualDate",
                title: "Actual",
                width: 140,
                filterable: true,
                renderCell: this.renderDateCell,
                select: false,
            },
            {
                field: "ResponsibleName",
                fieldId: "ResponsibleId",
                title: "Responsible",
                width: 200,
                filterable: true,
                renderCell: this.renderResponsibleCell,
                columnMenu: true,
                select: false,
            },
            {
                field: "Comments",
                title: "Comments",
                width: 300,
                filterable: true,
                renderCell: this.renderCommentsCell,
                select: false,
            },
        ];

        let savedMSColumnsFilter = localStorage.getItem("MSColumnsFilter");
        this.MSColumnsFilter = savedMSColumnsFilter
            ? JSON.parse(savedMSColumnsFilter)
            : this.GetEmptyMSColumnsFilter();
        if (this.MSColumnsFilter.MSColumns.length) {
            for (let detail of this.MSColumns) {
                detail.select =
                    this.MSColumnsFilter.MSColumns.findIndex(
                        (item) => item === detail.field
                    ) > -1;
            }
        }
    }

    componentDidMount() {
        this.Init();
        this.InitFilters();
    }

    componentDidUpdate() {
        if (
            !this.isPage &&
            this.props.isActive &&
            this.bpId !== this.props.buildPlanId
        ) {
            super.componentWillUnmount();
            this.bpId = this.props.buildPlanId;
            this.groups = {};
            this.Init();
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.document.removeEventListener("resize", this.RefreshPageSize);
    }

    Init = async () => {
        this.setState({loading: true, editKey: null});
        try {
            let results: Array<any> = await Promise.allSettled([
                this.LoadEmployees(),
                this.LoadCxStatuses(),
                this.LoadPermitStatuses(),
                this.LoadData(),
            ]);
            let employees = [];
            let cxStatuses = [];
            let permitStatuses = [];
            let milestones = [];
            let groups = [];
            if (results[0].status === "fulfilled" && results[0].value[0].length) {
                employees = results[0].value[0];
            }
            if (results[1].status === "fulfilled" && results[1].value[0].length) {
                cxStatuses = results[1].value[0];
            }
            if (results[2].status === "fulfilled" && results[2].value[0].length) {
                permitStatuses = results[2].value[0];
            }
            if (
                results[3].status === "fulfilled" &&
                (results[3].value[0].length || results[3].value[1].length)
            ) {
                groups = results[3].value[0];
                milestones = results[3].value[1];
                this.PrepareGridData(
                    groups,
                    milestones,
                    employees,
                    cxStatuses,
                    permitStatuses
                );
            }
        } finally {
            this.setState({loading: false});
        }
    };

    InitFilters = async () => {
        this.setState({loadingFiltersData: true});
        try {
            let results: Array<any> = await Promise.allSettled([
                this.LoadFilterData("FSMMilestones"),
                this.LoadFilterData("FSMProjectTemplates"),
                this.LoadFilterData("FSMProjectScenarios"),
                this.LoadFilterData("FSMRegions"),
                this.LoadFilterData("Classes"),
            ]);
            let mailstonesFilter = [];
            if (results[0].status === "fulfilled" && results[0].value[0].length) {
                mailstonesFilter = results[0].value[0];
            }
            this.setState({mailstonesFilter});
            if (this.isPage) {
                let projects = [];
                let scenarios = [];
                let regions = [];
                let classes = [];
                if (
                    results[1].status === "fulfilled" &&
                    results[1].value &&
                    results[1].value[0].length
                ) {
                    projects = results[1].value[0];
                }
                if (
                    results[2].status === "fulfilled" &&
                    results[2].value &&
                    results[2].value[0].length
                ) {
                    scenarios = results[2].value[0];
                }
                if (
                    results[3].status === "fulfilled" &&
                    results[3].value &&
                    results[3].value[0].length
                ) {
                    regions = results[3].value[0];
                }
                if (
                    results[4].status === "fulfilled" &&
                    results[4].value &&
                    results[4].value[0].length
                ) {
                    classes = results[4].value[0];
                }
                this.setState({projects, scenarios, regions, classes});
            }
        } finally {
            this.setState({loadingFiltersData: false});
        }
    };

    PrepareGridData = (
        groups: Array<IMSGroup>,
        milestones: Array<IMilestoneItem>,
        employees: Array<IComboboxItem>,
        cxStatuses: Array<IComboboxItem>,
        permitStatuses: Array<IComboboxItem>
    ) => {
        let buildPlans: Array<IComboboxItem> = [];
        let groupsObject: IMSGroups = {};
        this.columnValues.BPId = [];
        let savedBP = this.columnValuesFilter.BPId;
        let unicIncludedPermitStatuses: { [key: number]: boolean } = {};
        let unicIncludedCxStatuses: { [key: number]: boolean } = {};
        let savedCxStatuses = this.columnValuesFilter.CxStatusId;
        let savedPermitStatuses = this.columnValuesFilter.PermitStatusId;
        this.columnValues.CxStatusId = [];
        this.columnValues.PermitStatusId = [];
        for (let group of groups) {
            group.headerRows = [];
            if (this.isPage) {
                group.headerRows.push({
                    rowType: "buildPlan",
                    BPId: group.BPId,
                    key: 'title' + group.BPId
                });
            }
            group.headerRows.push(
                {
                    rowType: "permitStatus",
                    BPId: group.BPId,
                    key: 'permitStatus' + group.BPId
                },
                {rowType: "cxStatus", BPId: group.BPId, key: 'cxStatus' + group.BPId},
                {rowType: "counts", BPId: group.BPId, key: 'counts' + group.BPId}
            );
            group.items = [];
            groupsObject[group.BPId] = group;
            if (group.CxStatusId) {
                group.CxStatus = cxStatuses.find(
                    (status: IComboboxItem) => status.Id === group.CxStatusId
                );
                if (group.CxStatus && !unicIncludedCxStatuses[group.CxStatusId]) {
                    unicIncludedCxStatuses[group.CxStatusId] = true;
                    this.columnValues.CxStatusId.push({
                        ...group.CxStatus,
                        Selected: savedCxStatuses
                            ? !!savedCxStatuses.find(
                                (filter: IGridFilterItem) => group.CxStatusId === filter.value
                            )
                            : true,
                        FieldId: "CxStatusId",
                    });
                }
            }
            if (group.PermitStatusId) {
                group.PermitStatus = permitStatuses.find(
                    (status: IComboboxItem) => status.Id === group.PermitStatusId
                );
                if (
                    group.PermitStatus &&
                    !unicIncludedPermitStatuses[group.PermitStatusId]
                ) {
                    unicIncludedPermitStatuses[group.PermitStatusId] = true;
                    this.columnValues.PermitStatusId.push({
                        ...group.PermitStatus,
                        Selected: savedPermitStatuses
                            ? !!savedPermitStatuses.find(
                                (filter: IGridFilterItem) =>
                                    group.PermitStatusId === filter.value
                            )
                            : true,
                        FieldId: "PermitStatusId",
                    });
                }
            }
            buildPlans.push({Id: group.BPId, Name: group.BPName});
            this.columnValues.BPId.push({
                Id: group.BPId,
                Name: group.BPName,
                Selected: savedBP
                    ? !!savedBP.find(
                        (filter: IGridFilterItem) => group.BPId === filter.value
                    )
                    : true,
                FieldId: "BPId",
            });
        }
        let unicIncludedMilestones: { [key: number]: boolean } = {};
        let unicIncludedEmloyees: { [key: number]: boolean } = {};
        this.columnValues.ResponsibleId = [];
        this.columnValues.MSId = [];
        let savedResponsible = this.columnValuesFilter.ResponsibleId;
        let savedMilestones = this.columnValuesFilter.MSId;

        for (let item of milestones) {
            item.key = `bp${item.BPId}ms${item.MSId}`
            item.rowType = "milestone";
            item.Applied = item.Applicable ? "Yes" : "No";
            if (item.ProjectedDate)
                item.ProjectedDate = moment(item.ProjectedDate).toDate();
            if (item.ActualDate) item.ActualDate = moment(item.ActualDate).toDate();
            if (item.ResponsibleId) {
                item.Responsible =
                    employees.find((employee) => employee.Id === item.ResponsibleId) ||
                    null;
                item.ResponsibleName = item.Responsible?.Name || null;
                if (item.Responsible && !unicIncludedEmloyees[item.ResponsibleId]) {
                    unicIncludedEmloyees[item.ResponsibleId] = true;
                    this.columnValues.ResponsibleId.push({
                        ...item.Responsible,
                        FieldId: "ResponsibleId",
                        Selected: savedResponsible
                            ? !!savedResponsible.find(
                                (filter: IGridFilterItem) =>
                                    item.ResponsibleId === filter.value
                            )
                            : true,
                    });
                }
            } else {
                item.Responsible = null;
                item.ResponsibleName = null;
            }
            if (item.MSId && !unicIncludedMilestones[item.MSId]) {
                unicIncludedMilestones[item.MSId] = true;
                let Select = false;
                if (this.groupMode) {
                    Select = savedMilestones
                        ? !!savedMilestones.find(
                            (filter: IGridFilterItem) => item.MSId === filter.value
                        )
                        : true;
                } else if (this.MSColumnsFilter.MSId.length) {
                    Select =
                        this.MSColumnsFilter.MSId.findIndex((id) => id === item.MSId) > -1;
                }
                this.columnValues.MSId.push({
                    Id: item.MSId,
                    Name: item.MSName,
                    FieldId: "MSId",
                    Selected: Select,
                    Order: item.MSOrder,
                });
            }
            let group = groupsObject[item.BPId];
            Object.assign(item, {
                BPName: group.BPName,
                CxStatusComment: group.CxStatusComment,
                CxStatusId: group.CxStatusId,
                CxStatusName: group.CxStatus?.Name || null,
                PermitStatusComment: group.PermitStatusComment,
                PermitStatusId: group.PermitStatusId,
                PermitStatusName: group.PermitStatus?.Name || null,
                ActualDaysOnSite: group.ActualDaysOnSite,
                PlannedDaysOnSite: group.PlannedDaysOnSite,
                Progress: group.Progress,
            });
            groupsObject[item.BPId].items.push(item);
        }

        for (let key in this.columnValues) {
            // @ts-ignore
            if (key !== "MSId") this.columnValues[key].sort(sortByName);
            else {
                // @ts-ignore
                this.columnValues[key].sort(
                    // @ts-ignore
                    (a: IColumnValue, b: IColumnValue) => a.Order - b.Order
                );
            }
        }

        this.groups = groupsObject;
        this.setState({buildPlans, employees, cxStatuses, permitStatuses});
        this.SetFinalMilestones("dataLoaded", +new Date());
    };

    initGridRef = (ref: any) => {
        if (!this.gridRef) {
            this.gridRef = ref;
            this.RefreshPageSize();
            window.addEventListener("resize", this.RefreshPageSize);
        }
    };

    initExportRef = (exporter: any) => {
        this.exportGridRef = exporter;
    };

    initExportNoGroupRef = (exporter: any) =>
        (this.exportGridNoGroupRef = exporter);

    IsMSField = (field: string): field is msKeys => {
        return (field as msKeys) !== undefined;
    };

    render() {
        let containerHeight = this.props.buildPlanId ? "100%" : "100vh";
        let IsFiltersInDefault = this.IsFiltersInDefault();
        return (
            <div
                className={styles.Container}
                style={{height: containerHeight}}
            >
                {this.state.loading && <Loader/>}
                <Grid
                    className={`${styles.MilestonesGrid} ${
                        this.state.grouped ? "" : styles.MilestonesGridNoGrouped
                    }`}
                    style={{height: "100%"}}
                    data={this.state.gridData.slice(
                        this.state.skip,
                        this.state.skip + this.state.pageSize + 2
                    )}
                    // columnVirtualization={!this.state.grouped}
                    scrollable={"virtual"}
                    rowHeight={GridRowHeight}
                    pageSize={this.state.pageSize}
                    skip={this.state.skip}
                    total={this.state.gridData.length}
                    onPageChange={this.OnChangePage}
                    resizable={true}
                    editField="inEdit"
                    onRowClick={this.OnRowClick}
                    filterable={true}
                    ref={this.initGridRef}
                    rowRender={this.renderRow}
                    filter={this.state.gridFilter}
                    sortable={true}
                    sort={this.state.sort}
                    onSortChange={this.OnSort}
                >
                    {!this.props.buildPlanId && (
                        <GridToolbar>
                            <div className={dashboardStyles.ModeSwitchContainer}>
                                <span style={{margin: "0 5px"}}>Group by BP</span>
                                <Switch
                                    style={{marginRight: -5}}
                                    checked={this.groupMode}
                                    id="groupByMilestones"
                                    onChange={this.OnSwitchGrouping}
                                />
                            </div>

                            <div className={dashboardStyles.ToolbarContent}>
                                <div className={dashboardStyles.ToolbarRow}>
                                    <span className={styles.ToolbarInfoLabel}>Build Plans:</span>
                                    <Chip
                                        text={this.state.shownGridInfo[0] + ""}
                                        className={dashboardStyles.ChipCounter}
                                    />
                                    {this.renderFilter(
                                        "BPId",
                                        this.state.buildPlans,
                                        "Filter by BP",
                                        styles.ToolbarLargeCombobox
                                    )}
                                    {this.renderFilter(
                                        "BPOId",
                                        this.state.employees,
                                        "Filter by BP Owner"
                                    )}
                                    {this.renderFilter(
                                        "ProjectId",
                                        this.state.projects,
                                        "Filter by Project"
                                    )}
                                    <Checkbox
                                        id="overdue"
                                        key={this.state.remountKey + "Overdue"}
                                        defaultChecked={!!this.MSFilter.IsOverdue}
                                        onChange={this.OnChangeOverDue}
                                        label="Only Overdue"
                                    />
                                    <ToolbarSpacer/>
                                    <Button
                                        icon="filter-clear"
                                        title="Set Default Filters"
                                        className={
                                            IsFiltersInDefault ? "" : dashboardStyles.BlueResetBtn
                                        }
                                        onClick={this.ClearFilters}
                                    />
                                    <Button
                                        icon="file-excel"
                                        title="Export to Excel"
                                        onClick={this.ExportToExcel}
                                        className={styles.RightBtn}
                                    />
                                    <Button
                                        icon="refresh"
                                        onClick={this.Refresh}
                                        className={styles.RightBtn}
                                    />
                                </div>
                                <div className={dashboardStyles.ToolbarRow}>
                                    <span className={styles.ToolbarInfoLabel}>Milestones:</span>
                                    <Chip
                                        text={this.state.shownGridInfo[1] + ""}
                                        className={dashboardStyles.ChipCounter}
                                    />
                                    {this.renderFilter(
                                        "ClassId",
                                        this.state.classes,
                                        "Filter by Class",
                                        styles.ToolbarLargeCombobox
                                    )}
                                    {this.renderFilter(
                                        "ScenarioId",
                                        this.state.scenarios,
                                        "Filter by Scenario"
                                    )}
                                    {this.renderFilter(
                                        "RegionId",
                                        this.state.regions,
                                        "Filter by Region"
                                    )}
                                    <Checkbox
                                        id="onlyMine"
                                        key={this.state.remountKey + "Mine"}
                                        defaultChecked={!!this.MSFilter.IsMy}
                                        onChange={this.OnChangeOnlyMy}
                                        label="Only Mine"
                                    />
                                    <ToolbarSpacer/>
                                    {!this.state.grouped && (
                                        <>
                                            <MultiSelectTree
                                                key={this.state.remountKey + "msColumns"}
                                                data={this.columnValues.MSId || []}
                                                dataAttr="MS"
                                                className={styles.MSMultiSelect}
                                                textField="Name"
                                                idField="Id"
                                                checkedField="Selected"
                                                placeholder="Milestones Columns"
                                                itemName="Milestone"
                                                onChange={this.OnChangeMSColumnsFilter}
                                            />
                                            <MultiSelectTree
                                                key={this.state.remountKey + "msDetails"}
                                                data={this.MSColumns}
                                                dataAttr="Details"
                                                className={styles.DetailsMultiSelect}
                                                textField="title"
                                                idField="field"
                                                checkedField="select"
                                                placeholder="Milestones Details"
                                                itemName="Detail"
                                                onChange={this.OnChangeMSColumnsFilter}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </GridToolbar>
                    )}
                    {this.GetColumns()}
                </Grid>
                {this.state.grouped
                    ? this.renderExcelGrid()
                    : this.renderNoGroupedExcelGrid()}
            </div>
        );
    }

    IsFiltersInDefault = () => {
        let filteredCount = 0;
        for (let key in this.columnValuesFilter) {
            let filter = this.columnValuesFilter[key];
            filteredCount += filter.length;
        }

        for (let key in this.MSColumnsFilter) {
            // @ts-ignore
            let filter = this.MSColumnsFilter[key];
            filteredCount += filter.length;
        }

        for (let key in this.ResponsibleMSFilters) {
            // @ts-ignore
            let filter = this.ResponsibleMSFilters[key];
            filteredCount += filter.length;
        }

        if (
            (this.MSFilter && Object.keys(this.MSFilter).length) ||
            (this.groupFilter && Object.keys(this.groupFilter).length) ||
            filteredCount ||
            this.complexGridFilter !== null
        ) {
            return false;
        }
        return true;
    };

    GetColumns = () => {
        let columns = [];

        if (this.state.grouped) {
            columns.push(
                <Column
                    key="Applicable"
                    field="Applicable"
                    title=" "
                    width="36px"
                    filterCell={this.renderApplicableFilterCell}
                    cell={this.renderApplicableCell}
                />
            );

            columns.push(
                <Column
                    key="MSName"
                    field="MSName"
                    title="Milestone"
                    width="225px"
                    editable={false}
                    headerClassName={`MSId ${
                        this.IsFilteredByValueFilter("MSId")
                            ? gridStyles.FilteredColumnTH
                            : ""
                    }`}
                    filterCell={this.renderGridFilter}
                    columnMenu={this.renderColumnMenu}
                />
            );

            for (let column of this.MSColumns) {
                columns.push(
                    <Column
                        key={column.field}
                        field={column.field}
                        title={column.title}
                        width={column.field === "Comments" ? undefined : column.width}
                        filterable={column.filterable}
                        cell={column.renderCell}
                        filterCell={
                            column.field === "Comments"
                                ? this.renderDefaultDashboardFilterCell
                                : this.renderGridFilter
                        }
                        columnMenu={column.columnMenu ? this.renderColumnMenu : undefined}
                        headerClassName={`${column.fieldId} ${
                            column.fieldId && this.hasFilters(column.field, column.fieldId)
                                ? gridStyles.FilteredColumnTH
                                : ""
                        }`}
                    />
                );
            }
        } else {
            columns.push(
                <Column
                    key={"BPName"}
                    field={"BPName"}
                    title={"Build Plan"}
                    width={200}
                    cell={this.renderGroupBPCell}
                    filterCell={this.renderGroupGridFilterCell}
                    columnMenu={this.renderColumnMenu}
                    headerClassName={`BPId ${
                        this.hasFilters("BPName", "BPId") ? gridStyles.FilteredColumnTH : ""
                    }`}
                    locked={true}
                />
            );

            if (!!this.columnValues.MSId) {
                for (let ms of this.columnValues.MSId) {
                    if (
                        !ms.Selected &&
                        this.columnValues.MSId &&
                        this.columnValues.MSId.findIndex((item) => item.Selected) > -1
                    ) {
                        continue;
                    }
                    columns.push(
                        <Column
                            key={"msc" + ms.Id}
                            title={ms.Name}
                            filterable={false}
                            headerClassName={commonStyles.TextCenter}
                            sortable={false}
                        >
                            {this.GetMSColumns(ms)}
                        </Column>
                    );
                }
            }

            for (let column of this.BPColumns) {
                if (
                    this.BPMenuColumns[column.field] &&
                    !this.BPMenuColumns[column.field].hidden
                ) {
                    columns.push(
                        <Column
                            key={column.field}
                            field={column.field}
                            title={column.title}
                            width={column.width}
                            filterable={column.filterable}
                            cell={this.renderGroupBPCell}
                            filterCell={
                                column.filterable !== false
                                    ? this.renderGroupGridFilterCell
                                    : undefined
                            }
                            filter={column.type}
                            columnMenu={this.renderColumnMenu}
                            headerClassName={`${column.fieldId} ${
                                column.fieldId && this.hasFilters(column.field, column.fieldId)
                                    ? gridStyles.FilteredColumnTH
                                    : ""
                            }`}
                        />
                    );
                }
            }
        }

        return columns;
    };

    GetMSColumns = (ms: IColumnValue) => {
        let cols = [];
        for (let column of this.MSColumns) {
            if (this.MSColumnsFilter.MSColumns.length && !column.select) continue;
            let columnKey = column.field + "_" + ms.Id;
            let fieldId = column.fieldId + "_" + ms.Id;
            let isFilteredColumn =
                // @ts-ignore
                column.fieldId && this.hasFilters(columnKey, fieldId);
            cols.push(
                <Column
                    key={columnKey}
                    field={columnKey}
                    title={column.title}
                    width={column.width}
                    cell={column.renderCell}
                    filterCell={
                        column.field === "Comments"
                            ? this.renderDefaultDashboardFilterCell
                            : this.renderGridFilter
                    }
                    columnMenu={column.columnMenu ? this.renderColumnMenu : undefined}
                    headerClassName={`${column.fieldId} ${
                        isFilteredColumn ? gridStyles.FilteredColumnTH : ""
                    }`}
                />
            );
        }
        return cols;
    };

    renderRow = (el: any, props: GridRowProps) => {
        if (!this.state.grouped) {
            return el;
        } else if (props.dataItem.rowType === "milestone") {
            return (
                <tr
                    {...el.props}
                    onContextMenu={this.OnRowContextMenu}
                    data-msid={props.dataItem.MSId}
                    data-bpid={props.dataItem.BPId}
                    key={props.dataItem.key}
                >
                    {el.props.children}
                </tr>
            );
        }
        return (
            <tr
                {...el.props}
                key={props.dataItem.key}
            >
                <td
                    colSpan={6}
                    className={styles.GroupTd}
                >
                    <div
                        className={`${styles.GroupRow} ${
                            props.dataItem.rowType === "counts" ? styles.GroupCountsRow : ""
                        }`}
                    >
                        {this.renderGroupRowContent(
                            props.dataItem.rowType,
                            props.dataItem.BPId
                        )}
                    </div>
                </td>
            </tr>
        );
    };

    renderGroupRowContent = (rowType: rowType, bpId: number) => {
        let groupData = this.groups[bpId];
        if (!groupData) return "no Data"; // for ts
        if (rowType === "buildPlan") {
            return this.renderBPLink(groupData);
        } else if (rowType === "permitStatus") {
            return (
                <>
                    <span className={styles.GroupTitle}>Permit Status</span>
                    {this.renderPermitStatusCombobox(groupData, "Permit Status")}
                    {this.renderPermitStatusCommentInput(groupData)}
                </>
            );
        } else if (rowType === "cxStatus") {
            return (
                <>
                    <span className={styles.GroupTitle}>Cx Status</span>
                    {this.renderCxStatusCombobox(groupData, "Cx Status")}
                    {this.renderCxStatusCommentInput(groupData)}
                </>
            );
        } else if (rowType === "counts") {
            return (
                <>
                    <span className={styles.GroupTitle}>Days on Site</span>
                    <Input
                        className={styles.GroupSmallField}
                        value={groupData.ActualDaysOnSite + ""}
                        disabled={true}
                    />
                    <span className={styles.GroupTitle}>of Planned</span>
                    {this.renderPlannedDaysInput(groupData)}
                    <span className={`${styles.GroupTitle}`}>Progress</span>
                    {this.renderProgressInput(groupData)}
                </>
            );
        }
    };

    renderGroupBPCell = (props: React.PropsWithChildren<GridCellProps>) => {
        let groupData = this.groups[props.dataItem.BPId];
        if (!groupData) return <td></td>;

        let field = props.field;
        let content: any;
        let isEditCellMode =
            props.dataItem.key === this.state.editKey &&
            groupData.CanManage;

        switch (field) {
            case "BPName":
                content = this.renderBPLink(groupData);
                break;
            case "PermitStatusName":
                if (isEditCellMode) {
                    content = this.renderPermitStatusCombobox(groupData);
                } else {
                    content = groupData.PermitStatus?.Name;
                }
                break;
            case "PermitStatusComment":
                if (isEditCellMode) {
                    content = this.renderPermitStatusCommentInput(groupData);
                } else {
                    content = (
                        <div
                            data-index={props.dataIndex}
                            onClick={this.OnGroupCommentCellClick}
                            className={styles.CommentCell}
                        >
                            {groupData.PermitStatusComment || ""}
                        </div>
                    );
                }
                break;
            case "CxStatusName":
                if (isEditCellMode) {
                    content = this.renderCxStatusCombobox(groupData);
                } else {
                    content = groupData.CxStatus?.Name;
                }
                break;
            case "CxStatusComment":
                if (isEditCellMode) {
                    content = this.renderCxStatusCommentInput(groupData);
                } else {
                    content = (
                        <div
                            data-index={props.dataIndex}
                            onClick={this.OnGroupCommentCellClick}
                            className={styles.CommentCell}
                        >
                            {groupData.CxStatusComment || ""}
                        </div>
                    );
                }
                break;
            case "ActualDaysOnSite":
                content = groupData.ActualDaysOnSite || "";
                break;
            case "PlannedDaysOnSite":
                if (isEditCellMode) {
                    content = this.renderPlannedDaysInput(groupData);
                } else {
                    content = groupData.PlannedDaysOnSite || "";
                }
                break;
            case "Progress":
                if (isEditCellMode) {
                    content = this.renderProgressInput(groupData);
                } else {
                    content = groupData.Progress;
                }
                break;
        }
        return (
            <td
                style={props.style}
                colSpan={props.colSpan}
                className={props.className}
            >
                {content}
            </td>
        );
    };

    renderBPLink = (groupData: IMSGroup) => {
        return (
            <OpenCardLink
                text={groupData.BPName}
                dataAttr={groupData.BPId}
                refName={"FSMBuildPlans"}
            />
        );
    };

    renderPermitStatusCombobox = (groupData: IMSGroup, placeholder?: string) => {
        return (
            <FilterCombobox
                className={styles.GroupCombobox}
                dataAttr={{bpId: groupData.BPId, field: "PermitStatusId"}}
                data={this.state.permitStatuses}
                onChange={this.OnChangeGroupCombobox}
                defaultValue={groupData.PermitStatus}
                placeholder={placeholder || ""}
                disabled={!groupData.CanManage}
            />
        );
    };

    renderCxStatusCombobox = (groupData: IMSGroup, placeholder?: string) => {
        return (
            <FilterCombobox
                className={styles.GroupCombobox}
                dataAttr={{bpId: groupData.BPId, field: "CxStatusId"}}
                data={this.state.cxStatuses}
                onChange={this.OnChangeGroupCombobox}
                defaultValue={groupData.CxStatus}
                placeholder={placeholder || ""}
                disabled={!groupData.CanManage}
            />
        );
    };

    renderPermitStatusCommentInput = (groupData: IMSGroup) => {
        return (
            <Input
                className={styles.GroupComment}
                disabled={!groupData.CanManage}
                type="text"
                data-bpid={groupData.BPId}
                data-field="PermitStatusComment"
                defaultValue={groupData.PermitStatusComment || ""}
                onKeyDown={this.OnChangeGridTextField}
                onBlur={this.OnChangeGridTextField}
            />
        );
    };

    renderCxStatusCommentInput = (groupData: IMSGroup) => {
        return (
            <div
                className={styles.CxStatusCommentBox}
                key={groupData.BPId + "cxcomment" + this.state.remountKey}
            >
                <Input
                    className={styles.GroupComment}
                    disabled={!groupData.CanManage}
                    type="text"
                    data-bpid={groupData.BPId}
                    data-field="CxStatusComment"
                    defaultValue={groupData.CxStatusComment || ""}
                    onKeyDown={this.OnChangeGridTextField}
                    onBlur={this.OnChangeGridTextField}
                />
                <div className={styles.CxStatusCommentBtns}>
                    <Button
                        icon="edit"
                        fillMode="flat"
                        onClick={this.OpenCxStatusCommentEdit}
                        data-bpid={groupData.BPId}
                    />
                </div>
            </div>
        );
    };

    renderPlannedDaysInput = (groupData: IMSGroup) => {
        return (
            <Input
                className={styles.GroupSmallField}
                data-bpid={groupData.BPId}
                data-field="PlannedDaysOnSite"
                type="number"
                disabled={!groupData.CanManage}
                defaultValue={groupData.PlannedDaysOnSite?.toString()}
                onKeyDown={this.OnChangeGridTextField}
                onBlur={this.OnChangeGridTextField}
            />
        );
    };

    renderProgressInput = (groupData: IMSGroup) => {
        return (
            <Input
                className={styles.GroupSmallField}
                disabled={!groupData.CanManage}
                defaultValue={groupData.Progress.toString()}
                data-bpid={groupData.BPId}
                data-field="Progress"
                type="number"
                onKeyDown={this.OnChangeGridTextField}
                onBlur={this.OnChangeGridTextField}
                max={100}
                min={0}
            />
        );
    };

    renderGroupGridFilterCell = (props: any) => {
        let field = props.field;
        if (field === "PermitStatusName" || field === "CxStatusName") {
            return this.renderGridFilter(props);
        }
        return this.renderDefaultDashboardFilterCell(props);
    };

    renderDefaultDashboardFilterCell = (props: any) => {
        let field = props.field;
        let defaultValue = props.value;

        return (
            <GridFilterCell
                key={props.field + this.state.remountKey}
                props={props}
                defaultValue={defaultValue}
                field={field}
                onChange={this.OnChangeMilestonesFilter}
                onOperatorChange={this.OnOperatorChange}
                clear={this.ClearQuickGridFilter}
            />
        );
    };

    renderApplicableCell = (props: React.PropsWithChildren<GridCellProps>) => {
        if (!this.groups[props.dataItem.BPId]) return <td></td>;
        return (
            <td>
                <Checkbox
                    disabled={!this.groups[props.dataItem.BPId].CanManage}
                    data-bpid={props.dataItem.BPId}
                    data-msid={props.dataItem.MSId}
                    checked={props.dataItem.Applicable}
                    onChange={this.OnChangeApplicable}
                    label=" "
                />
            </td>
        );
    };

    renderCommentsCell = (props: React.PropsWithChildren<GridCellProps>) => {
        let msId = props.field?.split("_")[1];
        let msCanChangeField = msId ? "CanChange_" + msId : "CanChange";
        // @ts-ignore
        let value = props.dataItem[props.field];
        if (
            value !== undefined &&
            props.dataItem.key === this.state.editKey &&
            props.dataItem[msCanChangeField]
        ) {
            return (
                <td>
                    <Input
                        style={{width: "100%"}}
                        type="text"
                        data-bpid={props.dataItem.BPId}
                        data-msid={msId || props.dataItem.MSId}
                        data-field="Comments"
                        // @ts-ignore
                        defaultValue={props.dataItem[props.field] || ""}
                        onKeyDown={this.OnChangeGridTextField}
                        onBlur={this.OnChangeGridTextField}
                    />
                </td>
            );
        }
        if (value === undefined) return this.renderNACell();
        return <td>{value}</td>;
    };

    renderNACell = () => {
        return <td className={styles.naCell}>N/A</td>;
    };

    renderResponsibleCell = (props: React.PropsWithChildren<GridCellProps>) => {
        // @ts-ignore
        let value = props.dataItem[props.field];
        if (
            value !== undefined &&
            props.dataItem.key === this.state.editKey &&
            this.groups[props.dataItem.BPId].CanManage
        ) {
            let msId = props.field?.split("_")[1];
            return (
                <td>
                    <FilterCombobox
                        className={styles.FilterCellCombobox}
                        dataAttr={{
                            bpId: props.dataItem.BPId,
                            field: "ResponsibleId",
                            msId: msId || props.dataItem.MSId,
                        }}
                        data={this.state.employees}
                        onChange={this.OnChangeResponsible}
                        defaultValue={
                            msId
                                ? props.dataItem["Responsible_" + msId]
                                : props.dataItem.Responsible
                        }
                        placeholder="Select Employee"
                    />
                </td>
            );
        }
        if (value === undefined) return this.renderNACell();
        return <td>{value}</td>;
    };

    renderDateCell = (props: React.PropsWithChildren<GridCellProps>) => {
        // @ts-ignore
        let value = props.dataItem[props.field];
        // @ts-ignore
        let field: "ProjectedDate" | "ActualDate" = props.field?.split("_")[0];
        let msId = props.field?.split("_")[1];
        let isOverdue = msId
            ? props.dataItem["IsOverdue_" + msId]
            : props.dataItem.IsOverdue;
        let className =
            field === "ProjectedDate" && isOverdue ? styles.RedDateCell : "";
        let msCanChangeField = msId ? "CanChange_" + msId : "CanChange";
        if (
            props.dataItem.key === this.state.editKey &&
            props.dataItem[msCanChangeField]
        ) {
            return (
                <td className={className}>
                    <GridDatePicker
                        bpId={props.dataItem.BPId}
                        msId={msId || props.dataItem.MSId}
                        field={field}
                        defaultValue={value}
                        onChange={this.OnChangeDate}
                    />
                </td>
            );
        }
        if (value === undefined) return this.renderNACell();
        return (
            <td className={className}>{value ? moment(value).format("L") : ""}</td>
        );
    };

    renderGridFilter = (props: any) => {
        let field: msKeys = props.field.split("_")[0];
        let msId = props.field.split("_")[1];
        let data: Array<IComboboxItem> = [];
        let className = styles.FilterCellCombobox;
        switch (field) {
            case "MSName":
                field = "MSId";
                data = this.columnValues.MSId || [];
                break;
            case "ProjectedDate":
            case "ActualDate":
                data = dateRangeValues;
                break;
            case "ResponsibleName":
                field = "ResponsibleId";
                data = this.columnValues.ResponsibleId || [];
                break;
            case "PermitStatusName":
                field = "PermitStatusId";
                data = this.columnValues.PermitStatusId || [];
                break;
            case "CxStatusName":
                field = "CxStatusId";
                data = this.columnValues.CxStatusId || [];
                break;
        }
        if (msId) field += "_" + msId;
        return this.renderFilter(field, data, undefined, className);
    };

    renderApplicableFilterCell = () => {
        return (
            <BooleanFilter
                key={this.state.remountKey + "Applicable"}
                trueText="Applicable"
                falseText="Not Applicable"
                id="Applicable"
                // @ts-ignore
                defaultValue={this.MSFilter.Applicable}
                onChange={(value: boolean | null) => {
                    if (value === null) {
                        delete this.MSFilter.Applicable;
                    } else {
                        this.MSFilter.Applicable = value;
                    }
                    this.SetSaveGridFilters(false);
                }}
            />
        );
    };

    renderFilter = (
        field: string /* msKeys | groupKeys */,
        data: Array<IComboboxItem>,
        placeholder?: string,
        className?: string
    ) => {
        let isGroupFilter = this.IsGroupFilter(field.split("_")[0]);
        // @ts-ignore
        let value = isGroupFilter ? this.groupFilter[field] : this.MSFilter[field];
        return (
            <FilterCombobox
                key={this.state.remountKey + field}
                className={`${styles.ToolbarCombobox} ${className || ""}`}
                dataAttr={{field}}
                data={data}
                onChange={
                    isGroupFilter
                        ? this.OnChangeGroupFilter
                        : this.OnChangeMilestonesFilter
                }
                defaultValue={value || null}
                placeholder={placeholder || ""}
                loading={this.state.loadingFiltersData}
            />
        );
    };

    renderColumnMenu = (props: any) => {
        let fieldIdByFieldName: { [key: string]: msKeys } = {
            MSName: "MSId",
            ResponsibleName: "ResponsibleId",
            BPName: "BPId",
            PermitStatusName: "PermitStatusId",
            CxStatusName: "CxStatusId",
        };

        let field = props.column.field;
        let onlyField = field.split("_")[0];
        let msId = field.split("_")[1];
        let fieldId = fieldIdByFieldName[onlyField];
        if (
            (this.groupMode && !fieldId) ||
            (!this.groupMode && !fieldId && !this.IsGroupKey(field))
        )
            return null;
        if (msId) fieldId += "_" + msId;
        let bpColumnsProps: simpleObject = {};
        if (!this.groupMode && this.IsGroupKey(field)) {
            if (!fieldId) {
                bpColumnsProps.getColumnValues = undefined;
                bpColumnsProps.filterByValues = undefined;
                bpColumnsProps.filterable = false;
                bpColumnsProps.filterSubmit = undefined;
            }
            bpColumnsProps.columns = this.BPMenuColumns;
            bpColumnsProps.onColumnsSubmit = this.OnBPColumnsSubmit;
            bpColumnsProps.getDefaultColumns = this.GetDefaultBPMenuColumns;
        }
        DisableColumnMenuBtn(
            this.gridRef,
            gridStyles.DisabledColumnMenuBtn,
            fieldId
        );
        return (
            <CustomColumnMenu
                defaultProps={props}
                onCloseMenu={this.OnCloseColumnMenu}
                getColumnValues={this.GetColumnValues}
                filterByValues={this.OnSubmitFilterByColumnValues}
                fieldId={fieldId}
                filterable={onlyField !== "MSName"}
                filterSubmit={
                    onlyField !== "MSName" ? this.OnSubmitComplexGridFilters : undefined
                }
                {...bpColumnsProps}
            />
        );
    };

    renderExcelGrid = () => {
        let groupBy = [{field: "BPId"}];
        let groupable = process(this.state.gridData, {
            group: groupBy,
            filter: {
                filters: [
                    {
                        field: "rowType",
                        value: "milestone",
                        operator: "eq",
                    },
                ],
                logic: "and",
            },
        });
        return (
            <ExcelExport
                data={groupable.data}
                ref={this.initExportRef}
                fileName="Milestones.xlsx"
                group={groupBy}
            >
                <ExcelExportColumn
                    field="BPId"
                    hidden={true}
                    groupHeaderCellOptions={{wrap: true}}
                    groupHeader={(props: any) => {
                        let group = this.groups[props.value];
                        let BPName = group.BPName;
                        let permitStatus =
                            group.PermitStatusId &&
                            this.state.permitStatuses.find(
                                (status) => status.Id === group.PermitStatusId
                            );
                        let permit = permitStatus
                            ? `Permit Status: ${permitStatus.Name} |`
                            : "";
                        let permitComment = group.PermitStatusComment
                            ? `Permit Status Comment: ${group.PermitStatusComment} |`
                            : "";

                        let cxStatus = this.state.cxStatuses.find(
                            (status) => status.Id === group.CxStatusId
                        );
                        let cx = cxStatus ? `CX Status: ${cxStatus.Name} |` : "";
                        let cxComment = group.CxStatusComment
                            ? `CX Status Comment: ${group.CxStatusComment} |`
                            : "";

                        let counts = `Days on Site: ${group.ActualDaysOnSite} of Planned ${group.PlannedDaysOnSite} Progress ${group.Progress}`;
                        return `${BPName} ${permit} ${permitComment} ${cx} ${cxComment} ${counts}`;
                    }}
                />
                <ExcelExportColumn
                    field="Applied"
                    title="Applied"
                    width={71}
                />
                <ExcelExportColumn
                    field="MSName"
                    title="Milestone"
                    width={225}
                />
                <ExcelExportColumn
                    field="ProjectedDate"
                    title="Projected"
                    width={150}
                />
                <ExcelExportColumn
                    field="ActualDate"
                    title="Actual"
                    width={150}
                />
                <ExcelExportColumn
                    field="ResponsibleName"
                    title="Responsible"
                    width={200}
                />
                <ExcelExportColumn
                    field="Comments"
                    title="Comments"
                    width={300}
                />
            </ExcelExport>
        );
    };

    renderNoGroupedExcelGrid = () => {
        let data = [];
        for (let row of this.state.gridData) {
            let rowData = {...row};
            if (this.columnValues.MSId) {
                for (let ms of this.columnValues.MSId) {
                    for (let column of this.MSColumns) {
                        let field = column.field + "_" + ms.Id;
                        // @ts-ignore
                        if (rowData[field] === undefined) rowData[field] = "N/A";
                    }
                }
            }
            data.push(rowData);
        }
        return (
            <ExcelExport
                // @ts-ignore
                data={data}
                ref={this.initExportNoGroupRef}
                fileName="Milestones.xlsx"
            >
                <ExcelExportColumn
                    field="BPName"
                    title="Build Plan"
                    width={225}
                    headerCellOptions={{wrap: true}}
                />
                {!!this.columnValues.MSId &&
                    this.columnValues.MSId.map((ms) => {
                        if (
                            !ms.Selected &&
                            this.columnValues.MSId &&
                            this.columnValues.MSId.findIndex((item) => item.Selected) > -1
                        ) {
                            return null;
                        }
                        return (
                            <ExcelExportColumn
                                key={ms.Id}
                                title={ms.Name}
                            >
                                {this.MSColumns.map((column) => {
                                    let columnKey = column.field + "_" + ms.Id;
                                    return (
                                        <ExcelExportColumn
                                            key={columnKey}
                                            field={columnKey}
                                            title={column.title}
                                            width={column.width}
                                            hidden={
                                                !!this.MSColumnsFilter.MSColumns.length &&
                                                !column.select
                                            }
                                        />
                                    );
                                })}
                            </ExcelExportColumn>
                        );
                    })}
                {this.BPColumns.map((column) => {
                    if (this.BPMenuColumns[column.field]?.hidden) return null;
                    return (
                        <ExcelExportColumn
                            key={column.field}
                            field={column.field}
                            title={column.title}
                            width={column.width}
                        />
                    );
                })}
            </ExcelExport>
        );
    };

    hasFilters = (fieldName: msKeys, fieldId: msKeys) => {
        return (
            this.IsFilteredByComplexFilter(fieldName) ||
            this.IsFilteredByValueFilter(fieldId)
        );
    };

    IsGroupKey = (field: string): field is groupKeys => {
        return (
            field === "BPName" ||
            field === "PermitStatusName" ||
            field === "PermitStatusComment" ||
            field === "CxStatusName" ||
            field === "CxStatusComment" ||
            field === "ActualDaysOnSite" ||
            field === "PlannedDaysOnSite" ||
            field === "Progress"
        );
    };

    IsFilteredByComplexFilter = (fieldName: msKeys) => {
        if (!this.complexGridFilter || !this.complexGridFilter.length) return false;
        let filterIndex = this.complexGridFilter.findIndex(
            (filter) =>
                IsComplexGridFilter(filter) &&
                !IsComplexGridFilter(filter.filters[0]) &&
                filter.filters[0].field === fieldName
        );
        return filterIndex > -1;
    };

    IsFilteredByValueFilter = (fieldId: msKeys) => {
        if (!this.columnValuesFilter[fieldId]) return false;
        return !!this.columnValuesFilter[fieldId].length;
    };

    OnGroupCommentCellClick = (e: any) => {
        let index = e.currentTarget.dataset.index;
        let dataItem = this.state.gridData[index];
        if (dataItem) this.OnRowClick({dataItem});
    };

    OpenCxStatusCommentEdit = (e: any) => {
        let bpId = +e.currentTarget.dataset.bpid;
        let field = "CxStatusComment";
        let group = this.groups[bpId];
        // @ts-ignore
        let oldValue = group[field];
        let commentRef: any = React.createRef();
        ModalRef.showDialog({
            title: "Cx Status Comment",
            width: 800,
            buttons: [
                {
                    text: "Ok",
                    color: "primary",
                    disabled: !group.CanManage,
                    action: () => {
                        let value = commentRef.element.current.value;
                        if (value !== oldValue) {
                            this.UpdateMilestones(
                                {
                                    FieldName: "CxStatusComment",
                                    NewString: value,
                                    BPID: bpId,
                                },
                                value
                            );
                        }
                        ModalRef.hideDialog();
                    },
                },
                {
                    text: "Cancel",
                    action: () => {
                        ModalRef.hideDialog();
                    },
                },
            ],
            children: (
                <div>
                    <TextArea
                        ref={(ref) => (commentRef = ref)}
                        rows={20}
                        style={{width: "100%"}}
                        defaultValue={oldValue}
                        readOnly={!group.CanManage}
                    />
                </div>
            ),
        });
    };

    OnCloseColumnMenu = () => {
        DisableColumnMenuBtn(this.gridRef, gridStyles.DisabledColumnMenuBtn);
    };

    OnSubmitComplexGridFilters = (
        filter: IGridFilter,
        fieldId: msKeys,
        fieldName: msKeys
    ) => {
        // @ts-ignore
        this.complexGridFilter =
            filter && filter.filters.length
                ? filter.filters.filter(
                    (item) =>
                        IsComplexGridFilter(item) &&
                        !IsComplexGridFilter(item.filters[0]) &&
                        item.filters[0].field !== fieldId
                )
                : null;
        let msFilterField = fieldId === "BPId" ? fieldName : fieldId;
        let remountFilters =
            this.IsFilteredByComplexFilter(fieldName) &&
            !!this.MSFilter[msFilterField];
        if (this.IsFilteredByComplexFilter(fieldName)) {
            this.RemoveColumnValuesFilter(fieldId);
            this.RemoveQuickColumnFilter(msFilterField);
        }
        this.SetSaveGridFilters(remountFilters);
    };

    OnSubmitFilterByColumnValues = (
        filters: Array<IGridFilterItem>,
        values: Array<IColumnValue>,
        fieldId: msKeys,
        fieldName: msKeys
    ) => {
        if (!this.groupMode && !this.IsGroupKey(fieldName)) {
            if (filters.length) {
            }
            let msId = fieldId.split("_")[1];
            let responsibleIds = [];
            for (let filter of filters) {
                filter.field += "_" + msId;
                responsibleIds.push(filter.value);
            }
            this.ResponsibleMSFilters[+msId] = responsibleIds;
        }
        this.columnValues[fieldId] = values;
        if (filters.length < values.length)
            this.columnValuesFilter[fieldId] = filters;
        else delete this.columnValuesFilter[fieldId];
        let msFilterField = fieldId === "BPId" ? fieldName : fieldId;
        let remountFilters = !!this.MSFilter[msFilterField];
        this.RemoveQuickColumnFilter(msFilterField);
        this.RemoveComplexColumnFilter(fieldName);
        this.SetSaveGridFilters(remountFilters);
    };

    GetColumnValues = (field: string, fieldId: msKeys): Array<IColumnValue> => {
        let onlyFieldId = fieldId.split("_")[0];
        let msId = fieldId.split("_")[1];
        // @ts-ignore
        let values = this.columnValues[onlyFieldId] || [];
        if (
            !msId ||
            !this.ResponsibleMSFilters[+msId] ||
            !this.ResponsibleMSFilters[+msId].length
        )
            return values;
        else {
            return values.map((value: IColumnValue) => ({
                ...value,
                Select:
                    this.ResponsibleMSFilters[+msId].findIndex((id) => id === value.Id) >
                    -1,
            }));
        }
    };

    RemoveColumnValuesFilter = (fieldId: msKeys) => {
        delete this.columnValuesFilter[fieldId];
        let values = this.columnValues[fieldId];
        if (values) {
            for (let value of values) value.Selected = true;
        }
        let msId = fieldId.split("_")[1];
        if (
            msId &&
            this.ResponsibleMSFilters[+msId] &&
            this.ResponsibleMSFilters[+msId].length
        )
            this.ResponsibleMSFilters[+msId] = [];
    };

    RemoveQuickColumnFilter = (fieldId: msKeys) => {
        delete this.MSFilter[fieldId];
    };

    RemoveComplexColumnFilter = (fieldName: string) => {
        if (this.complexGridFilter && this.complexGridFilter.length) {
            this.complexGridFilter = this.complexGridFilter.filter(
                (item) =>
                    IsComplexGridFilter(item) &&
                    !IsComplexGridFilter(item.filters[0]) &&
                    item.filters[0].field !== fieldName
            );
        }
    };

    GetFullGridFilters = (forState: boolean) => {
        let gridFilter = GetDefaultGridFilter();
        for (let key in this.MSFilter) {
            // @ts-ignore
            let field: msKeys = key;
            let value = this.MSFilter[field];
            let onlyField = field.split("_")[0];

            if (value || onlyField === "Applicable") {
                if (onlyField === "ActualDate" || onlyField === "ProjectedDate") {
                    gridFilter.filters.push(
                        // @ts-ignore
                        this.GetGridFilterAfterDateRangeChange(value, field)
                    );
                } else {
                    let filter;
                    let filterValue;
                    let filterOperator;
                    if (
                        onlyField === "ActualDaysOnSite" ||
                        onlyField === "PlannedDaysOnSite" ||
                        onlyField === "Progress"
                    ) {
                        filterValue =
                            // @ts-ignore
                            value.value !== null || value.value !== undefined
                                ? // @ts-ignore
                                +value.value
                                : value;
                        // @ts-ignore
                        filterOperator = value.operator;
                    } else if (typeof value === "boolean") {
                        filterValue = value;
                        filterOperator = "eq";
                    } else if (value instanceof Object) {
                        // @ts-ignore
                        filterValue = value?.Id;
                        filterOperator = "eq";
                    } else {
                        filterValue = value;
                        filterOperator = "contains";
                    }
                    filter = {field, value: filterValue, operator: filterOperator};
                    if (!forState) {
                        if (
                            this.groupMode ||
                            (onlyField !== "IsOverdue" && onlyField !== "IsMy")
                        )
                            gridFilter.filters.push(filter); // not for state
                    } else {
                        gridFilter.filters.push(filter); // ??
                    }
                }
            }
        }
        if (!forState) {
            if (!this.groupMode) {
                let complexFilter = [];
                if (this.complexGridFilter?.length) {
                    for (let filter of this.complexGridFilter) {
                        if (
                            !IsComplexGridFilter(filter.filters[0]) &&
                            filter.filters[0].operator !== "isnull"
                        ) {
                            if (
                                !filter.filters[1] ||
                                (!IsComplexGridFilter(filter.filters[1]) &&
                                    filter.filters[1].operator !== "isnull")
                            ) {
                                complexFilter.push(filter);
                                continue;
                            }
                        }
                        let copyFilter: IGridFilter = {filters: [], logic: filter.logic};
                        for (let subFilter of filter.filters) {
                            if (!IsComplexGridFilter(subFilter)) {
                                if (subFilter.operator !== "isnull")
                                    copyFilter.filters.push(subFilter);
                                else
                                    copyFilter.filters.push({
                                        field: subFilter.field,
                                        value: null,
                                        operator: "eq",
                                    });
                            }
                        }
                        complexFilter.push(copyFilter);
                    }
                    gridFilter.filters.push(...complexFilter);
                }
            } else if (this.complexGridFilter) {
                gridFilter.filters.push(...this.complexGridFilter);
            }

            for (let key in this.columnValuesFilter) {
                let filter = this.columnValuesFilter[key];
                if (filter.length)
                    gridFilter.filters.push({
                        filters: filter,
                        logic: "or",
                    });
            }
        } else {
            if (this.complexGridFilter?.length)
                gridFilter.filters.push(...this.complexGridFilter);
        }

        return gridFilter;
    };

    GetDefaultBPMenuColumns = () => {
        let defaultColumns: IHiddenColumn = {};
        for (let key in this.BPMenuColumns) {
            defaultColumns[key] = {...this.BPMenuColumns[key], hidden: false};
        }
        return defaultColumns;
    };

    OnBPColumnsSubmit = (columns: IHiddenColumn) => {
        this.BPMenuColumns = columns;
        localStorage.setItem("MS_BPColumnsSettings", JSON.stringify(columns));
        this.forceUpdate();
    };

    SetSaveGridFilters = (remountFilters: boolean) => {
        if (!this.props.doNotSaveFilters) {
            if (this.complexGridFilter)
                localStorage.setItem(
                    MS_COMPLEX_FILTER_LS,
                    JSON.stringify(this.complexGridFilter)
                );
            else localStorage.removeItem(MS_COMPLEX_FILTER_LS);
            localStorage.setItem(
                MS_FILTER_VALUES_LS,
                JSON.stringify(this.columnValuesFilter)
            );
            localStorage.setItem(MS_FILTERS_LS, JSON.stringify(this.MSFilter));
        }
        this.SetFinalMilestones(
            "filterChanged",
            remountFilters ? +new Date() : undefined
        );
    };

    GetGridPageSize = () => {
        let gridRef = this.gridRef;
        if (gridRef && gridRef.element) {
            let container = gridRef.element;
            let contentDOMEl = container.querySelector(".k-grid-content");
            let htmlRows = contentDOMEl.querySelectorAll("tr");
            if (!htmlRows.length) return 0;
            return Math.ceil(contentDOMEl.clientHeight / GridRowHeight);
        }
        return 0;
    };

    RefreshPageSize = () => {
        this.setState({pageSize: this.GetGridPageSize()});
    };

    IsGroupFilter = (field: string) => {
        return (
            field === "BPId" ||
            field === "BPOId" ||
            field === "ProjectId" ||
            field === "ScenarioId" ||
            field === "RegionId" ||
            field === "ClassId"
        );
    };

    OnRowContextMenu = (e: any) => {
        e.preventDefault();
        let tr = e.target.closest("tr[data-msid]");
        if (!tr) return;
        let msid = tr.dataset.msid;
        let bpid = tr.dataset.bpid;
        let group = this.groups[bpid];
        let ms = group.items.find((ms) => +ms.BPId === +bpid && +ms.MSId === +msid);
        if (!msid) return;
        let cardTitle = `${group.BPName} - ${ms?.MSName || ""}`;
        CardManagement.OpenMilestoneHistoryCard(bpid, msid, cardTitle);
    };

    OnChangePage = (event: any) => {
        this.setState({skip: event.page.skip});
    };

    OnSort = (e: any) => {
        this.SetFinalMilestones("sort", undefined, e.sort);
    };

    OnOperatorChange = (props: any) => {
        //todo rename
        let field: msKeys = props.item.field;
        let operator = props.item.operator;
        let filter = this.MSFilter[field];
        //@ts-ignore
        let value = filter && filter.value !== null ? filter.value : null;
        //@ts-ignore
        let oldOperator = filter && filter.operator;
        if (value === null && oldOperator === operator) delete this.MSFilter[field];
        else {
            if (filter) this.MSFilter[field] = {value, operator};
            else this.MSFilter[field] = {value: null, operator};
        }
        this.SetSaveGridFilters(false);
    };

    ClearQuickGridFilter = (props: simpleObject) => {
        //todo rename
        this.OnChangeMilestonesFilter(props.value, {field: props.field});
    };

    OnChangeMilestonesFilter = (
        value: IComboboxItem | boolean,
        data: {
            field: keyof IMilestoneItem;
            filterType?: "numeric" | "text";
            operator?: string;
        }
    ) => {
        let field = data.field;
        this.RemoveQuickColumnFilter(field);
        if (value) {
            let newValue: any = value;
            if (data.filterType === "numeric") {
                newValue = {value: +value, operator: data.operator || "gte"};
            } else if (data.filterType === "text") newValue = "" + value;

            let valuesFilterFieldId = field === "BPName" ? "BPId" : field;
            this.MSFilter[field] = newValue;
            this.RemoveColumnValuesFilter(valuesFilterFieldId);

            let fieldName = field;
            let onlyField = field.split("_")[0];
            let msId = field.split("_")[1];
            if (onlyField === "ResponsibleId") fieldName = "ResponsibleName";
            else if (onlyField === "CxStatusId") fieldName = "CxStatusName";
            if (msId) fieldName += "_" + msId;
            this.RemoveComplexColumnFilter(fieldName);
        }
        this.SetSaveGridFilters(false);
    };

    GetGridFilterAfterDateRangeChange = (
        value: IComboboxItem,
        field: "ActualDate" | "ProjectedDate"
    ) => {
        let todayStart = moment().startOf("d");
        let todayFinish = moment().endOf("d");
        let filtervalue1;
        let filtervalue2;

        switch (value.Id) {
            case "today":
                filtervalue1 = todayStart.toDate();
                filtervalue2 = todayFinish.toDate();
                break;
            case "thisWeek":
                filtervalue1 = todayStart.startOf("w").toDate();
                filtervalue2 = todayFinish.endOf("w").toDate();
                break;
            case "last7Days":
                filtervalue1 = todayStart.subtract(7, "d").toDate();
                filtervalue2 = todayFinish.subtract(1, "d").toDate();
                break;
            case "last30Days":
                filtervalue1 = todayStart.subtract(30, "d").toDate();
                filtervalue2 = todayFinish.subtract(1, "d").toDate();
                break;
            case "thisMonth":
                filtervalue1 = todayStart.startOf("M").toDate();
                filtervalue2 = todayFinish.endOf("M").toDate();
                break;
            case "tomorrow":
                filtervalue1 = todayStart.add(1, "d").toDate();
                filtervalue2 = todayFinish.add(1, "d").toDate();
                break;
            case "next7Days":
                filtervalue1 = todayStart.add(1, "d").toDate();
                filtervalue2 = todayFinish.add(7, "d").toDate();
                break;
            case "thisYear":
                filtervalue1 = moment().startOf("year").toDate();
                filtervalue2 = moment().endOf("year").toDate();
                break;
            case "isNotNull":
                return {
                    filters: [
                        {field, value: null, operator: "neq"},
                        {field, value: undefined, operator: "neq"},
                    ],
                    logic: "and",
                };
            case "isNull":
                return {
                    field,
                    value: null,
                    operator: "eq",
                };
        }
        return {
            filters: [
                {field, value: filtervalue1, operator: "gte"},
                {field, value: filtervalue2, operator: "lte"},
            ],
            logic: "and",
        };
    };

    OnChangeGroupFilter = (
        value: IComboboxItem,
        data: {
            field: keyof IMSGroup;
        }
    ) => {
        if (value) this.groupFilter[data.field] = value;
        else delete this.groupFilter[data.field];
        if (!this.props.buildPlanId)
            localStorage.setItem(GROUP_FILTERS_LS, JSON.stringify(this.groupFilter));
        this.SetFinalMilestones("filterChanged");
    };

    OnChangeDate = (
        value: Date | null,
        FieldName: string,
        BPID: number,
        MSID: number
    ) => {
        let group = this.groups[BPID];
        if (!group) return;
        for (let item of group.items) {
            if (+item.BPId === BPID && +item.MSId === +MSID) {
                // @ts-ignore
                let oldValue = item[FieldName];
                let isSame = value === oldValue || moment(oldValue).isSame(value);
                if (!isSame) {
                    let params: IUpdateParams = {
                        BPID: +BPID,
                        MSID: +MSID,
                        FieldName,
                        NewDate: value === null ? null : moment(value).format("DD.MM.YYYY"),
                    };
                    this.UpdateMilestones(params, value);
                }
                break;
            }
        }
    };

    OnChangeApplicable = (e: any) => {
        let value = e.value;
        let msid = e.target.element.dataset.msid;
        let bpId = e.target.element.dataset.bpid;
        let params: IUpdateParams = {
            BPID: +bpId,
            MSID: +msid,
            FieldName: "Applicable",
            NewBoolean: value,
        };
        this.UpdateMilestones(params, value);
    };

    OnChangeGroupCombobox = (
        value: any,
        data: {
            field: string;
            bpId: number;
        }
    ) => {
        let params: IUpdateParams = {
            FieldName: data.field,
            NewInteger: value?.Id || null,
            BPID: data.bpId,
        };
        this.UpdateMilestones(params, value);
    };

    OnChangeGridTextField = (e: any) => {
        if (e.type === "keydown" && e.key !== "Enter") return;
        let bpId = +e.target.dataset.bpid;
        let field = e.target.dataset.field;
        let group = this.groups[bpId];
        let msId = e.target.dataset.msid;
        // @ts-ignore
        let oldValue = group[field];
        if (msId) {
            for (let item of group.items) {
                if (+item.BPId === bpId && +item.MSId === +msId) {
                    // @ts-ignore
                    oldValue = item[field];
                    break;
                }
            }
        }

        let newValue = e.target.value === "" ? null : e.target.value;
        if (field === "Progress" && +oldValue === +newValue) return;
        else if (oldValue === newValue) return;

        if (field === "Progress") {
            if (
                newValue === null ||
                newValue === "" ||
                +newValue < 0 ||
                +newValue > 100 ||
                +newValue % 1
            )
                return;
            this.UpdateProgress(bpId, +newValue);
        } else {
            let valueField =
                field === "PlannedDaysOnSite" ? "NewInteger" : "NewString";
            let params: IUpdateParams = {
                FieldName: field,
                [valueField]: newValue,
                BPID: bpId,
            };
            if (field === "Comments") params.MSID = +e.target.dataset.msid;
            this.UpdateMilestones(params, newValue);
        }
    };

    OnChangeResponsible = (
        value: any,
        data: {
            field: string;
            bpId: number;
            msId: number;
        }
    ) => {
        let params: IUpdateParams = {
            BPID: +data.bpId,
            MSID: +data.msId,
            FieldName: data.field,
            NewInteger: value?.Id || null,
        };
        this.UpdateMilestones(params, value);
    };

    GetEmptyMSColumnsFilter = () => {
        return {
            MSColumns: [],
            MSId: [],
        };
    };

    OnSwitchGrouping = (checked: boolean) => {
        this.groupMode = checked;
        localStorage.setItem("MS_Group", checked.toString());
        localStorage.removeItem("MSColumnsFilter");
        localStorage.removeItem("MS_BPColumnsSettings");
        this.BPMenuColumns = this.GetDefaultBPMenuColumns();
        this.MSColumnsFilter = this.GetEmptyMSColumnsFilter();
        for (let column of this.MSColumns) {
            column.select = false;
        }
        this.RemoveGridFilters();
        this.SetFinalMilestones("switchMode", +new Date());
    };

    OnRowClick = (event: any /* GridRowClickEvent */) => {
        if (event.dataItem.items) return;
        this.setState({editKey: event.dataItem.key});
    };

    SetFinalMilestones = (
        action: gridChangeDataAction,
        remountKey: number = this.state.remountKey,
        sort: Array<ISortGridItem> = this.state.sort
    ) => {
        let gridFilter = this.GetFullGridFilters(false);
        let rows: Array<IGroupRow | IMilestoneItem | simpleObject> = [];
        let hasGridFilters = !!gridFilter && !!gridFilter.filters.length;
        if (!hasGridFilters && !this.groupMode) {
            hasGridFilters = !!this.MSFilter.IsMy || !!this.MSFilter.IsOverdue;
        }
        let groupFilterLength = Object.keys(this.groupFilter).length;
        let shownBPLength = 0;
        let shownMSLength = 0;
        for (let bpId in this.groups) {
            let group = this.groups[bpId];
            let showGroup = true;
            if (groupFilterLength) {
                for (let field in this.groupFilter) {
                    // @ts-ignore
                    showGroup = this.groupFilter[field].Id === group[field];
                    if (!showGroup) break;
                }
            }
            if (!showGroup) continue;
            if (this.groupMode) {
                let items = hasGridFilters
                    ? filterBy(group.items, gridFilter)
                    : group.items;
                if (items.length || !this.isPage) {
                    rows.push(...group.headerRows, ...orderBy(items, sort));
                    shownBPLength++;
                    shownMSLength += items.length;
                }
            } else {
                let row: simpleObject = {
                    BPId: group.BPId,
                    BPName: group.BPName,
                    BPOId: group.BPOId,
                    CanManage: group.CanManage,
                    ClassId: group.ClassId,
                    CxStatusComment: group.CxStatusComment,
                    CxStatusId: group.CxStatusId,
                    CxStatusName: group.CxStatus?.Name || null,
                    PermitStatusComment: group.PermitStatusComment,
                    PermitStatusId: group.PermitStatusId,
                    PermitStatusName: group.PermitStatus?.Name || null,
                    PlannedDaysOnSite: group.PlannedDaysOnSite,
                    ActualDaysOnSite: group.ActualDaysOnSite,
                    Progress: group.Progress,
                    ProjectId: group.ProjectId,
                    RegionId: group.RegionId,
                    ScenarioId: group.ScenarioId,
                };

                let itemsLength = 0;
                for (let item of group.items) {
                    if (this.MSFilter.IsMy && !item.IsMy) continue;
                    if (this.MSFilter.IsOverdue && !item.IsOverdue) continue;

                    for (let column of this.MSColumns) {
                        row[column.field + "_" + item.MSId] = item[column.field];
                    }
                    row["Applied_" + item.MSId] = item.Applied;
                    row["CanChange_" + item.MSId] = item.CanChange;
                    row["IsMy_" + item.MSId] = item.IsMy;
                    row["IsOverdue_" + item.MSId] = item.IsOverdue;
                    row["ResponsibleId_" + item.MSId] = item.ResponsibleId;
                    row["Responsible_" + item.MSId] = item.Responsible;
                    row["CanChange_" + item.MSId] = item.CanChange;
                    row.key = group.BPId
                    itemsLength++;
                }
                // todo filter rows с учетом не показываемых столбцов??
                if (itemsLength) {
                    let items = hasGridFilters
                        ? filterBy([row], gridFilter)
                        : group.items;
                    if (items.length) {
                        rows.push(row);
                        shownBPLength++;
                        shownMSLength += itemsLength;
                    }
                }
            }
        }
        let gridData = this.groupMode ? rows : orderBy(rows, sort);
        if (this.props.onChangeGridData)
            this.props.onChangeGridData(gridData, action);
        this.setState({
            editKey: null,
            gridData,
            shownGridInfo: [shownBPLength, shownMSLength],
            sort,
            gridFilter: this.GetFullGridFilters(true),
            remountKey,
            grouped: this.groupMode,
        });
    };

    IsGroupField = (field: string) => {
        return (
            field === "CxStatusComment" ||
            field === "CxStatusId" ||
            field === "PermitStatusComment" ||
            field === "PermitStatusId" ||
            field === "PlannedDaysOnSite"
        );
    };

    IsCombobox = (field: string) => {
        return (
            field === "CxStatusId" ||
            field === "PermitStatusId" ||
            field === "ResponsibleId"
        );
    };

    UpdateMilestones = async (params: IUpdateParams, newValue: any) => {
        this.setState({loading: true});
        try {
            await RunScriptAsync("FSMBuildPlans_UpdateMilestone", params);
            let fieldName = params.FieldName;
            let isCombobox = this.IsCombobox(fieldName);
            let valueField = isCombobox
                ? fieldName.substr(0, fieldName.length - 2)
                : fieldName;
            let valueFieldId = valueField + "Id";
            let valueFieldName = valueField + "Name";
            let group = this.groups[params.BPID];
            if (this.IsGroupField(fieldName)) {
                // @ts-ignore
                if (isCombobox) group[fieldName] = newValue?.Id;
                // @ts-ignore
                group[valueField] = newValue;
                for (let item of group.items) {
                    if (isCombobox) {
                        // @ts-ignore
                        item[valueFieldId] = newValue?.Id;
                        // @ts-ignore
                        item[valueFieldName] = newValue?.Name;
                    } else {
                        // @ts-ignore
                        item[valueField] = newValue;
                    }
                }
            } else {
                for (let item of group.items) {
                    if (params.MSID && +item.BPId === +params.BPID && +item.MSId === +params.MSID) {
                        if (isCombobox) {
                            // @ts-ignore
                            item[fieldName] = newValue?.Id || null;
                            // @ts-ignore
                            item[valueFieldName] = newValue?.Name || null;
                        }
                        // @ts-ignore
                        item[valueField] = newValue;
                        if (params.FieldName === "Applicable") {
                            item.Applied = newValue ? "Yes" : "No";
                        }
                        break;
                    }
                }
            }

            // @ts-ignore
            if (isCombobox && this.columnValues[valueFieldId]) {
                if (newValue) {
                    if (
                        // @ts-ignore
                        this.columnValues[valueFieldId].findIndex(
                            // @ts-ignore
                            (item) => item.Id === newValue.Id
                        ) === -1
                    ) {
                        // @ts-ignore
                        this.columnValues[valueFieldId].push({
                            ...newValue,
                            // @ts-ignore
                            FieldId: valueFieldId,
                            Select: true,
                        });
                        // @ts-ignore
                        this.columnValues[valueField].sort(sortByName);
                    }
                }
            }
            if (fieldName === "CxStatusComment")
                this.setState({remountKey: +new Date()});

            if (!this.IsGroupField(fieldName) || !this.groupMode) {
                this.SetFinalMilestones("dataLoaded");
            }
        } catch (error: any) {
            this.Refresh();
        } finally {
            this.setState({loading: false});
        }
    };

    UpdateProgress = async (BPID: number, Progress: number) => {
        this.setState({loading: true});
        try {
            await RunScriptAsync("FSMBuildPlans_UpdateProgress", {BPID, Progress});
            this.groups[BPID].Progress = Progress;
        } catch (error: any) {
            this.Refresh();
        } finally {
            this.setState({loading: false});
        }
    };

    LoadData = () => {
        let params: simpleObject = {};
        if (this.props.buildPlanId) params.BuildPlanId = this.props.buildPlanId;
        return this.GetSQLData({spName: "DB_Milestones", params});
    };

    OnChangeOverDue = (e: any) => {
        this.MSFilter.IsOverdue = e.value;
        this.OnChangeMilestonesFilter(e.value, {field: "IsOverdue"});
    };

    OnChangeOnlyMy = (e: any) => {
        this.MSFilter.IsMy = e.value;
        this.OnChangeMilestonesFilter(e.value, {field: "IsMy"});
    };

    OnChangeMSColumnsFilter = (
        selectedIds: Array<number | string>,
        filterName: any
    ) => {
        let field = filterName === "Details" ? "MSColumns" : "MSId";
        // @ts-ignore
        this.MSColumnsFilter[field] = selectedIds;
        localStorage.setItem(
            "MSColumnsFilter",
            JSON.stringify(this.MSColumnsFilter)
        );
        // todo ? copy new values
        this.forceUpdate();
    };

    ClearFilters = () => {
        this.RemoveGridFilters();
        this.RemoveToolbarFilters();
        this.SetSaveGridFilters(true);
    };

    RemoveGridFilters = () => {
        for (let key in this.MSFilter) {
            // @ts-ignore
            if (key !== "IsMy" && key !== "IsOverdue") delete this.MSFilter[key];
        }
        for (let key in this.columnValues) {
            if (this.groupMode || key !== "MSId") {
                // @ts-ignore
                let list = this.columnValues[key];
                for (let value of list) value.Select = true;
            }
        }
        this.ResponsibleMSFilters = {};
        this.columnValuesFilter = {};
        this.complexGridFilter = null;
        if (!this.props.doNotSaveFilters) {
            localStorage.setItem(MS_FILTERS_LS, JSON.stringify(this.MSFilter));
            localStorage.removeItem(MS_FILTER_VALUES_LS);
            localStorage.removeItem(MS_COMPLEX_FILTER_LS);
        }
    };

    RemoveToolbarFilters = () => {
        for (let key in this.MSFilter) {
            // @ts-ignore
            if (key === "IsMy" || key === "IsOverdue") delete this.MSFilter[key];
        }
        if (!this.props.doNotSaveFilters) {
            localStorage.removeItem(GROUP_FILTERS_LS);
        }
        this.groupFilter = {};
    };

    LoadEmployees = () => {
        return ReferenceRecordsDataSource("Employees");
    };

    LoadCxStatuses = () => {
        return ReferenceRecordsDataSource("FSMBPConstructionStatus");
    };

    LoadPermitStatuses = () => {
        return ReferenceRecordsDataSource("FSMBPPermitStatus");
    };

    LoadFilterData = (refName: string) => {
        if (this.props.buildPlanId && refName !== "FSMMilestones") return;
        return ReferenceRecordsDataSource(refName);
    };

    Refresh = async () => {
        this.Init();
        this.InitFilters();
    };

    ExportToExcel = (e: any) => {
        if (this.groupMode) this.exportGridRef.save();
        else this.exportGridNoGroupRef.save();
    };
}

export default Milestones;
