import styles from "./sidebar.module.scss";

interface IProps {
    rtl?: boolean
    opened: boolean
    btnText: string
    toggle: () => void
    btnTextStyle?: React.CSSProperties
    boxHeight: number
}

const SideBarToggleBtn = (props: IProps) => {
    const {rtl, opened, btnText, btnTextStyle, boxHeight, toggle} = props

    let iconClass = ''
    if (rtl) iconClass = opened ? "k-icon k-i-arrow-chevron-right" : "k-icon k-i-arrow-chevron-left"
    else iconClass = opened ? "k-icon k-i-arrow-chevron-left" : "k-icon k-i-arrow-chevron-right"
    return <div
        className={`${styles.Btn} ${rtl ? styles.RTL : ''}`}
        onClick={toggle}
    >
        <div className={styles.Arrows}>
            <span className={iconClass}></span>
        </div>
        {btnText &&
            <span
                className={`${styles.BtnText} ${rtl ? styles.RTL : ''}`}
                style={{...btnTextStyle, width: boxHeight}}
            >
                  {btnText}
                </span>}
    </div>
}

export default SideBarToggleBtn