import React, {Component} from 'react';
import {simpleObject} from '../../helpers/interfaces';
import DashboardGrid from '../Dashboard/Grid'
import {IColumnSetting} from '../Dashboard/interfaces';

interface props {
    isActive: boolean
    urlParams?: simpleObject
    periodId?: number  // if PPTab

    onChangeGridData?(): void
}

interface state {
}

const IsWarningRow = (row: simpleObject) => row.AdditionType == 'N/A' && row.AppliedAdditionType !== 'N/A' || row.Hours !== null && row.Amount == null

const COLUMNS: Array<IColumnSetting> = [
    {
        field: 'PeriodName',
        title: 'Payroll Period',
        type: 'string',
        minWidth: 200,
        format: {
            type: 'link',
            fieldId: 'PeriodId',
            refName: 'TKPayrollPeriods'
        }
    },
    {
        field: 'EmployeeName',
        title: 'Employee',
        type: 'string',
        gridWidth: 200,
        format: {
            type: 'link',
            fieldId: 'EmployeeId',
            refName: 'Employees'
        },
    },
    {
        field: 'DepartmentName',
        title: 'Department',
        type: 'string',
        gridWidth: 100,
        format: {
            type: 'string',
            fieldId: 'DepartmentName'
        }
    },
    {
        field: 'PayrollItemName',
        title: 'Payroll Item',
        type: 'string',
        minWidth: 180,
        format: {
            type: 'string',
            fieldId: 'PayrollItemName'
        }
    },
    {
        field: 'Type',
        title: 'Type',
        type: 'string',
        format: {
            type: 'string',
            fieldId: 'Type'
        }
    },
    {
        field: 'AdditionType',
        title: 'Addition Type',
        type: 'string',
        gridWidth: 180,
        format: {
            type: 'string',
            fieldId: 'AdditionType'
        }
    },
    {
        field: 'AppliedAdditionType',
        title: 'Applied Addition Type',
        type: 'string',
        gridWidth: 180,
        format: {
            type: 'string',
            fieldId: 'AppliedAdditionType'
        }
    },
    {
        field: 'Hours',
        title: 'Hours',
        type: 'number',
        format: {
            type: 'number',
        },
        aggregate: 'sum'
    },
    {
        field: 'QTY',
        title: 'QTY',
        type: 'number',
        format: {
            type: 'number',
        },
        aggregate: 'sum'
    },
    {
        field: 'Rate',
        title: 'Rate',
        type: 'number',
        format: {
            type: 'number',
        }
    },
    {
        field: 'Amount',
        title: 'Amount',
        type: 'number',
        format: {
            type: 'number',
        },
        aggregate: 'sum'
    },
    {
        field: 'AdjRate',
        title: 'Adj. Rate',
        type: 'number',
        format: {
            type: 'number',
        }
    },
    /* {
      field: 'AvgRate',
      title: 'Avg. Rate',
      type: 'number',
      gridWidth: 90,
      format: {
        type: 'number',
      }
    }, */
    {
        field: 'LastRate',
        title: 'Last Rate',
        type: 'number',
        format: {
            type: 'number'
        }
    },
    {
        field: 'PayrollCheckName',
        title: 'Payroll Check Name',
        type: 'string',
        gridWidth: 180,
        format: {
            type: 'string',
            fieldId: 'PayrollCheckName'
        }
    },
    {
        field: 'LastSynced',
        title: 'Last Synced',
        type: 'date',
        format: {
            type: 'datetime'
        }
    },
    {
        field: 'HasWarningsStr',
        title: 'Warn.',
        type: 'string',
        gridWidth: 70,
        filterable: false,
        sortable: false,
        format: {
            type: 'icon',
            customFormat: true,
            getCustomFormat: (dataItem: simpleObject) => {
                if (!dataItem.HasWarnings) return null
                return <div style={{textAlign: 'center'}}>
                    <span className="fx-warning-color mdi mdi-alert-outline"></span>
                </div>
            },
            fieldId: 'HasWarnings'
        }
    },
]

class CostRates extends Component<props, state> {
    gridRef: any = React.createRef()
    periodId = this.props.periodId

    componentDidMount() {
        let isExternalBrowser = !!this.props.urlParams
        if (!isExternalBrowser) {
            for (let i = 0; i < COLUMNS.length; i++) {
                let col = COLUMNS[i]
                if (/* col.field === 'AvgRate' ||  */col.field === 'PeriodName') col.hiddenColumn = true
            }
        }
    }

    render() {
        let urlParams = this.props.urlParams
        let isExternalBrowser = !!urlParams
        let propsPeriodId = this.props.periodId
        if (!isExternalBrowser && this.props.isActive && propsPeriodId && this.periodId !== propsPeriodId) {
            this.periodId = this.props.periodId
        }
        const params = isExternalBrowser ? {
            periodId: urlParams?.payrollPeriodId || null,
            checkId: urlParams?.checkId || null,
        } : {
            periodId: this.props.periodId || null,
        }
        return (
            <DashboardGrid
                key={this.periodId}
                isActive={this.props.isActive}
                isParentPage={isExternalBrowser}
                ref={this.gridRef}
                columnsSettings={COLUMNS}
                getSqlParams={{spName: 'DB_CostRates', params}}
                excelFileName="Cost Rates.xlsx"
                getRowKey={(dataItem: simpleObject) => dataItem.RowNum}
                columnMenu={{
                    filters: true,
                    columns: false
                }}
                prepareData={this.PrepareData}
                initRefresh={this.InitRefresh}
                onChangeGridData={this.props.onChangeGridData}
                defaultSort={[{
                    dir: 'asc',
                    field: 'EmployeeName'
                }]}
            />
        )
    }

    PrepareData = (result: [Array<simpleObject>]) => {
        let data = result[0]
        for (let row of data) {
            row.HasWarnings = IsWarningRow(row)
            row.HasWarningsStr = row.HasWarnings ? 'Yes' : 'No'
        }
        return data
    }

    Refresh = () => {
    }

    InitRefresh = (refresh: any) => {
        this.Refresh = refresh
    }
}

export default CostRates
